import { useEffect, useRef, useState } from 'react'
import CalendarMemoized, { CalendarSize } from '../calendar/Calendar'
import InputField, {
  IconPosition,
  InputShadow,
  InputSize,
} from '../input/InputField'

export interface IBasePickerProps {
  value: string
  onChange: (val: string) => void
  text?: string
  sized?: InputSize
  shadow?: InputShadow
  onPressEnter?: () => void
  minValue?: string
  maxValue?: string
}

const DatePicker = ({
  value,
  onChange,
  sized = InputSize.xs,
  shadow,
  onPressEnter,
}: IBasePickerProps) => {
  const [isShow, setIsShow] = useState(false)
  const ref = useRef<null | HTMLDivElement>(null)

  const outSideClickHandler = (e: any) => {
    e.stopPropagation()
    if (ref.current && !ref.current.contains(e.target)) setIsShow(false)
  }

  useEffect(() => {
    if (isShow) {
      document.addEventListener('click', outSideClickHandler, false)
      document.addEventListener('touchend', outSideClickHandler, false)
    }
    return () => {
      if (isShow) {
        document.removeEventListener('click', outSideClickHandler, false)
        document.removeEventListener('touchend', outSideClickHandler, false)
      }
    }
  }, [isShow])

  return (
    <div className="wrapper-date-picker" ref={ref}>
      <InputField
        type="date"
        sized={sized}
        shadow={shadow && shadow}
        value={value}
        onChange={onChange}
        iconName="calendar"
        iconPosition={IconPosition.right}
        onClick={() => setIsShow(!isShow)}
        onPressEnter={onPressEnter}
      />
      {isShow && (
        <CalendarMemoized
          value={value}
          onChange={onChange}
          size={CalendarSize.md}
        />
      )}
    </div>
  )
}

export default DatePicker
