import React, { useMemo } from 'react'
import { IIngredients } from '../../core/api/dto/DishObjDto'

interface IPropsTableProducts {
  ingredients: IIngredients[]
  differences: string
}

const isChange = (ingredient: IIngredients, differences: string) =>
  differences.includes(JSON.stringify(ingredient))

const TableProducts = ({ ingredients, differences }: IPropsTableProducts) => {
  const total = useMemo(() => {
    if (ingredients.length) {
      return ingredients.reduce((acc, item) => (acc += Number(item.weight)), 0)
    }
  }, [ingredients])

  return (
    <>
      {ingredients.length ? (
        <div className="table-products">
          <div className="tr thead">
            <div className="name">Наименование:</div>
            <div className="count">Нетто:</div>
            <div className="count">Готовое:</div>
          </div>
          {ingredients.map((el, id) => (
            <div className="tr" key={`item-table-${id}`}>
              <div
                className={`name ${
                  differences.length && isChange(el, differences) ? 'new' : ''
                }`}
              >
                {el.name}
              </div>
              <div className="count">{el.netto}г</div>
              <div className="count">{el.weight}г</div>
            </div>
          ))}
          <div className="tr  bold">
            <div className="name">итого:</div>
            <div className="count"></div>
            <div className="count">{total}г</div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TableProducts
