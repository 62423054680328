import React from 'react'
import { IVersionDish } from '../../core/api/dto/DishObjDto'
import AppBtn, { AppBtnColor, AppBtnSize } from '../btn/AppBtn'
import moment from 'moment'

interface IPropsVersionsCard {
  setVersion: (val: number) => void
  version: null | number
  versions: IVersionDish[]
}

const VersionsCard = ({
  versions,
  setVersion,
  version,
}: IPropsVersionsCard) => {
  const getData = (num: number) => moment(num * 1000).format('DD.MM.YY')

  return (
    <div className="sidebar-versions column">
      {versions.map((el) => (
        <AppBtn
          sized={AppBtnSize.sm}
          color={
            version === el.version
              ? AppBtnColor.violetLightWhite
              : AppBtnColor.outlineViolet
          }
          title={getData(el.timestamp)}
          key={el.timestamp}
          onClick={() => setVersion(el.version)}
        />
      ))}
    </div>
  )
}

export default VersionsCard
