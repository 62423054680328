import { api } from './BaseApi'
import { IOrderLine } from './dto/PlanMenuDto'
import {
  IPlanMenuTemplateBase,
  IPlanMenuTemplateDto,
  IPlanMenuTemplateRequest,
  IPlanMenuTemplateResponse,
} from './dto/PlanMenuTemplateDto'

export const planMenuTemplateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlanMenuTemplateList: builder.query<
      IPlanMenuTemplateResponse,
      IPlanMenuTemplateRequest
    >({
      query: (params) => ({
        url: '/plan_menu_pattern/',
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenuTemplate', 'ВuffetDish'],
    }),
    getPlanMenuTemplateDetail: builder.query<
      IPlanMenuTemplateDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/plan_menu_pattern/${id}/`,
      }),
      providesTags: ['PlanMenuTemplate', 'ВuffetDish'],
    }),
    createPlanMenuTemplate: builder.mutation<
      IPlanMenuTemplateDto,
      IPlanMenuTemplateBase
    >({
      query: (body) => ({
        method: 'POST',
        url: '/plan_menu_pattern/',
        body,
      }),
      invalidatesTags: ['PlanMenuTemplate'],
    }),
    addedPlanMenuTemplateDishList: builder.mutation<
      IPlanMenuTemplateDto,
      { id: number; body: IOrderLine[] }
    >({
      query: ({ id, body }) => ({
        method: 'POST',
        url: `/plan_menu_pattern_line/create_or_update_multiple_pattern_lines/${id}/`,
        body,
      }),
      invalidatesTags: ['PlanMenuTemplate'],
    }),
    deletePlanMenuTemplateList: builder.mutation<
      IPlanMenuTemplateDto,
      { ids: number[] }
    >({
      query: ({ ids }) => ({
        method: 'POST',
        url: `/plan_menu_pattern/delete_multiple_plan_menu_patterns/`,
        body: { ids },
      }),
      invalidatesTags: ['PlanMenuTemplate'],
    }),
    deletePlanMenuTemplateDishList: builder.mutation<
      IPlanMenuTemplateDto,
      { id: number; lineGuids: string[] }
    >({
      query: ({ id, lineGuids }) => ({
        method: 'POST',
        url: `/plan_menu_pattern_line/delete_multiple_pattern_lines/${id}/`,
        body: { lineGuids },
      }),
      invalidatesTags: ['PlanMenuTemplate'],
    }),
    updatePlanMenuTemplate: builder.mutation<
      IPlanMenuTemplateDto,
      { id: number; data: IPlanMenuTemplateBase }
    >({
      query: ({ id, data }) => ({
        method: 'PATCH',
        url: `/plan_menu_pattern/${id}/`,
        body: data,
      }),
      invalidatesTags: ['PlanMenuTemplate'],
    }),
  }),
})

export const {
  useGetPlanMenuTemplateListQuery,
  useGetPlanMenuTemplateDetailQuery,
  useAddedPlanMenuTemplateDishListMutation,
  useCreatePlanMenuTemplateMutation,
  useDeletePlanMenuTemplateListMutation,
  useDeletePlanMenuTemplateDishListMutation,
  useUpdatePlanMenuTemplateMutation,
} = planMenuTemplateApi
