import React, { useCallback, useEffect, useState } from 'react'
import InputField, { InputShadow } from '../../input/InputField'
import { Outlet, useNavigate, useParams } from 'react-router'
import { useGetAllOrdersByDateQuery } from '../../../core/api/OrderApi'
import OrderCardAdmin from '../../orderCard/OrderCardAdmin'
import DatePicker from '../../datePicker/DatePicker'
import Spinner, {
  SpinnerColor,
  SpinnerPosition,
  SpinnerSize,
} from '../../spinner/Spinner'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../btn/AppBtn'
import moment from 'moment'
import { FormatDate } from '../../calendar/Calendar'

const OrdersColumn = () => {
  const { dateOrders, orderId } = useParams()
  const [date, setDate] = useState(dateOrders!)
  const [searchString, setSearchString] = useState('')
  const { data: allOrders, isFetching } = useGetAllOrdersByDateQuery(
    {
      date: dateOrders!,
      searchString,
    },
    { skip: !dateOrders },
  )

  const navigate = useNavigate()

  useEffect(() => {
    if (allOrders?.length && !orderId && !isFetching) {
      navigate(`${allOrders[0].id}`)
    } else if (orderId && !isFetching) {
      const res = allOrders?.find((el) => el.id === +orderId)
      !res && navigate('./')
    }
  }, [orderId, allOrders, isFetching, navigate])

  const onClickHandler = useCallback(() => {
    const currentDate = moment().format(FormatDate.BASE_DATE)

    if (
      (date === currentDate && date !== dateOrders) ||
      !moment(date).isSameOrAfter(currentDate)
    ) {
      setSearchString('')
      navigate(`../${date}`)
    } else {
      setDate(dateOrders!)
    }
  }, [date, dateOrders, navigate])

  return (
    <div className="row">
      <div className="col-xl-4 col-lg-6 col-scroll">
        <InputField
          placeholder="Например: стейк"
          iconName="search"
          value={searchString}
          onChange={setSearchString}
        />
        <div className="wrapper-filter-orders">
          <DatePicker
            value={date}
            onChange={setDate}
            shadow={InputShadow.gray}
            onPressEnter={onClickHandler}
          />
          <AppBtn
            title="Применить"
            color={
              dateOrders === date
                ? AppBtnColor.violetLightWhite
                : AppBtnColor.violetWhite
            }
            sized={AppBtnSize.md}
            onClick={onClickHandler}
          />
        </div>
        {allOrders && !isFetching ? (
          <div className="column mt-10">
            {allOrders.map((el, idx) => (
              <OrderCardAdmin
                info={el}
                key={`order-card-admin-${el.id}-${idx}`}
              />
            ))}
          </div>
        ) : (
          <div className="mt-10 h-100">
            <Spinner
              size={SpinnerSize.md}
              color={SpinnerColor.violet}
              spinnerPosition={SpinnerPosition.center}
            />
          </div>
        )}
      </div>
      <Outlet />
    </div>
  )
}

export default OrdersColumn
