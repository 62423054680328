import React, { memo } from 'react'
import { IOrderLine } from '../../../core/api/dto/OrderObjDto'
import { compareProps } from '../../../utils'
import { isGivenOutDish, renderStatusDish } from './MealItemWaiter'

interface IMealItemWaiterMobileProps {
  info: IOrderLine
}

const MealItemWaiterMobile = ({ info }: IMealItemWaiterMobileProps) => {
  return (
    <div
      className={`meal-item ${
        isGivenOutDish(info.quantity, info.orderLineDimensions.length)
          ? 'ready'
          : ''
      }`}
    >
      <div className="name-dish">
        <div>
          {isGivenOutDish(info.quantity, info.orderLineDimensions.length)
            ? `${info.quantity}`
            : `${info.orderLineDimensions.length} из ${info.quantity}`}
        </div>
        <div>{info.dish.name}</div>
      </div>
      {renderStatusDish(info)}
    </div>
  )
}

const MealItemWaiterMobileMemoized = memo(MealItemWaiterMobile, compareProps)
export default MealItemWaiterMobileMemoized
