import React, { useCallback, useEffect, useState } from 'react'
import { IHeaderData } from '../AppTable'
import { useSearchParams } from 'react-router-dom'

const moveItem = (
  headerData: IHeaderData[],
  fromIndex: number,
  toIndex: number,
) => {
  const newArray = [...headerData]
  const [movedItem] = newArray.splice(fromIndex, 1)
  newArray.splice(toIndex, 0, movedItem)
  return newArray
}

interface TableHeaderDndBlockProps {
  chooseMode?: boolean
  subTableRepresent?: boolean
  importantMode?: boolean
  ordering: string | null
  headerData: IHeaderData[]
  setHeaderData: (value: IHeaderData[]) => void
  setCurrentSortFn?: (value: string) => void
}

export const TableHeaderDndBlock = ({
  headerData,
  setHeaderData,
  subTableRepresent,
  chooseMode,
  ordering,
  setCurrentSortFn,
  importantMode,
}: TableHeaderDndBlockProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentDataTitle, setCurrentDataTitle] = useState<IHeaderData | null>(
    null,
  )

  useEffect(() => {
    if (!ordering) {
      setActiveSort({ name: '', activeField: '' })
    }
  }, [ordering])

  const sortStartValue = ordering
    ? { name: ordering.replace('-', ''), activeField: ordering }
    : null
  const [activeSort, setActiveSort] = useState<{
    name: string
    activeField: string
  } | null>(sortStartValue)

  const onSortHandler = (name: string) => {
    const sortValue =
      activeSort?.name === name
        ? activeSort?.activeField === name
          ? `-${name}`
          : name
        : name
    setActiveSort({
      name,
      activeField: sortValue,
    })
    if (setCurrentSortFn) {
      setCurrentSortFn(sortValue)
    }
    if (!setCurrentSortFn) {
      searchParams.set('ordering', sortValue)
      setSearchParams(searchParams.toString())
    }
  }

  const dragStartHandler = useCallback(
    (e, title: IHeaderData) => setCurrentDataTitle(title),
    [currentDataTitle],
  )
  const dragEndHandler = useCallback(
    (e) => (e.target.style.opacity = '1'),
    [currentDataTitle],
  )
  const dragOverHandler = useCallback(
    (e) => {
      e.preventDefault()
      e.target.style.opacity = '.3'
    },
    [currentDataTitle],
  )
  const dropHandler = useCallback(
    (e, title: IHeaderData) => {
      e.preventDefault()
      const fromIndex = headerData.findIndex(
        (th) => th.title === currentDataTitle?.title,
      )
      const toIndex = headerData.findIndex((th) => th.title === title.title)

      if (fromIndex !== -1 && toIndex !== -1) {
        setHeaderData(moveItem(headerData, fromIndex, toIndex))
        localStorage.setItem(
          'header-data-application-table',
          JSON.stringify(moveItem(headerData, fromIndex, toIndex)),
        )
      }

      e.target.style.opacity = '1'
    },
    [currentDataTitle, headerData],
  )

  return (
    <thead>
      <tr>
        {subTableRepresent && <th />}
        {chooseMode && <th />}
        {importantMode && <th />}
        {headerData.map((th, index) => {
          return (
            <th
              key={`th-table-${index}`}
              className={
                th.sort || !!th.order || th.order === 0 ? 'c-pointer' : ''
              }
              onClick={th.sort ? () => onSortHandler(th.sort!) : undefined}
              draggable={!!th.order || th.order === 0}
              onDragStart={(e) => dragStartHandler(e, th)}
              onDragLeave={dragEndHandler}
              onDragEnd={dragEndHandler}
              onDragOver={dragOverHandler}
              onDrop={(e) => dropHandler(e, th)}
            >
              <div className={'thead-th'}>
                <span className={'order-block'}>
                  {(!!th.order || th.order === 0) && (
                    <i className="an-ico an-ico-dnd-dots" />
                  )}
                  {th.title}
                </span>
                {!!th.sort && (
                  <i
                    className={`an-ico an-ico-arrow-down-bg ico-sort ${
                      activeSort?.name === th.sort
                        ? activeSort?.activeField === `-${th.sort}`
                          ? 'active-sort asc'
                          : 'active-sort'
                        : ''
                    }`}
                  />
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
