import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../core/store/store'
import { deleteCredentials } from '../../core/store/auth/authSlice'
import { useLogoutMutation } from '../../core/api/BaseApi'
import { useDeleteFCMTokenMutation } from '../../core/api/FCMTokenApi'
import { setFCMToken } from '../../core/store/fcmToken/fcmTokenSlice'
import { useNavigate } from 'react-router'

export const LogoutPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [logout] = useLogoutMutation()
  const [deleteFCMToken] = useDeleteFCMTokenMutation()
  const { token } = useAppSelector((state) => state.fcmToken)
  const currentRole = useAppSelector((state) => state.foodBoxAuth.currentRole)

  useEffect(() => {
    if (token) {
      deleteFCMToken({ token })
        .then(() => dispatch(setFCMToken('')))
        .finally(() => navigate('/'))
    } else {
      logout()
        .then(() => dispatch(deleteCredentials()))
        .finally(() => navigate('/'))
    }
  }, [currentRole, dispatch, logout, deleteFCMToken, token, navigate])
  return <div />
}
