import React, { useEffect, useState } from 'react'
import Popup, { PopupSize } from '../../../components/popup/Popup'
import { AppInput } from '../../../components/AppInput/AppInput'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import {
  useAddCategoryMutation,
  useGetCategoryByIdQuery,
  useUpdateCategoryMutation,
} from '../../../core/api/CategoryApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useGetCurrentUserQuery } from '../../../core/api/UserApi'
import { useTranslation } from 'react-i18next'

interface IAddCategoryPopupProps {
  id?: number | null
  closePopup: () => void
}

export const AddCategoryPopup = ({
  closePopup,
  id,
}: IAddCategoryPopupProps) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [error, setError] = useState('')
  const { data: currentUser } = useGetCurrentUserQuery()
  const { data: currentCategory } = useGetCategoryByIdQuery(id ? id : 0, {
    skip: !id,
  })
  const [createCategory, { isLoading: isLoadingCreate }] =
    useAddCategoryMutation()
  const [updateCategory, { isLoading: isLoadingUpdate }] =
    useUpdateCategoryMutation()

  useEffect(() => {
    if (!!currentCategory) {
      setTitle(currentCategory.name)
    }
  }, [currentCategory])

  const onSaveHandler = () => {
    if (!title) {
      setError('Обязательное поле')
      return
    }
    if (!!id) {
      updateCategory({ id, name: title })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Категория успешно обновлена!'),
            type: NotificationType.success,
          })
          closePopup()
        })
    } else {
      createCategory({
        name: title,
        restaurant: currentUser?.userRoles?.restaurant?.id,
      })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Категория успешно создана!'),
            type: NotificationType.success,
          })
          closePopup()
        })
    }
  }

  return (
    <Popup
      size={PopupSize.base}
      borderRadiusMin
      closePopup={closePopup}
      isLoading={isLoadingCreate || isLoadingUpdate}
    >
      <div className="category-popup">
        <h3>{t(`${!!id ? 'Редактирование' : 'Создание'} категории блюда`)}</h3>
        <AppInput
          value={title}
          onChange={(value) => {
            setError('')
            setTitle(value)
          }}
          label="Название"
          border
          fullWidth
          error={error ? error : undefined}
        />
        <div className="buttons-block">
          <AppBtn
            title={'Отменить'}
            color={AppBtnColor.outlineViolet}
            iconClassName={'close'}
            sized={AppBtnSize.md}
            onClick={closePopup}
          />
          <AppBtn
            iconClassName={'save'}
            title={'Сохранить'}
            sized={AppBtnSize.md}
            color={AppBtnColor.violetWhite}
            onClick={onSaveHandler}
            isLoading={isLoadingCreate || isLoadingUpdate}
          />
        </div>
      </div>
    </Popup>
  )
}
