import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetCategoryByRestaurantQuery } from '../../core/api/CategoryApi'
import { AllRoutes } from '../../core/routes/AllRoutes'
import AddCategoryPopup from '../popup/AddCategoryPopup'
import NavSidebarItem from './NavSidebarItem'

const NavSidebar = () => {
  const [isShowPopup, setIsShowPopup] = useState(false)
  const { technologicalCatalogRestaurantId } = useParams()
  const { data: allCategories } = useGetCategoryByRestaurantQuery(
    technologicalCatalogRestaurantId!,
    { skip: !technologicalCatalogRestaurantId },
  )

  return (
    <div className="sidebar">
      <h1 className="title">
        <span>Food</span>Box<span></span>
      </h1>
      <div className="column sidebar-nav">
        <nav>
          <NavSidebarItem
            name="Все категориии"
            path={AllRoutes.technologicalMapAllSearchCards.path}
          />
          <NavSidebarItem
            name="Без категорий"
            path={AllRoutes.technologicalMapCatalogWithoutCategory.path}
          />
          {!!allCategories?.results.length &&
            allCategories.results.map((el) => (
              <NavSidebarItem el={el} key={`item-category-${el.id}`} />
            ))}
          <div className="item-category" onClick={() => setIsShowPopup(true)}>
            <div className="wrapper-icon">
              <i className="an-ico an-ico-add" />
            </div>
            <span>Добавить категорию</span>
          </div>
        </nav>
      </div>
      {isShowPopup && <AddCategoryPopup setIsShowPopup={setIsShowPopup} />}
    </div>
  )
}

export default NavSidebar
