import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyboardInitialState } from './keyboardState'

const keyboardSlice = createSlice({
  name: 'foodBoxKeyboard',
  initialState: keyboardInitialState,
  reducers: {
    setIsKeyboard: (
      state,
      {
        payload: { isKeyboard },
      }: PayloadAction<{
        isKeyboard: boolean
      }>,
    ) => {
      state.isKeyboard = isKeyboard
    },
  },
})

export const { setIsKeyboard } = keyboardSlice.actions
export const { reducer: KeyboardReducer } = keyboardSlice