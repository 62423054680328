import React, { useState } from 'react'
import './EditTaskEditComment.scss'
import { useGetUsersListQuery } from '../../../../../../core/api/UserApi'
import { IUserResponseDto } from '../../../../../../core/api/dto/UserDto'
import { AppPopoverList } from '../../../../../../components/AppPopover/AppPopoverList'
import {
  ICreateTasksComment,
  ITaskObjectDto,
  ITasksCommentDto,
} from '../../../../../../core/api/dto/TaskDto'
import {
  useCreateTaskCommentMutation,
  useGetTaskObjectsQuery,
} from '../../../../../../core/api/TaskApi'
import {
  AppNotification,
  NotificationType,
} from '../../../../../../components/notification/Notification'
import {
  AppInput,
  TextareaInputRowsType,
} from '../../../../../../components/AppInput/AppInput'

interface EditTaskEditCommentProps {
  taskId: number
  setNewComment: React.Dispatch<React.SetStateAction<ITasksCommentDto | null>>
}

export const EditTaskEditComment = ({
  taskId,
  setNewComment,
}: EditTaskEditCommentProps) => {
  const [text, setText] = useState<string>('')
  const [users, setUsers] = useState<IUserResponseDto[]>([])
  const [taskObj, setTaskObj] = useState<ITaskObjectDto[]>([])

  const [createComment] = useCreateTaskCommentMutation()

  const createCommentHandler = () => {
    if (text) {
      const newComment: ICreateTasksComment = {
        text,
        task: taskId,
      }
      if (users.length) newComment.mentionedUsers = users.map(({ id }) => id)
      if (taskObj.length) newComment.taskObjects = taskObj.map(({ id }) => id)

      createComment(newComment)
        .unwrap()
        .then((data) => {
          setNewComment(data)
          setText('')
          setUsers([])
          setTaskObj([])
          AppNotification({
            msg: 'Комментарий успешно отправлен!',
            type: NotificationType.success,
          })
        })
    }
  }
  const clearValueHandler = () => {
    setText('')
    setTaskObj([])
    setUsers([])
  }

  return (
    <>
      <label>Комментарий</label>
      <div className={'edit-task-edit-comment-input-wrapper'}>
        <AppInput
          type={'textarea'}
          value={text}
          onChange={setText}
          rows={TextareaInputRowsType['rows-1']}
          maxLength={200}
        />
        <div className="user-list">
          {users.map((user) => (
            <div key={user.id} className={'user-item'}>
              @{user.name}
            </div>
          ))}
        </div>
        <div className="user-list">
          {taskObj.map((task) => (
            <div key={task.id} className={'user-item'}>
              {task.title}
            </div>
          ))}
        </div>
        <div className="edit-btns-block">
          <div className="ico-row">
            <PopoverTaskList
              taskObj={taskObj}
              onChange={setTaskObj}
              taskId={taskId}
            />
            <PopoverUserList value={users} onChange={setUsers} />
          </div>
          <div className="btn-row">
            <div className="cansel-btn" onClick={clearValueHandler}>
              <i className="an-ico an-ico-close" />
            </div>
            <div
              className={`send-btn${text ? '' : ' disabled'}`}
              onClick={createCommentHandler}
            >
              <i className="an-ico an-ico-arrow-send" />
              <span>Отправить</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface IPopoverTaskListProps {
  taskObj: ITaskObjectDto[]
  onChange: React.Dispatch<React.SetStateAction<ITaskObjectDto[]>>
  taskId: number
}

const PopoverTaskList = ({
  taskObj,
  onChange,
  taskId,
}: IPopoverTaskListProps) => {
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)

  const { data: taskObjects } = useGetTaskObjectsQuery(
    { task: taskId, search: search || undefined, offset, limit: 20 },
    { skip: !taskId },
  )

  return (
    <AppPopoverList
      data={taskObjects?.results || []}
      prevPage={!!taskObjects?.previous}
      nextPage={!!taskObjects?.next}
      setOffset={setOffset}
      onChange={onChange}
      value={taskObj}
      propValue="id"
      propName="title"
      subtitle="Наименование"
      trigger={<i className="an-ico an-ico-add" />}
      placeholder="Укажите наименование"
      setSearchDebounce={setSearch}
    />
  )
}

interface PopoverUserListProps {
  value: IUserResponseDto[]
  onChange: React.Dispatch<React.SetStateAction<IUserResponseDto[]>>
}

const PopoverUserList = ({ value, onChange }: PopoverUserListProps) => {
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)

  const { data: usersList } = useGetUsersListQuery(
    { offset, limit: 20, search },
    { skip: !search },
  )

  return (
    <AppPopoverList
      data={usersList?.results || []}
      prevPage={!!usersList?.previous}
      nextPage={!!usersList?.next}
      setOffset={setOffset}
      onChange={onChange}
      value={value}
      propValue="id"
      propName="name"
      subtitle="Пользователи"
      trigger={<i className="an-ico an-ico-at" />}
      placeholder="@Пользователь"
      searchDebounce={search}
      setSearchDebounce={setSearch}
    />
  )
}
