import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import { FileUploadDishPopup } from './components/FileUploadDishPopup'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import { useNavigate } from 'react-router'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import {
  useArchiveMultipleBuffetDishMutation,
  useGetBuffetDishQuery,
  useGetBuffetDishSimpleListQuery,
  useGetLoadBuffetDishesFromShQuery,
} from '../../../core/api/BuffetDishApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import { IBuffetDishesRequest } from '../../../core/api/dto/BuffetDishDto'
import { AppFilterDropdown } from '../../../components/AppDropdown/AppFilterDropdown'
import { useSearchParams } from 'react-router-dom'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { useGetCategoryQuery } from '../../../core/api/CategoryApi'
import { ICategory } from '../../../core/api/dto/CategoriesObjDto'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { setFullTime } from './CreateDishPage'
import { IBaseSimpleList } from '../../../core/api/dto/BaseDto'
import { GetFile } from '../../../utils/getFile'
import { BASE_URL } from '../../../core/api/BaseApi'
import { createQueryString } from '../../../utils/createQueryString'
import { useDeleteDebounce } from '../../../utils/useDeleteDebounce'
import { AppTableImageViewer } from '../../../components/AppTableImageViewer/AppTableImageViewer'
import { ImagePreviewWrapperSizeEnum } from 'UiKitComponents/ImagePreviewWrapper/ImagePreviewWrapper'
import {
  AppTableAlignType,
  AppTableLink,
} from '../../../components/AppTable/components/AppTableLink'
import { AppTableNumberBlock } from '../../../components/AppTable/components/AppTableNumberBlock'
import { AppTableMobile } from '../../../components/AppTable/AppTableMobile'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'

const headerData = [
  { title: 'Наименование', colWidth: '20%', sort: 'name' },
  { title: 'Категория', colWidth: '15%', sort: 'category__name' },
  { title: 'Статус', colWidth: '8%', sort: 'archived' },
  { title: 'Вес Эталонный, кг', colWidth: '10%', sort: 'standard_weight' },
  {
    title: 'Отклонение эталонного веса, кг',
    colWidth: '15%',
    sort: 'standard_deviation',
  },
  {
    title: 'Время реализации, ч:мин',
    colWidth: '12%',
    sort: 'implementation_period',
  },
  {
    title: 'Температура Эталонная, °C',
    colWidth: '10%',
    sort: 'standard_temp_min,standard_temp_max',
  },
  { title: 'Эталонное фото', colWidth: '5%' },
]

const archivedFilterData = [
  { name: 'Активно', value: 0 },
  { name: 'Удалено', value: 1 },
]

export const DishesPage = () => {
  const { t } = useTranslation()
  const [, setSearchParams] = useSearchParams()
  const { width } = useGetWidth();
  const pageParams = useParamsControl<
    IBuffetDishesRequest,
    keyof IBuffetDishesRequest
  >({
    withPagination: true,
    whiteList: ['search', 'archived', 'ordering', 'category', 'ids'],
  })
  const {
    data: buffetDishesData,
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
  } = useGetBuffetDishQuery(
    {
      ...pageParams,
      ordering: !!pageParams?.ordering
        ? pageParams?.ordering.replace(
            '-standard_temp_min,standard_temp_max',
            '-standard_temp_min,-standard_temp_max',
          )
        : undefined,
    },
    { skip: !pageParams },
  )
  const [isLoadDishesFromSh, setIsLoadDishesFromSh] = useState(false)
  const { data: loadBuffetDishesFromSh } = useGetLoadBuffetDishesFromShQuery(
    null,
    { skip: !isLoadDishesFromSh, refetchOnMountOrArgChange: true },
  )
  const [archiveMultipleBuffetDish] = useArchiveMultipleBuffetDishMutation()
  const navigate = useNavigate()
  const [uploadFilePopup, setUploadFilePopup] = useState<boolean>(false)
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false)
  const deleteDish = () => {
    if (!!chooseItems) {
      archiveMultipleBuffetDish({
        dishes: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>{
          AppNotification({
            msg: t('Выбранные блюда успешно удалены!'),
            type: NotificationType.success,
          });
          setChooseItems(null)}
        )
    }
  }

  const [onClickHandler] = useDeleteDebounce({ fn: deleteDish })

  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]
  const clearFilterHandler = useCallback(() => {
    setSearchParams('limit=40')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openUploadFilePopupHandler = useCallback(() => {
    setUploadFilePopup(true)
  }, [])

  const unloadDishesHandler = () => {
    setIsLoadingLocal(true)
    const params = createQueryString(pageParams)
    GetFile(`${BASE_URL}/buffet_dish/export_dishes_to_file/${params}`)
      .then(() =>
        AppNotification({
          msg: t('Выбранные блюда успешно выгружены!'),
          type: NotificationType.success,
        }),
      )
      .finally(() => setIsLoadingLocal(false))
  }

  useEffect(() => {
    setSearchParams((sp) => {
      sp.set('archived', '0')
      return sp
    })
  }, [])

  useEffect(() => {
    if (loadBuffetDishesFromSh && isLoadDishesFromSh) {
      AppNotification({
        msg: loadBuffetDishesFromSh.detail,
        type: NotificationType.success,
      })
    }
    setIsLoadDishesFromSh(false)
  }, [loadBuffetDishesFromSh, isLoadDishesFromSh])

  const setIsLoadDishesFromShHandler = useCallback(
    () => setIsLoadDishesFromSh(true),
    [],
  )

  const [isFiltersOpen, setIsFiltersOpen] = useState(true)

  const actions = useMemo(
    () => [
      {
        btnTitle: '',
        btnIco: 'reload',
        sized: AppBtnSize.onlyIco,
        onClick: setIsLoadDishesFromShHandler,
        color: AppBtnColor.violetWhite,
      },
      {
        btnTitle: 'Загрузить',
        btnIco: 'import',
        onClick: openUploadFilePopupHandler,
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Выгрузить',
        btnIco: 'export',
        onClick: unloadDishesHandler,
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Добавить блюдо',
        btnIco: 'add',
        onClick: () => navigate(AllRoutes.addDishes.path),
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Сбросить фильтр',
        onClick: clearFilterHandler,
        color: AppBtnColor.extraVioletViolet,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, pageParams, isFiltersOpen],
  )
  if (!buffetDishesData) {
    return null
  }

  return (
    <React.Fragment>
      {isLoadingLocal && <AppLoader loaderType={LoaderType.main} />}
      {uploadFilePopup && (
        <FileUploadDishPopup setActive={setUploadFilePopup} />
      )}
      <div className={'width-for-page-wrapper'}>
        <AppTitlePage
          title="Блюда"
          actions={actions}
          isFiltersOpen={isFiltersOpen}
          setIsFiltersOpen={setIsFiltersOpen}
        />
        {isFiltersOpen && (
          <div className="filter-block mt-20">
            <div className={width <= 500 ? 'col-4' : ''}>
              <AppFilterMultiDropdownWithPagination<
                IBaseSimpleList,
                keyof IBaseSimpleList
              >
                searchParam={'ids'}
                id={'ids'}
                getterData={useGetBuffetDishSimpleListQuery}
                propToShowInList={'name'}
                valuePropName={'id'}
                label={'Названия блюд'}
                placeholder={'Выбрать'}
                fullWidth
              />
            </div>
            <AppFilterMultiDropdownWithPagination<ICategory, keyof ICategory>
              searchParam={'category'}
              id={'-category'}
              getterData={useGetCategoryQuery}
              propToShowInList={'name'}
              valuePropName={'id'}
              label={'Категория'}
              placeholder={'Выбрать'}
            />
            <AppFilterDropdown
              withTranslate
              id={'-archived'}
              searchParam={'archived'}
              data={archivedFilterData}
              valuePropName={'value'}
              propToShowInList={'name'}
              label={'Статус'}
              placeholder={'Выбрать'}
              minHeight
            />
          </div>
        )}
        <AppTableMobile
          data={buffetDishesData}
          headerData={headerData}
          chooseActions={tableActions}
          chooseMode
          chooseItems={chooseItems}
          setChooseItems={setChooseItems}
          idName={'dish'}
          tableDataSelectors={[
            {
              renderItem: (item) => {
                return (
                  <AppTableLink path={`${item.id}`} deleteItem={item.archived}>
                    <div className="clip-base">{item.name}</div>
                  </AppTableLink>
                )
              },
            },
            {
              renderItem: (item) => {
                return (
                  <AppTableLink path={`${item.id}`} deleteItem={item.archived}>
                    {item?.category?.name}
                  </AppTableLink>
                )
              },
            },
            {
              renderItem: (item) => {
                return item.archived ? (
                  <div className={`table-status delete`}>{t('Удалённый')}</div>
                ) : (
                  <AppTableLink path={`${item.id}`} deleteItem={item.archived}>
                    <div className={`table-status`}>{t('Активный')}</div>
                  </AppTableLink>
                )
              },
            },
            {
              renderItem: (item) => {
                return (
                  <AppTableLink
                    path={`${item.id}`}
                    deleteItem={item.archived}
                    align={AppTableAlignType.right}
                  >
                    <AppTableNumberBlock
                      value={item?.standardWeight?.toFixed(3) || ''}
                    />
                  </AppTableLink>
                )
              },
            },
            {
              renderItem: (item) => {
                return (
                  <AppTableLink
                    path={`${item.id}`}
                    deleteItem={item.archived}
                    align={AppTableAlignType.right}
                  >
                    <AppTableNumberBlock
                      value={item?.standardDeviation?.toFixed(3) || ''}
                    />
                  </AppTableLink>
                )
              },
            },
            {
              renderItem: (item) => {
                return (
                  <AppTableLink
                    path={`${item.id}`}
                    deleteItem={item.archived}
                    align={AppTableAlignType.right}
                  >
                    {`${
                      item.implementationPeriodRepresent
                        ? setFullTime(item.implementationPeriodRepresent)
                        : ''
                    }`}
                  </AppTableLink>
                )
              },
            },
            {
              renderItem: (item) => {
                return (
                  <AppTableLink
                    path={`${item.id}`}
                    deleteItem={item.archived}
                    align={AppTableAlignType.right}
                  >
                    <React.Fragment>
                      <AppTableNumberBlock
                        isTemp
                        value={item.standardTempMin ?? ''}
                      />{' '}
                      <span className="pl-4 pr-4"> - </span>
                      <AppTableNumberBlock
                        isTemp
                        value={item.standardTempMax ?? ''}
                      />
                    </React.Fragment>
                  </AppTableLink>
                )
              },
            },
            {
              renderItem: (item) => {
                return (
                  <AppTableImageViewer
                    imgPreview={item.imagePreview}
                    image={item.image}
                    imageName={item.name}
                    archived={item.archived}
                    size={ImagePreviewWrapperSizeEnum.MEDIUM}
                  />
                )
              },
            },
          ]}
          isLoading={isLoadingGet || isFetchingGet}
        />
      </div>
    </React.Fragment>
  )
}
