import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { buffetInitialState, TPayloadChangedMode } from './buffetState'

const buffetSlice = createSlice({
  name: 'buffetMode',
  initialState: buffetInitialState,
  reducers: {
    changeField: (
      state,
      { payload: { value, field } }: PayloadAction<TPayloadChangedMode>,
    ) => {
      state[field] = value
    },
  },
})

export const { changeField } = buffetSlice.actions
export const { reducer: BuffetReducer } = buffetSlice
