type CallbackFunction = () => void

class ValidationController {
  subscribers: CallbackFunction[] = []
  subscribe = (fn: CallbackFunction) => {
    this.subscribers.push(fn)
    return () => {
      this.subscribers = this.subscribers.filter((el) => el !== fn)
    }
  }
  validate = () => {
    this.subscribers.forEach((fn) => fn())
  }
}

export const controller = new ValidationController()
