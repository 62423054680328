import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IOrderLineDimension } from '../../../core/api/dto/OrderObjDto'
import { scrollLeft, scrollRight } from '../../../utils'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../btn/AppBtn'
import Popup from '../../popup/Popup'

interface IPropsPhotoItem {
  dimensions: IOrderLineDimension[]
  nameDish: string
}

const DimensionPhotos = ({ dimensions, nameDish }: IPropsPhotoItem) => {
  const [urlPhoto, setUrlPhoto] = useState('')
  const ref = useRef<HTMLDivElement | null>(null)
  const [idx, setIdx] = useState(0)

  const handlerScrollLeft = useCallback(() => {
    if (!idx) return

    setIdx(idx - 1)
    scrollLeft(ref)
  }, [idx])

  const handlerScrollRight = useCallback(() => {
    if (idx === dimensions.length - 1) return

    setIdx(idx + 1)
    scrollRight(ref)
  }, [idx, dimensions.length])

  useEffect(() => {
    if (dimensions.length) {
      setIdx(0)
    }
  }, [dimensions])

  return (
    <div className="photo-dimensions main-view">
      <div className="photos-header">
        <div className="nav">
          <div className="count">Замер {`${idx + 1}/${dimensions.length}`}</div>
          <div className="btn-block">
            <AppBtn
              color={AppBtnColor.violetWhite}
              iconClassName="arrow-l"
              onClick={handlerScrollLeft}
              sized={AppBtnSize.sm}
            />
            <AppBtn
              color={AppBtnColor.violetWhite}
              iconClassName="arrow-r"
              onClick={handlerScrollRight}
              sized={AppBtnSize.sm}
            />
          </div>
        </div>
        <div className="name-dish">{nameDish}</div>
      </div>
      <div ref={ref} className="dimensions">
        {dimensions.map((el, idx) => (
          <div key={`${idx}-${el.photoUrl}`} className="dimension">
            <div className="picture-dish">
              <div className="name">
                <span>
                  {`Вес без тары: ${el.quantity.toFixed(2)}кг, Общий вес: ${
                    el.weight
                  }кг, Вес тары: ${(el.weight - el.quantity).toFixed(2)}кг`}
                </span>
              </div>
              {el.photoUrl ? (
                <>
                  <div
                    className="wrapper-picture"
                    onClick={() => setUrlPhoto(`${el.photoUrl}`)}
                  >
                    <div className="wrapper-icon">
                      <i className="an-ico an-ico-search"></i>
                    </div>
                    <img src={el.photoUrl} alt="" className="picture" />
                  </div>
                </>
              ) : (
                <div className="no-photo">
                  <i className="an-ico an-ico-photo" />
                  <h4>Фото отсутствует</h4>
                </div>
              )}
            </div>
            {el.thermoPhotoUrl && (
              <div
                className="picture-dish"
                onClick={() => setUrlPhoto(`${el.thermoPhotoUrl}`)}
              >
                <div className="wrapper-picture">
                  <div className="wrapper-icon">
                    <i className="an-ico an-ico-search"></i>
                  </div>
                  <img src={el.thermoPhotoUrl} alt="" className="picture" />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {urlPhoto && (
        <Popup closePopup={() => setUrlPhoto('')}>
          <img src={urlPhoto} alt="" className="img-child-popup" />
        </Popup>
      )}
    </div>
  )
}

export default DimensionPhotos
