import React from 'react'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import { useGetSimpleOrderBuffetQuery } from '../../../core/api/BuffetOrderApi'
import {
  IBuffetOrderDto,
  IBuffetOrdersRequestDto,
} from '../../../core/api/dto/BuffetOrderDto'
import {
  OrderType,
  StatusOrder,
  StatusPlanMenu,
} from '../../../core/api/dto/OrderObjDto'
import moment from 'moment'
import { OrderingByDate } from '../../../core/api/OrderApi'
import WorkshopCard from '../../../components/workshopCard.tsx/WorkshopCard'
import { useParamsControl } from '../../../utils/useParamsControl'
import { useGetCategoriesFromOrdersQuery } from '../../../core/api/CategoryApi'
import { useGetDishesWithImplInfoQuery } from '../../../core/api/BuffetDishApi'
import { DishItem } from './components/DishItem'
import { useTranslation } from 'react-i18next'
import { AppScrollWrapperNew } from '../../../widgets/AppScrollWrapper/AppScrollWrapper'
import { PredictionData } from './components/PredictionData'
import { useGetWidth } from 'utils/useGetWidth'

export const PlanMenuIssuingPage = () => {
  const { t } = useTranslation()
  const { width } = useGetWidth()
  const pageParams = useParamsControl<
    IBuffetOrdersRequestDto,
    keyof IBuffetOrdersRequestDto
  >({
    withPagination: true,
    whiteList: ['search', 'ids'],
  })
  const { data: categoryList } = useGetCategoriesFromOrdersQuery(
    { order_ids: pageParams?.ids || '' },
    { skip: !pageParams?.ids },
  )
  const { data: dishesList } = useGetDishesWithImplInfoQuery(
    { search: pageParams?.search, order_ids: pageParams?.ids || '' },
    { skip: !pageParams?.search || !pageParams?.ids },
  )

  return (
    <React.Fragment>
      <div className="width-for-page-wrapper">
        <div className={`filter-block ${width <= 768 ? '' : 'nowrap'} mt-20`}>
          <div
            className={`${
              width <= 1200 ? (width <= 768 ? 'width-100' : 'col-6') : 'col-3'
            }`}
          >
            <AppFilterMultiDropdownWithPagination<
              IBuffetOrderDto,
              keyof IBuffetOrderDto
            >
              searchParam={'ids'}
              getterData={useGetSimpleOrderBuffetQuery}
              propToShowInList={'name'}
              valuePropName={'id'}
              label={'Название план-меню'}
              placeholder={'Выбрать'}
              defaultValue={true}
              fullWidth
              extraOptions={{
                planMenuStatus: `${StatusPlanMenu.Opened}`,
                order_type: OrderType.Buffet,
                order_current_datetime: moment().format(),
                ordering: -OrderingByDate.ORDER_DATE_START_TIME,
                archived: '0',
              }}
              sparePropToShowInList={'sourceRepresent'}
              extraPropToShowInList={'orderDateEnd'}
            />
          </div>
          <AppFilterInput
            searchParam="search"
            label="Поиск"
            ico="search"
            placeholder="Например: яблоко"
            fullWidth
          />
        </div>
        <PredictionData isIds={!!pageParams?.ids} />
        <div className="close mt-15">
          {!!pageParams?.ids &&
            !!categoryList?.results?.length &&
            !(dishesList?.results?.length && pageParams?.search) && (
              <div className={`row-flex-wrap`}>
                <WorkshopCard
                  title={t('Все блюда')}
                  path={`0/?ids=${pageParams?.ids}`}
                />
                {categoryList.results.map((category) => (
                  <WorkshopCard
                    key={`${category.id}-w-shop`}
                    title={`${category.name}`}
                    path={`${
                      category.id +
                      (pageParams?.ids ? '/?ids=' + pageParams?.ids : '')
                    }`}
                  />
                ))}
              </div>
            )}
        </div>
        {!!dishesList?.results.length && pageParams?.search && (
          <div className="plan-menu-control-manager-page auto-height">
            <AppScrollWrapperNew>
              {dishesList.results.map((dishItem, index) => (
                <DishItem
                  dish={dishItem}
                  key={`${dishItem.id}d-i-m-${index}`}
                />
              ))}
            </AppScrollWrapperNew>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
