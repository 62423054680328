import React, { useRef } from 'react'
import { useGetCreatedOrdersQuery } from '../../core/api/OrderApi'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import HeaderColumn from '../headerColumn/HeaderColumn'
import OrderCardCook from '../orderCard/OrderCardCook'

const PendingOrdersColumn = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { data: currentUser } = useGetCurrentUserQuery()
  const { data: createdOrders } = useGetCreatedOrdersQuery(
    currentUser?.userRoles.restaurant.shiftNum
      ? currentUser.userRoles.restaurant.shiftNum
      : '',
    {
      pollingInterval: 5000,
      skip: !currentUser,
    },
  )

  return (
    <div className="col-xl-3 col-lg-3 col-md-6 col-scroll">
      <HeaderColumn title="В ожидании" refContent={ref} />
      <div className="column mt-10" ref={ref}>
        {createdOrders?.length
          ? createdOrders.map((el) => (
              <OrderCardCook info={el} key={`created-card-${el.id}`} />
            ))
          : null}
      </div>
    </div>
  )
}

export default PendingOrdersColumn
