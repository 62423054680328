import React from 'react'
import BaseHeader from '../widgets/header/BaseHeader'
import BuffetCardByWorkshopMapCatalog from '../components/mainContent/BuffetCardByWorkshopMapCatalog'

const BuffetCardByWorkshop = () => {
  return (
    <main className="buffet-page">
      <BaseHeader />
      <BuffetCardByWorkshopMapCatalog />
    </main>
  )
}

export default BuffetCardByWorkshop
