export interface IFetchError {
  status: string
  data?: string
  error?: string
}

export enum ErrorMsgFields {
  email = 'email',
  code = 'code',
  detail = 'detail',
  image = 'image',
  lineGuids = 'lineGuids',
}
type KeyFields = keyof typeof ErrorMsgFields | number

export type TFormFields = Omit<Record<KeyFields, string[]>, 'detail'>

type TBaseField = Record<ErrorMsgFields.detail, string | string[]>

interface IErrorFormFields {
  status: number
  data: TFormFields | TBaseField
  error?: never
}

export type ErrorBaseResponseDto = IErrorFormFields | IFetchError
