import React, { useCallback, useRef, useState } from 'react'
import {
  AppInputFile,
  AppInputFileColor,
} from '../../../components/AppInput/AppInputFile'
import {
  AppImagePopup,
  IAppImagePopup,
} from '../../../components/AppImagePopup/AppImagePopup'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useOutsideClick } from '../../../utils/useOutsideClick'
import { useTranslation } from 'react-i18next'

interface IDragAndDropUploadPhotoProps {
  fileURL: string | null
  onSetFile: (file: File | null) => void
  setFilePreview: (file: string) => void
  setFileFromCamera?: (value: boolean) => void
  isLoading?: boolean
  setIsDirty?: (value: boolean) => void
  setIsDeletedFile?: (value: boolean) => void
  mt?: boolean
}

export const DragAndDropUploadPhoto = ({
  fileURL,
  onSetFile,
  setFilePreview,
  mt,
  setFileFromCamera,
  isLoading,
  setIsDirty,
  setIsDeletedFile,
}: IDragAndDropUploadPhotoProps) => {
  const { t } = useTranslation()
  const [drag, setDrag] = useState(false)
  const [active, setActive] = useState(false)
  const [imagePopup, setImagePopup] = useState<IAppImagePopup>({
    active: false,
    photo: null,
    title: null,
  })

  const removeFile = useCallback(() => {
    onSetFile(null)
    setFilePreview('')
    setActive(false)
    setIsDeletedFile?.(true)
    setIsDirty && setIsDirty(true)
  }, [])

  const refMenu = useRef<HTMLDivElement | null>(null)

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDrag(true)
  }
  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDrag(false)
  }
  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files
    onSetFile(file[0])
    setDrag(false)
    setIsDirty && setIsDirty(true)
  }

  useOutsideClick(refMenu, () => setActive(false), active)

  const onSetFileHandler = useCallback((file: File | null) => {
    setActive(false)
    setIsDirty && setIsDirty(true)
    onSetFile(file)
  }, [])

  const closeImagePopupHandler = useCallback(() => {
    setImagePopup({ active: false, photo: null, title: null })
  }, [])
  const openImagePopupHandler = useCallback((photo: string, title: string) => {
    setImagePopup({ active: true, photo, title })
  }, [])

  if (!!fileURL) {
    return (
      <React.Fragment>
        {imagePopup.active && imagePopup.photo && (
          <AppImagePopup
            title={imagePopup.title || ''}
            img={imagePopup.photo}
            setActive={closeImagePopupHandler}
          />
        )}
        <div className={`upload-photo-block with-photo ${mt ? 'mt-20' : ''}`}>
          <div
            className="loupe-block"
            onClick={() => openImagePopupHandler(fileURL, '')}
          >
            <i className="an-ico an-ico-search" />
          </div>
          <div
            className="photo-menu-ico"
            onClick={() => setActive((prevState) => !prevState)}
          >
            <i className="an-ico an-ico-dotes-v" />
          </div>
          {active && (
            <div className="photo-menu" ref={refMenu}>
              <div className="photo-menu-item">
                <i className="an-ico an-ico-reload" />
                <AppInputFile
                  onChangeFile={onSetFileHandler}
                  text={t('Заменить фото')}
                  color={AppInputFileColor.gray}
                />
              </div>
              {setFileFromCamera && (
                <div
                  className="photo-menu-item"
                  onClick={() => setFileFromCamera(true)}
                >
                  <i className="an-ico an-ico-camera" />
                  {t('Сфотографировать станцией')}
                </div>
              )}
              {!!setIsDeletedFile && (
                <div className="photo-menu-item" onClick={removeFile}>
                  <i className="an-ico an-ico-basket" />
                  {t('Удалить')}
                </div>
              )}
            </div>
          )}
          <img src={fileURL} alt={'photo'} />
        </div>
      </React.Fragment>
    )
  }

  return (
    <>
      {drag ? (
        <div
          className="upload-photo-block is-draggable"
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
          onDrop={(e) => onDropHandler(e)}
        >
          <span>{t('Отпустите файл, чтобы загрузить его')}</span>
        </div>
      ) : (
        <div
          className={`upload-photo-block ${mt ? 'mt-20' : ''}`}
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
        >
          {isLoading && <AppLoader loaderType={LoaderType.table} />}
          <div className="drag-block-wrap">
            <div className="link-wrap">
              <i className="an-ico an-ico-clip" />
              <div className="link-block">
                <span>
                  {t('Перетащите')} <br /> {t('или')}{' '}
                  <AppInputFile onChangeFile={onSetFile} />
                </span>
                <br />
                <div className="mt-10" />
                <small>
                  {t(
                    'Максимальный размер файла 300 Мбайт. Рекомендуемый размер 800 на 600',
                  )}
                </small>
              </div>
            </div>
            {setFileFromCamera && (
              <div
                className="photo-upload-btn-wrap"
                onClick={() => setFileFromCamera(true)}
              >
                <div className="btn-upload-photo">
                  <i className="an-ico an-ico-camera" />
                  <span>{t('Сфотографировать станцией')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
