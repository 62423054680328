import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParamsControl } from '../../../utils/useParamsControl'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { ShowFormatType } from './BuffetReportsVZ'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createQueryString } from '../../../utils/createQueryString'
import { GetFile } from '../../../utils/getFile'
import { BASE_URL } from '../../../core/api/BaseApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useTranslation } from 'react-i18next'
import { useGetBuffetGeneralReportQuery } from 'core/api/BuffetReportApi'
import {
  IBuffetGeneralReportListDto,
  IBuffetGeneralReportRequest,
  IBuffetGeneralReportResult,
} from 'core/api/dto/BuffetGeneralReportDto'
import moment from 'moment'
import { ReportFull } from './components/ReportFull/ReportFull'
import { ReportVolume } from './components/ReportVolume/ReportVolume'
import { ReportTemperature } from './components/ReportTemperature/ReportTemperature'
import { ReportVisual } from './components/ReportVisual/ReportVisual'
import './BuffetReportsVZLine.scss'
import { DimensionTranslateType } from 'core/api/dto/BuffetReportDto'
import { GeneralReportImagePopup } from './components/GeneralReportPopup/GeneralReportImagePopup'
import { ReportIntensity } from './components/ReportIntensity/ReportIntensity'

export const showFormatHeader = {
  [ShowFormatType.full]: 'По всем данным',
  [ShowFormatType.volume]: 'Объем выдаваемой продукции',
  [ShowFormatType.visual]: 'Визуальный',
  [ShowFormatType.intensity]: 'Интенсивность выдаваемой продукции',
  [ShowFormatType.temperature]: 'Температурный',
}

export const showStyleDimensionType = {
  [DimensionTranslateType.Serving as string]: 'match',
  [DimensionTranslateType.Refund as string]: 'refund',
  [DimensionTranslateType.Write_off as string]: 'no-match',
}

const onSortHandler = (
  sortValue: null | string,
  reportData: IBuffetGeneralReportResult[],
  summary: IBuffetGeneralReportResult,
  setReportData: React.Dispatch<
    React.SetStateAction<IBuffetGeneralReportResult[]>
  >,
) => {
  if (!sortValue) {
    setReportData([summary, ...reportData])
    return
  }
  let newArr: IBuffetGeneralReportResult[] = []
  if (sortValue.includes('name') || sortValue.includes('categoryName')) {
    if (sortValue[0] !== '-') {
      newArr = [...reportData].sort((el1, el2) =>
        (`${
          el1[`${sortValue}` as keyof IBuffetGeneralReportResult]
        }`.toLowerCase() || 0) >
        (`${
          el2[`${sortValue}` as keyof IBuffetGeneralReportResult]
        }`.toLowerCase() || 0)
          ? 1
          : -1,
      )
    }
    if (sortValue[0] === '-') {
      newArr = [...reportData].sort((el1, el2) =>
        (`${
          el1[`${sortValue}`.slice(1) as keyof IBuffetGeneralReportResult]
        }`.toLowerCase() || 0) <
        (`${
          el2[`${sortValue}`.slice(1) as keyof IBuffetGeneralReportResult]
        }`.toLowerCase() || 0)
          ? 1
          : -1,
      )
    }
  } else {
    if (sortValue[0] !== '-') {
      newArr = [...reportData].sort((el1, el2) =>
        (el1[`${sortValue}` as keyof IBuffetGeneralReportResult] || 0) >
        (el2[`${sortValue}` as keyof IBuffetGeneralReportResult] || 0)
          ? 1
          : -1,
      )
    }
    if (sortValue[0] === '-') {
      newArr = [...reportData].sort((el1, el2) =>
        (el1[`${sortValue}`.slice(1) as keyof IBuffetGeneralReportResult] ||
          0) <
        (el2[`${sortValue}`.slice(1) as keyof IBuffetGeneralReportResult] || 0)
          ? 1
          : -1,
      )
    }
  }
  setReportData([summary, ...newArr])
}

export const BuffetReportsVZLine = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false)
  const [currentSortValue, setCurrentSortValue] = useState<null | string>(null)
  const [reportData, setReportData] = useState<IBuffetGeneralReportResult[]>([])
  const pageParams = useParamsControl<
    IBuffetGeneralReportRequest,
    keyof IBuffetGeneralReportRequest
  >({
    withPagination: true,
    whiteList: [
      'report_type',
      'dimension_date_gte',
      'dimension_date_lte',
      'dish_ids',
      'dimension_types',
      'station_ids',
      'plan_menu_type_ids',
      'dimension_time_gte',
      'dimension_time_lte',
    ],
  })

  const { data: generalReport, isLoading } = useGetBuffetGeneralReportQuery(
    { ...pageParams, offset: undefined },
    { skip: !pageParams },
  )

  useEffect(() => {
    if (generalReport) {
      onSortHandler(
        currentSortValue,
        generalReport.results,
        {
          ...generalReport.summary,
          id: 12,
          standardTempMax: null,
          standardTempMin: null,
          image: '',
          imageOnS3: null,
          categoryName: null,
          dates: [],
        },
        setReportData,
      )
    }
  }, [generalReport, currentSortValue])

  const unloadDishesHandler = () => {
    setIsLoadingLocal(true)
    const params = createQueryString(pageParams)
    GetFile(`${BASE_URL}/report_buffet/export_general_report_to_file/${params}`)
      .then(() =>
        AppNotification({
          msg: 'Отчет успешно выгружен!',
          type: NotificationType.success,
        }),
      )
      .finally(() => setIsLoadingLocal(false))
  }

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Выгрузить',
        btnIco: 'export',
        items: [
          {
            title: 'Таблица .XLSX',
            onClick: unloadDishesHandler,
            ico: 'file-xlsx',
          },
          // { title: 'Журнал .PDF', onClick: () => {}, ico: 'file-pdf' },
          // { title: 'Экспортировать .CSV', onClick: () => {}, ico: 'file-csv' },
        ],
        color: AppBtnColor.whiteOutlineViolet,
        disabled: !!(!generalReport)
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageParams, generalReport],
  )

  const [isChart, setIsChart] = useState(false)
  const onChangeChart = (value: boolean) => setIsChart(value)

  const goToChart = useCallback(
    () =>
      navigate(
        `/${AllRoutes.buffetReportsVZ.path}/${
          AllRoutes.buffetReportsVZChart.path
        }/?${searchParams.toString()}`,
      ),
    [navigate, searchParams],
  )

  useEffect(() => {
    if (isChart) goToChart()
  }, [isChart, goToChart])

  const showReport = useMemo(() => {
    if (!reportData && !pageParams?.report_type && !isChart) return null
    return {
      [ShowFormatType.full as string]: (
        <ReportFull
          reportData={reportData}
          setSearchParams={setSearchParams}
          setCurrentSortValue={setCurrentSortValue}
        />
      ),
      [ShowFormatType.volume as string]: (
        <ReportVolume
          reportData={reportData}
          setCurrentSortValue={setCurrentSortValue}
        />
      ),
      [ShowFormatType.temperature as string]: (
        <ReportTemperature
          reportData={reportData}
          setSearchParams={setSearchParams}
          setCurrentSortValue={setCurrentSortValue}
        />
      ),
      [ShowFormatType.visual as string]: (
        <ReportVisual
          reportData={reportData}
          setSearchParams={setSearchParams}
          setCurrentSortValue={setCurrentSortValue}
        />
      ),
      [ShowFormatType.intensity as string]: (
        <ReportIntensity
          reportData={reportData}
          setCurrentSortValue={setCurrentSortValue}
        />
      ),
    }
  }, [isChart, pageParams?.report_type, reportData, setSearchParams])

  return (
    <div className="width-for-page-wrapper buffet-comparative-report-detail general-report">
      {!!(isLoadingLocal || isLoading) && (
        <AppLoader loaderType={LoaderType.main} />
      )}
      {generalReport && (
        <GeneralReportImagePopup
          data={
            { results: generalReport?.results } as IBuffetGeneralReportListDto
          }
        />
      )}
      <AppTitlePage
        title={`${moment(pageParams?.dimension_date_gte).format(
          'DD.MM.YYYY',
        )} - ${moment(pageParams?.dimension_date_lte).format(
          'DD.MM.YYYY',
        )};</br> ${t(
          showFormatHeader[pageParams?.report_type as ShowFormatType],
        )}`}
        actions={actions}
        goBack
        goBackPath={`/${AllRoutes.buffetReportsVZ.path}/?${searchParams
          .toString()
          .replace(
            `&dimension_date=${searchParams.get('dimension_date')}`,
            '',
          )}`}
        isReportIntensity={pageParams?.report_type === ShowFormatType.intensity}
        isReportIntensityChart={isChart}
        onChangeChart={onChangeChart}
      />
      {!!(showReport && pageParams && pageParams.report_type) &&
        showReport[pageParams?.report_type]}
    </div>
  )
}
