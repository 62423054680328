import React, { useEffect, useState } from 'react'
import moment from 'moment'

const CurrentDate = () => {
  const lang = process.env.REACT_APP_LANG || 'ru'
  const [date, setDate] = useState(moment().format('DD.MM.YYYY dd LT'))
  moment.locale(lang)

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(moment().format('DD.MM.YYYY dd LT'))
    }, 1000)
    return () => clearInterval(interval)
  }, [date])

  return <span>{date}</span>
}

export default CurrentDate
