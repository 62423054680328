import React from 'react'
import { AppLoader, LoaderType } from '../../../../components/AppLoader'

interface IFrozeWeightItemProps {
  label: string
  value: string
  isLoading?: boolean
}

export const FrozeWeightItem = ({
  label,
  value,
  isLoading,
}: IFrozeWeightItemProps) => {
  return (
    <div className="froze-weight-item">
      <div className="label">{label}</div>
      <div className="value" style={{ position: 'relative' }}>
        {isLoading && <AppLoader loaderType={LoaderType.dropdown} />}
        {value}
      </div>
    </div>
  )
}
