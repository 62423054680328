import React, { useEffect, useState } from 'react'
import Popup from '../../../../components/popup/Popup'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
} from '../../../../components/btn/AppBtn'
import Checkbox from '../../../../components/checkbox/Checkbox'
import { useGetCorrespondentTypeListQuery } from '../../../../core/api/OrderApi'
import {
  CorrespondentType,
  DimensionType,
} from '../../../../core/api/dto/BuffetReportDto'

interface IPlanMenuTemplatePopup {
  onClose: () => void
  currentCorrespondent: CorrespondentType | null
  setCurrentCorrespondent: (value: CorrespondentType | null) => void
  setOrderType: React.Dispatch<React.SetStateAction<DimensionType>>
}

export const WriteOffPopup = ({
  onClose,
  setOrderType,
  currentCorrespondent,
  setCurrentCorrespondent,
}: IPlanMenuTemplatePopup) => {
  const [offset, setOffset] = useState(0)
  const [correspondentTypeList, setCorrespondentTypeList] = useState<
    CorrespondentType[]
  >([])
  const [loadMore, setLoadMore] = useState(true)
  const { data: correspondentTypeListData } = useGetCorrespondentTypeListQuery({
    limit: 20,
    offset,
    active: true,
  })

  useEffect(() => {
    if (correspondentTypeListData && !offset && loadMore) {
      setCorrespondentTypeList(correspondentTypeListData.results)
      setLoadMore(false)
    }
    if (correspondentTypeListData && !!offset && loadMore) {
      setCorrespondentTypeList((prev) => [
        ...prev,
        ...correspondentTypeListData.results,
      ])
      setLoadMore(false)
    }
  }, [correspondentTypeListData])

  useEffect(() => {
    if (Boolean(correspondentTypeList?.length) && !currentCorrespondent) {
      setCurrentCorrespondent(correspondentTypeList[0] || null)
    }
  }, [correspondentTypeList, currentCorrespondent])

  const showElseHandler = () => {
    setOffset((prevState) => prevState + 20)
    setLoadMore(true)
  }
  const onCreateDimension = () => {
    setOrderType(DimensionType.Write_off)
    onClose()
  }

  return (
    <Popup closePopup={onClose}>
      <div className="stations-list-popup correspondent-wrap">
        <span className="title-stations-popup">Выбор корреспондента</span>
        <div className="stations-list correspondent">
          <AppScrollWrapperNew>
            {Boolean(correspondentTypeList.length) &&
              correspondentTypeList.map((contractor) => (
                <div
                  className="stations-item"
                  key={`st-item-${contractor.id}`}
                  onClick={() => setCurrentCorrespondent(contractor)}
                >
                  <Checkbox
                    name={contractor.name}
                    value={currentCorrespondent?.id === contractor.id}
                    text={contractor.name}
                    onChange={() => {}}
                    isRadioView
                  />
                </div>
              ))}
            {!!correspondentTypeListData?.next && (
              <AppBtn
                sized={AppBtnSize.fullWidth}
                color={AppBtnColor.violetWhite}
                title={'Показать ещё'}
                onClick={showElseHandler}
              />
            )}
          </AppScrollWrapperNew>
        </div>
        <div className="buttons-block mt-20">
          <AppBtn
            title="Отмена"
            color={AppBtnColor.outlineViolet}
            sized={AppBtnSize.md}
            onClick={onClose}
          />
          <AppBtn
            title="Продолжить"
            color={AppBtnColor.violetWhite}
            sized={AppBtnSize.md}
            onClick={onCreateDimension}
            disabled={!currentCorrespondent}
          />
        </div>
      </div>
    </Popup>
  )
}
