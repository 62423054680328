import { IChooseItems } from '../../mainContent/TableAllDishes'
import React, { useState } from 'react'
import Checkbox from '../../checkbox/Checkbox'
import { TSelector } from '../AppTable'
import '../AppTable.css'
import { AppTableTaskStatusEnum } from '../AppTableStatus/AppTableStatus'

interface ITableRow<T, TKey> {
  item: T
  itemIndex: number
  tableDataSelectors: TSelector<T, TKey>[]
  subTableRepresent?: (item: T) => void
  bigPaddingBottom?: boolean
  chooseMode?: boolean
  importantItem?: TKey
  idName?: string
  chooseItems?: IChooseItems | null
  chooseItemsHandler: (value: boolean, id: number) => void
}

export const TableRow = <
  T extends {
    id: number
    archived?: boolean
    status?: AppTableTaskStatusEnum
  },
  TKey extends keyof T,
>({
  item,
  itemIndex,
  tableDataSelectors,
  subTableRepresent,
  bigPaddingBottom,
  chooseMode,
  importantItem,
  chooseItems,
  chooseItemsHandler,
  idName,
}: ITableRow<T, TKey>) => {
  const [subTableActive, setSubTableActive] = useState(false)
  const defaultRenderItem = (item: T, name: TKey) => {
    return <div>{item[name] ? `${item[name]}` : null}</div>
  }
  const isImportant = !!importantItem && item[importantItem]

  return (
    <React.Fragment key={`table-row-${itemIndex}`}>
      <tr
        id={`${(idName || '') + '-'}row-${itemIndex}`}
        className={`${isImportant ? ' important-t-row' : ''}`}
      >
        {!!importantItem && (
          <td>
            {isImportant ? <i className="an-ico an-ico-important" /> : ''}
          </td>
        )}
        {!!subTableRepresent && (
          <td className="td-toggle">
            <div
              className={`table-toggle-block ${subTableActive ? 'active' : ''}`}
              onClick={() => {
                setSubTableActive(!subTableActive)
              }}
            >
              <i className={`an-ico an-ico-arrow-down`} />
            </div>
          </td>
        )}
        {chooseMode && (
          <td className={bigPaddingBottom ? 'pb-30' : ''}>
            {!!item?.archived ||
            item?.status === AppTableTaskStatusEnum.DELETED ? (
              <div className={'delete-checkbox delete-item'}>
                <i className="an-ico an-ico-close" />
              </div>
            ) : (
              <Checkbox
                name={`${item.id}`}
                value={
                  !!chooseItems && chooseItems[item.id!]
                    ? chooseItems[item.id!]
                    : false
                }
                onChange={(value) => chooseItemsHandler(value, item.id!)}
              />
            )}
          </td>
        )}
        {tableDataSelectors.map(
          (
            {
              propsNameToDisable,
              name,
              renderItem = (item) =>
                name ? defaultRenderItem(item, name!) : '?',
            },
            tdIndex,
          ) => {
            return (
              <td
                key={`tr-${itemIndex}-td-${tdIndex}`}
                className={`${bigPaddingBottom ? 'pb-30' : ''}${
                  (item[propsNameToDisable!] as unknown) === true
                    ? ' set-disabled'
                    : ''
                }`}
                id={`${(idName || '') + '-'}row-${itemIndex}-col-${tdIndex}`}
              >
                {renderItem(item, itemIndex)}
              </td>
            )
          },
        )}
      </tr>
      {subTableRepresent && subTableActive && subTableRepresent(item)}
    </React.Fragment>
  )
}
