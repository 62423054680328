import { api, LIMIT } from './BaseApi'
import { IExternalDishResponseDto } from './dto/ExternalDishObjDto'

export const externalDishApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExternalDishByName: builder.query<IExternalDishResponseDto, string>({
      query: (queryString) => ({
        url: `/external_dish/?${queryString}`,
        params: {
          limit: queryString ? LIMIT.search : LIMIT.buffet,
          ordering: 'name',
        },
      }),
    }),
  }),
})

export const { useGetExternalDishByNameQuery } = externalDishApi
