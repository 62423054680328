import React, { memo, useState } from 'react'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import {
  IStatusHistory,
  PlanMenuStatusType,
} from '../../../../core/api/dto/PlanMenuControlDto'
import moment from 'moment'
import { RoleTranslate } from '../../../../core/api/dto/UserDto'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
  IconPosition,
} from '../../../../components/btn/AppBtn'
import { useTranslation } from 'react-i18next'

interface IHistoryListBlockProps {
  historyItems: IStatusHistory[]
}

export const HistoryListBlock = ({ historyItems }: IHistoryListBlockProps) => {
  const { t } = useTranslation()
  return (
    <div className="history-block">
      <AppScrollWrapperNew theme="dropdown">
        <div className="title">{t('История')}</div>
        {!historyItems.length && <div>{t('История пуста')}</div>}
        {historyItems.map((historyItem) => (
          <HistoryItemBlock historyItem={historyItem} key={historyItem.id} />
        ))}
      </AppScrollWrapperNew>
    </div>
  )
}

interface IHistoryItemBlockProps {
  historyItem: IStatusHistory
}

const HistoryItemBlock = ({ historyItem }: IHistoryItemBlockProps) => {
  const [isCollapsedDishes, setIsCollapsedDishes] = useState(true)
  const {
    user,
    status,
    orderPlanMenuIntegrationStatus,
    createdAt,
    nonSyncedDishes,
  } = historyItem
  const { t } = useTranslation()
  const onCollapsedHandler = () =>
    setIsCollapsedDishes((prevState) => !prevState)
  return (
    <div className="history-item-block">
      <HistoryItemRowBlock
        ico="user-one"
        label="Пользователь"
        text={user.name}
      />
      <HistoryItemRowBlock
        ico="star"
        label="Роль"
        text={user.role.map((role) => t(RoleTranslate[role])).join(', ')}
      />
      <HistoryItemRowBlock
        ico="aim-2"
        label="Статус"
        text={status === PlanMenuStatusType.Opened ? 'Открыто' : 'Закрыто'}
      />
      {orderPlanMenuIntegrationStatus !== null && (
        <HistoryItemRowBlock
          ico="aim-2"
          label="Интеграция"
          text={
            orderPlanMenuIntegrationStatus
              ? 'Успешно отправлен в источник'
              : 'При интеграции произошла какая-то ошибка'
          }
        />
      )}
      <HistoryItemRowBlock
        ico="watch"
        label="Время"
        // text={moment(historyItem.createdAt).format('DD.MM.YYYY')}
        text={moment(createdAt).format('HH:mm')}
      />
      {Boolean(nonSyncedDishes.length) && (
        <div className="non-synced-dishes">
          <div className="dishes-title">
            {t('Блюда не отправленные в интеграцию')}
          </div>
          {(isCollapsedDishes
            ? nonSyncedDishes.slice(0, 3)
            : nonSyncedDishes
          ).map((dish) => (
            <div className="item" key={dish.id}>
              {dish.name}
            </div>
          ))}
        </div>
      )}
      {nonSyncedDishes.length > 3 && (
        <AppBtn
          sized={AppBtnSize.fullWidth}
          color={AppBtnColor.outlineViolet}
          iconClassName={
            isCollapsedDishes ? 'arrow-down-bold' : 'arrow-up-bold'
          }
          iconPosition={IconPosition.right}
          title={isCollapsedDishes ? 'Развернуть' : 'Свернуть'}
          onClick={onCollapsedHandler}
        />
      )}
    </div>
  )
}

interface IHistoryItemRowBlockProps {
  ico: string
  label: string
  text: string
}

const HistoryItemRowBlock = memo(
  ({ ico, text, label }: IHistoryItemRowBlockProps) => {
    const { t } = useTranslation()

    return (
      <div className="history-item-row">
        <i className={`an-ico an-ico-${ico}`} />
        <span>{t(label)}:</span>
        <div className="dotes" />
        <span>
          <b>{t(text)}</b>
        </span>
      </div>
    )
  },
)
