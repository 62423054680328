import { IHeaderData } from '../AppTable'
import React, { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTouchMove } from './useTouchMove'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'

interface THeadMobileBlock {
  mobileMode?: boolean
  currentCol: number
  headerData: IHeaderData[]
  setCurrentCol: React.Dispatch<React.SetStateAction<number>>
  setCurrentSortFn?: (value: string) => void
  onToggleAll?: () => void
  isAllOpen?: boolean
  chooseMode?: boolean
}

export const THeadMobileBlock = React.memo(
  ({
    headerData,
    mobileMode,
    currentCol,
    setCurrentCol,
    setCurrentSortFn,
    onToggleAll,
    isAllOpen,
    chooseMode,
  }: THeadMobileBlock) => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const { width } = useGetWidth()
    const ordering = searchParams.get('ordering')
    const sortStartValue = ordering
      ? { name: ordering.replace('-', ''), activeField: ordering }
      : null
    const [activeSort, setActiveSort] = useState<{
      name: string
      activeField: string
    } | null>(sortStartValue)
    const touchFn = useTouchMove({
      totalCount: headerData.length,
      currentCol,
      setCurrentCol,
    })

    const onSortHandler = useCallback(
      (name: string) => {
        const sortValue =
          activeSort?.name === name
            ? activeSort?.activeField === name
              ? `-${name}`
              : name
            : name
        setActiveSort({
          name,
          activeField: sortValue,
        })
        if (setCurrentSortFn) {
          setCurrentSortFn(sortValue)
        }
        if (!setCurrentSortFn) {
          searchParams.set('ordering', sortValue)
          setSearchParams(searchParams.toString())
        }
      },
      [activeSort, searchParams],
    )

    return (
      <thead>
        {mobileMode && headerData.length > 1 ? (
          <tr>
            {chooseMode && <th />}
            <th>
              <div className={'thead-th'} style={{ height: '100%' }}>
              <div className={'title'}>
                {
                  !chooseMode && (
                    <div
                    className={`table-toggle-block${isAllOpen ? '' : ' active'}`}
                    onClick={onToggleAll}
                  >
                    <i className="an-ico an-ico-arrow-down" />
                  </div>
                  )
                }
                {t(headerData[0].title)}
              </div>
              </div>
            </th>
            {headerData
              .slice(currentCol, currentCol + (width < 600 ? 1 : 3))
              .map((e: any) => (
                <th key={e.title} className="second-col" {...touchFn}>
                  {t(e?.title)}
                </th>
              ))}
          </tr>
        ) : (
          <tr>
            {chooseMode && <th />}
            {headerData.map((th, index) => {
              return index === 0 && onToggleAll ? (
                <th key={th.title + index} className="desktop-view">
                  <div className={'thead-th'} style={{ height: '100%' }}>
                    <div className={'title'}>
                      <div
                        className={`table-toggle-block${
                          isAllOpen ? '' : ' active'
                        }`}
                        onClick={onToggleAll}
                      >
                        <i className="an-ico an-ico-arrow-down" />
                      </div>
                      {t(th.title)}
                    </div>

                    <div
                      onClick={
                        th.sort ? () => onSortHandler(th.sort!) : undefined
                      }
                      className={'thead-th'}
                      style={{ height: '100%' }}
                    >
                      {!!th.sort && (
                        <i
                          className={`an-ico an-ico-arrow-down-bg ico-sort ${
                            activeSort?.name === th.sort
                              ? activeSort?.activeField === `-${th.sort}`
                                ? 'active-sort asc'
                                : 'active-sort'
                              : ''
                          }`}
                        />
                      )}
                    </div>
                  </div>
                </th>
              ) : (
                <th
                  key={th.title + index}
                  className="desktop-view"
                  onClick={th.sort ? () => onSortHandler(th.sort!) : undefined}
                >
                  <div className={'thead-th'}>
                    {t(th.title)}
                    {!!th.sort && (
                      <i
                        className={`an-ico an-ico-arrow-down-bg ico-sort ${
                          activeSort?.name === th.sort
                            ? activeSort?.activeField === `-${th.sort}`
                              ? 'active-sort asc'
                              : 'active-sort'
                            : ''
                        }`}
                      />
                    )}
                  </div>
                </th>
              )
            })}
          </tr>
        )}
      </thead>
    )
  },
)
