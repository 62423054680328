import React from 'react'
import { FrozeWeightItem } from './FrozeWeightItem'
import { useGetCurrentWeightQuery } from '../../../../core/api/OrderApi'
import { IBuffetMilletDto } from '../../../../core/api/dto/BuffetMilletDto'
import { useAppSelector } from '../../../../core/store/store'
import { useTranslation } from 'react-i18next'

interface TotalWeightShowProps {
  currentWeightDisable: boolean
  currentMillet: IBuffetMilletDto | null
  isOpenPopup?: boolean
}

export const TotalWeightShow = ({
  currentWeightDisable,
  currentMillet,
  isOpenPopup,
}: TotalWeightShowProps) => {
  const { t } = useTranslation()
  const { isErrorWeight } = useAppSelector((state) => state.connection)
  const { data: currentWeight } = useGetCurrentWeightQuery(null, {
    skip: currentWeightDisable,
    pollingInterval: 1000,
  })

  return (
    <>
      <FrozeWeightItem
        label={t('Общий вес, кг')}
        value={`${currentWeight?.weight.toFixed(3) || 0}${t('кг')}`}
        isLoading={isErrorWeight && !isOpenPopup}
      />
      <FrozeWeightItem
        label={t('Вес тары, кг')}
        value={`${currentMillet?.milletWeight.toFixed(3) || 0}${t('кг')}`}
      />
      <FrozeWeightItem
        label={t('Вес без тары, кг')}
        value={`${(
          (currentWeight?.weight || 0) - (currentMillet?.milletWeight || 0)
        ).toFixed(3)}${t('кг')}`}
      />
    </>
  )
}
