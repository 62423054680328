import React from 'react'
import { useTranslation } from 'react-i18next'

export enum CheckboxSize {
  base = '',
  sm = ' sm',
  md = ' md',
  lg = ' lg',
}

interface IPropsCheckbox {
  name: string
  text?: string
  size?: CheckboxSize
  value: boolean
  onChange: (arg: boolean) => void
  disabled?: boolean
  isRadio?: boolean
  isRadioView?: boolean
  white?: boolean
}

const Checkbox = ({
  name,
  text,
  size = CheckboxSize.base,
  value,
  onChange,
  disabled = false,
  isRadio = false,
  isRadioView = false,
  white,
}: IPropsCheckbox) => {
  const { t } = useTranslation()
  return (
    <div
      className={`wrapper-checkbox${size}${isRadio ? ' radio-btn' : ''}${
        isRadioView ? ' radio-view' : ''
      }${white ? ' white' : ''}`}
    >
      <input
        type="checkbox"
        className="custom-checkbox"
        id={name}
        name={name}
        checked={value}
        onChange={() => onChange(!value)}
        disabled={disabled}
      />
      <label htmlFor={name}>{t(text || '')}</label>
    </div>
  )
}

export default Checkbox
