import React, { useMemo } from 'react'
import { IOrderLine } from '../../core/api/dto/OrderObjDto'
import { useAppSelector } from '../../core/store/store'
import AppBtn, { AppBtnColor, AppBtnSize, IconPosition } from '../btn/AppBtn'

interface IPropsBuffetCard {
  info: IOrderLine
  setActiveDish: () => void
}

const BuffetCard = ({ info, setActiveDish }: IPropsBuffetCard) => {
  const { isFullModeDish } = useAppSelector((state) => state.buffet)
  const factQuantity = useMemo(() => {
    if (info.orderLineDimensions?.length) {
      return info.orderLineDimensions.reduce((acc, el) => {
        return acc + el.quantity
      }, 0)
    } else {
      return 0
    }
  }, [info.orderLineDimensions])

  return (
    <div
      className={`meal-card buffet ${isFullModeDish ? '' : 'sm'}`}
      onClick={setActiveDish}
    >
      <div className="wrapper-info">
        <div className="info">
          <div className="content">{info.dish.name}</div>
          {isFullModeDish && (
            <div className="count">
              <div className="plan">
                <div>План</div>
                <div>{info.quantity.toFixed(2)}</div>
              </div>
              <div className="fact">
                <div>Выдано</div>
                <div>{factQuantity.toFixed(2)}</div>
              </div>
            </div>
          )}
        </div>
        {isFullModeDish && (
          <AppBtn
            title="Выдать"
            color={AppBtnColor.outlineGrayLight}
            iconClassName="arrow-long"
            iconPosition={IconPosition.right}
            sized={AppBtnSize.sm}
          />
        )}
      </div>
    </div>
  )
}

export default BuffetCard
