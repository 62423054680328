import React, { memo } from 'react'
import { compareProps } from '../../utils'
import SortingItem, { ISortingItem } from './SortingItem'

interface ISorting {
  sorts: ISortingItem[]
  sortingParamName?: string
  defaultSorting?: string
}

const Sorting = ({
  sorts,
  sortingParamName = 'ordering',
  defaultSorting,
}: ISorting) => {
  return (
    <div className="sorting">
      {sorts.map((el) => (
        <SortingItem
          info={el}
          sortingParamName={sortingParamName}
          key={el.name}
          isDefaultSort={el.name === defaultSorting}
        />
      ))}
    </div>
  )
}

export const SortingMemoized = memo(Sorting, compareProps)

export default SortingMemoized
