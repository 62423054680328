import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { AllRoutes } from '../../core/routes/AllRoutes'
import { mockDataDishes } from '../../mock'
import AppBtn, { AppBtnColor, AppBtnSize } from '../btn/AppBtn'
import InputField from '../input/InputField'
import PhotoUploader, {
  PhotoUploaderSize,
} from '../photoUploader/PhotoUploader'

interface IInfoItemDish {
  type: string
  title: string
  placeholder: string
  onChange: (val: string) => void
  value: string
}

const InfoItemDish = ({
  type,
  title,
  placeholder,
  value,
  onChange,
}: IInfoItemDish) => {
  return (
    <div className="item-info-dish">
      <h4>{title}</h4>
      <InputField
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export interface IStateDish {
  name: string
  category: string
  weight: string
  min: string
  max: string
  id?: number
  photo?: string
}

const initState = {
  name: '',
  category: '',
  weight: '',
  min: '',
  max: '',
}

const CreateUpdateDish = () => {
  const { pathname } = useLocation()
  const { dishId } = useParams()
  const [dish, setDish] = useState<IStateDish>(
    pathname.includes(AllRoutes.addNewDish.path)
      ? initState
      : mockDataDishes.find((el) => el.id === +dishId!)!,
  )

  const [photo, setPhoto] = useState<null | string>(null)

  const inputHandler = (value: string, fieldName: keyof IStateDish) => {
    setDish((prev) => ({
      ...prev,
      [fieldName]: value,
    }))
  }

  const navigate = useNavigate()

  return (
    <>
      <div className="wrapper-info-dish">
        <div className="list-item-info-dish">
          <InfoItemDish
            type="text"
            title="Название"
            placeholder="Название блюда"
            value={dish.name}
            onChange={(val) => inputHandler(val, 'name')}
          />
          <InfoItemDish
            type="text"
            title="Категория"
            placeholder="Название категории"
            value={dish.category}
            onChange={(val) => inputHandler(val, 'category')}
          />
          <InfoItemDish
            type="number"
            title="Вес кг, эталонный"
            placeholder="Вес"
            value={dish.weight}
            onChange={(val) => inputHandler(val, 'weight')}
          />
          <div className="item-info-dish">
            <h4>Температура, эталонная °C</h4>
            <div className="fields">
              <InputField
                type="number"
                placeholder="min"
                value={dish.min}
                onChange={(val) => inputHandler(val, 'min')}
              />
              <InputField
                type="number"
                placeholder="max"
                value={dish.max}
                onChange={(val) => inputHandler(val, 'max')}
              />
            </div>
          </div>
        </div>
        <div className="wrapper-photo-uploader">
          <PhotoUploader
            size={PhotoUploaderSize.lg}
            photo={photo}
            setPhoto={setPhoto}
            iconClassName="search"
          />
          <AppBtn
            sized={AppBtnSize.md}
            color={AppBtnColor.violetWhite}
            title={`${
              pathname.includes(AllRoutes.addNewDish.path)
                ? 'Добавить блюдо'
                : 'Сохранить'
            }`}
          />
        </div>
      </div>
      <footer className="nav-footer">
        <nav>
          <AppBtn
            color={AppBtnColor.violetGray}
            iconClassName="arrow-long-l"
            title="Назад"
            onClick={() => navigate(-1)}
          />
        </nav>
      </footer>
    </>
  )
}

export default CreateUpdateDish
