import React, { useState } from 'react'
import './ReportImageUpscale.scss'
import Popup, { PopupTypeEnum } from '../../../components/popup/Popup'
import { useCreateTaskObjectMutation } from '../../../core/api/TaskApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useTranslation } from 'react-i18next'

interface IReportImageUpscaleProps {
  photo?: string | null
  isDimension?: boolean
  isCategory?: boolean
  isFact?: boolean
  dimensionId?: number | null
  temperature?: number | string | null
  standardTempMax?: number | null
  standardTempMin?: number | null
}

export const ReportImageUpscale = (props: IReportImageUpscaleProps) => {
  const { t } = useTranslation()
  const { photo, isDimension, isCategory, dimensionId, isFact, temperature, standardTempMax, standardTempMin } = props
  const [isZoomPhoto, setIsZoomPhoto] = useState(false)
  const onZoomToggle = () => setIsZoomPhoto((state) => !state)

  if (!photo) {
    return isDimension ? (
      !isCategory ? (
        <div className="not-found-photo-block">
          <div className="title">{isFact ? `${t('Факт')} ${temperature}°C'` : `${t('Эталон')} ${!!((standardTempMax || standardTempMax === 0) && (standardTempMin || standardTempMin === 0)) ? `${standardTempMin}-${standardTempMax}°C` : ''}`}</div>
          <i className="an-ico an-ico-photo-add report-image-popup__photos-notfound" />
          {/*<AddTaskBtn id={dimensionId} />*/}
        </div>
      ) : null
    ) : (
      <div className="not-found-photo-block">
        <div className="title">{isFact ? `${t('Факт')} ${temperature}°C'` : `${t('Эталон')} ${!!((standardTempMax || standardTempMax === 0) && (standardTempMin || standardTempMin === 0)) ? `${standardTempMin}-${standardTempMax}°C` : ''}`}</div>
        <i className="an-ico an-ico-photo-add report-image-popup__photos-notfound" />
      </div>
    )
  }
  return (
    <div className={'report-image-upscale__container'}>
      <div className="title">{isFact ? `${t('Факт')} ${temperature}°C` : `${t('Эталон')} ${!!((standardTempMax || standardTempMax === 0) && (standardTempMin || standardTempMin === 0)) ? `${standardTempMin}-${standardTempMax}°C` : ''}`}</div>
      <img src={photo} alt="" onClick={onZoomToggle} />
      {isZoomPhoto && (
        <Popup popupType={PopupTypeEnum.photo} closePopup={onZoomToggle}>
          <img
            src={photo}
            alt=""
            className="img-child-popup"
            style={{ cursor: 'pointer' }}
            onClick={onZoomToggle}
          />
        </Popup>
      )}
      {isDimension && <AddTaskBtn id={dimensionId} />}
    </div>
  )
}

interface AddTaskBtnProps {
  id?: number | null
}

const AddTaskBtn = ({ id }: AddTaskBtnProps) => {
  const { t } = useTranslation()
  const [createTaskObject] = useCreateTaskObjectMutation()

  const onAddTaskObjectClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (id) {
      createTaskObject({ orderLineDimension: id })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Объект успешно добавлен'),
            type: NotificationType.success,
          })
        })
    }
  }
  return (
    <div className="add-task-block" onClick={onAddTaskObjectClick}>
      <i className="an-ico an-ico-add" />
    </div>
  )
}
