export interface IRouteItem {
  path: string
  name: string
}

const buffetRoutes = {
  buffetCardMapCatalog: {
    path: 'catalog',
    name: 'Каталог заказов шведского стола',
  },
  buffetCardMapCatalogOrder: {
    path: ':buffetOrderId',
    name: 'buffetOrderId',
  },
  additionBuffetCard: {
    path: 'add',
    name: 'Добавление блюда в каталог шведского стола',
  },
  allDishesByWorkshops: {
    path: 'all-workshops',
    name: 'Блюда по всем цехам',
  },
  workshopId: {
    path: ':workshopId',
    name: 'Workshop Id',
  },
}

const authRoutes = {
  login: { path: 'login', name: 'Login' },
  logout: { path: 'logout', name: 'Logout' },
  resetPassword: { path: 'reset-password', name: 'Reset password' },
  emailCheck: { path: 'check-email', name: 'Check email' },
  confirmNewPassword: {
    path: 'confirm-new-password',
    name: 'Confirm new password',
  },
  acceptInvitation: {
    path: 'accept-invitation',
    name: 'Accept invitation',
  },
}

const baseRoutes = {
  user: {
    path: 'user',
    name: 'User',
  },
}

const chefRoutes = {
  technologicalMapCatalog: {
    path: 'catalog',
    name: 'Каталог технологических карт',
  },
  technologicalMapCatalogRestaurant: {
    path: ':technologicalCatalogRestaurantId',
    name: 'restaurantId',
  },
  technologicalMapCatalogCategory: {
    path: ':technologicalCatalogCategoryId',
    name: 'categoryId',
  },
  technologicalMapCardItem: {
    path: ':technologicalMapCardItemId',
    name: 'Технологическая карта',
  },
  technologicalMapCatalogWithoutCategory: {
    path: 'without-category',
    name: 'Технологические карты без категории',
  },
  technologicalMapCardItemEdit: {
    path: 'edit',
    name: 'Обновление технологической карты',
  },
  technologicalMapAllSearchCards: {
    path: 'by-all-dish',
    name: 'Search by all dishes',
  },
}

const adminRoutes = {
  adminCardMapCatalog: {
    path: 'catalog',
    name: 'Каталог заказов',
  },
  reports: {
    path: 'reports',
    name: 'reports',
  },
  taskList: {
    path: 'task-list',
    name: 'task-list',
  },
  addTask: {
    path: 'new',
    name: 'new task',
  },
  editTask: {
    path: ':taskId',
    name: 'task-edit',
  },
  dishes: {
    path: 'dishes',
    name: 'dishes',
  },
  addDishes: {
    path: 'add-dishes',
    name: 'add-dishes',
  },
  editDishes: {
    path: ':dishId',
    name: 'edit-dishes',
  },
  millet: {
    path: 'millet',
    name: 'millet',
  },
  addMillet: {
    path: 'addMillet',
    name: 'add new millet',
  },
  editMillet: {
    path: ':milletId',
    name: 'edit millet',
  },
  categories: {
    path: 'categories',
    name: 'categories',
  },
  planMenu: {
    path: 'plan-menu',
    name: 'plan-menu',
  },
  addPlanMenu: {
    path: 'create-plan-menu',
    name: 'add plan menu',
  },
  editPlanMenu: {
    path: ':planMenuId',
    name: 'edit plan menu',
  },
  templatePlanMenu: {
    path: 'template-plan-menu',
    name: 'template-plan-menu',
  },
  addTemplatePlanMenu: {
    path: 'create-template-plan-menu',
    name: 'add template plan menu',
  },
  editTemplatePlanMenu: {
    path: ':templatePlanMenuId',
    name: 'edit template plan menu',
  },
  planMenuType: {
    path: 'plan-menu-type',
    name: 'plan menu type',
  },
  planMenuControl: {
    path: 'plan-menu-control',
    name: 'plan-menu control',
  },
  planMenuIssuing: {
    path: 'plan-menu-issuing',
    name: 'plan-menu issuing',
  },
  planMenuIssuingDetail: {
    path: ':categoryId',
    name: 'plan-menu issuing detail',
  },
  addPlanMenuIssuingDetail: {
    path: ':oderId/:dishId',
    name: 'add plan-menu issuing',
  },
  choosePlanMenuIssuingDetail: {
    path: 'choose',
    name: 'choose plan-menu issuing',
  },
  planMenuControlManager: {
    path: 'plan-menu-control-manager',
    name: 'plan-menu control manager',
  },
  planMenuControlManagerDetail: {
    path: ':dishId',
    name: 'plan-menu control manager detail',
  },
  planMenuClose: {
    path: 'plan-menu-close',
    name: 'plan-menu close',
  },
  guestType: {
    path: 'guest-type',
    name: 'guest-type',
  },
  buffetReports: {
    path: 'buffet-report',
    name: 'buffet report',
  },
  imagePopupTestPage: {
    path: 'imagePopupTestPage',
    name: 'ImagePopupTestPage',
  },
  buffetComparativeReports: {
    path: 'buffet-comparative-report',
    name: 'buffet comparative report',
  },
  buffetComparativeReportsDetail: {
    path: 'detail',
    name: 'buffet comparative report detail',
  },
  tags: {
    path: 'tags',
    name: 'tags',
  },
  stations: {
    path: 'stations',
    name: 'stations',
  },
  editStations: {
    path: ':stationId',
    name: 'edit station',
  },
  options: {
    path: 'options',
    name: 'options',
  },
  dateOrders: {
    path: ':dateOrders',
    name: 'Заказы за конкретный день',
  },
  orderId: {
    path: ':orderId',
    name: 'Id выбранного заказа',
  },
  orderLineId: {
    path: ':orderLineId',
    name: 'Id выбранного блюда',
  },
  addNewDish: {
    path: 'add-dish',
    name: 'add new dish',
  },
  dishId: {
    path: ':dishId',
    name: 'dish id',
  },
  buffetReportsVZ: {
    path: 'buffet-report-vz',
    name: 'buffet-report-vz',
  },
  buffetReportsVZLine: {
    path: 'line-report',
    name: 'buffet-report-vz-line-report',
  },
  buffetReportsVZChart: {
    path: 'chart-report',
    name: 'buffet-report-vzchart-report',
  }
}

type TAllRoutes = {
  [key in keyof typeof routes]: IRouteItem
}

const routes = {
  ...baseRoutes,
  ...authRoutes,
  ...chefRoutes,
  ...buffetRoutes,
  ...adminRoutes,
}

export const AllRoutes: TAllRoutes = routes
