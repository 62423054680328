import { useIsAdmin } from '../../../../utils/useIsAdmin'
import {
  useGetPlanMenuControlOrdersOnlyReadQuery,
  useGetPlanMenuControlOrdersQuery,
} from '../../../../core/api/PlanMenuControlApi'
import { IPlanMenuControlOrdersRequest } from '../../../../core/api/dto/PlanMenuControlDto'

export const useGetPlanMenuByRole = (
  pageParams: IPlanMenuControlOrdersRequest | null,
  offset: number,
) => {
  const isAdmin = useIsAdmin()

  const { data: ordersListData } = useGetPlanMenuControlOrdersQuery(
    {
      ...pageParams,
      offset,
    },
    { skip: !pageParams || !isAdmin },
  )

  const { data: ordersListDataOnlyRead } =
    useGetPlanMenuControlOrdersOnlyReadQuery(
      {
        ...pageParams,
        offset,
      },
      { skip: !pageParams || isAdmin || isAdmin === null },
    )

  return isAdmin ? ordersListData : ordersListDataOnlyRead
}
