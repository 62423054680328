import React from 'react'
import { useNavigate } from 'react-router'
import AppBtn, { AppBtnColor, IconPosition } from '../btn/AppBtn'
import Spinner, { SpinnerColor } from '../spinner/Spinner'
interface IPropsFooter {
  onClick: () => void
  isEditable: boolean
  disabled: boolean
}

const Footer = ({ onClick, isEditable, disabled }: IPropsFooter) => {
  const navigation = useNavigate()
  return (
    <footer className="nav-footer">
      <nav>
        <AppBtn
          onClick={() => !disabled && navigation(-1)}
          color={AppBtnColor.whiteViolet}
          title="Назад"
          iconClassName="arrow-long-l"
        />
        {isEditable &&
          (disabled ? (
            <Spinner color={SpinnerColor.violet} />
          ) : (
            <AppBtn
              onClick={onClick}
              color={AppBtnColor.whiteViolet}
              title="Продолжить"
              iconClassName="arrow-long-r"
              iconPosition={IconPosition.right}
            />
          ))}
      </nav>
    </footer>
  )
}

export default Footer
