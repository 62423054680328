import React from 'react'
import { Link } from 'react-router-dom'
import AppBtn, {
  AppBtnColor,
  AppBtnRadius,
  AppBtnSize,
} from '../components/btn/AppBtn'
import { useGetCurrentUserQuery } from '../core/api/UserApi'
import { Role } from '../core/api/dto/UserDto'
import { useAppDispatch, useAppSelector } from '../core/store/store'
import { changeField } from '../core/store/buffet/buffetSlice'
import Checkbox from '../components/checkbox/Checkbox'

export const isShowCheckbox = (role: keyof typeof Role) =>
  role === 'FOTOBOX' || role === 'WAITER'

const User = () => {
  const { data: currentUser } = useGetCurrentUserQuery()
  const { isKeyboard } = useAppSelector((state) => state.buffet)
  const dispatch = useAppDispatch()

  if (!currentUser) {
    return null
  }

  return (
    <main className="auth-page">
      <div className="authorized">
        <Link to={'/'} className="link">
          <i className="an-ico an-ico-arrow-l" />
          <span>Вернуться к панели</span>
        </Link>
        <div className="info-user">
          <div className="name">{currentUser.name}</div>
          <div className="position">
            {Role[currentUser.userRoles.roleType[0]]}
          </div>
          {isShowCheckbox(currentUser.userRoles.roleType[0]) && (
            <Checkbox
              value={isKeyboard}
              onChange={() =>
                dispatch(
                  changeField({ field: 'isKeyboard', value: !isKeyboard }),
                )
              }
              name="keyboard"
              text="Отображать клавиатуру"
            />
          )}
          <AppBtn
            path={'logout'}
            title="Выйти"
            color={AppBtnColor.outlineViolet}
            sized={AppBtnSize.lg}
            radius={AppBtnRadius.r30}
          />
        </div>
      </div>
    </main>
  )
}

export default User
