import React, { useCallback, useEffect, useState } from 'react'
import Checkbox from '../../../../components/checkbox/Checkbox'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
  IconPosition,
} from '../../../../components/btn/AppBtn'
import {
  AppNotification,
  NotificationType,
} from '../../../../components/notification/Notification'
import moment from 'moment'
import { IBuffetDishUploadDto } from '../../../../core/api/dto/BuffetDishDto'
import { useUpdateUploadedBuffetDishMutation } from '../../../../core/api/BuffetDishApi'

interface IConflictsPopupProps {
  setActive: (value: boolean) => void
  conflictData: IBuffetDishUploadDto
  setLoading: (value: boolean) => void
}
interface ISelectedItems {
  [key: number]: 'old' | 'new'
}
export const ConflictsDishesBlock = ({
  setActive,
  conflictData,
  setLoading,
}: IConflictsPopupProps) => {
  const [selectedItems, setSelectedItems] = useState<ISelectedItems | null>(
    null,
  )
  const [chooseAllOldData, setChooseAllOldData] = useState(true)
  const [chooseAllNewData, setChooseAllNewData] = useState(false)

  const [updateUploadedBuffetDish, { isLoading }] =
    useUpdateUploadedBuffetDishMutation()

  const outSideClickHandler = useCallback(() => {
    setActive(false)
  }, [])

  const onChooseItem = (index: number, param: 'old' | 'new') => {
    if (selectedItems && selectedItems[index] === param) return
    setSelectedItems((prev) => ({ ...prev, [index]: param }))
    if (param === 'old' && chooseAllNewData) {
      setChooseAllNewData(false)
    }
    if (param === 'new' && chooseAllOldData) {
      setChooseAllOldData(false)
    }
  }

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    const result: ISelectedItems = {}
    for (let i: number = 0; i < conflictData.conflictDishesCount; i++) {
      result[i] = 'old'
    }
    setSelectedItems(result)
  }, [])

  useEffect(() => {
    if (
      !!selectedItems &&
      Object.entries(selectedItems).filter((item) => item[1] === 'old')
        .length === conflictData.conflictDishesCount &&
      !chooseAllOldData
    ) {
      setChooseAllOldData(true)
    }
    if (
      !!selectedItems &&
      Object.entries(selectedItems).filter((item) => item[1] === 'new')
        .length === conflictData.conflictDishesCount &&
      !chooseAllNewData
    ) {
      setChooseAllNewData(true)
    }
  }, [selectedItems])

  const onChooseAllItem = (value: boolean, param: 'old' | 'new') => {
    if (value) {
      const result: ISelectedItems = {}
      for (let i: number = 0; i < conflictData.conflictDishesCount; i++) {
        result[i] = param
      }
      setSelectedItems(result)
      if (param === 'old') {
        setChooseAllOldData(true)
        setChooseAllNewData(false)
      }
      if (param === 'new') {
        setChooseAllOldData(false)
        setChooseAllNewData(true)
      }
      return
    }
    setSelectedItems(null)
    setChooseAllOldData(false)
    setChooseAllNewData(false)
  }

  const saveData = () => {
    if (
      !selectedItems ||
      Object.keys(selectedItems).length < conflictData.conflictDishesCount
    ) {
      AppNotification({
        msg: 'Для продолжения должны быть отмечены все пункты!',
        type: NotificationType.error,
      })
      return
    }
    const newData = Object.keys(selectedItems).map((param, index) =>
      param === 'old'
        ? conflictData.existingDishes[index]
        : conflictData.uploadedDishes[index],
    )
    updateUploadedBuffetDish({ data: newData })
      .unwrap()
      .then(() => {
        if (
          selectedItems &&
          Object.entries(selectedItems).filter((item) => item[1] === 'old')
            .length !== conflictData.conflictDishesCount
        ) {
          AppNotification({
            msg: 'Блюда успешно добавлены!',
            type: NotificationType.success,
          })
        }

        setActive(false)
      })
  }

  return (
    <div className="popup-conflicts">
      <span className="total-conflicts">
        Всего конфликтных данных: {conflictData.conflictDishesCount}
      </span>
      <small className="bold">Какие данные вы хотите сохранить?</small>
      <span className="description-conflicts">
        Найдено {conflictData.conflictDishesCount} повторяющихся строк данных.
        Выберете файлы которые необходимо сохранить
      </span>
      <div className="conflicts-checkbox-wrap">
        <div className="conflicts-checkbox-header">
          <Checkbox
            name={'8-pb-all-old'}
            value={chooseAllOldData}
            onChange={(value) => onChooseAllItem(value, 'old')}
            text="Существующие данные"
          />
          <Checkbox
            name={'8-pb-all-new'}
            value={chooseAllNewData}
            onChange={(value) => onChooseAllItem(value, 'new')}
            text="Загружаемые данные"
          />
        </div>
        <div className="conflicts-checkbox-body">
          <AppScrollWrapperNew theme="dropdown">
            {Array.from(
              { length: conflictData.conflictDishesCount },
              (_, index) => index,
            ).map((item, index) => {
              const existingItem = conflictData.existingDishes[index]
              const newItem = conflictData.uploadedDishes[index]
              return (
                <div className="checkbox-row" key={`conflict-item-${index}`}>
                  <div className="old-data">
                    <Checkbox
                      name={existingItem.sourceIdent + 'old'}
                      value={!!selectedItems && selectedItems[index] === 'old'}
                      onChange={() => onChooseItem(index, 'old')}
                      text={existingItem.name}
                    />
                    <small>
                      Изменение от{' '}
                      {moment(existingItem.createdAt).format('DD.MM.YYYY')}
                    </small>
                  </div>
                  <div className="new-data pl-20">
                    <Checkbox
                      name={newItem.sourceIdent + 'new'}
                      value={!!selectedItems && selectedItems[index] === 'new'}
                      onChange={() => onChooseItem(index, 'new')}
                      text={newItem.name}
                    />
                    <small>
                      Изменение от {moment(new Date()).format('DD.MM.YYYY')}
                    </small>
                  </div>
                </div>
              )
            })}
          </AppScrollWrapperNew>
        </div>
      </div>
      <div className="buttons-block mt-20">
        <AppBtn
          title="Отменить"
          color={AppBtnColor.outlineViolet}
          sized={AppBtnSize.md}
          onClick={outSideClickHandler}
          iconClassName="close"
        />
        <AppBtn
          title="Продолжить"
          color={AppBtnColor.violetWhite}
          sized={AppBtnSize.md}
          onClick={saveData}
          iconClassName="arrow-long"
          iconPosition={IconPosition.right}
        />
      </div>
    </div>
  )
}
