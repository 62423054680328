import React, { useMemo } from 'react'
import { useGetCurrentUserQuery } from '../../../core/api/UserApi'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import BurgerMenu, { IBurgerData } from '../../../widgets/burgerMenu/BurgerMenu'
import CurrentDate from '../../../components/currentDate/CurrentDate'
import { useAppSelector } from '../../../core/store/store'
import { Role } from '../../../core/api/dto/UserDto'

const burgerDataBuffetLineAll: IBurgerData[] = [
  // {
  //   id: 16,
  //   title: 'Список задач',
  //   to: AllRoutes.taskList.path,
  //   ico: AllRoutes.categories.path,
  // },
  { id: 1, title: 'Блюда', ico: 'dish', to: `${AllRoutes.dishes.path}?archived=0` },
  {
    id: 2,
    title: 'Категории блюд',
    ico: 'categories',
    to: AllRoutes.categories.path,
  },
  { id: 3, title: 'Тары', ico: 'container', to: AllRoutes.millet.path },
  {
    id: 4,
    title: 'Шаблоны план-меню',
    ico: 't-menu-plan',
    to: AllRoutes.templatePlanMenu.path,
  },
  {
    id: 5,
    title: 'Типы план-меню',
    ico: 'type-plan-menu',
    to: AllRoutes.planMenuType.path,
  },
  { id: 6, title: 'План-меню', ico: 'plan-menu', to: AllRoutes.planMenu.path },
  {
    id: 7,
    title: 'Выдача план-меню',
    ico: 'control-plan-menu',
    to: AllRoutes.planMenuIssuing.path,
  },
  //{
  //  id: 8,
  //  title: 'Отчёты',
  //  ico: 'report-heart',
  //  to: AllRoutes.buffetReports.path,
  //},
  { id: 23, title: 'Отчёты', ico: 'report-heart', to: AllRoutes.buffetReportsVZ.path},
  {
    id: 15,
    title: 'Сравнительный отчет',
    ico: 'report-heart',
    to: AllRoutes.buffetComparativeReports.path,
  },
  {
    id: 9,
    title: 'Контроль план-меню',
    ico: 'control-plan-menu',
    to: AllRoutes.planMenuControl.path,
  },
  {
    id: 10,
    title: 'Контроль зала',
    ico: 'control-plan-menu',
    to: AllRoutes.planMenuControlManager.path,
  },
  {
    id: 11,
    title: 'Закрытие план-меню',
    ico: 'close-plan-menu',
    to: AllRoutes.planMenuClose.path,
  },
  { id: 12, title: 'Хэштеги', ico: 'hashtag', to: AllRoutes.tags.path },
  { id: 13, title: 'Станции', ico: 'station', to: AllRoutes.stations.path },
  {
    id: 14,
    title: 'Тип гостя',
    ico: 'user-circle',
    to: AllRoutes.guestType.path,
  },
  { id: 19, title: 'Настройки', ico: 'settings', to: AllRoutes.options.path },
]

export const burgerDataBuffetLineByRole: { [key in Role]: number[] } = {
  BUFF_SUPERVISOR: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 19, 20, 21, 16, 23
  ],
  BUFF_ACCOUNTANT: [1, 2, 3, 4, 5, 6, 8, 19, 20, 21, 16],
  BUFF_CHEF: [1, 2, 3, 7, 8, 19, 20, 21, 16],
  BUFF_MANAGER: [10, 11, 19, 20, 21, 16],
  BUFF_CASHIER: [7, 8, 19, 20, 21, 16],
  ADMIN: [19],
  CHEF: [19],
  WAITER: [19],
  FOTOBOX: [19],
  COOK: [19],
  WAITER_WEB: [19],
}

export const HeaderBuffetLinePage = () => {
  const token = useAppSelector((state) => state.foodBoxAuth.token)
  const { data: currentUser } = useGetCurrentUserQuery({}, { skip: !token })

  const burgerData: IBurgerData[] = useMemo(() => {
    return burgerDataBuffetLineAll.filter((item) =>
      currentUser?.userRoles.roleType
        .map((roleItem) => burgerDataBuffetLineByRole[roleItem as Role])
        .reduce((acc, el) => [...acc, ...el], [])
        .some((i) => i === item.id),
    )
  }, [currentUser])

  return (
    <header className="header admin">
      <BurgerMenu burgerData={burgerData} currentUser={currentUser} />
      <div className="container-fluid">
        <div className="wrapper-heading">
          <h4 className="color-White text-center">
            {currentUser?.userRoles.restaurant.name}
          </h4>
          <h4 className="color-White text-end">
            <CurrentDate />
          </h4>
        </div>
      </div>
    </header>
  )
}
