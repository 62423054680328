import React, { RefObject, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import moment from 'moment'
import 'moment/locale/ru'

moment.locale(process.env.REACT_APP_LANG || 'ru')

interface IAppDatePicker {
  onClose: () => void
  onChange: (value: Date | null) => void
  value: Date | null
  inputRef: RefObject<HTMLDivElement>
  maxDate?: null | Date
  minDate?: null | Date
}

export const AppDatePicker = ({
  onClose,
  onChange,
  value,
  inputRef,
  maxDate,
  minDate,
}: IAppDatePicker) => {
  const outSideClickHandler = (e: any) => {
    e.stopPropagation()
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target) &&
      !e.target.classList.contains('react-datepicker__day')
    ) {
      onClose()
    }
  }
  useEffect(() => {
    document.addEventListener('click', outSideClickHandler, false)
    document.addEventListener('touchend', outSideClickHandler, false)
    return () => {
      document.removeEventListener('click', outSideClickHandler, false)
      document.removeEventListener('touchend', outSideClickHandler, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="datepicker-container">
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          const month = moment(date).format('MMMM YYYY')
          return (
            <div className="react-datepicker__header-wrapper">
              <div className="react-datepicker__header-month-btn-block left">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <i className="an-ico an-ico-arrow-up-bold" />
                </button>
              </div>
              <div className="react-datepicker__header-month">{month}</div>
              <div className="react-datepicker__header-month-btn-block right">
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <i className="an-ico an-ico-arrow-up-bold" />
                </button>
              </div>
            </div>
          )
        }}
        dayClassName={(date) => {
          return date.toDateString() === new Date().toDateString()
            ? 'bg-color-Gray-medium'
            : ''
        }}
        selected={value}
        locale={process.env.REACT_APP_LANG || ru}
        inline
        onChange={(date) => onChange(date)}
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  )
}
