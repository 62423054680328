import { useIsAdmin } from '../../../../utils/useIsAdmin'
import {
  useGetPlanMenuControlOrderOnlyReadQuery,
  useGetPlanMenuControlOrderQuery,
} from '../../../../core/api/PlanMenuControlApi'

export const useGetPlanMenuDetailByRole = (id?: number) => {
  const isAdmin = useIsAdmin()

  const { data: currentOrder } = useGetPlanMenuControlOrderQuery(
    {
      id: id || 0,
    },
    { skip: !id || !isAdmin },
  )

  const { data: currentOrderOnlyRead } =
    useGetPlanMenuControlOrderOnlyReadQuery(
      {
        id: id || 0,
      },
      { skip: !id || isAdmin || isAdmin === null },
    )

  return isAdmin ? currentOrder : currentOrderOnlyRead
}
