import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { IOrderLine } from '../../../core/api/dto/OrderObjDto'

interface IPropsActiveOrderItem {
  info: IOrderLine
}

const ActiveOrderItem = ({ info }: IPropsActiveOrderItem) => {
  const navigate = useNavigate()
  const { orderLineId } = useParams()
  const setActiveDish = useCallback(() => {
    if (info.id !== Number(orderLineId)) {
      navigate(`${info.id}`)
    }
  }, [navigate, info.id, orderLineId])

  return (
    <div
      className={`order-item ${Number(orderLineId) === info.id ? 'check' : ''}`}
      onClick={setActiveDish}
    >
      <div className="wrapper-info">
        <div className="name clip">{info.dish.name}</div>
        <div className="list-info">
          <div className="item-info">
            <div>Выдано блюд: {info.orderLineDimensions.length}</div>
            <div>Количество порций: {info.quantity}</div>
          </div>
        </div>
      </div>
      <div className="wrapper-icon">
        {info.orderLineDimensions.length &&
        info.orderLineDimensions[0].photoUrl ? (
          <img
            src={`${info.orderLineDimensions[0].photoUrl}`}
            alt=""
            className="img-dish"
          />
        ) : (
          <i className="an-ico an-ico-photo" />
        )}
      </div>
    </div>
  )
}

export default ActiveOrderItem
