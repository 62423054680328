import moment from 'moment'
import 'moment/locale/ru'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { compareProps } from '../../utils'

export enum FormatDate {
  BASE_DATE = 'Y-MM-DD',
  FULL_DATE = 'Y-MMMM-D',
  FULL_MONTH = 'MMMM',
  YEAR = 'Y',
  DAY = 'D',
  SHORT_DAY_WEEK = 'dd',
}

export const initDate = (arg: string) => moment().format(arg)

const allYears = (starting: number) => {
  const current = Number(moment().format(FormatDate.YEAR))
  const arr = []
  for (let i = 0; i <= current - starting; i++) {
    if (i === 0) {
      arr.push(String(starting))
    } else {
      arr.push(String(starting + i))
    }
  }
  return arr
}

const getAllDays = (count: number) =>
  Array.from({ length: count }, (_, i) => String(++i))
const getFirstDayMonth = (month: string, year: string) =>
  moment(`${year}-${month}-1`, FormatDate.FULL_DATE).format(
    FormatDate.SHORT_DAY_WEEK,
  )

const DAYS_WEEK = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
const MONTHS = moment.months()
const NUMBER_MONTHS = 12

export enum CalendarSize {
  base = '',
  md = 'md',
}

interface ICalendarProps {
  value: string
  onChange: (val: string) => void
  size?: CalendarSize
}

interface IDate {
  year: string
  day: string
  month: string
}

const onChangeDateHandler = (date: IDate, onChange: (val: string) => void) =>
  onChange(
    moment(
      `${date.year}-${date.month}-${date.day}`,
      FormatDate.FULL_DATE,
    ).format(FormatDate.BASE_DATE),
  )

const getStateDate = (val: string) => {
  const date = moment(val)

  return {
    month: date.format(FormatDate.FULL_MONTH),
    year: date.format(FormatDate.YEAR),
    day: date.format(FormatDate.DAY),
  }
}
const Calendar = ({
  value,
  onChange,
  size = CalendarSize.base,
}: ICalendarProps) => {
  const [date, setDate] = useState<IDate>(getStateDate(value))
  const [days, setDays] = useState(getAllDays(moment().daysInMonth()))
  const [showYears, setShowYears] = useState(false)
  const [showMonths, setShowMonths] = useState(false)

  const startDayMonth = useMemo(
    () => getFirstDayMonth(date.month, date.year),
    [date.month, date.year],
  )
  const arrayDaysInMonth = useMemo(
    () => [
      ...Array.from({ length: DAYS_WEEK.indexOf(startDayMonth) }, (x) => ''),
      ...days,
    ],
    [startDayMonth, days],
  )

  const handlerChangeMonth = (params: number) => {
    const idx = MONTHS.indexOf(date!.month)

    if (!idx && !params) {
      setDate((state) => ({ ...state, month: MONTHS[NUMBER_MONTHS - 1] }))
    } else if (idx === NUMBER_MONTHS - 1 && params) {
      setDate((state) => ({ ...state, day: '1', month: MONTHS[0] }))
    } else {
      params
        ? setDate((state) => ({ ...state, day: '1', month: MONTHS[idx + 1] }))
        : setDate((state) => ({ ...state, day: '1', month: MONTHS[idx - 1] }))
    }
  }

  const handlerChangeYear = (params: string, e: any) => {
    e.stopPropagation()
    setDate((state) => ({ ...state, year: params }))
    setShowYears(!showYears)
  }

  const handlerChangeCurrentMonth = (month: string, e: any) => {
    e.stopPropagation()
    setDate((state) => ({ ...state, month }))
    setShowMonths(!showMonths)
  }

  const handlerChangeDay = useCallback(
    (day: string) => {
      setDate((state) => ({ ...state, day }))
      onChangeDateHandler({ ...date, day }, onChange)
    },
    [date, onChange],
  )

  const renderDays = useCallback(
    () =>
      arrayDaysInMonth.map((el, idx) => (
        <div className="item" key={`day-${idx}`}>
          {el && (
            <span
              className={el === date.day ? 'check' : ''}
              onClick={() => handlerChangeDay(el)}
            >
              {el}
            </span>
          )}
        </div>
      )),
    [arrayDaysInMonth, date.day, handlerChangeDay],
  )

  const setDaysInMonth = useCallback(() => {
    const res = getAllDays(
      moment(
        `${date.year}-${date.month}`,
        `${FormatDate.YEAR}-${FormatDate.FULL_MONTH}`,
      ).daysInMonth(),
    )

    if (res.length !== days.length) {
      setDays(res)
    }
  }, [date.month, date.year, days.length])

  useEffect(() => {
    setDaysInMonth()
    onChangeDateHandler(date, onChange)
  }, [date, setDaysInMonth, onChange])

  const onClickMonth = useCallback(() => {
    if (showYears) {
      setShowYears(!showYears)
    }

    setShowMonths(!showMonths)
  }, [showYears, showMonths])

  const onClickYear = useCallback(() => {
    if (showMonths) {
      setShowMonths(!showMonths)
    }

    setShowYears(!showYears)
  }, [showMonths, showYears])

  return (
    <div className={`calendar main-view ${size}`}>
      <div className="month">
        <i
          className="an-ico an-ico-arrow-l"
          onClick={() => handlerChangeMonth(0)}
        />
        <div className="current">
          <span onClick={onClickMonth}>{date.month}</span>
          <span onClick={onClickYear}>{date.year}</span>
        </div>
        <i
          className="an-ico an-ico-arrow-r"
          onClick={() => handlerChangeMonth(1)}
        />
      </div>
      <div className="wrapper-items column">
        {showYears ? (
          <div className="years">
            {allYears(2018).map((el) => (
              <div className="item" key={el}>
                <span
                  className={el === date.year ? 'check' : ''}
                  onClick={(e) => handlerChangeYear(el, e)}
                >
                  {el}
                </span>
              </div>
            ))}
          </div>
        ) : showMonths ? (
          <div className="months">
            {MONTHS.map((el) => (
              <div className="item" key={el}>
                <span
                  className={el === date.month ? 'check' : ''}
                  onClick={(e) => handlerChangeCurrentMonth(el, e)}
                >
                  {el}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="days-week">
              {DAYS_WEEK.map((name) => (
                <div className="day" key={name}>
                  {name}
                </div>
              ))}
            </div>
            <div className="days">{renderDays()}</div>
          </>
        )}
      </div>
    </div>
  )
}

export const CalendarMemoized = memo(Calendar, compareProps)

export default CalendarMemoized
