import React, { useEffect, useRef, useState } from 'react'
import { AppInput, IcoPosition } from '../AppInput/AppInput'
import { AppScrollWrapperNew } from '../../widgets/AppScrollWrapper/AppScrollWrapper'
import Checkbox from '../checkbox/Checkbox'
import {
  IBaseResponseDto,
  IWithPaginationRequestSearch,
} from '../../core/api/dto/BaseDto'
import AppBtn, { AppBtnColor, AppBtnRadius, AppBtnSize } from '../btn/AppBtn'
import { paginationHelper } from '../../utils/paginationHelper'
import moment from 'moment'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useOutsideClick } from '../../utils/useOutsideClick'
import { useTranslation } from 'react-i18next'

interface IAppDropdownBase<T, TKey extends keyof T> {
  disabled?: boolean
  error?: string | null
  label?: string
  placeholder?: string
  multiValue?: T[]
  value: T | null
  onChange: (value: T) => void
  propToShowInList?: TKey // если data массив строк, то не передаем propToShowInList и propToShowInInput
  extraPropToShowInList?: TKey
  sparePropToShowInList?: TKey
  propToShowInInput?: TKey
  resetValueHandler?: () => void
  paginationMode?: boolean
  border?: boolean
  fullWidth?: boolean
  minHeight?: boolean
  minWidth?: boolean
  isLoading?: boolean
  inputSearch?: string
  id?: string
  inputSearchFn?: (value: string) => void
  required?: boolean
  withTranslate?: boolean
}

interface IAppDropdown<T, TKey extends keyof T>
  extends IAppDropdownBase<T, TKey> {
  data: T[] // data - может быть объектом с ключами, в этом случае мы можем передать propToShowInList и propToShowInInput
  loadMoreCallBack?: never
  resetData?: never
  setResetData?: never
}

interface IData<T> extends IBaseResponseDto {
  results: T[] | []
}

interface IAppDropdownWithPagination<T, TKey extends keyof T>
  extends IAppDropdownBase<T, TKey> {
  loadMoreCallBack: (newPageParams: IWithPaginationRequestSearch) => void
  resetData?: boolean
  setResetData?: (value: boolean) => void
  data: IData<T>
}

type AppDropdownType<T, TKey extends keyof T> =
  | IAppDropdown<T, TKey>
  | IAppDropdownWithPagination<T, TKey>

export const AppDropdown = <T, TKey extends keyof T>({
  data,
  disabled = false,
  resetValueHandler,
  error,
  placeholder,
  label,
  propToShowInList,
  propToShowInInput,
  extraPropToShowInList,
  sparePropToShowInList,
  onChange,
  value,
  paginationMode = false,
  inputSearch,
  inputSearchFn,
  multiValue,
  fullWidth,
  border,
  loadMoreCallBack,
  setResetData,
  resetData,
  minWidth,
  isLoading,
  required,
  id,
  withTranslate,
}: AppDropdownType<T, TKey>) => {
  const { t } = useTranslation()
  const [active, setActive] = useState<boolean>(false)
  const [currentData, setCurrentData] = useState<T[] | []>([])
  const [isLoadMore, setIsLoadMore] = useState(false)

  const refDropDown = useRef<HTMLDivElement>(null)
  const refInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!loadMoreCallBack) {
      setCurrentData(data)
    }
    if (isLoadMore && loadMoreCallBack && !resetData) {
      setCurrentData((prev) => {
        return [...prev, ...data.results]
      })
      setIsLoadMore(false)
    } else if (loadMoreCallBack) {
      setCurrentData(data.results)
    }
    if (setResetData) {
      setResetData(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const nextPageParams = loadMoreCallBack
    ? paginationHelper({ next: data?.next })
    : {}

  const nextPageHandler = () => {
    if (!!loadMoreCallBack) {
      loadMoreCallBack(nextPageParams)
      setIsLoadMore(true)
    }
  }

  useOutsideClick(refDropDown, () => setActive(false), active)

  const onChangeHandler = (item: T | null) => {
    if (item) {
      onChange(item)
    } else if (resetValueHandler) {
      resetValueHandler()
    }
    setActive(!!multiValue)
  }

  const inputValue = !!value
    ? propToShowInInput
      ? `${value[propToShowInInput]}`
      : propToShowInList
      ? `${value[propToShowInList]}`
      : `${value}`
    : null

  return (
    <div
      className={`app-dropdown ${active ? 'active' : ''} ${
        paginationMode ? 'pagination-mode' : ''
      } ${fullWidth ? 'full-width' : ''} ${minWidth ? 'width-200' : ''}`}
      ref={refDropDown}
    >
      <AppInput
        onClick={() =>
          disabled
            ? false
            : setActive((prev) => {
                if (!prev) refInput.current?.focus()
                return !prev
              })
        }
        onChange={(value) => {
          if (!active) {
            setActive(true)
          }
          return inputSearchFn ? inputSearchFn(value) : false
        }}
        icoPosition={IcoPosition.right}
        value={withTranslate ? t(inputValue || '') : inputValue}
        dropdownInput={!disabled}
        error={error}
        placeholder={placeholder}
        dropdownActive={active}
        disabled={true}
        label={label}
        fullWidth={fullWidth}
        border={border}
        required={required || label === 'Инервал времени'}
      />

      <div
        className={`dropdown-values-block ${
          active && !!currentData ? 'activate-dropdown-values-block' : ''
        }`}
      >
        {isLoading && <AppLoader loaderType={LoaderType.dropdown} />}
        {inputSearchFn && (
          <AppInput
            ref={refInput}
            value={inputSearch}
            onChange={inputSearchFn}
            placeholder={'Поиск...'}
            fullWidth
            clear
            id={`drop-d-${id || ''}`}
          />
        )}
        {!!resetValueHandler && (
          <div
            className={`value-item reset-filter`}
            onClick={() => onChangeHandler(null)}
          >
            {t('Очистить фильтр')}
          </div>
        )}
        <div className={'value-items'} id="zero">
          <AppScrollWrapperNew theme="dropdown">
            {currentData.map((item, index) => {
              const itemValue = propToShowInList
                ? extraPropToShowInList
                  ? `${
                      item[propToShowInList]
                        ? item[propToShowInList]
                        : sparePropToShowInList && item[sparePropToShowInList]
                    } (${
                      typeof item[extraPropToShowInList] === 'string'
                        ? moment(`${item[extraPropToShowInList]}`).format(
                            'DD.MM.YYYY',
                          )
                        : item[extraPropToShowInList]
                    })`
                  : item[propToShowInList]
                : item
              const multiItem =
                !!multiValue &&
                multiValue.find(
                  (multiI: T) =>
                    propToShowInInput &&
                    item[propToShowInInput] === multiI[propToShowInInput],
                )
              return !!multiValue ? (
                <div
                  onClick={() => onChangeHandler(item)}
                  className={'value-item'}
                  id={`dd-value-${index}`}
                  key={`dropdown-active${index}`}
                >
                  <Checkbox
                    value={!!multiItem}
                    onChange={() => {}}
                    name={`multi-value-${index}-${itemValue}`}
                    text={`${itemValue}` as string}
                  />
                </div>
              ) : (
                <div
                  onClick={() => onChangeHandler(item)}
                  className={'value-item'}
                  key={`dropdown-active${index}`}
                >
                  {withTranslate ? t(`${itemValue || ''}`) : itemValue}
                </div>
              )
            })}
            {!!loadMoreCallBack && !!data?.next && (
              <div onClick={(e) => e.stopPropagation()} className="mt-10">
                <AppBtn
                  title="Показать ещё"
                  sized={AppBtnSize.fullWidth}
                  color={AppBtnColor.lightViolet}
                  radius={AppBtnRadius.base}
                  onClick={nextPageHandler}
                />
              </div>
            )}
          </AppScrollWrapperNew>
        </div>
      </div>
    </div>
  )
}
