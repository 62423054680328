import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from '../../core/store/store'
import AppBtn, { AppBtnColor, AppBtnRadius, AppBtnSize } from '../btn/AppBtn'
import { TProps } from '../../widgets/header/NavWaiterPage'
import InputField, { InputSize } from '../input/InputField'
import KeyboardWrapper, { Lang } from '../keyboardWrapper/KeyboardWrapper'
import ReactDOM from 'react-dom'
import { PopupTypeEnum, getRootPopup } from '../popup/Popup'

interface IPropsPagination {
  countPage: number
  next: boolean
  prev: boolean
  offset: number
  changePage: (val: string) => void
  limit: number
  maxCountBtn?: number
  isFetching?: boolean
}

const Pagination = ({
  countPage,
  next,
  prev,
  offset,
  changePage,
  limit,
  maxCountBtn = 5,
  isFetching = false,
}: IPropsPagination) => {
  const [keyboardVisibility, setKeyboardVisibility] = useState(false)
  const keyboard = useRef<any>(null)
  const { isKeyboard } = useAppSelector((state) => state.buffet)
  const [value, setValue] = useState(offset ? `${offset / limit + 1}` : '1')
  const isValidValue = useMemo(
    () => +value > 0 && +value <= countPage,
    [value, countPage],
  )
  const handlerChangedPage = useCallback(
    (arg: TProps | number) => {
      if (arg === 'next' && next && !isFetching && isValidValue) {
        changePage(String(offset + limit))
        setValue(`${+value + 1}`)

        if (keyboardVisibility) {
          keyboard.current.setInput(`${+value + 1}`)
        }
      } else if (prev && arg === 'previous' && !isFetching && isValidValue) {
        changePage(String(offset - limit))
        setValue(`${+value - 1}`)

        if (keyboardVisibility) {
          keyboard.current.setInput(`${+value - 1}`)
        }
      } else if (typeof arg === 'number') {
        changePage(`${arg * limit - limit}`)
        setValue(String(arg))

        if (keyboardVisibility) {
          keyboard.current.setInput(`${arg * limit - limit}`)
        }
      } else {
        setValue(`${offset / limit + 1}`)

        if (keyboardVisibility) {
          keyboard.current.setInput(`${offset / limit + 1}`)
        }
      }
    },
    [
      next,
      prev,
      offset,
      value,
      limit,
      keyboardVisibility,
      isFetching,
      isValidValue,
      changePage,
    ],
  )
  const onEnterHandler = useCallback(() => {
    if (isValidValue) {
      changePage(`${+value * limit - limit}`)
    } else {
      setValue(offset ? `${offset / limit + 1}` : '1')

      if (keyboardVisibility) {
        keyboard.current.setInput(offset ? `${offset / limit + 1}` : '1')
      }
    }
  }, [value, offset, limit, keyboardVisibility, changePage, isValidValue])

  const onChangeHandler = (val: string) => {
    setValue(val)

    if (keyboardVisibility && keyboard) {
      keyboard.current.setInput(val)
    }
  }

  const onChangeKeyboard = (val: string) => setValue(val)

  const showKeyboard = () => {
    if (isKeyboard && countPage > maxCountBtn) {
      setKeyboardVisibility(true)
    }
  }

  const Keyboard = useMemo(() => {
    return ReactDOM.createPortal(
      <div className="wrapper-keyboard">
        <div className="keyboard-elements">
          <AppBtn
            iconClassName="close"
            radius={AppBtnRadius.round}
            color={AppBtnColor.violetWhite}
            onClick={() => setKeyboardVisibility(!keyboardVisibility)}
          />
          <KeyboardWrapper
            onChange={onChangeKeyboard}
            keyboard={keyboard}
            lang={Lang.NUM}
            handlerKeyEnter={onEnterHandler}
          />
        </div>
      </div>,
      getRootPopup(PopupTypeEnum.default),
    )
  }, [keyboardVisibility, onEnterHandler])

  useEffect(() => {
    if (value && !keyboard.current?.getInput() && keyboardVisibility) {
      keyboard.current.setInput(value)
    }
  }, [value, keyboardVisibility])

  return (
    <div className="pagination">
      <div className="btn-block">
        {countPage <= maxCountBtn &&
          countPage !== 1 &&
          [...Array(countPage)].map((_, idx) => (
            <AppBtn
              key={idx}
              color={
                offset / limit === idx
                  ? AppBtnColor.outlineViolet
                  : AppBtnColor.violetWhite
              }
              sized={AppBtnSize.sm}
              title={`${idx + 1}`}
              onClick={() => changePage(String(idx ? idx * limit : idx))}
            />
          ))}
        {countPage > maxCountBtn && (
          <>
            <AppBtn
              color={
                !prev ? AppBtnColor.outlineViolet : AppBtnColor.violetWhite
              }
              sized={AppBtnSize.sm}
              title={`${1}`}
              onClick={() => handlerChangedPage(1)}
            />
            <AppBtn
              color={
                prev ? AppBtnColor.violetWhite : AppBtnColor.violetLightWhite
              }
              sized={AppBtnSize.sm}
              iconClassName="arrow-l"
              onClick={() => handlerChangedPage('previous')}
            />
            <InputField
              sized={InputSize.sm}
              value={value}
              onChange={onChangeHandler}
              onPressEnter={onEnterHandler}
              onFocus={showKeyboard}
              type="number"
            />
            <AppBtn
              color={
                next ? AppBtnColor.violetWhite : AppBtnColor.violetLightWhite
              }
              sized={AppBtnSize.sm}
              iconClassName="arrow-r"
              onClick={() => handlerChangedPage('next')}
            />
            <AppBtn
              color={
                !next ? AppBtnColor.outlineViolet : AppBtnColor.violetWhite
              }
              sized={AppBtnSize.sm}
              title={`${countPage}`}
              onClick={() => handlerChangedPage(countPage)}
            />
          </>
        )}
      </div>
      {keyboardVisibility && Keyboard}
    </div>
  )
}

export default Pagination
