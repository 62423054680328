import React from 'react'

interface IPropsSourceInfo {
  source: string
  sourceName: string
}
const SourceInfo = ({ source, sourceName }: IPropsSourceInfo) => {

  return (
    <div className="source-info">
      <div className="item">
        Источник: <span>{source}</span>
      </div>
      <div className="item">
        Наименование: <span>{sourceName}</span>
      </div>
    </div>
  )
}

export default SourceInfo
