import { useIsAdmin } from '../../../../utils/useIsAdmin'
import {
  useGetPlanMenuControlDimensionOnlyReadQuery,
  useGetPlanMenuControlDimensionQuery,
} from '../../../../core/api/PlanMenuControlApi'
import { IPlanMenuControlOrdersRequest } from '../../../../core/api/dto/PlanMenuControlDto'

export const useGetDimensionListByRole = (
  pageParams?: IPlanMenuControlOrdersRequest | null,
  currentDishId?: string | null,
  dishId?: string,
) => {
  const isAdmin = useIsAdmin()

  const { data: dimensionList } = useGetPlanMenuControlDimensionQuery(
    { id: Number(currentDishId ? currentDishId : dishId), ...pageParams },
    {
      skip:
        (!currentDishId && !dishId) || isAdmin === false || isAdmin === null,
    },
  )
  const { data: dimensionListOnlyRead } =
    useGetPlanMenuControlDimensionOnlyReadQuery(
      {
        order_line: Number(currentDishId ? currentDishId : dishId),
        ...pageParams,
      },
      {
        skip: (!currentDishId && !dishId) || isAdmin || isAdmin === null,
      },
    )

  return isAdmin ? dimensionList : dimensionListOnlyRead?.results
}
