import React, { useState } from 'react'
import {
  ImagePreviewWrapper,
  ImagePreviewWrapperSizeEnum,
} from 'UiKitComponents/ImagePreviewWrapper/ImagePreviewWrapper'
import {
  IBuffetGeneralReportResult,
} from 'core/api/dto/BuffetGeneralReportDto'
import { AppTableMobile } from 'components/AppTable/AppTableMobile'
import { ReportTempView } from 'pages/BuffetLinePage/ReportPage/ReportPage'
import { SetURLSearchParams } from 'react-router-dom'
import { SubTableRepresentFull } from './SubTableRepresentFull'

const headerData = [
  { title: 'Станция', colWidth: '10%' },
  { title: 'Название блюда', colWidth: '22%', sort: 'name' },
  { title: 'Категория', colWidth: '3%', sort: 'categoryName' },
  { title: 'Дата', colWidth: '15%' },
  { title: 'Время', colWidth: '3%' },
  { title: 'Название план-меню', colWidth: '3%' },
  { title: 'Тип план-меню', colWidth: '3%' },
  { title: 'Тип гостя', colWidth: '3%' },
  { title: 'Тип замера', colWidth: '3%' },
  { title: 'Корреспондент', colWidth: '3%' },
  { title: 'Кол-во замеров', colWidth: '3%', sort: 'dimensionsCount' },
  { title: 'Порядковый номер замера', colWidth: '3%' },
  { title: 'Вес заявки, кг', colWidth: '3%', sort: 'plannedWeight' },
  { title: 'Вес факт, кг', colWidth: '3%', sort: 'actualWeight' },
  { title: 'Разница вес, кг', colWidth: '3%', sort: 'weightDiff' },
  {
    title: 'Кол-во заявленных гостей',
    colWidth: '3%',
    sort: 'plannedPersonsAmount',
  },
  {
    title: 'Кол-во указанных гостей',
    colWidth: '3%',
    sort: 'actualPersonsAmount',
  },
  {
    title: 'Разница кол-во гостей',
    colWidth: '3%',
    sort: 'personsAmountDiff',
  },
  { title: 'Вес на персону', colWidth: '3%', sort: 'weightPerPerson' },
  {
    title: 'Мин темп эталонного блюда',
    colWidth: '3%',
    sort: 'standardTempMin',
  },
  {
    title: 'Макс темп эталонного блюда',
    colWidth: '3%',
    sort: 'standardTempMax',
  },
  {
    title: 'Температура факт. (мин/ср/макс), °C',
    colWidth: '25%',
    sort: 'temperatureAvg',
  },
  { title: 'Соответствие темп режиму ', colWidth: '3%' },
  { title: 'Фото', colWidth: '5%' },
]

interface IReportFullProps {
  reportData: IBuffetGeneralReportResult[]
  setSearchParams: SetURLSearchParams
  setCurrentSortValue: React.Dispatch<React.SetStateAction<string | null>>
}

export const ReportFull = ({reportData, setSearchParams, setCurrentSortValue} : IReportFullProps) => {
  const [toggleAllState, setToggleAllState] = useState<number[]>([])

  const subTableRepresentFn = (
    item: IBuffetGeneralReportResult,
    currentCol?: number,
    itemIndex?: number,
    onTouchStart?: ((e: React.TouchEvent) => void) | undefined,
    onTouchMove?: ((e: React.TouchEvent) => void) | undefined,
    width?: number,
    toggleAllState?: number[],
  ) => (
    <SubTableRepresentFull
      item={item}
      currentCol={currentCol}
      itemIndex={itemIndex}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      width={width}
      toggleAllState={toggleAllState}
      toggleAllStateFullLenght={reportData.length}
    />
  )

  return (
    <React.Fragment>
      {reportData && (
        <AppTableMobile<
          IBuffetGeneralReportResult,
          keyof IBuffetGeneralReportResult
        >
          toggleAllState={toggleAllState}
          setToggleAllState={setToggleAllState}
          data={{
            count: 0,
            results: reportData,
            previous: '',
            next: '',
          }}
          tableWrapperClassName={'buffet-report-table'}
          tableCellClassName={'buffet-report-page-table-cell'}
          rowClassName={'buffet-report-page-table-category'}
          setCurrentSortFn={setCurrentSortValue}
          tableDataSelectors={[
            {
              renderItem: (item, index) => (
                <div
                  className={
                    index
                      ? 'violet-sticky'
                      : 'buffet-report-page-table-category-title'
                  }
                ></div>
              ),
            },
            {
              renderItem: (item) => (
                <div>{item?.name}</div>
              ),
            },
            {
              renderItem: (item) => (
                <div>{item?.categoryName}</div>
              ),
            },
            {
              renderItem: () => <div className={`text-end`}>{}</div>,
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>{item.dimensionsCount}</div>
              ),
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.plannedWeight.toFixed(3)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.actualWeight.toFixed(3)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>{item?.weightDiff?.toFixed(3)}</div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {+item?.plannedPersonsAmount.toFixed(1)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {+item?.actualPersonsAmount.toFixed(1)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.personsAmountDiff}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.weightPerPerson?.toFixed(3)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>{item.standardTempMin}</div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>{item.standardTempMax}</div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {!!((item.standardTempMin || item.standardTempMin === 0) && (item.standardTempMax || item.standardTempMax === 0)) ? (
                    <>
                      <ReportTempView
                        value={item.temperatureMin}
                        min={item.standardTempMin}
                        max={item.standardTempMax}
                      />{' '}
                      {item.temperatureAvg !== null && (
                        <>
                          {'- '}
                          <ReportTempView
                            value={item.temperatureAvg}
                            min={item.standardTempMin}
                            max={item.standardTempMax}
                          />
                        </>
                      )}
                      {item.temperatureMax !== null && (
                        <>
                          {'- '}
                          <ReportTempView
                            value={item.temperatureMax}
                            min={item.standardTempMin}
                            max={item.standardTempMax}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <div style={{minWidth: 150}}>
                      {`${item.temperatureMin.toFixed(
                        2,
                      )}-${item.temperatureAvg.toFixed(
                        2,
                      )}-${item.temperatureMax.toFixed(2)}`}
                    </div>
                  )}
                </div>
              ),
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  <ImagePreviewWrapper
                    className={'report-image-preview'}
                    onClick={() => {
                      setSearchParams((sp) => {
                        sp.set('report-image-id', item.id.toString())
                        return sp
                      })
                    }}
                    isReportPageItem={true}
                    image={item.imageOnS3 || item.image}
                    size={ImagePreviewWrapperSizeEnum.MEDIUM}
                  />
                </div>
              ),
            },
          ]}
          subTableRepresent={subTableRepresentFn}
          headerData={headerData.map((item) => item)}
        />
      )}
      </React.Fragment>
  )
}
