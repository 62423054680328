import React, { useEffect, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { CommentBlock } from '../PlanMenuControl/components/CommentBlock'
import { useParamsControl } from '../../../utils/useParamsControl'
import {
  IPlanMenuCloseOrdersRequest,
  IUpdatePlanMenuCloseOrderDto,
} from '../../../core/api/dto/PlanMenuCloseDto'
import { PlanMenuCloseList } from './components/PlanMenuListClose'
import { HistoryListBlock } from './components/HistoryListBlock'
import { ITag } from '../../../core/api/dto/TagsDto'
import { PlanMenuCloseTagsBlock } from './components/PlanMenuCloseTagsBlock'
import {
  useGetPlanMenuCloseOrderQuery,
  useUpdatePlanMenuCloseOrderMutation,
} from '../../../core/api/PlanMenuCloseApi'
import { useGetParamQuery } from '../../../utils/useGetParamQuery'
import { AppInput, InputMaskType } from '../../../components/AppInput/AppInput'
import { PlanMenuStatusType } from '../../../core/api/dto/PlanMenuControlDto'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AppFilterDateInput } from '../../../components/AppInput/AppFilterDateInput'
import { useIsAdmin } from '../../../utils/useIsAdmin'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'
import './PlanMenuClosePage.scss'

export const PlanMenuClosePage = () => {
  const { t } = useTranslation()
  const { width } = useGetWidth()
  const pageParams = useParamsControl<
    IPlanMenuCloseOrdersRequest,
    keyof IPlanMenuCloseOrdersRequest
  >({
    withPagination: true,
    whiteList: [
      'search',
      'order_date_execution_gte',
      'order_date_execution_lte',
    ],
  })
  const isAdmin = useIsAdmin()
  const orderId = useGetParamQuery('order')
  const { data: orderDetail } = useGetPlanMenuCloseOrderQuery(
    { id: Number(orderId || 0) },
    { skip: !orderId },
  )
  const [updateOrder] = useUpdatePlanMenuCloseOrderMutation()

  const [editMode, setEditMode] = useState<boolean>(false)
  const [localComments, setLocalComments] = useState<string>('')
  const [comments, setComments] = useState<string>('')
  const [tags, setTags] = useState<ITag[]>([])
  const [numberOfSold, setNumberOfSold] = useState<number>(0)
  const [numberOfPersonsFact, setNumberOfPersonsFact] = useState<number>(0)

  const navigate = useNavigate()
  const startDateString = moment(orderDetail?.orderDateStart).format(
    'YYYY-MM-DD',
  )
  const endDateString = moment(orderDetail?.orderDateStart).format('YYYY-MM-DD')

  const redirectString = `/buffet-report-vz/line-report/?report_type=full&dimension_date_lte=${startDateString}&dimension_date_gte=${endDateString}&go_back_pl=true`
  useEffect(() => {
    if (!!orderDetail) {
      setComments(orderDetail?.comment || '')
      setTags(orderDetail.orderTags)
      setNumberOfSold(orderDetail.numberOfSold || 0)
      setNumberOfPersonsFact(orderDetail.numberOfPersonsFact || 0)
    }
  }, [orderDetail])

  const updateOrderHandler = () => {
    if (!!orderId && editMode) {
      const newOrder: IUpdatePlanMenuCloseOrderDto = {
        comment: localComments || ' ',
        numberOfPersonsFact,
        numberOfSold,
        orderTags: tags.map((tag) => tag.id),
      }
      const data: IUpdatePlanMenuCloseOrderDto = Object.fromEntries(
        Object.entries(newOrder).filter(([key, value]) => !!key && !!value),
      )
      updateOrder({ id: Number(orderId), data })
        .unwrap()
        .then(() =>
          AppNotification({
            msg: t('План-меню отредактирован!'),
            type: NotificationType.success,
          }),
        )
    }
    setEditMode((prevState) => !prevState)
  }
  const closePlanMenu = () => {
    if (!!orderId) {
      const isOpen = orderDetail?.planMenuStatus === PlanMenuStatusType.Opened
      const newStatus: IUpdatePlanMenuCloseOrderDto = {
        planMenuStatus: isOpen
          ? PlanMenuStatusType.Closed
          : PlanMenuStatusType.Opened,
      }
      updateOrder({ id: Number(orderId), data: newStatus })
        .unwrap()
        .then(() =>
          AppNotification({
            msg: t(`План-меню успешно ${isOpen ? 'закрыт' : 'открыт'}!`),
            type: NotificationType.success,
          }),
        )
    }
  }

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Перейти в отчет',
        onClick: () => navigate(redirectString),
        color: AppBtnColor.lightViolet,
        disabled: orderDetail?.planMenuStatus === PlanMenuStatusType.Opened,
      },
      {
        btnTitle: editMode ? 'Сохранить' : 'Редактировать',
        onClick: updateOrderHandler,
        color: AppBtnColor.lightViolet,
        disabled: orderDetail?.planMenuStatus === PlanMenuStatusType.Closed,
      },
      {
        btnTitle:
          orderDetail?.planMenuStatus === PlanMenuStatusType.Opened
            ? 'Закрыть план-меню'
            : 'Открыть план-меню',
        onClick: closePlanMenu,
        color: AppBtnColor.violetWhite,
        disabled: editMode,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      orderDetail,
      localComments,
      tags,
      numberOfSold,
      numberOfPersonsFact,
      orderId,
      editMode,
      closePlanMenu,
      updateOrderHandler,
    ],
  )

  return (
    <div className="width-for-page-wrapper plan-menu-close-page">
      <AppTitlePage
        title={'Закрытие план-меню'}
        actions={orderId && orderDetail ? actions : undefined}
      />
      <div className="filter-block mt-20">
        {isAdmin && (
          <div>
            <label>{t('Период исполнения')}</label>
            <div className="input-row mt-10">
              <AppFilterDateInput searchParam={'order_date_execution_gte'} />
              <AppFilterDateInput searchParam={'order_date_execution_lte'} />
            </div>
          </div>
        )}
        {orderDetail && orderId && (
          <React.Fragment>
            <AppInput
              value={`${numberOfPersonsFact}`}
              onChange={(value) => setNumberOfPersonsFact(Number(value))}
              label="Фактическое количество гостей"
              inputMask={InputMaskType.integer}
              placeholder="Введите количество гостей"
              disabled={
                orderDetail?.planMenuStatus === PlanMenuStatusType.Closed ||
                !editMode
              }
              maxLength={6}
            />
            <AppInput
              value={`${numberOfSold}`}
              onChange={(value) => setNumberOfSold(Number(value))}
              label="Количество проданных (дополнительные)"
              placeholder="Введите количество проданных (дополнительные)"
              inputMask={InputMaskType.integer}
              disabled={
                orderDetail?.planMenuStatus === PlanMenuStatusType.Closed ||
                !editMode
              }
              maxLength={6}
            />
          </React.Fragment>
        )}
      </div>
      <div className="plan-menu-control-page close mt-15">
        <div className={`width-${width <= 1024 ? 100 : 25}`}>
          <PlanMenuCloseList
            pageParams={pageParams}
            setEditMode={setEditMode}
          />
        </div>
        <div className={`width-${width <= 1024 ? width<=768 ? 100 : 50 : 37}`}>
          {orderDetail && orderId && (
            <div className="dishes-list-wrap">
              <div className="user-block">
                <CommentBlock
                  editMode={editMode}
                  commentMain={comments}
                  updateComment={setComments}
                  disabled={
                    orderDetail?.planMenuStatus === PlanMenuStatusType.Closed ||
                    !editMode
                  }
                  icoNone
                  getComment={setLocalComments}
                />
              </div>
              <PlanMenuCloseTagsBlock
                tags={tags}
                setTags={setTags}
                disabled={
                  orderDetail?.planMenuStatus === PlanMenuStatusType.Closed ||
                  !editMode
                }
              />
            </div>
          )}
        </div>
        <div className={`width-${width <= 1024 ? width<=768 ? 100 : 50 : 37}`}>
          {orderDetail && orderId && (
            <HistoryListBlock
              historyItems={orderDetail.orderPlanMenuStatusHistory}
            />
          )}
        </div>
      </div>
    </div>
  )
}
