import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppInput } from './AppInput'
import moment from 'moment'
import { resetPaginationByParamsChanged } from '../../utils/resetPaginationByParamsChanged'

export interface IAppFilterInput {
  searchParam: string
  placeholder?: string
  label?: string
  defaultValue?: string
  shouldResetPagination?: boolean
  maxValue?: string
  minValue?: string
  fullWidth?: boolean
}

export const AppFilterDateInput = ({
  searchParam,
  label,
  placeholder,
  shouldResetPagination = true,
  maxValue,
  minValue,
  fullWidth,
  defaultValue,
}: PropsWithChildren<IAppFilterInput>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [state, setState] = useState(searchParams.get(searchParam) || null)

  const currentSearchParamValue = searchParams.get(searchParam) as string

  useEffect(() => {
    if (!!currentSearchParamValue) {
      setState(!!currentSearchParamValue ? currentSearchParamValue : '')
    } else if (defaultValue) {
      setState(defaultValue)
    } else {
      setState('')
    }
  }, [currentSearchParamValue, defaultValue])

  const changeHandler = useCallback(
    (value: string) => {
      if (!!value) {
        setState(moment(value).format('YYYY-MM-DD'))
        searchParams.set(searchParam, moment(value).format('YYYY-MM-DD'))
      } else {
        setState('')
        searchParams.delete(searchParam)
      }
      setSearchParams(searchParams.toString())
      if (shouldResetPagination) {
        resetPaginationByParamsChanged({ searchParams, setSearchParams })
      }
    },
    [searchParams, setSearchParams, searchParam],
  )

  return (
    <AppInput
      value={state}
      onChange={changeHandler}
      label={label}
      placeholder={placeholder}
      type={'date'}
      fullWidth={fullWidth}
      maxValue={maxValue}
      minValue={minValue}
    />
  )
}
