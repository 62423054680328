import React from 'react'
import { AppTable } from '../../../components/AppTable/AppTable'
import { StationProgressBar } from './StationProgressBar'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { CpuBlock } from './CpuBlock'
import { useGetStationsQuery } from '../../../core/api/StationsApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import { IStationsRequestDto } from '../../../core/api/dto/StationsDto'
import { AppTableStatusStation } from 'components/AppTable/AppTableStatus/AppTableStatusStation/AppTableStatusStation'
import { AppTableLink } from '../../../components/AppTable/components/AppTableLink'
import { useTranslation } from 'react-i18next'

const headerData = [
  { title: 'Наименование станции', colWidth: '45%', sort: 'name' },
  { title: 'Статус', colWidth: '10%', sort: 'status' },
  { title: 'Отображение загрузки CPU', colWidth: '15%', sort: 'cpu_usage' },
  { title: 'Память устройства', colWidth: '20%', sort: 'disk_free' },
]

const percent = (total: number, value: number) => {
  if (!!total && !!value) {
    return (value / total) * 100
  }
  return 0
}

export const StationsPage = () => {
  const { t } = useTranslation()
  const pageParams = useParamsControl<
    IStationsRequestDto,
    keyof IStationsRequestDto
  >({
    withPagination: true,
    whiteList: ['search', 'ordering'],
  })
  const { data: stationList } = useGetStationsQuery(
    { ...pageParams },
    { skip: !pageParams },
  )

  if (!stationList) {
    return null
  }
  return (
    <div className="width-for-page-wrapper">
      <AppTitlePage title="Станции" />
      <AppTable
        data={stationList}
        headerData={headerData}
        idName="stations"
        tableDataSelectors={[
          {
            renderItem: (item) => (
              <AppTableLink path={`${item.id}`} deleteItem={!item.status}>
                {item.name}
              </AppTableLink>
            ),
          },
          {
            renderItem: (item) => (
              <AppTableStatusStation
                id={item.id}
                isDelete={!item.status}
                station
              />
            ),
          },
          {
            renderItem: (item) => (
              <AppTableLink path={`${item.id}`} deleteItem={!item.status}>
                <CpuBlock value={item.cpuUsage} isDelete={!item.status} />
              </AppTableLink>
            ),
          },
          {
            renderItem: (item) => (
              <AppTableLink path={`${item.id}`} deleteItem={!item.status}>
                <StationProgressBar
                  value={percent(item.diskTotal, item.diskUsed)}
                  text={`${item.diskUsed || 0} ${t('Гб')} ${t('из')} ${
                    item.diskTotal || 0
                  }${t('Гб')}`}
                  label="Дисковое пространство"
                />
              </AppTableLink>
            ),
          },
        ]}
      />
    </div>
  )
}
