import { api, LIMIT } from './BaseApi'
import {
  ICategoriesRequestDto,
  ICategoriesResponseDto,
  ICategory,
} from './dto/CategoriesObjDto'
import { IBuffetOrderCategoriesResponseDto } from './dto/BuffetOrderDto'

export const categoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query<ICategoriesResponseDto, ICategoriesRequestDto>({
      query: (params) => ({
        url: '/category/',
        params: !!params ? params : {},
      }),
      providesTags: ['Category'],
    }),
    getCategoryByRestaurant: builder.query<ICategoriesResponseDto, string>({
      query: (id) => ({
        url: '/category/',
        params: {
          restaurant: id,
          limit: LIMIT.base,
          ordering: 'name',
        },
      }),
      providesTags: ['Category'],
    }),
    getCategoryById: builder.query<ICategory, number>({
      query: (id) => ({
        url: `/category/${id}/`,
      }),
      providesTags: ['Category'],
    }),
    getCategoryByName: builder.query<ICategoriesResponseDto, string>({
      query: (name) => ({
        url: '/category/',
        params: {
          name,
        },
      }),
      providesTags: ['Category'],
    }),
    getCategoriesFromOrders: builder.query<
      IBuffetOrderCategoriesResponseDto,
      { order_ids: string }
    >({
      query: (params) => ({
        url: '/category/get_categories_from_orders/',
        params: !!params ? params : {},
      }),
      providesTags: ['Order', 'OrderLine'],
    }),
    addCategory: builder.mutation<ICategory, Partial<ICategory>>({
      query: (body) => ({
        url: '/category/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Category'],
    }),
    updateCategory: builder.mutation<ICategory, Partial<ICategory>>({
      query: (body) => ({
        url: `/category/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Category'],
    }),
    archiveMultipleCategory: builder.mutation<
      ICategory,
      { categories: number[] }
    >({
      query: ({ categories }) => ({
        url: `category/archive_multiple_categories/`,
        method: 'PATCH',
        body: { categories },
      }),
      invalidatesTags: ['Category'],
    }),
    deleteCategory: builder.mutation<void, number>({
      query: (id) => ({
        url: `/category/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Category'],
    }),
  }),
})

export const {
  useGetCategoryQuery,
  useGetCategoryByRestaurantQuery,
  useAddCategoryMutation,
  useGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useArchiveMultipleCategoryMutation,
  useGetCategoriesFromOrdersQuery,
} = categoryApi
