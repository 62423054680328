import React from 'react'
import { useGetVersionsQuery } from '../../core/api/VersionsApi'
import version from '../../version.json'

const Versions = () => {
  const { data: versions } = useGetVersionsQuery(null)

  return (
    <div className="versions-list color-White">
      {versions?.backendVersion && (
        <div className="item-version">Backend: v{versions.backendVersion}</div>
      )}
      {versions?.serviceSensorVersion && (
        <div className="item-version">
          Raspberry: v{versions.serviceSensorVersion}
        </div>
      )}
      <div className="item-version">Frontend: v{version.version}</div>
    </div>
  )
}

export default Versions
