import React from 'react'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import { useSearchParams } from 'react-router-dom'
import {
  IPlanMenuControlDimensionDto,
  IPlanMenuControlOrdersRequest,
} from '../../../../core/api/dto/PlanMenuControlDto'
import { getTimeFromMins } from '../../DishesPage/CreateDishPage'
import moment from 'moment'
import { useParams } from 'react-router'
import './FrozeList.css'
import { AddTaskItemButton } from 'UiKitComponents/EmployeeTask/AddTaskItemButton/AddTaskItemButton'
import { useCreateTaskObjectMutation } from 'core/api/TaskApi'
import {
  AppNotification,
  NotificationType,
} from 'components/notification/Notification'
import { useGetDimensionListByRole } from '../hooks/useGetDimensionListByRole'
import { useTranslation } from 'react-i18next'

interface IFrozeListProps {
  pageParams?: IPlanMenuControlOrdersRequest | null
  withTaskAddButton?: boolean
}

export const FrozeList = ({
  pageParams,
  withTaskAddButton,
}: IFrozeListProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { dishId } = useParams()
  const currentDishId = searchParams.get('dish')

  const dimensionList = useGetDimensionListByRole(
    pageParams,
    currentDishId,
    dishId,
  )

  return (
    <div className="froze-list-wrap">
      <AppScrollWrapperNew theme="dropdown">
        {!!dimensionList && (!!currentDishId || dishId) && (
          <React.Fragment>
            {[...dimensionList]
              .reverse()
              .map((item, index) => (
                <FrozeItem
                  dimensionItem={item}
                  index={index + 1}
                  key={`${item.id}-fr-${index}`}
                  withTaskAddButton={withTaskAddButton}
                />
              ))
              .reverse()}
            {!dimensionList.length && (
              <div>{t('Замеров по данному заказу нет')}</div>
            )}
          </React.Fragment>
        )}
      </AppScrollWrapperNew>
    </div>
  )
}

interface IFrozeItemProps {
  dimensionItem: IPlanMenuControlDimensionDto
  index: number
  withTaskAddButton?: boolean
}

const FrozeItem = ({
  dimensionItem,
  index,
  withTaskAddButton,
}: IFrozeItemProps) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentDimensionId = searchParams.get('dimension')?.split('_')[0] || 0
  const onDimensionHandler = () => {
    searchParams.set('dimension', `${dimensionItem.id}`)
    searchParams.set('index', `${index}`)
    setSearchParams(searchParams.toString())
  }

  const [createTaskObject] = useCreateTaskObjectMutation()

  const onAddTaskObjectClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    createTaskObject({ orderLineDimension: dimensionItem.id })
      .unwrap()
      .then(() => {
        AppNotification({
          msg: 'Объект успешно добавлен',
          type: NotificationType.success,
        })
      })
  }

  return (
    <div
      className={`froze-item${
        dimensionItem.id === Number(currentDimensionId || 0) ? ' active' : ''
      }`}
      onClick={onDimensionHandler}
    >
      <div className="number">
        {dimensionItem.id === Number(currentDimensionId) &&
        withTaskAddButton ? (
          <AddTaskItemButton onClick={onAddTaskObjectClick} />
        ) : (
          <span>{index}</span>
        )}
      </div>
      <div className="froze-info">
        <div className="froze">
          <span>{t('Замер')}</span>
          <div className="dotes" />
          <span>
            <b>{moment(dimensionItem.dimensionDatetime).format('HH:mm')}</b>
          </span>
        </div>
        <div
          className={`realization${
            dimensionItem.implementationTimeExceeded ? ' error' : ''
          }`}
        >
          <span>{t('Реализация')}</span>
          <div className="dotes" />
          <span>
            <b>
              {!!dimensionItem.implementationTimeExceeded
                ? getTimeFromMins(dimensionItem.implementationTimeExceeded)
                : getTimeFromMins(dimensionItem.implementationTime)}
            </b>
          </span>
        </div>
      </div>
    </div>
  )
}
