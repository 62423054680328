import { api } from './BaseApi'
import {
  ICompareReportRequest,
  ICompareReportResponse,
} from './dto/CompareReportDto'

export const compareReportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompareReport: builder.query<
      ICompareReportResponse,
      ICompareReportRequest
    >({
      query: (params) => ({
        url: '/compare_report/',
        params: !!params ? params : {},
      }),
      providesTags: ['CompareReport'],
    }),
  }),
})

export const { useGetCompareReportQuery } = compareReportApi
