import React, { Dispatch, ReactNode, SetStateAction } from 'react'

interface IToggleTableBlockProps {
  children: ReactNode
  subTableActive: boolean
  setSubTableActive: React.Dispatch<React.SetStateAction<boolean>>
  toggleAllState?: number[]
  setToggleAllState?: Dispatch<SetStateAction<number[]>>
  toggableRows?: number
  itemIndex?: number
}

export const ToggleTableBlock = ({
  children,
  setSubTableActive,
  subTableActive,
  setToggleAllState,
  toggleAllState,
  itemIndex,
}: IToggleTableBlockProps) => {
  const onToggle = () => {
    if (!toggleAllState || !setToggleAllState || itemIndex === undefined) {
      setSubTableActive((prev) => !prev)
      return
    }

    setToggleAllState((prevState) =>
      prevState.includes(itemIndex)
        ? prevState.filter((index) => index !== itemIndex)
        : [...prevState, itemIndex],
    )
  }

  return (
    <div className="toggle-block">
      <div
        className={`table-toggle-block${subTableActive ? ' active' : ''}`}
        onClick={onToggle}
      >
        <i className="an-ico an-ico-arrow-down" />
      </div>
      {children}
    </div>
  )
}
