import React from 'react'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
} from '../../../../components/btn/AppBtn'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import {
  IPlanMenuControlOrderDto,
  PlanMenuStatusType,
} from '../../../../core/api/dto/PlanMenuControlDto'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { IPlanMenuCloseOrderDto } from '../../../../core/api/dto/PlanMenuCloseDto'
import './PlanMenuListBlock.css'
import { AddTaskItemButton } from 'UiKitComponents/EmployeeTask/AddTaskItemButton/AddTaskItemButton'
import {
  AppNotification,
  NotificationType,
} from 'components/notification/Notification'
import { useCreateTaskObjectMutation } from 'core/api/TaskApi'
import { useTranslation } from 'react-i18next'

export interface IPlanMenuListBlockProps {
  ordersList: IPlanMenuControlOrderDto[] | IPlanMenuCloseOrderDto[]
  next?: string
  nextPageHandler: () => void
  setEditMode?: (value: boolean) => void
  withTaskAddButton?: boolean
}

export const PlanMenuListBlock = ({
  ordersList,
  nextPageHandler,
  next,
  setEditMode,
  withTaskAddButton,
}: IPlanMenuListBlockProps) => {
  return (
    <div className="plan-menu-list-wrap">
      <AppScrollWrapperNew theme="dropdown">
        {ordersList.map((planMenu, index) => (
          <PlanMenuItem
            planMenu={planMenu}
            key={`${planMenu.id}-pl-${index}`}
            setEditMode={setEditMode}
            withTaskAddButton={withTaskAddButton}
          />
        ))}
        {!!next && (
          <AppBtn
            title="Показать ещё"
            sized={AppBtnSize.fullWidth}
            color={AppBtnColor.extraVioletViolet}
            onClick={nextPageHandler}
          />
        )}
      </AppScrollWrapperNew>
    </div>
  )
}

interface IPlanMenuItem {
  planMenu: IPlanMenuControlOrderDto
  setEditMode?: (value: boolean) => void
  withTaskAddButton?: boolean
}

const PlanMenuItem = ({
  planMenu,
  setEditMode,
  withTaskAddButton,
}: IPlanMenuItem) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentOrderId = searchParams.get('order')
  const onOrderHandler = () => {
    searchParams.set('order', `${planMenu.id}`)
    searchParams.delete('dish')
    searchParams.delete('dimension')
    searchParams.delete('index')
    setSearchParams(searchParams.toString())
    setEditMode?.(false)
  }

  const [createTaskObject] = useCreateTaskObjectMutation()

  const onAddTaskObjectClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    createTaskObject({ order: planMenu.id })
      .unwrap()
      .then(() => {
        AppNotification({
          msg: t('Объект успешно добавлен'),
          type: NotificationType.success,
        })
      })
  }

  return (
    <div
      className={`plan-menu-item-block${
        planMenu.id === Number(currentOrderId || 0) ? ' active' : ''
      }`}
      onClick={onOrderHandler}
    >
      <div className="status-wrapper">
        <div className="date-block">
          <span className="date">
            {t('ДАТА')}:{' '}
            <b> {moment(planMenu.orderDateStart).format('DD.MM.YYYY')}</b>
          </span>
          <span className="time">
            {t('ВРЕМЯ')}:{' '}
            <b>
              {moment(planMenu.orderDateStart).format('HH:mm')}-
              {moment(planMenu.orderDateEnd).format('HH:mm')}
            </b>
          </span>
        </div>

        <div
          className={`status-block status ${
            planMenu.planMenuStatus === PlanMenuStatusType.Closed ? 'close' : ''
          }`}
        >
          <span>
            {t(
              planMenu.planMenuStatus === PlanMenuStatusType.Closed
                ? 'Закрыто'
                : 'Открыто',
            )}
          </span>
        </div>
      </div>

      <div className="content-wrapper">
        <div className="content-block">
          <span className="title">
            {planMenu.name ? planMenu.name : planMenu.sourceRepresent}
          </span>
          <span className="dishes-count">
            {t('Блюд')}: {planMenu.dishTotalCount} / {planMenu.dishPlannedCount}
          </span>
        </div>

        {withTaskAddButton && planMenu.id === Number(currentOrderId) && (
          <div className="content-button-wrapper">
            <AddTaskItemButton onClick={onAddTaskObjectClick} />
          </div>
        )}
      </div>
    </div>
  )
}
