interface IBlockLinkState {
  linkBlock: boolean
  clickedLink: string
}

export interface ISystemState {
  rehydrated: boolean
  blockLinkState: IBlockLinkState
}

export const SystemInitialState: ISystemState = {
  rehydrated: false,
  blockLinkState: {
    linkBlock: false,
    clickedLink: '',
  },
}
