import React from 'react'

interface AppCheckboxButtonProps {
  className?: string
  active: boolean
  text: string
  onClick?: () => void
  disabled?: boolean
}

export const AppCheckboxButton = ({
  text,
  active,
  onClick,
  disabled,
  className,
}: AppCheckboxButtonProps) => {
  return (
    <button
      className={`checkbox-button${active ? ' active' : ''}${
        className ? ' ' + className : ''
      }${disabled ? ' disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{text}</span>
    </button>
  )
}
