import React, { useMemo, useState } from 'react'
import { useGetWidth } from 'utils/useGetWidth'

interface UseTouchMove {
  totalCount: number
  currentCol: number
  setCurrentCol: React.Dispatch<React.SetStateAction<number>>
}

interface TouchFn {
  onTouchStart: (e: React.TouchEvent) => void
  onTouchMove: (e: React.TouchEvent) => void
}

export const useTouchMove = ({
  totalCount,
  currentCol,
  setCurrentCol,
}: UseTouchMove): TouchFn => {
  const [startX, setStartX] = useState<number | null>(null)
  const { width } = useGetWidth()
  const countTotatCol = Math.ceil((totalCount-1)/3)
  const countCurrentCol = useMemo(() => Math.ceil(currentCol/3),[currentCol])

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX)
  }
  const handleTouchMove = (e: React.TouchEvent) => {
    if (startX === null) return

    const deltaX = e.touches[0].clientX - startX

    if (deltaX > 30 && currentCol > 1) {
      setCurrentCol((prev) => prev - (width<600 ? 1 : 3))
      setStartX(null)
    } else if (deltaX < -30 && currentCol < totalCount - 1 && width<600) {
      setCurrentCol((prev) => prev + 1)
      setStartX(null)
    } else if (deltaX < -30 && countTotatCol > countCurrentCol){
      setCurrentCol((prev) => prev + 3)
      setStartX(null)
    }
  }

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
  }
}
