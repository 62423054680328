import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import { useNavigate, useParams } from 'react-router'
import { AppInput, InputMaskType } from '../../../components/AppInput/AppInput'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import {
  useAddedPlanMenuDishListMutation,
  useComputeDishQuantityQuery,
  useCreatePlanMenuMutation,
  useDeletePlanMenuDishListMutation,
  useGetPlanMenuDetailQuery,
  useUpdatePlanMenuMutation,
} from '../../../core/api/PlanMenuApi'
import {
  ICreatePlanMenuDto,
  IOrderLine,
} from '../../../core/api/dto/PlanMenuDto'
import { AddDishInPlanMenuPopup } from './AddDishInPlanMenuPopup'
import { AppFormHook } from '../../../components/AppFormController'
import AppDropdownWithPagination from '../../../components/AppDropdown/AppDropdownWithPagination'
import { useGetPlanMenuTypeListQuery } from '../../../core/api/PlanMenuTypeApi'
import { IPlanMenuTypeDto } from '../../../core/api/dto/PlanMenuTypeDto'
import { useGetFullDateTime } from '../../../utils/useGetFullDateTime'
import moment from 'moment'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { PlanMenuTableDish } from './components/PlanMenuTableDish'
import { PlanMenuTemplatePopup } from './components/PlanMenuTemplatePopup'
import { useGetGuestListTypeListQuery } from '../../../core/api/GuestTypeApi'
import { IGuestTypeDto } from '../../../core/api/dto/GuestTypeDto'
import { useTranslation } from 'react-i18next'
import './CreatePlanMenuPage.scss'
import { useGetWidth } from 'utils/useGetWidth'

const formData = [
  {
    fieldName: 'name',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'planMenuType',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'orderDateStart',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'orderDateEnd',
  },
  {
    fieldName: 'orderTimeStart',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'orderTimeEnd',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'numberOfPersons',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'planMenuGuestType',
    required: 'Обязательное поле',
  },
]

export const customSortForOrderLine = (
  sortValue: null | string,
  setOrderLines: React.Dispatch<React.SetStateAction<IOrderLine[]>>,
) => {
  switch (sortValue) {
    case 'name': {
      setOrderLines((orderLines) =>
        [...orderLines].sort((a, b) =>
          a.dish.name?.toLowerCase() > b.dish.name?.toLowerCase() ? 1 : -1,
        ),
      )
      break
    }
    case '-name': {
      setOrderLines((orderLines) =>
        [...orderLines].sort((a, b) =>
          b.dish.name?.toLowerCase() > a.dish.name?.toLowerCase() ? 1 : -1,
        ),
      )
      break
    }
    case 'category': {
      setOrderLines((orderLines) =>
        [...orderLines].sort((a, b) =>
          a.dish?.category?.name
            ?.toLowerCase()
            .localeCompare(b.dish?.category?.name?.toLowerCase()),
        ),
      )
      break
    }
    case '-category': {
      setOrderLines((orderLines) =>
        [...orderLines].sort((a, b) =>
          b.dish?.category?.name
            ?.toLowerCase()
            .localeCompare(a.dish?.category?.name?.toLowerCase()),
        ),
      )
      break
    }
    case 'quantity': {
      setOrderLines((orderLines) =>
        [...orderLines].sort((a, b) => Number(b.quantity) - Number(a.quantity)),
      )
      break
    }
    case '-quantity': {
      setOrderLines((orderLines) =>
        [...orderLines].sort((a, b) => Number(a.quantity) - Number(b.quantity)),
      )
      break
    }
    case 'standard_weight': {
      setOrderLines((orderLines) =>
        [...orderLines].sort(
          (a, b) => (b.dish.standardWeight || 0) - (a.dish.standardWeight || 0),
        ),
      )
      break
    }
    case '-standard_weight': {
      setOrderLines((orderLines) =>
        [...orderLines].sort(
          (a, b) => (a.dish.standardWeight || 0) - (b.dish.standardWeight || 0),
        ),
      )
      break
    }
    case 'standard_temp': {
      setOrderLines((orderLines) =>
        [...orderLines].sort((a, b) => {
          if (a.dish.standardTempMin === b.dish.standardTempMin) {
            return (b.dish.standardTempMax || 0) - (a.dish.standardTempMax || 0)
          }
          return (b.dish.standardTempMin || 0) - (a.dish.standardTempMin || 0)
        }),
      )
      break
    }
    case '-standard_temp': {
      setOrderLines((orderLines) =>
        [...orderLines].sort((a, b) => {
          if (a.dish.standardTempMin === b.dish.standardTempMin) {
            return (a.dish.standardTempMax || 0) - (b.dish.standardTempMax || 0)
          }
          return (a.dish.standardTempMin || 0) - (b.dish.standardTempMin || 0)
        }),
      )
      break
    }
    case 'implementation_period': {
      setOrderLines((orderLines) =>
        [...orderLines].sort(
          (a, b) =>
            (b.dish.implementationPeriod || 0) -
            (a.dish.implementationPeriod || 0),
        ),
      )
      break
    }
    case '-implementation_period': {
      setOrderLines((orderLines) =>
        [...orderLines].sort(
          (a, b) =>
            (a.dish.implementationPeriod || 0) -
            (b.dish.implementationPeriod || 0),
        ),
      )
      break
    }
    default:
      return null
  }
}

export const CreatePlanMenuPage = () => {
  const { planMenuId } = useParams()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    formState,
    formStateChangeHandler,
    formErrorState,
    formValidationHandler,
    formClearErrors,
  } = AppFormHook({
    formConfig: formData,
  })

  const { data: currentPlanMenu } = useGetPlanMenuDetailQuery(
    { id: Number(planMenuId!) },
    { skip: !planMenuId },
  )
  const [isCompute, setIsCompute] = useState(false)
  const { data: computeDishData } = useComputeDishQuantityQuery(
    { id: Number(planMenuId), count: Number(formState.numberOfPersons) },
    {
      skip:
        !planMenuId ||
        !formState.numberOfPersons ||
        formState?.numberOfPersons === '0' ||
        !isCompute,
    },
  )
  const startFullDate = useGetFullDateTime(
    formState.orderDateStart,
    formState.orderTimeStart,
  )
  const endFullDate = useGetFullDateTime(
    formState.orderDateEnd || formState.orderDateStart,
    formState.orderTimeEnd,
  )
  const [planMenuTableData, setPlanMenuTableData] = useState<IOrderLine[]>([])
  const [currentPlanMenuType, setCurrentPlanMenuType] =
    useState<null | IPlanMenuTypeDto>(null)
  const [currentGuestType, setCurrentGuestType] =
    useState<null | IGuestTypeDto>(null)
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)
  const [addDishPopup, setAddDishPopup] = useState(false)
  const [templatePopup, setTemplatePopup] = useState(false)
  const [tableValid, setTableValid] = useState(true)
  const [isDirty, setIsDirty] = useState(false)
  const { width } = useGetWidth()

  const createNewMode: boolean = !!searchParams.get('create_now')
  const changeHandler = (propName: string, value: string) => {
    formStateChangeHandler({ [propName]: value })
    setIsDirty(true)
  }

  useEffect(() => {
    if (currentPlanMenu && !createNewMode) {
      changeGuestType(currentPlanMenu.planMenuGuestType || null, true)
      changePlanMenuType(currentPlanMenu.planMenuType || null, true)
      setPlanMenuTableData(currentPlanMenu.planMenuLines)
      if (
        currentPlanMenu.planMenuDateStart &&
        currentPlanMenu.planMenuDateEnd
      ) {
        formStateChangeHandler({
          name: currentPlanMenu?.name || '',
          orderDateStart: moment(currentPlanMenu.planMenuDateStart)
            .toDate()
            .toISOString(),
          orderDateEnd: moment(currentPlanMenu.planMenuDateEnd)
            .toDate()
            .toISOString(),
          orderTimeStart: moment(currentPlanMenu.planMenuDateStart).format(
            'HH:mm',
          ),
          orderTimeEnd: moment(currentPlanMenu.planMenuDateEnd).format('HH:mm'),
          numberOfPersons: `${currentPlanMenu.numberOfPersons || 0}`,
        })
      }
    }
  }, [currentPlanMenu, createNewMode])
  useEffect(() => {
    if (!!computeDishData) {
      setPlanMenuTableData((planMenuTableData) =>
        planMenuTableData.map((orderLine) => {
          const newValue = computeDishData.find(
            (newValue) => newValue.lineGuid === orderLine.lineGuid,
          )
          return newValue
            ? { ...orderLine, quantity: `${newValue.quantity.toFixed(3)}` }
            : orderLine
        }),
      )
      setIsCompute(false)
    }
  }, [computeDishData])

  const [createPlanMenu, { isLoading: isLoadingCreate }] =
    useCreatePlanMenuMutation()
  const [updatePlanMenu, { isLoading: isLoadingUpdate }] =
    useUpdatePlanMenuMutation()
  const [addedPlanMenuDish, { isLoading: isLoadingAdded }] =
    useAddedPlanMenuDishListMutation()
  const [deletePlanMenu, { isLoading: isLoadingDelete }] =
    useDeletePlanMenuDishListMutation()

  const onSaveHandler = () => {
    const { isValid } = formValidationHandler()
    const tableValueError = planMenuTableData.find(
      (item) =>
        item.quantity === 0 || Number(item.quantity) === 0 || !item.quantity,
    )
    if (!!tableValueError) {
      setTableValid(false)
    }
    if (!planMenuTableData.length) {
      AppNotification({
        msg: t(
          'Для создания план-меню должно быть добавлено минимум одно блюдо',
        ),
        type: NotificationType.error,
      })
      return
    }
    if (
      isValid &&
      startFullDate &&
      endFullDate &&
      !!formState.numberOfPersons &&
      Number(formState.numberOfPersons) !== 0 &&
      !tableValueError &&
      currentGuestType
    ) {
      const { name, planMenuType, numberOfPersons } = formState

      const newPlanMenuData: ICreatePlanMenuDto = {
        name,
        planMenuType: Number(planMenuType),
        planMenuDateEnd: endFullDate,
        planMenuDateStart: startFullDate,
        numberOfPersons: Number(numberOfPersons),
        planMenuGuestType: currentGuestType.id,
      }
      const newPlanMenuDishesData = planMenuTableData
        .filter((item) => !item.dish.archived)
        .map(({ lineGuid, dish, quantity }) => ({
          lineGuid,
          dish: dish.id,
          quantity: quantity || 0,
        }))
      const removeDishes = planMenuTableData
        .filter((item) => item.dish.archived)
        .map((item) => item.lineGuid)

      if (!!removeDishes.length) {
        AppNotification({
          msg: t(
            'В списке есть удаленные блюда. При сохранении они будут удалены.',
          ),
          type: NotificationType.info,
        })
        setIsDirty(false)
      }

      if (planMenuId && !createNewMode) {
        let deletedItems: string[] = []
        if (!!currentPlanMenu) {
          deletedItems = currentPlanMenu.planMenuLines
            .filter(
              (obj1) => !planMenuTableData.some((obj2) => obj1.id === obj2.id),
            )
            .map((item) => item.lineGuid)
        }
        updatePlanMenu({ id: Number(planMenuId), data: newPlanMenuData })
          .unwrap()
          .then((res) => {
            if (!!deletedItems.length || !!removeDishes.length) {
              return deletePlanMenu({
                id: Number(planMenuId),
                lineGuids: [...deletedItems, ...removeDishes],
              }).then(() => res)
            } else {
              return res
            }
          })
          .then(() =>
            addedPlanMenuDish({
              id: Number(planMenuId),
              body: newPlanMenuDishesData as unknown as IOrderLine[],
            })
              .unwrap()
              .then(() => {
                AppNotification({
                  msg: t('План-меню успешно отредактировано'),
                  type: NotificationType.success,
                })
                setIsDirty(false)
                navigate(`/${AllRoutes.planMenu.path}/`)
              }),
          )
      } else {
        createPlanMenu(newPlanMenuData)
          .unwrap()
          .then((res) => {
            return addedPlanMenuDish({
              id: Number(res.id),
              body: newPlanMenuDishesData as unknown as IOrderLine[],
            }).then(() => res)
          })
          .then((data) => {
            if (!!removeDishes.length)
              deletePlanMenu({
                id: data.id,
                lineGuids: removeDishes,
              })
            return data
          })
          .then(() => {
            console.log('3')
            AppNotification({
              msg: t('План-меню успешно создано'),
              type: NotificationType.success,
            })
            setIsDirty(false)
            navigate(`/${AllRoutes.planMenu.path}/`)
          })
      }
    }
  }

  const createFromChooseHandler = () => {
    if (chooseItems) {
      setPlanMenuTableData((planMenuTableData) =>
        planMenuTableData
          .filter((item) => chooseItems[item.id])
          .map((item) => ({ ...item, lineGuid: uuidv4() })),
      )
    }
    changePlanMenuType(null)
    formStateChangeHandler({
      name: '',
      orderDateStart: '',
      orderDateEnd: '',
      orderTimeStart: '',
      orderTimeEnd: '',
    })
    formClearErrors()
    setChooseItems(null)
    searchParams.set('create_now', 'true')
    setSearchParams(searchParams.toString())
  }
  const saveAsTemplateHandler = () => {
    navigate(
      `/${AllRoutes.templatePlanMenu.path}/${AllRoutes.addTemplatePlanMenu.path}/?plan_menu=${planMenuId}`,
    )
  }

  const isEdinMode = planMenuId
    ? [
        {
          btnTitle: 'Сохранить как шаблон',
          btnIco: 'save',
          onClick: saveAsTemplateHandler,
          color: AppBtnColor.whiteOutlineViolet,
        },
      ]
    : []
  const actions = useMemo(
    () => [
      {
        btnTitle: 'Добавить блюдо',
        btnIco: 'add',
        onClick: () => setAddDishPopup(true),
        color: AppBtnColor.whiteOutlineViolet,
      },
      ...isEdinMode,
      {
        btnTitle: 'Добавить из шаблона',
        btnIco: 'add',
        onClick: () => setTemplatePopup(true),
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Сохранить',
        btnIco: 'save',
        onClick: onSaveHandler,
        color: AppBtnColor.violetWhite,
      },
    ],
    [
      formState,
      planMenuTableData,
      startFullDate,
      endFullDate,
      planMenuId,
      currentPlanMenu,
      createNewMode,
    ],
  )

  const closeAddDishPopupHandler = useCallback(() => setAddDishPopup(false), [])
  const closeTemplatePopupHandler = useCallback(
    () => setTemplatePopup(false),
    [],
  )

  const changePlanMenuType = (
    value: IPlanMenuTypeDto | null,
    fromCurrent?: boolean,
  ) => {
    setCurrentPlanMenuType(value)
    formStateChangeHandler({ planMenuType: `${!!value ? value.id : ''}` })
    !fromCurrent && setIsDirty(true)
  }
  const changeGuestType = (
    value: IGuestTypeDto | null,
    fromCurrent?: boolean,
  ) => {
    setCurrentGuestType(value)
    formStateChangeHandler({ planMenuGuestType: `${!!value ? value.id : ''}` })
    !fromCurrent && setIsDirty(true)
  }

  return (
    <React.Fragment>
      {(isLoadingCreate ||
        isLoadingUpdate ||
        isLoadingAdded ||
        isLoadingDelete) && <AppLoader loaderType={LoaderType.main} />}
      {addDishPopup && (
        <AddDishInPlanMenuPopup
          closePopup={closeAddDishPopupHandler}
          addedDish={planMenuTableData}
          setNewDish={setPlanMenuTableData}
          setIsDirty={setIsDirty}
        />
      )}
      {templatePopup && (
        <PlanMenuTemplatePopup
          onClose={closeTemplatePopupHandler}
          setPlanMenuTableData={setPlanMenuTableData}
          setNumberOfPersons={
            !!Number(formState.numberOfPersons) ? null : changeHandler
          }
          setIsDirty={setIsDirty}
        />
      )}
      <div className={'width-for-page-wrapper create-plan-menu-page'}>
        <AppTitlePage
          title={`${
            planMenuId && !createNewMode ? 'Редактирование' : 'Создание'
          } план-меню`}
          actions={actions}
          goBack
          isDirty={isDirty}
        />
        <div className="filter-block mt-20">
          <div className={`${width<=1024 ? 'width-100' : "col-3"}`}>
            <AppDropdownWithPagination
              value={currentPlanMenuType}
              getterData={useGetPlanMenuTypeListQuery}
              propToShowInList={'name'}
              propToShowInInput={'name'}
              onChange={changePlanMenuType}
              label="Тип план-меню"
              placeholder={'Выбрать'}
              fullWidth
              required
              error={formErrorState.planMenuType ? 'Обязательное поле' : null}
            />
          </div>
          <div className={`${width<=1024 ? 'width-100' : ""}`}>
            <label>
              Назначить период исполнения <span className="color-Red"> *</span>
            </label>
            <div className="input-row mt-10">
              <AppInput
                value={formState.orderDateStart}
                error={
                  formErrorState.orderDateStart
                    ? 'Обязательное поле'
                    : undefined
                }
                onChange={(value) => changeHandler('orderDateStart', value)}
                type={'date'}
              />
              <AppInput
                value={formState.orderDateEnd}
                error={
                  formErrorState.orderDateEnd ? 'Обязательное поле' : undefined
                }
                onChange={(value) => changeHandler('orderDateEnd', value)}
                type={'date'}
              />
            </div>
          </div>
        </div>
        <div className="filter-block mt-20">
          <div className={`${width<=1024 ? 'width-100' : "col-3"}`}>
            <AppInput
              value={formState.name}
              error={formErrorState.name ? 'Обязательное поле' : undefined}
              onChange={(value) => changeHandler('name', value)}
              label="Название план-меню"
              placeholder="Название"
              fullWidth
              required
            />
          </div>
          <div className={`${width<=1024 ? 'width-100' : ""}`}>
            <label>
              {t('Назначить время исполнения')}{' '}
              <span className="color-Red"> *</span>
            </label>
            <div className="input-row mt-10">
              <AppInput
                value={formState.orderTimeStart}
                error={
                  formErrorState.orderTimeStart
                    ? 'Обязательное поле'
                    : undefined
                }
                maxValue={
                  !!formState.orderTimeEnd ? formState.orderTimeEnd : undefined
                }
                onChange={(value) => changeHandler('orderTimeStart', value)}
                fullWidth
                required
                type={'time'}
                timeWithList
              />
              <AppInput
                value={formState.orderTimeEnd}
                minValue={
                  !!formState.orderTimeStart
                    ? formState.orderTimeStart
                    : undefined
                }
                error={
                  formErrorState.orderTimeEnd ? 'Обязательное поле' : undefined
                }
                onChange={(value) => changeHandler('orderTimeEnd', value)}
                fullWidth
                required
                type={'time'}
                timeWithList
              />
            </div>
          </div>
          <AppDropdownWithPagination
            value={currentGuestType}
            getterData={useGetGuestListTypeListQuery}
            propToShowInList={'name'}
            propToShowInInput={'name'}
            onChange={changeGuestType}
            label="Тип гостя"
            placeholder={'Выбрать'}
            required
            error={
              formErrorState.planMenuGuestType ? 'Обязательное поле' : null
            }
          />
          <AppInput
            value={formState.numberOfPersons}
            error={
              formErrorState.numberOfPersons
                ? 'Обязательное поле'
                : Number(formState.numberOfPersons) === 0 &&
                  !!formState.numberOfPersons
                ? 'Обязательное поле'
                : undefined
            }
            onChange={(value) => changeHandler('numberOfPersons', value)}
            label="Кол-во гостей"
            inputMask={InputMaskType.integer}
            required
            maxLength={6}
          />
          {!!planMenuId && !createNewMode && (
            <div className="m-t-auto pb-17">
              <AppBtn
                sized={AppBtnSize.md}
                color={AppBtnColor.violetWhite}
                iconClassName={'save'}
                title={'Рассчитать'}
                disabled={
                  !formState.numberOfPersons ||
                  Number(formState.numberOfPersons) === 0 ||
                  Number(formState.numberOfPersons) ===
                    currentPlanMenu?.numberOfPersons
                }
                onClick={() => setIsCompute(true)}
              />
            </div>
          )}
        </div>
        <PlanMenuTableDish
          planMenuTableData={planMenuTableData}
          setPlanMenuTableData={setPlanMenuTableData}
          tableActionsProps={
            planMenuId && !createNewMode
              ? [
                  {
                    title: 'Создать из выбранного',
                    ico: 'add',
                    onClick: createFromChooseHandler,
                  },
                ]
              : []
          }
          tableValid={tableValid}
          chooseItems={chooseItems}
          setChooseItems={setChooseItems}
          setIsDirty={setIsDirty}
        />
      </div>
    </React.Fragment>
  )
}
