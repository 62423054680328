import React, { useCallback, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import { IPopup } from '../CategoriesPage/CategoriesPage'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import { AppTable } from '../../../components/AppTable/AppTable'
import {
  useArchiveMultipleGuestTypeMutation,
  useGetGuestListTypeListQuery,
} from '../../../core/api/GuestTypeApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import { IGuestsTypeRequest } from '../../../core/api/dto/GuestTypeDto'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AddGuestTypePopup } from './components/AddGuestTypePopup'
import { useDeleteDebounce } from '../../../utils/useDeleteDebounce'
import { useTranslation } from 'react-i18next'

const headerData = [{ title: 'Наименование', colWidth: '95%', sort: 'name' }]

export const GuestTypePage = () => {
  const { t } = useTranslation()
  const pageParams = useParamsControl<
    IGuestsTypeRequest,
    keyof IGuestsTypeRequest
  >({
    withPagination: true,
    whiteList: ['search', 'ordering'],
  })
  const [openCreatePopup, setOpenCreatePopup] = useState<IPopup>({
    active: false,
    id: null,
  })

  const { data: guestTypeList } = useGetGuestListTypeListQuery(
    { ...pageParams },
    { skip: !pageParams },
  )
  const [archiveMultipleGuestType] = useArchiveMultipleGuestTypeMutation()
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)

  const openEditPopupHandler = useCallback(
    (id: number) => setOpenCreatePopup({ id, active: true }),
    [],
  )

  const deleteGuestTypeHandler = async () => {
    if (!!chooseItems) {
      archiveMultipleGuestType({
        data: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>{
          AppNotification({
            msg: t('Выбранные типы гостей успешно удалены!'),
            type: NotificationType.success,
          })
          setChooseItems(null)
        })
    }
  }

  const closePopupHandler = useCallback(
    () => setOpenCreatePopup({ id: null, active: false }),
    [],
  )

  const [onClickHandler] = useDeleteDebounce({ fn: deleteGuestTypeHandler })

  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Добавить тип гостя',
        btnIco: 'add',
        onClick: () =>
          setOpenCreatePopup((prevState) => ({ ...prevState, active: true })),
        color: AppBtnColor.whiteOutlineViolet,
      },
    ],
    [],
  )
  if (!guestTypeList) return null
  return (
    <React.Fragment>
      {openCreatePopup.active && (
        <AddGuestTypePopup
          closePopup={closePopupHandler}
          id={openCreatePopup.id}
        />
      )}
      <div className="width-for-page-wrapper">
        <AppTitlePage title="Тип гостя" actions={actions} />
        <div className="col-7-width-from-1000">
          <div className="filter-block mt-20">
            <AppFilterInput
              fullWidth
              searchParam={'search'}
              ico={'search'}
              label={'Поиск'}
              placeholder="Например: Ребёнок"
            />
          </div>
          <AppTable
            data={guestTypeList}
            headerData={headerData}
            chooseItems={chooseItems}
            chooseMode
            setChooseItems={setChooseItems}
            tableDataSelectors={[
              {
                renderItem: (item) => (
                  <div
                    className={`table-link ${
                      item.archived ? 'delete-item' : ''
                    }`}
                    onClick={() => openEditPopupHandler(item.id)}
                  >
                    {item.name}
                  </div>
                ),
              },
            ]}
            chooseActions={tableActions}
            idName="guest-type"
            // isLoading={isLoadingGet || isFetchingGet}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
