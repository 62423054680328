import AppDropdownWithPagination from 'components/AppDropdown/AppDropdownWithPagination'
import { AppFormHook } from 'components/AppFormController'
import { AppInput, TextareaInputRowsType } from 'components/AppInput/AppInput'
import { AppRadioButtonsBlock } from 'components/AppRadioButtonsBlock/AppRadioButtonsBlock'
import { ITaskDto, ITaskEmployee } from 'core/api/dto/TaskDto'
import React, { useCallback, useEffect, useState } from 'react'
import './EditTaskForm.scss'
import { AppMultiDropdownWithPagination } from 'components/AppDropdown/AppMultiDropdownWithPagination'
import { IEmployeeDto } from 'core/api/dto/EmployeeDto'
import {
  AppTableTaskStatusEnum,
  AppTableTaskStatusTranslate,
} from 'components/AppTable/AppTableStatus/AppTableStatus'
import { useGetUsersListQuery } from '../../../../../core/api/UserApi'
import { getAvailableStatuses } from '../../getAvailableStatuses'
import { AppCheckboxButton } from '../../../../../UiKitComponents/AppCheckboxButton'
import moment from 'moment'

const formData = [
  {
    fieldName: 'title',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'description',
  },
  {
    fieldName: 'responsible',
  },
  {
    fieldName: 'expectedCompletionDate',
  },
  {
    fieldName: 'status',
  },
]

interface IEditTaskFormProps {
  isError: boolean
  isCreatorCurrentUser: boolean
  currentTask?: ITaskDto
  setFormData: React.Dispatch<React.SetStateAction<ITaskFormData | null>>
  isInputDisabled?: boolean
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ITaskFormData {
  title?: string
  description?: string
  responsible?: string
  isImportant?: boolean
  status?: AppTableTaskStatusEnum
  expectedCompletionDate?: string
  followers?: IEmployeeDto[]
}

export const EditTaskForm: React.FC<IEditTaskFormProps> = (props) => {
  const {
    isError,
    currentTask,
    setFormData,
    isInputDisabled = false,
    isCreatorCurrentUser,
    setIsDirty,
  } = props
  const [status, setStatus] = useState(AppTableTaskStatusEnum.DRAFT)
  const [isImportant, setIsImportant] = useState(false)
  const [currentResponsible, setCurrentResponsible] =
    useState<null | ITaskEmployee>(null)
  const [currentFollowers, setCurrentFollowers] = useState<IEmployeeDto[]>([])

  const {
    formState,
    formStateChangeHandler,
    formErrorState,
    formValidationHandler,
  } = AppFormHook({
    formConfig: formData,
  })

  const changeHandler = (propName: string, value: string) => {
    formStateChangeHandler({ [propName]: value })
    setFormData((prev) => ({ ...prev, [propName]: value }))
    setIsDirty(true)
  }

  const changeResponsibleHandler = (value: ITaskEmployee) => {
    formStateChangeHandler({ responsible: value ? `${value?.id}` : '' })
    setFormData((prev) => ({ ...prev, responsible: value.id.toString() }))
    setCurrentResponsible(value)
    setIsDirty(true)
  }

  const changeFollowersHandler = (value: IEmployeeDto[]) => {
    setCurrentFollowers(value)
    setFormData((prev) => ({ ...prev, followers: value }))
    setIsDirty(true)
  }

  const onRemoveFollowersItem = useCallback(
    (id: number) => {
      setCurrentFollowers((prevState) =>
        prevState.filter((item) => item.id !== id),
      )
      setFormData((prev) => ({
        ...prev,
        followers: prev?.followers?.filter((item) => item.id !== id),
      }))
      setIsDirty(true)
    },
    [setIsDirty, setFormData],
  )

  useEffect(() => {
    if (currentTask) {
      const {
        title,
        description,
        responsible,
        isImportant,
        status,
        expectedCompletionDate,
      } = currentTask
      const data = {
        title,
        description,
        responsible: `${responsible?.id}`,
        status,
        expectedCompletionDate: expectedCompletionDate || '',
      }
      setCurrentFollowers(currentTask.followers)
      setCurrentResponsible(responsible)
      setIsImportant(isImportant)
      setStatus(status)

      formStateChangeHandler(data)
      setFormData({ ...data, followers: currentTask.followers })
    }
  }, [currentTask])

  useEffect(() => {
    if (isError) {
      formValidationHandler()
    }
  }, [isError])

  const onImportantClick = () => {
    setFormData((prev) => ({ ...prev, isImportant: !isImportant }))
    setIsImportant(!isImportant)
    setIsDirty(true)
  }

  const setStatusHandler = (status: AppTableTaskStatusEnum) => {
    setStatus(status)
    setIsDirty(true)
    setFormData((prev) => ({ ...prev, status }))
  }

  const radioButtons = currentTask
    ? getAvailableStatuses(isCreatorCurrentUser, currentTask.status)
    : [
        {
          value: AppTableTaskStatusEnum.DRAFT,
          title: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.DRAFT],
        },
        {
          value: AppTableTaskStatusEnum.PROGRESS,
          title: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.PROGRESS],
        },
        {
          value: AppTableTaskStatusEnum.DONE,
          title: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.DONE],
        },
      ]

  const isImportantButtonDisabled = !currentTask
    ? false
    : !isCreatorCurrentUser ||
      currentTask?.status === AppTableTaskStatusEnum.DONE

  return (
    <>
      <AppInput
        value={formState.title}
        onChange={(value) => changeHandler('title', value)}
        error={formErrorState.title ? 'Обязательное поле' : undefined}
        label="Наименование задачи"
        placeholder="Название задачи"
        border
        required
        fullWidth
        clear={!isInputDisabled}
        disabled={isInputDisabled}
        id="title"
      />

      <AppInput
        value={formState.description}
        onChange={(value) => changeHandler('description', value)}
        label="Описание"
        placeholder="Дополнительное описание задачи"
        border
        fullWidth
        clear={!isInputDisabled}
        disabled={isInputDisabled}
        id="description"
        type="textarea"
        rows={TextareaInputRowsType['rows-2']}
        maxLength={2000}
      />
      <AppDropdownWithPagination
        label="Исполнитель"
        placeholder="Выбрать исполнителя"
        value={currentResponsible}
        onChange={changeResponsibleHandler}
        getterData={useGetUsersListQuery}
        propToShowInInput={'name'}
        propToShowInList={'name'}
        border
        fullWidth
        id="responsible"
        disabled={isInputDisabled}
      />

      <AppInput
        minValue={moment().format('MM.DD.YYYY')}
        value={formState.expectedCompletionDate}
        onChange={(value) => changeHandler('expectedCompletionDate', value)}
        label="Срок исполнения"
        type={'date'}
        border
        fullWidth
        disabled={isInputDisabled}
      />

      <div className={'edit-task-form-status-wrapper'}>
        <label className={'edit-task-form-label'}>Статус</label>

        <div className={'edit-task-form-status-buttons-wrapper'}>
          <AppRadioButtonsBlock
            value={status}
            setValue={setStatusHandler}
            buttons={radioButtons}
            wrapperClassName={'edit-task-form-radio-wrapper'}
            buttonClassName={'edit-task-form-radio-button'}
          />

          <AppCheckboxButton
            className="edit-task-form-important-button"
            active={isImportant}
            onClick={onImportantClick}
            disabled={isImportantButtonDisabled}
            text="Пометить как важное"
          />
        </div>
      </div>

      <AppMultiDropdownWithPagination<IEmployeeDto, keyof IEmployeeDto>
        label={'Отслеживающий'}
        value={currentFollowers}
        getterData={useGetUsersListQuery}
        propToShowInInput={'name'}
        propToShowInList={'name'}
        border
        fullWidth
        id="followers"
        placeholder="Выбрать отслеживающего"
        onChange={changeFollowersHandler}
        disabled={isInputDisabled}
      />

      <div className={'edit-task-form-status-wrapper'}>
        <label className={'edit-task-form-label'}>Список отслеживающих</label>

        <div
          className="choose-items-block full-width"
          style={{ minHeight: '60px' }}
        >
          {!currentFollowers.length ? (
            <input
              placeholder={'Список людей отслеживающих задачу'}
              disabled
              className={'edit-task-form-followers-empty'}
            />
          ) : (
            currentFollowers.map((item, index) => (
              <div className="choose-item" key={item.id} id={`millet-${index}`}>
                {item.name}
                {!isInputDisabled && (
                  <i
                    className="an-ico an-ico-close"
                    onClick={() => onRemoveFollowersItem(item.id)}
                  />
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  )
}
