import React, { useEffect, useState } from 'react'
import {
  AppNotification,
  NotificationType,
} from '../../../../components/notification/Notification'
import Popup, { PopupSize } from '../../../../components/popup/Popup'
import { AppInput } from '../../../../components/AppInput/AppInput'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
} from '../../../../components/btn/AppBtn'
import {
  useCreateTagMutation,
  useGetTagDetailQuery,
  useUpdateTagMutation,
} from '../../../../core/api/TagsApi'
import { useTranslation } from 'react-i18next'

interface IAddTagsPopupProps {
  id?: number | null
  closePopup: () => void
}

export const AddTagsPopup = ({ closePopup, id }: IAddTagsPopupProps) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [error, setError] = useState('')
  const { data: currentTag } = useGetTagDetailQuery(id ? id : 0, {
    skip: !id,
  })
  const [createTag, { isLoading: isLoadingCreate }] = useCreateTagMutation()
  const [updateTag, { isLoading: isLoadingUpdate }] = useUpdateTagMutation()

  useEffect(() => {
    if (!!currentTag) {
      setTitle(currentTag.name)
    }
  }, [currentTag])

  const onSaveHandler = () => {
    if (!title) {
      setError('Обязательное поле')
      return
    }
    if (!!id) {
      updateTag({ id, name: title })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Хэштег успешно обновлен!'),
            type: NotificationType.success,
          })
          closePopup()
        })
    } else {
      createTag({
        name: title,
      })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Хэштег успешно создан!'),
            type: NotificationType.success,
          })
          closePopup()
        })
    }
  }

  return (
    <Popup
      size={PopupSize.base}
      borderRadiusMin
      closePopup={closePopup}
      isLoading={isLoadingCreate || isLoadingUpdate}
    >
      <div className="category-popup">
        <h3>{t(`${!!id ? 'Редактирование' : 'Создание'} хэштега`)}</h3>
        <AppInput
          value={title}
          onChange={(value: string) => {
            setError('')
            setTitle(value)
          }}
          label="Название"
          border
          fullWidth
          error={error ? error : undefined}
        />
        <div className="buttons-block">
          <AppBtn
            title={'Отменить'}
            color={AppBtnColor.outlineViolet}
            iconClassName={'close'}
            sized={AppBtnSize.md}
            onClick={closePopup}
          />
          <AppBtn
            iconClassName={'save'}
            title={'Сохранить'}
            sized={AppBtnSize.md}
            color={AppBtnColor.violetWhite}
            onClick={onSaveHandler}
          />
        </div>
      </div>
    </Popup>
  )
}
