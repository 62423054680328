import React, { memo, useCallback } from 'react'
import { CookingStatus, IOrderLine } from '../../../core/api/dto/OrderObjDto'
import { compareProps } from '../../../utils'
import { TMealItemProps } from './MealItemCook'

export const renderStatusDish = (info: IOrderLine) => {
  if (
    info.orderLineCookingStatuses?.cookingStatus === CookingStatus.Cooking &&
    !info.orderLineDimensions.length
  ) {
    return (
      <div className="wrapper-icon processed">
        <i className="an-ico an-ico-hourglass" />
      </div>
    )
  } else if (
    info.orderLineCookingStatuses?.cookingStatus === CookingStatus.Cook_done &&
    !isGivenOutDish(info.quantity, info.orderLineDimensions.length)
  ) {
    return (
      <div className="wrapper-icon">
        <i className="an-ico an-ico-check" />
      </div>
    )
  }
}

export const isGivenOutDish = (quantity: number, countDimensions: number) =>
  countDimensions === quantity ||
  (!Number.isInteger(quantity) && countDimensions)

const MealItemWaiter = ({
  info,
  handlerSetActiveDish,
  parentId,
  orderRepresent,
}: TMealItemProps) => {
  const onClickHandler = useCallback(() => {
    if (!isGivenOutDish(info.quantity, info.orderLineDimensions.length)) {
      handlerSetActiveDish!({
        info,
        id: parentId,
        orderRepresent: orderRepresent!,
      })
    }
  }, [info, handlerSetActiveDish, parentId, orderRepresent])

  return (
    <div
      className={`meal-item ${
        isGivenOutDish(info.quantity, info.orderLineDimensions.length)
          ? 'ready'
          : ''
      }`}
      onClick={onClickHandler}
    >
      <div className="name-dish">
        <div>
          {isGivenOutDish(info.quantity, info.orderLineDimensions.length)
            ? `${info.quantity}`
            : `${info.orderLineDimensions.length} из ${info.quantity}`}
        </div>
        <div>{info.dish.name}</div>
      </div>
      {renderStatusDish(info)}
    </div>
  )
}

const MealItemWaiterMemoized = memo(MealItemWaiter, compareProps)
export default MealItemWaiterMemoized
