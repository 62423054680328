import React, { useEffect, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppInput, InputMaskType } from '../../../components/AppInput/AppInput'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
  IconPosition,
} from '../../../components/btn/AppBtn'
import { DragAndDropUploadPhoto } from '../components/DragAndDropUploadPhoto'
import { useNavigate, useParams } from 'react-router'
import { AppFormHook } from '../../../components/AppFormController'
import { convertBase64 } from '../../../components/input/InputFile'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import {
  useCreateBuffetMilletMutation,
  useGetBuffetMilletDetailQuery,
  useLazyGetImageMilletFromCameraQuery,
  useUpdateBuffetMilletMutation,
} from '../../../core/api/BuffetMilletApi'
import { IBuffetMilletBase } from '../../../core/api/dto/BuffetMilletDto'
import { setCurrentData } from '../../../utils/setCurrentData'
import { useGetCurrentWeightQuery } from '../../../core/api/OrderApi'
import { handleBase64Image } from '../../../utils/base64ToBlob'
import { useTranslation } from 'react-i18next'
import './CreateMilletPage.scss'

const formData = [
  {
    fieldName: 'milletName',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'milletWeight',
    required: 'Обязательное поле',
  },
]

export const CreateMilletPage = () => {
  const { t } = useTranslation()
  const { milletId } = useParams()
  const navigate = useNavigate()
  const {
    formState,
    formStateChangeHandler,
    formErrorState,
    formValidationHandler,
  } = AppFormHook({
    formConfig: formData,
  })
  const [activeWeighingStation, setActiveWeighingStation] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const { data: currentWeight, isError: isErrorCurrentWeight } =
    useGetCurrentWeightQuery(null, {
      skip: !activeWeighingStation,
      pollingInterval: 1000,
    })

  const { data: currentMillet } = useGetBuffetMilletDetailQuery(
    { id: Number(milletId) },
    { skip: !milletId },
  )

  const [
    getImageFromCamera,
    { data: imageFromCamera, isLoading: isLoadingPhotoFromCamera },
  ] = useLazyGetImageMilletFromCameraQuery()

  const [createMillet, { isLoading: isLoadingCreate }] =
    useCreateBuffetMilletMutation()
  const [updateMillet, { isLoading: isLoadingUpdate }] =
    useUpdateBuffetMilletMutation()

  const setPhotoFromCameraHandler = (value: boolean) => {
    if (imageFromCamera && value) {
      getImageFromCamera(null)
    } else {
      getImageFromCamera(null)
    }
  }

  const changeHandler = (propName: string, value: string) => {
    formStateChangeHandler({ [propName]: value })
    setIsDirty(true)
  }

  const [filePreview, setFilePreview] = useState<null | string>(null)
  const [isDeletedFile, setIsDeletedFile] = useState<boolean>(false)
  const [file, setFile] = useState<null | File>(null)

  useEffect(() => {
    if (!!imageFromCamera) {
      const image = handleBase64Image(imageFromCamera.image, 'title')
      setFile(image)
    }
  }, [imageFromCamera])
  useEffect(() => {
    if (!!isErrorCurrentWeight) {
      setActiveWeighingStation(false)
      AppNotification({
        msg: t('Отсутствует соединение c весами!'),
        type: NotificationType.error,
      })
    }
  }, [isErrorCurrentWeight])

  useEffect(() => {
    if (!!currentMillet) {
      const currentData = setCurrentData({ data: currentMillet, formState })
      formStateChangeHandler({
        ...currentData,
      })
      setFilePreview(
        !!currentMillet.milletPhoto ? currentMillet.milletPhoto : null,
      )
    }
  }, [currentMillet])
  useEffect(() => {
    if (!!file) {
      setFilePreview(URL.createObjectURL(file))
    }
  }, [file])

  const setCurrentWeight = () => {
    if (!!currentWeight && !!currentWeight.weight) {
      formStateChangeHandler({ milletWeight: `${currentWeight.weight}` })
      setActiveWeighingStation(false)
    }
    setIsDirty(true)
  }

  const createMilletHandler = async () => {
    const { isValid } = formValidationHandler()
    if (isValid) {
      const image = !!file
        ? { milletPhoto: await convertBase64(file) }
        : isDeletedFile
        ? { milletPhoto: null }
        : {}
      const newMillet = {
        ...formState,
        ...image,
      }
      if (!!milletId) {
        updateMillet({
          id: Number(milletId),
          data: newMillet as unknown as IBuffetMilletBase,
        })
          .unwrap()
          .then(() => {
            AppNotification({
              msg: t('Тара успешно изменена'),
              type: NotificationType.success,
            })
            setIsDirty(false)
            navigate(`/${AllRoutes.millet.path}/`)
          })
      }
      if (!milletId) {
        createMillet(newMillet as unknown as IBuffetMilletBase)
          .unwrap()
          .then(() => {
            AppNotification({
              msg: t('Тара успешно добавлена'),
              type: NotificationType.success,
            })
            setIsDirty(false)
            navigate(`/${AllRoutes.millet.path}/`)
          })
      }
    }
  }

  return (
    <div className="width-for-page-wrapper">
      <AppTitlePage
        title={milletId ? `Редактирование тары` : `Добавление тары`}
        goBack
        isDirty={isDirty}
      />
      <div className="col-7-width-from-1000">
        <div className="dish-create-wrap create-millet-page">
          <div className="row">
            <div className="create-col full-width">
              <AppInput
                value={formState.milletName}
                onChange={(value) => changeHandler('milletName', value)}
                error={
                  formErrorState.milletName ? 'Обязательное поле' : undefined
                }
                label="Наименование"
                placeholder="Название"
                border
                fullWidth
                required
                clear
                id={'milletName'}
              />
              <AppInput
                value={
                  currentWeight && activeWeighingStation
                    ? `${currentWeight.weight}`
                    : formState.milletWeight
                }
                onChange={(value) => changeHandler('milletWeight', value)}
                error={
                  !activeWeighingStation && formErrorState.milletWeight
                    ? 'Обязательное поле'
                    : undefined
                }
                label="Вес, кг"
                placeholder="0.000"
                inputMask={InputMaskType.float}
                disabled={activeWeighingStation}
                border
                fullWidth
                required
                clear={!activeWeighingStation}
              />
              {activeWeighingStation ? (
                <div className="buttons-block mt-10">
                  <AppBtn
                    title="Отменить"
                    color={AppBtnColor.outlineViolet}
                    sized={AppBtnSize.fullWidth}
                    iconClassName="close"
                    iconPosition={IconPosition.left}
                    onClick={() => setActiveWeighingStation(false)}
                  />
                  <AppBtn
                    title="Применить"
                    color={AppBtnColor.lightViolet}
                    sized={AppBtnSize.fullWidth}
                    iconClassName="add"
                    iconPosition={IconPosition.left}
                    onClick={setCurrentWeight}
                    disabled={
                      !currentWeight ||
                      (!!currentWeight && !currentWeight.weight)
                    }
                  />
                </div>
              ) : (
                <div className="mt-10">
                  <AppBtn
                    title="Измерить станцией"
                    color={AppBtnColor.lightViolet}
                    sized={AppBtnSize.fullWidth}
                    iconClassName="scales"
                    onClick={() => setActiveWeighingStation(true)}
                  />
                </div>
              )}
              <DragAndDropUploadPhoto
                fileURL={filePreview}
                onSetFile={setFile}
                setFilePreview={setFilePreview}
                mt
                setFileFromCamera={setPhotoFromCameraHandler}
                isLoading={isLoadingPhotoFromCamera}
                setIsDirty={setIsDirty}
                setIsDeletedFile={setIsDeletedFile}
              />
            </div>
            <div className="buttons-block mt-20">
              <AppBtn
                title="Отменить"
                color={AppBtnColor.outlineViolet}
                sized={AppBtnSize.md}
                iconClassName="close"
                iconPosition={IconPosition.left}
                onClick={() => navigate(-1)}
              />
              <AppBtn
                title="Сохранить"
                color={AppBtnColor.violetWhite}
                sized={AppBtnSize.md}
                iconClassName="save"
                iconPosition={IconPosition.left}
                onClick={createMilletHandler}
                isLoading={isLoadingCreate || isLoadingUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
