import React, { FormEvent, useMemo, useRef, useState } from 'react'

interface IAppFormController {
  children: JSX.Element
  onSubmit?: (data: {}) => void
}

export const AppFormController = React.forwardRef(
  (
    { children, onSubmit }: IAppFormController,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const formRef = useRef(null)
    const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
      if (!!onSubmit) {
        e.preventDefault()
        // @ts-ignore
        const formData = new FormData(e.target)
        const formDataObj: { [key: string]: string } = {}
        formData.forEach((value, key) => {
          formDataObj[key] = `${value}`
        })
        onSubmit(formDataObj)
      }
    }
    if (!ref) {
      return <React.Fragment>{children}</React.Fragment>
    }
    return (
      <form onSubmit={onSubmitHandler} ref={formRef}>
        {children}
        <input type="submit" style={{ display: 'none' }} ref={ref} />
      </form>
    )
  },
)

interface IAppFormHookReturn {
  ref: React.Ref<HTMLInputElement>
  formSubmit: () => void
  formState: IFormState
  formErrorState: IFormErrorState
  formStateChangeHandler: (newState: { [key: string]: string }) => void
  formValidationHandler: () => { isValid: boolean }
  formClearErrors: () => void
}

interface IAppFormHook {
  formConfig: {
    fieldName: string
    required?: string
    maxLength?: number
    inputTitle?: string
  }[]
}

export interface IFormState {
  [key: string]: string
}

export interface IFormErrorState {
  [key: string]: boolean
}

interface IFormConfig {
  [key: string]: {
    required: string | null
    maxLength: number | null
  }
}

export const AppFormHook = ({
  formConfig,
}: IAppFormHook): IAppFormHookReturn => {
  const submitRef = useRef<HTMLInputElement>(null)
  const initStates = useMemo(() => {
    const initFormState = {} as IFormState
    const initErrorState = {} as IFormErrorState
    const initIsDirtyState = {} as IFormErrorState
    const config = {} as IFormConfig
    formConfig.forEach((field) => {
      initFormState[field.fieldName] = ''
      initErrorState[field.fieldName] = false
      initIsDirtyState[field.fieldName] = false
      config[field.fieldName] = {
        required: !!field.required ? field.required : null,
        maxLength: !!field.maxLength ? field.maxLength : null,
      }
    })
    return { initFormState, initErrorState, initIsDirtyState, config }
  }, [formConfig])
  const [formState, setFormState] = useState(initStates.initFormState)
  const [formErrorState, setFormErrorState] = useState(
    initStates.initErrorState,
  )
  const [formIsDirtyState, setFormIsDirtyState] = useState(
    initStates.initIsDirtyState,
  )
  const makeValidation = (state: IFormState, forcedValidation: boolean) => {
    const newErrorState: { [key: string]: boolean } = {}
    const newDirtyState: { [key: string]: boolean } = {}
    let isValid = true
    Object.keys(state).forEach((propName) => {
      const isDirty = forcedValidation ? true : formIsDirtyState[propName]
      if (!isDirty) {
        newDirtyState[propName] = true
      }
      if (
        (!!initStates.config[propName].required &&
          isDirty &&
          state[propName] === '') ||
        (!!initStates.config[propName].maxLength &&
          isDirty &&
          state[propName].length > initStates.config[propName].maxLength!)
      ) {
        newErrorState[propName] = true
        isValid = false
      } else if (
        (!!initStates.config[propName].required &&
          formState[propName] !== '') ||
        (!!initStates.config[propName].maxLength &&
          isDirty &&
          state[propName].length <= initStates.config[propName].maxLength!)
      ) {
        newErrorState[propName] = false
      } else if (
        !!initStates.config[propName].required &&
        state[propName] !== ''
      ) {
        newErrorState[propName] = false
      } else if (!initStates.config[propName].required) {
        newErrorState[propName] = false
      }
    })
    if (Object.keys(newErrorState).length > 0) {
      setFormErrorState((prev) => ({
        ...prev,
        ...newErrorState,
      }))
    }
    if (Object.keys(newDirtyState).length > 0) {
      setFormIsDirtyState((prev) => ({
        ...prev,
        ...newDirtyState,
      }))
    }
    return { isValid }
  }
  const formStateChangeHandler = (
    newState: { [key: string]: string },
    forcedValidation?: boolean,
  ) => {
    makeValidation(newState, false)
    setFormState((prev) => {
      return {
        ...prev,
        ...newState,
      }
    })
  }
  const formValidationHandler = () => {
    return makeValidation(formState, true)
  }
  const formSubmit = () => {
    submitRef.current!.click()
  }
  const formClearErrors = () => {
    setFormErrorState(initStates.initErrorState)
  }
  return {
    ref: submitRef,
    formSubmit,
    formState,
    formStateChangeHandler,
    formErrorState,
    formValidationHandler,
    formClearErrors,
  }
}
