import React, { useCallback, useState } from 'react'
import { AppScrollWrapperNew } from '../../../widgets/AppScrollWrapper/AppScrollWrapper'
import {
  AppImagePopup,
  IAppImagePopup,
} from '../../../components/AppImagePopup/AppImagePopup'
import { useUpdatePlanMenuDimensionCommentMutation } from '../../../core/api/PlanMenuControlApi'
import { useSearchParams } from 'react-router-dom'
import {
  IPlanMenuControlDimensionDto,
  PlanMenuStatusType,
} from '../../../core/api/dto/PlanMenuControlDto'
import {
  DimensionTranslateType,
  DimensionType,
} from '../../../core/api/dto/BuffetReportDto'
import moment from 'moment'
import { getTimeFromMins } from '../DishesPage/CreateDishPage'
import { CommentBlock } from './components/CommentBlock'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { ITag } from '../../../core/api/dto/TagsDto'
import { AppMultiDropdownWithPagination } from '../../../components/AppDropdown/AppMultiDropdownWithPagination'
import { useGetTagsListQuery } from '../../../core/api/TagsApi'
import { useGetPlanMenuDetailByRole } from './hooks/useGetPlanMenuDetailByRole'
import { useGetDimensionDetailByRole } from './hooks/useGetDimensionDetailByRole'
import { useTranslation } from 'react-i18next'

interface IFrozeDetailProps {
  withoutPhotoBlock?: boolean
  withoutInfoBlock?: boolean
  isAdmin?: boolean
}

export const FrozeDetail = ({
  withoutInfoBlock,
  withoutPhotoBlock,
  isAdmin,
}: IFrozeDetailProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const currentDimensionId = searchParams.get('dimension')
  const currentOrderId = searchParams.get('order')
  const index = searchParams.get('index')
  const currentOrder = useGetPlanMenuDetailByRole(Number(currentOrderId!))
  const currentDimension = useGetDimensionDetailByRole(currentDimensionId)

  return (
    <div className="froze-detail-wrap">
      <AppScrollWrapperNew theme="dropdown">
        {!!currentDimension && !!currentDimensionId && (
          <React.Fragment>
            {!withoutInfoBlock && (
              <React.Fragment>
                <div className="title">
                  {t('Замер')} {index}
                </div>
                <FrozeDetailInfo dimensionItem={currentDimension} />
              </React.Fragment>
            )}
            {!withoutPhotoBlock && (
              <FrozeDetailPhotoBlock dimensionItem={currentDimension} />
            )}
            {!withoutInfoBlock && (
              <div>
                <FrozeDetailCommentBlock
                  comment={currentDimension.comment}
                  dimensionId={currentDimension.id}
                  disabled={
                    currentOrder?.planMenuStatus ===
                      PlanMenuStatusType.Closed || !isAdmin
                  }
                />
                <div className="line-h" />
                <FrozeDetailTagsBlock
                  dimensionTags={currentDimension.dimensionTags}
                  dimensionId={currentDimension.id}
                  disabled={
                    currentOrder?.planMenuStatus ===
                      PlanMenuStatusType.Closed || !isAdmin
                  }
                />
              </div>
            )}
          </React.Fragment>
        )}
      </AppScrollWrapperNew>
    </div>
  )
}

const FrozeDetailInfo = ({
  dimensionItem,
}: {
  dimensionItem: IPlanMenuControlDimensionDto
}) => {
  const { t } = useTranslation()
  return (
    <div className="detail-wrap">
      <div className="detail-header">
        <span>{t('Факт')}</span>
        <span>{t('Эталонность')}</span>
      </div>
      <FrozeDetailCommonInfo
        type={dimensionItem.dimensionType}
        dimensionDatetime={dimensionItem.dimensionDatetime}
        implementationTime={dimensionItem.implementationTime}
        dishImplementationTime={dimensionItem.dishImplementationTime || 0}
        implementationTimeExceeded={
          dimensionItem.implementationTimeExceeded || 0
        }
      />
      <div className="line-h" />
      <FrozeDetailWeightInfo
        weight={dimensionItem.weight || 0}
        dishStandardWeight={dimensionItem.dishStandardWeight || 0}
        milletWeight={dimensionItem.milletWeight || 0}
        quantity={dimensionItem.quantity || 0}
      />
      <div className="line-h" />
      <FrozeDetailTempInfo
        temperature={dimensionItem.temperature}
        tempMin={dimensionItem.dishTempMin}
        tempMax={dimensionItem.dishTempMax}
        tempChecker={dimensionItem.tempChecker}
      />
      <div className="line-h" />
    </div>
  )
}

interface IFrozeDetailCommonInfoProps {
  type: DimensionType
  dimensionDatetime: string
  implementationTime: number
  dishImplementationTime: number
  implementationTimeExceeded: number
}

const FrozeDetailCommonInfo = ({
  type,
  dimensionDatetime,
  implementationTime,
  dishImplementationTime,
  implementationTimeExceeded,
}: IFrozeDetailCommonInfoProps) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className="detail-row">
        <div className="detail-item">
          <i className="an-ico an-ico-aim-2" />
          <span>{t('Тип замера')}:</span>
          <div className="dotes" />
          <span>
            <b>{t(DimensionTranslateType[type])}</b>
          </span>
        </div>
        <div className="detail-item" />
      </div>
      <div className="detail-row">
        <div className="detail-item">
          <i className="an-ico an-ico-calendar" />
          <span>{t('Дата замера')}:</span>
          <div className="dotes" />
          <span>
            <b>{moment(dimensionDatetime).format('DD/MM/YYYY')}</b>
          </span>
        </div>
        <div className="detail-item" />
      </div>
      <div className="detail-row">
        <div className="detail-item">
          <i className="an-ico an-ico-watch" />
          <span>{t('Время замера')}: </span>
          <div className="dotes" />
          <span>
            <b>{moment(dimensionDatetime).format('HH:mm')}</b>
          </span>
        </div>
      </div>
      <div className="detail-row">
        <div className="detail-item">
          <i className="an-ico an-ico-watch" />
          <span>{t('Время реализации')}: </span>
          <div className="dotes" />
          <span>
            <b>{getTimeFromMins(implementationTime)}</b>
          </span>
        </div>
        <div className="detail-item">
          <i className="an-ico an-ico-watch" />
          <span>{t('Время реализации')}: </span>
          <div className="dotes" />
          <span>
            <b>{getTimeFromMins(dishImplementationTime)}</b>
          </span>
        </div>
      </div>
      {!!implementationTimeExceeded && (
        <div className="detail-total">
          <div className="close-block">
            <i className="an-ico an-ico-close" />
          </div>
          <span>{t('Время превышено')}</span>
          <div className="dotes" />
          <span>
            <b>{getTimeFromMins(implementationTimeExceeded)}</b>
          </span>
        </div>
      )}
    </React.Fragment>
  )
}

interface IFrozeDetailWeightInfoProps {
  quantity: number
  weight: number
  milletWeight: number
  dishStandardWeight: number
}

const FrozeDetailWeightInfo = ({
  weight,
  quantity,
  milletWeight,
  dishStandardWeight,
}: IFrozeDetailWeightInfoProps) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className="detail-row">
        <div className="detail-item">
          <i className="an-ico an-ico-scales" />
          <span>{t('Вес без тары')}</span>
          <div className="dotes" />
          <span>
            <b>
              {quantity.toFixed(3)} {t('кг')}
            </b>
          </span>
        </div>
        <div className="detail-item" />
      </div>
      <div className="detail-row">
        <div className="detail-item">
          <i className="an-ico an-ico-scales" />
          <span>{t('Вес тары')}</span>
          <div className="dotes" />
          <span>
            <b>
              {milletWeight.toFixed(3)} {t('кг')}
            </b>
          </span>
        </div>
        <div className="detail-item">
          <i className="an-ico an-ico-scales" />
          <span>{t('Вес')}</span>
          <div className="dotes" />
          <span>
            <b>
              {dishStandardWeight.toFixed(3)} {t('кг')}
            </b>
          </span>
        </div>
      </div>
      <div className="detail-row">
        <div className="detail-item">
          <i className="an-ico an-ico-scales" />
          <span>{t('Общий вес')}</span>
          <div className="dotes" />
          <span>
            <b>
              {weight.toFixed(3)} {t('кг')}
            </b>
          </span>
        </div>
        <div className="detail-item" />
      </div>
    </React.Fragment>
  )
}

interface IFrozeDetailTempInfoProps {
  temperature: number | null
  tempMin: number
  tempMax: number
  tempChecker: number
}

const FrozeDetailTempInfo = ({
  temperature,
  tempMin,
  tempMax,
  tempChecker,
}: IFrozeDetailTempInfoProps) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className="detail-row">
        <div className="detail-item">
          <i className="an-ico an-ico-temp" />
          <span>{t('Температура')}</span>
          <div className="dotes" />
          <span>
            <b>
              {temperature || temperature === 0 ? temperature + '°C' : '---'}
            </b>
          </span>
        </div>
        <div className="detail-item">
          <i className="an-ico an-ico-temp" />
          <span>{t('Температура')}</span>
          <div className="dotes" />
          <span>
            <b>{tempMin}°C</b>
            <b> - </b>
            <b>{tempMax}°C</b>
          </span>
        </div>
      </div>
      {tempChecker && (
        <div className="detail-total">
          <div className="close-block">
            <i className="an-ico an-ico-close" />
          </div>
          <span>
            {t(
              tempChecker > 0
                ? 'Температура превышена'
                : 'Температура ниже нормы',
            )}
          </span>
          <div className="dotes" />
          <span>
            <b>{tempChecker.toFixed(0)}°C</b>
          </span>
        </div>
      )}
    </React.Fragment>
  )
}

const FrozeDetailPhotoBlock = ({
  dimensionItem,
}: {
  dimensionItem: IPlanMenuControlDimensionDto
}) => {
  return (
    <div className="photo-wrap">
      {dimensionItem?.thermoPhotoUrl && (
        <FrozeDetailPhotoItem
          img={dimensionItem.thermoPhotoUrl}
          title="Тепловая карта"
        />
      )}
      {dimensionItem?.photoUrl && (
        <FrozeDetailPhotoItem
          img={dimensionItem.photoUrl}
          title="Фото замера"
        />
      )}
      {dimensionItem?.dishStandardImage && (
        <FrozeDetailPhotoItem
          img={dimensionItem.dishStandardImage}
          title="Эталонное фото"
        />
      )}
    </div>
  )
}

interface IFrozeDetailPhotoItem {
  img: string
  title: string
}

const FrozeDetailPhotoItem = ({ img, title }: IFrozeDetailPhotoItem) => {
  const { t } = useTranslation()
  const [imagePopup, setImagePopup] = useState<IAppImagePopup>({
    active: false,
    photo: null,
    title: null,
  })
  const closeImagePopupHandler = useCallback(() => {
    setImagePopup({ active: false, photo: null, title: null })
  }, [])
  const openImagePopupHandler = useCallback((photo: string, title: string) => {
    setImagePopup({ active: true, photo, title })
  }, [])

  return (
    <React.Fragment>
      {imagePopup.active && imagePopup.photo && (
        <AppImagePopup
          title={imagePopup.title || ''}
          img={imagePopup.photo}
          setActive={closeImagePopupHandler}
        />
      )}
      <div className="img-block">
        <div className="title">{t(title)}</div>
        <img src={img} alt="" />
        <div
          className="show-photo"
          onClick={() => openImagePopupHandler(img, t(title))}
        >
          <i className="an-ico an-ico-search" />
        </div>
      </div>
    </React.Fragment>
  )
}

interface IFrozeDetailCommentBlockProps {
  dimensionId: number
  comment: string
  disabled?: boolean
}

const FrozeDetailCommentBlock = ({
  comment,
  dimensionId,
  disabled,
}: IFrozeDetailCommentBlockProps) => {
  const { t } = useTranslation()
  const [updateComment] = useUpdatePlanMenuDimensionCommentMutation()
  const updateCommentHandler = (newComment: string) => {
    updateComment({ id: dimensionId, comment: newComment })
      .unwrap()
      .then(
        () =>
          newComment &&
          comment !== newComment &&
          AppNotification({
            msg: t(`Комментарий успешно ${comment ? 'обнавлен' : 'добавлен'}`),
            type: NotificationType.success,
          }),
      )
  }
  return (
    <CommentBlock
      commentMain={comment}
      updateComment={updateCommentHandler}
      disabled={disabled}
    />
  )
}

interface IFrozeDetailTagsBlockProps {
  dimensionTags: ITag[]
  dimensionId: number
  disabled?: boolean
}

const FrozeDetailTagsBlock = ({
  dimensionTags,
  dimensionId,
  disabled,
}: IFrozeDetailTagsBlockProps) => {
  const { t } = useTranslation()
  const [updateComment] = useUpdatePlanMenuDimensionCommentMutation()
  const addTagHandler = (newTag: ITag[]) => {
    const dimensionTagIds = newTag.map((tag) => tag.id)
    updateComment({
      id: dimensionId,
      dimensionTags: dimensionTagIds,
    })
  }
  const onRemoveTagItem = (id: number) => {
    const newTags = dimensionTags
      .filter((tag) => tag.id !== id)
      .map((tag) => tag.id)
    updateComment({
      id: dimensionId,
      dimensionTags: newTags,
    })
  }
  return (
    <React.Fragment>
      {!disabled && (
        <div className={'mt-10'}>
          <AppMultiDropdownWithPagination<ITag, keyof ITag>
            label={'Тэги'}
            value={dimensionTags}
            getterData={useGetTagsListQuery}
            propToShowInInput={'name'}
            propToShowInList={'name'}
            border
            fullWidth
            placeholder="Выбрать"
            onChange={addTagHandler}
          />
        </div>
      )}
      {!!dimensionTags.length && (
        <React.Fragment>
          <label>{t('Выбранные тэги')}</label>
          <div className="choose-items-block full-width">
            {dimensionTags.map((item) => (
              <div className="choose-item" key={item.id}>
                {item.name}
                {!disabled && (
                  <i
                    className="an-ico an-ico-close"
                    onClick={() => onRemoveTagItem(item.id)}
                  />
                )}
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
