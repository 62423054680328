import React, { useState } from 'react'
import Popup from '../../../../components/popup/Popup'
import { AppInput } from '../../../../components/AppInput/AppInput'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import Checkbox from '../../../../components/checkbox/Checkbox'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
} from '../../../../components/btn/AppBtn'
import { useSearchParams } from 'react-router-dom'
import { useGetSimpleOrderBuffetQuery } from '../../../../core/api/BuffetOrderApi'
import { LIMIT } from '../../../../core/api/BaseApi'
import { IBuffetOrderDto } from '../../../../core/api/dto/BuffetOrderDto'
import { useNavigate } from 'react-router'
import { AllRoutes } from '../../../../core/routes/AllRoutes'
import { useTranslation } from 'react-i18next'

interface IAddFrozePopupProps {
  dishId: number | null
  onClose: () => void
}

export const AddFrozePopup = ({ dishId, onClose }: IAddFrozePopupProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const orderIds = searchParams.get('ids')
  const { data: orderList } = useGetSimpleOrderBuffetQuery({
    limit: LIMIT.buffet,
    ids: orderIds || undefined,
  })
  const [currentOrder, setCurrentOrder] = useState<IBuffetOrderDto | null>(null)

  const onNextHandler = () => {
    if (!!currentOrder && !!dishId) {
      navigate(
        `${AllRoutes.planMenuIssuing.path}/${currentOrder.id}/${dishId}/`,
      )
    }
  }

  if (!orderList) {
    return null
  }

  return (
    <Popup closePopup={onClose}>
      <div className="stations-list-popup">
        <span className="title-stations-popup">{t('Выбор план-меню')}</span>
        <AppInput
          label="Поиск"
          placeholder="Введите название план-меню"
          onChange={() => {}}
          fullWidth
          ico="search"
          border
        />
        <div className="stations-list">
          <AppScrollWrapperNew theme="dropdown">
            {orderList.results.map((order, index) => (
              <div
                className="stations-item"
                key={`order-${order.id}-${index}`}
                onClick={() => setCurrentOrder(order)}
              >
                <Checkbox
                  name={`${order.id}`}
                  value={currentOrder?.id === order.id}
                  onChange={() => {}}
                  isRadioView
                  text={order.name}
                />
              </div>
            ))}
          </AppScrollWrapperNew>
        </div>
        <div className="buttons-block mt-20">
          <AppBtn
            title="Отменить"
            color={AppBtnColor.outlineViolet}
            sized={AppBtnSize.md}
            iconClassName={'close'}
            onClick={onClose}
          />
          <AppBtn
            title="Выбрать"
            iconClassName={'done'}
            color={AppBtnColor.violetWhite}
            sized={AppBtnSize.md}
            disabled={!currentOrder}
            onClick={onNextHandler}
          />
        </div>
      </div>
    </Popup>
  )
}
