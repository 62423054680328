import AppBtn, { AppBtnColor, AppBtnSize } from './btn/AppBtn'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../core/store/store'
import { setBlockedLink } from '../core/store/system/systemSlice'
import { BlockLinkPopup } from '../pages/BuffetLinePage/components/BlockLinkPopup'
import { IcoPosition } from './AppInput/AppInput'
import { useOutsideClick } from '../utils/useOutsideClick'
import { useGetPosition } from '../utils/useGetPosition'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

interface IAction {
  minWidth?: string
  btnTitle: string
  btnIco?: string
  positionIco?: IcoPosition
  disabled?: boolean
  onClick?: () => void
  color: AppBtnColor
  sized?: AppBtnSize
  items?: {
    title: string
    onClick: () => void
    ico: string
  }[]
}

export interface IAppTitlePageProps {
  title: string
  actions?: IAction[]
  goBack?: boolean
  goBackPath?: string
  isDirty?: boolean
  style?: {}
  isFiltersOpen?: boolean
  setIsFiltersOpen?: React.Dispatch<React.SetStateAction<boolean>>
  isReportIntensity?: boolean
  isReportIntensityChart?: boolean
  onChangeChart?: (value: boolean) => void
}

export const AppTitlePage = React.memo(
  ({
    title,
    actions,
    goBack,
    goBackPath,
    isDirty,
    style,
    isFiltersOpen,
    setIsFiltersOpen,
    isReportIntensity,
    isReportIntensityChart,
    onChangeChart,
  }: IAppTitlePageProps) => {
    const { t } = useTranslation()
    const { blockLinkState } = useAppSelector((state) => state.system)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    const openFilters = useMemo(
      () => ({
        btnTitle: isFiltersOpen ? 'Скрыть фильтры' : 'Показать фильтры',
        onClick: () => setIsFiltersOpen?.((prevState) => !prevState),
        color: AppBtnColor.extraVioletViolet,
        minWidth: '184px',
      }),
      [isFiltersOpen],
    )

    const goBackHandler = () => {
      if (!blockLinkState.linkBlock) {
        if (!!goBackPath) {
          navigate(goBackPath, { state: { black: true } })
          return
        }
        navigate(-1)
      }
      if (blockLinkState.linkBlock) {
        dispatch(
          setBlockedLink({
            ...blockLinkState,
            clickedLink: goBackPath ? goBackPath : 'goBack',
          }),
        )
      }
    }

    useEffect(() => {
      if (isDirty && !blockLinkState.linkBlock) {
        dispatch(setBlockedLink({ ...blockLinkState, linkBlock: true }))
      }
      if (!isDirty) {
        dispatch(setBlockedLink({ ...blockLinkState, linkBlock: false }))
      }
    }, [isDirty, dispatch])

    const fullActions = !!setIsFiltersOpen
      ? [...(actions || []), openFilters]
      : actions

    return (
      <React.Fragment>
        {blockLinkState.clickedLink && <BlockLinkPopup />}
        <div className="page-title-wrap" style={style}>
          <div className="title-block">
            {goBack && (
              <AppBtn
                color={AppBtnColor.whiteOutlineViolet}
                title={'Назад'}
                sized={AppBtnSize.md}
                iconClassName={'arrow'}
                onClick={goBackHandler}
              />
            )}
            <h3 dangerouslySetInnerHTML={{ __html: t(title) }} />
          </div>
          <div className="actions-page-wrap">
            {isReportIntensity && (
              <label className="custom-switcher" htmlFor="checkbox">
                <input
                  type="checkbox"
                  id="checkbox"
                  checked={isReportIntensityChart}
                  onChange={() =>
                    onChangeChart && onChangeChart(!isReportIntensityChart)
                  }
                />
                <div className="slider-container">
                  <span className="slider"></span>
                  <span className="option position-left">
                    <i className="an-ico an-ico-table"></i>Табличный вид
                  </span>
                  <span className="option position-right">
                    <i className="an-ico an-ico-chart-vector"></i>Графический
                    вид
                  </span>
                </div>
              </label>
            )}
            {fullActions?.map((action, index) => (
              <ActionItem
                action={action}
                key={`page-action-${index}-${action.btnTitle}`}
              />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  },
)

interface IActionItemProps {
  action: IAction
}

const ActionItem = ({ action }: IActionItemProps) => {
  const {
    minWidth,
    items,
    color,
    onClick,
    btnTitle,
    btnIco,
    disabled,
    positionIco,
    sized,
  } = action
  const { t } = useTranslation()
  const [ref, setRef] =
    useState<React.MutableRefObject<HTMLDivElement | null> | null>(null)
  const itemsRef = useRef<HTMLDivElement | null>(null)
  const [active, setActive] = useState(false)

  useOutsideClick(ref, () => setActive(false), active)
  const position = useGetPosition(ref, active)

  return !!items ? (
    <React.Fragment>
      <AppBtn
        minWidth={minWidth}
        sized={AppBtnSize.md}
        color={color}
        onClick={() => setActive((prevState) => !prevState)}
        setRef={setRef}
        disabled={disabled}
      >
        <React.Fragment>
          <i className={`an-ico an-ico-${btnIco}`} />
          <span>{t(btnTitle)}</span>
          <i className={'an-ico an-ico-arrow-down'} />
        </React.Fragment>
      </AppBtn>
      {active && !disabled && (
        <div
          className="btn-items"
          style={{
            top: position.top - 10,
            left:
              window.innerWidth < 500 ? position.left + 50 : position.left + 10,
          }}
          ref={itemsRef}
        >
          {items.map((item, index) => (
            <div
              key={`btn-i-${index}`}
              className="btn-item"
              onClick={() => {
                setActive(false)
                item.onClick()
              }}
              onTouchStart={() => {
                setActive(false)
                item.onClick()
              }}
            >
              <i className={`an-ico an-ico-${item.ico}`} />
              <div>{t(item.title)}</div>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  ) : (
    <AppBtn
      minWidth={minWidth}
      sized={sized || AppBtnSize.md}
      color={color}
      title={btnTitle}
      iconClassName={btnIco}
      onClick={onClick}
      disabled={disabled}
      iconPosition={positionIco}
    />
  )
}
