import { IRoleState } from '../../api/dto/AuthDto'

export type credentialsByStoreType = IRoleState & {
  token: string | null
}

export const authInitialState: credentialsByStoreType = {
  token: null,
  currentRole: [],
}
