import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  useDeleteCategoryMutation,
  useGetCategoryByRestaurantQuery,
  useUpdateCategoryMutation,
} from '../../core/api/CategoryApi'
import { ICategory } from '../../core/api/dto/CategoriesObjDto'
import InputField, { InputSize } from '../input/InputField'
import { AppNotification, NotificationType } from '../notification/Notification'
import PhotoUploader, {
  PhotoUploaderSize,
} from '../photoUploader/PhotoUploader'
import Spinner from '../spinner/Spinner'
import Popup, { PopupBtnBgColor, PopupSize } from './Popup'

interface IPopupProps {
  setIsShowPopup: (arg: boolean) => void
  currentCategory: ICategory
}

const ChangeCategoryPopup = ({
  setIsShowPopup,
  currentCategory,
}: IPopupProps) => {
  const [updateCategory] = useUpdateCategoryMutation()
  const [deleteCategory] = useDeleteCategoryMutation()

  const [newCategory, setNewCategory] = useState<ICategory>(currentCategory)
  const [categoryForUpdate, setCategoryForUpdate] = useState<
    Partial<ICategory>
  >({
    id: currentCategory.id,
  })
  const [isValid, setIsValid] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const navigate = useNavigate()
  const { technologicalCatalogRestaurantId } = useParams()
  const { data: allCategories } = useGetCategoryByRestaurantQuery(
    technologicalCatalogRestaurantId!,
    { skip: !technologicalCatalogRestaurantId },
  )

  const validateCategoryName = useCallback(() => {
    const name = newCategory.name.trim()
    if (name) {
      const result = allCategories!.results.findIndex(
        (el: ICategory) => el.name === name,
      )

      result === -1 || allCategories!.results[result].id === newCategory.id
        ? setIsValid(true)
        : setIsValid(false)
    }

    !name && setIsValid(false)
  }, [newCategory.name, allCategories, newCategory.id])

  useEffect(() => {
    if (newCategory.name !== currentCategory.name) {
      validateCategoryName()
    }
  }, [newCategory.name, validateCategoryName, currentCategory.name])

  const updateCurrentCategory = () => {
    const isNotCategoryChange = Object.keys(categoryForUpdate).length <= 1
    if (isValid && !isNotCategoryChange && !disabled) {
      setDisabled(true)
      updateCategory(categoryForUpdate)
        .unwrap()
        .then(() => {
          AppNotification({
            msg: 'Категория обновлена!',
            type: NotificationType.success,
          })
        })
        .catch(() => {})
        .finally(() => {
          closePopup()
          setDisabled(false)
        })
    }
  }
  const deleteCurrentCategory = () => {
    if (!disabled) {
      setDisabled(true)
      deleteCategory(newCategory.id)
        .unwrap()
        .then(() => {
          AppNotification({
            msg: 'Категория удалена!',
            type: NotificationType.success,
          })
          navigate(`./`)
        })
        .catch(() => {})
        .finally(() => {
          setDisabled(false)
          closePopup()
        })
    }
  }

  const actionsUpdateCategory = disabled
    ? [
        {
          text: <Spinner />,
          bgColor: PopupBtnBgColor.violet,
        },
      ]
    : [
        {
          text: 'Обновить',
          onClick: updateCurrentCategory,
          bgColor:
            !isValid || Object.keys(categoryForUpdate).length <= 1
              ? PopupBtnBgColor.grayMedium
              : PopupBtnBgColor.violet,
        },
        {
          text: 'Удалить',
          onClick: deleteCurrentCategory,
          bgColor: PopupBtnBgColor.red,
        },
      ]

  const closePopup = () => {
    if (!disabled) {
      setIsShowPopup(false)
    }
  }

  const setDataForUpdate = useCallback(
    (val: string | null, field: keyof ICategory) => {
      if ((val && val.trim()) !== currentCategory[field]) {
        setCategoryForUpdate({ ...categoryForUpdate, [field]: val })
      } else if (field in categoryForUpdate) {
        const newState = Object.entries(categoryForUpdate)
          .filter(([key, _]) => key !== field)
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {} as Partial<ICategory>,
          )
        setCategoryForUpdate({ ...newState })
      }
      setNewCategory({ ...newCategory, [field]: val })
    },
    [newCategory, categoryForUpdate, currentCategory],
  )

  return (
    <Popup
      size={PopupSize.md}
      actions={actionsUpdateCategory}
      closePopup={closePopup}
    >
      <div className="popup-content create-category">
        <div className="popup-icon-close">
          <i className="an-ico an-ico-close" onClick={closePopup} />
        </div>
        <h4>Текущая категория</h4>
        <p>Введите название новой категории:</p>
        <InputField
          sized={InputSize.sm}
          value={newCategory.name}
          onChange={(val) => setDataForUpdate(val, 'name')}
          required={true}
        />
        {newCategory.name.trim() && !isValid ? (
          <p className="alert-message">
            <i className="an-ico an-ico-alert" />
            <span>
              Категория с таким названием уже существует, измените название
            </span>
          </p>
        ) : null}
        <PhotoUploader
          size={PhotoUploaderSize.sm}
          photo={newCategory.iconUrl}
          setPhoto={(val) => setDataForUpdate(val, 'iconUrl')}
        />
      </div>
    </Popup>
  )
}

export default ChangeCategoryPopup
