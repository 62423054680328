import { Navigate, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/store'
import { NotAuthRoutes } from './NotAuthRoutes'
import { AuthRoutes } from './AuthRoutes'
import { useGetCurrentUserQuery } from '../api/UserApi'
import { useEffect } from 'react'
import { setCurrentRoles } from '../store/auth/authSlice'

interface IRedirect {
  path?: string
}

export const AppRedirect = ({ path = '/' }: IRedirect) => {
  const location = useLocation()
  return <Navigate to={path} state={{ from: location }} replace />
}

const RequireAuth = ({
  children,
  routesWithAuth,
}: {
  children: JSX.Element
  routesWithAuth: JSX.Element
}) => {
  const isAuthenticated = useAppSelector((state) => state.foodBoxAuth.token)
  const rehydrated = useAppSelector((state) => state.system.rehydrated)

  if (!rehydrated) {
    return null
  }

  if (!isAuthenticated) {
    return children
  }

  return routesWithAuth
}

export const AppRouter = () => {
  const isAuthenticated = useAppSelector((state) => state.foodBoxAuth.token)
  const pathname = useLocation().pathname
  const { data: currentUser } = useGetCurrentUserQuery(
    {},
    { skip: !isAuthenticated || pathname.includes('logout') },
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentUser) {
      dispatch(
        setCurrentRoles({ currentRoleByPath: currentUser.userRoles.roleType }),
      )
    }
  }, [currentUser, dispatch])

  return (
    <RequireAuth routesWithAuth={<AuthRoutes />}>
      <NotAuthRoutes />
    </RequireAuth>
  )
}
