import React, { useEffect, useState } from 'react'

export const useGetPosition = (
  ref: React.MutableRefObject<HTMLDivElement | null> | null,
  active: boolean,
): { left: number; top: number } => {
  const [position, setPosition] = useState({ left: 0, top: 0 })

  const getPosition = () => {
    if (ref?.current) {
      const { left, top } = ref.current.getBoundingClientRect()
      setPosition({ left, top })
    }
  }

  useEffect(() => {
    const scrollWrapper = document.getElementsByClassName(
      'app-scroll-wrap main-scroll',
    )[0]
    if (active && scrollWrapper) {
      getPosition()
      scrollWrapper.addEventListener('resize', getPosition, false)
      scrollWrapper.addEventListener('scroll', getPosition, false)
    }
    return () => {
      if (active && scrollWrapper) {
        scrollWrapper.removeEventListener('resize', getPosition, false)
        scrollWrapper.removeEventListener('scroll', getPosition, false)
      }
    }
  }, [active])

  return position
}
