import React, { useEffect, useRef } from 'react'
import { Outlet } from 'react-router'
import Versions from '../../components/versions/Versions'
import { useAppSelector } from 'core/store/store'

export const AuthPageLayout = () => {
  const { isKeyboard } = useAppSelector((state) => state.isKeyboard)
  const { isKeyboard: isKeyboardGlobal } = useAppSelector((state) => state.buffet)
  const pageEndRef = useRef<HTMLDivElement | null>(null)

  const scrollToBottom = () => {
    pageEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [isKeyboard, isKeyboardGlobal])

  return (
    <main className={`auth-page ${(isKeyboard && isKeyboardGlobal) ? 'auth-page-with-keyboard' : ' '}`}>
      <div className="auth" onClick={(e) => e.stopPropagation()}>
        <Outlet />
        <div ref={pageEndRef}>
          <Versions />
        </div>
      </div>
    </main>
  )
}
