import { createSlice } from '@reduxjs/toolkit'
import { NotificationInitialState } from './notificationsState'

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: NotificationInitialState,
  reducers: {
    setNotificationCount(state, action) {
      const { payload } = action
      state.count = payload
    },
  },
})

export const { reducer: NotificationReducer } = notificationSlice
export const { setNotificationCount } = notificationSlice.actions
