export function throttle<T>(callback: T, delay: number) {
  let isWaiting = false
  let savedArgs: any[] | null = null
  let savedThis: null | any = null
  return function wrapper(this: any, ...args: any) {
    if (isWaiting) {
      savedArgs = args
      savedThis = this
      return
    }
    // @ts-ignorer
    callback.apply(this, args)
    isWaiting = true
    setTimeout(() => {
      isWaiting = false
      if (savedThis) {
        wrapper.apply(savedThis, savedArgs!)
        savedThis = null
        savedArgs = args
      }
    }, delay)
  }
}
