import {
  AppNotification,
  NotificationType,
} from '../components/notification/Notification'
import {
  ErrorMsgFields,
  ErrorBaseResponseDto,
  TFormFields,
} from '../core/api/dto/ErrorObjDto'
import { CookingStatus, IOrder } from '../core/api/dto/OrderObjDto'
import React from 'react'

export const scrollUp = (
  refRoot: React.MutableRefObject<HTMLDivElement | null>,
  heightChild: number,
  margin: number = 20,
) => {
  if (refRoot && refRoot.current && heightChild) {
    refRoot.current.scrollTop -= heightChild + margin
  }
}

export const scrollDown = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  heightChild: number,
  margin: number = 20,
) => {
  if (ref && ref.current) {
    ref.current.scrollTop += heightChild + margin
  }
}

export const scrollLeft = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
) => {
  if (ref && ref.current) {
    ref.current.scrollLeft -= ref.current?.getBoundingClientRect().width
  }
}
export const scrollRight = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
) => {
  if (ref && ref.current) {
    ref.current.scrollLeft += ref.current?.getBoundingClientRect().width
  }
}

export function compareProps<Props extends object>(
  prevProps: Props,
  nextProps: Props,
) {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

export const filterMealItems = (info: IOrder, isWaiterPage: boolean = true) => {
  const readyDishes = info.orderLines.filter(
    (el) =>
      el.orderLineCookingStatuses?.cookingStatus === CookingStatus.Cook_done,
  )
  const atWorkDishes = info.orderLines.filter(
    (el) =>
      el.orderLineCookingStatuses?.cookingStatus === CookingStatus.Cooking,
  )
  const notReadyDishes = info.orderLines.filter(
    (el) =>
      !el.orderLineCookingStatuses ||
      el.orderLineCookingStatuses.cookingStatus === CookingStatus.Waiting,
  )

  return isWaiterPage
    ? [...readyDishes, ...atWorkDishes, ...notReadyDishes]
    : [...notReadyDishes, ...atWorkDishes]
}

export const errorHandling = ({ data }: ErrorBaseResponseDto) => {
  if (!data) {
    AppNotification({
      msg: 'Нет соединения!',
      type: NotificationType.error,
    })
  } else if (typeof data === 'string') {
    return AppNotification({
      msg: data,
      type: NotificationType.error,
    })
  } else if (
    ErrorMsgFields.detail in data &&
    Array.isArray(data[ErrorMsgFields.detail])
  ) {
    AppNotification({
      msg: `${data[ErrorMsgFields.detail]}`,
      type: NotificationType.error,
    })
  } else if (ErrorMsgFields.detail in data) {
    AppNotification({
      msg: `${data[ErrorMsgFields.detail]}`,
      type: NotificationType.error,
    })
  } else {
    const key = Object.keys(data)[0] as keyof TFormFields
    const message =
      `${key}`.length === 1
        ? data[0]
        : !!data[key][0]
        ? data[key][0]
        : // @ts-ignore
          data[key].lineGuid
    console.log('message', message)
    AppNotification({
      msg: `${message === 'К' ? 'Некорректный общий вес' : message}`.replace(
        'Поля restaurant, name должны производить массив с уникальными значениями.',
        'Категория с таким названием уже существует.',
      ),
      type: NotificationType.error,
    })
  }
}

export const filterOrders = (orders: IOrder[]) => {
  const filterOrders = [] as IOrder[]

  orders.forEach((el) => {
    if (el.orderLines.length) {
      const orderLines = el.orderLines.filter((item) => {
        return item.quantity !== 0
      })

      orderLines.length && filterOrders.push({ ...el, orderLines })
    }
  })

  return filterOrders
}
