import React, { useCallback, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import { AppTable } from '../../../components/AppTable/AppTable'
import { AddPlanMenuTypePopup } from './AddPlanMenuTypePopup'
import { IPopup } from '../CategoriesPage/CategoriesPage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import {
  useArchiveMultiplePlanMenuTypeMutation,
  useGetPlanMenuTypeListQuery,
} from '../../../core/api/PlanMenuTypeApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import { IPlanMenuTypeRequest } from '../../../core/api/dto/PlanMenuTypeDto'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useDeleteDebounce } from '../../../utils/useDeleteDebounce'

const headerData = [{ title: 'Тип план-меню', colWidth: '95%', sort: 'name' }]

export const PlanMenuTypePage = () => {
  const pageParams = useParamsControl<
    IPlanMenuTypeRequest,
    keyof IPlanMenuTypeRequest
  >({
    withPagination: true,
    whiteList: ['search', 'ordering'],
  })
  const {
    data: planMenuTypeList,
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
  } = useGetPlanMenuTypeListQuery(
    { ...pageParams, archived: '0' },
    { skip: !pageParams },
  )
  const [openCreatePopup, setOpenCreatePopup] = useState<IPopup>({
    active: false,
    id: null,
  })
  const [archiveMultiplePlanMenuType] = useArchiveMultiplePlanMenuTypeMutation()
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)

  const openEditPopupHandler = useCallback(
    (id: number) => setOpenCreatePopup({ id, active: true }),
    [],
  )

  const closePopupHandler = useCallback(
    () => setOpenCreatePopup({ id: null, active: false }),
    [],
  )

  const deleteHandler = () => {
    if (!!chooseItems) {
      archiveMultiplePlanMenuType({
        data: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>{
          AppNotification({
            msg: 'Выбранные типы план-меню успешно удалены!',
            type: NotificationType.success,
          })
          setChooseItems(null)
        })
    }
  }
  const [onClickHandler] = useDeleteDebounce({ fn: deleteHandler })
  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Создать тип план-меню',
        btnIco: 'add',
        onClick: () =>
          setOpenCreatePopup((prevState) => ({ ...prevState, active: true })),
        color: AppBtnColor.whiteOutlineViolet,
      },
    ],
    [],
  )
  if (!planMenuTypeList) {
    return null
  }

  return (
    <React.Fragment>
      {openCreatePopup.active && (
        <AddPlanMenuTypePopup
          closePopup={closePopupHandler}
          id={openCreatePopup.id}
        />
      )}
      <div className="width-for-page-wrapper">
        <AppTitlePage title="Типы план-меню" actions={actions} />
        <div className="col-7-width-from-1000">
          <div className="filter-block mt-20">
            <AppFilterInput
              fullWidth
              searchParam={'search'}
              ico={'search'}
              label={'Поиск'}
              placeholder="Например: обед"
            />
          </div>
          <AppTable
            data={planMenuTypeList}
            headerData={headerData}
            chooseItems={chooseItems}
            chooseMode
            setChooseItems={setChooseItems}
            tableDataSelectors={[
              {
                renderItem: (item) => (
                  <div
                    className={`table-link ${
                      item.archived ? 'delete-item' : ''
                    }`}
                    onClick={() => openEditPopupHandler(item.id)}
                  >
                    {item.name}
                  </div>
                ),
              },
            ]}
            chooseActions={tableActions}
            isLoading={isLoadingGet || isFetchingGet}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
