import { api } from './BaseApi'
import {
  IBuffetMilletBase,
  IBuffetMilletDto,
  IBuffetMilletRequest,
  IBuffetMilletResponse,
  IBuffetMilletUpload,
  IBuffetMilletUploadDto,
} from './dto/BuffetMilletDto'

export const buffetMilletApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuffetMillet: builder.query<IBuffetMilletResponse, IBuffetMilletRequest>(
      {
        query: (params) => ({
          url: '/buffet_millet/',
          params: !!params ? params : {},
        }),
        providesTags: ['BuffetMillet'],
      },
    ),
    getBuffetMilletByDish: builder.query<
      IBuffetMilletResponse,
      { dishId: number } & IBuffetMilletRequest
    >({
      query: ({ dishId, ...params }) => ({
        url: `/buffet_millet/get_millets_by_dish/${dishId}/`,
        params: !!params ? params : {},
      }),
      providesTags: ['BuffetMillet'],
    }),
    getBuffetMilletDetail: builder.query<IBuffetMilletDto, { id: number }>({
      query: ({ id }) => ({
        url: `/buffet_millet/${id}/`,
      }),
      providesTags: ['BuffetMillet'],
    }),
    getMilletsByDish: builder.query<IBuffetMilletResponse, { id: number }>({
      query: ({ id }) => ({
        url: `/buffet_millet/get_millets_by_dish/${id}/`,
      }),
      providesTags: ['BuffetMillet'],
    }),
    getImageMilletFromCamera: builder.query<{ image: string }, null>({
      query: () => ({
        url: `/buffet_millet/get_image_from_camera/`,
      }),
    }),
    createBuffetMillet: builder.mutation<IBuffetMilletDto, IBuffetMilletBase>({
      query: (body) => ({
        method: 'POST',
        url: '/buffet_millet/',
        body,
      }),
      invalidatesTags: ['BuffetMillet'],
    }),
    updateBuffetMillet: builder.mutation<
      IBuffetMilletDto,
      { id: number; data: IBuffetMilletBase }
    >({
      query: ({ id, data }) => ({
        method: 'PATCH',
        url: `/buffet_millet/${id}/`,
        body: data,
      }),
      invalidatesTags: ['BuffetMillet'],
    }),
    uploadBuffetMillet: builder.mutation<
      IBuffetMilletUploadDto,
      { data: FormData }
    >({
      query: ({ data }) => ({
        method: 'POST',
        url: '/buffet_millet/load_millets_from_file/',
        data,
        body: data,
      }),
      invalidatesTags: ['BuffetMillet'],
    }),
    updateUploadedBuffetMillet: builder.mutation<
      IBuffetMilletUploadDto,
      { data: IBuffetMilletUpload[] }
    >({
      query: ({ data }) => ({
        method: 'PATCH',
        url: '/buffet_millet/update_millets/',
        body: data,
      }),
      invalidatesTags: ['BuffetMillet'],
    }),
    unloadBuffetMillet: builder.query<
      ArrayBuffer | Uint8Array,
      IBuffetMilletRequest
    >({
      query: () => ({
        method: 'GET',
        url: '/buffet_millet/export_millets_to_file/',
      }),
      providesTags: ['BuffetMillet'],
    }),
    archiveMultipleBuffetMillet: builder.mutation<
      IBuffetMilletDto,
      { millets: number[] }
    >({
      query: ({ millets }) => ({
        method: 'PATCH',
        url: `/buffet_millet/archive_multiple_millets/`,
        body: { millets },
      }),
      invalidatesTags: ['BuffetMillet'],
    }),
  }),
})

export const {
  useGetBuffetMilletQuery,
  useGetBuffetMilletByDishQuery,
  useGetBuffetMilletDetailQuery,
  useGetImageMilletFromCameraQuery,
  useLazyGetImageMilletFromCameraQuery,
  useCreateBuffetMilletMutation,
  useUpdateBuffetMilletMutation,
  useUploadBuffetMilletMutation,
  useUpdateUploadedBuffetMilletMutation,
  useArchiveMultipleBuffetMilletMutation,
  useUnloadBuffetMilletQuery,
} = buffetMilletApi
