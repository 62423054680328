import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppInput, InputMaskType } from './AppInput'
import moment from 'moment'
import { resetPaginationByParamsChanged } from '../../utils/resetPaginationByParamsChanged'

export interface IAppFilterInput {
  searchParam: string
  placeholder?: string
  label?: string
  shouldResetPagination?: boolean
  ico?: string
  fullWidth?: boolean
  disabled?: boolean
  border?: boolean
  type?: string
  clearValue?: boolean
  setClearValue?: (value: boolean) => void
  inputMask?: InputMaskType
  clear?: boolean
}

export const AppFilterInput = ({
  searchParam,
  label,
  placeholder,
  shouldResetPagination = true,
  fullWidth,
  disabled,
  ico,
  type,
  border,
  clearValue,
  setClearValue,
  inputMask,
}: PropsWithChildren<IAppFilterInput>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [state, setState] = useState(searchParams.get(searchParam))

  const onChangeHandler = useCallback(() => {
    setSearchParams((sp) => {
      const isParameterEntry = !!sp.get(searchParam)
      if (!isParameterEntry && !!state) {
        sp.append(searchParam, state)
      } else if (!!state) {
        sp.set(searchParam, state)
      } else if (!state) {
        sp.delete(searchParam)
      }
      return sp
    })
  }, [searchParam, state])

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const handler = setTimeout(() => {
        onChangeHandler()
      }, 500)
      return () => {
        clearTimeout(handler)
      }
    }
  }, [onChangeHandler])

  useEffect(() => {
    if (!!clearValue && !!setClearValue) {
      setState('')
      setClearValue(false)
    }
  }, [clearValue, setClearValue])

  const changeHandler = (value: string) => {
    if (type === 'date' && value) {
      setState(moment(value).format('DD.MM.YYYY'))
    } else {
      setState(value)
    }
    if (shouldResetPagination) {
      resetPaginationByParamsChanged({ searchParams, setSearchParams })
    }
  }

  return (
    <AppInput
      value={state}
      onChange={changeHandler}
      label={label}
      placeholder={placeholder}
      type={!!type ? type : 'text'}
      fullWidth={fullWidth}
      disabled={disabled}
      ico={ico}
      border={border}
      inputMask={inputMask}
      clear
    />
  )
}
