import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  useGetAllOrdersBuffetQuery,
  useGetOrderByIdQuery,
} from '../../core/api/OrderApi'
import AppBtn, { AppBtnColor, AppBtnSize } from '../btn/AppBtn'

interface ISelect {
  refetchDishes: () => void
}

const BuffetSelect = ({ refetchDishes }: ISelect) => {
  const { data: allOrdersBuffet, refetch: refetchOrders } =
    useGetAllOrdersBuffetQuery(null, {
      refetchOnMountOrArgChange: true,
    })
  const { buffetOrderId } = useParams()

  const { data: currentOrder } = useGetOrderByIdQuery(
    buffetOrderId ? buffetOrderId : '',
    { skip: !buffetOrderId },
  )
  const navigate = useNavigate()
  const [isShow, setIsShow] = useState(false)

  const handlerShowHideOptions = () =>
    allOrdersBuffet?.results.length && setIsShow(!isShow)

  const changeOrder = useCallback(
    (id: number) => {
      setIsShow(!isShow)
      navigate(`${id}`)
    },
    [navigate, isShow],
  )

  const refetchData = () => {
    refetchDishes()
    refetchOrders()
  }

  return (
    <div className="wrapper-select">
      <div
        className={`select ${isShow ? 'open' : ''} md ${
          currentOrder ? 'check' : ''
        } `}
      >
        <div className="select-trigger" onClick={handlerShowHideOptions}>
          <div className="option">
            <span>
              {currentOrder?.sourceRepresent
                ? currentOrder.sourceRepresent
                : allOrdersBuffet?.results.length
                ? 'Выберите план-меню'
                : 'Список пуст'}
            </span>
          </div>
          <div className="wrapper-icon">
            <i className="an-ico an-ico-arrow-down" />
          </div>
        </div>

        {isShow && allOrdersBuffet?.results.length && (
          <div className="wrapper-list">
            <div className="list-options">
              {allOrdersBuffet?.results.map((el, idx) => (
                <div
                  className="option"
                  key={`option-${el.id}-${idx}`}
                  onClick={() => changeOrder(el.id)}
                >
                  <span>{el.sourceRepresent}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <AppBtn
        iconClassName="refresh"
        sized={AppBtnSize.sm}
        color={AppBtnColor.violetWhite}
        onClick={refetchData}
      />
    </div>
  )
}

export default BuffetSelect
