import React, { useEffect, useState } from 'react'
import { AppDropdown } from './AppDropdown'
import { IWithPaginationRequestSearch } from '../../core/api/dto/BaseDto'
import { emptyResponse } from '../../utils/emptyResponse'

export interface IExtraOptions {
  [key: string]: string | number | boolean | undefined
}

interface IAppDropdownWithPaginationProps<T, TKey> {
  value: T | null
  propToShowInList?: TKey // если data массив строк, то не передаем propToShowInList и propToShowInInput
  sparePropToShowInList?: TKey
  extraPropToShowInList?: TKey
  propToShowInInput?: TKey
  onChange: (value: T) => void
  resetValueHandler?: () => void
  getterData: (params: any, extraParams?: any) => any
  label?: string
  require?: boolean
  placeholder?: string
  id?: string
  emptyListMessage?: string
  error?: string | null
  disabled?: boolean
  fullWidth?: boolean
  border?: boolean
  multiValue?: T[] | []
  extraOptions?: IExtraOptions
  skip?: boolean
  required?: boolean
  minWidth?: boolean
}

const AppDropdownWithPagination = <T, TKey extends keyof T>({
  emptyListMessage,
  extraOptions,
  getterData,
  multiValue,
  resetValueHandler,
  value,
  onChange,
  fullWidth,
  label,
  placeholder,
  skip,
  error,
  propToShowInList,
  propToShowInInput,
  extraPropToShowInList,
  sparePropToShowInList,
  disabled,
  border,
  required,
  minWidth,
  id,
}: IAppDropdownWithPaginationProps<T, TKey>) => {
  const [params, setParams] = useState<IWithPaginationRequestSearch | null>({
    limit: 20,
    offset: 0,
    search: '',
  })
  const [inputValue, setInputValue] = useState<string>('')

  const [resetData, setResetData] = useState<boolean>(false)
  const { data, isFetching } = getterData(
    !!params
      ? { ...extraOptions, ...params, archived: '0' }
      : !!extraOptions
      ? extraOptions
      : null,
    { skip: !!skip || !params },
  )
  useEffect(() => {
    if (!!extraOptions) {
      setInputValue('')
      setParams((prevState) => ({
        ...prevState,
        limit: 20,
        offset: 0,
      }))
      if (!!data && !!data.results) {
        setResetData(true)
      }
    }
  }, [JSON.stringify(extraOptions)])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputValue === '') {
        setParams({ limit: 20, offset: 0, search: inputValue })
      } else {
        setParams({ limit: 20, offset: 0, search: inputValue })
      }
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue])

  const inputSearchFn = (value: string) => {
    setInputValue(value)
  }
  const onChangeHandler = (value: T) => {
    onChange(value)
  }

  return (
    <AppDropdown
      label={label}
      loadMoreCallBack={(newPageParams) => {
        setParams((prev) => {
          return { ...prev, ...newPageParams }
        })
      }}
      resetData={resetData}
      setResetData={setResetData}
      placeholder={
        !!emptyListMessage && !!data && !data.results.length && !inputValue
          ? emptyListMessage
          : placeholder
      }
      propToShowInList={propToShowInList}
      error={error}
      data={!!data ? data : (emptyResponse as any)}
      value={value}
      onChange={onChangeHandler}
      inputSearch={inputValue}
      inputSearchFn={inputSearchFn}
      propToShowInInput={propToShowInInput}
      disabled={disabled}
      resetValueHandler={resetValueHandler}
      multiValue={multiValue}
      fullWidth={fullWidth}
      border={border}
      isLoading={isFetching}
      required={required}
      minWidth={minWidth}
      extraPropToShowInList={extraPropToShowInList}
      sparePropToShowInList={sparePropToShowInList}
      id={`-pag-dr${id || ''}`}
    />
  )
}

export default AppDropdownWithPagination
