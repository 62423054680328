import React, { useCallback, useState } from 'react'
import {
  useGetDishByIdQuery,
  useGetDishVersionByIdQuery,
} from '../../core/api/DishApi'
import { CookingStatus } from '../../core/api/dto/OrderObjDto'
import {
  useChangeCookStatusMutation,
  useGetOrderByIdQuery,
} from '../../core/api/OrderApi'
import { useGetOrderLineByIdQuery } from '../../core/api/OrderLineApi'
import { deleteDish } from '../../core/store/cook/cookSlice'
import { IInfoDish } from '../../core/store/cook/cookState'
import { useAppDispatch } from '../../core/store/store'
import AppBtn, { AppBtnColor, AppBtnSize } from '../btn/AppBtn'
import { AppNotification, NotificationType } from '../notification/Notification'
import Popup from '../popup/Popup'
import Spinner, { SpinnerColor, SpinnerSize } from '../spinner/Spinner'
import TableProducts from './TableProducts'

interface ICookingCardProps {
  info: IInfoDish
}

const CookingCard = ({ info }: ICookingCardProps) => {
  const [isZoomPhoto, setIsZoomPhoto] = useState(false)
  const { data: orderLine } = useGetOrderLineByIdQuery(`${info.orderLineId}`, {
    pollingInterval: 5000,
  })
  const { data: dish } = useGetDishByIdQuery(String(orderLine?.dish.id), {
    skip: !orderLine,
    refetchOnMountOrArgChange: true,
  })
  const { data: dishVersion } = useGetDishVersionByIdQuery(
    String(orderLine?.dish.id),
    {
      skip: !orderLine,
      refetchOnMountOrArgChange: true,
    },
  )

  const { data: order } = useGetOrderByIdQuery(String(info.parentId))
  const dispatch = useAppDispatch()
  const closeCard = () => dispatch(deleteDish(info))
  const [isDisabled, setIsDisabled] = useState(false)
  const [changeCookStatus] = useChangeCookStatusMutation()

  const sendDimensionsToDb = useCallback(
    (status: keyof typeof CookingStatus) => {
      if (!isDisabled) {
        setIsDisabled(true)
        changeCookStatus({
          order: info.parentId,
          orderLine: info.orderLineId,
          cookingStatus: status,
        })
          .unwrap()
          .then(() => {
            AppNotification({
              msg: 'Статус изменен!',
              type: NotificationType.success,
            })
          })
          .catch(() => {})
          .finally(() => {
            setIsDisabled(false)
          })
      }
    },
    [changeCookStatus, info.parentId, info.orderLineId, isDisabled],
  )

  const renderButtons = useCallback(() => {
    const { orderLineDimensions, orderLineCookingStatuses } = orderLine!
    if (
      orderLineCookingStatuses?.cookingStatus === CookingStatus.Cook_done ||
      (orderLineDimensions.length &&
        (!orderLineCookingStatuses ||
          orderLineCookingStatuses.cookingStatus === CookingStatus.Waiting))
    ) {
      return
    } else if (
      !orderLineCookingStatuses ||
      orderLineCookingStatuses.cookingStatus === CookingStatus.Waiting
    ) {
      return (
        <AppBtn
          sized={AppBtnSize.sm}
          color={AppBtnColor.outlineGreen}
          onClick={() => sendDimensionsToDb(CookingStatus.Cooking)}
        >
          {isDisabled ? (
            <Spinner color={SpinnerColor.green} size={SpinnerSize.extraSm} />
          ) : (
            <span>Начать</span>
          )}
        </AppBtn>
      )
    } else {
      return (
        <>
          <AppBtn
            sized={AppBtnSize.sm}
            color={
              isDisabled || orderLineDimensions.length
                ? AppBtnColor.lightRedWhite
                : AppBtnColor.redWhite
            }
            onClick={() =>
              !orderLineDimensions.length &&
              sendDimensionsToDb(CookingStatus.Waiting)
            }
            title="Отмена"
          />
          <AppBtn
            sized={AppBtnSize.sm}
            color={
              isDisabled ? AppBtnColor.lightGreenWhite : AppBtnColor.greenWhite
            }
            onClick={() => sendDimensionsToDb(CookingStatus.Cook_done)}
          >
            {isDisabled ? (
              <Spinner size={SpinnerSize.extraSm} />
            ) : (
              <span>Сдать</span>
            )}
          </AppBtn>
        </>
      )
    }
  }, [orderLine, isDisabled, sendDimensionsToDb])

  return (
    <>
      {dish ? (
        <div className="dish-info main-view">
          <div className="col-info">
            <div
              className={`wrapper-photo ${
                !dish.ingredients.length ? 'radius-left' : ''
              }`}
            >
              {dish.imagePreview ? (
                <>
                  <div
                    className="wrapper-icon"
                    onClick={() => setIsZoomPhoto(true)}
                  >
                    <i className="an-ico an-ico-search"></i>
                  </div>
                  <img src={dish.imagePreview} alt="" className="photo" />
                </>
              ) : (
                <>
                  <i className="an-ico an-ico-photo" />
                  <h5>Фото отсутствует</h5>
                </>
              )}
            </div>
            <TableProducts
              ingredients={dish.ingredients.length ? dish.ingredients : []}
              differences={
                dishVersion?.ingredients.length
                  ? JSON.stringify(dishVersion.ingredients)
                  : ''
              }
            />
          </div>
          <div className="col-info desc">
            <div className="wrapper-info">
              <div className="wrapper-name">
                <h4 className="name">{dish.name}</h4>
                <div className="wrapper-icon m-l-auto" onClick={closeCard}>
                  <i className="an-ico an-ico-close"></i>
                </div>
              </div>
              <h5>Заказ №{order?.orderRepresent}</h5>
              {dishVersion?.description && (
                <div className="requirements">
                  <h5 className="color-Gray">Требования к блюду:</h5>
                  <div
                    className="requirement"
                    dangerouslySetInnerHTML={{
                      __html: dishVersion?.description,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="btn-block">{renderButtons()}</div>
          </div>
          {isZoomPhoto && (
            <Popup closePopup={() => setIsZoomPhoto(false)}>
              <img src={dish.image!} alt="" className="img-child-popup" />
            </Popup>
          )}
        </div>
      ) : null}
    </>
  )
}

export default CookingCard
