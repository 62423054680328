import React, { useRef } from 'react'
import { useAppSelector } from '../../core/store/store'
import CookingCard from '../cookingCard/CookingCard'
import HeaderColumn from '../headerColumn/HeaderColumn'

const RecipeCardsColumn = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { dishes } = useAppSelector((state) => state.cook)
  return (
    <div className="col-xl-6 col-lg-6 col-scroll">
      <HeaderColumn title="Технологические карты" refContent={ref} />
      <div className="column mt-10" ref={ref}>
        {dishes.length
          ? dishes.map((el, idx) => (
              <CookingCard
                info={el}
                key={`cooking-card-${el.orderLineId}-${idx}`}
              />
            ))
          : null}
      </div>
    </div>
  )
}

export default RecipeCardsColumn
