import { api } from './BaseApi'
import { ITag, ITagsRequestDto, ITagsResponseDto } from './dto/TagsDto'

export const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTagsList: builder.query<ITagsResponseDto, ITagsRequestDto>({
      query: (params) => ({
        url: '/tags/',
        params: !!params ? params : {},
      }),
      providesTags: ['Tags'],
    }),
    getTagDetail: builder.query<ITag, number>({
      query: (id) => ({
        url: `/tags/${id}/`,
      }),
      providesTags: ['Tags'],
    }),
    createTag: builder.mutation<ITag, Partial<ITag>>({
      query: (body) => ({
        url: '/tags/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tags'],
    }),
    updateTag: builder.mutation<ITag, Partial<ITag>>({
      query: (body) => ({
        url: `/tags/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tags'],
    }),
    archiveMultipleTag: builder.mutation<ITag, { ids: number[] }>({
      query: ({ ids }) => ({
        url: `/tags/delete_multiple_tags/`,
        method: 'POST',
        body: { ids },
      }),
      invalidatesTags: ['Tags'],
    }),
    deleteTags: builder.mutation<void, number>({
      query: (id) => ({
        url: `/tags/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tags'],
    }),
  }),
})

export const {
  useGetTagsListQuery,
  useGetTagDetailQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useArchiveMultipleTagMutation,
} = tagsApi
