import React, { useState } from 'react'
import './EditTaskCommentsBlock.css'
import { EditTaskCommentList } from './EditTaskCommentList/EditTaskCommentList'
import { EditTaskEditComment } from './EditTaskEditComment/EditTaskEditComment'
import { ITasksCommentDto } from '../../../../../core/api/dto/TaskDto'

interface EditTaskCommentsBlockProps {
  id?: number
  isCommentInputVisible?: boolean
  isDraft?: boolean
}

export const EditTaskCommentsBlock: React.FC<EditTaskCommentsBlockProps> = ({
  id,
  isCommentInputVisible = true,
  isDraft,
}) => {
  const [newComment, setNewComment] = useState<null | ITasksCommentDto>(null)

  return (
    <>
      <EditTaskCommentList
        id={id}
        isFullHeight={!isCommentInputVisible}
        newComment={newComment}
        setNewComment={setNewComment}
        isDraft={isDraft}
      />
      {isCommentInputVisible && id && (
        <EditTaskEditComment
          taskId={Number(id)}
          setNewComment={setNewComment}
        />
      )}
    </>
  )
}
