import React from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { useGetCategoryByIdQuery } from '../../../core/api/CategoryApi'
import { useParams } from 'react-router'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import {
  useGetDishesFromOrdersByCategoriesQuery,
  useGetDishesWithImplInfoQuery,
} from '../../../core/api/BuffetDishApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import { IBuffetOrdersRequestDto } from '../../../core/api/dto/BuffetOrderDto'
import { DishItem } from './components/DishItem'
import { useSearchParams } from 'react-router-dom'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppScrollWrapperNew } from '../../../widgets/AppScrollWrapper/AppScrollWrapper'
import { PredictionData } from './components/PredictionData'

export const PlanMenuIssuingDetailPage = () => {
  const { categoryId } = useParams()
  const [searchParams] = useSearchParams()
  const orderIds = searchParams.get('ids')
  const pageParams = useParamsControl<
    IBuffetOrdersRequestDto,
    keyof IBuffetOrdersRequestDto
  >({
    withPagination: true,
    whiteList: ['search'],
  })
  const currentCategoryId = Number(categoryId) || 0
  const { data: currentCategory } = useGetCategoryByIdQuery(currentCategoryId, {
    skip: !currentCategoryId,
  })
  const { data: dishesListFromCategory } =
    useGetDishesFromOrdersByCategoriesQuery({
      order_ids: orderIds || '',
      category: currentCategoryId || undefined,
    })
  const { data: dishesList } = useGetDishesWithImplInfoQuery(
    { search: pageParams?.search, order_ids: orderIds || '' },
    { skip: !pageParams?.search || !orderIds },
  )

  return (
    <div className="width-for-page-wrapper">
      <AppTitlePage
        title={!!currentCategory ? currentCategory.name : 'Все категории'}
        goBack
        goBackPath={`/${AllRoutes.planMenuIssuing.path}/?ids=${orderIds}`}
      />
      <div className={'mt-20'}>
        <AppFilterInput
          searchParam={'search'}
          fullWidth
          ico="search"
          placeholder="Например: манная каша"
        />
      </div>
      <PredictionData isIds={!!orderIds} />
      <div className="plan-menu-control-manager-page auto-height">
        <AppScrollWrapperNew>
          {!!dishesListFromCategory?.results.length &&
            !pageParams?.search &&
            dishesListFromCategory.results.map((dishItem, index) => (
              <DishItem dish={dishItem} key={`${dishItem.id}d-i-m-${index}`} />
            ))}
          {!!dishesList?.results.length &&
            pageParams?.search &&
            dishesList.results.map((dishItem, index) => (
              <DishItem dish={dishItem} key={`${dishItem.id}d-in-m-${index}`} />
            ))}
        </AppScrollWrapperNew>
      </div>
    </div>
  )
}
