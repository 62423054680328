import React, { useMemo, useState } from 'react'
import HeaderWaiterMobile from '../widgets/header/HeaderWaiterMobile'
import OrderCardWaiterMobileMemoized from '../components/orderCard/OrderCardWaiterMobile'
import { useGetAllOrdersByWaiterQuery } from '../core/api/OrderApi'
import { useGetCurrentUserQuery } from '../core/api/UserApi'
import { getOrders } from './Waiter'

const WaiterMobilePage = () => {
  const { data: currentUser } = useGetCurrentUserQuery()
  const [stateNavPanel, setStateNavPanel] = useState({
    isShowReadyDishes: false,
    isUnwrapOrders: false,
  })
  const { data: allOrders } = useGetAllOrdersByWaiterQuery(
    {
      email: currentUser?.email ? currentUser.email : '',
      shiftNum: currentUser?.userRoles.restaurant.shiftNum
        ? currentUser?.userRoles.restaurant.shiftNum
        : '',
    },
    {
      pollingInterval: 5000,
      skip: !currentUser,
    },
  )
  // const { token } = useAppSelector((state) => state.fcmToken)
  // const dispatch = useAppDispatch()
  // const [sendFcmToken] = useSendFCMTokenMutation()
  // const [notification, setNotification] = useState({ title: '', body: '' })
  //
  // useEffect(() => {
  //   if (notification.body && notification.title) {
  //     AppNotification({
  //       msg: notification.body,
  //       title: notification.title,
  //       type: NotificationType.info,
  //     })
  //   }
  // }, [notification.body, notification.title])

  // const onMessageListenerHandler = async () => {
  //   const message = await onMessageListener()
  //   if (message?.notification?.title && message?.notification?.body) {
  //     setNotification({
  //       title: message?.notification.title,
  //       body: message?.notification.body,
  //     })
  //   }
  // }
  //
  // onMessageListenerHandler()

  // const setToken = useCallback(
  //   (arg: string) => {
  //     sendFcmToken({ token: arg })
  //     dispatch(setFCMToken(arg))
  //   },
  //   [dispatch, sendFcmToken],
  // )

  // useEffect(() => {
  //   if (!token) {
  //     fetchToken(setToken)
  //   }
  // }, [token, setToken])

  const orders = useMemo(() => {
    if (allOrders?.results) {
      return getOrders(allOrders.results, stateNavPanel.isShowReadyDishes)
    }

    return []
  }, [allOrders?.results, stateNavPanel.isShowReadyDishes])
  return (
    <div>
      <HeaderWaiterMobile
        stateNavPanel={stateNavPanel}
        setStateNavPanel={setStateNavPanel}
      />
      <div className="waiter-mobile">
        {allOrders?.results?.length ? (
          <div className="container-fluid container-sm">
            <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-xs-1">
              {orders.map((el) => (
                <div className="col" key={el.id}>
                  <OrderCardWaiterMobileMemoized
                    info={el}
                    isUnwrapOrders={stateNavPanel.isUnwrapOrders}
                    isShowReadyDishes={stateNavPanel.isShowReadyDishes}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default WaiterMobilePage
