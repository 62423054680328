
import './GeneralReportImagePopup.scss'

import React, { useEffect, useMemo } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Popup from 'components/popup/Popup'
import { ReportImageUpscale } from 'pages/ReportImagePopup/ReportImageUpscale/ReportImageUpscale'
import { IBuffetGeneralReportListDto } from 'core/api/dto/BuffetGeneralReportDto'
import { getReportImagePopupData, IReportImagePopupDto } from './GeneralReportImagePopupDto'

interface IReportImagePopupProps {
  data: IBuffetGeneralReportListDto
}

export const GeneralReportImagePopup: React.FC<IReportImagePopupProps> = (props) => {
  const { data } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const id = useMemo<string | null>(() => {
    const param = searchParams.get('report-image-id')
    if (!param) return null
    return param
  }, [searchParams])

  const reportImagePopupData = useMemo(
    () => getReportImagePopupData(data),
    [data],
  )

  const itemIndex = useMemo(
    () => reportImagePopupData.findIndex((i) => i.id === id),
    [id, reportImagePopupData],
  )

  const item = useMemo<IReportImagePopupDto | undefined>(
    () => reportImagePopupData[itemIndex],
    [itemIndex, reportImagePopupData],
  )
  const prevItem = useMemo(() => {
    if (itemIndex <= 0) return

    return reportImagePopupData[itemIndex - 1]
  }, [itemIndex, reportImagePopupData])

  const nextItem = useMemo(() => {
    if (itemIndex >= reportImagePopupData.length) return

    return reportImagePopupData[itemIndex + 1]
  }, [itemIndex, reportImagePopupData])

  const onButtonPreviousClick = () => {
    if (!prevItem) return
    searchParams.set('report-image-id', prevItem.id.toString())
    setSearchParams(searchParams.toString())
  }

  const onButtonNextClick = () => {
    if (!nextItem) return
    searchParams.set('report-image-id', nextItem.id.toString())
    setSearchParams(searchParams.toString())
  }

  const closeImagePopupHandler = () => {
    setSearchParams((sp) => {
      sp.delete('report-image-id')
      return sp
    })
  }

  useEffect(() => {
    if (
      data.results.length < data.count &&
      itemIndex + 2 >= reportImagePopupData.length
    ) {
      setSearchParams((sp) => {
        const spLimit = sp.get('limit') || 0
        sp.set('limit', `${+spLimit + 1}`)
        return sp.toString()
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndex, data.count, reportImagePopupData.length, data.results.length])

  if (!item) return null
  return (
    <Popup closePopup={closeImagePopupHandler}>
      <div className="report-image-popup__container">
        <div className="report-image-popup__header">
          <span>{item.name}</span>

          <button
            className="report-image-popup__image-wrapper"
            onClick={closeImagePopupHandler}
          >
            <i
              className={`an-ico an-ico-close`}
              style={{ fontSize: '12px', color: 'white' }}
            />
          </button>
        </div>

        <div className="report-image-popup__body">
          <div className="report-image-popup__photos">
            <ReportImageUpscale photo={item?.categoryImage} standardTempMax={item.standardTempMax} standardTempMin={item.standardTempMin}/>
            <ReportImageUpscale
              photo={item?.dimensionImage}
              isDimension
              isFact
              isCategory={item.isCategory}
              temperature={item.temperature}
              dimensionId={Number(item?.id.replace('-dimension', ''))}
            />
          </div>
        </div>

        <div className="report-image-popup__footer">
          <button
            className="report-image-popup__button report-image-popup__button"
            onClick={onButtonPreviousClick}
            disabled={!prevItem}
            title={!prevItem ? 'Нет предыдущего элемента' : undefined}
          >
            Предыдущая
          </button>

          {!item.isCategory && (
            <Link
              className="report-image-popup__button report-image-popup__button__redirect btn"
              to={`/plan-menu-control?order=${item.orderParam}&dish=${item.dishParam}&dimension=${item.id.split('-')[0]}&isRedirected=1`}
            >
              {window.innerWidth < 600 ? (
                <i className="an-ico an-ico-search" />
              ) : (
                'Перейти'
              )}
            </Link>
          )}

          <button
            className="report-image-popup__button report-image-popup__button"
            onClick={onButtonNextClick}
            disabled={!nextItem}
            title={!nextItem ? 'Нет следующего элемента' : undefined}
          >
            Следующая
          </button>
        </div>
      </div>
    </Popup>
  )
}
