import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import { AllRoutes } from '../../core/routes/AllRoutes'
import { IStateNavPanel } from '../../pages/Waiter'
import CurrentDate from '../../components/currentDate/CurrentDate'
import { IPropsNavBarBase } from './HeaderWaiterMobile'
import NavWaiterPage from './NavWaiterPage'

export interface IPropsNavBar extends IPropsNavBarBase<IStateNavPanel> {
  contentRef: React.MutableRefObject<HTMLDivElement | null>
}
const HeaderWaiterPage = ({
  contentRef,
  setStateNavPanel,
  stateNavPanel,
}: IPropsNavBar) => {
  const { data: currentUser } = useGetCurrentUserQuery()

  return (
    <header className="header">
      <div className="container-fluid">
        <div className="wrapper-heading">
          <Link to={`${AllRoutes.user.path}`} className="link">
            <i className="an-ico an-ico-user" />
            {currentUser && <span>{currentUser.name}</span>}
          </Link>
          {currentUser?.userRoles.restaurant.name && (
            <h4 className="color-White">
              {currentUser?.userRoles.restaurant.name}
            </h4>
          )}
          <h4 className="color-White">
            <CurrentDate />
          </h4>
        </div>
        <NavWaiterPage
          contentRef={contentRef}
          stateNavPanel={stateNavPanel}
          setStateNavPanel={setStateNavPanel}
        />
      </div>
    </header>
  )
}

function compareProps(prevProps: IPropsNavBar, nextProps: IPropsNavBar) {
  return (
    JSON.stringify(prevProps.stateNavPanel) ===
      JSON.stringify(nextProps.stateNavPanel) &&
    JSON.stringify(prevProps.setStateNavPanel) ===
      JSON.stringify(nextProps.setStateNavPanel) &&
    prevProps.contentRef === nextProps.contentRef
  )
}
export const HeaderWaiterPageMemoized = memo(HeaderWaiterPage, compareProps)

export default HeaderWaiterPageMemoized
