import moment from 'moment'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { IOrder } from '../../core/api/dto/OrderObjDto'
import { compareProps, filterMealItems } from '../../utils'
import MealItemWaiterMobileMemoized from './MealItem/MealItemWaiterMobile'
import StatusInfo from './StatusInfo'

/* в последующем инфа будет приходить с бэка и константа будет удалена, здесь макимальное время в минутах*/
const MAX_TIME_IN_MIN = 15

export interface IOrderMobileCardProps {
  info: IOrder
  isUnwrapOrders: boolean
  isShowReadyDishes: boolean
}

const OrderCardWaiterMobile = ({
  info,
  isUnwrapOrders,
  isShowReadyDishes,
}: IOrderMobileCardProps) => {
  const [isUnwrapCard, setIsUnwrapCard] = useState(false)
  const [orderTimeAtWork, setOrderTimeAtWork] = useState('')

  const getTimeAtWork = () => {
    if (info.orderDateStart) {
      const duration = moment().diff(moment(info.orderDateStart))
      setOrderTimeAtWork(moment.utc(duration).format('HH:mm'))
    }
  }

  useEffect(() => {
    if (!orderTimeAtWork) {
      getTimeAtWork()
    } else if (orderTimeAtWork && !info.orderDateStart) {
      setOrderTimeAtWork('')
    } else {
      const interval = setInterval(() => {
        getTimeAtWork()
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [info.orderDateStart, getTimeAtWork, orderTimeAtWork])

  useEffect(() => {
    isUnwrapOrders ? setIsUnwrapCard(true) : setIsUnwrapCard(false)
  }, [isUnwrapOrders])

  /* в последующем эта инфа будет приходить с бэка*/
  const isOverdue = useMemo(
    () =>
      orderTimeAtWork &&
      +moment.duration(orderTimeAtWork).asMinutes() > MAX_TIME_IN_MIN,
    [orderTimeAtWork],
  )

  return (
    <div
      className={`card main-view  ${isUnwrapCard ? 'active' : ''}  ${
        isOverdue && !isUnwrapCard ? 'overdue' : ''
      }`}
    >
      <div
        className="wrapper-card"
        onClick={() => setIsUnwrapCard(!isUnwrapCard)}
      >
        <div className={`number ${info.orderRepresent.length > 4 ? 'sm' : ''}`}>
          {info.orderRepresent.slice(0, 20)}
        </div>
        <div className="wrapper-info m-l-auto">
          <StatusInfo title="Создан" time={info.orderDate} />
          <StatusInfo
            title="В работе"
            time={orderTimeAtWork ? orderTimeAtWork : '00:00'}
          />
        </div>
      </div>
      {isUnwrapCard && (
        <>
          {info.orderLines.length ? (
            <div className="meal-list">
              {(isShowReadyDishes
                ? info.orderLines
                : filterMealItems(info)
              ).map((el, idx) => (
                <MealItemWaiterMobileMemoized
                  info={el}
                  key={`meal-item-${el.id}-${idx}`}
                />
              ))}
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

const OrderCardWaiterMobileMemoized = memo(OrderCardWaiterMobile, compareProps)

export default OrderCardWaiterMobileMemoized
