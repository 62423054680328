import React from 'react'

interface IListOptions<T, TKey extends keyof T> {
  options: T[]
  name?: TKey
  id?: TKey
  icon?: TKey
  onChange: (val: T) => void
  isSearch?: boolean
}

const ListOptions = <T, TKey extends keyof T>({
  options,
  name,
  id,
  icon,
  onChange,
  isSearch = false,
}: IListOptions<T, TKey>) => {
  return (
    <div className="wrapper-list">
      <div className={`list-options  ${!options.length ? 'not-found' : ''}`}>
        {!options.length && isSearch && <div>Совпадений не найдено</div>}
        {options.map((el) => (
          <div
            className="option"
            key={`option-${id ? el[id] : el}`}
            onClick={() => onChange(el)}
          >
            {icon && (
              <img
                src={`${
                  el[icon] ? el[icon] : '/assets/images/category-icon.png'
                }`}
                alt=""
                className="image"
              />
            )}
            <span>{name ? el[name] : el}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ListOptions
