import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import AppBtn, { AppBtnColor } from '../btn/AppBtn'
import StatusInfo from './StatusInfo'
import useScroll from '../../utils/useScroll'
import moment from 'moment'
import { compareProps, filterMealItems } from '../../utils'
import { IActiveDish } from '../../pages/Waiter'
import MealItemWaiterMemoized from './MealItem/MealItemWaiter'
import { IOrderMobileCardProps } from './OrderCardWaiterMobile'

export interface IOrderCardProps extends IOrderMobileCardProps {
  handlerSetActiveDish: (arg: IActiveDish) => void
}

/* в последующем инфа будет приходить с бэка и константа будет удалена, здесь макимальное время в минутах*/
const MAX_TIME_IN_MIN = 15

const OrderCardWaiter = ({
  info,
  isUnwrapOrders,
  isShowReadyDishes,
  handlerSetActiveDish,
}: IOrderCardProps) => {
  const [isUnwrapCard, setIsUnwrapCard] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const [orderTimeAtWork, setOrderTimeAtWork] = useState('')
  const scrollList = useScroll(ref)

  const getTimeAtWork = () => {
    if (info.orderDateStart) {
      const duration = moment().diff(moment(info.orderDateStart))
      setOrderTimeAtWork(moment.utc(duration).format('HH:mm'))
    }
  }

  useEffect(() => {
    if (!orderTimeAtWork) {
      getTimeAtWork()
    } else if (orderTimeAtWork && !info.orderDateStart) {
      setOrderTimeAtWork('')
    } else {
      const interval = setInterval(() => {
        getTimeAtWork()
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [info.orderDateStart, getTimeAtWork, orderTimeAtWork])

  useEffect(() => {
    isUnwrapOrders ? setIsUnwrapCard(true) : setIsUnwrapCard(false)
  }, [isUnwrapOrders])

  /* в последующем эта инфа будет приходить с бэка*/
  const isOverdue = useMemo(
    () =>
      orderTimeAtWork &&
      +moment.duration(orderTimeAtWork).asMinutes() > MAX_TIME_IN_MIN,
    [orderTimeAtWork],
  )

  return (
    <div
      className={`card main-view  ${isUnwrapCard ? 'active' : ''}  ${
        isOverdue && !isUnwrapCard ? 'overdue' : ''
      }`}
    >
      <div
        className="wrapper-card"
        onClick={() => setIsUnwrapCard(!isUnwrapCard)}
      >
        <div className={`number ${info.orderRepresent.length > 4 ? 'sm' : ''}`}>
          {info.orderRepresent.slice(0, 20)}
        </div>
        <div className="wrapper-info m-l-auto">
          <StatusInfo title="Создан" time={info.orderDate} />
          <StatusInfo
            title="В работе"
            time={orderTimeAtWork ? orderTimeAtWork : '00:00'}
          />
        </div>
      </div>
      {isUnwrapCard && (
        <>
          <div className="details">
            <div className="title-count">Выдано</div>
            <div className="btn-block">
              <AppBtn
                color={AppBtnColor.whiteViolet}
                iconClassName="arrow-down"
                onClick={() => scrollList('down')}
              />
              <AppBtn
                color={AppBtnColor.whiteViolet}
                iconClassName="arrow-up"
                onClick={() => scrollList('up')}
              />
            </div>
          </div>
          {info.orderLines.length ? (
            <div className="meal-list" ref={ref}>
              {(isShowReadyDishes
                ? info.orderLines
                : filterMealItems(info)
              ).map((el, idx) => (
                <MealItemWaiterMemoized
                  parentId={info.id}
                  info={el}
                  key={`meal-item-${el.id}-${idx}`}
                  handlerSetActiveDish={handlerSetActiveDish}
                  orderRepresent={info.orderRepresent}
                />
              ))}
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

const OrderCardWaiterMemoized = memo(OrderCardWaiter, compareProps)

export default OrderCardWaiterMemoized
