import { api } from './BaseApi'
import { IBuffetGeneralReport, IBuffetGeneralReportChartData, IBuffetGeneralReportChartRequest, IBuffetGeneralReportRequest } from './dto/BuffetGeneralReportDto'
import {
  IBuffetReportListDto,
  IBuffetReportRequest,
} from './dto/BuffetReportDto'

export const buffetReportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuffetReport: builder.query<IBuffetReportListDto, IBuffetReportRequest>({
      query: (params) => ({
        url: '/report_buffet/',
        params: !!params ? params : {},
      }),
      providesTags: ['BuffetReport'],
    }),
    getBuffetGeneralReport: builder.query<IBuffetGeneralReport, IBuffetGeneralReportRequest>({
      query: (params) => ({
        url: '/report_buffet/general_report/',
        params: !!params ? params : {},
      }),
      providesTags: ['BuffetReport'],
    }),
    getBuffetGeneralReportChart: builder.query<IBuffetGeneralReportChartData, IBuffetGeneralReportChartRequest>({
      query: (params) => ({
        url: '/report_buffet/general_report_chart_data/',
        params: !!params ? params : {},
      }),
      providesTags: ['BuffetReport'],
    }),
  }),
})

export const { useGetBuffetReportQuery, useGetBuffetGeneralReportQuery, useGetBuffetGeneralReportChartQuery } = buffetReportApi
