import { api } from './BaseApi'
import { IPlanMenuDto } from './dto/PlanMenuDto'
import {
  IGuestsTypeRequest,
  IGuestsTypeResponse,
  IGuestTypeBase,
  IGuestTypeDto,
} from './dto/GuestTypeDto'

export const guestTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGuestListTypeList: builder.query<
      IGuestsTypeResponse,
      IGuestsTypeRequest
    >({
      query: (params) => ({
        url: '/plan_menu_guest_type/',
        params: !!params ? params : {},
      }),
      providesTags: ['GuestType'],
    }),
    getGuestTypeDetail: builder.query<IGuestTypeDto, { id: number }>({
      query: ({ id }) => ({
        url: `/plan_menu_guest_type/${id}/`,
      }),
      providesTags: ['GuestType'],
    }),
    createGuestType: builder.mutation<IGuestTypeDto, IGuestTypeBase>({
      query: (body) => ({
        method: 'POST',
        url: '/plan_menu_guest_type/',
        body,
      }),
      invalidatesTags: ['GuestType'],
    }),
    updateGuestType: builder.mutation<
      IPlanMenuDto,
      { id: number; data: IGuestTypeBase }
    >({
      query: ({ id, data }) => ({
        method: 'PATCH',
        url: `/plan_menu_guest_type/${id}/`,
        body: data,
      }),
      invalidatesTags: ['GuestType'],
    }),
    archiveMultipleGuestType: builder.mutation<
      IPlanMenuDto,
      { data: number[] }
    >({
      query: ({ data }) => ({
        method: 'PATCH',
        url: `/plan_menu_guest_type/archive_multiple_guest_types/`,
        body: { ids: data },
      }),
      invalidatesTags: ['GuestType'],
    }),
  }),
})

export const {
  useGetGuestListTypeListQuery,
  useGetGuestTypeDetailQuery,
  useCreateGuestTypeMutation,
  useUpdateGuestTypeMutation,
  useArchiveMultipleGuestTypeMutation,
} = guestTypeApi
