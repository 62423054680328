import React from 'react'
import { IHeaderData } from '../AppTable'

interface TableColgroupBlockProps {
  chooseMode?: boolean
  importantMode?: boolean
  subTableRepresent?: boolean
  headerData: IHeaderData[]
}

export const TableColgroupBlock = ({
  subTableRepresent,
  headerData,
  chooseMode,
  importantMode,
}: TableColgroupBlockProps) => {
  return (
    <colgroup>
      {subTableRepresent && <col width="5%" style={{ width: '5%' }} />}
      {chooseMode && <col width="5%" style={{ width: '5%' }} />}
      {importantMode && <col width="5%" style={{ width: '5%' }} />}
      {headerData.map((th, index) => {
        return <col width={th.colWidth} key={`table-colgroup-${index}`} />
      })}
    </colgroup>
  )
}
