import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { IDish } from '../../core/api/dto/DishObjDto'
import { AllRoutes } from '../../core/routes/AllRoutes'
import { compareProps } from '../../utils'
import AppBtn, { AppBtnColor, AppBtnSize, IconPosition } from '../btn/AppBtn'

interface IPropsMealCard {
  info: IDish
}

const MealCard = ({
  info: { name, description, imagePreview, id },
}: IPropsMealCard) => {
  return (
    <div className="meal-card">
      <div className="wrapper-photo">
        {imagePreview && <img src={imagePreview} alt="" className="img" />}
        <Link
          to={`${id}/${AllRoutes.technologicalMapCardItemEdit.path}`}
          className="wrapper-icon"
        >
          <i className="an-ico an-ico-pencil" />
        </Link>
      </div>
      <div className="wrapper-info">
        <div className="name clip">{name}</div>
        <div className="description">{description}</div>
        <AppBtn
          title="Посмотреть"
          color={AppBtnColor.outlineGrayLight}
          iconClassName="arrow-long"
          iconPosition={IconPosition.right}
          sized={AppBtnSize.sm}
          path={`${id}`}
        />
      </div>
    </div>
  )
}

const MealCardMemoized = memo(MealCard, compareProps)
export default MealCardMemoized
