import React, { useCallback, useEffect, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppInput, InputMaskType } from '../../../components/AppInput/AppInput'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
  IconPosition,
} from '../../../components/btn/AppBtn'
import { DragAndDropUploadPhoto } from '../components/DragAndDropUploadPhoto'
import { AppFormHook } from '../../../components/AppFormController'
import AppDropdownWithPagination from '../../../components/AppDropdown/AppDropdownWithPagination'
import { useGetCategoryQuery } from '../../../core/api/CategoryApi'
import { ICategory } from '../../../core/api/dto/CategoriesObjDto'
import {
  useCreateBuffetDishMutation,
  useGetBuffetDishDetailQuery,
  useLazyGetImageDishFromCameraQuery,
  useUpdateBuffetDishMutation,
} from '../../../core/api/BuffetDishApi'
import { ICreateBuffetDishDto } from '../../../core/api/dto/BuffetDishDto'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useNavigate, useParams } from 'react-router'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { setCurrentData } from '../../../utils/setCurrentData'
import { convertBase64 } from '../../../components/input/InputFile'
import { AppMultiDropdownWithPagination } from '../../../components/AppDropdown/AppMultiDropdownWithPagination'
import { useGetBuffetMilletQuery } from '../../../core/api/BuffetMilletApi'
import { IBuffetMilletDto } from '../../../core/api/dto/BuffetMilletDto'
import { handleBase64Image } from '../../../utils/base64ToBlob'
import { ITag } from '../../../core/api/dto/TagsDto'
import { useGetTagsListQuery } from '../../../core/api/TagsApi'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'

const formData = [
  {
    fieldName: 'name',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'category',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'standardWeight',
  },
  {
    fieldName: 'standardTempMin',
  },
  {
    fieldName: 'standardTempMax',
  },
  {
    fieldName: 'standardDeviation',
  },
  {
    fieldName: 'implementationPeriod',
  },
]

export const setFullTime = (time: string) => {
  const hour = time.split(':')[0].padStart(2, '0')
  const min = time.split(':')[1]?.padStart(2, '0')
  return !!time ? `${hour}:${min}` : ''
}

export function getTimeFromMins(mins: number) {
  if (mins === 0) {
    return '00:00'
  }
  if (!mins) {
    return ''
  }
  const hours = String(Math.trunc(mins / 60)).padStart(2, '0')
  const minutes = String(mins % 60).padStart(2, '0')
  return `${hours}:${minutes}`
}

const getMin = (time: string) => {
  const hour = Number(time.split(':')[0])
  const min = Number(time.split(':')[1])
  return !!hour ? hour * 60 + min : min
}

export const CreateDishPage = () => {
  const navigate = useNavigate()
  const { dishId } = useParams()
  const { t } = useTranslation()
  const {
    formState,
    formStateChangeHandler,
    formErrorState,
    formValidationHandler,
  } = AppFormHook({
    formConfig: formData,
  })
  const { data: currentDish } = useGetBuffetDishDetailQuery(
    { id: Number(dishId!) },
    { skip: !dishId },
  )

  const [
    getImageFromCamera,
    { data: imageFromCamera, isLoading: isLoadingPhotoFromCamera },
  ] = useLazyGetImageDishFromCameraQuery()
  const [currentCategory, setCurrentCategory] = useState<null | ICategory>(null)
  const [milletValue, setMilletValue] = useState<IBuffetMilletDto[]>([])
  const [tagsValue, setTagsValue] = useState<ITag[]>([])
  const [file, setFile] = useState<null | File>(null)
  const [isDeletedFile, setIsDeletedFile] = useState<boolean>(false)
  const [filePreview, setFilePreview] = useState<null | string>(null)
  const [isDirty, setIsDirty] = useState(false)
  const { width } = useGetWidth()

  useEffect(() => {
    if (!!imageFromCamera) {
      const image = handleBase64Image(imageFromCamera.image, 'title')
      setFile(image)
    }
  }, [imageFromCamera])

  useEffect(() => {
    if (!!file) {
      setFilePreview(URL.createObjectURL(file))
    }
  }, [file])

  useEffect(() => {
    if (!!currentDish) {
      const currentData = setCurrentData({ data: currentDish, formState })
      formStateChangeHandler({
        ...currentData,
        implementationPeriod: setFullTime(
          currentDish.implementationPeriodRepresent || '',
        ),
        category: `${currentDish?.category?.id || ''}`,
      })
      setCurrentCategory(!!currentDish.category ? currentDish.category : null)
      setFilePreview(!!currentDish.image ? currentDish.image : null)
      setMilletValue(currentDish.millets)
      setTagsValue(currentDish.dishTags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDish])

  const [createBuffetDish, { isLoading: isLoadingCreateDish }] =
    useCreateBuffetDishMutation()
  const [updateBuffetDish, { isLoading: isLoadingUpdateDish }] =
    useUpdateBuffetDishMutation()

  const setPhotoFromCameraHandler = () => {
    getImageFromCamera()
  }

  const changeHandler = (propName: string, value: string) => {
    setIsDirty(true)
    formStateChangeHandler({ [propName]: value })
  }

  const changeCategoryHandler = (value: null | ICategory) => {
    setIsDirty(true)
    formStateChangeHandler({ category: `${value?.id}` })
    setCurrentCategory(value)
  }
  const changeMilletHandler = (value: IBuffetMilletDto[]) => {
    setMilletValue(value)
    setIsDirty(true)
  }
  const changeTagHandler = (value: ITag[]) => {
    setTagsValue(value)
    setIsDirty(true)
  }
  const onRemoveMilletItem = useCallback((id: number) => {
    setIsDirty(true)
    setMilletValue((prevState) => prevState.filter((item) => item.id !== id))
  }, [])
  const onRemoveTagItem = useCallback((id: number) => {
    setIsDirty(true)
    setTagsValue((prevState) => prevState.filter((item) => item.id !== id))
  }, [])

  const createDishes = async () => {
    const { isValid } = formValidationHandler()
    if (isValid) {
      const {
        name,
        category,
        implementationPeriod,
        standardWeight,
        standardDeviation,
        standardTempMin,
        standardTempMax,
      } = formState
      const image = !!file
        ? { image: await convertBase64(file) }
        : isDeletedFile
        ? { image: null }
        : {}
      const newDish = {
        name,
        category: Number(category),
        implementationPeriod: !!implementationPeriod
          ? getMin(implementationPeriod)
          : null,
        standardWeight: standardWeight ? Number(standardWeight) : null,
        standardDeviation: standardDeviation ? Number(standardDeviation) : null,
        standardTempMin: standardTempMin ? Number(standardTempMin) : null,
        standardTempMax: standardTempMax ? Number(standardTempMax) : null,
        ...image,
        millets: milletValue.map(({ id }) => id),
        dishTags: tagsValue.map(({ id }) => id),
      }
      if (!!dishId) {
        updateBuffetDish({
          id: Number(dishId),
          data: newDish as unknown as ICreateBuffetDishDto,
        })
          .unwrap()
          .then(() => {
            AppNotification({
              msg: t('Блюдо успешно изменено'),
              type: NotificationType.success,
            })
            setIsDirty(false)
            navigate(`/${AllRoutes.dishes.path}/`)
          })
      }
      if (!dishId) {
        createBuffetDish(newDish as unknown as ICreateBuffetDishDto)
          .unwrap()
          .then(() => {
            AppNotification({
              msg: t('Блюдо успешно добавлено'),
              type: NotificationType.success,
            })
            setIsDirty(false)
            navigate(`/${AllRoutes.dishes.path}/`)
          })
      }
    }
  }

  return (
    <React.Fragment>
      {/*<StationsPopup />*/}
      <div
        className={`width-for-page-wrapper ${
          dishId ? 'edit-dishes-page' : 'create-dishes-page'
        }`}
      >
        <AppTitlePage
          title={`${dishId ? 'Редактирование' : 'Добавление'} блюда`}
          goBack
          isDirty={isDirty}
        />
        <div className="dish-create-wrap">
          {currentDish && (
            <div className="head-wrap">
              <div className="uuid">
                <b>UUID </b>
                <span className={'color-Gray-medium'}>
                  {' '}
                  {currentDish.sourceIdent}
                </span>
              </div>
              {currentDish.source.name && (
                <div className="uuid">
                  <b>{t('Источник данных')}</b>
                  <span className={'color-Gray-medium'}>
                    {' '}
                    {currentDish.source.name}
                  </span>
                </div>
              )}
            </div>
          )}
          <div className="row">
            <div className="create-col">
              <AppInput
                value={formState.name}
                onChange={(value) => changeHandler('name', value)}
                error={formErrorState.name ? 'Обязательное поле' : undefined}
                label="Наименование"
                placeholder="Название"
                border
                required
                fullWidth
                clear
                id="name"
              />
              {width <= 1200 && (
                <AppDropdownWithPagination
                  label="Категория"
                  placeholder="Выбрать категорию"
                  value={currentCategory}
                  onChange={changeCategoryHandler}
                  getterData={useGetCategoryQuery}
                  propToShowInInput={'name'}
                  propToShowInList={'name'}
                  error={
                    formErrorState.category ? 'Обязательное поле' : undefined
                  }
                  border
                  fullWidth
                  required
                  id="category"
                />
              )}
              <AppInput
                value={formState.standardWeight}
                onChange={(value) => changeHandler('standardWeight', value)}
                label="Вес эталонный, кг"
                placeholder="0.000"
                maxLength={6}
                inputMask={InputMaskType.float}
                border
                fullWidth
                clear
                id="standardWeight"
              />
              {width <= 1200 && (
                <AppInput
                  value={formState.standardDeviation}
                  onChange={(value) =>
                    changeHandler('standardDeviation', value)
                  }
                  label="Отклонение, кг"
                  border
                  id="standardDeviation"
                  placeholder="±0.000"
                  fullWidth
                  inputMask={InputMaskType.float}
                  maxLength={6}
                  clear
                />
              )}
              <label className="w-100">{t('Температура эталонная, °C')}</label>
              <div className="input-row mt-10">
                <AppInput
                  value={formState.standardTempMin}
                  onChange={(value) => changeHandler('standardTempMin', value)}
                  border
                  fullWidth
                  placeholder="0"
                  maxLength={3}
                  inputMask={InputMaskType.negativeInteger}
                  clear
                  id="standardTempMin"
                />
                <AppInput
                  value={formState.standardTempMax}
                  onChange={(value) => changeHandler('standardTempMax', value)}
                  border
                  fullWidth
                  placeholder="0"
                  maxLength={3}
                  inputMask={InputMaskType.negativeInteger}
                  clear
                  id="standardTempMax"
                />
              </div>
              {width > 1200 && (
                <DragAndDropUploadPhoto
                  fileURL={filePreview}
                  onSetFile={setFile}
                  setFilePreview={setFilePreview}
                  setFileFromCamera={setPhotoFromCameraHandler}
                  isLoading={isLoadingPhotoFromCamera}
                  setIsDirty={setIsDirty}
                  setIsDeletedFile={setIsDeletedFile}
                />
              )}
            </div>
            <div className="create-col">
              {width > 1200 && (
                <AppDropdownWithPagination
                  label="Категория"
                  placeholder="Выбрать категорию"
                  value={currentCategory}
                  onChange={changeCategoryHandler}
                  getterData={useGetCategoryQuery}
                  propToShowInInput={'name'}
                  propToShowInList={'name'}
                  error={
                    formErrorState.category ? 'Обязательное поле' : undefined
                  }
                  border
                  fullWidth
                  required
                  id="category"
                />
              )}
              {width > 1200 && (
                <AppInput
                  value={formState.standardDeviation}
                  onChange={(value) =>
                    changeHandler('standardDeviation', value)
                  }
                  label="Отклонение, кг"
                  border
                  id="standardDeviation"
                  placeholder="±0.000"
                  fullWidth
                  inputMask={InputMaskType.float}
                  maxLength={6}
                  clear
                />
              )}
              <AppInput
                value={formState.implementationPeriod}
                onChange={(value) =>
                  changeHandler('implementationPeriod', value)
                }
                type="time"
                id="implementationPeriod"
                label="Допустимое время реализации, ч:мин"
                placeholder="00:00"
                border
                fullWidth
                clear
              />
              <AppMultiDropdownWithPagination<
                IBuffetMilletDto,
                keyof IBuffetMilletDto
              >
                label={'Выбор тары'}
                value={milletValue}
                getterData={useGetBuffetMilletQuery}
                propToShowInInput={'milletName'}
                propToShowInList={'milletName'}
                border
                fullWidth
                id="milletName"
                placeholder="Выбрать"
                onChange={changeMilletHandler}
              />
              {!!milletValue.length && (
                <React.Fragment>
                  <label>{t('Выбранные тары')}</label>
                  <div className="choose-items-block full-width">
                    {milletValue.map((item, index) => (
                      <div
                        className="choose-item"
                        key={item.id}
                        id={`millet-${index}`}
                      >
                        {item.milletName}
                        <i
                          className="an-ico an-ico-close"
                          onClick={() => onRemoveMilletItem(item.id)}
                        />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )}
              <div className={'mt-10'}>
                <AppMultiDropdownWithPagination<ITag, keyof ITag>
                  label={'Тэги'}
                  value={tagsValue}
                  getterData={useGetTagsListQuery}
                  propToShowInInput={'name'}
                  propToShowInList={'name'}
                  id="tags"
                  border
                  fullWidth
                  placeholder="Выбрать"
                  onChange={changeTagHandler}
                />
              </div>
              {!!tagsValue.length && (
                <div className="app-input-wrap">
                  <label>{t('Выбранные теги')}</label>
                  <div className="choose-items-block full-width">
                    {tagsValue.map((item, index) => (
                      <div
                        className="choose-item"
                        key={item.id}
                        id={`tag-${index}`}
                      >
                        {item.name}
                        <i
                          className="an-ico an-ico-close"
                          onClick={() => onRemoveTagItem(item.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {width <= 1200 && (
                <DragAndDropUploadPhoto
                  fileURL={filePreview}
                  onSetFile={setFile}
                  setFilePreview={setFilePreview}
                  setFileFromCamera={setPhotoFromCameraHandler}
                  isLoading={isLoadingPhotoFromCamera}
                  setIsDirty={setIsDirty}
                  setIsDeletedFile={setIsDeletedFile}
                />
              )}
            </div>
          </div>
          <div className="buttons-block mt-40 mw-btn-wrap">
            <AppBtn
              title="Отменить"
              color={AppBtnColor.outlineViolet}
              sized={AppBtnSize.md}
              iconClassName="close"
              iconPosition={IconPosition.left}
              onClick={() => navigate(-1)}
            />
            <AppBtn
              title="Сохранить"
              color={AppBtnColor.violetWhite}
              sized={AppBtnSize.md}
              iconClassName="save"
              iconPosition={IconPosition.left}
              onClick={createDishes}
              isLoading={isLoadingCreateDish || isLoadingUpdateDish}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
