import React, { useState } from 'react'
import { AppInput, IcoPosition } from '../../../components/AppInput/AppInput'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import Spinner from '../../../components/spinner/Spinner'
import { useLoginByUsernameMutation } from '../../../core/api/BaseApi'
import { setCredentials } from '../../../core/store/auth/authSlice'
import { useAppDispatch } from '../../../core/store/store'
import { useTranslation } from 'react-i18next'

export const LoginByUsername = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [loginByUsername, { isLoading: isLoadingLogin }] =
    useLoginByUsernameMutation()

  const onShowPasswordHandler = () =>
    setIsShowPassword((prevState) => !prevState)

  const authHandler = () => {
    if (login.trim() && password.trim()) {
      loginByUsername({ login, password })
        .unwrap()
        .then((response) => {
          dispatch(setCredentials({ token: response.token }))
        })
    }
  }

  return (
    <>
      <div className="login-by-username-wrap">
        <AppInput
          value={login}
          onChange={setLogin}
          placeholder="Логин"
          fullWidth
        />
        <AppInput
          value={password}
          onChange={setPassword}
          type={isShowPassword ? 'text' : 'password'}
          icoPosition={IcoPosition.right}
          ico={!isShowPassword ? 'blind' : 'eye'}
          icoAction={onShowPasswordHandler}
          placeholder="Пароль"
          fullWidth
        />
        <div className="buttons-block">
          <AppBtn
            sized={AppBtnSize.lg}
            color={AppBtnColor.outlineWhite}
            onClick={authHandler}
            disabled={!login.trim() || !password.trim()}
          >
            {isLoadingLogin ? <Spinner /> : <span>{t('Войти')}</span>}
          </AppBtn>
        </div>
      </div>
    </>
  )
}
