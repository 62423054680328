import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../core/store/store'
import './AppScrollWrapper.scss'

export interface IAppScrollWrapperProps {
  childrenRef: React.MutableRefObject<
    HTMLDivElement | HTMLTextAreaElement | null
  > | null
  children: JSX.Element
  showIn?: 'textarea'
  fullWidth?: boolean
}

export const AppScrollTextareaWrapper = ({
  children,
  childrenRef,
  showIn = 'textarea',
  fullWidth,
}: IAppScrollWrapperProps) => {
  const { buttonScrollMode } = useAppSelector((state) => state.options)
  const ref = childrenRef?.current || null
  const [scrollDisableUp, setScrollDisableUp] = useState(true)
  const [scrollDisableDown, setScrollDisableDown] = useState(true)

  const handleScrollDown = () => {
    setScrollDisableUp(false)
    ref?.scrollBy({
      top: 100,
      behavior: 'smooth',
    })
    if (!!ref && ref.scrollHeight - (ref.scrollTop + ref?.clientHeight) <= 1) {
      setScrollDisableDown(true)
    }
  }

  useEffect(() => {
    if (!!ref && ref.scrollHeight - (ref.scrollTop + ref?.clientHeight) >= 10) {
      setScrollDisableDown(false)
    }
    if (!!ref && ref.scrollHeight === ref.clientHeight) {
      setScrollDisableDown(true)
    }
  }, [ref, children])

  const handleScrollUp = () => {
    ref?.scrollBy({
      top: -100,
      behavior: 'smooth',
    })
    if ((ref?.scrollTop && ref?.scrollTop - 100 <= 0) || ref?.scrollTop === 0) {
      setScrollDisableUp(true)
    }
    if (!!ref && ref.scrollHeight - (ref.scrollTop + ref?.clientHeight) <= 20) {
      setScrollDisableDown(false)
    }
  }

  return (
    <div
      className={`scroll-wrap${fullWidth ? ' full-width' : ''}${
        showIn === 'textarea' ? ' textarea-mode' : ''
      }`}
    >
      <div
        className={`scroll-content ${!buttonScrollMode ? 'scroll-mode' : ''}`}
        style={{
          maxHeight: '100%',
          height: ref?.clientHeight,
        }}
      >
        {children}
        {!childrenRef ||
        (scrollDisableDown && scrollDisableUp) ||
        !buttonScrollMode ? (
          <></>
        ) : (
          <div className={`scroll-arrows-vertical`}>
            <button
              onClick={handleScrollUp}
              className={`arrow-up ${scrollDisableUp ? 'disabled' : ''}`}
              disabled={scrollDisableUp}
            >
              <i className="an-ico an-ico-arrow-down-bg" />
            </button>
            <button
              onClick={handleScrollDown}
              className={`arrow-down ${scrollDisableDown ? 'disabled' : ''}`}
              disabled={scrollDisableDown}
            >
              <i className="an-ico an-ico-arrow-down-bg" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

interface AppScrollWrapperNewProps {
  theme?:
    | 'table'
    | 'dropdown'
    | 'main-scroll'
    | 'timepicker'
    | 'textarea'
    | 'burger'
  children: ReactNode
  trigger?: boolean | number | string
  scrollUp?: boolean
  onScroll?: React.UIEventHandler<HTMLDivElement> | undefined
}

export const AppScrollWrapperNew = ({
  children,
  theme = 'dropdown',
  trigger,
  onScroll,
  scrollUp,
}: AppScrollWrapperNewProps) => {
  const { buttonScrollMode } = useAppSelector((state) => state.options)
  const bottomRef = useRef<HTMLDivElement>(null)
  const scrollWrapRef = useRef<HTMLDivElement>(null)
  const [scrollDisableUp, setScrollDisableUp] = useState(true)
  const [scrollDisableDown, setScrollDisableDown] = useState(true)
  const [scrollDisableLeft, setScrollDisableLeft] = useState(true)
  const [scrollDisableRight, setScrollDisableRight] = useState(true)

  useEffect(() => {
    if (theme === 'main-scroll' && buttonScrollMode) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const { scrollTop } = scrollWrapRef?.current || {}
          if (!entry.isIntersecting) {
            setScrollDisableDown(entry.isIntersecting)
          } else if (entry.isIntersecting) {
            setScrollDisableDown(entry.isIntersecting)
          } else if (scrollTop === 0) {
            setScrollDisableUp(true)
          }
        })
      })

      if (bottomRef.current) {
        observer.observe(bottomRef.current)
      }
      return () => {
        observer.disconnect()
      }
    }
  }, [theme, buttonScrollMode, trigger, children])

  useEffect(() => {
    const ref = scrollWrapRef?.current
    if (ref && buttonScrollMode) {
      const {
        scrollHeight,
        scrollTop,
        clientHeight,
        scrollWidth,
        clientWidth,
        scrollLeft,
      } = ref
      if (scrollUp) {
        if (scrollHeight - -(scrollTop + clientHeight) >= 1) {
          setScrollDisableUp(false)
        }
        if (scrollWidth - (clientWidth + scrollLeft) >= 1) {
          setScrollDisableRight(false)
        }
      } else {
        if (clientHeight < scrollHeight && scrollTop !== 0) {
          setScrollDisableUp(false)
        }
        if (scrollHeight - (scrollTop + clientHeight) >= 1) {
          setScrollDisableDown(false)
        }
        if (scrollWidth - (clientWidth + scrollLeft) >= 1) {
          setScrollDisableRight(false)
        }
      }
    }
  }, [scrollWrapRef, children, trigger, scrollUp, buttonScrollMode])

  const handleScrollDown = () => {
    const ref = scrollWrapRef?.current
    setScrollDisableUp(false)
    ref?.scrollBy({
      top: 100,
      behavior: 'smooth',
    })
    if (scrollUp) {
      if (!!ref && ref.scrollTop >= 0) {
        setScrollDisableDown(true)
      }
    } else {
      if (
        !!ref &&
        ref.scrollHeight - (ref.scrollTop + ref?.clientHeight) <= 1
      ) {
        setScrollDisableDown(true)
      }
    }
  }

  const handleScrollUp = () => {
    const ref = scrollWrapRef?.current
    ref?.scrollBy({
      top: -100,
      behavior: 'smooth',
    })
    if (scrollUp) {
      if (ref && ref.scrollHeight <= -(ref.scrollTop - ref?.clientHeight - 5)) {
        setScrollDisableUp(true)
      }
      setScrollDisableDown(false)
    } else {
      if (
        (ref?.scrollTop && ref?.scrollTop - 100 <= 0) ||
        ref?.scrollTop === 0
      ) {
        setScrollDisableUp(true)
      }
      if (
        !!ref &&
        ref.scrollHeight - (ref.scrollTop + ref?.clientHeight) <= 100
      ) {
        setScrollDisableDown(false)
      }
    }
  }

  const handleScrollRight = () => {
    const ref = scrollWrapRef?.current
    setScrollDisableLeft(false)
    ref?.scrollBy({
      left: 100,
      behavior: 'smooth',
    })
    if (!!ref && ref.scrollWidth - (ref.clientWidth + ref.scrollLeft) <= 100) {
      setScrollDisableRight(true)
    }
  }

  const handleScrollLeft = () => {
    const ref = scrollWrapRef?.current
    ref?.scrollBy({
      left: -100,
      behavior: 'smooth',
    })
    if (
      (ref?.scrollLeft && ref?.scrollLeft - 100 <= 0) ||
      ref?.scrollLeft === 0
    ) {
      setScrollDisableLeft(true)
    }
    if (!!ref && ref.scrollWidth - (ref.clientWidth + ref.scrollLeft) <= 100) {
      setScrollDisableRight(false)
    }
  }

  return (
    <>
      <div
        className={`app-scroll-wrap ${theme}${
          buttonScrollMode && theme === 'main-scroll'
            ? ' button-mode-active'
            : ''
        }`}
        ref={scrollWrapRef}
        onScroll={onScroll}
      >
        {children}
        {(theme === 'main-scroll' || theme === 'table') && (
          <div ref={bottomRef} className="bottom-el" />
        )}
      </div>
      {(!scrollDisableUp || !scrollDisableDown) && buttonScrollMode && (
        <div className={`app-scroll-arrows-vertical ${theme}`}>
          <button
            className={`arrow-up${scrollDisableUp ? ' disabled' : ''}`}
            onClick={handleScrollUp}
          >
            <i className="an-ico an-ico-arrow-down-bg" />
          </button>
          <button
            className={`arrow-down${scrollDisableDown ? ' disabled' : ''}`}
            onClick={handleScrollDown}
          >
            <i className="an-ico an-ico-arrow-down-bg" />
          </button>
        </div>
      )}
      {(!scrollDisableLeft || !scrollDisableRight) && buttonScrollMode && (
        <div className={`app-scroll-arrows-horizontal ${theme}`}>
          <button
            className={`arrow-left${scrollDisableLeft ? ' disabled' : ''}`}
            onClick={handleScrollLeft}
          >
            <i className="an-ico an-ico-arrow-down-bg" />
          </button>
          <button
            className={`arrow-right${scrollDisableRight ? ' disabled' : ''}`}
            onClick={handleScrollRight}
          >
            <i className="an-ico an-ico-arrow-down-bg" />
          </button>
        </div>
      )}
    </>
  )
}
