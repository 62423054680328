import React from 'react'
import Popup from '../../../components/popup/Popup'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'

interface BlockCbPopupProps {
  closePopup: () => void
  onCbClick: () => void
}

export const BlockCbPopup = ({ closePopup, onCbClick }: BlockCbPopupProps) => {
  return (
    <Popup>
      <div className="block-link-popup-wrap">
        <div className="title">
          У вас есть не сохраненные данные, вы действительно хотите покинуть
          страницу?
        </div>
        <div className="buttons-block mt-10">
          <AppBtn
            title="Остаться"
            sized={AppBtnSize.md}
            color={AppBtnColor.outlineViolet}
            onClick={closePopup}
          />
          <AppBtn
            title="Покинуть страницу"
            sized={AppBtnSize.md}
            color={AppBtnColor.outlineViolet}
            onClick={onCbClick}
          />
        </div>
      </div>
    </Popup>
  )
}
