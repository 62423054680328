import React, { useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import BaseHeader from '../widgets/header/BaseHeader'
import { useAppSelector } from '../core/store/store'
import { AllRoutes } from '../core/routes/AllRoutes'
import WorkshopCard from '../components/workshopCard.tsx/WorkshopCard'
import { FixedPanelMemoized } from '../components/fixedPanel/FixedPanel'
import { useGetOrderByIdQuery } from '../core/api/OrderApi'

const BuffetWorkshopsCatalog = () => {
  const { isFullModeWorkshopCard } = useAppSelector((state) => state.buffet)
  const { buffetOrderId } = useParams()
  const refRoot = useRef<HTMLDivElement | null>(null)
  const refChild = useRef<HTMLDivElement | null>(null)
  const { data: allDishById, refetch } = useGetOrderByIdQuery(
    String(buffetOrderId),
    {
      skip: !buffetOrderId,
    },
  )
  const workshops = useMemo(() => {
    const arr: string[] = []
    if (allDishById) {
      allDishById.orderLines.forEach(
        (el) =>
          el.dish.categoryRepresent && arr.push(el.dish.categoryRepresent),
      )
    }

    return arr.length ? Array.from(new Set(arr)) : []
  }, [allDishById])

  return (
    <main className="buffet-page">
      <BaseHeader />
      <FixedPanelMemoized
        refChild={refChild}
        refRoot={refRoot}
        refetch={refetch}
      />
      <div className="column cards" ref={refRoot}>
        <div className="container-fluid">
          <div
            className={`row row-cols-xxl-6 row-cols-xl-5 ${
              isFullModeWorkshopCard ? 'row-cols-md-3' : 'row-cols-md-4'
            }`}
            ref={refChild}
          >
            {buffetOrderId && allDishById?.orderLines.length && (
              <>
                <div className="col">
                  <WorkshopCard
                    title="Все блюда"
                    path={`${buffetOrderId}/${AllRoutes.allDishesByWorkshops.path}`}
                  />
                </div>
                {workshops.map((el, idx) => (
                  <div className="col" key={`${el}-${idx}`}>
                    <WorkshopCard
                      title={el}
                      path={`${buffetOrderId}/${encodeURIComponent(el)}`}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

export default BuffetWorkshopsCatalog
