import React from 'react'

export enum ExtraClass {
  notCategory = 'not-category',
}

interface IPropsNotFound {
  path?: string
  title: string
  className?: ExtraClass
  to?: string
}

const NotFound = ({ path, title, className, to = '' }: IPropsNotFound) => {
  const renderChildren = () => {
    if (className === ExtraClass.notCategory) {
      return <div className="text">Выберите или создайте ее в меню слева</div>
    }
  }

  return (
    <div className={`not-found ${className ? className : ''}`}>
      <img src={path} alt="" className="picture" />
      <div className="text bold">{title}</div>
      {renderChildren()}
    </div>
  )
}

export default NotFound
