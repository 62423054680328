import { useState } from 'react'
import { IIngredients } from '../../core/api/dto/DishObjDto'
import AppBtn, { AppBtnColor, AppBtnRadius, AppBtnSize } from '../btn/AppBtn'
import IngredientTableCell from './IngredientTableCell'

interface IIngredientTableRowProps {
  info: IIngredients
  id: number
  updateItem: (id: number, item: IIngredients) => void
  deleteItem: (id: number) => void
  isEditable: boolean
}

const IngredientTableRow = ({
  info,
  id,
  updateItem,
  isEditable,
  deleteItem,
}: IIngredientTableRowProps) => {
  const [item, setItem] = useState(info)

  const itemChange = (field: string, value: string) => {
    setItem({ ...item, [field]: value.trim() })
  }

  return (
    <tr>
      {Object.keys(item).map((field, idx) => (
        <IngredientTableCell
          key={field}
          onBlur={() => {
            updateItem(id, item)
          }}
          setItem={itemChange.bind(null, field)}
          value={item[field as keyof IIngredients]}
          isEditable={isEditable}
          required={true}
          type={idx === 0 ? 'text' : 'number'}
        />
      ))}
      {isEditable && (
        <td className="icon-delete">
          <AppBtn
            radius={AppBtnRadius.round}
            color={AppBtnColor.outlineGrayLight}
            sized={AppBtnSize.sm}
            iconClassName="close"
            onClick={() => deleteItem(id)}
          />
        </td>
      )}
    </tr>
  )
}

export default IngredientTableRow
