import { IBuffetReportListDto } from '../../core/api/dto/BuffetReportDto'

export interface IReportImagePopupDto {
  id: string
  isCategory: boolean
  categoryImage?: string | null
  dimensionImage?: string | null
  name: string
  dishParam?: number | null
  orderParam?: number | null
}

export const getReportImagePopupData = (
  data: IBuffetReportListDto,
): IReportImagePopupDto[] =>
  data.results.reduce(
    (result, { id, image: categoryImage, name, dimensions }) => {
      result.push({ id: id.toString(), isCategory: true, name, categoryImage })

      dimensions.forEach((item) => {
        result.push({
          id: `${item.id}-dimension`,
          name,
          isCategory: false,
          dimensionImage: item.photoUrl || item?.photoUrlOnS3,
          categoryImage,
          dishParam: item.orderLine.id,
          orderParam: item.orderLine.order.id,
        })
      })

      return result
    },
    new Array<IReportImagePopupDto>(),
  )
