import React from 'react'
import BaseHeader from '../widgets/header/BaseHeader'
import BuffetCardMapCatalogAddition from '../components/mainContent/BuffetCardMapCatalogAddition'

const BuffetAllDishesCatalog = () => {
  return (
    <main className="buffet-page">
      <BaseHeader />
      <BuffetCardMapCatalogAddition />
    </main>
  )
}

export default BuffetAllDishesCatalog
