import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'

export const useGetOffsetParam = (): number => {
  const [searchParams] = useSearchParams()
  const offset = searchParams.get('offset')
  return useMemo(() => {
    return !!offset ? Number(offset) : 0
  }, [offset])
}
