import { IHeaderData } from '../AppTable'
import React from 'react'

interface ITableColgroupProps {
  mobileMode?: boolean
  tabletMode?: boolean
  headerData: IHeaderData[]
  chooseMode?: boolean
}

export const TableColgroup = React.memo(
  ({ mobileMode, tabletMode, headerData, chooseMode }: ITableColgroupProps) => {
    return mobileMode ? (
      <colgroup>
        {chooseMode && <col width="2%" />}
        <col width="49%" />
        <col width="49%" />
      </colgroup>
    ) : tabletMode ? (
      <colgroup>
        {chooseMode && <col width="2%" />}
        <col width="38%" />
        <col width="25%" />
        <col width="20%" />
        <col width="15%" />
      </colgroup>
    ) : (
      <colgroup>
        {chooseMode && <col width="2%" />}
        {headerData.map((th, index) => (
          <col width={th.colWidth} key={th.title + index + 'col'} />
        ))}
      </colgroup>
    )
  },
)
