import React, { memo } from 'react'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { ITaskDto } from '../../../core/api/dto/TaskDto'
import { useGetTasksQuery } from '../../../core/api/TaskApi'
import { AppFilterDropdown } from '../../../components/AppDropdown/AppFilterDropdown'
import {
  AppTableTaskStatusEnum,
  AppTableTaskStatusTranslate,
} from '../../../components/AppTable/AppTableStatus/AppTableStatus'

const taskStatusFilterData = [
  {
    name: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.DRAFT],
    value: AppTableTaskStatusEnum.DRAFT,
  },
  {
    name: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.DONE],
    value: AppTableTaskStatusEnum.DONE,
  },
  {
    name: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.PROGRESS],
    value: AppTableTaskStatusEnum.PROGRESS,
  },
  {
    name: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.DELETED],
    value: AppTableTaskStatusEnum.DELETED,
  },
]

interface TaskListFilterProps {
  isFiltersOpen: boolean
}

export const TaskListFilter = memo(({ isFiltersOpen }: TaskListFilterProps) => {
  if (!isFiltersOpen) return null
  return (
    <div className="filter-block mt-20">
      <div className="col-4">
        <AppFilterMultiDropdownWithPagination<ITaskDto, keyof ITaskDto>
          searchParam={'ids'}
          id={'ids'}
          getterData={useGetTasksQuery}
          propToShowInList={'title'}
          valuePropName={'id'}
          label={'Задачи'}
          placeholder={'Выбрать'}
          fullWidth
        />
      </div>
      <div className="col-4">
        <AppFilterDropdown
          searchParam={'statuses'}
          propToShowInList={'name'}
          valuePropName={'value'}
          data={taskStatusFilterData}
          label={'Статус'}
          placeholder={'Выбрать'}
          minHeight
          fullWidth
        />
      </div>
    </div>
  )
})
