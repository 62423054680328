import React, { useCallback } from 'react'
import StatusInfo from './StatusInfo'
import { IOrder, IOrderLine } from '../../core/api/dto/OrderObjDto'
import { useNavigate, useParams } from 'react-router'

export interface IOrderCardProps {
  info: IOrder
}

const getQuantity = (arr: IOrderLine[]) => {
  // tslint:disable-next-line: one-variable-per-declaration
  let a: number = 0,
    b: number = 0

  arr.forEach((el) => {
    a += el.orderLineDimensions.length
    b += !Number.isInteger(el.quantity) ? 1 : el.quantity
  })

  return {
    count: `${a}/${b}`,
    isIssued: a === b,
  }
}

const OrderCardAdmin = ({
  info: {
    id,
    orderDate,
    orderDateEnd,
    orderDateStart,
    orderRepresent,
    orderLines,
    waiter,
  },
}: IOrderCardProps) => {
  const { orderId } = useParams()
  const navigate = useNavigate()

  const changeActiveCard = useCallback(() => {
    if (id !== Number(orderId)) navigate(`${id}`)
  }, [id, navigate, orderId])

  return (
    <div
      className={`card main-view admin  
      ${id === Number(orderId) ? 'check' : ''}
      `}
      onClick={changeActiveCard}
    >
      <div className="wrapper-card">
        <div className={`number ${orderRepresent.length > 5 ? 'sm' : ''}`}>
          {orderRepresent.slice(0, 30)}
        </div>
        <div className="info-dish">
          <div className="waiter">
            {waiter && waiter?.name ? waiter.name : ''}
          </div>
          <div
            className={`count ${
              getQuantity(orderLines).isIssued ? '' : 'color-Red'
            }`}
          >
            <div>Выдано</div>
            <div>{getQuantity(orderLines).count}</div>
          </div>
        </div>
        <div className="wrapper-info">
          <StatusInfo title="Создан" time={orderDate} />
          <StatusInfo
            title="Взят в работу"
            time={orderDateStart ? orderDateStart : ''}
          />
          <StatusInfo
            title="Завершен"
            time={orderDateEnd ? orderDateEnd : ''}
          />
        </div>
      </div>
    </div>
  )
}

export default OrderCardAdmin
