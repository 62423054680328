import { IFormState } from '../components/AppFormController'

interface ISetCurrentData<T, TKey, OtherValue> {
  data: T
  formState: IFormState
}

export const setCurrentData = <
  T extends { [key in TKey]?: string | number | null | OtherValue },
  TKey extends keyof T,
  OtherValue,
>({
  data,
  formState,
}: ISetCurrentData<T, TKey, OtherValue>) => {
  const resultData = {} as { [p: string]: string }
  for (const value in formState) {
    resultData[value] =
      !!data[value as TKey] || data[value as TKey] === 0
        ? `${data[value as keyof T]}`
        : ''
  }
  return resultData
}
