import React from 'react'
import './AppRadioButtonsBlock.css'

export interface IAppRadioButton<T> {
  value: T
  title: string
}

interface IAppRadioButtonsBlockProps<T> {
  wrapperClassName?: string
  buttonClassName?: string
  value: T
  setValue: (value: T) => void
  buttons: IAppRadioButton<T>[]
}

export const AppRadioButtonsBlock = <T extends string>(
  props: IAppRadioButtonsBlockProps<T>,
) => {
  const { wrapperClassName, buttonClassName, value, setValue, buttons } = props
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as T)
  }
  const isChecked = (checked: T) => value === checked

  return (
    <div className={`app-radio-buttons-block-wrapper ${wrapperClassName}`}>
      {buttons.map((item, idx) => (
        <label
          className={'app-radio-buttons-block-label'}
          key={`btn-list-${idx}`}
        >
          <input
            style={{ display: 'none' }}
            value={item.value}
            type={'radio'}
            onChange={onValueChange}
            checked={isChecked(item.value)}
          />
          <div className={`app-radio-buttons-block-button ${buttonClassName}`}>
            {item.title}
          </div>
        </label>
      ))}
    </div>
  )
}
