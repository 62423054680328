import React, { useCallback, memo } from 'react'
import { useParams } from 'react-router'
import { AllRoutes } from '../../core/routes/AllRoutes'
import { changeField } from '../../core/store/buffet/buffetSlice'
import { useAppDispatch, useAppSelector } from '../../core/store/store'
import { scrollDown, scrollUp } from '../../utils'
import AppBtn, { AppBtnColor, AppBtnSize, IconPosition } from '../btn/AppBtn'
import BuffetSelect from '../buffetSelect/BuffetSelect'
import Checkbox from '../checkbox/Checkbox'
import { IPropsFixedPanelAddition } from './FixedSearchPanel'

export interface IPropsFixedPanel {
  refRoot: React.MutableRefObject<HTMLDivElement | null>
  refChild: React.MutableRefObject<HTMLDivElement | null>
  refetch: () => void
}
const FixedPanel = ({ refChild, refRoot, refetch }: IPropsFixedPanel) => {
  const { buffetOrderId } = useParams()
  const { isFullModeWorkshopCard } = useAppSelector((state) => state.buffet)
  const dispatch = useAppDispatch()
  const handlerScrollUp = useCallback(() => {
    if (refChild.current?.children.length) {
      scrollUp(refRoot, refChild.current!.children[0].clientHeight)
    }
  }, [refRoot, refChild])

  const handlerScrollDown = useCallback(() => {
    if (refChild.current?.children.length) {
      scrollDown(refRoot, refChild.current!.children[0].clientHeight)
    }
  }, [refRoot, refChild])

  const handlerOnChangeMode = () =>
    dispatch(
      changeField({
        field: 'isFullModeWorkshopCard',
        value: !isFullModeWorkshopCard,
      }),
    )

  return (
    <div className="fixed-panel">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-8">
            <BuffetSelect refetchDishes={refetch} />
          </div>
          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-4">
            <div className="btn-block">
              <AppBtn
                title="Добавить блюдо"
                sized={AppBtnSize.md}
                iconClassName="add"
                iconPosition={IconPosition.right}
                path={
                  buffetOrderId
                    ? `${buffetOrderId}/${AllRoutes.additionBuffetCard.path}`
                    : ''
                }
              />
            </div>
          </div>
          <div className="col-xxl-6 col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div className="wrapper-action">
              <Checkbox
                isRadio={true}
                name="radio"
                text="полный размер"
                value={isFullModeWorkshopCard}
                onChange={handlerOnChangeMode}
              />
              <AppBtn
                iconClassName="arrow-up"
                sized={AppBtnSize.sm}
                color={AppBtnColor.violetWhite}
                onClick={handlerScrollUp}
              />
              <AppBtn
                iconClassName="arrow-down"
                sized={AppBtnSize.sm}
                color={AppBtnColor.violetWhite}
                onClick={handlerScrollDown}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function comparePropsFixedPanel(
  prevProps: IPropsFixedPanel | IPropsFixedPanelAddition,
  nextProps: IPropsFixedPanel | IPropsFixedPanelAddition,
) {
  return (
    prevProps.refChild === nextProps.refChild &&
    prevProps.refRoot === nextProps.refRoot &&
    prevProps.refetch.toString() === nextProps.refetch.toString()
  )
}
export const FixedPanelMemoized = memo(FixedPanel, comparePropsFixedPanel)
