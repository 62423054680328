interface IAppCutString {
  text: string
  length: number
  cancel?: boolean
}

export const cutString = ({ text, length, cancel }: IAppCutString) => {
  return cancel
    ? text
    : `${(text?.length || 0) > length ? `${text.slice(0, length)}...` : text}`
}
