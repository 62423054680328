interface IBuffetState {
  isFullModeDish: boolean
  isFullModeAdditionDish: boolean
  isFullModeWorkshopCard: boolean
  isKeyboard: boolean
}

export type TPayloadChangedMode = {
  value: boolean
  field: keyof IBuffetState
}

export const buffetInitialState: IBuffetState = {
  isFullModeDish: false,
  isFullModeAdditionDish: false,
  isFullModeWorkshopCard: false,
  isKeyboard: false,
}
