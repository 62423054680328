import React from 'react'

interface NotificationDotProps {
  count?: number
}

export const NotificationDot = ({ count }: NotificationDotProps) => {
  return (
    <div className="notification-dot-wrap">
      <div className="notification-dot">
        {!!count && <span>{count > 9 ? '9+' : count}</span>}
      </div>
    </div>
  )
}
