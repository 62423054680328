import { useEffect, useState } from 'react'
import { useAppSelector } from '../core/store/store'
import { getNotificationCount } from '../core/store/notifications/notificationsSelectors'

export const useRefetchUnread = (cb: () => void) => {
  const [unreadTaskCount, setUnreadTaskCount] = useState(0)
  const unreadTaskCountNew = useAppSelector(getNotificationCount)

  useEffect(() => {
    if (unreadTaskCount !== unreadTaskCountNew || unreadTaskCountNew === 0) {
      cb()
      setUnreadTaskCount(unreadTaskCount)
    }
  }, [cb, unreadTaskCountNew, unreadTaskCount])
}
