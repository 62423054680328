import React, { useEffect, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppInput } from '../../../components/AppInput/AppInput'
import { TextWithLabel } from '../../../components/TextWithLabel'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
  IconPosition,
} from '../../../components/btn/AppBtn'
import { useNavigate, useParams } from 'react-router'
import {
  useGetStationDetailQuery,
  useUpdateStationMutation,
} from '../../../core/api/StationsApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useTranslation } from 'react-i18next'

export const EditStationsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { stationId } = useParams()
  const { data: currentStation } = useGetStationDetailQuery(
    { id: Number(stationId!) },
    { skip: !stationId },
  )

  const [updateStation] = useUpdateStationMutation()

  const [title, setTitle] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!!currentStation) {
      setTitle(currentStation.name)
    }
  }, [currentStation])

  const updateStationHandler = () => {
    if (!title) {
      setError(true)
    }
    if (title && !!stationId) {
      updateStation({ id: Number(stationId), name: title })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Данные станции успешно изменены!'),
            type: NotificationType.success,
          })
          navigate(-1)
        })
    }
  }

  const setTitleHandler = (value: string) => {
    setTitle(value)
    if (error) {
      setError(false)
    }
  }

  return (
    <div className="width-for-page-wrapper edit-station">
      <AppTitlePage title={`Станция`} goBack />
      {!!currentStation && (
        <div className="col-7-width-from-1000">
          <div className="dish-create-wrap">
            <div className="row">
              <div className="create-col full-width">
                <TextWithLabel label="Guid" text={currentStation.guid} />
                <div className="mt-20">
                  <AppInput
                    value={title}
                    onChange={setTitleHandler}
                    error={!title && error ? 'Обязательное поле' : undefined}
                    label="Название"
                    placeholder="Название"
                    border
                    fullWidth
                    required
                    clear
                  />
                </div>
                <TextWithLabel
                  label="Тип сервера"
                  text={currentStation.serverType}
                />
                <TextWithLabel label="Хост" text={currentStation.host} />
                {/*<TextWithLabel*/}
                {/*  label="Источник"*/}
                {/*  text={'Interface-barcelonetta'}*/}
                {/*/>*/}
                {/*<TextWithLabel label="Код доступа" text={currentStation.host} />*/}
                {/*<div className="mt-20">*/}
                {/*  <Checkbox*/}
                {/*    name={'ididi'}*/}
                {/*    text="Текущий сервер"*/}
                {/*    value={false}*/}
                {/*    onChange={() => {}}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*<TextWithLabel label="General Acces Code" text={'None'} />*/}
              </div>
            </div>
            <div className="buttons-block mt-20">
              <AppBtn
                title="Отменить"
                color={AppBtnColor.outlineViolet}
                sized={AppBtnSize.md}
                iconClassName="close"
                iconPosition={IconPosition.left}
                onClick={() => navigate(-1)}
              />
              <AppBtn
                title="Сохранить"
                color={AppBtnColor.violetWhite}
                sized={AppBtnSize.md}
                iconClassName="save"
                iconPosition={IconPosition.left}
                onClick={updateStationHandler}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
