import React, { useEffect, useRef } from 'react'
import { AppScrollWrapperNew } from '../../widgets/AppScrollWrapper/AppScrollWrapper'

interface IAppTimePicker {
  onChange: (value: string) => void
  value: string | null | undefined
  onClose: () => void
  hourValueValidFn: (value: string) => boolean | undefined
  minValueValidFn: (value: string) => boolean | undefined
}

export const AppTimePicker = ({
  onChange,
  value,
  onClose,
  hourValueValidFn,
  minValueValidFn,
}: IAppTimePicker) => {
  const refDropDown = useRef<HTMLDivElement>(null)

  const outSideClickHandler = (e: any) => {
    e.stopPropagation()
    if (refDropDown.current && !refDropDown.current.parentNode?.contains(e.target)) {
      onClose()
    }
  }
  useEffect(() => {
    document.addEventListener('click', outSideClickHandler, false)
    document.addEventListener('touchend', outSideClickHandler, false)

    return () => {
      document.removeEventListener('click', outSideClickHandler, false)
      document.removeEventListener('touchend', outSideClickHandler, false)
    }
  }, [])

  const hourOptions = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ].map((num) => num.toString().padStart(2, '0'))
  const minuteOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 59].map(
    (num) => num.toString().padStart(2, '0'),
  )

  const setTimeHandler = (newTime: string, set: 'hour' | 'min') => {
    switch (set) {
      case 'hour': {
        return onChange(
          value ? `${newTime}:${value.split(':')[1]}` : `${newTime}:00`,
        )
      }
      case 'min': {
        return onChange(
          value ? `${value.split(':')[0]}:${newTime}` : `00:${newTime}`,
        )
      }
    }
  }

  return (
    <div className="timepicker-container" ref={refDropDown}>
      <div className="timepicker-block">
        <div className="timepicker-title">
          <span className="color-Blue">Часы</span>
        </div>
        <div className="timepicker-items">
          <AppScrollWrapperNew theme="timepicker">
            {hourOptions.map((item, index) => {
              return (
                <div
                  className={`timepicker-item ${
                    value?.split(':')[0] === item ? 'active' : ''
                  } ${hourValueValidFn(item) ? 'disable-time' : ''}`}
                  key={`${index} ${item} hour-key`}
                  onClick={() => {
                    setTimeHandler(item, 'hour')
                  }}
                >
                  {item}
                </div>
              )
            })}
          </AppScrollWrapperNew>
        </div>
      </div>
      <div className="timepicker-block">
        <div className="timepicker-title">
          <span className="color-Blue">Минуты</span>
        </div>
        <div className="timepicker-items">
          <AppScrollWrapperNew theme="timepicker">
            {minuteOptions.map((item, index) => {
              return (
                <div
                  className={`timepicker-item ${
                    value?.split(':')[1] === item ? 'active' : ''
                  } ${minValueValidFn(item) ? 'disable-time' : ''}`}
                  key={`${index} ${item} min-key`}
                  onClick={() => setTimeHandler(item, 'min')}
                >
                  {item}
                </div>
              )
            })}
          </AppScrollWrapperNew>
        </div>
      </div>
    </div>
  )
}
