import React from 'react'

export enum SpinnerPosition {
  center = 'center',
  right = 'right',
}

export enum SpinnerColor {
  white = 'white',
  violet = 'violet',
  green = 'green',
}

export enum SpinnerSize {
  extraSm = 'extra-sm',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

interface IPropsSpinner {
  color?: SpinnerColor
  size?: SpinnerSize
  spinnerPosition?: SpinnerPosition
}

const Spinner = ({
  color = SpinnerColor.white,
  size = SpinnerSize.sm,
  spinnerPosition,
}: IPropsSpinner) => {
  return (
    <div
      className={`wrapper-spinner ${spinnerPosition ? spinnerPosition : ''} `}
    >
      <span className={`spinner ${color} ${size}`}></span>
    </div>
  )
}

export default Spinner
