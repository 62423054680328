import React, { memo, useCallback } from 'react'
import { CookingStatus, IOrderLine } from '../../../core/api/dto/OrderObjDto'
import { setDish } from '../../../core/store/cook/cookSlice'
import { useAppDispatch, useAppSelector } from '../../../core/store/store'
import { IActiveDish } from '../../../pages/Waiter'
import { compareProps } from '../../../utils'
import {
  AppNotification,
  NotificationType,
} from '../../notification/Notification'

export interface IMealItemBaseProps {
  info: IOrderLine
  parentId: number
}

interface IMealItemCook extends IMealItemBaseProps {
  orderDateStart: string | null
  handlerSetActiveDish?: never
  orderRepresent?: never
}

interface IMealItemWaiter extends IMealItemBaseProps {
  handlerSetActiveDish: (el: IActiveDish) => void
  orderDateStart?: never
  orderRepresent: string
}

export type TMealItemProps = IMealItemCook | IMealItemWaiter

export const renderStatusDish = (info: IOrderLine) => {
  if (info.orderLineCookingStatuses?.cookingStatus === CookingStatus.Cooking) {
    return (
      <div className="wrapper-icon processed">
        <i className="an-ico an-ico-hourglass" />
      </div>
    )
  } else if (
    info.orderLineCookingStatuses?.cookingStatus === CookingStatus.Cook_done &&
    !info.orderLineDimensions.length
  ) {
    return (
      <div className="wrapper-icon">
        <i className="an-ico an-ico-check" />
      </div>
    )
  }
}

const MealItemCook = ({ info, parentId, orderDateStart }: TMealItemProps) => {
  const { dishes } = useAppSelector((state) => state.cook)
  const dispatch = useAppDispatch()

  const onClickHandler = useCallback(() => {
    if (orderDateStart) {
      const result = dishes.find(
        (el) => el.orderLineId === info.id && el.parentId === parentId,
      )
      if (!result) {
        dispatch(setDish({ parentId, orderLineId: info.id }))
      } else {
        AppNotification({
          msg: 'Это блюдо уже есть в колонке технологичнских карт.',
          type: NotificationType.info,
        })
      }
    }
  }, [info, orderDateStart, dishes, dispatch, parentId])

  return (
    <div
      className={`meal-item ${info.orderLineDimensions?.length ? 'ready' : ''}`}
      onClick={onClickHandler}
    >
      <div className="name-dish">
        <div>{info.quantity}</div>
        <div>{info.dish.name}</div>
      </div>
      {renderStatusDish(info)}
    </div>
  )
}

const MealItemCookMemoized = memo(MealItemCook, compareProps)
export default MealItemCookMemoized
