import { SystemInitialState } from './systemState'
import { createSlice } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist/es/constants'

export const systemSlice = createSlice({
  name: 'system',
  initialState: SystemInitialState,
  reducers: {
    setBlockedLink(state, action) {
      const { payload } = action
      state.blockLinkState = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state) => {
      state.rehydrated = true
    })
  },
})

export const { reducer: SystemReducer } = systemSlice
export const { setBlockedLink } = systemSlice.actions
