import React, { useCallback, useState } from 'react'
import Popup, { PopupSize } from '../../../../components/popup/Popup'
import { AppInputFile } from '../../../../components/AppInput/AppInputFile'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
  IconPosition,
} from '../../../../components/btn/AppBtn'
import { useUploadBuffetMilletMutation } from '../../../../core/api/BuffetMilletApi'
import { ConflictsBlock } from './ConflictsBlock'
import {
  AppNotification,
  NotificationType,
} from '../../../../components/notification/Notification'
import { IBuffetMilletUploadDto } from '../../../../core/api/dto/BuffetMilletDto'
import { GetFile } from '../../../../utils/getFile'
import { BASE_URL } from '../../../../core/api/BaseApi'
import { useTranslation } from 'react-i18next'

interface IFileUploadMilletPopupProps {
  setActive: (value: boolean) => void
}

export const FileUploadMilletPopup = ({
  setActive,
}: IFileUploadMilletPopupProps) => {
  const { t } = useTranslation()
  const [drag, setDrag] = useState(false)
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<null | File>(null)
  const [conflictData, setConflictData] =
    useState<null | IBuffetMilletUploadDto>(null)
  const [uploadBuffetMillet, { isLoading: isLoadingUpload }] =
    useUploadBuffetMilletMutation()

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDrag(true)
  }
  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDrag(false)
  }
  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files
    setFile(file[0])
    setDrag(false)
  }

  const removeFileHandler = useCallback(() => setFile(null), [])
  const outSideClickHandler = useCallback(() => {
    setActive(false)
  }, [])

  const uploadFileHandler = () => {
    if (file) {
      const formData = new FormData()
      formData.append('millet_file', file)
      uploadBuffetMillet({ data: formData })
        .unwrap()
        .then((res) => {
          if (!!res.conflictMilletsCount) {
            setConflictData({
              ...res,
              uploadedMillets: [...res.uploadedMillets].sort((item, item2) =>
                item.guid > item2.guid ? 1 : -1,
              ),
              existingMillets: [...res.existingMillets].sort((item, item2) =>
                item.guid > item2.guid ? 1 : -1,
              ),
            })
            return // setData
          }
          AppNotification({
            msg: 'Тары успешно загружены!',
            type: NotificationType.success,
          })
          outSideClickHandler()
        })
    }
  }

  return (
    <Popup
      size={PopupSize.base}
      borderRadiusMin
      isLoading={isLoadingUpload || loading}
      closePopup={outSideClickHandler}
    >
      {!!conflictData ? (
        <ConflictsBlock
          setActive={setActive}
          conflictData={conflictData}
          setLoading={setLoading}
        />
      ) : (
        <div className="file-upload-popup">
          <h3 className="file-upload-title">{t('Загрузка файла')}</h3>
          <a
            href="#"
            className="example-link"
            onClick={() =>
              GetFile(`${BASE_URL}/buffet_millet/generate_pattern_file/`)
            }
          >
            <i className="an-ico an-ico-import" />
            <span>{t('Загрузить шаблон .xlsx')}</span>
          </a>
          {!!file ? (
            <div className="file-item-popup">
              <span>{file?.name}</span>
              <div className="delete-file-block" onClick={removeFileHandler}>
                <i className="an-ico an-ico-basket" />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {drag ? (
                <div
                  className="drop-down-block is-draggable"
                  onDragStart={(e) => dragStartHandler(e)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDragOver={(e) => dragStartHandler(e)}
                  onDrop={(e) => onDropHandler(e)}
                >
                  <span>{t('Отпустите файл, чтобы загрузить его')}</span>
                </div>
              ) : (
                <div
                  className="drop-down-block"
                  onDragStart={(e) => dragStartHandler(e)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDragOver={(e) => dragStartHandler(e)}
                >
                  <i className="an-ico an-ico-clip" />
                  <div className="drop-down-text-block">
                    <span className="drop-down-link-text">
                      {t('Перетащите или')}{' '}
                      <AppInputFile onChangeFile={setFile} />{' '}
                      {t('для загрузки')}
                    </span>
                    <span className="color-Gray">
                      {t('Максимальный размер файла 30 Mб')}
                    </span>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          <div className="buttons-block">
            <AppBtn
              title="Отменить"
              color={AppBtnColor.outlineViolet}
              sized={AppBtnSize.md}
              onClick={outSideClickHandler}
              iconClassName="close"
            />
            <AppBtn
              title="Продолжить"
              color={AppBtnColor.violetWhite}
              sized={AppBtnSize.md}
              onClick={uploadFileHandler}
              iconClassName="arrow-long"
              iconPosition={IconPosition.right}
              disabled={isLoadingUpload || loading}
            />
          </div>
        </div>
      )}
    </Popup>
  )
}
