import React from 'react'
import Popup from '../../../components/popup/Popup'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../core/store/store'
import { setBlockedLink } from '../../../core/store/system/systemSlice'
import { useTranslation } from 'react-i18next'

export const BlockLinkPopup = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { blockLinkState } = useAppSelector((state) => state.system)

  const canselHandler = () => {
    dispatch(setBlockedLink({ ...blockLinkState, clickedLink: '' }))
  }
  const goNextPageHandler = () => {
    dispatch(setBlockedLink({ clickedLink: '', linkBlock: false }))
    if (blockLinkState.clickedLink === 'goBack') {
      navigate(-1)
      return
    }
    if (blockLinkState.clickedLink !== 'goBack') {
      navigate(`/${blockLinkState.clickedLink}`.replace(/\/\//g, '/'))
      return
    }
  }

  return (
    <Popup>
      <div className="block-link-popup-wrap">
        <div className="title">
          {t(
            'У вас есть не сохраненные данные, вы действительно хотите покинуть страницу?',
          )}
        </div>
        <div className="buttons-block mt-10">
          <AppBtn
            title="Остаться"
            sized={AppBtnSize.md}
            color={AppBtnColor.outlineViolet}
            onClick={canselHandler}
          />
          <AppBtn
            title="Покинуть страницу"
            sized={AppBtnSize.md}
            color={AppBtnColor.outlineViolet}
            onClick={goNextPageHandler}
          />
        </div>
      </div>
    </Popup>
  )
}
