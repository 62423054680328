import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OptionsInitialState } from './optionsState'

export const optionsSlice = createSlice({
  name: 'options',
  initialState: OptionsInitialState,
  reducers: {
    setButtonScrollMode: (
      state,
      { payload: { buttonMode } }: PayloadAction<{ buttonMode: boolean }>,
    ) => {
      state.buttonScrollMode = buttonMode
    },
    setKeyboardMode: (
      state,
      { payload: { keyboardMode } }: PayloadAction<{ keyboardMode: boolean }>,
    ) => {
      state.keyboardMode = keyboardMode
    },
  },
})

export const { setButtonScrollMode, setKeyboardMode } = optionsSlice.actions
export const { reducer: OptionsReducer } = optionsSlice
