import React, { useMemo, useState } from 'react'
import Checkbox from '../../components/checkbox/Checkbox'
import { changeField } from '../../core/store/buffet/buffetSlice'
import { useAppDispatch, useAppSelector } from '../../core/store/store'
import { LoginByEmail } from './containers/LoginByEmail'
import { LoginByUsername } from './containers/LoginByUsername'
import { setKeyboardMode } from '../../core/store/options/optionsSlice'
import { useTranslation } from 'react-i18next'

enum TabType {
  email = 'email',
  login = 'login',
}

interface TabsData {
  name: string
  value: TabType
}

const tabsData: TabsData[] = [
  { name: 'Email', value: TabType.email },
  { name: 'Login', value: TabType.login },
]

const Login = () => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState<TabType>(tabsData[0].value)
  const dispatch = useAppDispatch()
  const { isKeyboard } = useAppSelector((state) => state.buffet)

  const showTabData = useMemo(
    () => ({
      [TabType.email]: <LoginByEmail isKeyboard={isKeyboard} />,
      [TabType.login]: <LoginByUsername />,
    }),
    [isKeyboard],
  )

  return (
    <div className="login-wrap">
      <TabsBlock
        tabsData={tabsData}
        currentTab={currentTab}
        setCurrentTabValue={setCurrentTab}
      />
      <div className="wrapper-heading">
        <h1>{t('Вход')}</h1>
        <div className="color-White mt-10">
          {/* {t('Введите e-mail для отправки кода подтверждения')} */}
          {t('Введите')}{' '}
          {currentTab === TabType.email
            ? t('e-mail для отправки кода подтверждения')
            : t('логин и пароль для входа')}
        </div>
      </div>
      <div className="mt-20">
        <Checkbox
          value={isKeyboard}
          onChange={() => {
            dispatch(changeField({ field: 'isKeyboard', value: !isKeyboard }))
            dispatch(setKeyboardMode({ keyboardMode: !isKeyboard }))
          }}
          name="keyboard"
          text="Отображать Фотобокс клавиатуру"
        />
      </div>
      <div className="separator" />
      {showTabData[currentTab]}
      {/*<h6>Вход на страницу {getNamePage(pathname)}</h6>*/}
    </div>
  )
}

export default Login

interface TabsBlockProps {
  tabsData: TabsData[]
  currentTab: TabType
  setCurrentTabValue: (value: TabType) => void
}

const TabsBlock = ({
  currentTab,
  setCurrentTabValue,
  tabsData,
}: TabsBlockProps) => {
  const setCurrentTabHandler = (value: TabType) => () =>
    setCurrentTabValue(value)
  return (
    <div className="tabs-block">
      {tabsData.map(({ name, value }) => (
        <div
          key={value}
          onClick={setCurrentTabHandler(value)}
          className={`tabs-btn${currentTab === value ? ' active' : ''}`}
        >
          {name}
        </div>
      ))}
    </div>
  )
}
