import React from 'react'
import { useTranslation } from 'react-i18next'

interface IStationProgressBarProps {
  value: number
  label: string
  text: string
}

export const StationProgressBar = ({
  value,
  label,
  text,
}: IStationProgressBarProps) => {
  const { t } = useTranslation()
  return (
    <div className="progress-bar-block">
      <div className="progress-bar-text">
        <span>{t(label)}</span>
        <span>{text}</span>
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${value}%` }} />
      </div>
    </div>
  )
}
