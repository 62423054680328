import React, { useEffect, useState } from 'react'
import './ImagePreviewWrapper.css'

export enum ImagePreviewWrapperSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface IImagePreviewWrapperProps {
  archived?: boolean
  image: string | null
  imgPreview?: string | null
  size?: ImagePreviewWrapperSizeEnum
  onClick: () => void
  isReportPageItem?: boolean
  className?: string
}

export const ImagePreviewWrapper: React.FC<IImagePreviewWrapperProps> = (
  props,
) => {
  const {
    archived,
    image,
    imgPreview,
    onClick,
    size,
    isReportPageItem,
    className,
  } = props

  function checkIfImageExists(url: string) {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = () => {
        resolve(true)
      }
      img.onerror = () => {
        resolve(false)
      }
      img.src = url
    })
  }
  const [isBroken, setIsBroken] = useState(false)

  useEffect(() => {
    if (!!image) {
      checkIfImageExists(image)
        .then((imageExists) => {
          if (!imageExists) return setIsBroken(true)
          setIsBroken(false)
        })
        .catch(() => setIsBroken(true))
    }
  }, [image])

  const getImagePreviewSize = () => {
    switch (size) {
      case ImagePreviewWrapperSizeEnum.SMALL:
        return { width: '24px', height: '24px' }
      case ImagePreviewWrapperSizeEnum.MEDIUM:
        return { width: '48px', height: '48px' }
      case ImagePreviewWrapperSizeEnum.LARGE:
        return { width: '64px', height: '64px' }
      default:
        return { width: '48px', height: '48px' }
    }
  }

  const onReportPageImageClick = isReportPageItem ? onClick : undefined

  return (
    <React.Fragment>
      <div
        style={getImagePreviewSize()}
        className={`photo-preview-block ${className} ${
          archived ? 'delete-item' : ''
        }`}
      >
        {!!image ? (
          <React.Fragment>
            <img
              style={getImagePreviewSize()}
              src={imgPreview ? imgPreview : image}
              alt={''}
            />
            {/* TODO: добавить дополнительную заглушку фото, когда появитя в дизайне */}
            {/*{isBroken ? 'Поломано' : 'Не поломано'}*/}
            <div className="loupe-block" onClick={onClick}>
              <i className="an-ico an-ico-search" />
            </div>
          </React.Fragment>
        ) : (
          <div
            style={getImagePreviewSize()}
            className="photo-cover"
            onClick={onReportPageImageClick}
          >
            <div className="photo-cover-circle">
              <i className="an-ico an-ico-photo-add" />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
