import React, { useMemo } from 'react'
import { useGetWidth } from 'utils/useGetWidth'

interface IColPaginationProps {
  setCurrentCol: React.Dispatch<React.SetStateAction<number>>
  currentCol: number
  totalCol: number
}

export const ColPagination = React.memo(
  ({ setCurrentCol, totalCol, currentCol }: IColPaginationProps) => {
    const { width } = useGetWidth()
    const prevCol = () => setCurrentCol((prev) => prev - (width<600 ? 1 : 3))
    const nextCol = () => setCurrentCol((prev) => prev + (width<600 ? 1 : 3))
    const countTotatCol = Math.ceil(totalCol/3)
    const countCurrentCol = useMemo(() => Math.ceil(currentCol/3),[currentCol])

    return (
      <div className="table-mobile-pagination-block">
        <div
          className={`arrow-block${currentCol <= 1 ? ' disabled' : ''}`}
          onClick={prevCol}
        >
          <i className="an-ico an-ico-arrow-l" />
        </div>
        {
          width < 600 ? `${currentCol}/${totalCol}`
          : `${countCurrentCol}/${countTotatCol}`
        }
        <div
          className={`arrow-block${(width < 600 ? totalCol === currentCol : countTotatCol === countCurrentCol)? ' disabled' : ''}`}
          onClick={nextCol}
        >
          <i className="an-ico an-ico-arrow-r" />
        </div>
      </div>
    )
  },
)
