import React, { useState } from 'react'
import PendingOrdersColumn from '../components/cookPageColumns/PendingOrdersColumn'
import RecipeCardsColumn from '../components/cookPageColumns/RecipeCardsColumn'
import WorkingOrdersColumn from '../components/cookPageColumns/WorkingOrdersColumn'
import HeaderCook from '../widgets/header/HeaderCook'

const CookPage = () => {
  const [isAllOrders, setIsAllOrders] = useState(false)

  return (
    <>
      <HeaderCook isAllOrders={isAllOrders} setIsAllOrders={setIsAllOrders} />
      <main className="main-content">
        <div className="scroll-area">
          <div className="container-fluid container-xl container-scroll">
            <div className="row">
              <WorkingOrdersColumn isAllOrders={isAllOrders} />
              <PendingOrdersColumn />
              <RecipeCardsColumn />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default CookPage
