import { Role } from '../core/api/dto/UserDto'

interface IParseJwtReturn {
  exp: number | null
  iat: number | null
  id: number | null
  roles: keyof typeof Role | null
}

export const parseJwt = (token: string | null): IParseJwtReturn => {
  const result = {
    exp: null,
    iat: null,
    id: null,
    roles: null,
  }
  if (!token) {
    return result
  }
  const base64Url = token.split('.')[1]
  if (!base64Url) {
    return result
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  const { user_roles, ...rest } = JSON.parse(jsonPayload)
  return user_roles
    ? {
        ...rest,
        roles: user_roles, // TODO make function which detect correct role in array with unknown values
      }
    : result
}
