import React from 'react'
import './AppTableStatus.scss'

export enum AppTableTaskStatusEnum {
  PROGRESS = 'PROGRESS',
  DONE = 'DONE',
  DRAFT = 'DRAFT',
  DELETED = 'DELETED',
}

export const AppTableTaskStatusTranslate = {
  [AppTableTaskStatusEnum.PROGRESS]: 'В работе',
  [AppTableTaskStatusEnum.DONE]: 'Выполнена',
  [AppTableTaskStatusEnum.DRAFT]: 'Черновик',
  [AppTableTaskStatusEnum.DELETED]: 'Удалена',
}

type AppTableStatusEnumType =
  (typeof AppTableTaskStatusEnum)[keyof typeof AppTableTaskStatusEnum]

interface IAppTableStatusProps {
  status: AppTableTaskStatusEnum
}

export const getExtraClassNameFromStatus = (
  value: AppTableStatusEnumType = AppTableTaskStatusEnum.PROGRESS,
): string => AppTableTaskStatusEnum[value].toLowerCase()

export const AppTableStatus: React.FC<IAppTableStatusProps> = (props) => {
  const { status } = props
  return (
    <div
      className={`table-status table-status-wrapper ${getExtraClassNameFromStatus(
        status,
      )}`}
    >
      {AppTableTaskStatusTranslate[status]}
    </div>
  )
}
