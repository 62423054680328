import React, { useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { AppFilterDateInput } from '../../../components/AppInput/AppFilterDateInput'
import { useGetBuffetDishSimpleListQuery } from '../../../core/api/BuffetDishApi'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { IBaseSimpleList } from '../../../core/api/dto/BaseDto'
import Checkbox from '../../../components/checkbox/Checkbox'
import { useParamsControl } from '../../../utils/useParamsControl'
import { useGetCategoryQuery } from '../../../core/api/CategoryApi'
import { useGetGuestListTypeListQuery } from '../../../core/api/GuestTypeApi'
import { AppFilterDropdown } from '../../../components/AppDropdown/AppFilterDropdown'
import { dimensionTypeData } from '../PlanMenuControl/PlanMenuControlAdmin'
import { IPlanMenuTypeDto } from '../../../core/api/dto/PlanMenuTypeDto'
import { useGetPlanMenuTypeListQuery } from '../../../core/api/PlanMenuTypeApi'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { ICompareReportRequest } from '../../../core/api/dto/CompareReportDto'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export enum ShowFormatType {
  'dish' = 'dish',
  'category' = 'category',
  'guest_type' = 'guest_type',
  'dimension_type' = 'dimension_type',
  'plan_menu_type' = 'plan_menu_type',
}

interface ShowFormatItem {
  searchParam: string
  value: ShowFormatType
  name: string
}

const showFormatData: ShowFormatItem[] = [
  {
    searchParam: 'show_format',
    value: ShowFormatType.dish,
    name: 'Название блюда',
  },
  {
    searchParam: 'show_format',
    value: ShowFormatType.category,
    name: 'Категория',
  },
  {
    searchParam: 'show_format',
    value: ShowFormatType.guest_type,
    name: 'Тип гостя',
  },
  {
    searchParam: 'show_format',
    value: ShowFormatType.dimension_type,
    name: 'Тип замера',
  },
  {
    searchParam: 'show_format',
    value: ShowFormatType.plan_menu_type,
    name: 'Тип план-меню',
  },
]

export const BuffetComparativeReports = () => {
  const { t } = useTranslation()
  const pageParams = useParamsControl<
    ICompareReportRequest,
    keyof ICompareReportRequest
  >({
    withPagination: true,
    whiteList: [
      'group',
      'first_period_start',
      'first_period_end',
      'second_period_start',
      'second_period_end',
    ],
  })
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFormat, setShowFormat] = useState(
    () =>
      showFormatData.find(
        (item) => item.value === (searchParams.get('group') as ShowFormatType),
      ) || showFormatData[0],
  )
  const disabledApproveFilter = useMemo(
    () =>
      !pageParams ||
      !pageParams?.first_period_start ||
      !pageParams?.first_period_end ||
      !pageParams?.second_period_start ||
      !pageParams?.second_period_end,
    [pageParams],
  )

  const onApproveFilter = () => {
    searchParams.set('group', showFormat.value)
    navigate(
      `${
        AllRoutes.buffetComparativeReportsDetail.path
      }/?${searchParams.toString()}`,
    )
  }

  const clearFilterHandler = () => {
    setShowFormat(showFormatData[0])
    setSearchParams('')
  }

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Применить фильтр',
        color: AppBtnColor.violetWhite,
        onClick: onApproveFilter,
        disabled: disabledApproveFilter,
      },
      {
        btnTitle: 'Сбросить фильтр',
        color: AppBtnColor.extraVioletViolet,
        onClick: clearFilterHandler,
      },
    ],
    [
      disabledApproveFilter,
      pageParams,
      showFormat,
      clearFilterHandler,
      onApproveFilter,
    ],
  )

  const onShowFormatHandler = (item: ShowFormatItem) => () => {
    searchParams.set('group', item.value)
    setSearchParams(searchParams.toString())
    setShowFormat(item)
  }

  return (
    <div className="width-for-page-wrapper">
      <AppTitlePage title="Сравнительный отчёт" actions={actions} />
      <div className="filter-block mt-20">
        <div>
          <label>
            {t('Период')} 1 <span className="color-Red"> *</span>
          </label>
          <div className="date-filter mt-10">
            <AppFilterDateInput
              searchParam={'first_period_start'}
              maxValue={
                pageParams?.first_period_end || moment().format('MM.DD.YYYY')
              }
            />
            <AppFilterDateInput
              searchParam={'first_period_end'}
              minValue={pageParams?.first_period_start}
              maxValue={moment().format('MM.DD.YYYY')}
            />
          </div>
        </div>
        <div>
          <label>
            {t('Период')} 2 <span className="color-Red"> *</span>
          </label>
          <div className="date-filter mt-10">
            <AppFilterDateInput
              searchParam={'second_period_start'}
              maxValue={
                pageParams?.second_period_end || moment().format('MM.DD.YYYY')
              }
            />
            <AppFilterDateInput
              searchParam={'second_period_end'}
              minValue={pageParams?.second_period_start}
              maxValue={moment().format('MM.DD.YYYY')}
            />
          </div>
        </div>
      </div>
      <div className="radio-filter-block mt-20">
        <span className="text">{t('Выбрать формат отображения')}</span>
        <div className="radio-list mt-20">
          {showFormatData.map((item) => (
            <Checkbox
              name={item.value}
              key={item.value}
              value={item.value === showFormat.value}
              text={t(item.name)}
              isRadioView
              onChange={onShowFormatHandler(item)}
            />
          ))}
        </div>
      </div>

      <div className="comparative-filter-block">
        <span className="title">{t('Выбрать фильтр')}</span>
        <AppFilterMultiDropdownWithPagination<
          IBaseSimpleList,
          keyof IBaseSimpleList
        >
          searchParam={'dish_ids'}
          propToShowInList={'name'}
          valuePropName={'id'}
          label={'Названия блюд'}
          placeholder={'Выбрать'}
          fullWidth
          getterData={useGetBuffetDishSimpleListQuery}
        />
        <AppFilterMultiDropdownWithPagination<
          IBaseSimpleList,
          keyof IBaseSimpleList
        >
          searchParam={'category_ids'}
          propToShowInList={'name'}
          valuePropName={'id'}
          label={'Категория'}
          placeholder={'Выбрать'}
          fullWidth
          getterData={useGetCategoryQuery}
        />
        <AppFilterMultiDropdownWithPagination<
          IBaseSimpleList,
          keyof IBaseSimpleList
        >
          searchParam={'guest_type_ids'}
          propToShowInList={'name'}
          valuePropName={'id'}
          label={'Тип гостя'}
          placeholder={'Выбрать'}
          fullWidth
          getterData={useGetGuestListTypeListQuery}
        />
        <AppFilterDropdown
          searchParam={'dimension_types'}
          data={dimensionTypeData}
          valuePropName={'value'}
          propToShowInList={'name'}
          label={'Тип замера'}
          placeholder={'Выбрать'}
          minHeight
          fullWidth
        />
        <AppFilterMultiDropdownWithPagination<
          IPlanMenuTypeDto,
          keyof IPlanMenuTypeDto
        >
          searchParam={'plan_menu_type_ids'}
          getterData={useGetPlanMenuTypeListQuery}
          propToShowInList={'name'}
          valuePropName={'id'}
          label={'Тип план-меню'}
          placeholder={'Выбрать'}
          fullWidth
        />
      </div>
    </div>
  )
}
