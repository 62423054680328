import { combineReducers } from '@reduxjs/toolkit'
import { api } from '../api/BaseApi'
import { AuthReducer } from './auth/authSlice'
import { BuffetReducer } from './buffet/buffetSlice'
import { ConnectionReducer } from './connection/connectionSlice'
import { CookReducer } from './cook/cookSlice'
import { FCMTokenReducer } from './fcmToken/fcmTokenSlice'
import { SystemReducer } from './system/systemSlice'
import { OptionsReducer } from './options/optionsSlice'
import { versionsApi } from '../api/VersionsApi'
import { NotificationReducer } from './notifications/notificationsSlice'
import { KeyboardReducer } from './keyboard/keyboardSlice'

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [versionsApi.reducerPath]: versionsApi.reducer,
  notification: NotificationReducer,
  system: SystemReducer,
  options: OptionsReducer,
  foodBoxAuth: AuthReducer,
  buffet: BuffetReducer,
  connection: ConnectionReducer,
  cook: CookReducer,
  fcmToken: FCMTokenReducer,
  isKeyboard: KeyboardReducer
})

export type RootState = ReturnType<typeof rootReducer>
