import React from 'react'
import { IBuffetMilletDto } from '../../../../core/api/dto/BuffetMilletDto'
import { useTranslation } from 'react-i18next'

interface IMilletItemProps {
  millet: IBuffetMilletDto
  currentMillet: IBuffetMilletDto | null
  setCurrentMillet: (value: IBuffetMilletDto) => void
}

export const MilletItem = ({
  millet,
  currentMillet,
  setCurrentMillet,
}: IMilletItemProps) => {
  const { t } = useTranslation()
  const { milletPhoto, milletName, milletWeight, dishMillet } = millet
  const setCurrentMilletHandler = () => setCurrentMillet(millet)
  return (
    <div
      className={`millet-item-for-issuing${
        currentMillet?.id === millet.id ? ' active' : ''
      }`}
      onClick={setCurrentMilletHandler}
    >
      {!!dishMillet && <div className="status">{t('назначена')}</div>}
      <div className="photo-block">
        {milletPhoto ? (
          <img src={milletPhoto} alt={milletName} />
        ) : (
          <i className="an-ico an-ico-photo-add" />
        )}
      </div>
      <div className="text-block">
        <div className="title clip-base">{milletName}</div>
        <div className="weight">
          <p style={{minWidth: 70, margin: 0}}>{milletWeight.toFixed(3)} {t('кг')}</p>
        </div>
      </div>
    </div>
  )
}
