import React, { useCallback, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { AppTable } from '../../../components/AppTable/AppTable'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import {
  useArchiveMultipleCategoryMutation,
  useGetCategoryQuery,
} from '../../../core/api/CategoryApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import { ICategoriesRequestDto } from '../../../core/api/dto/CategoriesObjDto'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import { AddCategoryPopup } from './AddCategoryPopup'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useDeleteDebounce } from '../../../utils/useDeleteDebounce'
import { useTranslation } from 'react-i18next'

const headerData = [{ title: 'Категория', colWidth: '95%', sort: 'name' }]

export interface IPopup {
  active: boolean
  id: number | null
}

export const CategoriesPage = () => {
  const { t } = useTranslation()
  const pageParams = useParamsControl<
    ICategoriesRequestDto,
    keyof ICategoriesRequestDto
  >({
    withPagination: true,
    whiteList: ['search', 'ordering'],
  })
  const {
    data: category,
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
  } = useGetCategoryQuery(
    { ...pageParams },
    {
      skip: !pageParams,
    },
  )
  const [archiveMultipleCategory] = useArchiveMultipleCategoryMutation()
  const [openCreatePopup, setOpenCreatePopup] = useState<IPopup>({
    active: false,
    id: null,
  })
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Добавить категорию',
        btnIco: 'add',
        onClick: () =>
          setOpenCreatePopup((prevState) => ({ ...prevState, active: true })),
        color: AppBtnColor.whiteOutlineViolet,
      },
    ],
    [],
  )

  const deleteCategoryHandler = async () => {
    if (!!chooseItems) {
      archiveMultipleCategory({
        categories: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>{
          AppNotification({
            msg: t('Выбранные категории успешно удалены!'),
            type: NotificationType.success,
          })
          setChooseItems(null)
        })
    }
  }

  const [onClickHandler] = useDeleteDebounce({ fn: deleteCategoryHandler })

  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]

  const openEditPopupHandler = useCallback(
    (id: number) => setOpenCreatePopup({ id, active: true }),
    [],
  )

  const closePopupHandler = useCallback(
    () => setOpenCreatePopup({ id: null, active: false }),
    [],
  )

  if (!category && isLoadingGet) {
    return <AppLoader loaderType={LoaderType.main} />
  }

  if (!category) {
    return null
  }

  return (
    <React.Fragment>
      {openCreatePopup.active && (
        <AddCategoryPopup
          closePopup={closePopupHandler}
          id={openCreatePopup.id}
        />
      )}
      <div className="width-for-page-wrapper">
        <AppTitlePage title="Категории блюд" actions={actions} />
        <div className="col-7-width-from-1000">
          <div className="filter-block mt-20">
            <AppFilterInput
              fullWidth
              searchParam={'search'}
              ico={'search'}
              label={'Поиск'}
              placeholder="Например: Холодный цех"
            />
          </div>
          <AppTable
            idName="categories"
            data={category}
            headerData={headerData}
            chooseItems={chooseItems}
            chooseMode
            setChooseItems={setChooseItems}
            tableDataSelectors={[
              {
                renderItem: (item) => (
                  <div
                    className={`table-link${
                      item.archived ? ' delete-item' : ''
                    }`}
                    onClick={() => openEditPopupHandler(item.id)}
                  >
                    {item.name}
                  </div>
                ),
              },
            ]}
            chooseActions={tableActions}
            isLoading={isLoadingGet || isFetchingGet}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
