import { BASE_URL } from './BaseApi'
import { IVersionsResponseDto } from './dto/VersionsDto'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const versionsApi = createApi({
  reducerPath: 'versions',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getVersions: builder.query<IVersionsResponseDto, null>({
      query: () => ({
        url: '/project_version/get_project_version/',
      }),
    }),
  }),
})

export const { useGetVersionsQuery } = versionsApi
