import React, { useEffect, useState } from 'react'
import {
  AppInput,
  InputMaskType,
} from '../../../../components/AppInput/AppInput'
import { customSortForOrderLine } from '../CreatePlanMenuPage'
import { getTimeFromMins } from '../../DishesPage/CreateDishPage'
import { AppTableImageViewer } from '../../../../components/AppTableImageViewer/AppTableImageViewer'
import { AppTable } from '../../../../components/AppTable/AppTable'
import { IOrderLine } from '../../../../core/api/dto/PlanMenuDto'
import { IChooseItems } from '../../../../components/mainContent/TableAllDishes'
import { ImagePreviewWrapperSizeEnum } from 'UiKitComponents/ImagePreviewWrapper/ImagePreviewWrapper'
import { AppTableNumberBlock } from '../../../../components/AppTable/components/AppTableNumberBlock'

const headerData = [
  { title: 'Наименование', colWidth: '20%', sort: 'name' },
  { title: 'Категория блюда', colWidth: '20%', sort: 'category' },
  {
    title: 'Заявка, согласно план-меню, кг',
    colWidth: '20%',
    sort: 'quantity',
  },
  { title: 'Вес эталонный, кг', colWidth: '10%', sort: 'standard_weight' },
  {
    title: 'Температура эталонная, °C',
    colWidth: '10%',
    sort: 'standard_temp',
  },
  {
    title: 'Время реализации, ч:мин',
    colWidth: '10%',
    sort: 'implementation_period',
  },
  { title: 'Эталонное фото', colWidth: '5%' },
]

interface IPlanMenuTableDishProps {
  planMenuTableData: IOrderLine[]
  setPlanMenuTableData: React.Dispatch<React.SetStateAction<IOrderLine[]>>
  tableActionsProps: {
    title: string
    ico: string
    onClick: () => void
    disabled?: boolean
  }[]
  tableValid: boolean
  setIsDirty?: (value: boolean) => void
  chooseItems: IChooseItems | null
  setChooseItems: React.Dispatch<React.SetStateAction<IChooseItems | null>>
}

export const PlanMenuTableDish = ({
  planMenuTableData,
  tableActionsProps,
  tableValid,
  setPlanMenuTableData,
  chooseItems,
  setChooseItems,
  setIsDirty,
}: IPlanMenuTableDishProps) => {
  const [currentSortValue, setCurrentSortValue] = useState<null | string>(null)

  const changeQuantityHandler = (lineGuid: string, value: string) => {
    const newPlanMenuTableData: IOrderLine[] = planMenuTableData.map((item) =>
      item.lineGuid === lineGuid ? { ...item, quantity: value } : item,
    )
    setPlanMenuTableData(newPlanMenuTableData)
    setIsDirty && setIsDirty(true)
  }
  const blurQuantityHandler = (lineGuid: string) => {
    const newPlanMenuTableData: IOrderLine[] = planMenuTableData.map((item) =>
      item.lineGuid === lineGuid
        ? { ...item, quantity: Number(item.quantity).toFixed(3) }
        : item,
    )
    setPlanMenuTableData(newPlanMenuTableData)
  }
  const onDeleteOrderLine = () => {
    if (chooseItems) {
      setPlanMenuTableData((planMenuTableData) =>
        planMenuTableData.filter((item) => !chooseItems[item.id]),
      )
      setIsDirty && setIsDirty(true)
    }

    setChooseItems(null)
  }

  useEffect(() => {
    if (currentSortValue) {
      customSortForOrderLine(currentSortValue, setPlanMenuTableData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSortValue])

  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onDeleteOrderLine,
    },
    ...tableActionsProps,
  ]

  return (
    <AppTable
      data={{
        count: 0,
        next: '',
        results: [...planMenuTableData].sort((a, b) =>
          a.dish.archived > b.dish.archived ? -1 : 1,
        ),
        previous: '',
      }}
      withoutPagination
      bigPaddingBottom={!tableValid}
      headerData={headerData}
      chooseMode
      idName="plan-menu-create"
      chooseItems={chooseItems}
      setChooseItems={setChooseItems}
      chooseActions={tableActions}
      setCurrentSortFn={setCurrentSortValue}
      tableDataSelectors={[
        {
          renderItem: (item) => (
            <div className={item.dish.archived ? 'delete-item' : undefined}>
              {item.dish.name}
            </div>
          ),
        },
        {
          renderItem: (item) => (
            <div className={item.dish.archived ? 'delete-item' : undefined}>
              {item.dish?.category?.name}
            </div>
          ),
        },
        {
          renderItem: (item) => (
            <AppInput
              value={`${item.quantity}`}
              onChange={(value) => changeQuantityHandler(item.lineGuid, value)}
              border
              inputMask={InputMaskType.float}
              onBlur={() => {
                blurQuantityHandler(item.lineGuid)
                customSortForOrderLine(currentSortValue, setPlanMenuTableData)
              }}
              error={
                !tableValid && (!item.quantity || Number(item.quantity) === 0)
                  ? 'Обязательное поле'
                  : null
              }
            />
          ),
        },
        {
          renderItem: (item) => (
            <div
              className={`text-end ${
                item.dish.archived ? 'delete-item' : undefined
              }`}
            >
              <AppTableNumberBlock
                value={item?.dish?.standardWeight?.toFixed(3) || ''}
              />
            </div>
          ),
        },
        {
          renderItem: (item) => (
            <div
              className={`text-end ${
                item.dish.archived ? 'delete-item' : undefined
              }`}
            >
              <AppTableNumberBlock
                isTemp
                value={item.dish.standardTempMin ?? ''}
              />{' '}
              <span className="pl-4 pr-4"> - </span>
              <AppTableNumberBlock
                isTemp
                value={item.dish.standardTempMax ?? ''}
              />
            </div>
          ),
        },
        {
          renderItem: (item) => (
            <div className={item.dish.archived ? 'delete-item' : undefined}>
              {`${
                !!item.dish.implementationPeriod
                  ? getTimeFromMins(item.dish.implementationPeriod)
                  : ''
              }`}
            </div>
          ),
        },
        {
          renderItem: (item) => (
            <AppTableImageViewer
              image={item.dish.image}
              imageName={item.dish.name}
              archived={item.dish.archived}
              size={ImagePreviewWrapperSizeEnum.MEDIUM}
            />
          ),
        },
      ]}
    />
  )
}
