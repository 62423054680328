import React from 'react'
import useScroll from '../../utils/useScroll'
import AppBtn, { AppBtnColor } from '../btn/AppBtn'

interface IPropsHeaderColumn {
  title: string
  refContent: React.MutableRefObject<HTMLDivElement | null>
}

const HeaderColumn = ({ title, refContent }: IPropsHeaderColumn) => {
  const scrollList = useScroll(refContent)
  return (
    <div className="header-column">
      <h2 className="column-name">{title}</h2>
      <div className="btn-block">
        <AppBtn
          color={AppBtnColor.violetWhite}
          iconClassName="arrow-down"
          onClick={() => scrollList('down')}
        />
        <AppBtn
          color={AppBtnColor.violetWhite}
          iconClassName="arrow-up"
          onClick={() => scrollList('up')}
        />
      </div>
    </div>
  )
}
export default HeaderColumn
