import React, { useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { useSearchParams } from 'react-router-dom'
import { useGetSimpleOrderBuffetQuery } from '../../../core/api/BuffetOrderApi'
import { LIMIT } from '../../../core/api/BaseApi'
import {
  IBuffetOrderDto,
  IBuffetOrdersRequestDto,
} from '../../../core/api/dto/BuffetOrderDto'
import moment from 'moment'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { IcoPosition } from '../../../components/AppInput/AppInput'
import { useNavigate } from 'react-router'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import { useParamsControl } from '../../../utils/useParamsControl'
import { AppScrollWrapperNew } from '../../../widgets/AppScrollWrapper/AppScrollWrapper'
import { useTranslation } from 'react-i18next'

export const ChoosePlanMenuIssuingPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const pageParams = useParamsControl<
    IBuffetOrdersRequestDto,
    keyof IBuffetOrdersRequestDto
  >({
    withPagination: true,
    whiteList: ['search', 'ids'],
  })
  const dishId = searchParams.get('dish')
  const { data: orderList } = useGetSimpleOrderBuffetQuery({
    ...pageParams,
    limit: LIMIT.buffet,
    ids: pageParams?.ids || undefined,
  })
  const [currentOrder, setCurrentOrder] = useState<null | IBuffetOrderDto>(null)

  const goNextPage = () => {
    if (currentOrder) {
      navigate(
        `/${AllRoutes.planMenuIssuing.path}/${currentOrder.id}/${dishId}/?ids=${
          pageParams?.ids || ''
        }`,
      )
    }
  }
  const actions = useMemo(
    () => [
      {
        btnTitle: 'Продолжить',
        btnIco: 'arrow arrow-right',
        positionIco: IcoPosition.right,
        onClick: goNextPage,
        color: AppBtnColor.violetWhite,
        disabled: !currentOrder,
      },
    ],
    [navigate, currentOrder],
  )

  return (
    <div className="width-for-page-wrapper">
      <AppTitlePage
        title={'Добавление блюда в план меню'}
        goBack
        // goBackPath={`/${AllRoutes.planMenuIssuing.path}/?ids=${
        //   pageParams?.ids || ''
        // }`}
        actions={actions}
      />
      <div className="filter-block mt-20">
        <div className="col-6">
          <AppFilterInput
            label="Поиск по планам меню"
            placeholder="Например: завтрак"
            ico={'search'}
            fullWidth
            searchParam={'search'}
          />
        </div>
        <div className="col-5">
          <div className="info-text">
            <div>
              <i className="an-ico an-ico-info-circle" />{' '}
            </div>
            <span>
              {t(
                'Выберете один из предложенных планов меню, или воспользуйтесь поиском по списку',
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="plan-menu-control-manager-page">
        <AppScrollWrapperNew>
          {orderList?.results.map((planMenu) => (
            <PlanMenuItem
              planMenu={planMenu}
              key={planMenu.id}
              currentOrder={currentOrder}
              setCurrentOrder={setCurrentOrder}
            />
          ))}
        </AppScrollWrapperNew>
      </div>
    </div>
  )
}

interface IPlanMenuItemProps {
  planMenu: IBuffetOrderDto
  currentOrder: IBuffetOrderDto | null
  setCurrentOrder: (value: IBuffetOrderDto) => void
}

const PlanMenuItem = ({
  planMenu,
  currentOrder,
  setCurrentOrder,
}: IPlanMenuItemProps) => {
  const { t } = useTranslation()
  const onPlanMenuClick = () => {
    setCurrentOrder(planMenu)
  }
  return (
    <div
      className={`plan-menu-item${
        currentOrder?.id === planMenu.id ? ' active' : ''
      }`}
      onClick={onPlanMenuClick}
    >
      <div className="title">{planMenu?.name || planMenu?.sourceRepresent}</div>
      <div className="time">
        <div className="title">{t('Назначенное время')}</div>
        <div className="value">
          {`${moment(planMenu?.orderDateStart).format('HH:mm')}`} -{' '}
          {`${moment(planMenu?.orderDateEnd).format('HH:mm')}`}
        </div>
      </div>
    </div>
  )
}
