import React from 'react'
import { useRoutes } from 'react-router'
import PopupNoConnection from '../../components/popup/PopupNoConnection'
import { useAppSelector } from '../store/store'
import { appRoutes } from './RoutesByRoles'
import { Role } from '../api/dto/UserDto'
import { BuffetLinePage } from '../../pages/BuffetLinePage/BuffetLinePage'
import {
  Navigate,
  IndexRouteObject,
  NonIndexRouteObject,
} from 'react-router-dom'
import { AllRoutes } from './AllRoutes'

interface RouteObjectExtendedBase {
  id?: string
}

interface IndexRouteObjectExtended
  extends IndexRouteObject,
    RouteObjectExtendedBase {}

interface NonIndexRouteObjectExtended
  extends NonIndexRouteObject,
    RouteObjectExtendedBase {
  children?: IRouteObjectExtended[]
}

export type IRouteObjectExtended =
  | IndexRouteObjectExtended
  | NonIndexRouteObjectExtended

const AuthRoutesComponent = React.memo(
  ({ currentRole }: { currentRole: (keyof typeof Role)[] }) => {
    const availableRoutes: IRouteObjectExtended[] = appRoutes.authorized
      .filter((route) => currentRole.includes(route.role))
      .map((item) => item.routes)
      .flat()
      .filter((item, index, self) => {
        return index === self.findIndex((t) => t?.path === item?.path)
      })

    const result = availableRoutes.sort((a, b) => Number(a?.id) - Number(b?.id))
    const firstRoutePath = result[0]?.path || ''
    return useRoutes([
      {
        path: '/',
        element: <BuffetLinePage />,
        children: [
          {
            index: true,
            element: <Navigate to={firstRoutePath} replace />,
          },
          ...result,
        ],
      },
    ])
  },
)

export const AuthRoutes = () => {
  const { currentRole } = useAppSelector((state) => state.foodBoxAuth)
  const { isFetchError } = useAppSelector((state) => state.connection)

  const pathVariable = AllRoutes.planMenuIssuing.path
  const regex = new RegExp(`${pathVariable}/\\d+/\\d+/`)
  const isIssuingError =
    isFetchError !== 'Ошибка сервера'
      ? !!isFetchError && regex.test(window.location.pathname)
      : !!isFetchError

  return (
    <>
      <AuthRoutesComponent currentRole={currentRole} />
      {isIssuingError ? <PopupNoConnection /> : null}
    </>
  )
}
