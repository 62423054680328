import useValidate from '../../utils/useValidate'

interface IIngredientTableCell {
  setItem: (value: string) => void
  onBlur: () => void
  value: string
  isEditable: boolean
  required: boolean
  type?: string
}

const IngredientTableCell = ({
  setItem,
  onBlur,
  value = '',
  isEditable,
  required,
  type = 'text',
}: IIngredientTableCell) => {
  const { error, onChangeHandler, val } = useValidate(value, setItem, required)

  return (
    <td>
      <input
        type={type}
        className={error}
        value={val}
        onChange={onChangeHandler}
        onBlur={onBlur}
        required={true}
        disabled={!isEditable}
      />
    </td>
  )
}

export default IngredientTableCell
