import React, { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { AppFilterDateInput } from '../../../components/AppInput/AppFilterDateInput'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import { AppTable } from '../../../components/AppTable/AppTable'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import {
  useDeletePlanMenuTemplateListMutation,
  useGetPlanMenuTemplateListQuery,
} from '../../../core/api/PlanMenuTemplateApi'
import moment from 'moment'
import { useParamsControl } from '../../../utils/useParamsControl'
import { IPlanMenuTemplateRequest } from '../../../core/api/dto/PlanMenuTemplateDto'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useDeleteDebounce } from '../../../utils/useDeleteDebounce'
import { AppTableNumberBlock } from '../../../components/AppTable/components/AppTableNumberBlock'
import {
  AppTableAlignType,
  AppTableLink,
} from '../../../components/AppTable/components/AppTableLink'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'

const headerData = [
  { title: 'Название шаблона', colWidth: '55%', sort: 'name' },
  { title: 'Количество гостей', colWidth: '20%', sort: 'number_of_persons' },
  { title: 'Дата создания шаблона', colWidth: '20%', sort: 'created_at' },
]

export const TemplatePlanMenuPage = () => {
  const { t } = useTranslation()
  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)
  const [clearFilterField, setClearFilterField] = useState<boolean>(false)
  const {width} = useGetWidth()

  const pageParams = useParamsControl<
    IPlanMenuTemplateRequest,
    keyof IPlanMenuTemplateRequest
  >({
    withPagination: true,
    whiteList: [
      'search',
      'archived',
      'ordering',
      'created_at_date_start',
      'created_at_date_end',
    ],
  })
  const { data: planMenuTemplateList } = useGetPlanMenuTemplateListQuery(
    { ...pageParams },
    { skip: !pageParams },
  )
  const [deleteTemplate] = useDeletePlanMenuTemplateListMutation()

  const clearFilterHandler = useCallback(() => {
    setClearFilterField(true)
    setSearchParams('limit=40')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteTemplateHandler = async () => {
    if (!!chooseItems) {
      deleteTemplate({
        ids: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>{
          AppNotification({
            msg: t('Выбранные шаблоны успешно удалены!'),
            type: NotificationType.success,
          })
          setChooseItems(null)
        })
    }
  }
  const [onClickHandler] = useDeleteDebounce({ fn: deleteTemplateHandler })
  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Добавить шаблон',
        btnIco: 'add',
        onClick: () => navigate(AllRoutes.addTemplatePlanMenu.path),
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Сбросить фильтр',
        onClick: clearFilterHandler,
        color: AppBtnColor.extraVioletViolet,
      },
    ],
    [clearFilterHandler, navigate],
  )
  if (!planMenuTemplateList) {
    return null
  }
  return (
    <div className="width-for-page-wrapper">
      <AppTitlePage
        title="Шаблоны план-меню"
        actions={actions}
        style={{ marginBottom: '20px' }}
      />
      <div className="filter-block mt-20">
        <div className={`col-${width<1200 ? width<690 ? 10 : 5 : 3}`}>
          <AppFilterInput
            searchParam={'search'}
            ico={'search'}
            placeholder="Например: Завтрак"
            fullWidth
            label={'Поиск'}
            clearValue={clearFilterField}
            setClearValue={setClearFilterField}
          />
        </div>
        <div>
          <label>{t('Период создания')}</label>
          <div className="input-row mt-10">
            <AppFilterDateInput searchParam={'created_at_date_start'} />
            <AppFilterDateInput searchParam={'created_at_date_end'} />
          </div>
        </div>
      </div>
      <AppTable
        headerData={headerData}
        data={planMenuTemplateList}
        chooseMode
        chooseItems={chooseItems}
        setChooseItems={setChooseItems}
        chooseActions={tableActions}
        tableDataSelectors={[
          {
            renderItem: (item) => (
              <AppTableLink path={`${item.id}`}>{item.name}</AppTableLink>
            ),
          },
          {
            renderItem: (item) => (
              <AppTableLink path={`${item.id}`} align={AppTableAlignType.right}>
                <AppTableNumberBlock value={item.numberOfPersons} />
              </AppTableLink>
            ),
          },
          {
            renderItem: (item) => (
              <AppTableLink path={`${item.id}`}>
                {moment(item.createdAt).format('DD.MM.YYYY')}
              </AppTableLink>
            ),
          },
        ]}
      />
    </div>
  )
}
