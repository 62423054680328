import React, { useState } from 'react'
import Keyboard, { KeyboardInput } from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'

export enum Lang {
  RU = 'ru',
  EN = 'en',
  NUM = 'num',
}

interface IProps {
  onChange?: (input: string) => void
  onChangeInputValue?: (input: string) => void
  value?: string | null
  handlerKeyEnter?: () => void
  inputName?: string
  onChangeAll?: (inputObj: KeyboardInput) => void
  keyboard: React.MutableRefObject<any>
  lang?: Lang
}

const layoutNumber = {
  default: ['1 2 3 4 5 {bksp}', '6 7 8 9 0 {enter}'],
}

const layoutRu = {
  default: [
    '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    '\u0439 \u0446 \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u0449 \u0437 \u0445 \u044a {shift}',
    '\u0444 \u044b \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u044d {enter}',
    '\u044f \u0447 \u0441 \u043c \u0438 \u0442 \u044c \u0431 \u044e . {lang}',
    '.com @ {space}',
  ],
  shift: [
    '@ ! " \u2116 ; % : ? * ( ) _ + {bksp}',
    '\u0419 \u0426 \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u0429 \u0417 \u0425 \u042a / {shift}',
    '\u0424 \u042b \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u042d {enter}',
    '\u042f \u0427 \u0421 \u041c \u0418 \u0422 \u042c \u0411 \u042e , {lang}',
    '.com @ {space}',
  ],
}

const layoutEn = {
  default: [
    '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    'q w e r t y u i o p [ ] \\ {shift}',
    "a s d f g h j k l ; ' {enter}",
    'z x c v b n m , . / {lang}',
    '.com @ {space}',
  ],
  shift: [
    '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
    'Q W E R T Y U I O P { } | {shift}',
    'A S D F G H J K L : " {enter}',
    'Z X C V B N M < > ? {lang}',
    '.com @ {space}',
  ],
}

const KeyboardWrapper = ({
  handlerKeyEnter,
  inputName,
  onChangeAll,
  keyboard,
  lang = Lang.EN,
  onChange,
  onChangeInputValue,
  value,
}: IProps) => {
  const [layoutName, setLayoutName] = useState('default')
  const [currentLang, setCurrentLang] = useState(lang)

  const onKeyPress = (button: string) => {
    if (button === '{shift}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default')
    }
    if (button === '{enter}' && handlerKeyEnter) {
      handlerKeyEnter()
    }
    if (button === '{lang}') {
      currentLang === Lang.RU
        ? setCurrentLang(Lang.EN)
        : setCurrentLang(Lang.RU)
    }
    if (button === '{bksp}' && !!value) {
      onChange?.(value.slice(0, -1))
      onChangeInputValue?.(value.slice(0, -1))
      keyboard?.current?.clearInput()
      return
    }
    // if (button === '{space}') {
    //   onChangeInputValue?.(value + ' ')
    // }
    // if (button === '.com') {
    //   onChangeInputValue?.(value + '.com')
    // }
    // const regex = /^[A-Za-zА-Яа-яЁё0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]$/
    // if (regex.test(button)) {
    //   onChangeInputValue?.(value + button)
    // }
  }

  const display = {
    '{shift}': '⇧',
    '{bksp}': '⌫',
    '{space}': ' ',
    '{lang}': currentLang === 'ru' ? 'EN' : 'RU',
    '{enter}': 'Enter',
  }

  const layoutKeyboard = () => {
    switch (currentLang) {
      case Lang.NUM:
        return layoutNumber
      case Lang.EN:
        return layoutEn

      default:
        return layoutRu
    }
  }
  return (
    <Keyboard
      layout={layoutKeyboard()}
      layoutName={layoutName}
      onChange={onChange}
      onKeyPress={onKeyPress}
      inputName={inputName}
      display={display}
      onChangeAll={onChangeAll}
      keyboardRef={(r) => (keyboard.current = r)}
    />
  )
}

export default KeyboardWrapper
