import { api } from './BaseApi'
import {
  IBuffetOrdersRequestDto,
  IBuffetOrdersResponseDto,
} from './dto/BuffetOrderDto'

export const buffetOrderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrderBuffet: builder.query<
      IBuffetOrdersResponseDto,
      IBuffetOrdersRequestDto
    >({
      query: (params) => ({
        url: '/order/',
        params: !!params ? params : {},
      }),
      providesTags: ['Order', 'OrderLine', 'PlanMenu'],
    }),
    getSimpleOrderBuffet: builder.query<
      IBuffetOrdersResponseDto,
      IBuffetOrdersRequestDto
    >({
      query: (params) => ({
        url: '/order/get_simple_orders/',
        params: !!params ? params : {},
      }),
      providesTags: ['Order', 'OrderLine', 'PlanMenu'],
    }),
  }),
})

export const { useGetOrderBuffetQuery, useGetSimpleOrderBuffetQuery } =
  buffetOrderApi
