import { URLSearchParamsInit, useLocation } from 'react-router-dom'

interface IResetPaginationByParamsChangedProps {
  searchParams: URLSearchParams
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | { replace?: boolean | undefined; state?: any }
      | undefined,
  ) => void
  pathname?: string
}

export const resetPaginationByParamsChanged = ({
  searchParams,
  setSearchParams,
  pathname
}: IResetPaginationByParamsChangedProps) => {
  if(pathname === '/plan-menu-control-manager'){
    searchParams.set('limit', `100`)
  } else {
    searchParams.set('limit', `40`)
  }
  searchParams.set('offset', '0')
  searchParams.delete('order')
  searchParams.delete('dimension')
  searchParams.delete('dish')
  setSearchParams(searchParams.toString())
}
