import React, { useState } from 'react'
import Popup, { PopupTypeEnum } from '../../popup/Popup'

interface IPropsPhotoItem {
  name?: string
  image: string
}

const BasePhotoItem = ({ name, image }: IPropsPhotoItem) => {
  const [isZoomPhoto, setIsZoomPhoto] = useState(false)
  return (
    <div className="picture-dish main-view">
      {!!name && (
        <div className="name">
          <span>{name}</span>
        </div>
      )}
      <div className="wrapper-picture">
        {image ? (
          <div onClick={() => setIsZoomPhoto(true)}>
            <div className="wrapper-icon">
              <i className="an-ico an-ico-search"></i>
            </div>
            <img src={image} alt="" className="picture" />
          </div>
        ) : (
          <div className="no-photo">
            <i className="an-ico an-ico-photo" />
            <h4>Фото отсутствует</h4>
          </div>
        )}
      </div>
      {isZoomPhoto && (
        <Popup
          popupType={PopupTypeEnum.photo}
          closePopup={() => setIsZoomPhoto(false)}
        >
          <img src={image} alt="" className="img-child-popup" />
        </Popup>
      )}
    </div>
  )
}

export default BasePhotoItem
