import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IBuffetDishDto } from '../../../core/api/dto/BuffetDishDto'
import { useGetBuffetDishQuery } from '../../../core/api/BuffetDishApi'
import Checkbox from '../../../components/checkbox/Checkbox'
import { useTranslation } from 'react-i18next'

interface IAppWidgetDishProps {
  title: string
  categoryId: number
  dishesByCategoryValue?: IBuffetDishDto[]
  dishesValue: IBuffetDishDto[]
  setDishesValue: React.Dispatch<React.SetStateAction<IBuffetDishDto[]>>
}

export const AppWidgetDish = ({
  title,
  categoryId,
  dishesValue,
  setDishesValue,
  dishesByCategoryValue,
}: IAppWidgetDishProps) => {
  const { t } = useTranslation()
  const [active, setActive] = useState<boolean>(!!dishesByCategoryValue)
  const [dishList, setDishList] = useState<IBuffetDishDto[]>([])
  const [chooseAll, setChooseAll] = useState(false)
  const { data: buffetDishesList } = useGetBuffetDishQuery(
    { limit: 200, category: `${categoryId}`, archived: '0' },
    { skip: !!dishesByCategoryValue || !active || !categoryId },
  )

  const onChangeHandler = (newItem: IBuffetDishDto) => {
    const duplicate = dishesValue.find((item) => item.id === newItem.id)
    if (!!duplicate) {
      const removeDuplicate = dishesValue.filter(
        (item) => item.id !== duplicate.id,
      )
      setDishesValue(removeDuplicate)
    } else setDishesValue([...dishesValue, newItem])
  }

  useEffect(() => {
    if (!!buffetDishesList) {
      setDishList(buffetDishesList.results)
    }
  }, [buffetDishesList])

  const onToggleHandler = useCallback(
    () => setActive((prevState) => !prevState),
    [],
  )
  const dishArr = useMemo(
    () => (dishesByCategoryValue ? dishesByCategoryValue : dishList),
    [dishList, dishesByCategoryValue],
  )
  const setChooseAllHandler = (value: boolean) => {
    setChooseAll(value)
    if (value) {
      setDishesValue((prevState) =>
        prevState
          .concat(dishArr)
          .filter(
            (obj, index, self) =>
              index ===
              self.findIndex((t) => t.id === obj.id && t.name === obj.name),
          ),
      )
    }
    if (!value) {
      setDishesValue((prevState) =>
        prevState.filter(
          (obj1) => !dishArr.some((obj2) => obj1.id === obj2.id),
        ),
      )
    }
  }

  return (
    <div className="widget-dish-wrap">
      <div className={`title-block ${active ? 'active' : ''}`}>
        <div className="title">{t(title)}</div>
        <div className="toggle" onClick={onToggleHandler}>
          <i className="an-ico an-ico-arrow-down-bold" />
        </div>
      </div>
      {active && (
        <div className="widget-dish-content">
          <React.Fragment>
            {!!dishArr.length && (
              <Checkbox
                name={`${categoryId}`}
                onChange={setChooseAllHandler}
                value={chooseAll}
                text="Выбрать все"
              />
            )}
            {dishArr.map((dish, index) => {
              const isChoose = dishesValue.find(
                (dishValue) => dishValue.id === dish.id,
              )
              return (
                <Checkbox
                  name={dish.name}
                  text={dish.name}
                  value={!!isChoose}
                  onChange={() => onChangeHandler(dish as IBuffetDishDto)}
                  key={`${dish.id}-i-${index}`}
                />
              )
            })}
            {!!buffetDishesList?.next && (
              <div className="btn md violet-white">{t('Показать ещё')}</div>
            )}
          </React.Fragment>
        </div>
      )}
    </div>
  )
}
