import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { useSearchParams } from 'react-router-dom'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { AppFilterDateInput } from '../../../components/AppInput/AppFilterDateInput'
import { IBaseSimpleList } from '../../../core/api/dto/BaseDto'
import { useGetPlanMenuSimpleListQuery } from '../../../core/api/PlanMenuApi'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import { InputMaskType } from '../../../components/AppInput/AppInput'
import { useGetBuffetDishSimpleListQuery } from '../../../core/api/BuffetDishApi'
import { ICategory } from '../../../core/api/dto/CategoriesObjDto'
import { useGetCategoryQuery } from '../../../core/api/CategoryApi'
import { AppFilterDropdown } from '../../../components/AppDropdown/AppFilterDropdown'
import { useGetBuffetReportQuery } from '../../../core/api/BuffetReportApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import {
  DimensionTranslateType,
  IBuffetReportDto,
  IBuffetReportRequest,
} from '../../../core/api/dto/BuffetReportDto'
import moment from 'moment'
import { createQueryString } from '../../../utils/createQueryString'
import { GetFile } from '../../../utils/getFile'
import { BASE_URL } from '../../../core/api/BaseApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useGetGuestListTypeListQuery } from '../../../core/api/GuestTypeApi'
import { AppTableMobile } from '../../../components/AppTable/AppTableMobile'
import { ReportImagePopup } from '../../ReportImagePopup/ReportImagePopup'
import React, { useState, useCallback, useMemo } from 'react'
import {
  ImagePreviewWrapper,
  ImagePreviewWrapperSizeEnum,
} from 'UiKitComponents/ImagePreviewWrapper/ImagePreviewWrapper'
import { AppTableNumberBlock } from '../../../components/AppTable/components/AppTableNumberBlock'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import './ReportPage.css'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'

enum ReportNumberType {
  red = 'red',
  orange = 'orange',
  blue = '',
}

export const measurementTypeData = [
  { name: 'Выдача', value: 'Serving' },
  { name: 'Возврат', value: 'Refund' },
  { name: 'Списание', value: 'Write_off' },
]

const headerData = [
  { title: 'Наименование', colWidth: '15%', sort: 'name' },
  { title: 'Категория', colWidth: '7%', sort: 'category__name' },
  { title: 'Дата исполнения', colWidth: '5%' },
  { title: 'Название план-меню', colWidth: '7%' },
  { title: 'Тип гостя', colWidth: '5%' },
  { title: 'Тип замера', colWidth: '5%' },
  { title: 'Тип контр-та', colWidth: '5%' },
  { title: 'Кол-во замеров', colWidth: '5%', sort: 'dimension_count' },
  { title: 'Вес этал., кг', colWidth: '5%', sort: 'standard_weight' },
  {
    title: 'Кол-во заявленных гостей',
    colWidth: '5%',
    sort: 'number_of_persons_sum',
  },
  { title: 'Кол-во указанных гостей', colWidth: '5%' },
  { title: 'Вес факт., кг', colWidth: '5%', sort: 'dimensions_quantity_sum' },
  { title: 'Вес заяв., кг', colWidth: '5%', sort: 'order_lines_quantity_sum' },
  {
    title: 'Допустимое откл. (±), кг',
    colWidth: '5%',
    sort: 'standard_deviation_total',
  },
  {
    title: 'Температура этал., °C',
    colWidth: '5%',
    sort: 'standard_temp_min,standard_temp_max',
  },
  {
    title: 'Температура факт. (мин/ср/макс), °C',
    colWidth: '15%',
    sort: 'avg_temperature',
  },
  { title: 'Фото', colWidth: '5%' },
]

const isValidTemp = (value: number, min: number, max: number) => {
  return value >= min && value <= max
}

enum fileTypeToExportEnum {
  XLSX = 'export_report_to_file',
  temperatureReport = 'export_temperature_report',
  defectReport = 'export_defect_report',
}

export const ReportPage = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [clearFilterField, setClearFilterField] = useState<boolean>(false)
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false)
  const [isFiltersOpen, setIsFiltersOpen] = useState(true)
  const [toggleAllState, setToggleAllState] = useState<number[]>([])
  const { width } = useGetWidth()
  const pageParams = useParamsControl<
    IBuffetReportRequest,
    keyof IBuffetReportRequest
  >({
    withPagination: true,
    whiteList: [
      'search',
      'archived',
      'ordering',
      'plan_menu_ids',
      'dish_ids',
      'category_ids',
      'dimension_date_gte',
      'dimension_date_lte',
      'dimension_temp_gte',
      'dimension_temp_lte',
      'dimension_type',
      'plan_menu_guest_type_ids',
    ],
  })

  const { data: buffetReportsList, isLoading: isLoadingGet } =
    useGetBuffetReportQuery(
      {
        ...pageParams,
        dimension_date_lte:
          pageParams?.dimension_date_lte || moment().format('YYYY-MM-DD'),
        dimension_date_gte:
          pageParams?.dimension_date_gte || moment().format('YYYY-MM-DD'),
        ordering: !!pageParams?.ordering
          ? pageParams?.ordering.replace(
              '-standard_temp_min,standard_temp_max',
              '-standard_temp_min,-standard_temp_max',
            )
          : undefined,
      },
      { skip: !pageParams },
    )

  const unloadReportHandler = useCallback(
    (fileType: fileTypeToExportEnum) => {
      setIsLoadingLocal(true)
      const params = createQueryString({
        ...pageParams,
        dimension_date_lte:
          pageParams?.dimension_date_lte || moment().format('YYYY-MM-DD'),
        dimension_date_gte:
          pageParams?.dimension_date_gte || moment().format('YYYY-MM-DD'),
        ordering: !!pageParams?.ordering
          ? pageParams?.ordering.replace(
              '-standard_temp_min,standard_temp_max',
              '-standard_temp_min,-standard_temp_max',
            )
          : undefined,
      })
      const getFileByEnum = (fileType: fileTypeToExportEnum) => {
        GetFile(`${BASE_URL}/report_buffet/${fileType}/${params}`)
          .then(() =>
            AppNotification({
              msg: t('Выбранные отчеты успешно выгружены!'),
              type: NotificationType.success,
            }),
          )
          .finally(() => setIsLoadingLocal(false))
      }
      switch (fileType) {
        case fileTypeToExportEnum.XLSX:
          getFileByEnum(fileTypeToExportEnum.XLSX)
          break
        case fileTypeToExportEnum.temperatureReport:
          getFileByEnum(fileTypeToExportEnum.temperatureReport)
          break
        case fileTypeToExportEnum.defectReport:
          getFileByEnum(fileTypeToExportEnum.defectReport)
      }
    },
    [pageParams],
  )

  const clearFilterHandler = useCallback(() => {
    setClearFilterField(true)
    setSearchParams((sp) => {
      const newSp = new URLSearchParams()
      sp.forEach((value, key) => {
        newSp.delete(key)
      })
      return newSp
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Выгрузить',
        btnIco: 'export',
        onClick: () => {},
        styleExtraDropdown: { position: 'absolute' },
        items: [
          {
            title: 'Таблица .XLSX',
            onClick: () => unloadReportHandler(fileTypeToExportEnum.XLSX),
            ico: 'file-xlsx',
          },
          {
            title: 'Температурный журнал',
            onClick: () =>
              unloadReportHandler(fileTypeToExportEnum.temperatureReport),
            ico: 'file-pdf',
          },
          {
            title: 'Бракеражный журнал',
            onClick: () =>
              unloadReportHandler(fileTypeToExportEnum.defectReport),
            ico: 'file-csv',
          },
        ],
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Сбросить фильтр',
        onClick: clearFilterHandler,
        color: AppBtnColor.extraVioletViolet,
      },
    ],
    [clearFilterHandler, unloadReportHandler],
  )

  const subDataComponentMobile = useCallback(
    (
      item: IBuffetReportDto,
      currentCol?: number,
      itemIndex?: number,
      onTouchStart?: ((e: React.TouchEvent) => void) | undefined,
      onTouchMove?: ((e: React.TouchEvent) => void) | undefined,
      width?: number,
    ) => {
      return (
        <React.Fragment>
          {item.dimensions.map((subItem, index) => {
            const touchParams = {
              onTouchStart: onTouchStart,
              onTouchMove: onTouchMove,
            }
            const data = [
              null,
              <td className={'report-page-table-cell'} {...touchParams}>
                {item?.category?.name}
              </td>,
              <td className={'report-page-table-cell'} {...touchParams}>
                {moment(subItem.dimensionDatetime).format('DD.MM.YYYY HH:mm')}
              </td>,
              <td className={'report-page-table-cell'} {...touchParams}>
                {subItem.orderLine?.order?.name ||
                  subItem.orderLine?.order?.sourceRepresent}
              </td>,
              <td className="sub-data report-page-table-cell" {...touchParams}>
                {subItem.orderLine.order?.planMenuGuestType?.name || ''}
              </td>,
              <td className="sub-data report-page-table-cell" {...touchParams}>
                {t(DimensionTranslateType[subItem.dimensionType])}
              </td>,
              <td className="sub-data report-page-table-cell" {...touchParams}>
                {subItem?.correspondentType?.name || ''}
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                {index + 1}
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                <div className={`text-end`}>
                  {item.standardWeight?.toFixed(3)}
                </div>
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                <div className={`text-end`}>
                  {subItem.orderLine.order.numberOfPersons}
                </div>
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                <div className="text-end">
                  {subItem.orderLine.order.numberOfPersonsFact +
                    subItem.orderLine.order.numberOfSold}
                </div>
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                <div className={`text-end`}>{subItem.quantity?.toFixed(3)}</div>
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                <div className={`text-end`}>
                  {(+subItem.orderLine.quantity)?.toFixed(3)}
                </div>
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                {(+subItem.orderLine.computedDeviation)?.toFixed(3)}
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                <AppTableNumberBlock
                  isTemp
                  value={item.standardTempMin ?? ''}
                />
                <span className="pl-4 pr-4"> - </span>
                <AppTableNumberBlock
                  isTemp
                  value={item.standardTempMax ?? ''}
                />
              </td>,
              <td
                className="sub-data report-page-table-cell text-end"
                {...touchParams}
              >
                <ReportTemp
                  maxTemp={item.maxTemperature}
                  minTemp={item.minTemperature}
                  maxStandardTemp={item.standardTempMax}
                  currentTemp={subItem.temperature}
                  avgTemp={item.avgTemperature}
                />
              </td>,
              <td className="sub-data report-page-table-cell" {...touchParams}>
                <ImagePreviewWrapper
                  className={'report-image-preview'}
                  onClick={() => {
                    setSearchParams((sp) => {
                      const newSearchParams = new URLSearchParams(sp)
                      newSearchParams.set(
                        'report-image-id',
                        `${subItem.id}-dimension`,
                      )
                      return newSearchParams.toString()
                    })
                  }}
                  isReportPageItem={true}
                  image={subItem.photoUrl || subItem?.photoUrlOnS3}
                  imgPreview={subItem.photoUrl || subItem?.photoUrlOnS3}
                  size={ImagePreviewWrapperSizeEnum.MEDIUM}
                />
              </td>,
            ]
            return (
              <tr
                key={`${subItem.id}-sub-${item.id}`}
                className={'sub-data-row'}
              >
                <td className={'report-page-table-cell'}>
                  <div className="table-sub-data-ico-block">
                    <i className="an-ico an-ico-corner-down-right" />
                  </div>
                </td>
                {currentCol
                  ? width && width < 600
                    ? data[currentCol]
                    : data
                        .slice(currentCol, currentCol + 3)
                        .map((item, index) => (
                          <React.Fragment key={`data-render-${index}`}>
                            {item}
                          </React.Fragment>
                        ))
                  : data.map((item, index) => (
                      <React.Fragment key={`data-render-${index}`}>
                        {item}
                      </React.Fragment>
                    ))}
              </tr>
            )
          })}
        </React.Fragment>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams],
  )

  if (!buffetReportsList && !!isLoadingGet) {
    return <AppLoader loaderType={LoaderType.main} />
  }

  const goBackString = searchParams.get('go_back_pl')
    ? `/${AllRoutes.planMenuClose.path}/?order=${searchParams.get('order')}`
    : undefined

  if (!buffetReportsList) {
    return null
  }

  return (
    <React.Fragment>
      {isLoadingLocal && <AppLoader loaderType={LoaderType.main} />}
      <ReportImagePopup data={buffetReportsList} />
      <div className="width-for-page-wrapper report-page">
        <AppTitlePage
          title="Отчеты"
          actions={actions}
          goBack={!!goBackString}
          goBackPath={goBackString}
          isFiltersOpen={isFiltersOpen}
          setIsFiltersOpen={setIsFiltersOpen}
        />
        {isFiltersOpen && (
          <div className="filter-block mt-20">
            <div className="full-width-mob">
              <label>{t('Период замеров')}</label>
              <div className="input-row mt-10">
                <AppFilterDateInput
                  searchParam={'dimension_date_gte'}
                  defaultValue={moment().format('YYYY-MM-DD')}
                  maxValue={
                    pageParams?.dimension_date_lte ||
                    moment().format('MM.DD.YYYY')
                  }
                />
                <AppFilterDateInput
                  searchParam={'dimension_date_lte'}
                  defaultValue={moment().format('YYYY-MM-DD')}
                  maxValue={moment().format('MM.DD.YYYY')}
                  minValue={pageParams?.dimension_date_gte}
                />
              </div>
            </div>
            <AppFilterMultiDropdownWithPagination<
              IBaseSimpleList,
              keyof IBaseSimpleList
            >
              searchParam={'plan_menu_ids'}
              getterData={useGetPlanMenuSimpleListQuery}
              propToShowInList={'name'}
              valuePropName={'id'}
              label={'План-меню'}
              placeholder={'Выбрать'}
            />
            <div className='temp-wrap'>
              <div className="tepm-input">
                <AppFilterInput
                  searchParam="dimension_temp_gte"
                  label="Факт. температура от, °C"
                  placeholder="0"
                  inputMask={InputMaskType.negativeInteger}
                  clearValue={clearFilterField}
                  setClearValue={setClearFilterField}
                />
              </div>
              <div className="tepm-input">
                <AppFilterInput
                  searchParam="dimension_temp_lte"
                  label="Факт. температура до, °C"
                  placeholder="0"
                  inputMask={InputMaskType.negativeInteger}
                  clearValue={clearFilterField}
                  setClearValue={setClearFilterField}
                />
              </div>
            </div>
          </div>
        )}
        {isFiltersOpen && (
          <div className="filter-block">
            <div className={`${width <= 1024 ? '' : 'width-502'}`}>
              <AppFilterMultiDropdownWithPagination<
                IBaseSimpleList,
                keyof IBaseSimpleList
              >
                searchParam={'dish_ids'}
                getterData={useGetBuffetDishSimpleListQuery}
                propToShowInList={'name'}
                valuePropName={'id'}
                label={'Названия блюд'}
                placeholder={'Выбрать'}
                fullWidth
              />
            </div>
            <AppFilterMultiDropdownWithPagination<ICategory, keyof ICategory>
              searchParam={'category_ids'}
              getterData={useGetCategoryQuery}
              propToShowInList={'name'}
              valuePropName={'id'}
              label={'Категория блюд'}
              placeholder={'Выбрать'}
            />
            <AppFilterDropdown
              label="Тип замера"
              placeholder="Выбрать"
              data={measurementTypeData}
              searchParam={'dimension_type'}
              propToShowInList={'name'}
              valuePropName={'value'}
              minHeight
            />
            <AppFilterMultiDropdownWithPagination<
              IBaseSimpleList,
              keyof IBaseSimpleList
            >
              searchParam={'plan_menu_guest_type_ids'}
              getterData={useGetGuestListTypeListQuery}
              propToShowInList={'name'}
              valuePropName={'id'}
              label={'Тип гостя'}
              placeholder={'Выбрать'}
            />
          </div>
        )}
        <AppTableMobile
          toggleAllState={toggleAllState}
          setToggleAllState={setToggleAllState}
          tableWrapperClassName={'report-page-table-wrap'}
          tableClassName={'report-page-table'}
          tableCellClassName={'report-page-table-cell'}
          rowClassName={'report-page-table-category'}
          data={
            !pageParams
              ? { results: [], count: 0, next: '', previous: '' }
              : buffetReportsList
          }
          headerData={headerData}
          subTableRepresent={subDataComponentMobile}
          tableDataSelectors={[
            {
              renderItem: (item) => (
                <span className={'report-page-table-category-title'}>
                  {item.name}
                </span>
              ),
            },
            {
              renderItem: (item) => <div>{item?.category?.name}</div>,
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>{item.dimensionCount}</div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item.standardWeightTotal?.toFixed(3)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>{item.numberOfPersonsSum}</div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item.numberOfPersonsFactSum + item.numberOfSoldSum}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item.isTotalWeightValid ? (
                    item.dimensionsQuantitySum?.toFixed(3)
                  ) : (
                    <ReportNumber
                      value={item.dimensionsQuantitySum?.toFixed(3) || 0}
                      color={ReportNumberType.red}
                    />
                  )}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item.orderLinesQuantitySum?.toFixed(3)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item.standardDeviationTotal?.toFixed(3)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  <React.Fragment>
                    <AppTableNumberBlock
                      isTemp
                      value={item.standardTempMin ?? ''}
                    />
                    <span className="pl-4 pr-4"> - </span>
                    <AppTableNumberBlock
                      isTemp
                      value={item.standardTempMax ?? ''}
                    />
                  </React.Fragment>
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end f-al-c-j-end`}>
                  <ReportTempView
                    value={item.minTemperature}
                    min={item.standardTempMin}
                    max={item.standardTempMax}
                  />{' '}
                  {item.avgTemperature !== null && (
                    <>
                      {'- '}
                      <ReportTempView
                        value={item.avgTemperature}
                        min={item.standardTempMin}
                        max={item.standardTempMax}
                      />
                    </>
                  )}
                  {item.maxTemperature !== null && (
                    <>
                      {'- '}
                      <ReportTempView
                        value={item.maxTemperature}
                        min={item.standardTempMin}
                        max={item.standardTempMax}
                      />
                    </>
                  )}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <ImagePreviewWrapper
                  className={'report-image-preview'}
                  onClick={() => {
                    setSearchParams((sp) => {
                      sp.set('report-image-id', item.id.toString())
                      return sp
                    })
                  }}
                  isReportPageItem={true}
                  image={item.image}
                  imgPreview={item.imagePreview}
                  size={ImagePreviewWrapperSizeEnum.MEDIUM}
                />
              ),
            },
          ]}
        />
      </div>
    </React.Fragment>
  )
}

interface IReportNumber {
  value: number | string
  color: ReportNumberType
}

interface IReportTempViewProps {
  value: number
  min: number
  max: number
}

export const ReportTempView = ({ value, min, max }: IReportTempViewProps) => {
  return isValidTemp(value, min, max) ? (
    <span>{value?.toFixed(2)}</span>
  ) : (
    <ReportNumber value={value?.toFixed(2)} color={ReportNumberType.red} />
  )
}

const ReportNumber = ({
  value,
  color = ReportNumberType.blue,
}: IReportNumber) => {
  return <div className={`report-number ${color}`}>{value}</div>
}

interface IReportTempProps {
  maxStandardTemp?: number
  minStandardTemp?: number
  maxTemp: number
  minTemp: number
  currentTemp: number
  avgTemp: number
}

const ReportTemp = ({
  currentTemp,
  minTemp,
  maxTemp,
  maxStandardTemp,
  minStandardTemp,
  avgTemp,
}: IReportTempProps) => {
  if (maxStandardTemp && currentTemp > maxStandardTemp)
    return <ReportNumber value={currentTemp} color={ReportNumberType.red} />
  if (minStandardTemp && currentTemp < minStandardTemp)
    return <ReportNumber value={currentTemp} color={ReportNumberType.red} />
  if (avgTemp === currentTemp) {
    return <div>{currentTemp}</div>
  }
  if (currentTemp === minTemp)
    return <ReportNumber value={currentTemp} color={ReportNumberType.orange} />
  if (currentTemp === maxTemp)
    return <ReportNumber value={currentTemp} color={ReportNumberType.blue} />
  return <div>{currentTemp}</div>
}
