import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useGetLimitParam } from './useGetLimitParam'
import { useGetOffsetParam } from './useGetOffsetParam'

interface IProps<T, TKey extends keyof T> {
  whiteList: (TKey | undefined)[]
}

interface IWithPagination<T, TKey extends keyof T> extends IProps<T, TKey> {
  withPagination: true
}

interface IWithOutPagination<T, TKey extends keyof T> extends IProps<T, TKey> {
  withPagination: false
}

type IUseParamsControl<T, TKey extends keyof T> =
  | IWithPagination<T, TKey>
  | IWithOutPagination<T, TKey>

export const useParamsControl = <
  T extends { limit?: number; offset?: number },
  TKey extends keyof T,
>({
  whiteList,
  withPagination,
}: IUseParamsControl<T, TKey>) => {
  const [searchParams] = useSearchParams()
  const search = searchParams.toString()
  const limit = useGetLimitParam()
  const offset = useGetOffsetParam()
  const [state, setState] = useState<T | null>(null)

  const whiteListWithPagination: (TKey | undefined)[] = withPagination
    ? [...whiteList, 'limit' as TKey, 'offset' as TKey]
    : whiteList

  useEffect(() => {
    const result = {} as {
      [key in TKey]?: string | number | boolean
    }

    whiteListWithPagination.forEach((param) => {
      if (param) {
        result[param] = !!searchParams.get(param as string)
          ? (searchParams.get(param as string) as string)
          : undefined
      }
    })

    const newState = Object.entries(result).reduce(
      (newPrams, [param, value]) =>
        value !== undefined ? { ...newPrams, [param]: value } : newPrams,
      {},
    ) as T
    if (withPagination && !!newState['limit' as TKey]) {
      setState(newState)
    } else if (withPagination && !newState['limit' as TKey]) {
      setState({ ...newState, limit: `${limit}`, offset: `${offset}` })
    } else {
      setState(newState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return state
}

/**
 * Пример использования:
 *
 * const params: ICamerasRequestDto = useParamsControl<    // Хук отдает объект параметров для запроса
 * ICamerasRequestDto,                                     // Явно указваем интерфейс запроса
 * keyof ICamerasRequestDto                                 // Вторым типом передаем keyof от основного интерфейса
 * >({
 * whiteList: ['disable_null', 'is_active'],                // Передаем массив параметров
 * withPagination: true,                                  // Указываем есть ли паггинация в запросе
 * })
 *
 * !!! Если нужно скинуть пагинацию при смене фильтра указываем =true в инпуте или дропдауне
 */
