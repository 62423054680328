import React, { useEffect, useMemo, useState } from 'react'
import { useParamsControl } from '../../../utils/useParamsControl'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import moment from 'moment'
import { useGetCompareReportQuery } from '../../../core/api/compareReportApi'
import {
  ICompareReportDto,
  ICompareReportRequest,
  PeriodsDifferenceData,
} from '../../../core/api/dto/CompareReportDto'
import { AppTableMobile } from '../../../components/AppTable/AppTableMobile'
import { ShowFormatType } from './BuffetComparativeReports'
import { useSearchParams } from 'react-router-dom'
import { createQueryString } from '../../../utils/createQueryString'
import { GetFile } from '../../../utils/getFile'
import { BASE_URL } from '../../../core/api/BaseApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import {
  DimensionTranslateType,
  DimensionType,
} from '../../../core/api/dto/BuffetReportDto'
import './BuffetComparativeReportsDetail.scss'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useTranslation } from 'react-i18next'

const headerData = [
  { title: 'Блюда', colWidth: '22%', sort: 'group' },
  { title: 'План кол-ва гостей', colWidth: '8%', sort: 'plannedPersonsAmount' },
  {
    title: '% План кол-ва гостей',
    colWidth: '8%',
    sort: 'plannedPersonsAmountPercentage',
  },
  {
    title: 'Факт кол-ва гостей',
    colWidth: '8%',
    sort: 'actualPersonsAmount',
  },
  {
    title: '% Факт кол-ва гостей',
    colWidth: '8%',
    sort: 'actualPersonsAmountPercentage',
  },
  { title: 'Разница гостей', colWidth: '8%' },
  { title: 'План вес', colWidth: '6%', sort: 'plannedWeight' },
  { title: '% План вес', colWidth: '6%', sort: 'plannedWeightPercentage' },
  { title: 'Факт вес', colWidth: '6%', sort: 'actualWeight' },
  { title: '% Факт вес', colWidth: '6%', sort: 'actualWeightPercentage' },
  { title: 'Разница вес, кг', colWidth: '8%' },
  {
    title: 'Вес на гостя, кг',
    colWidth: '8%',
    sort: 'weightForPerson',
  },
  {
    title: '% Вес на гостя, кг',
    colWidth: '8%',
    sort: 'weightForPersonPercentage',
  },
]

const showFormatHeader = {
  [ShowFormatType.dish]: 'Блюда',
  [ShowFormatType.category]: 'Категория',
  [ShowFormatType.guest_type]: 'Тип гостя',
  [ShowFormatType.dimension_type]: 'Тип замера',
  [ShowFormatType.plan_menu_type]: 'Тип план-меню',
}

const onSortHandler = (
  sortValue: null | string,
  compareReportList: ICompareReportDto[],
  total: ICompareReportDto,
  setCompareReportList: React.Dispatch<
    React.SetStateAction<ICompareReportDto[]>
  >,
) => {
  if (!sortValue) {
    setCompareReportList([total, ...compareReportList])
    return
  }
  let newArr: ICompareReportDto[] = []
  if (sortValue.includes('group')) {
    if (sortValue[0] !== '-') {
      newArr = [...compareReportList].sort((el1, el2) =>
        (el1[`${sortValue}`.toLowerCase() as keyof ICompareReportDto] || 0) >
        (el2[`${sortValue}`.toLowerCase() as keyof ICompareReportDto] || 0)
          ? 1
          : -1,
      )
    }
    if (sortValue[0] === '-') {
      newArr = [...compareReportList].sort((el1, el2) =>
        (`${
          el1[`${sortValue}`.toLowerCase().slice(1) as keyof ICompareReportDto]
        }`.toLowerCase() || 0) <
        (`${
          el2[`${sortValue}`.slice(1) as keyof ICompareReportDto]
        }`.toLowerCase() || 0)
          ? 1
          : -1,
      )
    }
  } else {
    if (sortValue[0] !== '-') {
      newArr = [...compareReportList].sort((el1, el2) =>
        (el1.periodsDifferenceData[
          `${sortValue}` as keyof PeriodsDifferenceData
        ] || 0) >
        (el2.periodsDifferenceData[
          `${sortValue}` as keyof PeriodsDifferenceData
        ] || 0)
          ? 1
          : -1,
      )
    }
    if (sortValue[0] === '-') {
      newArr = [...compareReportList].sort((el1, el2) =>
        (el1.periodsDifferenceData[
          `${sortValue}`.slice(1) as keyof PeriodsDifferenceData
        ] || 0) <
        (el2.periodsDifferenceData[
          `${sortValue}`.slice(1) as keyof PeriodsDifferenceData
        ] || 0)
          ? 1
          : -1,
      )
    }
  }
  setCompareReportList([total, ...newArr])
}

export const BuffetComparativeReportsDetail = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false)
  const [currentSortValue, setCurrentSortValue] = useState<null | string>(null)
  const [toggleAllState, setToggleAllState] = useState<number[]>([])
  const [compareReportList, setCompareReportList] = useState<
    ICompareReportDto[]
  >([])
  const pageParams = useParamsControl<
    ICompareReportRequest,
    keyof ICompareReportRequest
  >({
    withPagination: true,
    whiteList: [
      'group',
      'first_period_start',
      'first_period_end',
      'second_period_end',
      'second_period_start',
      'dish_ids',
      'category_ids',
      'guest_type_ids',
      'dimension_types',
      'plan_menu_type_ids',
    ],
  })
  const { data: compareReport } = useGetCompareReportQuery(
    { ...pageParams, offset: undefined },
    { skip: !pageParams },
  )

  const unloadDishesHandler = () => {
    setIsLoadingLocal(true)
    const params = createQueryString(pageParams)
    GetFile(`${BASE_URL}/compare_report/export_compare_report/${params}`)
      .then(() =>
        AppNotification({
          msg: 'Отчет успешно выгружен!',
          type: NotificationType.success,
        }),
      )
      .finally(() => setIsLoadingLocal(false))
  }

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Выгрузить',
        btnIco: 'export',
        items: [
          {
            title: 'Таблица .XLSX',
            onClick: unloadDishesHandler,
            ico: 'file-xlsx',
          },
          // { title: 'Журнал .PDF', onClick: () => {}, ico: 'file-pdf' },
          // { title: 'Экспортировать .CSV', onClick: () => {}, ico: 'file-csv' },
        ],
        color: AppBtnColor.whiteOutlineViolet,
      },
    ],
    [pageParams],
  )

  useEffect(() => {
    if (compareReport) {
      onSortHandler(
        currentSortValue,
        compareReport.results,
        compareReport.total,
        setCompareReportList,
      )
    }
  }, [compareReport, currentSortValue])

  const subTableRepresentFn = (
    item: ICompareReportDto,
    currentCol?: number,
    itemIndex?: number,
    onTouchStart?: ((e: React.TouchEvent) => void) | undefined,
    onTouchMove?: ((e: React.TouchEvent) => void) | undefined,
    width?: number,
  ) => {
    return (
      <React.Fragment>
        {[item.firstPeriodData, item.secondPeriodData].map((subItem, index) => {
          const moveFn = {
            onTouchStart: onTouchStart,
            onTouchMove: onTouchMove,
          }
          const data = [
            null,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>{subItem.plannedPersonsAmount}</div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>{subItem.actualPersonsAmount}</div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>
                {subItem.personsAmountDifference}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>
                {subItem.plannedWeight?.toFixed(3)}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>
                {subItem.actualWeight?.toFixed(3)}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>
                {subItem.weightDifference.toFixed(3)}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>
                {subItem.weightForPerson.toFixed(3)}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
          ]
          return (
            <tr
              key={`${index}-sub-${item.group}`}
              className={!itemIndex ? 'violet-sticky' : undefined}
              style={{ top: `${56 + 40 + index * 40}px` }}
            >
              <td className={'buffet-report-page-table-cell'}>
                <div className="table-sub-data-ico-block">
                  <div className="sub-data">
                    <i className="an-ico an-ico-corner-down-right" />
                    <span>{`${moment(
                      !index
                        ? pageParams?.first_period_start
                        : pageParams?.second_period_start,
                    ).format('DD.MM.YYYY')} - ${moment(
                      !index
                        ? pageParams?.first_period_end
                        : pageParams?.second_period_end,
                    ).format('DD.MM.YYYY')}`}</span>
                  </div>
                </div>
              </td>
              {currentCol
                ? width && width < 600
                  ? data[currentCol]
                  : data
                      .slice(currentCol, currentCol + 3)
                      .map((item, index) => (
                        <React.Fragment key={`data-render-${index}`}>
                          {item}
                        </React.Fragment>
                      ))
                : data.map((item, index) => (
                    <React.Fragment key={`data-render-${index}`}>
                      {item}
                    </React.Fragment>
                  ))}
            </tr>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <div className="width-for-page-wrapper buffet-comparative-report-detail">
      {isLoadingLocal && <AppLoader loaderType={LoaderType.main} />}
      <AppTitlePage
        title={`${moment(pageParams?.first_period_start).format(
          'DD.MM.YYYY',
        )} - ${moment(pageParams?.first_period_end).format(
          'DD.MM.YYYY',
        )},</br> ${moment(pageParams?.second_period_start).format(
          'DD.MM.YYYY',
        )} - ${moment(pageParams?.second_period_end).format(
          'DD.MM.YYYY',
        )};</br> ${t(showFormatHeader[pageParams?.group as ShowFormatType])}`}
        actions={compareReport ? actions : undefined}
        goBack
        goBackPath={`/${
          AllRoutes.buffetComparativeReports.path
        }/?${searchParams.toString()}`}
      />

      {compareReport && (
        <AppTableMobile<ICompareReportDto, keyof ICompareReportDto>
          toggleAllState={toggleAllState}
          setToggleAllState={setToggleAllState}
          data={{
            count: 0,
            results: compareReportList,
            previous: '',
            next: '',
          }}
          tableWrapperClassName={'buffet-report-table'}
          tableCellClassName={'buffet-report-page-table-cell'}
          rowClassName={'buffet-report-page-table-category'}
          setCurrentSortFn={setCurrentSortValue}
          tableDataSelectors={[
            {
              renderItem: (item, index) => (
                <div
                  className={
                    !index
                      ? 'violet-sticky'
                      : 'buffet-report-page-table-category-title'
                  }
                >
                  {item?.group
                    .replace('total', t('Всего'))
                    .replace(
                      DimensionType.Serving,
                      t(DimensionTranslateType[DimensionType.Serving]),
                    )
                    .replace(
                      DimensionType.Write_off,
                      t(DimensionTranslateType[DimensionType.Write_off]),
                    )
                    .replace(
                      DimensionType.Refund,
                      t(DimensionTranslateType[DimensionType.Refund]),
                    )
                    .replace('None', t('Не указано')) || ''}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.periodsDifferenceData.plannedPersonsAmount}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {
                    +item?.periodsDifferenceData.plannedPersonsAmountPercentage?.toFixed(
                      1,
                    )
                  }
                  %
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.periodsDifferenceData.actualPersonsAmount}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {
                    +item?.periodsDifferenceData.actualPersonsAmountPercentage.toFixed(
                      1,
                    )
                  }
                  %
                </div>
              ),
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.periodsDifferenceData.plannedWeight?.toFixed(3) || 0}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {+item?.periodsDifferenceData.plannedWeightPercentage?.toFixed(
                    1,
                  ) || 0}
                  %
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.periodsDifferenceData.actualWeight?.toFixed(3) || 0}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {+item?.periodsDifferenceData.actualWeightPercentage?.toFixed(
                    1,
                  ) ||
                    0 ||
                    '0'}
                  %
                </div>
              ),
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.periodsDifferenceData.weightForPerson.toFixed(3) || 0}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {
                    +item?.periodsDifferenceData.weightForPersonPercentage.toFixed(
                      1,
                    )
                  }
                  %
                </div>
              ),
            },
          ]}
          subTableRepresent={subTableRepresentFn}
          headerData={headerData.map((item, index) =>
            !index && !!pageParams?.group
              ? {
                  ...item,
                  title: t(
                    showFormatHeader[pageParams?.group! as ShowFormatType],
                  ),
                }
              : item,
          )}
        />
      )}
    </div>
  )
}
