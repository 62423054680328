import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import KeyboardWrapper, { Lang } from '../keyboardWrapper/KeyboardWrapper'
import { useAppDispatch, useAppSelector } from '../../core/store/store'
import moment from 'moment'
import { AppDatePicker } from './AppDatePicker'
import { AppTimePicker } from './AppTimePicker'
import { useTimePickerValidValue } from '../../utils/useTimePickerValidValue'
import { AppScrollTextareaWrapper } from '../../widgets/AppScrollWrapper/AppScrollWrapper'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'
import { setIsKeyboard } from 'core/store/keyboard/keyboardSlice'
import { KeyboardReactInterface } from 'react-simple-keyboard'

export enum IcoPosition {
  left = 'left',
  right = 'right',
}

export enum InputMaskType {
  float,
  integer,
  negativeInteger,
}

export enum TextareaInputRowsType {
  'rows-1' = 'rows-ta-1',
  'rows-2' = 'rows-ta-2',
  'rows-3' = 'rows-ta-3',
  'rows-4' = 'rows-ta-4',
  'rows-5' = 'rows-ta-5',
  'rows-full' = 'rows-full',
}

interface IAppInputBase {
  label?: string
  ico?: string
  icoPosition?: IcoPosition
  icoAction?: () => void
  onBlur?: () => void
  placeholder?: string
  type?: string
  id?: string
  value?: string | null
  onChange: (value: string) => void
  onEnterCb?: () => void
  message?: string | null
  error?: string | null
  inputMask?: InputMaskType
  disabled?: boolean
  border?: boolean
  required?: boolean
  fullWidth?: boolean
  maxLength?: number
  clear?: boolean
  timeWithList?: boolean
  minValue?: string
  maxValue?: string
  keyDirectionFn?:
    | null
    | ((value: React.KeyboardEvent<HTMLInputElement>) => void) // использует исключительно для определения нажатия вверх или вниз на клавиатуре
}

interface IAppInput extends IAppInputBase {
  rows?: never
  onClick?: never
  dropdownInput?: never
  dropdownActive?: never
}

interface IAppInputTextarea extends IAppInputBase {
  rows: TextareaInputRowsType
  onClick?: never
  dropdownInput?: never
  dropdownActive?: never
}

interface IAppInputDropdown extends IAppInputBase {
  rows?: never
  onClick: () => void
  dropdownInput: boolean
  dropdownActive: boolean
}

type AppInputType = IAppInputTextarea | IAppInputDropdown | IAppInput

function formatTime(value: string) {
  // форматируем введенное значение в правильный формат
  const time = value.padStart(4, '0')
  const hours = time.slice(0, 2)
  const minutes = time.slice(2, 4)
  return `${hours}:${minutes}`
}

export const AppInput = React.forwardRef(
  (
    {
      label,
      type = 'text',
      value,
      onChange,
      onClick,
      dropdownInput,
      dropdownActive,
      error,
      placeholder,
      inputMask,
      disabled = false,
      keyDirectionFn = null,
      message,
      border,
      fullWidth,
      ico,
      icoPosition = IcoPosition.left,
      icoAction,
      required,
      maxLength,
      clear,
      timeWithList,
      minValue,
      maxValue,
      onBlur,
      rows,
      id,
      onEnterCb,
    }: AppInputType,
    inputRef: React.Ref<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { keyboardMode } = useAppSelector((state) => state.options)
    const wrapperProps = onClick ? { onClick } : {}
    const keyboard = useRef<KeyboardReactInterface | null>(null)
    const inputWrapRef = useRef<HTMLDivElement | null>(null)
    const [isCalendarOpen, setCalendarOpen] = useState(false)
    const [isTimePickerOpen, setTimePickerOpen] = useState(false)
    const [keyboardVisibility, setKeyboardVisibility] = useState(false)
    const [hourValueValidFn, minValueValidFn, onBlurHandler] =
      useTimePickerValidValue({ minValue, maxValue, value, onChange })
    const textareaLocalRef = useRef<null | HTMLTextAreaElement>(null)
    const [currentTextareaRef, setCurrentTextareaRef] =
      useState<React.MutableRefObject<
        HTMLDivElement | HTMLTextAreaElement | null
      > | null>(null)
    useEffect(() => {
      if (textareaLocalRef) {
        setCurrentTextareaRef(textareaLocalRef)
      }
    }, [textareaLocalRef, textareaLocalRef?.current?.scrollHeight])
    const [focus, setFocus] = useState(false)
    const [hover, setHover] = useState(false)
    const { width } = useGetWidth()

    const onChangeHandler = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      let result = ''
      if (type === 'date') {
        if (e.target.value.length > 10) {
          return
        }
        const value = moment(e.target.value).toISOString()
        onChange(value ? value : '')
        return
      }
      switch (inputMask) {
        case InputMaskType.float:
          const floatVal = e.target.value
            .replace(/[,]+/g, '.')
            .replace(/^\.+/g, '')
            .replace(/^(0)([0-9])+/g, '$2')
            .match(/(^[0-9]*(\.)?[0-9]{0,3})*/g)
          result = !!floatVal ? floatVal[0] : ''
          break
        case InputMaskType.integer:
          const integerVal = e.currentTarget.value
            .replace(/\D/g, '')
            .replace(/^(0)([0-9])+/g, '$2')
          result = integerVal
          break
        case InputMaskType.negativeInteger:
          const factor =
            e.target.value.length > 1 && e.target.value[0] === '-' ? -1 : 1
          const isOnlyMinus = e.target.value === '-'
          const integerNegative = e.target.value
            .replace(/\D/g, '')
            .replace(/^(0)([0-9])+/g, '$2')
          result = isOnlyMinus
            ? '-'
            : !!integerNegative
            ? `${Number(integerNegative) * factor}`
            : ''
          break
        default:
          result = e.currentTarget.value
      }
      return onChange(result)
    }

    const keyDownPropsWrapper = useMemo(() => {
      return keyDirectionFn
        ? {
            onKeyDown: keyDirectionFn,
          }
        : {}
    }, [keyDirectionFn])

    const [clearedTime, setClearedTime] = useState(false)

    const onChangeKeyboard = (valueKey: string) => {
      if (!valueKey && !!value) {
        return
      }
      value && keyboard.current?.setInput(value)
      const currentValue = (value || '') + (valueKey.slice(-4) === '.com' ? valueKey.slice(-4) : valueKey.slice(-1))
      if (type === 'time') {
        if(valueKey.length >= 4) {
          onChange('')
          keyboard.current!.clearInput()
          setClearedTime(true)
        }
        let curValue = valueKey
        if(clearedTime) curValue = valueKey.split('').reverse().join('')
        const inputTime = formatTime(curValue)

        const maxTime = '23:59'
        const minTime = '00:00'
        if (inputTime < minTime) {
          onChange(minTime)
        } else if (inputTime > maxTime) {
          onChange(maxTime)
        } else {
          onChange(inputTime)
        }
        return
      }
      let result: string
      switch (inputMask) {
        case InputMaskType.float:
          const floatVal = currentValue
            .replace(/[,]+/g, '.')
            .replace(/^\.+/g, '')
            .replace(/^(0)([0-9])+/g, '$2')
            .match(/(^[0-9]*(\.)?[0-9]{0,3})*/g)
          result = !!floatVal ? floatVal[0] : ''
          break
        case InputMaskType.integer:
          const integerVal = currentValue
            .replace(/\D/g, '')
            .replace(/^(0)([0-9])+/g, '$2')
          result = integerVal
          break
        case InputMaskType.negativeInteger:
          const factor =
            currentValue.length > 1 && currentValue[0] === '-' ? -1 : 1
          const isOnlyMinus = currentValue === '-'
          const integerNegative = currentValue
            .replace(/\D/g, '')
            .replace(/^(0)([0-9])+/g, '$2')
          result = isOnlyMinus
            ? '-'
            : !!integerNegative
            ? `${Number(integerNegative) * factor}`
            : ''
          break
        default:
          result = currentValue
      }
      onChange(result);
      keyboard?.current?.clearInput()
      return
    }

    const showKeyboard = useCallback(() => {
      setFocus(true)
      if (keyboardMode) {
        if(type === 'date'){
          setCalendarOpen(true)
        } else if(type === 'time') {
          setTimePickerOpen(true)
        } else {
          setKeyboardVisibility(true)
          dispatch(setIsKeyboard({isKeyboard: true}))
        }
      } else if (!keyboardMode && keyboardVisibility) {
        setKeyboardVisibility(false)
        dispatch(setIsKeyboard({isKeyboard: false}))
      }
    }, [keyboardMode, keyboardVisibility, setKeyboardVisibility])

    const outSideClickHandler = (e: any) => {
      e.stopPropagation()
      if (inputWrapRef.current && !inputWrapRef.current.contains(e.target)) {
        setKeyboardVisibility(false)
        dispatch(setIsKeyboard({isKeyboard: false}))
      }
    }

    const clearActionHandler = (e: React.MouseEvent) => {
      e.stopPropagation()
      onChange('')
    }

    useEffect(() => {
      if (keyboardVisibility) {
        document.addEventListener('click', outSideClickHandler, false)
        //document.addEventListener('touchend', outSideClickHandler, false)
      }
      return () => {
        if (keyboardVisibility) {
          document.removeEventListener('click', outSideClickHandler, false)
          //document.removeEventListener('touchend', outSideClickHandler, false)
        }
      }
    }, [keyboardVisibility])

    return (
      <React.Fragment>
        <div
          className={`app-input-wrap ${
            type === 'date' ? 'date-mode' : type === 'time' ? 'time-mode' : ''
          }`}
          style={fullWidth ? { width: '100%' } : undefined}
          {...wrapperProps}
          ref={inputWrapRef}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {label && (
            <label>
              {t(label)}
              {required && <span className="color-Red"> *</span>}
            </label>
          )}
          <div
            className={`app-input without-timepicker ${
              error ? 'border-error' : ''
            } ${dropdownActive ? 'active' : ''}`}
          >
            {ico && (
              <i
                className={`an-ico ${(focus || hover) ? 'ico-focus' : ''} an-ico-${ico} ${
                  icoPosition === IcoPosition.right ? 'input-ico-right' : ''
                } ${!!icoAction ? 'c-pointer' : ''}`}
                onClick={!!icoAction ? icoAction : undefined}
              />
            )}
            {type === 'date' && !disabled && (
              <i
                className={`an-ico ${
                  focus || isCalendarOpen || hover ? 'ico-focus' : ''
                } an-ico-calendar input-ico-right c-pointer`}
                onClick={() => setCalendarOpen((prev) => !prev)}
              />
            )}
            {timeWithList && type === 'time' && (
              <i
                className={`an-ico ${
                  focus || isTimePickerOpen || hover ? 'ico-focus' : ''
                } an-ico-watch input-ico-right c-pointer`}
                tabIndex={1}
                onClick={() => setTimePickerOpen!((prev) => !prev)}
              />
            )}
            {clear && !!value?.length && (
              <i
                className={`an-ico ${
                  focus ? 'ico-focus' : ''
                } an-ico-close input-ico-right c-pointer`}
                onClick={clearActionHandler}
              />
            )}
            {!!dropdownInput && (
              <i
                className={`an-ico an-ico-arrow-down ico-dropdown ${
                  dropdownActive ? 'active' : ''
                } ${dropdownActive || hover ? 'ico-focus' : ''}`}
              />
            )}
            {type === 'textarea' ? (
              <AppScrollTextareaWrapper
                childrenRef={currentTextareaRef}
                showIn={'textarea'}
                fullWidth
              >
                <textarea
                  value={!!value ? value : ''}
                  placeholder={t(placeholder || '')}
                  onChange={onChangeHandler}
                  disabled={disabled}
                  className={`textarea ${rows}`}
                  ref={textareaLocalRef}
                  maxLength={maxLength || 150}
                  id={id ? `i-${id}` : undefined}
                  onFocus={showKeyboard}
                />
              </AppScrollTextareaWrapper>
            ) : (
              <input
                value={
                  !!value
                    ? type === 'date'
                      ? moment(value).format('YYYY-MM-DD')
                      : value
                    : ''
                }
                placeholder={t(placeholder || '')}
                onChange={onChangeHandler}
                {...keyDownPropsWrapper}
                disabled={disabled}
                type={type}
                id={id ? `i-${id}` : undefined}
                className={`app-input-base ${border ? 'border' : ''} ${
                  focus || dropdownActive || isCalendarOpen || isTimePickerOpen
                    ? 'focus'
                    : ''
                } ${
                  !!ico
                    ? icoPosition === IcoPosition.left
                      ? 'p-input-left'
                      : 'p-input-right'
                    : ''
                }${clear || dropdownInput ? ' p-input-right' : ''} ${
                  !!error ? 'error-border-color' : ''
                } ${width <=1024 ? 'mob-disabled' : ''}`}
                style={fullWidth ? { width: '100%' } : undefined}
                onFocus={showKeyboard}
                maxLength={maxLength || 150}
                onBlur={() => {
                  setFocus(false)
                  if (type === 'time') {
                    onBlurHandler()
                  } else if (onBlur) {
                    onBlur()
                  }
                }}
                ref={inputRef}
              />
            )}
            {type === 'date' && isCalendarOpen && (
              <AppDatePicker
                onClose={() => setCalendarOpen(false)}
                inputRef={inputWrapRef}
                onChange={(value) =>
                  onChange(value ? `${moment(value).toISOString()}` : '')
                }
                value={value ? new Date(value) : null}
                maxDate={maxValue ? new Date(maxValue) : undefined}
                minDate={minValue ? new Date(minValue) : undefined}
              />
            )}
            {type === 'time' && isTimePickerOpen && (
              <AppTimePicker
                value={value}
                onChange={onChange}
                onClose={() => setTimePickerOpen(false)}
                hourValueValidFn={hourValueValidFn}
                minValueValidFn={minValueValidFn}
              />
            )}
            {error && <div className={'app-input-error'}>{t(error)}</div>}
            {message && !error && (
              <div className={'app-input-message'}>{t(message)}</div>
            )}
          </div>
          <div
            className={`keyboard-wrap${
              keyboardMode && keyboardVisibility ? ' active' : ''
            }`}
          >
            <div
              className="close-keyboard-block"
              onClick={() => {
                setKeyboardVisibility(false)
                dispatch(setIsKeyboard({isKeyboard: false}))
              }}
            >
              <i className="an-ico an-ico-close" />
            </div>
            <KeyboardWrapper
              onChange={onChangeKeyboard}
              onChangeInputValue={onChange}
              handlerKeyEnter={onEnterCb}
              value={value}
              keyboard={keyboard}
              lang={Lang.RU}
              inputName={`input-${id || ''}`}
            />
          </div>
        </div>
      </React.Fragment>
    )
  },
)
