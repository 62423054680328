import { AppTableLink } from '../../components/AppTableLink'
import { useTranslation } from 'react-i18next'

interface IAppTableStatusStation {
  isDelete: boolean
  id: number
  station?: boolean
}

export const AppTableStatusStation = ({
  isDelete,
  id,
  station,
}: IAppTableStatusStation) => {
  const { t } = useTranslation()
  return isDelete ? (
    <div className={`table-status delete`}>
      {t(station ? 'Неактивный' : 'Удалённый')}
    </div>
  ) : (
    <AppTableLink path={`${id}`} deleteItem={isDelete}>
      <div className={`table-status`}>{t('Активный')}</div>
    </AppTableLink>
  )
}
