import React from 'react'

interface IPropsAmountItem {
  title: string
  value: number
}

const AmountItem = ({ title, value }: IPropsAmountItem) => {
  return (
    <div className="amount">
      <span className="title">{title}</span>
      <span className={`value ${value <= 0 ? 'color-Red' : ''}`}>
        {(+value).toFixed(3)}кг
      </span>
    </div>
  )
}

export default AmountItem
