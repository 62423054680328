import React, { useEffect, useState } from 'react'
import { useLazyGetPredictionDataQuery } from '../../../../core/api/BuffetDishApi'
import { DishItem } from './DishItem'
import { useTranslation } from 'react-i18next'
import {
  IBuffetDishWithImplInfoDto,
  IPredictDataDto,
} from '../../../../core/api/dto/BuffetDishDto'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import { PredictionDishPopup } from './PredictionDishPopup/PredictionDishPopup'
import { useSearchParams } from 'react-router-dom'
import './PredictionDishPopup/PredictionDishPopup.scss'
import { handleBase64Image } from '../../../../utils/base64ToBlob'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { ModalMobile } from '../../../../shared/ui/ModalMobile/ModalMobile'
import { useGetCurrentWeightQuery } from '../../../../core/api/OrderApi'

const useStableWeight = (
  status: QueryStatus,
  stableMode: boolean,
  setStableMode: (value: boolean) => void,
  weight: number,
  setWeight: (value: number) => void,
  setPredictionDataHandler: (clear?: boolean) => void,
  currentWeight?: number,
) => {
  const [stableCount, setStableCount] = useState(0)

  useEffect(() => {
    if (!stableMode || status !== 'fulfilled' || currentWeight === undefined) {
      return
    }

    if (currentWeight <= 0.002) {
      setWeight(0)
      setStableMode(false)
      setStableCount(0)
      setPredictionDataHandler(true)
    }

    if (isStableWeight(currentWeight, weight)) {
      setStableCount((prev) => prev + 1)
    } else {
      setStableCount(0)
      setWeight(currentWeight)
    }

    if (stableCount >= 1 && isStableWeight(currentWeight, weight)) {
      setPredictionDataHandler()
      setStableCount(0)
      setStableMode(false)
    }
  }, [stableMode, status])
}

interface PredictionDataProps {
  isIds: boolean
}

const isNewWeight = (newWeight: number, oldWeight: number) => {
  if (!newWeight) return false
  return newWeight >= oldWeight + 0.005 || newWeight <= oldWeight - 0.005
}
const isStableWeight = (newWeight: number, oldWeight: number) => {
  if (!newWeight) return false
  return Number(Math.abs(newWeight - oldWeight).toFixed(2)) <= 0.01
}

export const PredictionData = ({ isIds }: PredictionDataProps) => {
  const { t } = useTranslation()
  const [isOpenPopup, setIsOpenPopup] =
    useState<IBuffetDishWithImplInfoDto | null>(null)
  const [predictionData, setPredictionData] = useState<IPredictDataDto | null>(
    null,
  )
  const [predictionPopup, setPredictionPopup] = useState(true)
  const [weight, setWeight] = useState(0)
  const [stableMode, setStableMode] = useState(false)
  const { data: currentWeight, status } = useGetCurrentWeightQuery(null, {
    pollingInterval: 500,
  })

  const [getPredictionData, { isError }] = useLazyGetPredictionDataQuery()
  const [searchParams] = useSearchParams()
  const orderIds = searchParams.get('ids')?.split(',')
  const onOpenPopup = (dish: IBuffetDishWithImplInfoDto) => {
    setIsOpenPopup(dish)
  }
  const onClosePopup = () => setIsOpenPopup(null)

  const setPredictionDataHandler = (clear?: boolean) => {
    if (clear) {
      setPredictionData(null)
    } else {
      getPredictionData()
        .unwrap()
        .then((data) => setPredictionData(data))
        .catch(() => setPredictionData(null))
    }
  }

  useStableWeight(
    status,
    stableMode,
    setStableMode,
    weight,
    setWeight,
    setPredictionDataHandler,
    currentWeight?.weight,
  )

  useEffect(() => {
    if (
      currentWeight &&
      isNewWeight(currentWeight?.weight, weight) &&
      !stableMode
    ) {
      setStableMode(true)
      setWeight(currentWeight?.weight)
    }
    if (
      (!currentWeight?.weight || currentWeight?.weight <= 0.002) &&
      !stableMode
    ) {
      setWeight(0)
    }
    // eslint-disable-next-line
  }, [currentWeight, stableMode])

  useEffect(() => {
    if (
      ((isError || currentWeight?.weight === 0.0) && isOpenPopup) ||
      (isOpenPopup &&
        !predictionData?.results?.find(
          (item) => item.dish.id === isOpenPopup.id,
        ))
    ) {
      setIsOpenPopup(null)
    }
  }, [isError, predictionData])

  if (
    !!predictionData &&
    isIds &&
    Number(currentWeight?.weight || '0') >= 0.005
  ) {
    return (
      <>
        {isOpenPopup && (
          <PredictionDishPopup
            dish={isOpenPopup}
            onClosePopup={onClosePopup}
            imageFromCamera={
              predictionData?.image
                ? URL.createObjectURL(
                    handleBase64Image(
                      predictionData?.image,
                      'фото с фотокассы',
                    ),
                  )
                : ''
            }
            orderIds={orderIds}
          />
        )}
        <ModalMobile
          onToggle={() => setPredictionPopup((prev) => !prev)}
          onClose={() => {}}
          isOpen={predictionPopup}
        >
          <div className="mt-15 mb-20">
            <div className="title-wrap">
              <div className="title-ico">
                <i className="an-ico an-ico-stars" />
                <span>{t('Блюдо распознанное ИИ помощником')}</span>
              </div>
              <div
                className="ico-block"
                onClick={() => setPredictionPopup(false)}
              >
                <i className="an-ico an-ico-close" />
              </div>
            </div>
            <div className="plan-menu-control-manager-page auto-height ai-line mt-10">
              <AppScrollWrapperNew>
                {predictionData?.results.map((dish) => (
                  <DishItem
                    key={`${dish.dish.id}`}
                    dish={dish.dish as IBuffetDishWithImplInfoDto}
                    onOpenPopup={onOpenPopup}
                  />
                ))}
              </AppScrollWrapperNew>
            </div>
          </div>
        </ModalMobile>
      </>
    )
  }

  return null
}
