import React, { useCallback, useEffect, useRef, useState } from 'react'
import InputField, {
  IconPosition,
  InputSize,
} from '../../../components/input/InputField'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import Spinner from '../../../components/spinner/Spinner'
import KeyboardWrapper, {
  Lang,
} from '../../../components/keyboardWrapper/KeyboardWrapper'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { setCredentials } from '../../../core/store/auth/authSlice'
import {
  useLoginGetCodeMutation,
  useLoginSendCodeMutation,
} from '../../../core/api/BaseApi'
import { useAppDispatch } from '../../../core/store/store'
import { useTranslation } from 'react-i18next'
import { setIsKeyboard } from 'core/store/keyboard/keyboardSlice'

const initFormState = {
  email: '',
  code: '',
}

const authFormObj = {
  email: {
    btnText: 'Получить пароль',
  },
  code: {
    btnText: 'Войти',
    btnTextBack: 'Назад',
  },
}

interface LoginByEmailProps {
  isKeyboard: boolean
}

export const LoginByEmail = ({ isKeyboard }: LoginByEmailProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [keyboardVisibility, setKeyboardVisibility] = useState(false)
  const [formState, setFormState] = useState(initFormState)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [activePage, setActivePage] =
    useState<keyof typeof authFormObj>('email')
  const keyboard = useRef<any>(null)
  const inputWrapRef = useRef<HTMLDivElement | null>(null)
  const wrapKeyboardRef = useRef<HTMLDivElement | null>(null)

  const [send, { isLoading }] = useLoginGetCodeMutation()
  const [signIn, { isLoading: isLoadingCode }] = useLoginSendCodeMutation()

  const changeActivePage = () => {
    setActivePage('email')
    setFormState(initFormState)
    if (keyboardVisibility) {
      keyboard.current.clearInput('email')
      keyboard.current.clearInput('code')
    }
  }
  const inputHandler = (value: string, fieldName: string) => {
    setFormState((prev) => ({
      ...prev,
      [fieldName]: value,
    }))
  }
  const authHandler = (repeatCode?: boolean) => {
    if ((activePage === 'email' || repeatCode) && !isLoading) {
      send({ email: formState.email })
        .unwrap()
        .then((payload) => {
          AppNotification({
            msg: `${payload}`.replace('Email отправлен', t('Email отправлен')),
            type: NotificationType.info,
          })
          setActivePage('code')
        })
        .catch((e) => {
          console.log(e)
        })
    } else if (activePage === 'code' && !isLoadingCode) {
      signIn({
        code: formState.code,
        email: formState.email,
      })
        .unwrap()
        .then((response) => {
          dispatch(setCredentials({ token: response.token }))
          setFormState(initFormState)
        })
        .catch(() => {
          inputHandler('', 'verificationCode')
        })
    }
  }

  const onChangeField = useCallback(
    (val: string) => {
      inputHandler(val.trim(), activePage)
      if (isKeyboard) {
        keyboard.current.setInput(val.trim())
      }
    },
    [activePage, isKeyboard],
  )

  const onChangeKeyboard = (val: string) => inputHandler(val.trim(), activePage)

  const showKeyboard = useCallback(() => {
    if (isKeyboard) {
      setKeyboardVisibility(true)
      dispatch(setIsKeyboard({isKeyboard: true}))
    } else if (!isKeyboard && keyboardVisibility) {
      setKeyboardVisibility(false)
      dispatch(setIsKeyboard({isKeyboard: false}))
    }
  }, [isKeyboard, keyboardVisibility, setKeyboardVisibility])

  const outSideClickHandler = (e: any) => {
    e.stopPropagation()
    if (inputWrapRef.current && !inputWrapRef.current.contains(e.target) && wrapKeyboardRef.current && !wrapKeyboardRef.current.contains(e.target)) {
      setKeyboardVisibility(false)
      dispatch(setIsKeyboard({isKeyboard: false}))
    }
  }

  useEffect(() => {
    if (keyboardVisibility) {
      document.addEventListener('click', outSideClickHandler, false)
      //document.addEventListener('touchend', outSideClickHandler, false)
    }
    return () => {
      if (keyboardVisibility) {
        document.removeEventListener('click', outSideClickHandler, false)
        //document.removeEventListener('touchend', outSideClickHandler, false)
      }
    }
  }, [keyboardVisibility])

  return (
    <>
      <div className="form">
        {activePage === 'email' && (
          <div ref={inputWrapRef}>
            <InputField
              placeholder="Email"
              sized={InputSize.lg}
              value={formState.email}
              onChange={onChangeField}
              required={true}
              onPressEnter={authHandler}
              onFocus={showKeyboard}
              iconName="email"
            />
          </div>
        )}
        {activePage === 'code' && (
          <React.Fragment>
            <div ref={inputWrapRef}>
              <InputField
                placeholder="Code"
                sized={InputSize.lg}
                type={isShowPassword ? 'text' : 'password'}
                iconPosition={IconPosition.right}
                iconName={!isShowPassword ? 'blind' : 'eye'}
                value={formState.code}
                onClick={() => setIsShowPassword(!isShowPassword)}
                onChange={onChangeField}
                required={true}
                onPressEnter={() => authHandler()}
                onFocus={showKeyboard}
              />
            </div>
            <div className="code-description-block">
              <div>
                <span>{t('Код отправлен на email:')}</span> {formState.email}
              </div>
              <div>
                <span>{t('Не пришёл код?')}</span>{' '}
                <span className="link-code" onClick={() => authHandler(true)}>
                  {t('Отправить код повторно')}
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="btn-block">
          {activePage === 'code' && (
            <AppBtn
              title={authFormObj[activePage]!.btnTextBack}
              sized={AppBtnSize.lg}
              color={AppBtnColor.outlineWhite}
              onClick={changeActivePage}
            />
          )}
          <AppBtn
            sized={AppBtnSize.lg}
            color={AppBtnColor.outlineWhite}
            onClick={() => authHandler()}
          >
            {isLoading || isLoadingCode ? (
              <Spinner />
            ) : (
              <span>{t(authFormObj[activePage]!.btnText)}</span>
            )}
          </AppBtn>
        </div>
      </div>
      {keyboardVisibility && isKeyboard && (
        <React.Fragment>
          <div
            className={`keyboard-wrap${keyboardVisibility ? ' active' : ''}`}
            ref={wrapKeyboardRef}
          >
            <div
              className="close-keyboard-wrap-auth"
            >
              <div
                className="close-keyboard-block"
                onClick={() => {
                  setKeyboardVisibility(false)
                  dispatch(setIsKeyboard({isKeyboard: false}))
                }}
              >
                <i className="an-ico an-ico-close" />
              </div>
            </div>
            <KeyboardWrapper
              handlerKeyEnter={() => authHandler()}
              value={activePage === 'email' ? formState.email : formState.code}
              onChangeInputValue={onChangeField}
              onChange={onChangeKeyboard}
              lang={Lang.RU}
              inputName={activePage}
              keyboard={keyboard}
            />
          </div>
        </React.Fragment>
      )}
    </>
  )
}
