import React, { useEffect, useState } from 'react'
import Popup, { PopupSize } from '../../../components/popup/Popup'
import { AppInput } from '../../../components/AppInput/AppInput'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import {
  useCreatePlanMenuTypeMutation,
  useGetPlanMenuTypeDetailQuery,
  useUpdatePlanMenuTypeMutation,
} from '../../../core/api/PlanMenuTypeApi'
import { useTranslation } from 'react-i18next'

interface IAddPlanMenuTypePopupProps {
  id?: number | null
  closePopup: () => void
}

export const AddPlanMenuTypePopup = ({
  closePopup,
  id,
}: IAddPlanMenuTypePopupProps) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [error, setError] = useState('')
  const { data: currentPlanMenuType } = useGetPlanMenuTypeDetailQuery(
    { id: Number(id) },
    {
      skip: !id,
    },
  )
  const [createPlanMenuType, { isLoading: isLoadingCreate }] =
    useCreatePlanMenuTypeMutation()
  const [updatePlanMenuType, { isLoading: isLoadingUpdate }] =
    useUpdatePlanMenuTypeMutation()

  useEffect(() => {
    if (!!currentPlanMenuType) {
      setTitle(currentPlanMenuType.name)
    }
  }, [currentPlanMenuType])

  const onSaveHandler = () => {
    if (!title) {
      setError('Обязательное поле')
      return
    }
    if (!!id) {
      updatePlanMenuType({ id, data: { name: title } })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Тип план-меню успешно обновлен!'),
            type: NotificationType.success,
          })
          closePopup()
        })
    } else {
      createPlanMenuType({ name: title })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Тип план-меню успешно создан!'),
            type: NotificationType.success,
          })
          closePopup()
        })
    }
  }

  return (
    <Popup
      size={PopupSize.base}
      borderRadiusMin
      closePopup={closePopup}
      isLoading={isLoadingCreate || isLoadingUpdate}
    >
      <div className="category-popup">
        <h3>{t(`${!!id ? 'Редактирование' : 'Создание'} типа план-меню`)}</h3>
        <AppInput
          value={title}
          onChange={(value) => {
            setError('')
            setTitle(value)
          }}
          label="Название"
          border
          fullWidth
          error={error ? error : undefined}
        />
        <div className="buttons-block">
          <AppBtn
            title={'Отменить'}
            color={AppBtnColor.outlineViolet}
            iconClassName={'close'}
            sized={AppBtnSize.md}
            onClick={closePopup}
          />
          <AppBtn
            iconClassName={'save'}
            title={'Сохранить'}
            sized={AppBtnSize.md}
            color={AppBtnColor.violetWhite}
            onClick={onSaveHandler}
          />
        </div>
      </div>
    </Popup>
  )
}
