import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { AppDropdown } from './AppDropdown'
import { useLocation, useSearchParams } from 'react-router-dom'
import { resetPaginationByParamsChanged } from '../../utils/resetPaginationByParamsChanged'

export interface IAppFilterDropdown<T, TKey> {
  id?: string
  searchParam: string
  propToShowInList: TKey
  valuePropName: TKey
  placeholder?: string
  label?: string
  shouldResetPagination?: boolean
  minHeight?: boolean
  minWidth?: boolean
  fullWidth?: boolean
  withTranslate?: boolean
  data: T[]
}

export const AppFilterDropdown = <T, TKey extends keyof T>({
  id,
  searchParam,
  data,
  propToShowInList,
  valuePropName,
  label,
  placeholder,
  shouldResetPagination = true,
  minHeight,
  minWidth,
  fullWidth,
  withTranslate,
}: PropsWithChildren<IAppFilterDropdown<T, TKey>>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const [currentFilterValue, setCurrentFilterValue] = useState<T | null>(null)
  const currentSearchParamValue = searchParams.get(
    searchParam,
  ) as unknown as T[TKey]

  useEffect(() => {
    if (!!currentSearchParamValue) {
      const current = data.find((item) => {
        return `${item[valuePropName]}` === `${currentSearchParamValue}`
      })
      setCurrentFilterValue(!!current ? current : null)
    } else {
      setCurrentFilterValue(null)
    }
  }, [currentSearchParamValue, data, valuePropName])

  const changeHandler = useCallback(
    (item) => {
      const isParameterEntry = !!searchParams.get(searchParam)
      if (!isParameterEntry) {
        searchParams.append(searchParam, item[valuePropName])
      } else {
        searchParams.set(searchParam, item[valuePropName])
      }
      if (shouldResetPagination) {
        resetPaginationByParamsChanged({ searchParams, setSearchParams, pathname })
      }
      setSearchParams(searchParams.toString())
    },
    [
      searchParams,
      setSearchParams,
      searchParam,
      valuePropName,
      shouldResetPagination,
    ],
  )

  const resetFilterHandler = useCallback(() => {
    searchParams.delete(searchParam)
    setSearchParams(searchParams.toString())
    if (shouldResetPagination) {
      resetPaginationByParamsChanged({ searchParams, setSearchParams, pathname })
    }
  }, [searchParams, searchParam, shouldResetPagination])

  return (
    <AppDropdown
      data={data}
      propToShowInList={propToShowInList}
      value={currentFilterValue}
      placeholder={placeholder ? placeholder : 'Фильтр по значению'}
      onChange={changeHandler}
      label={label}
      resetValueHandler={resetFilterHandler}
      minHeight={minHeight}
      minWidth={minWidth}
      id={`-fi${id || ''}`}
      fullWidth={fullWidth}
      withTranslate={withTranslate}
    />
  )
}
