import React, { useCallback } from 'react'
import { IIngredients } from '../../core/api/dto/DishObjDto'
import AppBtn, { AppBtnSize } from '../btn/AppBtn'
import IngredientTableRow from './IngredientTableRow'

interface IPropsTable {
  ingredients: IIngredients[]
  changeIngredients: (items: IIngredients[]) => void
  isEditable: boolean
}

const IngredientTable = ({
  ingredients,
  changeIngredients,
  isEditable,
}: IPropsTable) => {
  const addNewIngredient = useCallback(
    () =>
      changeIngredients([...ingredients, { name: '', netto: '', weight: '' }]),
    [changeIngredients, ingredients],
  )

  const updateItem = useCallback(
    (id: number, item: IIngredients) =>
      changeIngredients(ingredients.map((el, idx) => (idx === id ? item : el))),
    [changeIngredients, ingredients],
  )

  const deleteItem = useCallback(
    (id: number) =>
      changeIngredients(ingredients.filter((_, idx) => idx !== id)),
    [changeIngredients, ingredients],
  )

  return (
    <table className={isEditable ? '' : 'r-b-15'}>
      <colgroup>
        <col style={{ width: '66%' }} />
        <col span={2} style={{ width: '17%' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Ингредиент</th>
          <th>Нетто</th>
          <th>Готовое</th>
        </tr>
      </thead>
      {isEditable && (
        <tfoot>
          <tr>
            <td colSpan={3}>
              <AppBtn
                title="Добавить ингредиент"
                iconClassName="add"
                sized={AppBtnSize.lg}
                onClick={addNewIngredient}
              />
            </td>
          </tr>
        </tfoot>
      )}
      <tbody>
        {ingredients.map((el, idx) => (
          <IngredientTableRow
            info={el}
            key={`ingredient-${idx}`}
            id={idx}
            updateItem={updateItem}
            deleteItem={deleteItem}
            isEditable={isEditable}
          />
        ))}
      </tbody>
    </table>
  )
}

export default IngredientTable
