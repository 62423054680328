import React, { useState } from 'react'
import Popup from '../../../../../components/popup/Popup'
import { IBuffetDishWithImplInfoDto } from '../../../../../core/api/dto/BuffetDishDto'
import './PredictionDishPopup.scss'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
} from '../../../../../components/btn/AppBtn'
import { AppImagePopup } from '../../../../../components/AppImagePopup/AppImagePopup'
import { useTranslation } from 'react-i18next'
import { AllRoutes } from '../../../../../core/routes/AllRoutes'
import { useNavigate } from 'react-router'

interface PredictionDishPopupProps {
  dish: IBuffetDishWithImplInfoDto
  onClosePopup: () => void
  imageFromCamera: string
  orderIds?: string[]
}

export const PredictionDishPopup = ({
  dish,
  onClosePopup,
  imageFromCamera,
  orderIds,
}: PredictionDishPopupProps) => {
  const navigate = useNavigate()

  const onContinueHandler = () => {
    if (orderIds && orderIds.length > 1 && !!dish) {
      navigate(
        `/${AllRoutes.planMenuIssuing.path}/${AllRoutes.choosePlanMenuIssuingDetail.path}/?ids=${orderIds}&dish=${dish.id}`,
      )
      return
    }
    if (!!orderIds && orderIds.length === 1 && !!dish) {
      navigate(`/${AllRoutes.planMenuIssuing.path}/${orderIds}/${dish.id}/`)
    }
  }

  return (
    <Popup closePopup={onClosePopup} className="popup-width">
      <div className="prediction-popup-wrap">
        <div className="photos">
          <PhotoBlock
            title="Эталон"
            src={dish?.image || dish?.imageOnS3 || ''}
          />
          <PhotoBlock title="Факт" src={imageFromCamera || ''} />
        </div>
        <div className="btns mt-20">
          <AppBtn
            title="Отмена"
            sized={AppBtnSize.md}
            color={AppBtnColor.outlineViolet}
            className="full-width"
            onClick={onClosePopup}
          />
          <AppBtn
            title="Продолжить"
            sized={AppBtnSize.md}
            color={AppBtnColor.violetWhite}
            className="full-width"
            onClick={onContinueHandler}
          />
        </div>
      </div>
    </Popup>
  )
}

interface PhotoBlockProps {
  title: string
  src: string
}

const PhotoBlock = ({ title, src }: PhotoBlockProps) => {
  const { t } = useTranslation()
  const [isZoomPopup, setIsZoomPopup] = useState(false)
  const onOpenPopup = () => setIsZoomPopup((prev) => !prev)
  return (
    <>
      {isZoomPopup && (
        <AppImagePopup title={t(title)} img={src} setActive={onOpenPopup} />
      )}
      <div className="photo-block">
        <div>{t(title)}</div>
        <div className="photo">
          <img src={src} alt={t(title)} />
          <div className="zoom-ico-block" onClick={onOpenPopup}>
            <i className="an-ico an-ico-search" />
          </div>
        </div>
      </div>
    </>
  )
}
