import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import { AllRoutes } from '../../core/routes/AllRoutes'
import { FormatDate, initDate } from '../../components/calendar/Calendar'
import { IUserResponseDto } from '../../core/api/dto/UserDto'
import { useAppDispatch, useAppSelector } from '../../core/store/store'
import { setBlockedLink } from '../../core/store/system/systemSlice'
import { NotificationDot } from '../../UiKitComponents/NotificationDot'
import { AppScrollWrapperNew } from '../AppScrollWrapper/AppScrollWrapper'
import { useOutsideClick } from '../../utils/useOutsideClick'
import { useUnreadTask } from '../../pages/BuffetLinePage/TaskListPage/TaskListPage'
import { getNotificationCount } from '../../core/store/notifications/notificationsSelectors'
import { useTranslation } from 'react-i18next'

export interface IBurgerData {
  title: string
  ico: string
  to?: string
  id: number
  onClick?: () => void
}

const burgerDataBase: IBurgerData[] = [
  { id: 1, title: 'История', ico: 'clock' },
  { id: 2, title: 'Отчеты', ico: 'report', to: AllRoutes.reports.path },
  { id: 3, title: 'Блюда', ico: 'dish', to: AllRoutes.dishes.path },
]

interface IBurgerMenuProps {
  burgerData?: IBurgerData[]
  currentUser?: IUserResponseDto
}

const BurgerMenu = ({
  burgerData = burgerDataBase,
  currentUser,
}: IBurgerMenuProps) => {
  useUnreadTask()
  const unreadTask = useAppSelector(getNotificationCount)
  const { dateOrders } = useParams()
  const { t } = useTranslation()
  const { buttonScrollMode } = useAppSelector((state) => state.options)
  const navigate = useNavigate()
  const [isOpenPanel, setIsOpenPanel] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const userMenuRef = useRef<HTMLDivElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)

  const [active, setActive] = useState(false)

  useOutsideClick(ref, () => setIsOpenPanel(false), isOpenPanel)

  const handlerOnChange = useCallback(
    () => setIsOpenPanel(!isOpenPanel),
    [isOpenPanel],
  )
  const goToMainPage = useCallback(() => {
    setIsOpenPanel(false)
    if (!dateOrders) {
      navigate(initDate(FormatDate.BASE_DATE))
    }
  }, [dateOrders, navigate])

  const [position, setPosition] = useState({ left: 0, top: 0 })
  const getPosition = () => {
    if (userMenuRef.current) {
      const { left, top } = userMenuRef.current.getBoundingClientRect()
      setPosition({ left, top })
    }
  }

  useEffect(() => {
    if (active) {
      getPosition()
    }
  }, [active])

  const closeMenu = (e: any) => {
    e.stopPropagation()
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      userMenuRef.current &&
      !userMenuRef.current.contains(e.target)
    ) {
      setActive(false)
    }
  }
  useEffect(() => {
    if (active) {
      document.addEventListener('click', closeMenu, false)
      document.addEventListener('touchend', closeMenu, false)
    }
    return () => {
      if (active) {
        document.removeEventListener('click', closeMenu, false)
        document.removeEventListener('touchend', closeMenu, false)
      }
    }
  }, [active])

  return (
    <React.Fragment>
      {isOpenPanel && <div className="burger-bg-screen" />}
      <div className="burger-menu" ref={ref}>
        <input
          type="checkbox"
          id="openSidebarMenu"
          onChange={handlerOnChange}
          checked={isOpenPanel}
        />
        <label htmlFor="openSidebarMenu" className={isOpenPanel ? 'burger-menu-open' : ''}>
          <i className="an-ico an-ico-b-menu" />
          {!!unreadTask && <NotificationDot count={unreadTask} />}
        </label>
        <div className="sidebar-menu-wrap">
          <div
            className={`sidebar-menu column ${
              buttonScrollMode ? 'sidebar-menu-without-scroll' : ''
            }`}
          >
            <AppScrollWrapperNew theme="burger" trigger={isOpenPanel}>
              {burgerData?.map((burgerItem, index) => (
                <NavItem
                  unreadTask={unreadTask}
                  burgerItem={burgerItem}
                  goToMainPage={goToMainPage}
                  setIsOpenPanel={setIsOpenPanel}
                  key={`burger-item-${index}`}
                />
              ))}
              {!!currentUser && (
                <div className="user-block-wrap">
                  <div
                    className="user-block"
                    onClick={() => setActive((prevState) => !prevState)}
                  >
                    <div className="name-wrap">
                      <div className="ico-name">{currentUser.name[0]}</div>
                      <div className="name-block">
                        <span>{currentUser.name}</span>
                        {/*<small>{currentUser.userRoles.roleType[0]}</small>*/}
                      </div>
                    </div>
                    <div className="dotes-block" ref={userMenuRef}>
                      <i className="an-ico an-ico-dotes-v" />
                    </div>
                  </div>
                </div>
              )}
            </AppScrollWrapperNew>
          </div>
        </div>
      </div>
      {active && (
        <div
          className="burger-menu-user"
          style={{ left: position.left, top: position.top }}
          ref={menuRef}
        >
          <div className="photo-menu-item" onClick={() => navigate('logout')}>
            <i className="an-ico an-ico-go" />
            {t('Выйти')}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default BurgerMenu

interface INavItemProps {
  unreadTask: number
  burgerItem: IBurgerData
  goToMainPage?: () => void
  setIsOpenPanel: (value: boolean) => void
}

const NavItem = memo(
  ({ burgerItem, goToMainPage, setIsOpenPanel, unreadTask }: INavItemProps) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { blockLinkState } = useAppSelector((state) => state.system)
    const path = useLocation().pathname
    const setLinkPathHandler = (path: string) => {
      setIsOpenPanel(false)
      dispatch(setBlockedLink({ ...blockLinkState, clickedLink: path }))
    }

    if (!!burgerItem.to) {
      return blockLinkState.linkBlock ? (
        <div
          className={`menu-item ${
            path.includes(burgerItem.to) ? 'active' : ''
          }`}
          onClick={() => setLinkPathHandler(burgerItem?.to || '')}
        >
          <div className="menu-item-ico">
            <i className={`an-ico an-ico-${burgerItem.ico}`} />
          </div>
          <span>{t(burgerItem.title)}</span>
        </div>
      ) : (
        <NavLink
          className="menu-item"
          to={burgerItem.to!}
          onClick={() => {
            setIsOpenPanel(false)
          }}
        >
          <div className="menu-item-ico">
            <i className={`an-ico an-ico-${burgerItem.ico}`} />
            {burgerItem.id === 16 && !!unreadTask && (
              <NotificationDot count={unreadTask} />
            )}
          </div>
          <span>{t(burgerItem.title)}</span>
        </NavLink>
      )
    } else {
      return (
        <div
          className="menu-item"
          onClick={
            burgerItem.title === 'История' ? goToMainPage : burgerItem.onClick
          }
        >
          <i className="an-ico an-ico-clock" />
          <span>{t(burgerItem.title)}</span>
        </div>
      )
    }
  },
)
