import { api } from './BaseApi'
import {
  IBuffetDish,
  IBuffetDishByCategoryDto,
  IBuffetDishDto,
  IBuffetDishesRequest,
  IBuffetDishesResponse,
  IBuffetDishesWithImplInfoDtoResponse,
  IBuffetDishUploadDto,
  ICreateBuffetDishDto,
  IPredictDataDto,
} from './dto/BuffetDishDto'

export const buffetDishApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuffetDish: builder.query<IBuffetDishesResponse, IBuffetDishesRequest>({
      query: (params) => ({
        url: '/buffet_dish/',
        params: !!params ? params : {},
      }),
      providesTags: ['ВuffetDish'],
    }),
    getBuffetDishSimpleList: builder.query<
      IBuffetDishesResponse,
      IBuffetDishesRequest
    >({
      query: (params) => ({
        url: '/buffet_dish/get_dish_simple_info/',
        params: !!params ? params : {},
      }),
      providesTags: ['ВuffetDish'],
    }),
    getBuffetDishDetail: builder.query<IBuffetDishDto, { id: number }>({
      query: ({ id }) => ({
        url: `/buffet_dish/${id}/`,
      }),
      providesTags: ['ВuffetDish', 'Tags'],
    }),
    getDishesFromOrdersByCategories: builder.query<
      IBuffetDishesWithImplInfoDtoResponse,
      { category?: number; order_ids?: string }
    >({
      query: (params) => ({
        url: `/buffet_dish/get_dishes_from_orders_by_categories/`,
        params: !!params ? params : {},
      }),
      providesTags: ['ВuffetDish'],
    }),
    getDishesWithImplInfo: builder.query<
      IBuffetDishesWithImplInfoDtoResponse,
      { search?: string; order_ids: string }
    >({
      query: (params) => ({
        url: `/buffet_dish/get_dishes_with_impl_info/`,
        params: !!params ? params : {},
      }),
      providesTags: ['ВuffetDish'],
    }),
    getPredictionData: builder.query<IPredictDataDto, void | null>({
      query: () => ({
        url: `/order/get_prediction_data/`,
      }),
      providesTags: ['ВuffetDish'],
    }),
    getImageDishFromCamera: builder.query<{ image: string }, void>({
      query: () => ({
        url: `/buffet_dish/get_image_from_camera/`,
      }),
    }),
    getLoadBuffetDishesFromSh: builder.query<{ detail: string }, null>({
      query: () => ({
        url: '/buffet_dish/load_dishes_from_source/',
      }),
      providesTags: ['ВuffetDish'],
    }),
    getBuffetDishByCategory: builder.query<
      IBuffetDishByCategoryDto[],
      { search?: string }
    >({
      query: ({ search }) => ({
        url: `/buffet_dish/get_dishes_split_by_category/`,
        params: { search },
      }),
      providesTags: ['ВuffetDish'],
    }),
    createBuffetDish: builder.mutation<IBuffetDishDto, ICreateBuffetDishDto>({
      query: (body) => ({
        method: 'POST',
        url: '/buffet_dish/',
        body,
      }),
      invalidatesTags: ['ВuffetDish'],
    }),
    updateBuffetDish: builder.mutation<
      IBuffetDishDto,
      { id: number; data: Partial<ICreateBuffetDishDto> }
    >({
      query: ({ id, data }) => ({
        method: 'PATCH',
        url: `/buffet_dish/${id}/`,
        body: data,
      }),
      invalidatesTags: ['ВuffetDish'],
    }),
    uploadBuffetDish: builder.mutation<
      IBuffetDishUploadDto,
      { data: FormData }
    >({
      query: ({ data }) => ({
        method: 'POST',
        url: '/buffet_dish/load_dishes_from_file/',
        data,
        body: data,
      }),
      invalidatesTags: ['ВuffetDish'],
    }),
    updateUploadedBuffetDish: builder.mutation<
      IBuffetDishUploadDto,
      { data: IBuffetDish[] }
    >({
      query: ({ data }) => ({
        method: 'PATCH',
        url: '/buffet_dish/update_dishes/',
        body: data,
      }),
      invalidatesTags: ['ВuffetDish'],
    }),
    archiveMultipleBuffetDish: builder.mutation<
      IBuffetDishDto,
      { dishes: number[] }
    >({
      query: ({ dishes }) => ({
        method: 'PATCH',
        url: `/buffet_dish/archive_multiple_dishes/`,
        body: { dishes },
      }),
      invalidatesTags: ['ВuffetDish'],
    }),
  }),
})

export const {
  useGetBuffetDishQuery,
  useGetBuffetDishSimpleListQuery,
  useGetBuffetDishDetailQuery,
  useGetBuffetDishByCategoryQuery,
  useGetImageDishFromCameraQuery,
  useLazyGetImageDishFromCameraQuery,
  useGetDishesFromOrdersByCategoriesQuery,
  useGetDishesWithImplInfoQuery,
  useGetPredictionDataQuery,
  useLazyGetPredictionDataQuery,
  useGetLoadBuffetDishesFromShQuery,
  useUpdateBuffetDishMutation,
  useCreateBuffetDishMutation,
  useUploadBuffetDishMutation,
  useUpdateUploadedBuffetDishMutation,
  useArchiveMultipleBuffetDishMutation,
} = buffetDishApi
