import { Navigate, Outlet, useLocation } from 'react-router-dom'
import React from 'react'
import Login from '../../pages/Auth/Login'
import { RouteObject } from 'react-router'
import { AuthPageLayout } from '../../pages/Auth/AuthPageLayout'
import User from '../../pages/User'
import { LogoutPage } from '../../pages/Auth/LogoutPage'
import Waiter from '../../pages/Waiter'
import TechnologicalMapCatalog from '../../pages/TechnologicalMapCatalog'
import TechnologicalMapCatalogCategory from '../../components/mainContent/TechnologicalMapCatalogCategory'
import RecipeCardDish from '../../pages/RecipeCardDish'
import BuffetWorkshopsCatalog from '../../pages/BuffetWorkshopsCatalog'
import AdminPage from '../../pages/AdminPage'
import BuffetAllDishesCatalog from '../../pages/BuffetAllDishesCatalog'
import NoCategorySelected from '../../components/noCategorySelected/NoCategorySelected'
import CookPage from '../../pages/CookPage'
import OrdersColumn from '../../components/adminPageColumns/ordersColumn/OrdersColumn'
import ActiveOrderColumn from '../../components/adminPageColumns/activeOrderColumn/ActiveOrderColumn'
import PhotosColumn from '../../components/adminPageColumns/photosColumn/PhotosColumn'
import AdminReport from '../../components/mainContent/AdminReport'
import WaiterMobilePage from '../../pages/WaiterMobilePage'
import TableAllDishes from '../../components/mainContent/TableAllDishes'
import CreateUpdateDish from '../../components/mainContent/CreateUpdateDish'
import BuffetCardByWorkshop from '../../pages/BuffetCardByWorkshop'
import TechnologicalMapCatalogWithoutCategory from '../../components/mainContent/TechnologicalMapCatalogWithoutCategory'
import { BuffetLinePage } from '../../pages/BuffetLinePage/BuffetLinePage'
import { DishesPage } from '../../pages/BuffetLinePage/DishesPage/DishesPage'
import { CreateDishPage } from '../../pages/BuffetLinePage/DishesPage/CreateDishPage'
import { MilletPage } from '../../pages/BuffetLinePage/TaresPage/MilletPage'
import { CreateMilletPage } from '../../pages/BuffetLinePage/TaresPage/CreateMilletPage'
import { CategoriesPage } from '../../pages/BuffetLinePage/CategoriesPage/CategoriesPage'
import { OptionsPage } from '../../pages/BuffetLinePage/OptionsPage/OptionsPage'
import { PlanMenuPage } from '../../pages/BuffetLinePage/PlanMenuPage/PlanMenuPage'
import { CreatePlanMenuPage } from '../../pages/BuffetLinePage/PlanMenuPage/CreatePlanMenuPage'
import { PlanMenuTypePage } from '../../pages/BuffetLinePage/PlanMenuTypePage/PlanMenuTypePage'
import { TemplatePlanMenuPage } from '../../pages/BuffetLinePage/TemplatePlanMenuPage/TemplatePlanMenuPage'
import { CreateTemplatePlanMenuPage } from '../../pages/BuffetLinePage/TemplatePlanMenuPage/CreateTemplatePlanMenuPage'
import { TagsPage } from '../../pages/BuffetLinePage/TagsPage/TagsPage'
import { ReportPage } from '../../pages/BuffetLinePage/ReportPage/ReportPage'
import { StationsPage } from '../../pages/BuffetLinePage/StationsPage/StationsPage'
import { EditStationsPage } from '../../pages/BuffetLinePage/StationsPage/EditStationsPage'
import { PlanMenuControlAdmin } from '../../pages/BuffetLinePage/PlanMenuControl/PlanMenuControlAdmin'
import { PlanMenuClosePage } from '../../pages/BuffetLinePage/PlanMenuClosePage/PlanMenuClosePage'
import { PlanMenuControlManager } from '../../pages/BuffetLinePage/PlanMenuControl/PlanMenuControlManager'
import { PlanMenuControlManagerDetail } from '../../pages/BuffetLinePage/PlanMenuControl/PlanMenuControlManagerDetail'
import { PlanMenuIssuingPage } from '../../pages/BuffetLinePage/PlanMenuIssuingPage/PlanMenuIssuingPage'
import { PlanMenuIssuingDetailPage } from '../../pages/BuffetLinePage/PlanMenuIssuingPage/PlanMenuIssuingDetailPage'
import { AddPlanMenuIssuingPage } from '../../pages/BuffetLinePage/PlanMenuIssuingPage/AddPlanMenuIssuingPage'
import { Role } from '../api/dto/UserDto'
import { burgerDataBuffetLineByRole } from '../../pages/BuffetLinePage/components/HeaderBuffetLinePage'
import { ChoosePlanMenuIssuingPage } from '../../pages/BuffetLinePage/PlanMenuIssuingPage/ChoosePlanMenuIssuingPage'
import { GuestTypePage } from '../../pages/BuffetLinePage/ GuestTypePage/GuestTypePage'
import { BuffetComparativeReports } from '../../pages/BuffetLinePage/BuffetComparativeReports/BuffetComparativeReports'
import { BuffetComparativeReportsDetail } from '../../pages/BuffetLinePage/BuffetComparativeReports/BuffetComparativeReportsDetail'
import { TaskListPage } from 'pages/BuffetLinePage/TaskListPage/TaskListPage'
import { EditTaskPage } from 'pages/BuffetLinePage/TaskListPage/EditTaskPage/EditTaskPage'
import { AllRoutes } from './AllRoutes'
import { BuffetReportsVZ } from 'pages/BuffetLinePage/BuffetReportsVZPage/BuffetReportsVZ'
import { BuffetReportsVZLine } from 'pages/BuffetLinePage/BuffetReportsVZPage/BuffetReportsVZLine'
import { BuffetReportsVZChart } from 'pages/BuffetLinePage/BuffetReportsVZPage/BuffetReportVZChart'

interface IRedirect {
  path?: string
}

export const AppRedirect = ({ path = '/' }: IRedirect) => {
  const location = useLocation()
  return <Navigate to={path} state={{ from: location }} replace />
}

export const loginRoutes: RouteObject = {
  path: '/',
  element: <AuthPageLayout />,
  children: [
    { index: true, element: <Login /> },
    {
      path: AllRoutes.login.path,
      element: <Login />,
    },
    { path: '*', element: <Login /> },
  ],
}

const adminRoutes = {
  path: '/',
  children: [
    {
      index: true,
      element: <Navigate to={AllRoutes.adminCardMapCatalog.path} replace />,
    },
    {
      path: `${AllRoutes.adminCardMapCatalog.path}`,
      element: <AdminPage />,
      children: [
        {
          path: `${AllRoutes.dateOrders.path}`,
          element: <OrdersColumn />,
          children: [
            {
              path: `${AllRoutes.orderId.path}`,
              element: <ActiveOrderColumn />,
              children: [
                {
                  path: `${AllRoutes.orderLineId.path}`,
                  element: <PhotosColumn />,
                },
              ],
            },
          ],
        },
        {
          path: `${AllRoutes.reports.path}`,
          element: <AdminReport />,
        },
        {
          path: `${AllRoutes.dishes.path}`,
          children: [
            { index: true, element: <TableAllDishes /> },
            {
              path: `${AllRoutes.dishId.path}`,
              element: <CreateUpdateDish />,
            },
          ],
        },
        {
          path: `${AllRoutes.addNewDish.path}`,
          element: <CreateUpdateDish />,
        },
      ],
    },
    {
      path: `${AllRoutes.user.path}`,
      element: <User />,
    },
    {
      path: `${AllRoutes.logout.path}`,
      element: <LogoutPage />,
    },
    {
      path: '*',
      element: <AppRedirect path="/" />,
    },
  ],
}

const cookRoutes = {
  path: '/',
  children: [
    {
      index: true,
      element: <CookPage />,
    },
    {
      path: `${AllRoutes.user.path}`,
      element: <User />,
    },
    {
      path: `${AllRoutes.logout.path}`,
      element: <LogoutPage />,
    },
    {
      path: '*',
      element: <AppRedirect path="/" />,
    },
  ],
}

const waiterRoutes = {
  path: '/',
  children: [
    {
      index: true,
      element: <Waiter />,
    },
    {
      path: `${AllRoutes.user.path}`,
      element: <User />,
    },
    {
      path: `${AllRoutes.logout.path}`,
      element: <LogoutPage />,
    },
    {
      path: '*',
      element: <AppRedirect path="/" />,
    },
  ],
}

const chefRoutes = {
  path: '/',
  element: (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={AllRoutes.technologicalMapCatalog.path} replace />,
    },
    {
      path: `${AllRoutes.user.path}`,
      element: <User />,
    },
    {
      path: `${AllRoutes.technologicalMapCatalog.path}`,
      element: <TechnologicalMapCatalog />,
      children: [
        {
          path: `${AllRoutes.technologicalMapCatalogRestaurant.path}`,
          children: [
            { index: true, element: <NoCategorySelected /> },
            {
              path: `${AllRoutes.technologicalMapCatalogCategory.path}`,
              element: <TechnologicalMapCatalogCategory />,
            },
            {
              path: `${AllRoutes.technologicalMapAllSearchCards.path}`,
              element: <TechnologicalMapCatalogCategory />,
            },
            {
              path: `${AllRoutes.technologicalMapCatalogWithoutCategory.path}`,
              element: <TechnologicalMapCatalogWithoutCategory />,
            },
          ],
        },
      ],
    },
    {
      path: `${AllRoutes.technologicalMapCatalog.path}`,
      children: [
        {
          path: `${AllRoutes.technologicalMapCatalogRestaurant.path}`,
          children: [
            {
              path: `${AllRoutes.technologicalMapCatalogCategory.path}`,
              children: [
                {
                  path: `${AllRoutes.technologicalMapCardItem.path}`,
                  children: [
                    { index: true, element: <RecipeCardDish /> },
                    {
                      path: `${AllRoutes.technologicalMapCardItemEdit.path}`,
                      element: <RecipeCardDish isEditable={true} />,
                    },
                  ],
                },
              ],
            },
            {
              path: `${AllRoutes.technologicalMapAllSearchCards.path}`,
              children: [
                {
                  path: `${AllRoutes.technologicalMapCardItem.path}`,
                  children: [
                    { index: true, element: <RecipeCardDish /> },
                    {
                      path: `${AllRoutes.technologicalMapCardItemEdit.path}`,
                      element: <RecipeCardDish isEditable={true} />,
                    },
                  ],
                },
              ],
            },
            {
              path: `${AllRoutes.technologicalMapCatalogWithoutCategory.path}`,
              children: [
                {
                  path: `${AllRoutes.technologicalMapCardItem.path}`,
                  children: [
                    { index: true, element: <RecipeCardDish /> },
                    {
                      path: `${AllRoutes.technologicalMapCardItemEdit.path}`,
                      element: <RecipeCardDish isEditable={true} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: `${AllRoutes.logout.path}`,
      element: <LogoutPage />,
    },
    {
      path: '*',
      element: <AppRedirect path="/" />,
    },
  ],
}

const orderLineRotes = {
  path: '/',
  children: [
    {
      index: true,
      element: <Navigate to={AllRoutes.buffetCardMapCatalog.path} replace />,
    },
    {
      path: `${AllRoutes.buffetCardMapCatalog.path}`,
      element: <BuffetWorkshopsCatalog />,
      children: [
        {
          path: `${AllRoutes.buffetCardMapCatalogOrder.path}`,
          element: <BuffetWorkshopsCatalog />,
        },
      ],
    },
    {
      path: `${AllRoutes.buffetCardMapCatalog.path}`,
      children: [
        {
          path: `${AllRoutes.buffetCardMapCatalogOrder.path}`,
          children: [
            {
              path: `${AllRoutes.allDishesByWorkshops.path}`,
              element: <BuffetCardByWorkshop />,
            },
          ],
        },
      ],
    },
    {
      path: `${AllRoutes.buffetCardMapCatalog.path}`,
      children: [
        {
          path: `${AllRoutes.buffetCardMapCatalogOrder.path}`,
          children: [
            {
              path: `${AllRoutes.workshopId.path}`,
              element: <BuffetCardByWorkshop />,
            },
          ],
        },
      ],
    },
    {
      path: `${AllRoutes.buffetCardMapCatalog.path}`,
      children: [
        {
          path: `${AllRoutes.buffetCardMapCatalogOrder.path}`,
          children: [
            {
              path: `${AllRoutes.additionBuffetCard.path}`,
              element: <BuffetAllDishesCatalog />,
            },
          ],
        },
      ],
    },
    {
      path: `${AllRoutes.user.path}`,
      element: <User />,
    },
    {
      path: `${AllRoutes.logout.path}`,
      element: <LogoutPage />,
    },
    {
      path: '*',
      element: <AppRedirect path="/" />,
    },
  ],
}

const waiterMobileRoutes = {
  path: '/',
  children: [
    {
      index: true,
      element: <WaiterMobilePage />,
    },
    {
      path: `${AllRoutes.user.path}`,
      element: <User />,
    },
    {
      path: `${AllRoutes.logout.path}`,
      element: <LogoutPage />,
    },
    {
      path: '*',
      element: <AppRedirect path="/" />,
    },
  ],
}

const buffetLineRoutes = {
  path: '/',
  element: <BuffetLinePage />,
  children: [
    {
      index: true,
      element: <Navigate to={AllRoutes.dishes.path} replace />,
    },
    {
      id: '16',
      path: AllRoutes.taskList.path,
      children: [
        {
          index: true,
          element: <TaskListPage />,
        },
        {
          path: AllRoutes.addTask.path,
          element: <EditTaskPage />,
        },
        {
          path: AllRoutes.editTask.path,
          element: <EditTaskPage />,
        },
      ],
    },
    {
      id: '1',
      path: AllRoutes.dishes.path,
      children: [
        {
          index: true,
          element: <DishesPage />,
        },
        {
          path: AllRoutes.addDishes.path,
          element: <CreateDishPage />,
        },
        {
          path: AllRoutes.editDishes.path,
          element: <CreateDishPage />,
        },
      ],
    },
    {
      id: '2',
      path: AllRoutes.categories.path,
      element: <CategoriesPage />,
    },
    {
      id: '3',
      path: AllRoutes.millet.path,
      children: [
        { index: true, element: <MilletPage /> },
        { path: AllRoutes.addMillet.path, element: <CreateMilletPage /> },
        { path: AllRoutes.editMillet.path, element: <CreateMilletPage /> },
      ],
    },
    {
      id: '4',
      path: AllRoutes.templatePlanMenu.path,
      children: [
        {
          index: true,
          element: <TemplatePlanMenuPage />,
        },
        {
          path: AllRoutes.addTemplatePlanMenu.path,
          element: <CreateTemplatePlanMenuPage />,
        },
        {
          path: AllRoutes.editTemplatePlanMenu.path,
          element: <CreateTemplatePlanMenuPage />,
        },
      ],
    },
    {
      id: '5',
      path: AllRoutes.planMenuType.path,
      element: <PlanMenuTypePage />,
    },
    {
      id: '6',
      path: AllRoutes.planMenu.path,
      children: [
        {
          index: true,
          element: <PlanMenuPage />,
        },
        {
          path: AllRoutes.addPlanMenu.path,
          element: <CreatePlanMenuPage />,
        },
        {
          path: AllRoutes.editPlanMenu.path,
          element: <CreatePlanMenuPage />,
        },
      ],
    },
    {
      id: '7',
      path: AllRoutes.planMenuIssuing.path,
      children: [
        { index: true, element: <PlanMenuIssuingPage /> },
        {
          path: AllRoutes.planMenuIssuingDetail.path,
          element: <PlanMenuIssuingDetailPage />,
        },
        {
          path: AllRoutes.addPlanMenuIssuingDetail.path,
          element: <AddPlanMenuIssuingPage />,
        },
        {
          path: AllRoutes.choosePlanMenuIssuingDetail.path,
          element: <ChoosePlanMenuIssuingPage />,
        },
      ],
    },
    {
      id: '8',
      path: AllRoutes.buffetReports.path,
      element: <ReportPage />,
    },
    {
      id: '15',
      path: AllRoutes.buffetComparativeReports.path,
      children: [
        { index: true, element: <BuffetComparativeReports /> },
        {
          path: AllRoutes.buffetComparativeReportsDetail.path,
          element: <BuffetComparativeReportsDetail />,
        },
      ],
    },
    {
      id: '9',
      path: AllRoutes.planMenuControl.path,
      element: <PlanMenuControlAdmin />,
    },
    {
      id: '10',
      path: AllRoutes.planMenuControlManager.path,
      children: [
        { index: true, element: <PlanMenuControlManager /> },
        {
          path: AllRoutes.planMenuControlManagerDetail.path,
          element: <PlanMenuControlManagerDetail />,
        },
      ],
    },
    {
      id: '11',
      path: AllRoutes.planMenuClose.path,
      element: <PlanMenuClosePage />,
    },
    {
      id: '12',
      path: AllRoutes.tags.path,
      element: <TagsPage />,
    },
    {
      id: '13',
      path: AllRoutes.stations.path,
      children: [
        { index: true, element: <StationsPage /> },
        { path: AllRoutes.editStations.path, element: <EditStationsPage /> },
      ],
    },
    {
      id: '19',
      path: AllRoutes.options.path,
      element: <OptionsPage />,
    },
    {
      id: '14',
      path: AllRoutes.guestType.path,
      element: <GuestTypePage />,
    },
    {
      path: `${AllRoutes.user.path}`,
      element: <User />,
    },
    {
      id: '20',
      path: `${AllRoutes.logout.path}`,
      element: <LogoutPage />,
    },
    {
      id: '21',
      path: '*',
      element: <AppRedirect path="/" />,
    },
    {
      id: '23',
      path: AllRoutes.buffetReportsVZ.path,
      children: [
        { index: true, element: <BuffetReportsVZ /> },
        {
          path: AllRoutes.buffetReportsVZLine.path,
          element: <BuffetReportsVZLine />,
        },
        {
          path: AllRoutes.buffetReportsVZChart.path,
          element: <BuffetReportsVZChart />,
        },
      ],
    },
  ],
}

const routeFilterByRole = (role: Role) => {
  const routes = buffetLineRoutes.children.filter((child) =>
    burgerDataBuffetLineByRole[role].some(
      (item: number) => `${item}` === `${child.id}`,
    ),
  )
  return routes
}

export const appRoutes = {
  authorized: [
    { role: Role.ADMIN, routes: adminRoutes },
    { role: Role.WAITER, routes: waiterRoutes },
    { role: Role.CHEF, routes: chefRoutes },
    { role: Role.FOTOBOX, routes: orderLineRotes },
    { role: Role.COOK, routes: cookRoutes },
    { role: Role.WAITER, routes: waiterMobileRoutes },
    {
      role: Role.BUFF_SUPERVISOR,
      routes: routeFilterByRole(Role.BUFF_SUPERVISOR),
    },
    {
      role: Role.BUFF_MANAGER,
      routes: [
        ...routeFilterByRole(Role.BUFF_MANAGER),
        {
          id: '9',
          path: AllRoutes.planMenuControl.path,
          element: <PlanMenuControlAdmin />,
        },
      ],
    },
    {
      role: Role.BUFF_CHEF,
      routes: [
        ...routeFilterByRole(Role.BUFF_CHEF),
        {
          id: '9',
          path: AllRoutes.planMenuControl.path,
          element: <PlanMenuControlAdmin />,
        },
      ],
    },
    {
      role: Role.BUFF_ACCOUNTANT,
      routes: [
        ...routeFilterByRole(Role.BUFF_ACCOUNTANT),
        {
          id: '9',
          path: AllRoutes.planMenuControl.path,
          element: <PlanMenuControlAdmin />,
        },
      ],
    },
    {
      role: Role.BUFF_CASHIER,
      routes: routeFilterByRole(Role.BUFF_CASHIER),
    },
  ],
  notAuthorized: [
    {
      ...loginRoutes,
      path: '/',
    },
  ],
}
