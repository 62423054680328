import React, { useCallback, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { AppFilterDateInput } from '../../../components/AppInput/AppFilterDateInput'
import { AppFilterTimeInput } from '../../../components/AppInput/AppFilterTimeInput'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import {
  useArchiveMultiplePlanMenuMutation,
  useGetPlanMenuListQuery,
  useGetPlanMenuSimpleListQuery,
} from '../../../core/api/PlanMenuApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import { IPlanMenuRequest } from '../../../core/api/dto/PlanMenuDto'
import { AppLoader, LoaderType } from 'components/AppLoader'
import moment from 'moment'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { useGetPlanMenuTypeListQuery } from '../../../core/api/PlanMenuTypeApi'
import { IPlanMenuTypeDto } from '../../../core/api/dto/PlanMenuTypeDto'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { IBaseSimpleList } from '../../../core/api/dto/BaseDto'
import { useGetGuestListTypeListQuery } from '../../../core/api/GuestTypeApi'
import { useDeleteDebounce } from '../../../utils/useDeleteDebounce'
import {
  AppTableAlignType,
  AppTableLink,
} from '../../../components/AppTable/components/AppTableLink'
import { AppTableNumberBlock } from '../../../components/AppTable/components/AppTableNumberBlock'
import { AppTableMobile } from '../../../components/AppTable/AppTableMobile'
import { useTranslation } from 'react-i18next'
import './PlanMenuPage.scss'

const headerData = [
  {
    title: 'Дата исполнения',
    colWidth: '15%',
    sort: 'plan_menu_date_start,plan_menu_date_end',
  },
  {
    title: 'Время исполнения',
    colWidth: '15%',
    sort: 'plan_menu_date_start__time,plan_menu_date_end__time',
  },
  { title: 'Тип план-меню', colWidth: '15%', sort: 'plan_menu_type__name' },
  { title: 'Тип гостя', colWidth: '15%' },
  { title: 'Название план-меню', colWidth: '15%', sort: 'name' },
  {
    title: 'Дата создания план-меню',
    colWidth: '10%',
    sort: 'created_at',
  },
  { title: 'Кол-во блюд', colWidth: '10%', sort: 'plan_menu_lines_count' },
]

export const PlanMenuPage = () => {
  const { t } = useTranslation()
  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const pageParams = useParamsControl<IPlanMenuRequest, keyof IPlanMenuRequest>(
    {
      withPagination: true,
      whiteList: [
        'search',
        'archived',
        'ordering',
        'plan_menu_type',
        'created_at_date_start',
        'created_at_date_end',
        'plan_menu_date_start_date',
        'plan_menu_date_end_date',
        'plan_menu_date_start_time',
        'plan_menu_date_end_time',
        'ids',
        'plan_menu_guest_type',
      ],
    },
  )
  const { data: planMenuList, isLoading: isLoadingGet } =
    useGetPlanMenuListQuery(
      {
        ...pageParams,
        ordering: pageParams?.ordering
          ?.replace(
            '-order_date_start__time,order_date_end__time',
            '-order_date_start__time,-order_date_end__time',
          )
          .replace(
            '-order_date_start_date,order_date_end_date',
            '-order_date_start_date,-order_date_end_date',
          ),
      },
      { skip: !pageParams },
    )
  const [archiveMultiplePlanMenu] = useArchiveMultiplePlanMenuMutation()

  const clearFilterHandler = useCallback(
    () => setSearchParams('limit=40'),
    [setSearchParams],
  )
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)
  const deleteHandler = () => {
    if (!!chooseItems) {
      archiveMultiplePlanMenu({
        plansMenu: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>{
          AppNotification({
            msg: t('Выбранные план-меню успешно удалены!'),
            type: NotificationType.success,
          })
          setChooseItems(null)
        })
    }
  }

  const [isFiltersOpen, setIsFiltersOpen] = useState(true)

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Добавить план-меню',
        btnIco: 'add',
        onClick: () => navigate(AllRoutes.addPlanMenu.path),
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Сбросить фильтр',
        onClick: clearFilterHandler,
        color: AppBtnColor.extraVioletViolet,
      },
    ],
    [clearFilterHandler, navigate],
  )
  const [onClickHandler] = useDeleteDebounce({ fn: deleteHandler })
  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]

  if (!planMenuList && isLoadingGet)
    return <AppLoader loaderType={LoaderType.main} />
  if (!planMenuList) return null

  return (
    <React.Fragment>
      <div className={'width-for-page-wrapper plan-menu-page'}>
        <AppTitlePage
          title="План-меню"
          actions={actions}
          style={{ marginBottom: '20px' }}
          isFiltersOpen={isFiltersOpen}
          setIsFiltersOpen={setIsFiltersOpen}
        />
        {isFiltersOpen && (
          <div className="filter-block mt-20">
            <div className="col-3">
              <AppFilterMultiDropdownWithPagination<
                IPlanMenuTypeDto,
                keyof IPlanMenuTypeDto
              >
                searchParam={'plan_menu_type'}
                getterData={useGetPlanMenuTypeListQuery}
                propToShowInList={'name'}
                valuePropName={'id'}
                label={'Тип план-меню'}
                placeholder={'Выбрать'}
                fullWidth
              />
            </div>
            <div className="full-width-mob">
              <label>{t('Дата исполнения')}</label>
              <div className="input-row mt-10">
                <AppFilterDateInput searchParam={'plan_menu_date_start_date'} />
                <AppFilterDateInput searchParam={'plan_menu_date_end_date'} />
              </div>
            </div>
            <div className="full-width-mob">
              <label>{t('Время исполнения')}</label>
              <div className="input-row mt-10">
                <AppFilterTimeInput searchParam={'plan_menu_date_start_time'} />
                <AppFilterTimeInput searchParam={'plan_menu_date_end_time'} />
              </div>
            </div>
          </div>
        )}
        {isFiltersOpen && (
          <div className="filter-block">
            <div className="col-3">
              <AppFilterMultiDropdownWithPagination<
                IBaseSimpleList,
                keyof IBaseSimpleList
              >
                searchParam={'ids'}
                getterData={useGetPlanMenuSimpleListQuery}
                propToShowInList={'name'}
                valuePropName={'id'}
                label={'Название план-меню'}
                placeholder={'Выбрать'}
                fullWidth
              />
            </div>
            <div className="full-width-mob">
              <label>{t('Период создания')}</label>
              <div className="input-row mt-10">
                <AppFilterDateInput searchParam={'created_at_date_start'} />
                <AppFilterDateInput searchParam={'created_at_date_end'} />
              </div>
            </div>
            <AppFilterMultiDropdownWithPagination<
              IBaseSimpleList,
              keyof IBaseSimpleList
            >
              searchParam={'plan_menu_guest_type'}
              getterData={useGetGuestListTypeListQuery}
              propToShowInList={'name'}
              valuePropName={'id'}
              label={'Тип гостя'}
              placeholder={'Выбрать'}
            />
          </div>
        )}
        <AppTableMobile
          data={planMenuList}
          headerData={headerData}
          chooseMode
          chooseItems={chooseItems}
          setChooseItems={setChooseItems}
          chooseActions={tableActions}
          idName="plan-menu"
          tableDataSelectors={[
            {
              renderItem: (item) => (
                <AppTableLink path={`${item.id}`} deleteItem={item.archived}>
                  {`${
                    item.planMenuDateStart
                      ? moment(item.planMenuDateStart).format('DD.MM.YYYY')
                      : ''
                  } - ${
                    item.planMenuDateEnd
                      ? moment(item.planMenuDateEnd).format('DD.MM.YYYY')
                      : ''
                  }`}
                </AppTableLink>
              ),
            },
            {
              renderItem: (item) => (
                <AppTableLink
                  path={`${item.id}`}
                  deleteItem={item.archived}
                >{`${
                  item.planMenuDateStart
                    ? moment(item.planMenuDateStart).format('HH:mm')
                    : ''
                } - ${
                  item.planMenuDateEnd
                    ? moment(item.planMenuDateEnd).format('HH:mm')
                    : ''
                }`}</AppTableLink>
              ),
            },
            {
              renderItem: (item) => (
                <AppTableLink path={`${item.id}`} deleteItem={item.archived}>
                  {item?.planMenuType?.name || ''}
                </AppTableLink>
              ),
            },
            {
              renderItem: (item) => (
                <AppTableLink path={`${item.id}`} deleteItem={item.archived}>
                  {item?.planMenuGuestType?.name || ''}
                </AppTableLink>
              ),
            },
            {
              renderItem: (item) => (
                <AppTableLink path={`${item.id}`} deleteItem={item.archived}>
                  {item.name}
                </AppTableLink>
              ),
            },
            {
              renderItem: (item) => (
                <AppTableLink
                  path={`${item.id}`}
                  deleteItem={item.archived}
                >{`${
                  item.createdAt
                    ? moment(item.createdAt).format('DD.MM.YYYY')
                    : ''
                }`}</AppTableLink>
              ),
            },
            {
              renderItem: (item) => (
                <AppTableLink
                  path={`${item.id}`}
                  deleteItem={item.archived}
                  align={AppTableAlignType.right}
                >
                  <AppTableNumberBlock value={item.planMenuLinesCount} />
                </AppTableLink>
              ),
            },
          ]}
        />
      </div>
    </React.Fragment>
  )
}
