import React from 'react'
import { AppDropdown } from './AppDropdown'

interface IAppMultiDropdown<T, TKey> {
  data: T[]
  value: T[]
  propToShowInList: TKey // если data массив строк, то не передаем propToShowInList и propToShowInInput
  propToShowInInput: TKey
  onChange: (value: T[]) => void | React.Dispatch<React.SetStateAction<T[]>>
  resetValueHandler?: () => void
  label?: string
  placeholder?: string
  disabled?: boolean
  error?: string | null
  inputSearch?: string
  inputSearchFn?: (value: string) => void
}

export const AppMultiDropdown = <T, TKey extends keyof T>({
  value,
  onChange,
  propToShowInList,
  propToShowInInput,
  data,
  label,
  placeholder,
  disabled,
  error,
  resetValueHandler,
  inputSearch,
  inputSearchFn,
}: IAppMultiDropdown<T, TKey>) => {
  const onChangeHandler = (newItem: T) => {
    const duplicate = value.find(
      (item) => item[propToShowInInput] === newItem[propToShowInInput],
    )
    if (!!duplicate) {
      const removeDuplicate = value.filter(
        (item) => item[propToShowInInput] !== duplicate[propToShowInInput],
      )
      onChange(removeDuplicate)
    } else onChange([...value, newItem])
  }

  return (
    <AppDropdown
      label={label}
      value={null}
      onChange={onChangeHandler}
      resetValueHandler={resetValueHandler}
      data={data}
      multiValue={value}
      propToShowInList={propToShowInList}
      propToShowInInput={propToShowInInput}
      placeholder={
        value.length === 0 ? placeholder : `Выбрано: ${value.length}`
      }
      disabled={disabled}
      error={error}
      inputSearch={inputSearch}
      inputSearchFn={inputSearchFn}
    />
  )
}
