import { api } from './BaseApi'
import { IMilletsRequestDto, IMilletsResponseDto } from './dto/MilletObjDto'

export const milletApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMillets: builder.query<IMilletsResponseDto, IMilletsRequestDto>({
      query: (params) => ({
        url: '/millet/',
        params: !!params ? params : {},
      }),
    }),
  }),
})

export const { useGetMilletsQuery } = milletApi
