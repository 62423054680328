import { useIsAdmin } from '../../../../utils/useIsAdmin'
import {
  useGetPlanMenuControlDimensionDetailOnlyReadQuery,
  useGetPlanMenuControlDimensionDetailQuery,
} from '../../../../core/api/PlanMenuControlApi'

export const useGetDimensionDetailByRole = (
  currentDimensionId?: string | null,
) => {
  const isAdmin = useIsAdmin()

  const { data: currentDimension } = useGetPlanMenuControlDimensionDetailQuery(
    { id: Number(currentDimensionId) },
    { skip: !currentDimensionId || !isAdmin },
  )
  const { data: dimensionDetailOnlyRead } =
    useGetPlanMenuControlDimensionDetailOnlyReadQuery(
      { id: Number(currentDimensionId) },
      { skip: !currentDimensionId || isAdmin || isAdmin === null },
    )

  return isAdmin ? currentDimension : dimensionDetailOnlyRead
}
