export const createQueryString = <T extends Object>(obj: T | null) => {
  if (!obj) return ''
  const queryString = Object.entries(obj)
    .filter(([key, value]) => !!key && !!value)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&')

  return `?${queryString}`
}
