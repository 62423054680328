import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Checkbox from '../checkbox/Checkbox'
import { resetPaginationByParamsChanged } from '../../utils/resetPaginationByParamsChanged'

export interface IAppFilterCheckbox {
  searchParam: string
  valueItem?: string
  text?: string
  isRadio?: boolean
  white?: boolean
}

export const AppFilterCheckbox = ({
  searchParam,
  text,
  isRadio,
  valueItem,
  white,
}: PropsWithChildren<IAppFilterCheckbox>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [state, setState] = useState(false)

  useEffect(() => {
    setState(searchParams.get(searchParam) ? !!(searchParams.get(searchParam) === 'True') : false )
  },[searchParams])

  const changeHandler = (value: boolean) => {
    setState(value)
    searchParams.set(
      searchParam,
      `${valueItem ? valueItem : value ? 'True' : 'False'}`,
    )
    setSearchParams(searchParams.toString())
    resetPaginationByParamsChanged({ searchParams, setSearchParams })
  }

  return (
    <Checkbox
      name={searchParam}
      value={state}
      text={text}
      onChange={changeHandler}
      isRadioView={isRadio}
      white={white}
    />
  )
}
