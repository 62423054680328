import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import { AllRoutes } from '../../core/routes/AllRoutes'
import AppBtn, {
  AppBtnColor,
  AppBtnShadow,
  AppBtnSize,
} from '../../components/btn/AppBtn'
import CurrentDate from '../../components/currentDate/CurrentDate'

export interface IStateNavPanelMobile {
  isUnwrapOrders: boolean
  isShowReadyDishes: boolean
}

export interface IPropsNavBarBase<T> {
  stateNavPanel: T
  setStateNavPanel: (arg: T) => void
}

const HeaderWaiterMobile = ({
  stateNavPanel,
  setStateNavPanel,
}: IPropsNavBarBase<IStateNavPanelMobile>) => {
  const { data: currentUser } = useGetCurrentUserQuery()
  const navigate = useNavigate()

  return (
    <header className="header mobile">
      <div className="container-fluid container-sm">
        <div className="wrapper-heading">
          <h1
            className="title"
            onClick={() => navigate(`${AllRoutes.user.path}`)}
          >
            <span>Food</span>Box
            <span />
          </h1>
          <div>
            <h6 className="color-White text-end">
              <CurrentDate />
            </h6>
            <h6 className="color-White text-end">
              {currentUser?.userRoles.restaurant.name
                ? currentUser?.userRoles.restaurant.name
                : ''}
            </h6>
          </div>
        </div>
        <nav className="nav">
          <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-xs-1">
            <div className="col">
              <div className="btn-block">
                <AppBtn
                  sized={AppBtnSize.sm}
                  color={
                    stateNavPanel.isShowReadyDishes
                      ? AppBtnColor.whiteViolet
                      : AppBtnColor.whiteGray
                  }
                  shadow={AppBtnShadow.gray}
                  title="Выданные блюда"
                  onClick={() =>
                    setStateNavPanel({
                      ...stateNavPanel,
                      isShowReadyDishes: !stateNavPanel.isShowReadyDishes,
                    })
                  }
                />
                <AppBtn
                  sized={AppBtnSize.sm}
                  color={
                    stateNavPanel.isUnwrapOrders
                      ? AppBtnColor.whiteViolet
                      : AppBtnColor.whiteGray
                  }
                  shadow={AppBtnShadow.gray}
                  title="Развернуть заказы"
                  onClick={() =>
                    setStateNavPanel({
                      ...stateNavPanel,
                      isUnwrapOrders: !stateNavPanel.isUnwrapOrders,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default HeaderWaiterMobile
