import { useCallback, useMemo } from 'react'

interface IUseTimePickerValidValueProps {
  value?: string | null
  maxValue?: string
  minValue?: string
  onChange: (value: string) => void
}

type IUseTimePickerValidResponse = [
  hourValueValidFn: (value: string) => boolean | undefined,
  minValueValidFn: (value: string) => boolean | undefined,
  onBlur: () => void,
]

export const useTimePickerValidValue = ({
  onChange,
  value,
  maxValue,
  minValue,
}: IUseTimePickerValidValueProps): IUseTimePickerValidResponse => {
  const hourDisabled: number | false = useMemo(() => {
    if (!!maxValue) {
      return maxValue.split(':')[0] ? Number(maxValue.split(':')[0]) : false
    }
    if (!!minValue) {
      return minValue.split(':')[0] ? Number(minValue.split(':')[0]) : false
    }
    return false
  }, [minValue, maxValue])
  const minDisabled: number | false = useMemo(() => {
    if (maxValue) {
      const currentHourMax = hourDisabled <= Number(value?.split(':')[0])
      return currentHourMax && maxValue.split(':')[1]
        ? Number(maxValue.split(':')[1])
        : false
    }
    if (minValue) {
      const currentHourMax = hourDisabled >= Number(value?.split(':')[0])
      return currentHourMax && minValue.split(':')[1]
        ? Number(minValue.split(':')[1])
        : false
    }
    return false
  }, [value, hourDisabled, minValue, maxValue])

  const hourValueValidFn = useCallback(
    (value: string) => {
      if (maxValue) {
        return hourDisabled < Number(value)
      }
      if (minValue) {
        return hourDisabled > Number(value)
      }
    },
    [maxValue, minValue, hourDisabled],
  )
  const minValueValidFn = useCallback(
    (value: string) => {
      if (!!maxValue) {
        return typeof minDisabled !== 'boolean'
          ? minDisabled < Number(value)
          : false
      }
      if (!!minValue) {
        return minDisabled > Number(value)
      }
    },
    [minDisabled, maxValue, minValue],
  )

  const onBlurHandler = () => {
    if (!!value) {
      if (!!maxValue) {
        const hour =
          hourDisabled < Number(value.split(':')[0])
            ? hourDisabled.toString().padStart(2, '0')
            : value.split(':')[0]
        const min =
          (minDisabled || minDisabled === 0) &&
          minDisabled < Number(value.split(':')[1])
            ? minDisabled.toString().padStart(2, '0')
            : value.split(':')[1]

        onChange(`${hour}:${min}`)
      }
      if (!!minValue) {
        const hour =
          hourDisabled > Number(value.split(':')[0])
            ? hourDisabled.toString().padStart(2, '0')
            : value.split(':')[0]
        const min =
          minDisabled > Number(value.split(':')[1])
            ? minDisabled.toString().padStart(2, '0')
            : value.split(':')[1]
        onChange(`${hour}:${min}`)
      }
    }
  }

  return [hourValueValidFn, minValueValidFn, onBlurHandler]
}
