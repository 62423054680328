import React from 'react'
import './AddTaskItemButton.css'

export interface IAddTaskItemButton {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  loading?: boolean
  width?: string
}

export const AddTaskItemButton: React.FC<IAddTaskItemButton> = (props) => {
  const { loading, onClick, width } = props
  return (
    <button
      className="employee-task-add-task-item-button"
      onClick={onClick}
      disabled={loading}
      style={{ width }}
    >
      <i className="an-ico an-ico-add" />
    </button>
  )
}
