import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  useAddCategoryMutation,
  useGetCategoryByRestaurantQuery,
} from '../../core/api/CategoryApi'
import { ICategory } from '../../core/api/dto/CategoriesObjDto'
import InputField, { InputSize } from '../input/InputField'
import { AppNotification, NotificationType } from '../notification/Notification'
import PhotoUploader, {
  PhotoUploaderSize,
} from '../photoUploader/PhotoUploader'
import Spinner from '../spinner/Spinner'
import Popup, { PopupBtnBgColor, PopupSize } from './Popup'

interface IPopupProps {
  setIsShowPopup: (arg: boolean) => void
}

interface INewCategory {
  name: string
  iconUrl: null | string
}

const AddCategoryPopup = ({ setIsShowPopup }: IPopupProps) => {
  const [addCategory] = useAddCategoryMutation()
  const [disabled, setDisabled] = useState(false)
  const [newCategory, setNewCategory] = useState<INewCategory>({
    name: '',
    iconUrl: null,
  })
  const [isValid, setIsValid] = useState(false)
  const navigate = useNavigate()
  const { technologicalCatalogRestaurantId } = useParams()
  const { data: allCategories } = useGetCategoryByRestaurantQuery(
    technologicalCatalogRestaurantId!,
    { skip: !technologicalCatalogRestaurantId },
  )

  const closePopup = () => {
    if (!disabled) {
      setIsShowPopup(false)
    }
  }

  const validateCategoryName = useCallback(
    (val: string) => {
      const name = val.trim()
      if (allCategories?.results.length && name) {
        const result = allCategories.results.findIndex(
          (el: ICategory) => el.name === name,
        )

        return result === -1 ? true : false
      } else if (!allCategories?.results.length && name) {
        return true
      }

      return false
    },
    [allCategories],
  )

  const addNewCategory = () => {
    if (isValid && !disabled) {
      setDisabled(true)
      addCategory({
        name: newCategory.name.trim(),
        restaurant: Number(technologicalCatalogRestaurantId),
        iconUrl: newCategory.iconUrl,
      })
        .unwrap()
        .then((res) => {
          AppNotification({
            msg: 'Категория добавлена!',
            type: NotificationType.success,
          })
          navigate(`${technologicalCatalogRestaurantId}/${res.id}`)
        })
        .finally(() => {
          setDisabled(false)
          closePopup()
        })
    }
  }

  const actionsAddCategory = [
    {
      text: disabled ? <Spinner /> : 'Создать свою категорию',

      onClick: addNewCategory,
      bgColor: !isValid ? PopupBtnBgColor.grayMedium : PopupBtnBgColor.violet,
    },
  ]

  const setNewCategoryName = useCallback(
    (val: string) => {
      setIsValid(validateCategoryName(val))
      setNewCategory((prev) => ({ ...prev, name: val }))
    },
    [setNewCategory, validateCategoryName, setIsValid],
  )

  const setNewCategoryPhoto = useCallback(
    (val: string | null) =>
      setNewCategory((prev) => ({ ...prev, iconUrl: val })),
    [setNewCategory],
  )

  return (
    <Popup
      size={PopupSize.md}
      actions={actionsAddCategory}
      closePopup={closePopup}
    >
      <div className="popup-content create-category">
        <div className="popup-icon-close">
          <i className="an-ico an-ico-close" onClick={closePopup} />
        </div>
        <h4>Создание новой категории</h4>
        <p>Введите название новой категории:</p>
        <InputField
          sized={InputSize.sm}
          value={newCategory.name}
          onChange={setNewCategoryName}
          required={true}
        />
        {newCategory.name.trim() && !isValid ? (
          <p className="alert-message">
            <i className="an-ico an-ico-alert" />
            <span>
              Категория с таким названием уже существует, измените название
            </span>
          </p>
        ) : null}
        <PhotoUploader
          size={PhotoUploaderSize.sm}
          photo={newCategory.iconUrl}
          setPhoto={setNewCategoryPhoto}
        />
      </div>
    </Popup>
  )
}

export default AddCategoryPopup
