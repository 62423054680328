import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { IActionsData, IAppTableBase } from './AppTable'
import { ColPagination } from './tableMobileComponents/ColPagination'
import { TableColgroup } from './tableMobileComponents/TableColgroup'
import { THeadMobileBlock } from './tableMobileComponents/THeadMobileBlock'
import { TableMobileRow } from './tableMobileComponents/TableMobileRow'
import { useSearchParams } from 'react-router-dom'
import { AppScrollWrapperNew } from '../../widgets/AppScrollWrapper/AppScrollWrapper'
import { IChooseItems } from '../mainContent/TableAllDishes'
import { TableActionsBlock } from './tableComponents/TableActionsBlock'
import { TablePaginationBlock } from './tableComponents/TablePaginationBlock'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'

interface IAppTableMobileProps<T, TKey> extends IAppTableBase<T, TKey> {
  params?: URLSearchParams
  isDefaultOpen?: boolean
  rowClassName?: string
  tableCellClassName?: string
  tableClassName?: string
  tableWrapperClassName?: string
  toggleAllState?: number[]
  setToggleAllState?: Dispatch<SetStateAction<number[]>>

  chooseActions?: IActionsData[]
  chooseMode?: boolean
  chooseItems?: IChooseItems | null
  setChooseItems?: React.Dispatch<React.SetStateAction<IChooseItems | null>>
}

export const AppTableMobile = <
  T extends { id: number; archived?: boolean },
  TKey extends keyof T,
>({
  params,
  isDefaultOpen,
  headerData,
  data,
  tableDataSelectors,
  subTableRepresent,
  setCurrentSortFn,
  rowClassName,
  tableCellClassName,
  tableClassName,
  tableWrapperClassName,
  toggleAllState,
  setToggleAllState,
  chooseMode,
  chooseActions,
  chooseItems,
  setChooseItems,
  isLoading,
}: IAppTableMobileProps<T, TKey>) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams(params)
  const offset = Number(searchParams.get('offset')) || 0
  const [loadMore, setLoadMore] = useState(false)
  const { width } = useGetWidth()
  const mobileMode = useMemo(() => width < 600, [width])
  const tabletMode = useMemo(() => width <= 1200, [width])
  const [tableData, setTableData] = useState<T[]>([])
  const [currentCol, setCurrentCol] = useState(1)
  const tableRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    setCurrentCol(1)
  },[width])

  useEffect(() => {
    if (data.results && !offset && !loadMore && !data.previous) {
      setTableData(data.results)
    }
    if (data.results && !!offset && loadMore) {
      setTableData((prev) => [...prev, ...data.results])
      setLoadMore(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const chooseItemsHandler = useCallback((value: boolean, id: number) => {
    setChooseItems &&
      setChooseItems((prevState) => ({ ...prevState, [id]: value }))
  }, [])

  const isAllOpen = !!toggleAllState && toggleAllState.length < tableData.length
  const onToggleAll = () => {
    if (isAllOpen) {
      setToggleAllState?.(tableData.map((item, index) => index))
    } else {
      setToggleAllState?.([])
    }
  }
  return (
    <div className="app-table-mobile-wrap">
      <TableActionsBlock
        chooseActions={chooseActions}
        tableData={tableData}
        chooseItems={chooseItems}
        setChooseItems={setChooseItems}
        chooseMode={chooseMode}
      />
      {(mobileMode || tabletMode) && (
        <ColPagination
          setCurrentCol={setCurrentCol}
          totalCol={tableDataSelectors.length - 1}
          currentCol={currentCol}
        />
      )}
      <div
        className={`app-table-mobile ${tableWrapperClassName || ''}`}
        ref={tableRef}
      >
        <AppScrollWrapperNew
          theme={'table'}
          trigger={toggleAllState?.join(',')}
        >
          <table className={tableClassName}>
            <TableColgroup
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              headerData={headerData}
              chooseMode={chooseMode}
            />
            <THeadMobileBlock
              isAllOpen={isAllOpen}
              onToggleAll={
                toggleAllState && setToggleAllState ? onToggleAll : undefined
              }
              headerData={headerData}
              mobileMode={mobileMode || tabletMode}
              currentCol={currentCol}
              setCurrentCol={setCurrentCol}
              setCurrentSortFn={setCurrentSortFn}
              chooseMode={chooseMode}
            />
            <tbody>
              {!tableData.length && (
                <tr>
                  <td
                    colSpan={tableDataSelectors.length}
                    className="result-not-found"
                  >
                    <div>{t('Выберите фильтры')}</div>
                  </td>
                </tr>
              )}
              {tableData.map((item, itemIndex) => {
                return (
                  <TableMobileRow
                    setToggleAllState={setToggleAllState}
                    toggleAllState={toggleAllState}
                    tableCellClassName={tableCellClassName}
                    rowClassName={rowClassName}
                    isDefaultOpen={isDefaultOpen}
                    tableDataSelectors={tableDataSelectors}
                    item={item}
                    itemIndex={itemIndex}
                    key={`table-row-c-${item?.id || itemIndex}`}
                    subTableRepresent={subTableRepresent}
                    mobileMode={mobileMode || tabletMode}
                    currentCol={currentCol}
                    setCurrentCol={setCurrentCol}
                    chooseItemsHandler={chooseItemsHandler}
                    chooseItems={chooseItems}
                    chooseMode={chooseMode}
                  />
                )
              })}
            </tbody>
          </table>
        </AppScrollWrapperNew>
        <TablePaginationBlock
          isLoading={!!isLoading}
          dataCount={data.count}
          dataNext={!!data.next}
          tableRef={tableRef}
        />
      </div>
    </div>
  )
}
