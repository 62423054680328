import moment from 'moment'
import React, { memo } from 'react'
import { compareProps } from '../../utils'

interface IStatus {
  title: string
  time: string
}

const StatusInfo = ({ title, time }: IStatus) => {
  return (
    <div className="status">
      <div className="name">{title}</div>
      <div className="m-l-auto">
        {time.length > 5 ? moment(time).format('HH:mm') : time}
      </div>
    </div>
  )
}

const StatusInfoMemoized = memo(StatusInfo, compareProps)
export default StatusInfoMemoized
