import { api } from './BaseApi'
import {
  ICreateTaskDto,
  ICreateTasksComment,
  ITaskDto,
  ITaskObjectCreateDto,
  ITaskObjectCreateResponseDto,
  ITaskObjectRequestDto,
  ITaskObjectResponseDto,
  ITasksCommentDto,
  ITasksCommentsRequestDto,
  ITasksCommentsResponseDto,
  ITasksRequestDto,
  ITasksResponseDto,
} from './dto/TaskDto'

export const taskApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<ITasksResponseDto, ITasksRequestDto>({
      query: (params) => ({
        url: '/employee_tasks/employee_tasks/',
        params: params ? params : {},
      }),
      providesTags: ['Task', 'User'],
    }),
    getTaskById: builder.query<ITaskDto, { id: number }>({
      query: ({ id }) => ({
        url: `/employee_tasks/employee_tasks/${id}/`,
      }),
      providesTags: ['Task'],
    }),
    getUnreadTaskCount: builder.query<{ count: number }, null>({
      query: () => ({
        url: `/employee_tasks/get_unread_task_count/`,
      }),
      providesTags: ['Task'],
    }),
    createTask: builder.mutation<ITasksResponseDto, ICreateTaskDto>({
      query: (newTask) => ({
        method: 'POST',
        url: `/employee_tasks/employee_tasks/`,
        body: newTask,
      }),
      invalidatesTags: ['Task'],
    }),
    updateTask: builder.mutation<
      ITasksResponseDto,
      { id: number; newTask: ICreateTaskDto }
    >({
      query: ({ id, newTask }) => ({
        method: 'PATCH',
        url: `/employee_tasks/employee_tasks/${id}/`,
        body: newTask,
      }),
      invalidatesTags: ['Task'],
    }),
    getComments: builder.query<
      ITasksCommentsResponseDto,
      ITasksCommentsRequestDto
    >({
      query: (params) => ({
        url: '/employee_tasks/comments/',
        params: params ? params : {},
      }),
      providesTags: ['Task'],
    }),
    createTaskComment: builder.mutation<ITasksCommentDto, ICreateTasksComment>({
      query: (body) => ({
        method: 'POST',
        url: '/employee_tasks/comments/',
        body,
      }),
      invalidatesTags: ['Task'],
    }),
    getTaskObjects: builder.query<
      ITaskObjectResponseDto,
      ITaskObjectRequestDto
    >({
      query: (params) => ({
        url: '/employee_tasks/task_objects/',
        params: params ? params : {},
      }),
      providesTags: ['Task'],
    }),
    createTaskObject: builder.mutation<
      ITaskObjectCreateResponseDto,
      ITaskObjectCreateDto
    >({
      query: (body) => ({
        method: 'POST',
        url: '/employee_tasks/task_objects/',
        body,
      }),
      invalidatesTags: ['Task'],
    }),
    deleteMultipleObjects: builder.mutation<
      { count: number },
      { task: number; task_objects: number[] }
    >({
      query: ({ task, task_objects }) => ({
        method: 'POST',
        url: `/employee_tasks/delete_many_task_objects/`,
        body: { task, task_objects },
      }),
      invalidatesTags: ['Task'],
    }),
    archiveMultipleTasks: builder.mutation<
      { count: number },
      { tasks: number[] }
    >({
      query: ({ tasks }) => ({
        method: 'POST',
        url: `/employee_tasks/delete_many_tasks/`,
        body: { tasks },
      }),
      invalidatesTags: ['Task'],
    }),
  }),
})

export const {
  useGetTasksQuery,
  useGetUnreadTaskCountQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useGetTaskObjectsQuery,
  useGetCommentsQuery,
  useCreateTaskCommentMutation,
  useGetTaskByIdQuery,
  useCreateTaskObjectMutation,
  useDeleteMultipleObjectsMutation,
  useArchiveMultipleTasksMutation,
} = taskApi
