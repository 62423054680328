import React, { useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { AppFilterDateInput } from '../../../components/AppInput/AppFilterDateInput'
import { useGetBuffetDishSimpleListQuery } from '../../../core/api/BuffetDishApi'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { IBaseSimpleList } from '../../../core/api/dto/BaseDto'
import Checkbox from '../../../components/checkbox/Checkbox'
import { useParamsControl } from '../../../utils/useParamsControl'
import { AppFilterDropdown } from '../../../components/AppDropdown/AppFilterDropdown'
import { IPlanMenuTypeDto } from '../../../core/api/dto/PlanMenuTypeDto'
import { useGetPlanMenuTypeListQuery } from '../../../core/api/PlanMenuTypeApi'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useGetStationsQuery } from 'core/api/StationsApi'
import { IBuffetGeneralReportRequest } from 'core/api/dto/BuffetGeneralReportDto'
import './BuffetReportsVZ.scss'
import {
  DimensionTranslateType,
  DimensionType,
} from 'core/api/dto/BuffetReportDto'
import { AppFilterTimeInput } from 'components/AppInput/AppFilterTimeInput'
import { AppFilterMultiDropdown } from 'components/AppDropdown/AppFilterMultiDropdown'
import { dimensionTypeData } from '../PlanMenuControl/PlanMenuControlAdmin'

export enum ShowFormatType {
  'full' = 'full',
  'visual' = 'visual',
  'intensity' = 'intensity',
  'temperature' = 'temperature',
  'volume' = 'volume',
}

interface ShowFormatItem {
  searchParam: string
  value: ShowFormatType
  name: string
}

interface IDimensionTypeData {
  name: DimensionTranslateType
  value: DimensionType
}

const showFormatData: ShowFormatItem[] = [
  {
    searchParam: 'report_type',
    value: ShowFormatType.full,
    name: 'По всем данным',
  },
  {
    searchParam: 'report_type',
    value: ShowFormatType.volume,
    name: 'Объем выдаваемой продукции',
  },
  {
    searchParam: 'report_type',
    value: ShowFormatType.temperature,
    name: 'Температурный',
  },
  {
    searchParam: 'report_type',
    value: ShowFormatType.visual,
    name: 'Визуальный',
  },
  {
    searchParam: 'report_type',
    value: ShowFormatType.intensity,
    name: 'Интенсивность выдаваемой продукции',
  },
]

const timeIntervalData = [
  {
    name: '15 минут',
    value: 15,
  },
  {
    name: '30 минут',
    value: 30,
  },
  {
    name: '1 час',
    value: 60,
  },
  {
    name: '3 часа',
    value: 180,
  },
]

export const BuffetReportsVZ = () => {
  const { t } = useTranslation()
  const pageParams = useParamsControl<
    IBuffetGeneralReportRequest,
    keyof IBuffetGeneralReportRequest
  >({
    withPagination: true,
    whiteList: [
      'report_type',
      'dimension_date_gte',
      'dimension_date_lte',
      'dimension_types',
      'station_ids',
      'dish_ids',
      'plan_menu_type_ids',
      'dimension_time_gte',
      'dimension_time_lte',
    ],
  })
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFormat, setShowFormat] = useState(
    () =>
      showFormatData.find(
        (item) =>
          item.value === (searchParams.get('report_type') as ShowFormatType),
      ) || showFormatData[0],
  )
  const disabledApproveFilter = false

  const onApproveFilter = () => {
    searchParams.set('report_type', showFormat.value)
    if (!searchParams.get('dimension_date_gte')) {
      searchParams.set('dimension_date_gte', moment().format('YYYY-MM-DD'))
    }
    if (!searchParams.get('dimension_date_lte')) {
      searchParams.set('dimension_date_lte', moment().format('YYYY-MM-DD'))
    }
    navigate(
      `${AllRoutes.buffetReportsVZLine.path}/?${searchParams.toString()}`,
    )
  }

  const clearFilterHandler = () => {
    setShowFormat(showFormatData[0])
    setSearchParams('')
  }

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Применить фильтр',
        color: AppBtnColor.violetWhite,
        onClick: onApproveFilter,
        disabled: disabledApproveFilter,
      },
      {
        btnTitle: 'Сбросить фильтр',
        color: AppBtnColor.extraVioletViolet,
        onClick: clearFilterHandler,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      disabledApproveFilter,
      pageParams,
      showFormat,
      clearFilterHandler,
      onApproveFilter,
    ],
  )

  const onShowFormatHandler = (item: ShowFormatItem) => () => {
    searchParams.set('report_type', item.value)
    setSearchParams(searchParams.toString())
    setShowFormat(item)
  }

  return (
    <div className="width-for-page-wrapper general-report-wrap">
      <AppTitlePage title="Отчёты" actions={actions} />
      <div className="filter-block mt-20">
        <div>
          <label>
            {t('Дата')}
            <span className="color-Red"> *</span>
          </label>
          <div className="input-row mt-10">
            <AppFilterDateInput
              searchParam={'dimension_date_gte'}
              defaultValue={moment().format('YYYY-MM-DD')}
              maxValue={
                pageParams?.dimension_date_lte || moment().format('MM.DD.YYYY')
              }
            />
            <AppFilterDateInput
              searchParam={'dimension_date_lte'}
              defaultValue={moment().format('YYYY-MM-DD')}
              maxValue={moment().format('MM.DD.YYYY')}
              minValue={pageParams?.dimension_date_gte}
            />
          </div>
        </div>
        <div className="time-inputs-wrap">
          <label>{t('Время')}</label>
          <div className="input-row mt-10">
            <AppFilterTimeInput searchParam={'dimension_time_gte'} />
            <AppFilterTimeInput searchParam={'dimension_time_lte'} />
          </div>
        </div>
      </div>
      <div className="radio-filter-block mt-20">
        <span className="text">{t('Название отчета')}</span>
        <div className="radio-list mt-20">
          {showFormatData.map((item) => (
            <Checkbox
              name={item.value}
              key={item.value}
              value={item.value === showFormat.value}
              text={t(item.name)}
              isRadioView
              onChange={onShowFormatHandler(item)}
            />
          ))}
        </div>
      </div>

      <div className="comparative-filter-block">
        <span className="title">{t('Выбрать фильтр')}</span>
        <AppFilterMultiDropdownWithPagination<
          IBaseSimpleList,
          keyof IBaseSimpleList
        >
          searchParam={'station_ids'}
          propToShowInList={'name'}
          valuePropName={'id'}
          label={'Станция'}
          placeholder={'Выбрать'}
          fullWidth
          getterData={useGetStationsQuery}
        />
        <AppFilterMultiDropdownWithPagination<
          IBaseSimpleList,
          keyof IBaseSimpleList
        >
          searchParam={'dish_ids'}
          propToShowInList={'name'}
          valuePropName={'id'}
          label={'Названия блюд'}
          placeholder={'Выбрать'}
          fullWidth
          getterData={useGetBuffetDishSimpleListQuery}
        />
        <AppFilterMultiDropdown<
          IDimensionTypeData, 
          keyof IDimensionTypeData
          >
          searchParam={'dimension_types'}
          data={dimensionTypeData}
          propToShowInList={'name'}
          valuePropName={'value'}
          label={'Тип замера'}
          placeholder={'Выбрать'}
        />
        <AppFilterMultiDropdownWithPagination<
          IPlanMenuTypeDto,
          keyof IPlanMenuTypeDto
        >
          searchParam={'plan_menu_type_ids'}
          getterData={useGetPlanMenuTypeListQuery}
          propToShowInList={'name'}
          valuePropName={'id'}
          label={'Тип план-меню'}
          placeholder={'Выбрать'}
          fullWidth
        />
        {pageParams?.report_type === ShowFormatType.intensity && (
          <AppFilterDropdown
            searchParam={'interval_minutes'}
            data={timeIntervalData}
            valuePropName={'value'}
            propToShowInList={'name'}
            label={'Интервал времени'}
            placeholder={'15 минут'}
            minHeight
            fullWidth
          />
        )}
      </div>
    </div>
  )
}
