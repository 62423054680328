import React, { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import './ChartPaginationBlock.scss'

interface TablePaginationBlockProps {
  dataNext?: string | null
  dataPrevious?: string | null
  dataCount?: number
  date?: string | null
}

export const ChartPaginationBlock = ({
  dataNext,
  dataPrevious,
  dataCount,
  date,
}: TablePaginationBlockProps) => {
  const [, setSearchParams] = useSearchParams()

  const nextPageHandler = useCallback(() => {
    setSearchParams(dataNext?.split('?')[1])
  }, [dataNext, setSearchParams])

  const previousPageHandler = useCallback(() => {
    setSearchParams(dataPrevious?.split('?')[1])
  }, [setSearchParams, dataPrevious])

  return (
    <div className="chart-buttons-block">
      <button onClick={previousPageHandler} disabled={!dataPrevious} className={`${!dataPrevious ? 'btn-disabled' : ''}`}>
        <i className="an-ico an-ico-arrow-l" />
      </button>
      <div>{moment(date).format('DD.MM.YYYY')}</div>
      <button onClick={nextPageHandler} disabled={!dataNext} className={`${!dataNext ? 'btn-disabled' : ''}`}>
        <i className="an-ico an-ico-arrow-r" />
      </button>
    </div>
  )
}
