import React from 'react'
import { useNavigate } from 'react-router'
import { useAppSelector } from '../../core/store/store'

interface IWorkshopCard {
  title: string
  path: string
}

const WorkshopCard = ({ title, path }: IWorkshopCard) => {
  const { isFullModeWorkshopCard } = useAppSelector((state) => state.buffet)
  const navigate = useNavigate()
  return (
    <div
      className={`workshop-card ${isFullModeWorkshopCard ? '' : 'sm'}`}
      onClick={() => navigate(path)}
    >
      {title}
    </div>
  )
}

export default WorkshopCard
