import React, { memo } from 'react'
import { IOrderLine } from '../../core/api/dto/OrderObjDto'
import { compareProps } from '../../utils'
import BuffetCard from './BuffetCard'

export const sortByAlphabet = (a: IOrderLine, b: IOrderLine): number =>
  a.dish.name.toLowerCase() > b.dish.name.toLowerCase() ? 1 : -1

interface IPropsBuffetCards {
  cards: IOrderLine[]
  handlerSetActiveDish: (el: IOrderLine) => void
}
const BuffetCards = ({ cards, handlerSetActiveDish }: IPropsBuffetCards) => {

  return (
    <>
      {[...cards].sort(sortByAlphabet).map((el, idx) => (
        <div className="col" key={`cards-${el.dish.id}-${idx}`}>
          <BuffetCard
            info={el}
            setActiveDish={() => handlerSetActiveDish(el)}
          />
        </div>
      ))}
    </>
  )
}

export const BuffetCardsMemoized = memo(BuffetCards, compareProps)
