import { api } from './BaseApi'
import { IRestaurant, RestaurantsObjDto } from './dto/RestaurantsObjDto'

export const restaurantApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRestaurants: builder.query<RestaurantsObjDto, null>({
      query: () => ({
        url: '/restaurant/',
      }),
      providesTags: ['Restaurant'],
    }),
    getRestaurantById: builder.query<IRestaurant, string>({
      query: (id) => ({
        url: `/restaurant/${id}/`,
      }),
      providesTags: ['Restaurant'],
    }),
  }),
})

export const { useGetRestaurantsQuery, useGetRestaurantByIdQuery } =
  restaurantApi
