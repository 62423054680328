import React from 'react'
import Popup from 'components/popup/Popup'
import './NewDimensionErrorPopup.css'
import {
  IBuffetDimension,
  IOrdersDishTemperatureResponseDto,
} from 'core/api/dto/OrderObjDto'
import { useTranslation } from 'react-i18next'

interface INewDimensionErrorPopupProps {
  onAddDimension: (dim: IBuffetDimension) => void
  newDimension: IBuffetDimension
  loading: boolean
  dishTemperature: IOrdersDishTemperatureResponseDto
  setIsGetTempFetch: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>
}

export const NewDimensionErrorPopup: React.FC<INewDimensionErrorPopupProps> = (
  props,
) => {
  const {
    onAddDimension,
    newDimension,
    loading,
    dishTemperature,
    setIsGetTempFetch,
    setIsOpenPopup
  } = props
  const { t } = useTranslation()

  const onCancelClick = () => {
    setIsGetTempFetch(false)
    setIsOpenPopup(false)
  }

  const onSubmitClick = () => {
    onAddDimension(newDimension)
  }

  return (
    <Popup borderRadiusMin={true} className="popup-temp">
      <div className="new-dimension-error-popup__container">
        <div className="new-dimension-error-popup__header">
          {t('Предупреждение')}
        </div>
        <div className="new-dimension-error-popup__main">
          <div>{t('Температура блюда не соответствует заданным нормам')}</div>
          <div>
            <div>
              {t('Эталонная')}: {dishTemperature.standardTempMin}-
              {dishTemperature.standardTempMax}
            </div>
            <div>
              {t('Фактическая')}: {dishTemperature.tempCurrent}
            </div>
          </div>

          <div className="new-dimension-error-popup__buttons">
            <button
              onClick={onCancelClick}
              className="new-dimension-error-popup__button__cancel"
            >
              {t('Отмена')}
            </button>
            <button
              onClick={onSubmitClick}
              className={`new-dimension-error-popup__button__submit ${
                loading ? 'disabled' : ''
              }`}
            >
              {t('Продолжить')}
            </button>
          </div>
        </div>
      </div>
    </Popup>
  )
}
