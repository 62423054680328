import { useState } from 'react'
import { mockDataReport } from '../../mock'
import AppBtn, { AppBtnColor, AppBtnSize } from '../btn/AppBtn'
import { FormatDate, initDate } from '../calendar/Calendar'
import DatePicker from '../datePicker/DatePicker'
import TimePicker from '../timePicker/TimePicker'

const nameColumns = [
  'Номер',
  'Наименование ',
  'Вес Эталонный (кг)',
  'Вес Фактический (кг) ',
  'Температура Эталонная',
  'Температура  Фактическая (мин)',
  'Температура Фактическая (средняя)',
  'Температура Фактическая (макс)',
]

export interface IItemReportTable {
  id: number
  name: string
  weightPlan: number
  weightFact: number | null
  tempPlan: number | null
  tempFactMin: number | null
  tempFactMax: number | null
  tempMiddle: number | null
}

export interface IFilterState {
  date: string
  timeFrom: string
  timeTo: string
}

const AdminReport = () => {
  const [filterState, setFilterState] = useState<IFilterState>({
    date: initDate(FormatDate.BASE_DATE),
    timeFrom: '',
    timeTo: '',
  })

  const changeStateHandler = (value: string, fieldName: keyof IFilterState) => {
    setFilterState((prev) => ({
      ...prev,
      [fieldName]: value,
    }))
  }

  return (
    <>
      <div className="filter-panel">
        <div className="row">
          <div className="col-xxl-10 col-xl-10 col-lg-10">
            <div className="filters">
              <h4>Фильтры</h4>
              <DatePicker
                value={filterState.date}
                onChange={(val) => changeStateHandler(val, 'date')}
              />
              <TimePicker
                text="c"
                value={filterState.timeFrom}
                onChange={(val) => changeStateHandler(val, 'timeFrom')}
              />
              <TimePicker
                text="по"
                value={filterState.timeTo}
                onChange={(val) => changeStateHandler(val, 'timeTo')}
              />
              <AppBtn
                sized={AppBtnSize.sm}
                color={AppBtnColor.violetWhite}
                title="Применить"
              />
            </div>
          </div>
          <div className="col-xxl-2 col-xl-2 col-lg-2">
            <div className="btn-block">
              <AppBtn
                sized={AppBtnSize.sm}
                color={AppBtnColor.violetWhite}
                title="Выгрузить в XL"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-table">
        <table className="table">
          <colgroup>
            <col style={{ width: '100px' }} />
            <col style={{ width: '250px' }} />
            <col style={{ width: '140px' }} />
            <col span={2} style={{ width: '160px' }} />
            <col style={{ width: '170px' }} />
            <col style={{ width: '210px' }} />
            <col style={{ width: '180px' }} />
          </colgroup>
          <thead>
            <tr>
              {nameColumns.map((el) => (
                <th key={el}>{el}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mockDataReport.map((el) => (
              <tr key={`report-item-${el.id}`}>
                <td>{el.id}</td>
                <td>{el.name}</td>
                <td>{el.weightPlan}</td>
                <td>{el.weightFact}</td>
                <td>{el.tempPlan}</td>
                <td>{el.tempFactMin}</td>
                <td>{el.tempMiddle}</td>
                <td>{el.tempFactMax}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination countPage={5} /> */}
    </>
  )
}

export default AdminReport
