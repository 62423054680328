import {
  IOrderLineDimensionTask,
  IOrderLineTask,
  IOrderTask,
  ITaskObjectDto,
  ObjectTypeEnum,
} from '../../../../../core/api/dto/TaskDto'
import moment from 'moment'
import { AllRoutes } from '../../../../../core/routes/AllRoutes'

interface ITaskObjectTableShowerProps {
  fieldName: 'date' | 'time' | 'link'
  item: ITaskObjectDto
}

const orderShow = (fieldName: 'date' | 'time' | 'link', order: IOrderTask) => {
  switch (fieldName) {
    case 'date':
      return moment(order.orderDate).format('DD.MM.YYYY')
    case 'time':
      return moment(order.orderDate).format('HH:mm')
    case 'link':
      return `/${AllRoutes.planMenuControl.path}?order=${order.id}`
  }
}
const lineShow = (
  fieldName: 'date' | 'time' | 'link',
  orderLine: IOrderLineTask,
) => {
  switch (fieldName) {
    case 'date':
      return moment(orderLine.dish.createdAt).format('DD.MM.YYYY')
    case 'time':
      return moment(orderLine.dish.createdAt).format('HH:mm')
    case 'link':
      return `/${AllRoutes.planMenuControl.path}?order=${orderLine.order.id}&dish=${orderLine.id}`
  }
}

const dimensionShow = (
  fieldName: 'date' | 'time' | 'link',
  orderLineDimension: IOrderLineDimensionTask,
) => {
  switch (fieldName) {
    case 'date':
      return moment(orderLineDimension.dimensionDatetime).format('DD.MM.YYYY')
    case 'time':
      return moment(orderLineDimension.dimensionDatetime).format('HH:mm')
    case 'link':
      return `/${AllRoutes.planMenuControl.path}?order=${orderLineDimension.orderLine.order.id}&dish=${orderLineDimension.orderLine.id}&dimension=${orderLineDimension.id}`
  }
}

export const taskObjectTableShower = ({
  fieldName,
  item,
}: ITaskObjectTableShowerProps) => {
  switch (item.objectType) {
    case ObjectTypeEnum.ORDER: {
      return orderShow(fieldName, item.order!)
    }
    case ObjectTypeEnum.LINE: {
      return lineShow(fieldName, item.orderLine!)
    }
    default: {
      return dimensionShow(fieldName, item.orderLineDimension!)
    }
  }
}
