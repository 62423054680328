import React, { useState } from 'react'
import InputFile from '../input/InputFile'
import Popup from '../popup/Popup'

export enum PhotoUploaderSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

interface IUploadPhotoProps {
  photo: string | null
  setPhoto: (val: string | null) => void
  isEditable?: boolean
  iconClassName?: string
  size?: PhotoUploaderSize
}

const PhotoUploader = ({
  photo,
  setPhoto,
  isEditable = true,
  iconClassName,
  size = PhotoUploaderSize.md,
}: IUploadPhotoProps) => {
  const [isZoomPhoto, setIsZoomPhoto] = useState(false)
  return (
    <div className={`photo-uploader ${size}`}>
      {isZoomPhoto && photo && (
        <Popup closePopup={() => setIsZoomPhoto(false)}>
          <img src={photo} alt="dish" className="img-child-popup" />
        </Popup>
      )}

      {photo ? (
        <div className="wrapper-photo">
          <img src={photo} alt="" className="photo" />
          {iconClassName && (
            <div
              className="wrapper-icon-l"
              onClick={() => setIsZoomPhoto(true)}
            >
              <i className={`an-ico an-ico-${iconClassName}`} />
            </div>
          )}
          {isEditable && (
            <div className="wrapper-icon-r" onClick={() => setPhoto(null)}>
              <i className="an-ico an-ico-basket" />
            </div>
          )}
        </div>
      ) : (
        <InputFile
          className="input-file"
          label="Прикрепите фотографию"
          onChange={setPhoto}
          disabled={!isEditable}
        />
      )}
    </div>
  )
}

export default PhotoUploader
