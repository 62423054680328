export enum LoaderType {
  table = 'table-loader',
  main = 'main-loader',
  dropdown = 'dropdown-loader',
  dimension = 'dimension-loader',
}

interface AppLoader {
  loaderType: LoaderType
  relative?: boolean
  white?: boolean
}

export const AppLoader = ({ loaderType, relative, white }: AppLoader) => {
  return (
    <div
      className={`loader ${loaderType}`}
      style={relative ? { position: 'relative' } : {}}
    >
      <div className={`lds-ring ${white ? 'white' : ''}`}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
