import { ICategoriesResponseDto } from '../core/api/dto/CategoriesObjDto'
import { PopupBtnBgColor } from '../components/popup/Popup'
import { IItemReportTable } from '../components/mainContent/AdminReport'
import { OrderType, StatusOrder } from '../core/api/dto/OrderObjDto'
import { IStateDish } from '../components/mainContent/CreateUpdateDish'
import { DimensionType } from 'core/api/dto/BuffetReportDto'
import { IPlanMenuControlDimensionDto } from 'core/api/dto/PlanMenuControlDto'
export const mockRestaurants = ['Кухмистр', 'Друзья', 'Гости']

export const mockTest = [
  { value: 'test', number: 11 },
  { value: 'test2', number: 112 },
]
export const popupContentSuccess = {
  iconName: 'popup',
  title: 'Успешно',
  text: 'Технологическая карта добавлена',
}

export const popupActionsSuccess = [
  {
    text: 'Продолжить',
    onClick: () => console.log('здесь будет какое-то действие'),
    bgColor: PopupBtnBgColor.violet,
  },
]

export const mockDataSidebar: ICategoriesResponseDto = {
  count: 5,
  next: '10',
  previous: '0',
  results: [
    {
      name: 'Холодные закуски',
      iconUrl: '../../../../../assets/images/fish-icon.svg',
      id: 1,
      restaurant: 1,
      iconPreview: null,
      archived: false,
      buffetDishCount: 0,
    },
    {
      name: 'Блюдо от шефа',
      iconUrl: '../../../../../assets/images/chef-icon.svg',
      id: 2,
      restaurant: 1,
      iconPreview: null,
      archived: false,
      buffetDishCount: 0,
    },
    {
      name: 'Блюда из яиц и творога',
      iconUrl: '../../../../../assets/images/milk-icon.svg',
      id: 3,
      restaurant: 2,
      iconPreview: null,
      archived: false,
      buffetDishCount: 0,
    },
    {
      name: 'Салаты',
      iconUrl: '../../../../../assets/images/cabbege-icon.svg',
      id: 4,
      restaurant: 1,
      iconPreview: null,
      archived: false,
      buffetDishCount: 0,
    },
    {
      name: 'Горячие закуски',
      iconUrl: '../../../../../assets/images/pizza-icon.svg',
      id: 5,
      restaurant: 1,
      iconPreview: null,
      archived: false,
      buffetDishCount: 0,
    },
    {
      name: 'Вторые блюда',
      id: 6,
      restaurant: 2,
      iconUrl: '../../../../../assets/images/chicken-icon.svg',
      iconPreview: null,
      archived: false,
      buffetDishCount: 0,
    },
    {
      name: 'Фрукты',
      iconUrl: '../../../../../assets/images/kiwi-icon.svg',
      id: 7,
      restaurant: 2,
      iconPreview: null,
      archived: false,
      buffetDishCount: 0,
    },
    {
      name: 'Десерты',
      iconUrl: '../../../../../assets/images/ice-cream-icon.svg',
      id: 8,
      restaurant: 2,
      iconPreview: null,
      archived: false,
      buffetDishCount: 0,
    },
  ],
}

export const sorts = [
  {
    iconName: 'arrows',
    value: 'От А до Я',
    reverseValue: 'От Я до А',
    name: 'alphabet',
  },
  {
    value: 'От новых к старым',
    reverseValue: 'От старых к новым',
    name: 'by date',
  },
]

export const mockUser = {
  id: 588554,
  email: '6227968jf.com',
  name: 'Алексей Ревякин',
  user_roles: {
    role_type: 'CHEF',
    id: 852,
    expire_date: '2022/22/05',
    user: 85,
  },
}

export const mockDataReport: IItemReportTable[] = [
  {
    id: 1345,
    name: 'Шашлычок из птицы',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 2765,
    name: 'Фаршированный баклажан',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 3445,
    name: 'Кальмар в кляре',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 4777,
    name: 'Запеканка',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 5444,
    name: 'Салат оливье',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 6444,
    name: 'Манник',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 7444,
    name: 'Шашлычок из птицы',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 8444,
    name: 'Шашлычок из птицы',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 9444,
    name: 'Шашлычок из птицы',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 1044,
    name: 'Шашлычок из птицы',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
  {
    id: 1144,
    name: 'Шашлычок из птицы',
    weightPlan: 140,
    weightFact: 138,
    tempPlan: 65,
    tempFactMin: 62,
    tempFactMax: 68,
    tempMiddle: 64,
  },
]

export const mockDataDishes: IStateDish[] = [
  {
    id: 1345,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 2765,
    name: 'Фаршированный баклажан',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 3445,
    name: 'Кальмар в кляре',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 4777,
    name: 'Запеканка',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 5444,
    name: 'Салат оливье',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 6444,
    name: 'Манник',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 7444,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 8444,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 9444,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 1044,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 1144,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 1,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 2,
    name: 'Фаршированный баклажан',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 3,
    name: 'Кальмар в кляре',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 4,
    name: 'Запеканка',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 5,
    name: 'Салат оливье',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 6,
    name: 'Манник',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 7,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 8,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 9,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 10,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
  {
    id: 11,
    name: 'Шашлычок из птицы',
    weight: '140',
    min: '62',
    max: '68',
    category: 'Горячие блюда',
  },
]

export const mockDataOrderCard = [
  {
    id: 1,
    sourceIdent: 'gjhefk579389023456',
    sourceRepresent: '234567890-9',
    orderRepresent: '#768',
    table: {
      id: 22,
      source: 1,
      sourceIdent: 'gjhefk579389023456',
      name: 'Антон',
    },
    orderDate: '2022-10-04T11:16:28+03:00',
    orderDateStart: '2022-10-04T12:16:28+03:00',
    orderDateEnd: '2022-10-04T13:16:28+03:00',
    status: StatusOrder.FullFilled,
    orderType: OrderType.Regular,
    orderLines: [],
    waiter: {
      name: '',
      email: '',
      code: '',
      source_ident: '',
    },
    workshops: [],
  },
  {
    id: 2,
    sourceIdent: 'gjhefk579389023456',
    sourceRepresent: '234567890-9',
    orderRepresent: '#7687',
    table: {
      id: 22,
      source: 1,
      sourceIdent: 'gjhefk579389023456',
      name: '155',
    },
    orderDate: '2022-10-04T11:16:28+03:00',
    orderDateStart: '2022-10-04T12:16:28+03:00',
    orderDateEnd: '2022-10-04T13:16:28+03:00',
    status: StatusOrder.FullFilled,
    orderType: OrderType.Regular,
    orderLines: [],
    waiter: {
      name: '',
      email: '',
      code: '',
      source_ident: '',
    },
    workshops: [],
  },
  {
    id: 1,
    sourceIdent: 'gjhefk579389023456',
    sourceRepresent: '234567890-9',
    orderRepresent: '#768',
    table: {
      id: 22,
      source: 1,
      sourceIdent: 'gjhefk579389023456',
      name: 'Анастасия Эдуардовна Белая',
    },
    orderDate: '2022-10-04T11:16:28+03:00',
    orderDateStart: '',
    orderDateEnd: '',
    status: StatusOrder.Created,
    orderType: OrderType.Regular,
    orderLines: [],
    waiter: {
      name: '',
      email: '',
      code: '',
      source_ident: '',
    },
    workshops: [],
  },
]

export const mockDataMeasurementsForOrder: IPlanMenuControlDimensionDto[] = [
  {
    id: 123345545454,
    photoUrl: 'https://i.ibb.co/YycJ5Fv/Frame-967-1.png',
    thermoPhotoUrl: 'https://i.ibb.co/c2Qkh6d/Frame-967.png',
    dishStandardImage: 'https://i.ibb.co/pwKs13F/Frame-967-2.png',
    dishStandardWeight: 0.5,
    temperature: 23,
    dimensionDatetime: '2022-10-04T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Serving,
    comment: 'LALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag',
        archived: false,
      },
    ],
  },
  {
    id: 1233455454546,
    photoUrl: 'https://i.ibb.co/YycJ5Fv/Frame-967-1.png',
    thermoPhotoUrl: 'https://i.ibb.co/c2Qkh6d/Frame-967.png',
    dishStandardImage: 'https://i.ibb.co/pwKs13F/Frame-967-2.png',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-05T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 170,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 175,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 174,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 173,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 172,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 171,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 190,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 180,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 192,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 195,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 197,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 201,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
  {
    id: 202,
    photoUrl: 'photoUrl',
    thermoPhotoUrl: 'photoUrl',
    dishStandardImage: 'photoUrl',
    dishStandardWeight: 0,
    temperature: 21,
    dimensionDatetime: '2022-10-06T11:16:28+03:00',
    weight: 0.2,
    milletWeight: 0.5,
    dishTempMin: 20,
    dishTempMax: 30,
    tempChecker: 25,
    quantity: 1,
    dimensionType: DimensionType.Refund,
    comment: 'BLALalalalalalala',
    dishImplementationTime: null,
    implementationTime: 123,
    implementationTimeExceeded: null,
    dimensionTags: [
      {
        id: 129292929,
        name: 'tag1',
        archived: false,
      },
    ],
  },
]
export interface IMockDataForNewReport {
  summary: IMockDataForNewReportSummary
  results: IMockDataForNewReportResult[]
}

export interface IMockDataForNewReportSummary {
  plannedPersonsAmount: number
  actualPersonsAmount: number
  plannedWeight: number
  actualWeight: number
  temperatureMin: number
  temperatureAvg: number
  temperatureMax: number
  dimensionsCount: number
  personsAmountDiff: number
  weightDiff: number
  weightPerPerson: number
  name: string
}

export interface IMockDataForNewReportResult {
  plannedPersonsAmount: number
  actualPersonsAmount: number
  plannedWeight: number
  actualWeight: number
  temperatureMin: number
  temperatureAvg: number
  temperatureMax: number
  dimensionsCount: number
  personsAmountDiff?: number
  weightDiff?: number
  weightPerPerson?: number
  id: number
  name: string
  standardTempMax: number | null
  standardTempMin: number | null
  image: ''
  imageOnS3: null
  categoryName: string | null
  dates: IMockDataForNewReportDate[]
}

export interface IMockDataForNewReportDate {
  plannedPersonsAmount: number
  actualPersonsAmount: number
  plannedWeight: number
  actualWeight: number
  temperatureMin: number
  temperatureAvg: number
  temperatureMax: number
  dimensionsCount: number
  personsAmountDiff?: number
  weightDiff?: number
  weightPerPerson?: number
  createdDay: string
  dimensions: IMockDataForNewReportDateDimensions[]
}

export interface IMockDataForNewReportDateDimensions {
  id: number
  createdAt: string
  actualPersonsAmount: number
  actualWeight: number
  plannedPersonsAmount: number
  plannedWeight: number
  returnedWeight: number
  temperatureMatch: boolean
  planMenuName: string
  station: string | null
  temperature: number
  photoUrl: string | null
  photoUrlOnS3: null
  dimensionType: string
  planMenuType: string | null
  planMenuGuestType: string | null
  correspondentType: string | null
  sequenceNumber: number
}

export const mockDataForNewReport: IMockDataForNewReport = {
  summary: {
    plannedPersonsAmount: 765,
    actualPersonsAmount: 0,
    plannedWeight: 73.5,
    actualWeight: 176.21,
    temperatureMin: 25.88,
    temperatureAvg: 29.95107421875,
    temperatureMax: 34.7,
    dimensionsCount: 44,
    personsAmountDiff: -765,
    weightDiff: 102.71000000000001,
    weightPerPerson: 0.0,
    name: 'Итого',
  },
  results: [
    {
      plannedPersonsAmount: 202,
      actualPersonsAmount: 0,
      plannedWeight: 16.0,
      actualWeight: 160.215,
      temperatureMin: 26.45,
      temperatureAvg: 32.43375,
      temperatureMax: 34.7,
      dimensionsCount: 13,
      personsAmountDiff: -202,
      weightDiff: 144.215,
      weightPerPerson: 0.0,
      id: 130,
      name: 'Огурцы свежие нарезка (Fotobox6147)',
      standardTempMax: null,
      standardTempMin: null,
      image: '',
      imageOnS3: null,
      categoryName: null,
      dates: [
        {
          plannedPersonsAmount: 60,
          actualPersonsAmount: 0,
          plannedWeight: 6.0,
          actualWeight: 151.0,
          temperatureMin: 28.09,
          temperatureAvg: 29.759999999999998,
          temperatureMax: 34.7,
          dimensionsCount: 3,
          personsAmountDiff: -60,
          weightDiff: 145.0,
          weightPerPerson: 0.0,
          createdDay: '2024-07-15',
          dimensions: [
            {
              id: 5456,
              createdAt: '2024-07-15 13:45:51.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 151.0,
              plannedPersonsAmount: 20.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'завтрак 15.07.2024',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5452,
              createdAt: '2024-07-15 10:43:31.539349+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 20.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'завтрак 15.07.2024',
              station: null,
              temperature: 28.16,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Списание',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5451,
              createdAt: '2024-07-15 10:43:16.426846+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 20.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'завтрак 15.07.2024',
              station: null,
              temperature: 28.09,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
        {
          plannedPersonsAmount: 72,
          actualPersonsAmount: 0,
          plannedWeight: 3.0,
          actualWeight: 2.215,
          temperatureMin: 26.45,
          temperatureAvg: 30.575000000000003,
          temperatureMax: 34.7,
          dimensionsCount: 3,
          personsAmountDiff: -72,
          weightDiff: -0.7850000000000001,
          weightPerPerson: 0.0,
          createdDay: '2024-07-11',
          dimensions: [
            {
              id: 5325,
              createdAt: '2024-07-11 08:54:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 24.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'обед',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5324,
              createdAt: '2024-07-11 08:51:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 24.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'обед',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5298,
              createdAt: '2024-07-11 08:39:41.165305+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.215,
              plannedPersonsAmount: 24.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'обед',
              station: null,
              temperature: 26.45,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
        {
          plannedPersonsAmount: 70,
          actualPersonsAmount: 0,
          plannedWeight: 7.0,
          actualWeight: 7.0,
          temperatureMin: 34.7,
          temperatureAvg: 34.7,
          temperatureMax: 34.7,
          dimensionsCount: 7,
          personsAmountDiff: -70,
          weightDiff: 0.0,
          weightPerPerson: 0.0,
          createdDay: '2024-07-10',
          dimensions: [
            {
              id: 5301,
              createdAt: '2024-07-10 13:09:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Списание',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5303,
              createdAt: '2024-07-10 13:09:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 1.0,
              returnedWeight: 1.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5315,
              createdAt: '2024-07-10 13:09:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 1.0,
              returnedWeight: 1.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5314,
              createdAt: '2024-07-10 13:09:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Списание',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5302,
              createdAt: '2024-07-10 13:09:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Списание',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5313,
              createdAt: '2024-07-10 13:00:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5300,
              createdAt: '2024-07-10 13:00:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
      ],
    },
    {
      plannedPersonsAmount: 258,
      actualPersonsAmount: 0,
      plannedWeight: 38.5,
      actualWeight: 14.276,
      temperatureMin: 25.88,
      temperatureAvg: 31.8734375,
      temperatureMax: 34.7,
      dimensionsCount: 21,
      personsAmountDiff: -258,
      weightDiff: -24.224,
      weightPerPerson: 0.0,
      id: 131,
      name: 'Брускетты (Fotobox6147)',
      standardTempMax: null,
      standardTempMin: null,
      image: '',
      imageOnS3: null,
      categoryName: null,
      dates: [
        {
          plannedPersonsAmount: 40,
          actualPersonsAmount: 0,
          plannedWeight: 1.5,
          actualWeight: 0.436,
          temperatureMin: 28.08,
          temperatureAvg: 28.255,
          temperatureMax: 28.43,
          dimensionsCount: 2,
          personsAmountDiff: -40,
          weightDiff: -1.064,
          weightPerPerson: 0.0,
          createdDay: '2024-07-12',
          dimensions: [
            {
              id: 5360,
              createdAt: '2024-07-12 10:43:01.711959+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.217,
              plannedPersonsAmount: 25.0,
              plannedWeight: 0.5,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'обед 05.07.2024',
              station: null,
              temperature: 28.08,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5331,
              createdAt: '2024-07-12 09:16:09.985571+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.219,
              plannedPersonsAmount: 15.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин2',
              station: null,
              temperature: 28.43,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
        {
          plannedPersonsAmount: 48,
          actualPersonsAmount: 0,
          plannedWeight: 3.0,
          actualWeight: 1.24,
          temperatureMin: 25.88,
          temperatureAvg: 30.29,
          temperatureMax: 34.7,
          dimensionsCount: 2,
          personsAmountDiff: -48,
          weightDiff: -1.76,
          weightPerPerson: 0.0,
          createdDay: '2024-07-11',
          dimensions: [
            {
              id: 5326,
              createdAt: '2024-07-11 08:55:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 24.0,
              plannedWeight: 1.5,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'обед',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5299,
              createdAt: '2024-07-11 08:39:53.299439+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.24,
              plannedPersonsAmount: 24.0,
              plannedWeight: 1.5,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'обед',
              station: null,
              temperature: 25.88,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
        {
          plannedPersonsAmount: 170,
          actualPersonsAmount: 0,
          plannedWeight: 34.0,
          actualWeight: 12.6,
          temperatureMin: 27.48,
          temperatureAvg: 34.474375,
          temperatureMax: 34.7,
          dimensionsCount: 17,
          personsAmountDiff: -170,
          weightDiff: -21.4,
          weightPerPerson: 0.0,
          createdDay: '2024-07-10',
          dimensions: [
            {
              id: 5316,
              createdAt: '2024-07-10 13:40:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5304,
              createdAt: '2024-07-10 13:40:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5312,
              createdAt: '2024-07-10 13:39:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5323,
              createdAt: '2024-07-10 13:39:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5310,
              createdAt: '2024-07-10 13:38:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.2,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5321,
              createdAt: '2024-07-10 13:38:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.2,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5311,
              createdAt: '2024-07-10 13:35:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.1,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5322,
              createdAt: '2024-07-10 13:35:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.1,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5309,
              createdAt: '2024-07-10 13:33:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5320,
              createdAt: '2024-07-10 13:33:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5308,
              createdAt: '2024-07-10 13:19:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5319,
              createdAt: '2024-07-10 13:19:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5306,
              createdAt: '2024-07-10 13:17:52.069105+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 27.48,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5318,
              createdAt: '2024-07-10 13:13:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5307,
              createdAt: '2024-07-10 13:13:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5317,
              createdAt: '2024-07-10 13:11:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5305,
              createdAt: '2024-07-10 13:11:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 10.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
      ],
    },
    {
      plannedPersonsAmount: 80,
      actualPersonsAmount: 0,
      plannedWeight: 3.0,
      actualWeight: 1.4,
      temperatureMin: 34.7,
      temperatureAvg: 34.7,
      temperatureMax: 34.7,
      dimensionsCount: 2,
      personsAmountDiff: -80,
      weightDiff: -1.6,
      weightPerPerson: 0.0,
      id: 133,
      name: 'Ребрышки копченые (Fotobox6147)',
      standardTempMax: null,
      standardTempMin: null,
      image: '',
      imageOnS3: null,
      categoryName: null,
      dates: [
        {
          plannedPersonsAmount: 55,
          actualPersonsAmount: 0,
          plannedWeight: 1.0,
          actualWeight: 0.4,
          temperatureMin: 34.7,
          temperatureAvg: 34.7,
          temperatureMax: 34.7,
          dimensionsCount: 1,
          createdDay: '2024-07-11',
          dimensions: [
            {
              id: 5327,
              createdAt: '2024-07-11 13:40:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.4,
              plannedPersonsAmount: 55.0,
              plannedWeight: 1.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
        {
          plannedPersonsAmount: 25,
          actualPersonsAmount: 0,
          plannedWeight: 2.0,
          actualWeight: 1.0,
          temperatureMin: 34.7,
          temperatureAvg: 34.7,
          temperatureMax: 34.7,
          dimensionsCount: 1,
          createdDay: '2024-07-10',
          dimensions: [
            {
              id: 5348,
              createdAt: '2024-07-10 07:11:51.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 1.0,
              plannedPersonsAmount: 25.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'обед 05.07.2024',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
      ],
    },
    {
      plannedPersonsAmount: 210,
      actualPersonsAmount: 0,
      plannedWeight: 13.0,
      actualWeight: 0.1,
      temperatureMin: 27.68,
      temperatureAvg: 29.677500000000002,
      temperatureMax: 34.7,
      dimensionsCount: 7,
      personsAmountDiff: -210,
      weightDiff: -12.9,
      weightPerPerson: 0.0,
      id: 134,
      name: 'Рагу (Fotobox6147)',
      standardTempMax: null,
      standardTempMin: null,
      image: '',
      imageOnS3: null,
      categoryName: null,
      dates: [
        {
          plannedPersonsAmount: 100,
          actualPersonsAmount: 0,
          plannedWeight: 10.0,
          actualWeight: 0.0,
          temperatureMin: 28.08,
          temperatureAvg: 28.165,
          temperatureMax: 28.25,
          dimensionsCount: 5,
          personsAmountDiff: -100,
          weightDiff: -10.0,
          weightPerPerson: 0.0,
          createdDay: '2024-07-15',
          dimensions: [
            {
              id: 5455,
              createdAt: '2024-07-15 10:44:42.221726+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 20.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'завтрак 15.07.2024',
              station: null,
              temperature: 28.08,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Списание',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5454,
              createdAt: '2024-07-15 10:44:31.828822+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 20.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'завтрак 15.07.2024',
              station: null,
              temperature: 28.14,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5453,
              createdAt: '2024-07-15 10:44:21.182865+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 20.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'завтрак 15.07.2024',
              station: null,
              temperature: 28.25,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5450,
              createdAt: '2024-07-15 10:43:05.745741+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 20.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'завтрак 15.07.2024',
              station: null,
              temperature: 28.2,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5449,
              createdAt: '2024-07-15 10:42:44.872616+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 20.0,
              plannedWeight: 2.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'завтрак 15.07.2024',
              station: null,
              temperature: 28.14,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
        {
          plannedPersonsAmount: 110,
          actualPersonsAmount: 0,
          plannedWeight: 3.0,
          actualWeight: 0.1,
          temperatureMin: 27.68,
          temperatureAvg: 31.19,
          temperatureMax: 34.7,
          dimensionsCount: 2,
          personsAmountDiff: -110,
          weightDiff: -2.9,
          weightPerPerson: 0.0,
          createdDay: '2024-07-11',
          dimensions: [
            {
              id: 5329,
              createdAt: '2024-07-11 13:55:17.697975+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.0,
              plannedPersonsAmount: 55.0,
              plannedWeight: 1.5,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 27.68,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
            {
              id: 5328,
              createdAt: '2024-07-11 13:46:00.004078+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.1,
              plannedPersonsAmount: 55.0,
              plannedWeight: 1.5,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин',
              station: null,
              temperature: 34.7,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Возврат',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
      ],
    },
    {
      plannedPersonsAmount: 15,
      actualPersonsAmount: 0,
      plannedWeight: 3.0,
      actualWeight: 0.219,
      temperatureMin: 28.35,
      temperatureAvg: 28.35,
      temperatureMax: 28.35,
      dimensionsCount: 1,
      id: 5330,
      name: 'Котлеты по-киевски (Fotobox6147)',
      standardTempMax: null,
      standardTempMin: null,
      image: '',
      imageOnS3: null,
      categoryName: null,
      dates: [
        {
          plannedPersonsAmount: 15,
          actualPersonsAmount: 0,
          plannedWeight: 3.0,
          actualWeight: 0.219,
          temperatureMin: 28.35,
          temperatureAvg: 28.35,
          temperatureMax: 28.35,
          dimensionsCount: 1,
          createdDay: '2024-07-12',
          dimensions: [
            {
              id: 5330,
              createdAt: '2024-07-12 09:15:28.800532+00:00',
              actualPersonsAmount: 0.0,
              actualWeight: 0.219,
              plannedPersonsAmount: 15.0,
              plannedWeight: 3.0,
              returnedWeight: 0.0,
              temperatureMatch: false,
              planMenuName: 'ужин2',
              station: null,
              temperature: 28.35,
              photoUrl: null,
              photoUrlOnS3: null,
              dimensionType: 'Выдача',
              planMenuType: null,
              planMenuGuestType: null,
              correspondentType: null,
              sequenceNumber: 3,
            },
          ],
        },
      ],
    },
  ],
}

export interface IMockDataForChart {
  next: string | null,
  previous: string | null,
  count: number,
  results: IMockDataForChartResults[]
}

export interface IMockDataForChartResults {
  id: number
  name: string
  date: IMockDataForChartResultsDate
}

export interface IMockDataForChartResultsDate {
  createdAt: string,
  charts: IMockDataForChartResultsDateCharts[]
}

export interface IMockDataForChartResultsDateCharts {
  time: string,
  actualWeight: number
  dimensionsCount: number
  writeOff: number
  serving: number
  refund: number
}

export const mockDataForChart: IMockDataForChart = {
  next: 'http://localhost:8001/api/v1/report_buffet/general_report_chart_data/?dimension_date_gte=2024-05-01&dimension_date_lte=2024-05-05&dish_ids=22&dimension_date=2024-05-02',
  previous: null,
  count: 0,
  results: [
    {
      id: 22,
      name: 'Яйцо отварное',
      date: {
        createdAt: '2024-05-01',
        charts: [
          {
            time: '00:00',
            actualWeight: 4,
            dimensionsCount: 6,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '00:15',
            actualWeight: 8,
            dimensionsCount: 3,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '00:30',
            actualWeight: 5,
            dimensionsCount: 10,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '00:45',
            actualWeight: 1,
            dimensionsCount: 80,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '01:00',
            actualWeight: 4,
            dimensionsCount: 7,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '01:15',
            actualWeight: 10,
            dimensionsCount: 20,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '01:30',
            actualWeight: 80,
            dimensionsCount: 33,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '01:45',
            actualWeight: 2,
            dimensionsCount: 1,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '02:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '02:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '02:30',
            actualWeight: 1,
            dimensionsCount: 1,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '02:45',
            actualWeight: 1,
            dimensionsCount: 1,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '03:00',
            actualWeight: 1,
            dimensionsCount: 2,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '03:15',
            actualWeight: 43,
            dimensionsCount: 18,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '03:30',
            actualWeight: 56,
            dimensionsCount: 12,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '03:45',
            actualWeight: 75,
            dimensionsCount: 90,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '04:00',
            actualWeight: 65,
            dimensionsCount: 54,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '04:15',
            actualWeight: 77,
            dimensionsCount: 78,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '04:30',
            actualWeight: 56,
            dimensionsCount: 98,
            writeOff: 1,
            serving: 0,
            refund: 0,
          },
          {
            time: '04:45',
            actualWeight: 50,
            dimensionsCount: 96,
            writeOff: 0,
            serving: 1,
            refund: 0,
          },
          {
            time: '05:00',
            actualWeight: 32,
            dimensionsCount: 23,
            writeOff: 0,
            serving: 0,
            refund: 1,
          },
          {
            time: '05:15',
            actualWeight: 35,
            dimensionsCount: 34,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '05:30',
            actualWeight: 67,
            dimensionsCount: 56,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '05:45',
            actualWeight: 79,
            dimensionsCount: 65,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '06:00',
            actualWeight: 24,
            dimensionsCount: 10,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '06:15',
            actualWeight: 17,
            dimensionsCount: 87,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '06:30',
            actualWeight: 67,
            dimensionsCount: 54,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '06:45',
            actualWeight: 18,
            dimensionsCount: 7,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '07:00',
            actualWeight: 3,
            dimensionsCount: 1,
            writeOff: 0,
            serving: 1,
            refund: 0,
          },
          {
            time: '07:15',
            actualWeight: 6,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '07:30',
            actualWeight: 10,
            dimensionsCount: 9,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '07:45',
            actualWeight: 20,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '08:00',
            actualWeight: 30,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '08:15',
            actualWeight: 5,
            dimensionsCount: 8,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '08:30',
            actualWeight: 7,
            dimensionsCount: 9,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '08:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '09:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '09:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '09:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '09:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '10:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '10:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '10:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '10:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '11:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '11:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '11:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '11:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '12:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '12:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '12:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '12:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '13:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '13:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '13:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '13:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '14:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '14:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '14:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '14:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '15:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '15:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '15:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '15:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '16:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '16:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '16:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '16:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '17:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '17:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '17:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '17:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '18:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '18:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '18:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '18:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '19:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '19:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '19:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '19:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '20:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '20:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '20:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '20:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '21:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '21:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '21:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '21:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '22:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '22:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '22:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '22:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '23:00',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '23:15',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '23:30',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
          {
            time: '23:45',
            actualWeight: 0,
            dimensionsCount: 0,
            writeOff: 0,
            serving: 0,
            refund: 0,
          },
        ],
      },
    },
  ],
}
