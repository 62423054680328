import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'

export const useGetLimitParam = (): number => {
  const [searchParams] = useSearchParams()
  const storageLimit = localStorage.getItem('limit')
  const limit = searchParams.get('limit')
  return useMemo(() => {
    return storageLimit ? Number(storageLimit) : !!limit ? Number(limit) : 40
  }, [limit, storageLimit])
}
