import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const fcmTokenState = {
  token: '',
}

const fcmTokenSlice = createSlice({
  name: 'fcmToken',
  initialState: fcmTokenState,
  reducers: {
    setFCMToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload
    },
  },
})

export const { setFCMToken } = fcmTokenSlice.actions
export const { reducer: FCMTokenReducer } = fcmTokenSlice