import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParamsControl } from '../../../utils/useParamsControl'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { ShowFormatType } from './BuffetReportsVZ'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createQueryString } from '../../../utils/createQueryString'
import { GetFile } from '../../../utils/getFile'
import { BASE_URL } from '../../../core/api/BaseApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useTranslation } from 'react-i18next'
import { IBuffetGeneralReportChartRequest } from 'core/api/dto/BuffetGeneralReportDto'
import moment from 'moment'
import './BuffetReportVZChart.scss'
import 'chart.js/auto'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  BubbleDataPoint,
  ChartTypeRegistry,
  Point,
} from 'chart.js'
import { useGetBuffetGeneralReportChartQuery, useGetBuffetGeneralReportQuery } from 'core/api/BuffetReportApi'
import { ChartPaginationBlock } from './components/ChartPaginationBlock/ChartPaginationBlock'
import CheckboxForChart from './components/CheckboxForChart/CheckboxForChart'
import { colorsArr } from 'helpers/colors'
import { showFormatHeader } from './BuffetReportsVZLine'
import { AppScrollWrapperNew } from 'widgets/AppScrollWrapper/AppScrollWrapper'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

interface IChartData {
  label: string
  data: IChartDataData[]
  id: number
}
interface IChartDataData {
  id: number
  label: string
  time: string
  actualWeight: number
  dimensionsCount: number
  writeOff: number
  serving: number
  refund: number
}
type LegendesActive = {
  id: number
  active: boolean
}

export const BuffetReportsVZChart = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false)
  const [legendesActive, setLegendesActive] = useState<LegendesActive[]>([])
  const [dataForFilterChart, setDataForFilterChart] = useState<IChartData[]>([])
  const pageParams: any = useParamsControl<
    IBuffetGeneralReportChartRequest,
    keyof IBuffetGeneralReportChartRequest
  >({
    withPagination: true,
    whiteList: [
      'report_type',
      'dimension_date_gte',
      'dimension_date_lte',
      'dish_ids',
      'dimension_types',
      'station_ids',
      'plan_menu_type_ids',
      'dimension_date',
      'interval_minutes',
      'dimension_time_gte',
      'dimension_time_lte',
    ],
  })

  const { data: generalReport } = useGetBuffetGeneralReportQuery(
    { ...pageParams, offset: undefined },
    { skip: !pageParams },
  )

  const { data: generalReportChartData, isLoading } =
    useGetBuffetGeneralReportChartQuery(
      { ...pageParams, offset: undefined },
      { skip: !pageParams, refetchOnMountOrArgChange: true },
    )
  const [chartData, setChartData] = useState<IChartData[]>([])

  useEffect(() => {
    if (generalReportChartData) {
      setLegendesActive(
        generalReportChartData.results.map((e) => ({ id: e.id, active: true })),
      )
      setChartData(
        generalReportChartData.results.map((e, i) => {
          return {
            label: e.name,
            id: e.id,
            data: e.date.charts.map((elem) => {
              return {
                id: e.id,
                label: e.name,
                ...elem,
              }
            }),
            borderColor: colorsArr[i],
          }
        }),
      )
      setDataForFilterChart(
        generalReportChartData.results.map((e, i) => {
          return {
            label: e.name,
            id: e.id,
            data: e.date.charts.map((elem) => {
              return {
                id: e.id,
                label: e.name,
                ...elem,
              }
            }),
            borderColor: colorsArr[i],
          }
        }),
      )
    }
  }, [generalReportChartData, pageParams])

  useEffect(() => {
    const chartDataFiltered = dataForFilterChart.filter((el) => {
      const activeLegend = legendesActive.find((e) => e.id === el.id)
      return activeLegend?.active
    })
    setChartData(chartDataFiltered)
  }, [dataForFilterChart, legendesActive])

  const unloadDishesHandler = () => {
    setIsLoadingLocal(true)
    const params = createQueryString(pageParams)
    GetFile(`${BASE_URL}/report_buffet/export_general_report_to_file/${params}`)
      .then(() =>
        AppNotification({
          msg: 'Отчет успешно выгружен!',
          type: NotificationType.success,
        }),
      )
      .finally(() => setIsLoadingLocal(false))
  }

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Выгрузить',
        btnIco: 'export',
        items: [
          {
            title: 'Таблица .XLSX',
            onClick: unloadDishesHandler,
            ico: 'file-xlsx',
          },
          // { title: 'Журнал .PDF', onClick: () => {}, ico: 'file-pdf' },
          // { title: 'Экспортировать .CSV', onClick: () => {}, ico: 'file-csv' },
        ],
        color: AppBtnColor.whiteOutlineViolet,
        disabled: !!(!generalReport)
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageParams, generalReport],
  )

  const [isChart, setIsChart] = useState(true)
  const onChangeChart = (value: boolean) => setIsChart(value)

  const goToTable = useCallback(
    () =>
      navigate(
        `/${AllRoutes.buffetReportsVZ.path}/${
          AllRoutes.buffetReportsVZLine.path
        }/?${searchParams.toString()}`,
      ),
    [navigate, searchParams],
  )

  useEffect(() => {
    if (!isChart) goToTable()
  }, [isChart, goToTable])

  const onActiveLegend = (id: number, value: boolean) => {
    setLegendesActive((prev) => {
      const element = prev.find((el) => el.id === id)
      if (element) {
        return prev.map((el) => (el.id === id ? { ...el, active: value } : el))
      } else {
        return [...prev, { id, active: false }]
      }
    })
  }

  return (
    <div className="width-for-page-wrapper buffet-comparative-report-detail general-report">
      {!!(isLoadingLocal || isLoading) && (
        <AppLoader loaderType={LoaderType.main} />
      )}
      <AppTitlePage
        title={`${moment(pageParams?.dimension_date_gte).format(
          'DD.MM.YYYY',
        )} - ${moment(pageParams?.dimension_date_lte).format(
          'DD.MM.YYYY',
        )};</br> ${t(
          showFormatHeader[pageParams?.report_type as ShowFormatType],
        )}`}
        actions={chartData ? actions : undefined}
        goBack
        goBackPath={`/${AllRoutes.buffetReportsVZ.path}/?${searchParams
          .toString()
          .replace(
            `&dimension_date=${searchParams.get('dimension_date')}`,
            '',
          )}`}
        isReportIntensity={pageParams?.report_type === ShowFormatType.intensity}
        isReportIntensityChart={isChart}
        onChangeChart={onChangeChart}
      />
      {!!(
        chartData &&
        pageParams?.report_type === ShowFormatType.intensity &&
        isChart
      ) && (
        <div style={{ marginTop: 15 }}>
          <div className="app-chart-wrap">
            {generalReportChartData && (
              <div className="legend">
                <AppScrollWrapperNew>
                {generalReportChartData.results.length ?
                  generalReportChartData.results.map((e, i) => (
                  <div className="legend-item" key={e.id}>
                    <div
                      className="legend-color"
                      style={{ background: `${colorsArr[i]}` }}
                    />
                    <CheckboxForChart
                      key={e.id}
                      name={`${e.name}-${e.id}`}
                      value={
                        legendesActive.find((el) => el.id === e.id)?.active ||
                        false
                      }
                      text={e.name}
                      onChange={(value) => onActiveLegend(e.id, value)}
                    />
                  </div>
                )) : <p className='color-Red text-center'>Нет данных</p>}
                </AppScrollWrapperNew>
              </div>
            )}
            <div className="chart">
              <div className="chart-wrap">
                <Line
                  data={{
                    datasets: chartData,
                  }}
                  options={{
                    interaction: {
                      mode: 'index',
                      intersect: false,
                    },
                    parsing: {
                      xAxisKey: 'time',
                      yAxisKey: 'actualWeight',
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: false,
                        position: 'nearest',
                        intersect: true,
                        mode: 'point',
                        external: (context: any) => {
                          const getOrCreateTooltip = (
                            chart: ChartJS<
                              keyof ChartTypeRegistry,
                              (
                                | number
                                | Point
                                | [number, number]
                                | BubbleDataPoint
                                | null
                              )[],
                              unknown
                            >,
                          ) => {
                            let tooltipEl =
                              chart.canvas.parentNode?.querySelector('div')

                            if (!tooltipEl) {
                              tooltipEl = document.createElement('div')
                              tooltipEl.style.background = '#ffffff'
                              tooltipEl.style.borderRadius = '3px'
                              tooltipEl.style.color = 'black'
                              tooltipEl.style.opacity = '1'
                              tooltipEl.style.pointerEvents = 'none'
                              tooltipEl.style.position = 'absolute'
                              tooltipEl.style.transform = 'translate(-50%, 0)'
                              tooltipEl.style.transition = 'all .1s ease'
                              tooltipEl.style.boxShadow = '0px 5px 10px 10px rgba(0, 0, 0, 0.1)'

                              const tooltipContent =
                                document.createElement('div')
                              tooltipContent.style.margin = '0px'

                              tooltipEl.appendChild(tooltipContent)
                              chart.canvas.parentNode?.appendChild(tooltipEl)
                            }

                            return tooltipEl
                          }
                          // Tooltip Element
                          const { chart, tooltip } = context

                          const tooltipEl = getOrCreateTooltip(chart)

                          // Hide if no tooltip
                          if (tooltip.opacity === 0) {
                            tooltipEl.style.opacity = '0'
                            return
                          }

                          // Set Text
                          if (tooltip.dataPoints) {
                            const titleLines = tooltip.title
                            const bodyLines = tooltip.dataPoints.map(
                              (e: any) => e.raw,
                            )
                            const filteredBodyLines = bodyLines.filter(
                              (e: any) => !!e.actualWeight,
                            )

                            const titleBlock = document.createElement('div')
                            titleBlock.style.textAlign = 'left'
                            if (filteredBodyLines.length) {
                              titleLines.forEach((title: string) => {
                                const textEl = document.createElement('p')
                                textEl.style.margin = '5px 0px'
                                const text = document.createTextNode(title)

                                textEl.appendChild(text)
                                titleBlock.appendChild(textEl)
                              })
                            }

                            const tableBody = document.createElement('div')
                            tableBody.style.textAlign = 'left'
                            tableBody.style.fontSize = '14px'

                            filteredBodyLines.forEach(
                              (body: any, i: number) => {
                                const colors = tooltip.labelColors[i]

                                const span: any = document.createElement('span')
                                span.style.background = colors.borderColor
                                span.style.borderColor = colors.borderColor
                                span.style.borderWidth = '2px'
                                span.style.marginRight = '10px'
                                span.style.height = '14px'
                                span.style.width = '14px'
                                span.style.display = 'inline-block'
                                span.style.borderRadius = '14px'

                                const tr: any = document.createElement('p')
                                tr.style.backgroundColor = 'inherit'
                                tr.style.borderWidth = 0
                                tr.style.margin = '0px'

                                const td: any = document.createElement('p')
                                td.style.borderWidth = 0
                                td.style.margin = '5px 0px'
                                const td2: any = document.createElement('p')
                                td2.style.borderWidth = 0
                                td2.style.margin = '5px 0px'
                                const td3: any = document.createElement('p')
                                td3.style.borderWidth = 0
                                td3.style.margin = '5px 0px'
                                const td4: any = document.createElement('p')
                                td4.style.borderWidth = 0
                                td4.style.margin = '5px 0px'
                                const td5: any = document.createElement('p')
                                td5.style.borderWidth = 0
                                td5.style.margin = '5px 0px'
                                const td6: any = document.createElement('p')
                                td6.style.borderWidth = 0
                                td6.style.margin = '5px 0px'
                                const td7: any = document.createElement('p')
                                td7.style.borderWidth = 0
                                td7.style.margin = '5px 0px'
                                const td8: any = document.createElement('p')
                                td8.style.borderWidth = 0
                                td8.style.margin = '5px 0px'

                                const spanName = document.createElement('span')
                                spanName.style.color = '#9A9BA1'
                                const textSpanName = document.createTextNode(
                                  'Наименование блюда: ',
                                )
                                spanName.appendChild(textSpanName)
                                const spanName2 = document.createElement('span')
                                const textSpanName2 = document.createTextNode(
                                  `${body.label}`,
                                )
                                spanName2.style.fontWeight = '700'
                                spanName2.appendChild(textSpanName2)
                                td.appendChild(span)
                                td.appendChild(spanName)
                                td.appendChild(spanName2)

                                const spanServing =
                                  document.createElement('span')
                                spanServing.style.color = '#9A9BA1'
                                const textServing =
                                  document.createTextNode('Тип замера: ')
                                spanServing.appendChild(textServing)
                                const spanServing2 =
                                  document.createElement('span')
                                spanServing2.style.fontWeight = '700'
                                const textServing2 =
                                  document.createTextNode(`Выдача`)
                                spanServing2.appendChild(textServing2)
                                td2.appendChild(spanServing)
                                td2.appendChild(spanServing2)

                                const spanServingCount =
                                  document.createElement('span')
                                spanServingCount.style.color = '#9A9BA1'
                                const textServingCount =
                                  document.createTextNode(
                                    'Количество замеров за интервал времени: ',
                                  )
                                spanServingCount.appendChild(textServingCount)
                                const spanServingCount2 =
                                  document.createElement('span')
                                spanServingCount2.style.fontWeight = '700'
                                const textServingCount2 =
                                  document.createTextNode(`${body.serving}`)
                                spanServingCount2.appendChild(textServingCount2)
                                td3.appendChild(spanServingCount)
                                td3.appendChild(spanServingCount2)

                                const spanWriteOff =
                                  document.createElement('span')
                                spanWriteOff.style.color = '#9A9BA1'
                                const textWriteOff =
                                  document.createTextNode('Тип замера: ')
                                spanWriteOff.appendChild(textWriteOff)
                                const spanWriteOff2 =
                                  document.createElement('span')
                                spanWriteOff2.style.fontWeight = '700'
                                const textWriteOff2 =
                                  document.createTextNode(`Списание`)
                                spanWriteOff2.appendChild(textWriteOff2)
                                td4.appendChild(spanWriteOff)
                                td4.appendChild(spanWriteOff2)

                                const spanWriteOffCount =
                                  document.createElement('span')
                                spanWriteOffCount.style.color = '#9A9BA1'
                                const textWriteOffCount =
                                  document.createTextNode(
                                    'Количество замеров за интервал времени: ',
                                  )
                                spanWriteOffCount.appendChild(textWriteOffCount)
                                const spanWriteOffCount2 =
                                  document.createElement('span')
                                spanWriteOffCount2.style.fontWeight = '700'
                                const textWriteOffCount2 =
                                  document.createTextNode(`${body.writeOff}`)
                                spanWriteOffCount2.appendChild(
                                  textWriteOffCount2,
                                )
                                td5.appendChild(spanWriteOffCount)
                                td5.appendChild(spanWriteOffCount2)

                                const spanRefund =
                                  document.createElement('span')
                                spanRefund.style.color = '#9A9BA1'
                                const textRefund =
                                  document.createTextNode('Тип замера: ')
                                spanRefund.appendChild(textRefund)
                                const spanRefund2 =
                                  document.createElement('span')
                                spanRefund2.style.fontWeight = '700'
                                const textRefund2 =
                                  document.createTextNode(`Возврат`)
                                spanRefund2.appendChild(textRefund2)
                                td6.appendChild(spanRefund)
                                td6.appendChild(spanRefund2)

                                const spanRefundCount =
                                  document.createElement('span')
                                spanRefundCount.style.color = '#9A9BA1'
                                const textRefundCount = document.createTextNode(
                                  'Количество замеров за интервал времени: ',
                                )
                                spanRefundCount.appendChild(textRefundCount)
                                const spanRefundCount2 =
                                  document.createElement('span')
                                spanRefundCount2.style.fontWeight = '700'
                                const textRefundCount2 =
                                  document.createTextNode(`${body.refund}`)
                                spanRefundCount2.appendChild(textRefundCount2)
                                td7.appendChild(spanRefundCount)
                                td7.appendChild(spanRefundCount2)

                                const spanWeight =
                                  document.createElement('span')
                                spanWeight.style.color = '#9A9BA1'
                                const textWeight = document.createTextNode(
                                  'Итоговый вес за выбранный период времени: ',
                                )
                                spanWeight.appendChild(textWeight)
                                const spanWeight2 =
                                  document.createElement('span')
                                spanWeight2.style.fontWeight = '700'
                                const textWeight2 = document.createTextNode(
                                  `${body.actualWeight.toFixed(3)}`,
                                )
                                spanWeight2.appendChild(textWeight2)
                                td8.appendChild(spanWeight)
                                td8.appendChild(spanWeight2)

                                tr.appendChild(td)
                                tr.appendChild(td2)
                                tr.appendChild(td3)
                                tr.appendChild(td4)
                                tr.appendChild(td5)
                                tr.appendChild(td6)
                                tr.appendChild(td7)
                                tr.appendChild(td8)
                                tableBody.appendChild(tr)
                              },
                            )


                            const tableRoot = tooltipEl.querySelector('div')

                            // Remove old children
                            while (tableRoot?.firstChild) {
                              tableRoot.firstChild.remove()
                            }

                            // Add new children
                            tableRoot?.appendChild(titleBlock)
                            tableRoot?.appendChild(tableBody)
                          }

                          const {
                            offsetLeft: positionX,
                            offsetTop: positionY,
                          } = chart.canvas

                          // Display, position, and set styles for font
                          if(tooltip.dataPoints.map((e: any) => e.raw).filter(
                            (e: any) => !!e.actualWeight,
                          ).length) tooltipEl.style.opacity = '1'
                          tooltipEl.style.width = '450px'
                          let offsetX = 0
                          if (450 / 2 > tooltip.caretX) {
                            offsetX = 225
                          } else {
                            offsetX = -225
                          }
                          tooltipEl.style.left =
                            positionX + tooltip.caretX + offsetX + 'px'
                          tooltipEl.style.top =
                          tooltip.caretY < 100 ? tooltip.caretY + positionY + 'px' : tooltip.caretY - positionY + 'px'
                          tooltipEl.style.padding =
                            tooltip.options.padding +
                            'px ' +
                            tooltip.options.padding +
                            'px'
                        },
                      },
                    },
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: 'Время',
                          color: 'rgba(154, 155, 161, 1)',
                          font: {
                            size: 22,
                            weight: 700,
                          },
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: 'Количество, кг',
                          color: 'rgba(154, 155, 161, 1)',
                          font: {
                            size: 22,
                            weight: 700,
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
              {generalReportChartData && (
                <ChartPaginationBlock
                  dataCount={generalReportChartData.count}
                  dataNext={generalReportChartData.next}
                  date={generalReportChartData.currentDate}
                  dataPrevious={generalReportChartData.previous}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
