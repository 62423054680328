import React, { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { IOrderLine } from '../../core/api/dto/OrderObjDto'
import {
  useGetCurrentWeightQuery,
  useGetOrderByIdQuery,
} from '../../core/api/OrderApi'
import { useGetOrderLinesByIdQuery } from '../../core/api/OrderLineApi'
import { useAppSelector } from '../../core/store/store'
import useFilterQuery from '../../utils/useFilterQuery'
import { BuffetCardsMemoized } from '../buffetCard/BuffetCards'
import { CalculatorMemoized } from '../calculator/Calculator'
import { FixedSearchPanelMemoized } from '../fixedPanel/FixedSearchPanel'
import Popup from '../popup/Popup'
import Spinner, {
  SpinnerColor,
  SpinnerPosition,
  SpinnerSize,
} from '../spinner/Spinner'

const BuffetCardByWorkshopMapCatalog = () => {
  const { isFullModeDish } = useAppSelector((state) => state.buffet)
  const { isFetchError } = useAppSelector((state) => state.connection)
  const refRoot = useRef<HTMLDivElement | null>(null)
  const refChild = useRef<HTMLDivElement | null>(null)
  const { buffetOrderId, workshopId } = useParams()
  const { data: orderInfo } = useGetOrderByIdQuery(
    buffetOrderId ? buffetOrderId : '',
    {
      skip: !buffetOrderId,
    },
  )
  const { searchString, getCurrentSearchParamValue } = useFilterQuery()
  const {
    data: dishes,
    refetch,
    isFetching,
  } = useGetOrderLinesByIdQuery(
    {
      order: buffetOrderId ? buffetOrderId : '',
      category: workshopId ? workshopId : '',
      search:
        searchString && getCurrentSearchParamValue('search')!.length > 2
          ? searchString
          : '',
    },
    {
      skip: !buffetOrderId,
    },
  )
  const [activeDish, setActiveDish] = useState<IOrderLine | null>(null)
  const { data, isError, isSuccess } = useGetCurrentWeightQuery(null, {
    pollingInterval: 1000,
    skip: Boolean(isFetchError),
  })
  const handlerSetActiveDish = (el: IOrderLine) => {
    if (!isError && !data?.errorMsg) {
      setActiveDish(el)
    }
  }
  return (
    <>
      <FixedSearchPanelMemoized
        refChild={refChild}
        refRoot={refRoot}
        refetch={refetch}
        title={workshopId ? workshopId : 'Блюда по всем цехам'}
      />
      {activeDish && (
        <Popup>
          <CalculatorMemoized
            dishName={activeDish.dish.name}
            dishId={activeDish.dish.id}
            sourceId={orderInfo!.id}
            sourceName={orderInfo!.sourceRepresent}
            resetActiveDish={() => setActiveDish(null)}
            isSuccessWeight={isSuccess}
            currentWeight={data?.weight ? data.weight : 0}
          />
        </Popup>
      )}
      {dishes && !isFetching && buffetOrderId ? (
        <div className="column cards" ref={refRoot}>
          <div className="container-fluid">
            <div
              className={`row row-cols-xl-6 ${
                isFullModeDish ? 'row-cols-lg-4' : 'row-cols-lg-6'
              } row-cols-md-3`}
              ref={refChild}
            >
              <BuffetCardsMemoized
                cards={dishes}
                handlerSetActiveDish={handlerSetActiveDish}
              />
            </div>
          </div>
        </div>
      ) : isFetching ? (
        <Spinner
          color={SpinnerColor.violet}
          size={SpinnerSize.lg}
          spinnerPosition={SpinnerPosition.center}
        />
      ) : null}
    </>
  )
}

export default BuffetCardByWorkshopMapCatalog
