import { api, LIMIT } from './BaseApi'
import { IOrderLine, IOredeLines } from './dto/OrderObjDto'

interface IPropsForGetDishes {
  order: string
  category?: string
  search?: string
}

export const orderLineApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrderLineById: builder.query<IOrderLine, string>({
      query: (id) => ({
        url: `/order_line/${id}/`,
      }),
      providesTags: ['OrderLine', 'PlanMenu'],
    }),
    getOrderLinesById: builder.query<IOrderLine[], IPropsForGetDishes>({
      query: ({ order, category, search }) => ({
        url: `/order_line/?${search}`,
        params: {
          limit: search ? LIMIT.search : LIMIT.base,
          order,
          dish__category_represent: category,
        },
      }),
      transformResponse: (response: IOredeLines) => response.results,
      providesTags: ['OrderLine', 'Order', 'PlanMenu'],
    }),
  }),
})

export const { useGetOrderLineByIdQuery, useGetOrderLinesByIdQuery } =
  orderLineApi
