import React from 'react'
import {
  setDimensionCount,
  setFetchError,
  setTempCount,
} from '../../core/store/connection/connectionSlice'
import { useAppDispatch, useAppSelector } from '../../core/store/store'
import Popup from './Popup'
import AppBtn, { AppBtnColor } from '../btn/AppBtn'
import { AllRoutes } from '../../core/routes/AllRoutes'
import { useTranslation } from 'react-i18next'

const PopupNoConnection = () => {
  const { t } = useTranslation()
  const { isFetchError } = useAppSelector((state) => state.connection)
  const dispatch = useAppDispatch()

  const onLeavePage = () => {
    dispatch(setFetchError(''))
    dispatch(setDimensionCount(0))
    dispatch(setTempCount(0))
  }

  return (
    <Popup>
      <div className="content-error-popup">
        <div>{t(isFetchError)}</div>
        <div className="mt-20">
          <AppBtn
            title="Закрыть"
            onClick={onLeavePage}
            color={AppBtnColor.violetWhite}
          />
        </div>
      </div>
    </Popup>
  )
}

export default PopupNoConnection
