import { api } from './BaseApi'
import { IPlanMenuDto } from './dto/PlanMenuDto'
import {
  IPlanMenuControlDimensionDto,
  IPlanMenuControlOrderDto,
  IPlanMenuControlOrderLineDto,
  IPlanMenuControlOrdersDto,
  IPlanMenuControlOrdersLinesOpenDto,
  IPlanMenuControlOrdersRequest,
} from './dto/PlanMenuControlDto'

export const planMenuControlApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlanMenuControlOrders: builder.query<
      IPlanMenuControlOrdersDto,
      IPlanMenuControlOrdersRequest
    >({
      query: (params) => ({
        url: '/plan_menu_control_order/',
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlOrdersOnlyRead: builder.query<
      IPlanMenuControlOrdersDto,
      IPlanMenuControlOrdersRequest
    >({
      query: (params) => ({
        url: '/read_only_plan_menu_control_order/',
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlOrder: builder.query<
      IPlanMenuControlOrderDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/plan_menu_control_order/${id}/`,
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlOrderOnlyRead: builder.query<
      IPlanMenuControlOrderDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/read_only_plan_menu_control_order/${id}/`,
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlOrderLine: builder.query<
      IPlanMenuControlOrderLineDto[],
      { id: number } & IPlanMenuControlOrdersRequest
    >({
      query: ({ id, ...params }) => ({
        url: `/plan_menu_control_order_line/order_lines/${id}/`,
        params: !!params ? { ...params } : {},
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlOrderLineOnlyRead: builder.query<
      { results: IPlanMenuControlOrderLineDto[] },
      { order: number } & IPlanMenuControlOrdersRequest
    >({
      query: (params) => ({
        url: `/read_only_plan_menu_control_order_line/`,
        params: !!params ? { ...params } : {},
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlDimension: builder.query<
      IPlanMenuControlDimensionDto[],
      { id: number } & IPlanMenuControlOrdersRequest
    >({
      query: ({ id, ...params }) => ({
        url: `/plan_menu_control_dimension/order_line_dimensions/${id}/`,
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlDimensionOnlyRead: builder.query<
      { results: IPlanMenuControlDimensionDto[] },
      { order_line: number } & IPlanMenuControlOrdersRequest
    >({
      query: (params) => ({
        url: `/read_only_plan_menu_control_dimension/`,
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlDimensionDetail: builder.query<
      IPlanMenuControlDimensionDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/plan_menu_control_dimension/${id}/`,
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuControlDimensionDetailOnlyRead: builder.query<
      IPlanMenuControlDimensionDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/read_only_plan_menu_control_dimension/${id}/`,
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getAllPlanMenuControlOrdersLine: builder.query<
      IPlanMenuControlOrdersLinesOpenDto,
      IPlanMenuControlOrdersRequest
    >({
      query: (params) => ({
        url: '/plan_menu_control_order_line/order_lines_open/',
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getOpenBuffetOrders: builder.query<
      IPlanMenuControlOrdersLinesOpenDto,
      { order_ids?: string }
    >({
      query: ({ order_ids }) => ({
        url: `/order/get_open_buffet_orders/`,
        params: { order_ids },
      }),
      providesTags: ['PlanMenuControl'],
    }),
    updatePlanMenuOrderComment: builder.mutation<
      IPlanMenuDto,
      { id: number; comment: string }
    >({
      query: ({ id, comment }) => ({
        method: 'PATCH',
        url: `/plan_menu_control_order/${id}/`,
        body: { comment },
      }),
      invalidatesTags: ['PlanMenuControl'],
    }),
    updatePlanMenuDimensionComment: builder.mutation<
      IPlanMenuDto,
      { id: number; comment?: string; dimensionTags?: number[] }
    >({
      query: ({ id, comment, dimensionTags }) => ({
        method: 'PATCH',
        url: `/plan_menu_control_dimension/${id}/`,
        body: { comment, dimensionTags },
      }),
      invalidatesTags: ['PlanMenuControl'],
    }),
  }),
})

export const {
  useGetPlanMenuControlOrdersQuery,
  useGetPlanMenuControlOrdersOnlyReadQuery,
  useGetPlanMenuControlOrderQuery,
  useGetPlanMenuControlOrderOnlyReadQuery,
  useGetPlanMenuControlOrderLineQuery,
  useGetPlanMenuControlOrderLineOnlyReadQuery,
  useGetPlanMenuControlDimensionQuery,
  useGetPlanMenuControlDimensionOnlyReadQuery,
  useGetPlanMenuControlDimensionDetailQuery,
  useGetPlanMenuControlDimensionDetailOnlyReadQuery,
  useGetAllPlanMenuControlOrdersLineQuery,
  useGetOpenBuffetOrdersQuery,
  useUpdatePlanMenuOrderCommentMutation,
  useUpdatePlanMenuDimensionCommentMutation,
} = planMenuControlApi
