import React from 'react'
import { ITag } from '../../../../core/api/dto/TagsDto'
import { AppMultiDropdownWithPagination } from '../../../../components/AppDropdown/AppMultiDropdownWithPagination'
import { useGetTagsListQuery } from '../../../../core/api/TagsApi'
import { useTranslation } from 'react-i18next'

interface IPlanMenuCloseTagsBlock {
  tags: ITag[]
  setTags: (value: ((prevState: ITag[]) => ITag[]) | ITag[]) => void
  disabled?: boolean
}

export const PlanMenuCloseTagsBlock = ({
  tags,
  setTags,
  disabled,
}: IPlanMenuCloseTagsBlock) => {
  const { t } = useTranslation()
  const onRemoveTagItem = (id: number) => {
    const newTags = tags.filter((tag) => tag.id !== id)
    setTags(newTags)
  }

  return (
    <div>
      <div className={'mt-10'}>
        <AppMultiDropdownWithPagination<ITag, keyof ITag>
          label={'Тэги'}
          value={tags}
          getterData={useGetTagsListQuery}
          propToShowInInput={'name'}
          propToShowInList={'name'}
          border
          fullWidth
          placeholder="Выбрать"
          onChange={setTags}
          disabled={disabled}
        />
      </div>
      {!!tags.length && (
        <React.Fragment>
          <label>{t('Выбранные тэги')}</label>
          <div className="choose-items-block full-width">
            {tags.map((item) => (
              <div className="choose-item" key={item.id}>
                {item.name}
                <i
                  className="an-ico an-ico-close"
                  onClick={
                    disabled ? undefined : () => onRemoveTagItem(item.id)
                  }
                />
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
