import React from 'react'
import NotFound, { ExtraClass } from '../notFound/NotFound'

const NoCategorySelected = () => {
  return (
    <NotFound
      className={ExtraClass.notCategory}
      path="/assets/images/question-mark.png"
      title="Категория не выбрана"
    />
  )
}

export default NoCategorySelected
