import { toast } from 'react-toastify'
import AppBtn, { AppBtnColor, AppBtnSize } from '../components/btn/AppBtn'
import { NotificationType } from '../components/notification/Notification'
import { useEffect, useState } from 'react'

interface IUseDeleteDebounceProps {
  fn: () => void
}
type IUseDeleteDebounceResponse = [onClickHandler: () => void]

export const useDeleteDebounce = ({
  fn,
}: IUseDeleteDebounceProps): IUseDeleteDebounceResponse => {
  const onClickHandler = () => {
    toast[NotificationType.info](<BtnToast fn={fn} />, {
      autoClose: 30000,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      closeOnClick: true,
    })
  }

  return [onClickHandler]
}

const BtnToast = ({ fn }: IUseDeleteDebounceProps) => {
  const [cancel, setCancel] = useState(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (!cancel) {
      timeoutId = setTimeout(() => {
        fn()
      }, 31000)
    }

    return () => {
      clearTimeout(timeoutId)
      if (cancel) return
    }
  }, [cancel, fn])

  return (
    <AppBtn
      title="Нажмите, чтобы отменить удаление"
      sized={AppBtnSize.fullWidth}
      color={AppBtnColor.outlineViolet}
      onClick={() => setCancel(true)}
    />
  )
}
