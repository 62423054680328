import React, { useCallback, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { useNavigate } from 'react-router'
import { AppTable } from '../../../components/AppTable/AppTable'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import {
  useArchiveMultipleBuffetMilletMutation,
  useGetBuffetMilletQuery,
} from '../../../core/api/BuffetMilletApi'
import { AppTableImageViewer } from '../../../components/AppTableImageViewer/AppTableImageViewer'
import { IBuffetMilletRequest } from '../../../core/api/dto/BuffetMilletDto'
import { useParamsControl } from '../../../utils/useParamsControl'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { FileUploadMilletPopup } from './components/FileUploadMilletPopup'
import { GetFile } from '../../../utils/getFile'
import { BASE_URL } from '../../../core/api/BaseApi'
import { useDeleteDebounce } from '../../../utils/useDeleteDebounce'
import { ImagePreviewWrapperSizeEnum } from 'UiKitComponents/ImagePreviewWrapper/ImagePreviewWrapper'
import {
  AppTableAlignType,
  AppTableLink,
} from '../../../components/AppTable/components/AppTableLink'
import { AppTableNumberBlock } from '../../../components/AppTable/components/AppTableNumberBlock'
import { useTranslation } from 'react-i18next'

const headerData = [
  { title: 'Наименование', colWidth: '65%', sort: 'millet_name' },
  { title: 'Вес, кг', colWidth: '15%', sort: 'millet_weight' },
  { title: 'Фото', colWidth: '15%' },
]

export const MilletPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const pageParams = useParamsControl<
    IBuffetMilletRequest,
    keyof IBuffetMilletRequest
  >({
    withPagination: true,
    whiteList: ['search', 'archived', 'ordering'],
  })
  const {
    data: millets,
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
  } = useGetBuffetMilletQuery({ ...pageParams }, { skip: !pageParams })
  const [archiveMultipleBuffetMillet] = useArchiveMultipleBuffetMilletMutation()
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)
  const [uploadFilePopup, setUploadFilePopup] = useState<boolean>(false)
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false)

  const openUploadFilePopupHandler = useCallback(() => {
    setUploadFilePopup(true)
  }, [])

  const unloadMilletsHandler = useCallback(() => {
    setIsLoadingLocal(true)
    const params =
      pageParams && pageParams?.search ? `?search=${pageParams?.search}` : ''
    GetFile(`${BASE_URL}/buffet_millet/export_millets_to_file/${params}`)
      .then(() =>
        AppNotification({
          msg: t('Выбранные тары успешно выгружены!'),
          type: NotificationType.success,
        }),
      )
      .finally(() => setIsLoadingLocal(false))
  }, [pageParams])

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Загрузить',
        btnIco: 'import',
        onClick: openUploadFilePopupHandler,
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Выгрузить',
        btnIco: 'export',
        onClick: unloadMilletsHandler,
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Добавить тару',
        btnIco: 'add',
        onClick: () => navigate(AllRoutes.addMillet.path),
        color: AppBtnColor.whiteOutlineViolet,
      },
    ],
    [openUploadFilePopupHandler, unloadMilletsHandler, navigate],
  )

  const deleteHandler = () => {
    if (!!chooseItems) {
      archiveMultipleBuffetMillet({
        millets: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>{
          AppNotification({
            msg: t('Выбранные тары успешно удалены!'),
            type: NotificationType.success,
          })
          setChooseItems(null)
        })
    }
  }
  const [onClickHandler] = useDeleteDebounce({ fn: deleteHandler })
  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]

  if (!millets && isLoadingGet) {
    return <AppLoader loaderType={LoaderType.main} />
  }

  if (!millets) {
    return null
  }

  return (
    <React.Fragment>
      {isLoadingLocal && <AppLoader loaderType={LoaderType.main} />}
      {uploadFilePopup && (
        <FileUploadMilletPopup setActive={setUploadFilePopup} />
      )}
      <div className="width-for-page-wrapper">
        <AppTitlePage title="Тары" actions={actions} />
        <div className="col-7-width-from-1000">
          <div className="filter-block mt-20">
            <AppFilterInput
              label="Поиск"
              placeholder="Например: тарелка"
              searchParam={'search'}
              ico={'search'}
              fullWidth
            />
          </div>
          <AppTable
            data={millets}
            headerData={headerData}
            idName={'millet'}
            tableDataSelectors={[
              {
                renderItem: (item) => {
                  return (
                    <AppTableLink
                      path={`${item.id}`}
                      deleteItem={item.archived}
                    >
                      {item?.milletName}
                    </AppTableLink>
                  )
                },
              },
              {
                renderItem: (item) => {
                  return (
                    <AppTableLink
                      path={`${item.id}`}
                      deleteItem={item.archived}
                      align={AppTableAlignType.right}
                    >
                      <AppTableNumberBlock
                        value={item?.milletWeight.toFixed(3) || ''}
                      />
                    </AppTableLink>
                  )
                },
              },
              {
                renderItem: (item) => (
                  <AppTableImageViewer
                    image={item.milletPhoto || null}
                    imageName={item.milletName}
                    archived={item.archived}
                    size={ImagePreviewWrapperSizeEnum.MEDIUM}
                  />
                ),
              },
            ]}
            chooseMode
            setChooseItems={setChooseItems}
            chooseItems={chooseItems}
            chooseActions={tableActions}
            isLoading={isLoadingGet || isFetchingGet}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
