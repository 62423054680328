import React, { useCallback, useEffect, useState } from 'react'

const useScroll = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
  const [activeElement, setActiveElement] = useState(0)
  const [isVisibleLastChild, setIsVisibleLastChild] = useState(false)

  const callbackFunction = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      setIsVisibleLastChild(entry.isIntersecting)
    },
    [],
  )
  useEffect(() => {
    if (!ref.current) return
    const refCurrent = ref.current
    const observer = new IntersectionObserver(callbackFunction, {
      root: refCurrent,
      rootMargin: '0px',
      threshold: 0.99,
    })
    if (refCurrent && refCurrent.children.length)
      observer.observe(refCurrent.children[refCurrent.children.length - 1])

    return () => {
      if (refCurrent && refCurrent.children.length)
        observer.unobserve(refCurrent.children[refCurrent.children.length - 1])
    }
  }, [ref, callbackFunction])

  const scrollList = (arg: 'up' | 'down') => {
    switch (arg) {
      case 'up':
        if (activeElement === 0) {
          return
        } else if (ref.current && ref.current.children.length) {
          scrollUp(activeElement - 1)
          setActiveElement(activeElement - 1)
        }

        break
      case 'down':
        if (isVisibleLastChild || ref.current?.children.length === 1) {
          return
        } else if (ref.current && ref.current.children.length) {
          scrollDown(activeElement)
          setActiveElement(activeElement + 1)
        }

        break
    }
  }

  const getMarginTop = (el: number) =>
    ref.current &&
    +window
      .getComputedStyle(ref.current.children[el])
      .getPropertyValue('margin-top')
      .slice(0, -2)

  const scrollUp = (el: number) =>
    (ref.current!.scrollTop -=
      ref.current!.children[el].getBoundingClientRect().height +
      getMarginTop(el)!)

  const scrollDown = (el: number) =>
    (ref.current!.scrollTop +=
      ref.current!.children[el].getBoundingClientRect().height +
      getMarginTop(el)!)

  return scrollList
}

export default useScroll
