import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authInitialState } from './authState'
import { Role } from '../../api/dto/UserDto'

const authSlice = createSlice({
  name: 'foodBoxAuth',
  initialState: authInitialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { token },
      }: PayloadAction<{
        token: string | null
      }>,
    ) => {
      state.token = token
    },
    deleteCredentials: (state) => {
      state.token = null
      state.currentRole = []
    },
    setCurrentRoles: (
      state,
      {
        payload: { currentRoleByPath },
      }: PayloadAction<{ currentRoleByPath: (keyof typeof Role)[] }>,
    ) => {
      state.currentRole = currentRoleByPath
    },
  },
})

export const { setCredentials, deleteCredentials, setCurrentRoles } =
  authSlice.actions
export const { reducer: AuthReducer } = authSlice
