import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { AppPopover } from './AppPopover'
import { AppInput } from '../AppInput/AppInput'
import AppBtn, { AppBtnColor, AppBtnSize } from '../btn/AppBtn'

interface AppPopoverListProps<T, TKey extends keyof T> {
  trigger: ReactNode | string
  placeholder?: string
  subtitle?: string
  value: T[]
  data: T[]
  prevPage?: boolean
  nextPage?: boolean
  setOffset: React.Dispatch<React.SetStateAction<number>>
  propName: TKey
  propValue: TKey
  onChange: React.Dispatch<React.SetStateAction<T[]>>
  searchDebounce?: string
  setSearchDebounce: (value: string) => void
}

export const AppPopoverList = <T, TKey extends keyof T>({
  propName,
  propValue,
  value,
  trigger,
  onChange,
  subtitle,
  placeholder,
  nextPage,
  prevPage,
  setOffset,
  searchDebounce,
  setSearchDebounce,
  data,
}: AppPopoverListProps<T, TKey>) => {
  const [search, setSearch] = useState('')
  const [currentData, setCurrentData] = useState<T[]>([])
  const [loadMore, setLoadMore] = useState(false)

  const objectFromIds = useMemo(
    () =>
      value.reduce((acc: { [key: string]: boolean }, obj) => {
        acc[`${obj[propValue]}` as string] = true
        return acc
      }, {}),
    [value, propValue],
  )

  useEffect(() => {
    if (!data.length) return setCurrentData([])
    if (!!data.length && !prevPage) return setCurrentData(data)
    if (!!data.length && !!prevPage && loadMore) {
      setCurrentData((prev) => [...prev, ...data])
      setLoadMore(false)
    }
  }, [data, prevPage])

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchDebounce(search.replace('@', ''))
    }, 500)
    return () => {
      clearTimeout(handler)
    }
  }, [search])

  const onClick = (value: T) => () => {
    onChange((prevState) => {
      const finedItem = prevState.find(
        (item) => item[propValue] === value[propValue],
      )
      if (!!finedItem) {
        return prevState.filter((item) => item[propValue] !== value[propValue])
      } else {
        return [...prevState, value]
      }
    })
  }

  const nextPageHandler = () => {
    setOffset((prev) => prev + 20)
    setLoadMore(true)
  }
  const showData = searchDebounce !== ''
  return (
    <AppPopover trigger={trigger}>
      <div className="popover-list">
        <AppInput
          border
          onChange={setSearch}
          value={search}
          placeholder={placeholder}
        />
        {!!currentData.length && showData && (
          <>
            <span className="subtitle">{subtitle}</span>
            <div className="list">
              {currentData.map((item) => (
                <div
                  className={`list-item${
                    objectFromIds[`${item[propValue]}`] ? ' active' : ''
                  }`}
                  onClick={onClick(item)}
                  key={`${item[propValue]}`}
                >
                  {item[propName]}
                </div>
              ))}
              {nextPage && (
                <div className="mt-10">
                  <AppBtn
                    color={AppBtnColor.violetWhite}
                    sized={AppBtnSize.fullWidth}
                    title={'Показать ещё'}
                    onClick={nextPageHandler}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </AppPopover>
  )
}
