import React from 'react'
import { useParams } from 'react-router'
import HeaderRecipeCard from '../widgets/header/HeaderRecipeCard'
import RecipeCard from '../components/recipeCard/RecipeCard'
import { useGetDishByIdQuery } from '../core/api/DishApi'

interface IRecipeCardDish {
  isEditable?: boolean
}

const RecipeCardDish = ({ isEditable = false }: IRecipeCardDish) => {
  const { technologicalMapCardItemId } = useParams()
  const { data: dish, isSuccess } = useGetDishByIdQuery(
    technologicalMapCardItemId ? technologicalMapCardItemId : '',
    {
      skip: technologicalMapCardItemId === undefined,
    },
  )

  return (
    <div className="main">
      <HeaderRecipeCard />
      <div className="cooking-card-content">
        <div className="container-fluid container-xxl">
          {isSuccess && <RecipeCard info={dish} isEditable={isEditable} />}
        </div>
      </div>
    </div>
  )
}

export default RecipeCardDish
