import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import { AllRoutes } from '../../core/routes/AllRoutes'

const HeaderRecipeCard = () => {
  const { data: currentUser } = useGetCurrentUserQuery()
  const navigate = useNavigate()

  return (
    <header className="header cooking-chart">
      <div className="container-fluid container-xxl">
        <div className="wrapper-heading">
          <h1 className="title" onClick={() => navigate(-1)}>
            <span>Food</span>Box
          </h1>
          <Link to={`${AllRoutes.user.path}`} className="link">
            {currentUser && <span>{currentUser.name}</span>}
            <i className="an-ico an-ico-go-out" />
          </Link>
        </div>
      </div>
    </header>
  )
}

export default HeaderRecipeCard
