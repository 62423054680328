import React from 'react'
import { useParams } from 'react-router'
import { useGetDishByIdQuery } from '../../../core/api/DishApi'
import { useGetOrderLineByIdQuery } from '../../../core/api/OrderLineApi'
import BasePhotoItem from './BasePhotoItem'
import DimensionPhotos from './DimensionPhotos'

const PhotosColumn = () => {
  const { orderLineId } = useParams()

  const { data: order } = useGetOrderLineByIdQuery(orderLineId!)
  const { data: dish } = useGetDishByIdQuery(
    order?.dish ? String(order!.dish.id) : '',
    {
      skip: !order,
    },
  )

  return (
    <div className="col-xl-4 col-lg-6 col-scroll">
      <div className="column">
        {!!order?.orderLineDimensions.length && dish && (
          <DimensionPhotos
            dimensions={order.orderLineDimensions}
            nameDish={dish.name}
          />
        )}
        <BasePhotoItem
          name="Эталонное фото"
          image={dish && dish.imagePreview ? dish.imagePreview : ''}
        />
      </div>
    </div>
  )
}

export default PhotosColumn
