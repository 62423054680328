import React, { useEffect, useState } from 'react'
import Popup from '../../../components/popup/Popup'
import { AppWidgetDish } from './AppWidgetDish'
import { ICategory } from '../../../core/api/dto/CategoriesObjDto'
import { useGetCategoryQuery } from '../../../core/api/CategoryApi'
import { AppScrollWrapperNew } from '../../../widgets/AppScrollWrapper/AppScrollWrapper'
import { IBuffetDishDto } from '../../../core/api/dto/BuffetDishDto'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import { AppInput } from '../../../components/AppInput/AppInput'
import { useGetBuffetDishByCategoryQuery } from '../../../core/api/BuffetDishApi'
import { IOrderLine } from '../../../core/api/dto/PlanMenuDto'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

interface IAddDishInPlanMenuPopupProps {
  setNewDish: React.Dispatch<React.SetStateAction<IOrderLine[]>>
  closePopup: () => void
  addedDish: IOrderLine[]
  setIsDirty?: (value: boolean) => void
}

export const AddDishInPlanMenuPopup = ({
  closePopup,
  setNewDish,
  addedDish,
  setIsDirty,
}: IAddDishInPlanMenuPopupProps) => {
  const { t } = useTranslation()
  const [offset, setOffset] = useState(0)
  const [loadMore, setLoadMore] = useState(false)
  const [search, setSearch] = useState('')
  const [categoryData, setCategoryData] = useState<ICategory[]>([])

  const { data: category } = useGetCategoryQuery(
    { limit: 20, offset },
    {
      skip: search.length > 2,
    },
  )
  const { data: dishByCategory } = useGetBuffetDishByCategoryQuery(
    { search },
    { skip: search.length < 3 },
  )
  const [dishesValue, setDishesValue] = useState<IBuffetDishDto[]>([])
  useEffect(() => {
    if (!!category && offset === 0) {
      setCategoryData(category.results)
    }
    if (!!category && offset !== 0 && loadMore) {
      setCategoryData((prevState) => [...prevState, ...category.results])
      setLoadMore(false)
    }
  }, [category])
  useEffect(() => {
    setDishesValue(addedDish.map(({ dish }) => dish))
  }, [addedDish])

  const addedDishHandler = () => {
    const newDishes: IOrderLine[] = dishesValue
      .map((dish, index) => {
        const id = uuidv4()
        return {
          quantity: '0',
          lineGuid: id,
          id: new Date().valueOf() + index,
          dish,
        }
      })
      .filter(
        (dish) =>
          !addedDish.some((orderLine) => dish.dish.id === orderLine.dish.id),
      )
    const removeDish = addedDish.filter(
      (obj1) => !dishesValue.some((obj2) => obj1.dish.id === obj2.id),
    )
    setNewDish((prevState) =>
      [...prevState, ...newDishes].filter(
        (obj) => !removeDish.some((item) => item.id === obj.id),
      ),
    )
    setIsDirty && setIsDirty(true)
    closePopup()
  }
  const showElseHandler = () => {
    setOffset((prevState) => prevState + 20)
    setLoadMore(true)
  }
  return (
    <Popup closePopup={closePopup}>
      <div className="plan-menu-add-dish-popup">
        <span className="popup-title">{t('Добавление блюда')}</span>
        <AppInput
          value={search}
          onChange={setSearch}
          placeholder="Поиск"
          border
        />
        <div className="categories-list">
          <AppScrollWrapperNew>
            {!!dishByCategory && search.length > 2
              ? dishByCategory.map((dishItem) =>
                  !!dishItem.category ? (
                    <AppWidgetDish
                      title={`${dishItem.category?.name} (${dishItem.dishes.length})`}
                      categoryId={dishItem.category?.id}
                      dishesValue={dishesValue}
                      setDishesValue={setDishesValue}
                      dishesByCategoryValue={dishItem.dishes}
                      key={dishItem.category?.id}
                    />
                  ) : null,
                )
              : categoryData.map((categoryItem) =>
                  !!categoryItem.buffetDishCount ? (
                    <AppWidgetDish
                      title={`${categoryItem.name} (${categoryItem.buffetDishCount})`}
                      key={categoryItem.id}
                      categoryId={categoryItem.id}
                      dishesValue={dishesValue}
                      setDishesValue={setDishesValue}
                    />
                  ) : null,
                )}
            {!(!!dishByCategory && search.length > 2) && !!category?.next && (
              <AppBtn
                sized={AppBtnSize.fullWidth}
                color={AppBtnColor.violetWhite}
                title={'Показать ещё'}
                onClick={showElseHandler}
              />
            )}
          </AppScrollWrapperNew>
        </div>
        <div className="buttons-block mt-10">
          <AppBtn
            sized={AppBtnSize.md}
            color={AppBtnColor.outlineViolet}
            title={'Отмена'}
            iconClassName={'close'}
            onClick={closePopup}
          />
          <AppBtn
            sized={AppBtnSize.md}
            color={AppBtnColor.violetWhite}
            iconClassName={'add'}
            title={'Добавить'}
            onClick={addedDishHandler}
          />
        </div>
      </div>
    </Popup>
  )
}
