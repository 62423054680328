import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppRouter } from './core/routes/AppRouter'

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={''}>
        <AppRouter />
      </Suspense>
    </BrowserRouter>
  )
}

export default App
