import { ICategory } from './CategoriesObjDto'
import { IOrderLine } from './PlanMenuDto'
import { IBaseRequestDto, IBaseResponseDto } from './BaseDto'
import { IGuestTypeDto } from './GuestTypeDto'

export enum DimensionTranslateType {
  Serving = 'Выдача',
  Refund = 'Возврат',
  Write_off = 'Списание',
}
export enum DimensionType {
  Serving = 'Serving',
  Refund = 'Refund',
  Write_off = 'Write_off',
}

export interface CorrespondentType {
  active: boolean
  id: number
  name: string
}

export interface IReportOrderLine extends IOrderLine {
  computedDeviation: number
  order: {
    id: number
    name: string
    sourceRepresent: string
    numberOfPersons: number
    planMenuGuestType: IGuestTypeDto
    numberOfPersonsFact: number
    numberOfSold: number
    orderDateStart: string
  }
}

interface IDimension {
  id: number
  guid: string
  photoUrl: string | null
  photoUrlOnS3: string | null
  thermoPhotoUrl: string | null
  thermoPhotoUrlOnS3: string | null
  temperature: number
  quantity: number
  weight: number
  dimensionDatetime: string
  refund: boolean
  orderLine: IReportOrderLine
  dimensionType: DimensionType
  correspondentType: CorrespondentType
}

export interface IBuffetReportBase {
  name: string
  standardWeight: number
  standardWeightTotal: number
  standardDeviation: number
  standardTempMin: number
  standardTempMax: number
  implementationPeriod: number
  image: string
  dimensionCount: number
  numberOfPersonsSum: number
  orderLinesQuantitySum: number
  dimensionsQuantitySum: number
  standardDeviationTotal: number
  isTotalWeightValid: boolean
  maxTemperature: number
  minTemperature: number
  avgTemperature: number
  lastDimension: string
  numberOfPersonsFactSum: number
  numberOfSoldSum: number
}

export interface IBuffetReportDto extends IBuffetReportBase {
  id: number
  sourceIdent: string
  createdAt: string
  dimensions: IDimension[]
  imagePreview: string
  category: ICategory
}

export interface IBuffetReportListDto extends IBaseResponseDto {
  results: IBuffetReportDto[]
}

export interface IBuffetReportRequest extends IBaseRequestDto {
  search?: string
  archived?: string
  ordering?: string
  plan_menu_ids?: string
  dish_ids?: string
  category_ids?: string
  dimension_date_gte?: string
  dimension_date_lte?: string
  dimension_temp_gte?: string
  dimension_temp_lte?: string
  dimension_type?: string
  plan_menu_guest_type_ids?: string
}
