import { NavLink } from 'react-router-dom'
import React from 'react'

export enum AppTableAlignType {
  right = 'right',
  left = 'left',
  center = 'center',
}

interface IAppTableLinkProps {
  path: string
  deleteItem?: boolean
  align?: AppTableAlignType
  children: JSX.Element | string | number | null
}

export const AppTableLink = ({
  path,
  deleteItem,
  children,
  align = AppTableAlignType.left,
}: IAppTableLinkProps) => {
  return (
    <NavLink
      to={path}
      className={`table-link${deleteItem ? ' delete-item' : ''} ${align}`}
    >
      {children}
    </NavLink>
  )
}
