import React, { memo, useState } from 'react'
import { AppTableLink } from '../../../components/AppTable/components/AppTableLink'
import {
  AppTableStatus,
  AppTableTaskStatusEnum,
} from '../../../components/AppTable/AppTableStatus/AppTableStatus'
import moment from 'moment'
import { AppTable } from '../../../components/AppTable/AppTable'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useDeleteDebounce } from '../../../utils/useDeleteDebounce'
import { useArchiveMultipleTasksMutation } from '../../../core/api/TaskApi'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import { ITasksResponseDto } from '../../../core/api/dto/TaskDto'
import { NotificationDot } from '../../../UiKitComponents/NotificationDot'

const headerData = [
  { title: 'Название', sort: 'title' },
  { title: 'Дата', sort: 'date' },
  { title: 'Статус', colWidth: '10%', sort: 'status' },
  { title: 'Сроки выполнения', sort: 'expectedcompletion' },
  { title: 'Создатель', sort: 'creator' },
  { title: 'Исполнитель', sort: 'responsible' },
]

interface TaskListTableProps {
  taskList: ITasksResponseDto
}

export const TaskListTable = memo(({ taskList }: TaskListTableProps) => {
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)
  const [archiveMultipleTasks] = useArchiveMultipleTasksMutation()
  const archiveTask = () => {
    if (!!chooseItems) {
      archiveMultipleTasks({
        tasks: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>
          AppNotification({
            msg: 'Выбранные задачи успешно удалены!',
            type: NotificationType.success,
          }),
        )
    }
  }
  const [onClickHandler] = useDeleteDebounce({ fn: archiveTask })

  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]

  return (
    <AppTable
      data={taskList}
      headerData={headerData}
      chooseActions={tableActions}
      chooseMode
      chooseItems={chooseItems}
      importantItem={'isImportant'}
      setChooseItems={setChooseItems}
      tableDataSelectors={[
        {
          renderItem: (item) => {
            return (
              <AppTableLink
                path={`${item.id}`}
                deleteItem={item.status === AppTableTaskStatusEnum.DELETED}
              >
                <TaskNotification
                  text={item.title}
                  isNotification={item.hasNotifications}
                />
              </AppTableLink>
            )
          },
        },
        {
          renderItem: (item) => {
            return (
              <AppTableLink
                path={`${item.id}`}
                deleteItem={item.status === AppTableTaskStatusEnum.DELETED}
              >
                <div className="clip-base">
                  {moment(item.createdAt).format('DD.MM.YYYY')}
                </div>
              </AppTableLink>
            )
          },
        },
        {
          renderItem: (item) => <AppTableStatus status={item.status} />,
        },
        {
          renderItem: (item) => {
            return (
              <AppTableLink
                path={`${item.id}`}
                deleteItem={item.status === AppTableTaskStatusEnum.DELETED}
              >
                <div className="clip-base">
                  {item.expectedCompletionDate
                    ? moment(item.expectedCompletionDate).format('DD.MM.YYYY')
                    : ''}
                </div>
              </AppTableLink>
            )
          },
        },
        {
          renderItem: (item) => {
            return (
              <AppTableLink
                path={`${item.id}`}
                deleteItem={item.status === AppTableTaskStatusEnum.DELETED}
              >
                <div className="clip-base">{item.creator.name}</div>
              </AppTableLink>
            )
          },
        },
        {
          renderItem: (item) => {
            return (
              <AppTableLink
                path={`${item.id}`}
                deleteItem={item.status === AppTableTaskStatusEnum.DELETED}
              >
                <div className="clip-base">{item?.responsible?.name || ''}</div>
              </AppTableLink>
            )
          },
        },
      ]}
    />
  )
})

interface TaskNotificationProps {
  text: string
  isNotification: boolean
}

const TaskNotification = ({ text, isNotification }: TaskNotificationProps) => {
  return (
    <div className="task-notification clip-base">
      {text}
      {isNotification && <NotificationDot />}
    </div>
  )
}
