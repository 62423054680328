import React, { useMemo, useRef } from 'react'
import { useGetWorkingOrdersQuery } from '../../core/api/OrderApi'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import { Condition, getOrders } from '../../pages/Waiter'
import HeaderColumn from '../headerColumn/HeaderColumn'
import OrderCardCook from '../orderCard/OrderCardCook'

interface IColumnProps {
  isAllOrders: boolean
}

const WorkingOrdersColumn = ({ isAllOrders }: IColumnProps) => {
  const refColumnWork = useRef<HTMLDivElement | null>(null)
  const { data: currentUser } = useGetCurrentUserQuery()
  const { data: workingOrders } = useGetWorkingOrdersQuery(
    currentUser?.userRoles.restaurant.shiftNum
      ? currentUser.userRoles.restaurant.shiftNum
      : '',
    {
      pollingInterval: 5000,
      skip: !currentUser,
    },
  )

  const orders = useMemo(() => {
    if (workingOrders?.length) {
      return getOrders(workingOrders, isAllOrders, Condition.CookStatus)
    }

    return []
  }, [workingOrders, isAllOrders])

  return (
    <div className="col-xl-3 col-lg-3 col-md-6 col-scroll">
      <HeaderColumn title="В работе" refContent={refColumnWork} />
      <div className="column mt-10" ref={refColumnWork}>
        {orders?.length
          ? orders.map((el) => (
              <OrderCardCook
                info={el}
                key={`working-card-${el.id}`}
                isAllOrders={isAllOrders}
              />
            ))
          : null}
      </div>
    </div>
  )
}

export default WorkingOrdersColumn
