import cls from './ModalMobile.module.scss'
import { ReactNode } from 'react'
import { AppFlex } from '../AppFlex/AppFlex'
import { classNames, Mods } from '../../lib/classNames/classNames'

interface ModalMobileProps {
  isOpen?: boolean
  children: ReactNode
  onClose: () => void
  onToggle: () => void
  className?: string
  title?: string
}

export const ModalMobile = ({
  isOpen,
  children,
  onClose,
  onToggle,
  className,
  title,
}: ModalMobileProps) => {
  const mods: Mods = {
    [cls.isOpen]: !!isOpen,
    'app-modal': isOpen,
  }

  return (
    <>
      <div className={classNames(cls.modalMobileWrap, mods, [className])}>
        <AppFlex fullWidth justify="center" onClick={onToggle}>
          <div className={cls.line} />
        </AppFlex>
        {title && (
          <AppFlex className={cls.titleWrap} justify="between" fullWidth>
            <span className={cls.title}>{title}</span>
          </AppFlex>
        )}
        <div>{children}</div>
      </div>
    </>
  )
}
