import { api } from './BaseApi'
import {
  IPlanMenuCloseOrderDetailDto,
  IPlanMenuCloseOrdersDto,
  IPlanMenuCloseOrdersRequest,
  IUpdatePlanMenuCloseOrderDto,
} from './dto/PlanMenuCloseDto'
import { IPlanMenuDto } from './dto/PlanMenuDto'

export const planMenuCloseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlanMenuCloseOrders: builder.query<
      IPlanMenuCloseOrdersDto,
      IPlanMenuCloseOrdersRequest
    >({
      query: (params) => ({
        url: '/plan_menu_close/',
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenuControl'],
    }),
    getPlanMenuCloseOrder: builder.query<
      IPlanMenuCloseOrderDetailDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/plan_menu_close/${id}/`,
      }),
      providesTags: ['PlanMenuControl'],
    }),
    updatePlanMenuCloseOrder: builder.mutation<
      IPlanMenuDto,
      { id: number; data: IUpdatePlanMenuCloseOrderDto }
    >({
      query: ({ id, data }) => ({
        method: 'PATCH',
        url: `/plan_menu_close/${id}/`,
        body: data,
      }),
      invalidatesTags: ['PlanMenuControl'],
    }),
  }),
})

export const {
  useGetPlanMenuCloseOrdersQuery,
  useGetPlanMenuCloseOrderQuery,
  useUpdatePlanMenuCloseOrderMutation,
} = planMenuCloseApi
