import { useIsAdmin } from '../../../../utils/useIsAdmin'
import {
  useGetPlanMenuControlOrderLineOnlyReadQuery,
  useGetPlanMenuControlOrderLineQuery,
} from '../../../../core/api/PlanMenuControlApi'
import { IPlanMenuControlOrdersRequest } from '../../../../core/api/dto/PlanMenuControlDto'

export const useGetOrderLine = (
  pageParams: IPlanMenuControlOrdersRequest | null,
  orderId?: number,
) => {
  const isAdmin = useIsAdmin()
  const { data: orderLineList } = useGetPlanMenuControlOrderLineQuery(
    { id: orderId!, ...pageParams },
    { skip: !orderId || isAdmin === false || isAdmin === null },
  )
  const { data: orderLineListOnlyRead } =
    useGetPlanMenuControlOrderLineOnlyReadQuery(
      { order: orderId!, ...pageParams },
      { skip: !orderId || isAdmin || isAdmin === null },
    )

  return isAdmin ? orderLineList : orderLineListOnlyRead?.results
}
