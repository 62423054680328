import React from 'react'
import { useGetDishesWithoutCategoryQuery } from '../../core/api/DishApi'
import MealCard from '../mealCard/MealCard'
import NotFound from '../notFound/NotFound'
import SortingMemoized from '../sorting/Sorting'
import useFilterQuery from '../../utils/useFilterQuery'
import Spinner, {
  SpinnerColor,
  SpinnerPosition,
  SpinnerSize,
} from '../spinner/Spinner'
import Pagination from '../pagination/Pagination'
import { sorts } from './TechnologicalMapCatalogCategory'
import { LIMIT } from '../../core/api/BaseApi'

const TechnologicalMapCatalogWithoutCategory = () => {
  const { searchString, getCurrentSearchParamValue, setNewSearchParams } =
    useFilterQuery()
  const { data: dishes, isFetching } =
    useGetDishesWithoutCategoryQuery(searchString)
  return (
    <>
      {dishes?.results?.length ? (
        <div className="column cards">
          <div className="container-fluid">
            <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2">
              <div className="wrapper-sorting-pagination">
                <SortingMemoized sorts={sorts} defaultSorting={sorts[0].name} />
                <Pagination
                  isFetching={isFetching}
                  limit={LIMIT.chef}
                  countPage={Math.ceil(dishes.count / LIMIT.chef)}
                  next={Boolean(dishes.next)}
                  prev={Boolean(dishes.previous)}
                  offset={Number(getCurrentSearchParamValue('offset'))}
                  changePage={(value: string) =>
                    setNewSearchParams({
                      searchParamName: 'offset',
                      value,
                    })
                  }
                />
              </div>
              {isFetching ? (
                <Spinner
                  color={SpinnerColor.violet}
                  size={SpinnerSize.lg}
                  spinnerPosition={SpinnerPosition.center}
                />
              ) : (
                <>
                  {dishes.results.map((el) => (
                    <div className="col" key={`${el.name}-${el.id}`}>
                      <MealCard info={el} />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <NotFound
          title="Технологические карты отсутствуют"
          path="/assets/images/meal.png"
        />
      )}
    </>
  )
}

export default TechnologicalMapCatalogWithoutCategory
