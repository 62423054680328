import { AppLoader, LoaderType } from 'components/AppLoader'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
export enum PopupSize {
  sm = 'sm',
  md = 'md',
  base = '',
}

export enum PopupBtnBgColor {
  violet = 'Violet',
  grayMedium = 'Gray-medium',
  red = 'Red',
}

export enum PopupTypeEnum {
  default = 'popup-root',
  photo = 'popup-photo-root',
}

interface IPopupProps {
  size?: PopupSize
  actions?: IPopupAction[]
  closePopup?: () => void
  borderRadiusMin?: boolean
  isLoading?: boolean
  popupType?: PopupTypeEnum
  className?: string
}

interface IPopupContent {
  iconName?: string
  title: string
  text: string
}

interface IPopupAction {
  text: string | JSX.Element
  onClick?: () => void
  bgColor: PopupBtnBgColor
}

interface IPopupChildren extends IPopupProps {
  children: JSX.Element
  content?: never
}

interface IPopupText extends IPopupProps {
  content: IPopupContent
  children?: never
}

type TPropsPopup = IPopupChildren | IPopupText

export const getRootPopup = (type: PopupTypeEnum) => {
  let PopupRoot = document.getElementById(type)

  if (PopupRoot === null) {
    PopupRoot = document.createElement('div')
    PopupRoot.setAttribute('id', type)
    document.body.appendChild(PopupRoot)
  }

  return PopupRoot
}

const Popup = ({
  popupType = PopupTypeEnum.default,
  size = PopupSize.base,
  content,
  children,
  closePopup,
  actions,
  borderRadiusMin,
  isLoading,
  className,
}: TPropsPopup) => {
  useEffect(() => {
    const scrollTop = window.scrollY
    const bodyNode = document.querySelector('body')
    if (bodyNode) {
      bodyNode.style.position = 'fixed'
      bodyNode.style.top = -scrollTop + 'px'
    }
    return () => {
      if (bodyNode) {
        bodyNode.style.removeProperty('top')
        bodyNode.style.removeProperty('position')
        window.scrollTo(0, scrollTop)
      }
    }
  }, [])

  return ReactDOM.createPortal(
    <div
      className={`wrapper-popup ${size}`}
      style={popupType === PopupTypeEnum.photo ? { zIndex: 100 } : undefined}
    >
      <div className="popup-bg" onClick={closePopup} />
      <div
        className={`popup ${borderRadiusMin ? 'b-radius-15' : ''} ${
          className || ''
        }`}
      >
        {isLoading && <AppLoader loaderType={LoaderType.table} />}
        {content ? (
          <div className="popup-content">
            {content.iconName && (
              <div className="popup-type-ico">
                <i className={`an-ico an-ico-${content.iconName}`} />
              </div>
            )}
            <h4>{content.title}</h4>
            <p>{content.text}</p>
          </div>
        ) : (
          children
        )}
        {actions?.length ? (
          <div className="popup-action">
            {actions.map(({ onClick, text, bgColor }) => (
              <div
                className={`popup-action-btn bg-color-${bgColor}`}
                onClick={onClick}
                key={`action-${text}`}
              >
                {text}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>,
    getRootPopup(popupType),
  )
}
export default Popup
