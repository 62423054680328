import { formatNumberWithSpaces } from '../../../utils/formatNumberWithSpaces'
import React from 'react'

interface AppTableNumberBlockProps {
  value: number | string
  isTemp?: boolean
}

export const AppTableNumberBlock = ({
  value,
  isTemp,
}: AppTableNumberBlockProps) => {
  return !!Number(value) && !!isTemp ? (
    Number(value) > 0 ? (
      <span className={'color-Orange'}> {formatNumberWithSpaces(value)} </span>
    ) : (
      <span className={'color-Blue'}> {formatNumberWithSpaces(value)} </span>
    )
  ) : (
    <span>{value}</span>
  )
}
