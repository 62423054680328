import React from 'react'
import { useTranslation } from 'react-i18next'

interface ITextWithLabel {
  label: string
  text: string
  fullHeight?: boolean
}

export const TextWithLabel = ({ label, text, fullHeight }: ITextWithLabel) => {
  const { t } = useTranslation()
  return (
    <div className="text-with-label-block mt-20">
      <label>{t(label)}</label>
      <span
        className={fullHeight ? 'full-height' : ''}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}
