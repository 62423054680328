import { IBuffetGeneralReportListDto } from 'core/api/dto/BuffetGeneralReportDto'

export interface IReportImagePopupDto {
  id: string
  isCategory: boolean
  categoryImage?: string | null
  dimensionImage?: string | null
  name: string
  dishParam?: number | null
  orderParam?: number | null
  standardTempMax?: number | null
  standardTempMin?: number | null
  temperature?: number | string | null
}

export const getReportImagePopupData = (
  data: IBuffetGeneralReportListDto,
): IReportImagePopupDto[] =>
  data.results.reduce(
    (
      result,
      {
        id,
        imageOnS3: categoryImage,
        name,
        dates: dimensions,
        standardTempMax,
        standardTempMin,
        temperatureAvg,
        temperatureMax,
        temperatureMin,
      },
    ) => {
      result.push({
        id: id.toString(),
        isCategory: true,
        name,
        categoryImage,
        standardTempMax,
        standardTempMin,
        temperature: `${temperatureMin?.toFixed(0)}-${temperatureAvg?.toFixed(0)}-${temperatureMax?.toFixed(0)}`,
      })

      dimensions
        .map((el) => el.dimensions)
        .flat()
        .forEach((item) => {
          result.push({
            id: `${item.id}-dimension`,
            name,
            isCategory: false,
            dimensionImage: item?.photoUrlOnS3 || item.photoUrl,
            categoryImage,
            dishParam: id,
            orderParam: item.orderId,
            standardTempMax: standardTempMax,
            standardTempMin: standardTempMin,
            temperature: item.temperature
          })
        })

      return result
    },
    new Array<IReportImagePopupDto>(),
  )
