import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useUpdatePlanMenuOrderCommentMutation } from '../../../../core/api/PlanMenuControlApi'
import {
  IPlanMenuControlOrderDto,
  IPlanMenuControlOrderLineDto,
  IPlanMenuControlOrdersRequest,
  PlanMenuStatusType,
} from '../../../../core/api/dto/PlanMenuControlDto'
import {
  AppNotification,
  NotificationType,
} from '../../../../components/notification/Notification'
import { CommentBlock } from '../components/CommentBlock'
import moment from 'moment'
import { getTimeFromMins } from '../../DishesPage/CreateDishPage'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import './DishesList.css'
import { AddTaskItemButton } from 'UiKitComponents/EmployeeTask/AddTaskItemButton/AddTaskItemButton'
import { useCreateTaskObjectMutation } from 'core/api/TaskApi'
import { useGetPlanMenuDetailByRole } from '../hooks/useGetPlanMenuDetailByRole'
import { useGetOrderLine } from '../hooks/useGetOrderLine'
import { useTranslation } from 'react-i18next'

enum StatusDish {
  notPlanned = 'notPlanned',
  notIssued = 'notIssued',
}

enum StatusDishView {
  notPlanned = 'незапланированное',
  notIssued = 'не выданное',
}

interface IDishesListProps {
  pageParams: IPlanMenuControlOrdersRequest | null
  withTaskAddButton?: boolean
  isAdmin?: boolean
}

export const DishesList = ({
  pageParams,
  withTaskAddButton,
  isAdmin,
}: IDishesListProps) => {
  const [searchParams] = useSearchParams()
  const currentOrderId = searchParams.get('order')
  const currentOrder = useGetPlanMenuDetailByRole(Number(currentOrderId || '0'))

  return currentOrder && !!currentOrderId ? (
    <div className="dishes-list-wrap">
      <UserBlock
        currentOrder={currentOrder}
        orderId={Number(currentOrderId || 0)}
        isAdmin={isAdmin}
      />
      <DishesListBlock
        orderId={Number(currentOrderId || 0)}
        pageParams={pageParams}
        withTaskAddButton={withTaskAddButton}
      />
    </div>
  ) : null
}

interface IUserBlockProps {
  orderId?: number
  isAdmin?: boolean
  currentOrder: IPlanMenuControlOrderDto
}

const UserBlock = ({ currentOrder, orderId, isAdmin }: IUserBlockProps) => {
  const { t } = useTranslation()
  const [updateComment] = useUpdatePlanMenuOrderCommentMutation()

  const updateCommentHandler = (comment: string) => {
    if (!!orderId && isAdmin) {
      updateComment({ id: orderId, comment })
        .unwrap()
        .then(
          () =>
            comment &&
            comment !== currentOrder.comment &&
            AppNotification({
              msg: `Комментарий успешно ${
                currentOrder.comment ? 'обнавлен' : 'добавлен'
              }`,
              type: NotificationType.success,
            }),
        )
    }
  }

  return (
    <div className="user-block">
      {!!currentOrder?.lastStatusHistory && (
        <div className="user-data">
          <div className="user-item">
            <i className="an-ico an-ico-user-one" />
            <span>{t('Пользователь')}:</span>
            <div className="dotes" />
            <span className="value">
              {currentOrder?.lastStatusHistory.user.name}
            </span>
          </div>
          <div className="user-item">
            <i className="an-ico an-ico-watch" />
            <span>
              {t(
                currentOrder?.lastStatusHistory.status ===
                  PlanMenuStatusType.Closed
                  ? 'Время закрытия'
                  : 'Время открытия',
              )}
            </span>
            <div className="dotes" />
            <span>
              {moment(currentOrder?.lastStatusHistory.createdAt).format(
                'HH:mm',
              )}
            </span>
          </div>
        </div>
      )}
      <CommentBlock
        commentMain={currentOrder.comment}
        updateComment={updateCommentHandler}
        disabled={
          currentOrder.planMenuStatus === PlanMenuStatusType.Closed || !isAdmin
        }
      />
    </div>
  )
}

interface IDishItem {
  orderId?: number
  orderItem: IPlanMenuControlOrderLineDto
  withTaskAddButton?: boolean
}

export const DishesListBlock = ({
  orderId,
  pageParams,
  withTaskAddButton,
}: {
  orderId?: number
  pageParams: IPlanMenuControlOrdersRequest | null
  withTaskAddButton?: boolean
}) => {
  const orderList = useGetOrderLine(pageParams, orderId)

  return (
    <div className="dishes-list">
      <AppScrollWrapperNew theme="dropdown">
        {orderList &&
          orderList?.map((orderItem, index) => (
            <DishItem
              orderItem={orderItem}
              key={`${orderItem.id}-dsh-${index}`}
              withTaskAddButton={withTaskAddButton}
            />
          ))}
      </AppScrollWrapperNew>
    </div>
  )
}

export const DishItem = ({ orderItem, withTaskAddButton }: IDishItem) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentDishId = searchParams.get('dish')
  const onDishHandler = () => {
    searchParams.set('dish', `${orderItem.id}`)
    searchParams.delete('dimension')
    searchParams.delete('index')
    setSearchParams(searchParams.toString())
  }

  const [createTaskObject] = useCreateTaskObjectMutation()

  const onAddTaskObjectClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    createTaskObject({ orderLine: orderItem.id })
      .unwrap()
      .then(() => {
        AppNotification({
          msg: 'Объект успешно добавлен',
          type: NotificationType.success,
        })
      })
  }

  return (
    <div
      className={`dish-item ${
        orderItem.id === Number(currentDishId || 0) ? 'active' : ''
      }`}
      onClick={onDishHandler}
    >
      <div
        className="time-category-wrapper"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div
          className="time-category"
          style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
        >
          <div className="time" style={{ display: 'flex', gap: '16px' }}>
            <div>
              {t('ВЫДАНО')}:
              <b>
                {orderItem.lastDimensionTime
                  ? moment(orderItem.lastDimensionTime).format('HH:mm')
                  : '---'}
              </b>
            </div>
          </div>
          <div className="category">{orderItem.dish?.category?.name}</div>
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          {withTaskAddButton && orderItem.id === Number(currentDishId) && (
            <div className="content-button-wrapper" style={{ height: '32px' }}>
              <AddTaskItemButton
                onClick={onAddTaskObjectClick}
                width={'32px'}
              />
            </div>
          )}
          <DishStatus
            status={
              !orderItem.deliveredStatus
                ? StatusDish.notIssued
                : !orderItem.planned
                ? StatusDish.notPlanned
                : null
            }
          />
        </div>
      </div>
      <div className="dishes-list__title">
        <span>{orderItem.dish?.name}</span>
        {orderItem.dish.imagePreview ? (
          <img
            src={orderItem.dish.imagePreview || ''}
            alt={''}
            width={'60'}
            height={'60px'}
          />
        ) : (
          <div className="ico-wrap">
            <i className="an-ico an-ico-photo-add" />
          </div>
        )}
      </div>
      <div
        className={`realization-time ${
          !!orderItem.implementationTimeExceeded ? 'error' : ''
        }`}
      >
        <span>{t('Время реализации')}</span>
        <span className="time">
          {orderItem.implementationTimeExceeded
            ? getTimeFromMins(orderItem.implementationTimeExceeded)
            : orderItem.implementationTime
            ? getTimeFromMins(orderItem.implementationTime)
            : '---'}
        </span>
      </div>
    </div>
  )
}

interface IDishStatusProps {
  status: null | StatusDish
}

const DishStatus = React.memo(({ status }: IDishStatusProps) => {
  const { t } = useTranslation()
  switch (status) {
    case StatusDish.notPlanned:
      return (
        <div className="status">{t(StatusDishView[StatusDish.notPlanned])}</div>
      )
    case StatusDish.notIssued:
      return (
        <div className="status wait">
          {t(StatusDishView[StatusDish.notIssued])}
        </div>
      )
    default:
      return null
  }
})
