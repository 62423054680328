import React, { useCallback, useEffect, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { useNavigate, useParams } from 'react-router'
import { useGetBuffetDishDetailQuery } from '../../../core/api/BuffetDishApi'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppFilterInput } from '../../../components/AppInput/AppFilterInput'
import { useGetBuffetMilletByDishQuery } from '../../../core/api/BuffetMilletApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import {
  IBuffetMilletDto,
  IBuffetMilletRequest,
} from '../../../core/api/dto/BuffetMilletDto'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import { AppScrollWrapperNew } from '../../../widgets/AppScrollWrapper/AppScrollWrapper'
import { useSearchParams } from 'react-router-dom'
import { MilletItem } from './components/MilletItem'
import {
  useAddDimensionMutation,
  useGetCurrentTemperatureQuery,
} from '../../../core/api/OrderApi'
import { IBuffetDimension } from '../../../core/api/dto/OrderObjDto'
import {
  CorrespondentType,
  DimensionType,
} from '../../../core/api/dto/BuffetReportDto'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { NewDimensionErrorPopup } from './components/NewDimensionErrorPopup/NewDimensionErrorPopup'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useAppSelector } from '../../../core/store/store'
import { TotalWeightShow } from './components/TotalWeightShow'
import { useTranslation } from 'react-i18next'
import { WriteOffPopup } from './components/WriteOffPopup'

const frozeBtnData = [
  {
    name: 'Выдача',
    value: DimensionType.Serving,
  },
  {
    name: 'Списание',
    value: DimensionType.Write_off,
  },
  {
    name: 'Возврат',
    value: DimensionType.Refund,
  },
]

export const AddPlanMenuIssuingPage = () => {
  const { t } = useTranslation()
  const { dishId, oderId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isFetchError, addDimensionCount, addTempCount } = useAppSelector(
    (state) => state.connection,
  )
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigate = useNavigate()
  const pageParams = useParamsControl<
    IBuffetMilletRequest,
    keyof IBuffetMilletRequest
  >({
    withPagination: true,
    whiteList: ['search'],
  })
  const { data: currentDish, isLoading: isLoadingDish } =
    useGetBuffetDishDetailQuery({ id: Number(dishId!) }, { skip: !dishId })
  const { data: milletList, isLoading: isLoadingMilletList } =
    useGetBuffetMilletByDishQuery(
      { ...pageParams, dishId: Number(dishId || 0) },
      { skip: !pageParams || !dishId },
    )
  const [writeOffPopup, setWriteOffPopup] = useState(false)
  const [currentCorrespondent, setCurrentCorrespondent] =
    useState<null | CorrespondentType>(null)
  const [milletListData, setMilletListData] = useState<IBuffetMilletDto[]>([])
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [isLoadingError, setIsLoadingError] = useState<boolean>(false)
  const [isLeavePage, setIsLeavePage] = useState<boolean>(false)
  const [orderType, setOrderType] = useState<DimensionType>(
    DimensionType.Serving,
  )
  const [isGetTempFetch, setIsGetTempFetch] = useState(false)
  const [currentMillet, setCurrentMillet] = useState<null | IBuffetMilletDto>(
    null,
  )
  const [addDimension, { isLoading: isLoadingAdded }] =
    useAddDimensionMutation()
  const {
    data: dishTemperature,
    isLoading: isLoadingGetTemp,
    refetch,
  } = useGetCurrentTemperatureQuery(Number(dishId || 0), {
    skip: !isGetTempFetch || isLeavePage || isLoadingAdded,
  })
  const onDimensionHandler = () => {
    setIsGetTempFetch(true)
  }

  const onAddDimension = useCallback(
    (dim: IBuffetDimension) => {
      addDimension(dim)
        .unwrap()
        .then(() => {
          setIsLeavePage(true)
          AppNotification({
            msg: t('Замер сделан'),
            type: NotificationType.success,
          })
          navigate(`/${AllRoutes.planMenuIssuing.path}/?ids=${oderId}`)
        })
        .catch((error) => console.log('onAddDimension catch', error))
    },
    [oderId, navigate, addDimension],
  )

  useEffect(() => {
    if (!!milletList && `${pageParams?.offset}` === '0') {
      setMilletListData(milletList.results)
    }
    if (!!milletList && `${pageParams?.offset}` !== '0' && loadMore) {
      setMilletListData((prev) => [...prev, ...milletList.results])
      setLoadMore(false)
    }
    // eslint-disable-next-line
  }, [milletList])

  const nextPageHandler = () => {
    const nextUrl = milletList?.next ? new URL(milletList.next) : ''
    const nextOffset = nextUrl ? nextUrl.searchParams.get('offset') : null
    if (!!nextOffset) {
      searchParams.set('offset', `${nextOffset}`)
      setSearchParams(searchParams.toString())
      setLoadMore(true)
    }
  }

  const setCurrentMilletHandler = (millet: null | IBuffetMilletDto) => {
    setIsGetTempFetch(false)
    setCurrentMillet(millet)
  }

  const [newDimension, setNewDimension] = useState<IBuffetDimension | null>(
    null,
  )

  useEffect(() => {
    if (!!dishTemperature && isGetTempFetch) {
      if (currentMillet && !!dishId && !!oderId && !!dishTemperature) {
        const dim: IBuffetDimension = {
          order: Number(oderId),
          dish: Number(dishId),
          millet: currentMillet.id,
          dimensionType: orderType,
          correspondentType:
            currentCorrespondent && orderType === DimensionType.Write_off
              ? currentCorrespondent.id
              : undefined,
        }
        const { standardTempMin, tempCurrent, standardTempMax } =
          dishTemperature
        if (
          (standardTempMin <= tempCurrent && tempCurrent <= standardTempMax) ||
          orderType === DimensionType.Refund ||
          orderType === DimensionType.Write_off
        ) {
          onAddDimension(dim)
        } else {
          setIsOpenPopup(true)
        }
        setNewDimension(dim)
      }
      setIsGetTempFetch(false)
    }
    // eslint-disable-next-line
  }, [
    dishTemperature,
    currentMillet,
    oderId,
    dishId,
    onAddDimension,
    isGetTempFetch,
    orderType,
    currentCorrespondent,
  ])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (
      !isFetchError &&
      addDimensionCount !== 0 &&
      addDimensionCount <= 3 &&
      newDimension
    ) {
      setIsLoadingError(true)
      timeout = setTimeout(() => onAddDimension(newDimension), 1000)
    } else {
      setIsLoadingError(false)
    }
    return () => {
      if (
        !isFetchError &&
        addDimensionCount !== 0 &&
        addDimensionCount <= 3 &&
        newDimension
      ) {
        clearTimeout(timeout)
      }
    }
  }, [addDimensionCount, newDimension, onAddDimension, isFetchError])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (!isFetchError && addTempCount !== 0 && addTempCount <= 3 && !!refetch) {
      setIsLoadingError(true)
      timeout = setTimeout(() => {
        refetch()
      }, 1000)
    } else {
      setIsLoadingError(false)
      setIsGetTempFetch(false)
    }
    return () => {
      if (
        !isFetchError &&
        addTempCount !== 0 &&
        addTempCount <= 3 &&
        !!refetch
      ) {
        clearTimeout(timeout)
      }
    }
  }, [refetch, addTempCount, isFetchError])

  const orderTypeHandler = (value: DimensionType) => () => {
    if (!writeOffPopup && value === DimensionType.Write_off) {
      setWriteOffPopup(true)
      setCurrentCorrespondent(null)
    } else {
      setCurrentCorrespondent(null)
      setIsGetTempFetch(false)
      setOrderType(value)
    }
  }

  return (
    <div className="width-for-page-wrapper position-rel">
      {(isLoadingDish || isLoadingMilletList) && (
        <AppLoader loaderType={LoaderType.main} />
      )}
      {writeOffPopup && (
        <WriteOffPopup
          onClose={() => setWriteOffPopup(false)}
          currentCorrespondent={currentCorrespondent}
          setCurrentCorrespondent={setCurrentCorrespondent}
          setOrderType={setOrderType}
        />
      )}
      {isOpenPopup && newDimension && dishTemperature && !isFetchError && (
        <NewDimensionErrorPopup
          loading={isLoadingAdded || !!addDimensionCount}
          onAddDimension={onAddDimension}
          newDimension={newDimension}
          dishTemperature={dishTemperature}
          setIsGetTempFetch={setIsGetTempFetch}
          setIsOpenPopup={setIsOpenPopup}
        />
      )}
      <AppTitlePage title={`${currentDish?.name || ''}`} goBack />
      <div className="froze-filter-block mt-10">
        <div className="col">
          <AppFilterInput
            searchParam="search"
            label="Название тары"
            placeholder="Например: поднос"
            fullWidth
            ico="search"
          />
        </div>
        <TotalWeightShow
          currentWeightDisable={
            !!isFetchError ||
            isOpenPopup ||
            isLoadingAdded ||
            isLoadingGetTemp ||
            isLoadingError ||
            writeOffPopup
          }
          isOpenPopup={!!isFetchError}
          currentMillet={currentMillet}
        />
      </div>
      <div className="froze-dishes-list-wrap">
        <AppScrollWrapperNew>
          {milletListData.map((millet, index) => {
            return (
              <MilletItem
                millet={millet}
                key={`${millet.id}-m-${index}`}
                currentMillet={currentMillet}
                setCurrentMillet={setCurrentMilletHandler}
              />
            )
          })}
          {!!milletList?.next && (
            <AppBtn
              title="Показать ещё"
              color={AppBtnColor.violetWhite}
              sized={AppBtnSize.fullWidth}
              onClick={nextPageHandler}
            />
          )}
        </AppScrollWrapperNew>
      </div>
      <div className="froze-btns-list">
        {frozeBtnData.map((btn, index) => (
          <div
            className={`froze-btn ${orderType === btn.value ? 'active' : ''}`}
            onClick={orderTypeHandler(btn.value)}
            key={btn.value + index}
          >
            {t(btn.name)}
          </div>
        ))}
        <div
          className={`froze-btn done ${
            !currentMillet ||
            isLoadingAdded ||
            isLoadingGetTemp ||
            isLoadingError
              ? 'disabled'
              : ''
          }`}
          onClick={onDimensionHandler}
        >
          {isLoadingAdded || isLoadingGetTemp || isLoadingError ? (
            <AppLoader loaderType={LoaderType.dimension} />
          ) : (
            <div className="done-wrap">
              <div className="circle">
                <i className="an-ico an-ico-done" />
              </div>
              <div>{t('Применить')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
