import React, {
  UIEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import './EditTaskCommentList.scss'
import { EditTaskComment } from '../EditTaskComment/EditTaskComment'
import { useGetCommentsQuery } from '../../../../../../core/api/TaskApi'
import { ITasksCommentDto } from '../../../../../../core/api/dto/TaskDto'
import { throttle } from '../../../../../../utils/throttle'
import { AppScrollWrapperNew } from '../../../../../../widgets/AppScrollWrapper/AppScrollWrapper'

interface IEditTaskCommentListProps {
  id?: number
  isDraft?: boolean
  isFullHeight?: boolean
  newComment: ITasksCommentDto | null
  setNewComment: React.Dispatch<React.SetStateAction<ITasksCommentDto | null>>
}

export const EditTaskCommentList: React.FC<IEditTaskCommentListProps> = (
  props,
) => {
  const commentListRef = useRef<HTMLDivElement>(null)
  const { id, isFullHeight = false, setNewComment, newComment, isDraft } = props
  const [offset, setOffset] = useState(0)
  const [loadMore, setLoadMore] = useState(false)

  const [commentList, setCommentList] = useState<ITasksCommentDto[]>([])
  const { data: commentListData } = useGetCommentsQuery(
    {
      task: id || 0,
      ordering: '-created_at',
      limit: 10,
      offset,
    },
    { skip: !id, refetchOnMountOrArgChange: true },
  )

  useLayoutEffect(() => {
    if (commentListRef.current) {
      commentListRef.current.scrollTo({
        top: commentListRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [])

  useEffect(() => {
    if (!!commentListData && !commentListData?.previous) {
      return setCommentList(commentListData.results)
    }
    if (!!commentListData && loadMore && !!commentListData?.previous) {
      setCommentList((prevState) => [...prevState, ...commentListData.results])
      setLoadMore(false)
    }
  }, [commentListData])

  useEffect(() => {
    if (loadMore) {
      setOffset((prevState) => prevState + 10)
    }
  }, [loadMore])

  useEffect(() => {
    if (newComment) {
      setCommentList((prevState) => [newComment, ...prevState])
      setNewComment(null)
    }
  }, [newComment])

  const setLoadMoreHandler = () => setLoadMore(true)
  const throttleSetLoadMore = throttle(setLoadMoreHandler, 300)

  const onScrollHandler = (e: UIEvent<HTMLDivElement>) => {
    e.preventDefault()
    const doc = e.currentTarget
    if (
      doc.scrollHeight - (-doc.scrollTop + doc.clientHeight) < 70 &&
      !!commentListData?.next &&
      !loadMore
    ) {
      throttleSetLoadMore()
    }
  }

  return (
    <>
      {commentList.length ? (
        <div
          className={'edit-task-comments'}
          style={isFullHeight ? { height: '100%' } : {}}
        >
          <AppScrollWrapperNew
            theme={'dropdown'}
            onScroll={onScrollHandler}
            scrollUp
          >
            {commentList.map((comment) => (
              <EditTaskComment
                key={`comment-${comment.id}`}
                comment={comment}
                taskId={id}
              />
            ))}
          </AppScrollWrapperNew>
        </div>
      ) : (
        <div
          className={'edit-task-comments-empty'}
          style={isFullHeight ? { height: '100%' } : {}}
        >
          <div className={'edit-task-comments-empty-content'}>
            <i className="an-ico an-ico-annotation-x color-Gray-medium" />
            <span>
              {isDraft
                ? 'Комментарии будут доступны после сохранения задачи'
                : 'Комментарии отсутствуют'}
            </span>
          </div>
        </div>
      )}
    </>
  )
}
