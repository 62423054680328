import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderBuffetLinePage } from './components/HeaderBuffetLinePage'
import { AllRoutes } from '../../core/routes/AllRoutes'
import { AppScrollWrapperNew } from '../../widgets/AppScrollWrapper/AppScrollWrapper'

export const BuffetLinePage = () => {
  const pathname = useLocation().pathname

  return (
    <React.Fragment>
      {pathname.includes(`${AllRoutes.logout.path}`) ? (
        <Outlet />
      ) : (
        <React.Fragment>
          <HeaderBuffetLinePage />
          <div className="main-content buffet-line-wrap">
            <div className="scroll-area">
              <div className="container-fluid container-scroll">
                <AppScrollWrapperNew theme={'main-scroll'}>
                  <Outlet />
                </AppScrollWrapperNew>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
