import React, { useEffect, useState } from 'react'
import Popup, { PopupSize } from '../../../../components/popup/Popup'
import { AppInput } from '../../../../components/AppInput/AppInput'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
} from '../../../../components/btn/AppBtn'
import {
  AppNotification,
  NotificationType,
} from '../../../../components/notification/Notification'
import {
  useCreateGuestTypeMutation,
  useGetGuestTypeDetailQuery,
  useUpdateGuestTypeMutation,
} from '../../../../core/api/GuestTypeApi'
import { useTranslation } from 'react-i18next'

interface IAddGuestTypePopupProps {
  id?: number | null
  closePopup: () => void
}

export const AddGuestTypePopup = ({
  closePopup,
  id,
}: IAddGuestTypePopupProps) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [error, setError] = useState('')
  const { data: currentGuestType } = useGetGuestTypeDetailQuery(
    { id: id ? id : 0 },
    {
      skip: !id,
    },
  )
  const [createGuestType] = useCreateGuestTypeMutation()
  const [updateGuestType] = useUpdateGuestTypeMutation()

  useEffect(() => {
    if (!!currentGuestType) {
      setTitle(currentGuestType.name)
    }
  }, [currentGuestType])

  const onSaveHandler = () => {
    if (!title) {
      setError('Обязательное поле')
      return
    }
    if (!!id) {
      updateGuestType({ id, data: { name: title } })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Тип гостя успешно обновлен!'),
            type: NotificationType.success,
          })
          closePopup()
        })
    } else {
      createGuestType({
        name: title,
      })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: t('Тип гостя успешно создан!'),
            type: NotificationType.success,
          })
          closePopup()
        })
    }
  }

  return (
    <Popup size={PopupSize.base} borderRadiusMin closePopup={closePopup}>
      <div className="category-popup">
        <h3>{t(`${!!id ? 'Редактирование' : 'Создание'} типа гостя`)}</h3>
        <AppInput
          value={title}
          onChange={(value) => {
            setError('')
            setTitle(value)
          }}
          label="Название"
          border
          fullWidth
          error={error ? error : undefined}
        />
        <div className="buttons-block">
          <AppBtn
            title={'Отменить'}
            color={AppBtnColor.outlineViolet}
            iconClassName={'close'}
            sized={AppBtnSize.md}
            onClick={closePopup}
          />
          <AppBtn
            iconClassName={'save'}
            title={'Сохранить'}
            sized={AppBtnSize.md}
            color={AppBtnColor.violetWhite}
            onClick={onSaveHandler}
          />
        </div>
      </div>
    </Popup>
  )
}
