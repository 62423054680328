import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cookInitialState, IInfoDish } from './cookState'

const cookSlice = createSlice({
  name: 'cook',
  initialState: cookInitialState,
  reducers: {
    setDish: (state, { payload }: PayloadAction<IInfoDish>) => {
      state.dishes = [payload, ...state.dishes]
    },
    deleteDish: (state, { payload }: PayloadAction<IInfoDish>) => {
      state.dishes = state.dishes.filter(
        (el) => JSON.stringify(el) !== JSON.stringify(payload),
      )
    },
    deleteOrder: (state, { payload }: PayloadAction<number>) => {
      state.dishes = state.dishes.filter((el) => el.parentId !== payload)
    },
  },
})

export const { setDish, deleteDish, deleteOrder } = cookSlice.actions
export const { reducer: CookReducer } = cookSlice
