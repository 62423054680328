import React from 'react'
import './EditTaskComment.scss'
import { ITasksCommentDto } from '../../../../../../core/api/dto/TaskDto'
import { RoleTranslate } from '../../../../../../core/api/dto/UserDto'
import moment from 'moment'
import { textWithLink } from '../../../../../../utils/textWithLink'
import { Link } from 'react-router-dom'
import { taskObjectTableShower } from '../../TasksObjectTable/taskObjectTableShower'

interface IEditTaskCommentProps {
  taskId?: number
  comment: ITasksCommentDto
}

export const EditTaskComment: React.FC<IEditTaskCommentProps> = (props) => {
  const { comment, taskId } = props

  return (
    <div className={'edit-task-comment-wrapper'}>
      <div className={'edit-task-comment-header'}>
        <div>
          {comment?.sender?.role.roleType
            .map((item) => RoleTranslate[item])
            .join(', ')}
        </div>
        <div className={'edit-task-comment-header-divider'} />
        <div>{comment?.sender?.name}</div>
        <div className={'edit-task-comment-header-divider'} />
        <div>{moment(comment?.createdAt).format('DD.MM.YYYY')}</div>
        <div className={'edit-task-comment-header-divider'} />
        <div>{moment(comment?.createdAt).format('HH:mm')}</div>
      </div>
      <div className={'edit-task-comment-content'}>
        <span
          className="text"
          dangerouslySetInnerHTML={textWithLink(comment.text)}
        />
        {!!comment?.mentionedUsers.length && (
          <div className="users">
            <span>
              {comment?.mentionedUsers.map(({ name }) => `@${name}`).join(', ')}
            </span>
          </div>
        )}
        {!!comment?.taskObjects.length && (
          <div className="users task">
            {comment.taskObjects.map((object) => (
              <Link
                to={`${taskObjectTableShower({
                  fieldName: 'link',
                  item: object,
                })}&go-back-taskId=${taskId}`}
                key={object.id}
              >
                {object.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
