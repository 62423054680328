import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

enum AppInputFileView {
  link = 'link',
}

export enum AppInputFileColor {
  violet = '',
  gray = 'gray',
}

interface IAppInputFileProps {
  color?: AppInputFileColor
  text?: string
  view?: AppInputFileView
  onChangeFile: (file: File | null) => void
}

export const AppInputFile = ({
  color = AppInputFileColor.violet,
  view = AppInputFileView.link,
  text,
  onChangeFile,
}: IAppInputFileProps) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const file = e.target.files && e.target.files[0]
    setInputValue('')
    onChangeFile(file)
  }
  return (
    <label className={`file-input-label ${color}`}>
      {t(text || 'выберете файл')}
      <input
        value={inputValue}
        type="file"
        className="file-input"
        onChange={changeHandler}
      />
    </label>
  )
}
