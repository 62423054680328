import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'
import { useGetOrderByIdQuery } from '../../../core/api/OrderApi'
import ActiveOrderItem from './ActiveOrderItem'

const ActiveOrderColumn = () => {
  const { orderId, orderLineId } = useParams()
  const { data: order, isFetching } = useGetOrderByIdQuery(orderId!)

  const navigate = useNavigate()
  useEffect(() => {
    if (order?.orderLines.length && !isFetching && !orderLineId) {
      navigate(`${order.orderLines[0].id}`)
    }
  }, [order, orderLineId, isFetching, navigate])

  return (
    <>
      <div className="col-xl-4 col-lg-6 col-scroll">
        {order ? (
          <div className="column main-view">
            {order.orderLines
              .filter((el) => el.quantity !== 0)
              .map((el) => (
                <ActiveOrderItem info={el} key={`active-order-item-${el.id}`} />
              ))}
          </div>
        ) : null}
      </div>
      <Outlet />
    </>
  )
}

export default ActiveOrderColumn
