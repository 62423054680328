import React, { useCallback, useEffect } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppToggle } from '../../../components/AppToggle'
import { useAppDispatch, useAppSelector } from '../../../core/store/store'
import {
  setButtonScrollMode,
  setKeyboardMode,
} from '../../../core/store/options/optionsSlice'
import { TextWithLabel } from '../../../components/TextWithLabel'
import { useGetVersionsQuery } from '../../../core/api/VersionsApi'
import version from '../../../version.json'
import { useTranslation } from 'react-i18next'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import {
  useCheckLatestModelQuery,
  useCheckTrainingProcessQuery,
  useEnableLatestModelMutation,
  useLazySetZeroWeightQuery,
  useTrainModelMutation,
} from '../../../core/api/OrderApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'

export const OptionsPage = () => {
  const { t } = useTranslation()
  const { buttonScrollMode, keyboardMode } = useAppSelector(
    (state) => state.options,
  )
  const { data: versions } = useGetVersionsQuery(null)
  const [setQuery] = useLazySetZeroWeightQuery()
  const dispatch = useAppDispatch()

  // const [trainModel] = useTrainModelMutation()
  // const [enableLatestModel, { isLoading }] = useEnableLatestModelMutation()
  // const isTrain = process.env.REACT_APP_TYPE_SERVER === 'MainServer'
  // const isPredict = process.env.REACT_APP_TYPE_SERVER === 'Fotobox'
  // const { data, isSuccess, isFetching, isError: isErrorLatestModel } = useCheckLatestModelQuery(undefined, {
  //   skip: isTrain || isLoading,
  //   pollingInterval: 10000,
  // })
  // const { isSuccess: isSuccessTrainings, isError} = useCheckTrainingProcessQuery(undefined, {
  //   skip: isPredict,
  //   pollingInterval: 10000,
  // })

  // useEffect(() => {
  //   if (isSuccess && !isFetching && !isErrorLatestModel) {
  //     AppNotification({
  //       type: NotificationType.success,
  //       msg: 'Доступна новая модель',
  //     })
  //   }
  // }, [data, isErrorLatestModel, isSuccess, isFetching])

  // const handleTrain = () => {
  //   trainModel({
  //     num_epochs: 5,
  //   })
  //     .unwrap()
  //     .then(() => {
  //       AppNotification({
  //         type: NotificationType.success,
  //         msg: 'Обучение успешно запущено',
  //       })
  //     })
  // }

  // const handleEnableLatestModel = () => {
  //   enableLatestModel()
  //     .unwrap()
  //     .then(() => {
  //       AppNotification({
  //         type: NotificationType.success,
  //         msg: 'Модель установлена',
  //       })
  //     })
  // }

  const changeButtonModeScroll = useCallback((value: boolean) => {
    dispatch(setButtonScrollMode({ buttonMode: value }))
  }, [])

  const changeKeyboardMode = useCallback((value: boolean) => {
    dispatch(setKeyboardMode({ keyboardMode: value }))
  }, [])

  const onSetWightZeroClick = () => {
    setQuery()
      .unwrap()
      .then((res) => {
        AppNotification({
          type: NotificationType.success,
          msg: res.status ? 'Калибровка выполнена успешно' : '',
        })
      })
  }

  return (
    <React.Fragment>
      <div className="width-for-page-wrapper">
        <AppTitlePage title="Настройки" />
        <div className="option-page-card mt-20">
          <div className="option-page-item">
            <div className="ico-block">
              <i className="an-ico an-ico-keyboard-2" />
            </div>
            <div className="item">
              <span>{t('Клавиатура Фотобокс')}</span>
              <AppToggle value={keyboardMode} onChange={changeKeyboardMode} />
            </div>
          </div>
          <div className="option-page-item">
            <div className="ico-block">
              <i className="an-ico an-ico-aim" />
            </div>
            <div className="item">
              <span>{t('Кнопки пролистывания')}</span>
              <AppToggle
                value={buttonScrollMode}
                onChange={changeButtonModeScroll}
              />
            </div>
          </div>
          <AppBtn
            onClick={onSetWightZeroClick}
            title="Калибровка весов"
            sized={AppBtnSize.sm}
            color={AppBtnColor.violetWhite}
          />
          {/* {isTrain && (
            <AppBtn
              onClick={handleTrain}
              iconClassName='learn-model'
              title="Обучить модель"
              sized={AppBtnSize.sm}
              color={AppBtnColor.violetWhite}
              disabled={!isSuccessTrainings || isError}
            />
          )}
          {isPredict && (
            <AppBtn
              onClick={handleEnableLatestModel}
              title={isLoading ? 'Загрузка модели' : 'Загрузить модель'}
              iconClassName="import"
              sized={AppBtnSize.sm}
              color={AppBtnColor.violetWhite}
              disabled={!isSuccess || isLoading}
            />
          )} */}
          <div className="option-page-item">
            <TextWithLabel
              label="Версия"
              text={`Frontend: ${
                versions?.frontendVersion || version.version || '---'
              }<br/> Backend: ${
                versions?.backendVersion || '---'
              }<br/> Sensor server: ${versions?.serviceSensorVersion || '---'}`}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
