import React, { ReactNode } from 'react'
import { AppScrollWrapperNew } from '../widgets/AppScrollWrapper/AppScrollWrapper'

interface AppTabsBlockProps<TabEnum extends string> {
  className?: string
  children: ReactNode
  tabs: { name: string; value: TabEnum }[]
  activeTab: TabEnum
  setActiveTab: (value: TabEnum) => void
}

export const AppTabsBlock = <TabEnum extends string>({
  className,
  children,
  tabs,
  setActiveTab,
  activeTab,
}: AppTabsBlockProps<TabEnum>) => {
  return (
    <div className={`app-tabs-wrap${className ? ' ' + className : ''}`}>
      <div className={'tabs-btn-wrapper'}>
        {tabs.map(({ name, value }) => (
          <button
            onClick={() => setActiveTab(value)}
            className={`tabs-btn ${activeTab === value ? ' active' : ''}`}
            key={value}
          >
            {name}
          </button>
        ))}
      </div>
      <div className="tabs-content-wrapper">
        <AppScrollWrapperNew>{children}</AppScrollWrapperNew>
      </div>
    </div>
  )
}
