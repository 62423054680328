import React, { useEffect, useState } from 'react'
import { cutString } from '../../../../utils/cutString'
import {
  AppInput,
  TextareaInputRowsType,
} from '../../../../components/AppInput/AppInput'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
  IconPosition,
} from '../../../../components/btn/AppBtn'
import { useTranslation } from 'react-i18next'

interface ICommentBlockProps {
  commentMain: string
  updateComment: (comment: string) => void
  disabled?: boolean
  icoNone?: boolean
  editMode?: boolean
  getComment?: (value: string) => void
}

export const CommentBlock = ({
  updateComment,
  commentMain,
  disabled,
  editMode: editModeParent,
  getComment,
  icoNone,
}: ICommentBlockProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [currentComment, setCurrentComment] = useState('')
  const [comment, setComment] = useState('')
  const [isExpand, setIsExpand] = useState(false)

  useEffect(() => {
    setComment(commentMain || '')
    setCurrentComment(commentMain || '')
    setEditMode(false)
    setIsExpand(false)
  }, [commentMain, disabled])

  const onEditMode = () => {
    setEditMode((prevState) => !prevState)
    setCurrentComment(comment)
    if (editMode) {
      updateComment(comment)
    }
    if (!isExpand) {
      setIsExpand(true)
    }
  }
  const onExpandHandler = () => {
    setIsExpand((prevState) => !prevState)
  }

  return (
    <div className="comment-block">
      <div className="title-block">
        <span>
          <b>{t('Комментарий')}</b>
        </span>
        {(editMode || editModeParent) && (
          <span className="count">{comment.length}/500</span>
        )}
      </div>
      <div className="content">
        <div className="text">
          <span>
            {cutString({
              text: currentComment || t('Комментарий отсутствует'),
              length: 100,
              cancel: isExpand || editModeParent,
            })}
            {(editMode || editModeParent) && (
              <div className="textarea-block">
                <AppInput
                  value={comment}
                  type="textarea"
                  placeholder={'Добавить комментарий'}
                  rows={TextareaInputRowsType['rows-full']}
                  onChange={(value) => {
                    setComment(value)
                    getComment && getComment(value)
                  }}
                  fullWidth
                  maxLength={500}
                />
              </div>
            )}
          </span>
          <div className="actions-block">
            <AppBtn
              title={
                (isExpand || editModeParent) && comment.length > 99
                  ? 'Свернуть'
                  : 'Развернуть'
              }
              sized={AppBtnSize.fullWidth}
              color={AppBtnColor.outlineViolet}
              iconClassName={
                (isExpand || editModeParent) && comment.length > 99
                  ? 'arrow-up-bold'
                  : 'arrow-down-bold'
              }
              iconPosition={IconPosition.right}
              onClick={onExpandHandler}
              disabled={editMode || !!editModeParent || comment.length < 100}
            />
            {!disabled && !icoNone && (
              <div
                className={`edit-block${disabled ? ' disabled' : ''}`}
                onClick={disabled ? undefined : onEditMode}
              >
                <i
                  className={`an-ico an-ico-${editMode ? 'save' : 'edit-pen'}`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
