import { IWorkshop } from './DishObjDto'
import { IRestaurant } from './RestaurantsObjDto'
import { IBaseRequestDto, IBaseResponseDto } from './BaseDto'

export enum Role {
  ADMIN = 'ADMIN',
  CHEF = 'CHEF',
  WAITER = 'WAITER',
  FOTOBOX = 'FOTOBOX',
  COOK = 'COOK',
  WAITER_WEB = 'WAITER_WEB',

  BUFF_SUPERVISOR = 'BUFF_SUPERVISOR',
  BUFF_ACCOUNTANT = 'BUFF_ACCOUNTANT',
  BUFF_CHEF = 'BUFF_CHEF',
  BUFF_MANAGER = 'BUFF_MANAGER',
  BUFF_CASHIER = 'BUFF_CASHIER',
}

export enum RoleTranslate {
  ADMIN = 'Админ',
  CHEF = 'Шеф',
  WAITER = 'Официант',
  FOTOBOX = 'FOTOBOX',
  COOK = 'Повар',
  WAITER_WEB = 'WAITER_WEB',

  BUFF_SUPERVISOR = 'Руководитель',
  BUFF_ACCOUNTANT = 'Бухгалтер',
  BUFF_CHEF = 'Шеф-повар',
  BUFF_MANAGER = 'Менеджер зала',
  BUFF_CASHIER = 'Кассир (оператор)',
}

export interface IRole {
  roleType: (keyof typeof Role)[]
  id: number
  expireDate: string
  user: number
  restaurant: IRestaurant
  workshops: IWorkshop[]
}

export interface IUserResponseDto {
  id: number
  name: string
  email: string
  phone: number
  userRoles: IRole
  isActive: boolean
}

export interface IUsersResponseDto extends IBaseResponseDto {
  results: IUserResponseDto[]
}

export interface IUsersRequestDto extends IBaseRequestDto {
  search?: string
  is_active?: string
}
