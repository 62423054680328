import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { FormatDate, initDate } from '../components/calendar/Calendar'
import HeaderAdminPage from '../widgets/header/HeaderAdminPage'
import { AllRoutes } from '../core/routes/AllRoutes'

const AdminPage = () => {
  const navigate = useNavigate()
  const { dateOrders, dishId } = useParams()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!dateOrders) {
      navigate(initDate(FormatDate.BASE_DATE))
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <HeaderAdminPage />
      <main
        className={`main-content ${
          pathname.includes(AllRoutes.addNewDish.path) || dishId
            ? 'create-update-dish'
            : ''
        }`}
      >
        <div className="scroll-area">
          <div className="container-fluid container-scroll">
            <Outlet />
          </div>
        </div>
      </main>
    </>
  )
}

export default AdminPage
