import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Checkbox from '../../checkbox/Checkbox'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../btn/AppBtn'
import { IActionsData } from '../AppTable'
import { IChooseItems } from '../../mainContent/TableAllDishes'

interface TableActionsBlockProps<T> {
  chooseMode?: boolean
  chooseActions?: IActionsData[]
  tableData: T[]
  chooseItems?: IChooseItems | null
  setChooseItems?: React.Dispatch<React.SetStateAction<IChooseItems | null>>
}

export const TableActionsBlock = <
  T extends { id: number; archived?: boolean },
>({
  chooseMode,
  chooseActions,
  tableData,
  setChooseItems,
  chooseItems,
}: TableActionsBlockProps<T>) => {
  const [chooseValue, setChooseValue] = useState<boolean>(false)

  const chooseAllHandler = useCallback(() => {
    const newAllId = tableData
      .map(({ id }) => ({ [id as number]: !chooseValue }))
      .reduce((acc, el) => {
        return { ...acc, ...el }
      }, {})
    setChooseItems && setChooseItems(newAllId)
    setChooseValue(!chooseValue)
  }, [tableData, chooseValue])

  const chooseItemEmpty = useMemo(
    () =>
      !!chooseItems ? !Object.values(chooseItems).find((item) => !!item) : true,
    [chooseItems],
  )

  useEffect(() => {
    if (!chooseItems) {
      setChooseValue(false)
    }
  }, [chooseItems])

  return (
    <React.Fragment>
      {(!!chooseActions?.length || chooseMode) && (
        <div className="table-actions-wrap">
          {chooseMode && (
            <Checkbox
              name={'Выбрать всё'}
              text={'Выбрать всё'}
              value={chooseValue}
              onChange={chooseAllHandler}
            />
          )}
          {chooseActions?.map((item, index) => (
            <AppBtn
              sized={AppBtnSize.sm}
              color={AppBtnColor.whiteGray}
              title={item.title}
              key={`table-btn-${index}`}
              iconClassName={item.ico}
              onClick={item.onClick}
              disabled={item.disabled || chooseItemEmpty}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  )
}
