import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { showStyleDimensionType } from '../../BuffetReportsVZLine'
import { IBuffetGeneralReportDate, IBuffetGeneralReportResult } from 'core/api/dto/BuffetGeneralReportDto'

interface SubTable {
  item: IBuffetGeneralReportResult
  currentCol?: number
  itemIndex?: number
  onTouchStart?: ((e: React.TouchEvent) => void) | undefined
  onTouchMove?: ((e: React.TouchEvent) => void) | undefined
  width?: number
  toggleAllState?: number[]
  toggleAllStateFullLenght?: number
}

type ToggleAllStateSub = {
  index: number
  active: boolean
}

export const SubTableRepresentVolume = ({
  item,
  currentCol,
  itemIndex,
  onTouchStart,
  onTouchMove,
  width,
  toggleAllState,
  toggleAllStateFullLenght,
}: SubTable) => {
  const [toggleAllStateSub, setToggleAllStateSub] = useState<
    ToggleAllStateSub[]
  >([])

  useEffect(() => {
    if (
      toggleAllState &&
      toggleAllState.find((el) => el === itemIndex) &&
      toggleAllStateFullLenght === toggleAllState.length
    ) {
      setToggleAllStateSub(
        item.dates.map((e, i) => ({ index: i, active: true })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleAllState])

  const onToggle = (index: number) => {
    setToggleAllStateSub((prev) => {
      const element = prev.find((el) => el.index === index)
      if (element) {
        return prev.map((el) =>
          el.index === index ? { ...el, active: !el.active } : el,
        )
      } else {
        return [...prev, { index: index, active: true }]
      }
    })
  }

  const subRow = (
    item: IBuffetGeneralReportDate,
    currentCol?: number,
    onTouchStart?: ((e: React.TouchEvent) => void) | undefined,
    onTouchMove?: ((e: React.TouchEvent) => void) | undefined,
    width?: number,
  ) => {
    return (
      <React.Fragment>
        {item.dimensions.map((subItem, index) => {
          const moveFn = {
            onTouchStart: onTouchStart,
            onTouchMove: onTouchMove,
          }
          const data = [
            null,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>
                {moment(subItem.createdAt).format('HH:mm')}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div
                className={`text-end no-wrap ${
                  showStyleDimensionType[subItem.dimensionType]
                }`}
              >
                {subItem.dimensionType}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>{subItem.sequenceNumber}</div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>{subItem.plannedPersonsAmount}</div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>{subItem.actualPersonsAmount}</div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>
                {subItem.plannedWeight.toFixed(3)}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`}>
                {subItem.actualWeight.toFixed(3)}
              </div>
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
            <td className={'buffet-report-page-table-cell'} {...moveFn}>
              <div className={`text-end`} />
            </td>,
          ]
          return (
            <tr
              key={`${index}-sub-${subItem.station}`}
              style={{ top: `${56 + 40 + index * 40}px` }}
            >
              <td className={'buffet-report-page-table-cell'}>
                <div className="table-sub-data-ico-block">
                  <div className="sub-data">
                    <div style={{ width: 20 }}></div>
                    <i className="an-ico an-ico-corner-down-right" />
                    <span>{subItem.station}</span>
                  </div>
                </div>
              </td>
              {currentCol
                ? width && width < 600
                  ? data[currentCol]
                  : data
                      .slice(currentCol, currentCol + 3)
                      .map((item, index) => (
                        <React.Fragment key={`data-render-${index}`}>
                          {item}
                        </React.Fragment>
                      ))
                : data.map((item, index) => (
                    <React.Fragment key={`data-render-${index}`}>
                      {item}
                    </React.Fragment>
                  ))}
            </tr>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {item.dates.map((subItem, index) => {
        const moveFn = {
          onTouchStart: onTouchStart,
          onTouchMove: onTouchMove,
        }
        const data = [
          null,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`} />
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`}>
              {moment(subItem.createdDay).format('DD.MM.YYYY')}
            </div>
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`} />
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`} />
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`} />
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`}>{subItem.plannedPersonsAmount}</div>
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`}>{subItem.actualPersonsAmount}</div>
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`}>{subItem.personsAmountDiff}</div>
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`}>{subItem.plannedWeight.toFixed(3)}</div>
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`}>{subItem.actualWeight.toFixed(3)}</div>
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`}>{subItem.weightDiff?.toFixed(3)}</div>
          </td>,
          <td className={'buffet-report-page-table-cell'} {...moveFn}>
            <div className={`text-end`}>
              {subItem.weightPerPerson?.toFixed(3)}
            </div>
          </td>,
        ]
        return (
          <React.Fragment key={`${index}-sub-${item.name}`}>
            <tr
              key={`${index}-sub-${item.name}`}
              className={'buffet-report-page-table-category'}
            >
              <td className={'buffet-report-page-table-cell'}>
                <div className="table-sub-data-ico-block">
                  <div className="sub-data">
                    <i className="an-ico an-ico-corner-down-right" />
                    <div
                      className={`table-toggle-block${
                        !!(
                          index ===
                            toggleAllStateSub.find((el) => el.index === index)
                              ?.index &&
                          toggleAllStateSub.find((el) => el.index === index)
                            ?.active
                        )
                          ? ' active'
                          : ''
                      }`}
                      onClick={() => onToggle(index)}
                    >
                      <i className="an-ico an-ico-arrow-down" />
                    </div>
                  </div>
                </div>
              </td>
              {currentCol
                ? width && width < 600
                  ? data[currentCol]
                  : data
                      .slice(currentCol, currentCol + 3)
                      .map((item, index) => (
                        <React.Fragment key={`data-render-${index}`}>
                          {item}
                        </React.Fragment>
                      ))
                : data.map((item, index) => (
                    <React.Fragment key={`data-render-${index}`}>
                      {item}
                    </React.Fragment>
                  ))}
            </tr>
            {!!(
              index ===
                toggleAllStateSub.find((el) => el.index === index)?.index &&
              toggleAllStateSub.find((el) => el.index === index)?.active
            ) && subRow(subItem, currentCol, onTouchStart, onTouchMove, width)}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}
