import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { IExtraOptions } from './AppDropdownWithPagination'
import { IWithPaginationRequestSearch } from '../../core/api/dto/BaseDto'
import { resetPaginationByParamsChanged } from '../../utils/resetPaginationByParamsChanged'
import { AppMultiDropdownWithPagination } from './AppMultiDropdownWithPagination'

export interface IAppFilterDropdown<T, TKey> {
  searchParam: string
  propToShowInList: TKey
  sparePropToShowInList?: TKey
  extraPropToShowInList?: TKey
  valuePropName: TKey
  placeholder?: string
  label?: string
  id?: string
  defaultValue?: boolean
  shouldResetPagination?: boolean
  disabled?: boolean
  fullWidth?: boolean
  minWidth?: boolean
  emptyListMessage?: string
  getterData: (
    params: IWithPaginationRequestSearch | null | any | { multi: string },
    extraParams?: { skip?: boolean },
  ) => any
  extraOptions?: IExtraOptions
  setCurrentValue?: (value: T[]) => void
}

export const AppFilterMultiDropdownWithPagination = <T, TKey extends keyof T>({
  searchParam,
  getterData,
  propToShowInList,
  extraPropToShowInList,
  sparePropToShowInList,
  valuePropName,
  label,
  placeholder,
  shouldResetPagination = true,
  extraOptions,
  setCurrentValue,
  emptyListMessage,
  disabled,
  fullWidth,
  minWidth,
  id,
  defaultValue,
}: PropsWithChildren<IAppFilterDropdown<T, TKey>>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname, state } = useLocation()
  const [currentFilterValue, setCurrentFilterValue] = useState<T[]>([])
  const [firstOpen, setFirstOpen] = useState<boolean>(true)
  const currentSearchParamValue = searchParams.get(
    searchParam,
  ) as unknown as TKey[]

  const { data } = getterData(
    !!currentSearchParamValue
      ? { ...extraOptions, ids: `${currentSearchParamValue}` }
      : firstOpen && defaultValue && !state
      ? { ...extraOptions }
      : {},
  )

  useEffect(() => {
    if (!!currentSearchParamValue && valuePropName) {
      //есть параметр и не первое открытие находим значчение и подставляем в фильтр
      const currentData =
        data &&
        data.results.filter((item: T) =>
          `${currentSearchParamValue}`
            .split(',')
            .some((i) => i === `${item[valuePropName]}`),
        )
      setCurrentFilterValue(!!currentData ? currentData : [])
      setCurrentValue && setCurrentValue(!!currentData ? currentData : [])
      setFirstOpen(false)
    } else if (
      !!currentSearchParamValue &&
      firstOpen &&
      state &&
      valuePropName
    ) {
      //есть парамер первое открытие и нажата кнопка назад
      const currentData =
        data &&
        data.results.filter((item: T) =>
          `${currentSearchParamValue}`
            .split(',')
            .some((i) => i === `${item[valuePropName]}`),
        )
      setCurrentFilterValue(!!currentData ? currentData : [])
      setCurrentValue && setCurrentValue(!!currentData ? currentData : [])
      setFirstOpen(false)
    } else if (
      !currentSearchParamValue &&
      defaultValue &&
      firstOpen &&
      !state
    ) {
      const defaultSearch =
        data?.results?.length > 0 && data?.results[0][valuePropName]
      const defaultData = data && data?.results?.length > 0 && data?.results[0]
      if (defaultSearch) {
        searchParams.set(searchParam, defaultSearch)
        setSearchParams(searchParams.toString())
        setCurrentFilterValue(!!defaultData ? [defaultData] : [])
        setCurrentValue && setCurrentValue(!!defaultData ? [defaultData] : [])
        setFirstOpen(false)
      } else {
        setCurrentFilterValue([])
        setCurrentValue && setCurrentValue([])
      }
    }
  }, [data, currentSearchParamValue])

  const changeHandler = useCallback(
    (item: T[]) => {
      if (!!item.length) {
        searchParams.set(
          searchParam,
          item.map((item) => item[valuePropName]).join(','),
        )
      } else {
        searchParams.delete(searchParam)
      }
      if (shouldResetPagination) {
        resetPaginationByParamsChanged({
          searchParams,
          setSearchParams,
          pathname,
        })
      }
      setCurrentFilterValue(item)
      setSearchParams(searchParams.toString())
    },
    [searchParams, setSearchParams, searchParam, valuePropName],
  )

  const resetFilterHandler = useCallback(() => {
    searchParams.delete(searchParam)
    setSearchParams(searchParams.toString())
    setCurrentFilterValue([])
    setCurrentValue && setCurrentValue([])
    if (shouldResetPagination) {
      resetPaginationByParamsChanged({
        searchParams,
        setSearchParams,
        pathname,
      })
    }
  }, [searchParams, setSearchParams, searchParam])
  return (
    <AppMultiDropdownWithPagination
      getterData={getterData}
      propToShowInList={propToShowInList}
      propToShowInInput={valuePropName}
      value={currentFilterValue}
      placeholder={placeholder}
      onChange={changeHandler}
      label={label}
      resetValueHandler={resetFilterHandler}
      extraOptions={extraOptions}
      disabled={disabled}
      emptyListMessage={emptyListMessage}
      fullWidth={fullWidth}
      minWidth={minWidth}
      extraPropToShowInList={extraPropToShowInList}
      sparePropToShowInList={sparePropToShowInList}
      id={`-f${id}`}
    />
  )
}
