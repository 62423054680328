import moment from 'moment'
import { useMemo } from 'react'

export const useGetFullDateTime = (
  date: string | null,
  time: string | null,
): string | null => {
  return useMemo(() => {
    const minArr = time?.split(':').map((item) => +item)
    if (!!date && !!time) {
      return moment(
        new Date(date).setHours(minArr![0], minArr![1]),
      ).toISOString()
    } else if (!!date && !time) {
      return date
    } else {
      return null
    }
  }, [date, time])
}
