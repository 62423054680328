import { api } from './BaseApi'

interface ITokenMsg {
  token: string
}

export const fcmTokenApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendFCMToken: builder.mutation<ITokenMsg, ITokenMsg>({
      query: (item) => ({
        url: '/fcm_token/',
        method: 'POST',
        body: item,
      }),

      invalidatesTags: ['TokenMsg'],
    }),
    deleteFCMToken: builder.mutation<void, ITokenMsg>({
      query: (token) => ({
        url: `/fcm_token/current_user_delete/`,
        method: 'DELETE',
        body: token,
      }),
      invalidatesTags: ['TokenMsg'],
    }),
  }),
})

export const { useSendFCMTokenMutation, useDeleteFCMTokenMutation } =
  fcmTokenApi
