import React, { useEffect, useState } from 'react'
import {
  IPlanMenuControlOrderDto,
  IPlanMenuControlOrdersRequest,
} from '../../../core/api/dto/PlanMenuControlDto'
import { PlanMenuListBlock } from './components/PlanMenuListBlock'
import { useGetPlanMenuByRole } from './hooks/useGetPlanMenuByRole'

export interface IPlanMenuListProps {
  pageParams: IPlanMenuControlOrdersRequest | null
  withTaskAddButton?: boolean
}

export const PlanMenuList = ({
  pageParams,
  withTaskAddButton,
}: IPlanMenuListProps) => {
  const [offset, setOffset] = useState<number>(0)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const ordersListData = useGetPlanMenuByRole(pageParams, offset)

  const [ordersList, setOrdersList] = useState<IPlanMenuControlOrderDto[]>([])

  useEffect(() => {
    if (offset === 0 && !!ordersListData) {
      setOrdersList(ordersListData.results)
    }
    if (offset !== 0 && !!ordersListData && loadMore) {
      setOrdersList((prevState) => [...prevState, ...ordersListData.results])
      setLoadMore(false)
    }
  }, [ordersListData])
  const nextPageHandler = () => {
    setLoadMore(true)
    setOffset((prevState) => prevState + 40)
  }

  return (
    <PlanMenuListBlock
      ordersList={ordersList}
      next={ordersListData?.next}
      nextPageHandler={nextPageHandler}
      withTaskAddButton={withTaskAddButton}
    />
  )
}
