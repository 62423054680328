import React, { memo } from 'react'
import { ICreateDish } from '../../core/api/dto/OrderObjDto'
import { compareProps } from '../../utils'
import AdditionBuffetCard from './AdditionBuffetCard'

interface IPropsAdditionBuffetCards {
  cards: ICreateDish[]
  handlerSetActiveDish: (el: ICreateDish) => void
}
const AdditionBuffetCards = ({
  cards,
  handlerSetActiveDish,
}: IPropsAdditionBuffetCards) => {
  return (
    <>
      {cards.map((el) => (
        <div className="col" key={el.id}>
          <AdditionBuffetCard
            info={el}
            setActiveDish={() => handlerSetActiveDish(el)}
          />
        </div>
      ))}
    </>
  )
}

export const AdditionBuffetCardsMemoized = memo(
  AdditionBuffetCards,
  compareProps,
)
