import React, { useEffect, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../../components/btn/AppBtn'
import { useSearchParams } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router'
import {
  useGetAllPlanMenuControlOrdersLineQuery,
  useGetOpenBuffetOrdersQuery,
} from '../../../core/api/PlanMenuControlApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import {
  IOrderLinesOpenDto,
  IPlanMenuControlOrdersRequest,
} from '../../../core/api/dto/PlanMenuControlDto'
import { getTimeFromMins } from '../DishesPage/CreateDishPage'
import { IBaseSimpleList } from '../../../core/api/dto/BaseDto'
import { useGetBuffetDishSimpleListQuery } from '../../../core/api/BuffetDishApi'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { AppFilterDropdown } from '../../../components/AppDropdown/AppFilterDropdown'
import { AppFilterTimeInput } from '../../../components/AppInput/AppFilterTimeInput'
import { ICategory } from '../../../core/api/dto/CategoriesObjDto'
import { useGetCategoryQuery } from '../../../core/api/CategoryApi'
import { implementedStatusData, useFullTimeHok } from './PlanMenuControlAdmin'
import { IBuffetOrderDto } from '../../../core/api/dto/BuffetOrderDto'
import moment from 'moment'
import { AppScrollWrapperNew } from '../../../widgets/AppScrollWrapper/AppScrollWrapper'
import { useTranslation } from 'react-i18next'

export const PlanMenuControlManager = () => {
  const { t } = useTranslation()
  const pageParams = useParamsControl<
    IPlanMenuControlOrdersRequest,
    keyof IPlanMenuControlOrdersRequest
  >({
    withPagination: true,
    whiteList: [
      'search',
      'tag_ids',
      'implemented_status',
      'delivery_status',
      'dimension_type',
      'dish_ids',
      'category_ids',
      'comment',
      'start_time',
      'end_time',
      'order_ids',
    ],
  })
  const timeStart = useFullTimeHok(pageParams?.start_time)
  const timeEnd = useFullTimeHok(pageParams?.end_time)
  const [searchParams, setSearchParams] = useSearchParams()
  const [orderOpenList, setOrderOpenList] = useState<IOrderLinesOpenDto[]>([])
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const { data: orderOpenListData } = useGetAllPlanMenuControlOrdersLineQuery(
    {
      ...pageParams,
      dimension_datetime:
        timeStart || timeEnd
          ? `${timeStart || ''},${timeEnd || ''}`
          : undefined,
    },
    { skip: !pageParams },
  )

  useEffect(() => {
    if (!!searchParams.get('offset')) {
      searchParams.set('offset', '0')
      setSearchParams(searchParams.toString())
    }
    if (!searchParams.get('limit')) {
      searchParams.set('limit', '100')
      searchParams.set('offset', '0')
      setSearchParams(searchParams.toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pageParams?.offset?.toString() === `${0}` && !!orderOpenListData) {
      setOrderOpenList(orderOpenListData.results)
    }
    if (
      pageParams?.offset?.toString() !== `${0}` &&
      !!orderOpenListData &&
      loadMore
    ) {
      setOrderOpenList((prevState) => [
        ...prevState,
        ...orderOpenListData.results,
      ])
      setLoadMore(false)
    }
  }, [orderOpenListData])

  const nextPageHandler = () => {
    setLoadMore(true)
    searchParams.set('offset', `${Number(pageParams?.offset || 0) + 100}`)
    setSearchParams(searchParams.toString())
  }

  const clearFilterHandler = () => {
    setSearchParams(`limit=100&offset=0`)
  }

  const [isFiltersOpen, setIsFiltersOpen] = useState(true)

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Сбросить фильтр',
        onClick: clearFilterHandler,
        color: AppBtnColor.extraVioletViolet,
      },
    ],
    [],
  )

  return (
    <div className="width-for-page-wrapper">
      <AppTitlePage
        title={'Контроль зала'}
        actions={actions}
        isFiltersOpen={isFiltersOpen}
        setIsFiltersOpen={setIsFiltersOpen}
      />
      {isFiltersOpen && (
        <div className="filter-block mt-20">
          <AppFilterMultiDropdownWithPagination<
            IBuffetOrderDto,
            keyof IBuffetOrderDto
          >
            searchParam={'order_ids'}
            getterData={useGetOpenBuffetOrdersQuery}
            propToShowInList={'name'}
            sparePropToShowInList={'sourceRepresent'}
            valuePropName={'id'}
            label={'Название план-меню'}
            placeholder={'Выбрать'}
            extraPropToShowInList={'orderDateEnd'}
          />
          <AppFilterMultiDropdownWithPagination<
            IBaseSimpleList,
            keyof IBaseSimpleList
          >
            valuePropName={'id'}
            label={'Названия блюд'}
            searchParam={'dish_ids'}
            getterData={useGetBuffetDishSimpleListQuery}
            propToShowInList={'name'}
            placeholder={'Выбрать'}
          />
          <div>
            <label>{t('Время замера')}</label>
            <div className="input-row mt-10">
              <AppFilterTimeInput searchParam={'start_time'} />
              <AppFilterTimeInput searchParam={'end_time'} />
            </div>
          </div>
          <AppFilterMultiDropdownWithPagination<ICategory, keyof ICategory>
            searchParam={'category_ids'}
            getterData={useGetCategoryQuery}
            propToShowInList={'name'}
            valuePropName={'id'}
            label={'Категория'}
            placeholder={'Выбрать'}
          />
          <AppFilterDropdown
            withTranslate
            searchParam={'implemented_status'}
            data={implementedStatusData}
            valuePropName={'value'}
            propToShowInList={'name'}
            label={'Статус реализации'}
            placeholder={'Выбрать'}
            minHeight
          />
        </div>
      )}
      <div className="plan-menu-control-manager-page mt-15" style={{maxHeight: isFiltersOpen ? 'calc(100vh - 290px)' :'calc(100vh - 200px)'}}>
        <AppScrollWrapperNew>
          {orderOpenList.map((dishItem, index) => (
            <DishItemManager
              order={dishItem}
              key={`${dishItem.id}d-i-m-${index}`}
            />
          ))}
          {!!(orderOpenList.length && orderOpenListData?.next) && (
            <AppBtn
              sized={AppBtnSize.fullWidth}
              color={AppBtnColor.violetWhite}
              title="Показать ещё"
              onClick={nextPageHandler}
            />
          )}
        </AppScrollWrapperNew>
      </div>
    </div>
  )
}

interface IDishItemManagerProps {
  order: IOrderLinesOpenDto
}

export const DishItemManager = ({ order }: IDishItemManagerProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onDetailPage = () => {
    navigate(
      `${order.id}${!!order?.dish?.id ? '/?dishId=' + order?.dish?.id : ''}`,
    )
  }
  return (
    <div
      className={`dish-item${!order?.deliveredStatus ? ' disabled' : ''}`}
      onClick={onDetailPage}
    >
      <div className="dish-item-header">
        <div className="given-away">
          <span>{t('ВЫДАНО')}: </span>
          <span>
            <b>
              {!!order.lastDimensionTime
                ? moment(order.lastDimensionTime).format('HH:mm')
                : ' ---'}
            </b>
          </span>
        </div>
        {!order?.planned && (
          <div className="status error">{t('незапланированное')}</div>
        )}
        {!order?.deliveredStatus && (
          <div className="status wait">{t('не выданное')}</div>
        )}
      </div>
      <div className="title-wrap">
        <div className="title-block">
          <div className="category">{order?.dish?.category?.name}</div>
          <div className="title clip-base">{order?.dish?.name}</div>
        </div>
        <div className="photo-block">
          {order?.photoUrl ? (
            <img src={order?.photoUrl || ''} alt={''} />
          ) : (
            <div className="fake">
              <i className="an-ico an-ico-photo-add" />
            </div>
          )}
        </div>
      </div>
      <div
        className={`realization-time ${
          !!order.implementationTimeExceeded ? ' error' : ''
        }`}
      >
        <span>{t('Время реализации')}</span>
        <span className="time">
          {!!order.implementationTimeExceeded
            ? getTimeFromMins(order.implementationTimeExceeded)
            : getTimeFromMins(order.implementationTime) || '---'}
        </span>
      </div>
    </div>
  )
}
