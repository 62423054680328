import { IBaseResponseDto } from '../core/api/dto/BaseDto'

interface IEmptyResponse extends IBaseResponseDto {
  results: []
}

export const emptyResponse: IEmptyResponse = {
  results: [],
  next: '',
  count: 0,
  previous: '',
}
