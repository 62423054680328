import React, { useEffect, useState } from 'react'
import { AppTableLink } from '../../../../../components/AppTable/components/AppTableLink'
import { IChooseItems } from '../../../../../components/mainContent/TableAllDishes'
import {
  ITaskObjectResponseDto,
  ObjectTypeTranslateEnum,
} from '../../../../../core/api/dto/TaskDto'
import { useDeleteDebounce } from '../../../../../utils/useDeleteDebounce'
import { taskObjectTableShower } from './taskObjectTableShower'
import {
  AppNotification,
  NotificationType,
} from 'components/notification/Notification'
import { useDeleteMultipleObjectsMutation } from 'core/api/TaskApi'
import { AppTableDnd } from '../../../../../components/AppTable/AppTableDnd'
import { IHeaderData } from '../../../../../components/AppTable/AppTable'

const headerDataList: IHeaderData[] = [
  { title: 'Дата', sort: 'date', order: 0, startPosition: 0 },
  { title: 'Время', colWidth: '10%', sort: 'time', order: 1, startPosition: 1 },
  { title: 'Тип объекта', sort: 'objectType', order: 2, startPosition: 2 },
  { title: 'Наименование', sort: 'name', order: 3, startPosition: 3 },
  { title: 'Подробности', colWidth: '10%', order: 4, startPosition: 4 },
]

interface TasksObjectTableProps {
  taskObjects: ITaskObjectResponseDto
  taskId: number
}

export const TasksObjectTable = ({
  taskObjects,
  taskId,
}: TasksObjectTableProps) => {
  const [headerData, setHeaderData] = useState<IHeaderData[]>(headerDataList)
  const [deleteMultipleObjects] = useDeleteMultipleObjectsMutation()
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)

  const deleteObject = () => {
    if (!!chooseItems) {
      deleteMultipleObjects({
        task: taskId,
        task_objects: Object.keys(Object.fromEntries(Object.entries(chooseItems).filter(e => e.includes(true)))).map((item) => Number(item)),
      })
        .unwrap()
        .then(() =>
          AppNotification({
            msg: 'Выбранные объекты успешно удалены!',
            type: NotificationType.success,
          }),
        )
    }
  }

  useEffect(() => {
    const headerDataLS = localStorage.getItem('header-task-objects-table')
    if (!!headerDataLS) {
      const newHeaderData: IHeaderData[] =
        JSON.parse(headerDataLS).length === headerDataList.length
          ? JSON.parse(headerDataLS)
          : headerDataList
      setHeaderData(newHeaderData)
    }
  }, [])

  const [onClickHandler] = useDeleteDebounce({ fn: deleteObject })

  const tableActions = [
    {
      title: 'Удалить',
      ico: 'basket',
      onClick: onClickHandler,
    },
  ]

  return (
    <AppTableDnd
      idName="task-objects"
      wrapperClassName="full-width"
      data={taskObjects}
      headerData={headerData}
      setHeaderData={setHeaderData}
      chooseActions={tableActions}
      chooseMode
      chooseItems={chooseItems}
      setChooseItems={setChooseItems}
      tableDataSelectors={[
        {
          renderItem: (item) => {
            return (
              <>
                {taskObjectTableShower({
                  fieldName: 'date',
                  item,
                })}
              </>
            )
          },
        },
        {
          renderItem: (item) => {
            return (
              <>
                {taskObjectTableShower({
                  fieldName: 'time',
                  item,
                })}
              </>
            )
          },
        },
        {
          renderItem: (item) => {
            return <>{ObjectTypeTranslateEnum[item.objectType]}</>
          },
        },
        {
          renderItem: (item) => {
            return <>{item.title}</>
          },
        },
        {
          renderItem: (item) => {
            return (
              <AppTableLink
                path={`${taskObjectTableShower({
                  fieldName: 'link',
                  item,
                })}&go-back-taskId=${taskId}`}
              >
                <div className="obj-task-link">
                  <i className="an-ico an-ico-clip" />
                  <span>Перейти</span>
                </div>
              </AppTableLink>
            )
          },
        },
      ]}
    />
  )
}
