import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const connectionState = {
  isErrorWeight: false,
  addDimensionCount: 0,
  addTempCount: 0,
  isFetchError: '',
}

const connectionSlice = createSlice({
  name: 'connection',
  initialState: connectionState,
  reducers: {
    setFetchError: (state, { payload }: PayloadAction<string>) => {
      state.isFetchError = payload
    },
    setErrorWeight: (state, { payload }: PayloadAction<boolean>) => {
      state.isErrorWeight = payload
    },
    setDimensionCount: (state, { payload }: PayloadAction<number>) => {
      state.addDimensionCount = payload
    },
    setTempCount: (state, { payload }: PayloadAction<number>) => {
      state.addTempCount = payload
    },
  },
})

export const {
  setFetchError,
  setErrorWeight,
  setTempCount,
  setDimensionCount,
} = connectionSlice.actions
export const { reducer: ConnectionReducer } = connectionSlice
