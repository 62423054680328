import React, { useCallback, useState } from 'react'
import Popup, { PopupSize } from '../../../../components/popup/Popup'
import { AppInputFile } from '../../../../components/AppInput/AppInputFile'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
  IconPosition,
} from '../../../../components/btn/AppBtn'
import { useUploadBuffetDishMutation } from '../../../../core/api/BuffetDishApi'
import {
  AppNotification,
  NotificationType,
} from '../../../../components/notification/Notification'
import { IBuffetDishUploadDto } from '../../../../core/api/dto/BuffetDishDto'
import { ConflictsDishesBlock } from './ConflictsDishesBlock'
import { GetFile } from '../../../../utils/getFile'
import { BASE_URL } from '../../../../core/api/BaseApi'

interface IFileUploadDishPopupProps {
  setActive: (value: boolean) => void
}

export const FileUploadDishPopup = ({
  setActive,
}: IFileUploadDishPopupProps) => {
  const [loading, setLoading] = useState(false)
  const [drag, setDrag] = useState(false)
  const [file, setFile] = useState<null | File>(null)
  const [conflictData, setConflictData] = useState<null | IBuffetDishUploadDto>(
    null,
  )
  const [uploadBuffetDish, { isLoading: isLoadingUpload }] =
    useUploadBuffetDishMutation()

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDrag(true)
  }
  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDrag(false)
  }
  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files
    setFile(file[0])
    setDrag(false)
  }

  const uploadFileHandler = () => {
    if (file) {
      const formData = new FormData()
      formData.append('dish_file', file)
      uploadBuffetDish({ data: formData })
        .unwrap()
        .then((res) => {
          if (!!res.conflictDishesCount) {
            setConflictData({
              ...res,
              uploadedDishes: [...res.uploadedDishes].sort((item, item2) =>
                item.sourceIdent > item2.sourceIdent ? 1 : -1,
              ),
              existingDishes: [...res.existingDishes].sort((item, item2) =>
                item.sourceIdent > item2.sourceIdent ? 1 : -1,
              ),
            })
            return // setData
          }
          AppNotification({
            msg: 'Блюда успешно загружены!',
            type: NotificationType.success,
          })
          outSideClickHandler()
        })
    }
  }

  const removeFileHandler = useCallback(() => setFile(null), [])
  const outSideClickHandler = useCallback(() => {
    setActive(false)
  }, [])

  return (
    <Popup
      size={PopupSize.base}
      borderRadiusMin
      closePopup={outSideClickHandler}
      isLoading={isLoadingUpload || loading}
    >
      {!!conflictData ? (
        <ConflictsDishesBlock
          setActive={setActive}
          conflictData={conflictData}
          setLoading={setLoading}
        />
      ) : (
        <div className="file-upload-popup">
          <h3 className="file-upload-title">Загрузка файла</h3>
          <a
            href="#"
            className="example-link"
            onClick={() =>
              GetFile(`${BASE_URL}/buffet_dish/generate_pattern_file/`)
            }
          >
            <i className="an-ico an-ico-import" />
            <span>Загрузить шаблон .xlsx</span>
          </a>
          {file ? (
            <div className="file-item-popup">
              <span>{file.name}</span>
              <div className="delete-file-block" onClick={removeFileHandler}>
                <i className="an-ico an-ico-basket" />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {drag ? (
                <div
                  className="drop-down-block is-draggable"
                  onDragStart={(e) => dragStartHandler(e)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDragOver={(e) => dragStartHandler(e)}
                  onDrop={(e) => onDropHandler(e)}
                >
                  <span>Отпустите файл, чтобы загрузить его</span>
                </div>
              ) : (
                <div
                  className="drop-down-block"
                  onDragStart={(e) => dragStartHandler(e)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDragOver={(e) => dragStartHandler(e)}
                >
                  <i className="an-ico an-ico-clip" />
                  <div className="drop-down-text-block">
                    <span className="drop-down-link-text">
                      Перетащите или <AppInputFile onChangeFile={setFile} /> для
                      загрузки
                    </span>
                    <span className="color-Gray">
                      Максимальный размер файла 30 Mб
                    </span>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          <div className="buttons-block">
            <AppBtn
              title="Отменить"
              color={AppBtnColor.outlineViolet}
              sized={AppBtnSize.md}
              onClick={outSideClickHandler}
              iconClassName="close"
            />
            <AppBtn
              title="Продолжить"
              color={AppBtnColor.violetWhite}
              sized={AppBtnSize.md}
              onClick={uploadFileHandler}
              iconClassName="arrow-long"
              iconPosition={IconPosition.right}
              disabled={isLoadingUpload || loading}
            />
          </div>
        </div>
      )}
    </Popup>
  )
}
