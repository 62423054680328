import { HTMLInputTypeAttribute, KeyboardEvent } from 'react'
import useValidate from '../../utils/useValidate'

export enum InputSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  base = '',
}

export enum IconPosition {
  left = 'left',
  right = 'right',
}

export enum InputShadow {
  base = '',
  gray = 'shadow',
}

export enum InputValidation {
  error = 'error',
  not = '',
}

type TInputField = {
  iconName?: string
  iconPosition?: IconPosition
  sized?: InputSize
  shadow?: InputShadow
  value: string
  placeholder?: string
  type?: HTMLInputTypeAttribute
  disabled?: boolean
  required?: boolean
  onClick?: () => void
  onChange: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onPressEnter?: () => void
}

const InputField = ({
  iconName,
  onClick,
  iconPosition = IconPosition.left,
  sized = InputSize.base,
  shadow = InputShadow.base,
  value,
  placeholder,
  onChange,
  onPressEnter,
  required = false,
  disabled,
  type = 'text',
  onFocus,
  onBlur,
}: TInputField) => {
  const { error, onChangeHandler } = useValidate(value, onChange, required)

  const handlerOnPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onPressEnter!()
    }
  }

  return (
    <div className={`wrapper-field ${shadow} ${sized} ${error}`}>
      {iconName && iconPosition !== 'right' && (
        <i className={`an-ico an-ico-${iconName}`} onClick={onClick} />
      )}
      <input
        value={value}
        onChange={onChangeHandler}
        onKeyDown={handlerOnPressEnter}
        className="field"
        placeholder={error ? 'Поле обязательно к заполнению' : placeholder}
        type={type}
        required={required}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {iconName && iconPosition === 'right' && (
        <i className={`an-ico an-ico-${iconName}`} onClick={onClick} />
      )}
    </div>
  )
}

export default InputField
