import {
  AppTableTaskStatusEnum,
  AppTableTaskStatusTranslate,
} from 'components/AppTable/AppTableStatus/AppTableStatus'

const radioButtons = {
  draft: {
    value: AppTableTaskStatusEnum.DRAFT,
    title: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.DRAFT],
  },
  progress: {
    value: AppTableTaskStatusEnum.PROGRESS,
    title: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.PROGRESS],
  },
  done: {
    value: AppTableTaskStatusEnum.DONE,
    title: AppTableTaskStatusTranslate[AppTableTaskStatusEnum.DONE],
  },
}

export const getAvailableStatuses = (
  isTaskCreator: boolean,
  currentTaskStatus: AppTableTaskStatusEnum,
  isResponsibleCurrentUser?: boolean,
) => {
  if (!isTaskCreator && !isResponsibleCurrentUser) {
    return [
      {
        value: currentTaskStatus,
        title: AppTableTaskStatusTranslate[currentTaskStatus],
      },
    ]
  }

  if (currentTaskStatus === AppTableTaskStatusEnum.DONE) {
    return [radioButtons.done]
  }

  if (!isTaskCreator) {
    if (currentTaskStatus === AppTableTaskStatusEnum.PROGRESS) {
      return [radioButtons.progress, radioButtons.done]
    }

    return [
      {
        value: currentTaskStatus,
        title: AppTableTaskStatusTranslate[currentTaskStatus],
      },
    ]
  }

  if (
    currentTaskStatus === AppTableTaskStatusEnum.DRAFT ||
    currentTaskStatus === AppTableTaskStatusEnum.PROGRESS
  ) {
    return [radioButtons.draft, radioButtons.progress, radioButtons.done]
  }

  return []
}
