import React, { useCallback } from 'react'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../btn/AppBtn'
import { useGetLimitParam } from '../../../utils/useGetLimitParam'
import { useSearchParams } from 'react-router-dom'

interface TablePaginationBlockProps {
  dataNext: boolean
  isLoading: boolean
  dataCount: number
  tableRef: React.MutableRefObject<HTMLDivElement | null>
}

export const TablePaginationBlock = ({
  dataNext,
  dataCount,
  tableRef,
  isLoading,
}: TablePaginationBlockProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const limit = useGetLimitParam()

  const nextPageHandler = useCallback(() => {
    searchParams.set('limit', `${limit + 40}`)
    setSearchParams(searchParams.toString())
  }, [searchParams, limit])

  const allPageHandler = useCallback(() => {
    searchParams.set('limit', `${dataCount}`)
    setSearchParams(searchParams.toString())
  }, [searchParams, limit, dataCount])

  if (!dataNext || !tableRef) return null

  return (
    <div
      className="table-buttons-block"
    >
      <AppBtn
        title="Показать ещё"
        color={AppBtnColor.lightViolet}
        sized={AppBtnSize.fullWidth}
        onClick={nextPageHandler}
        disabled={isLoading}
      />
      <div className="w-last">
        <AppBtn
          title="Показать всё"
          color={AppBtnColor.outlineViolet}
          sized={AppBtnSize.fullWidth}
          onClick={allPageHandler}
          disabled={isLoading}
        />
      </div>
    </div>
  )
}
