import React, { useEffect, useState } from 'react'
import { IHeaderData } from '../AppTable'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface TableHeaderBlockProps {
  chooseMode?: boolean
  subTableRepresent?: boolean
  importantMode?: boolean
  ordering: string | null
  headerData: IHeaderData[]
  setCurrentSortFn?: (value: string) => void
}

export const TableHeaderBlock = ({
  headerData,
  subTableRepresent,
  chooseMode,
  ordering,
  setCurrentSortFn,
  importantMode,
}: TableHeaderBlockProps) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!ordering) {
      setActiveSort({ name: '', activeField: '' })
    }
  }, [ordering])

  const sortStartValue = ordering
    ? { name: ordering.replace('-', ''), activeField: ordering }
    : null
  const [activeSort, setActiveSort] = useState<{
    name: string
    activeField: string
  } | null>(sortStartValue)

  const onSortHandler = (name: string) => {
    const sortValue =
      activeSort?.name === name
        ? activeSort?.activeField === name
          ? `-${name}`
          : name
        : name
    setActiveSort({
      name,
      activeField: sortValue,
    })
    if (setCurrentSortFn) {
      setCurrentSortFn(sortValue)
    }
    if (!setCurrentSortFn) {
      searchParams.set('ordering', sortValue)
      setSearchParams(searchParams.toString())
    }
  }

  return (
    <thead>
      <tr>
        {subTableRepresent && <th />}
        {chooseMode && <th />}
        {importantMode && <th />}
        {headerData.map((th, index) => {
          return (
            <th
              key={`th-table-${index}`}
              className={th.sort ? 'c-pointer' : ''}
              onClick={th.sort ? () => onSortHandler(th.sort!) : undefined}
            >
              <div className={'thead-th'}>
                {t(th.title)}
                {!!th.sort && (
                  <i
                    className={`an-ico an-ico-arrow-down-bg ico-sort ${
                      activeSort?.name === th.sort
                        ? activeSort?.activeField === `-${th.sort}`
                          ? 'active-sort asc'
                          : 'active-sort'
                        : ''
                    }`}
                  />
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
