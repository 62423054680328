import { IBaseRequestDto, IBaseResponseDto } from './BaseDto'
import { IBuffetDishDto } from './BuffetDishDto'
import { ITag } from './TagsDto'
import { DimensionType } from './BuffetReportDto'
import { Role } from './UserDto'

export enum PlanMenuStatusType {
  Opened = 'Opened',
  Closed = 'Closed',
}

interface INonSyncedDishes {
  id: number
  name: string
}

export interface IStatusHistory {
  id: number
  user: {
    id: number
    name: string
    email: string
    role: Role[]
  }
  order: number
  status: PlanMenuStatusType
  createdAt: string
  nonSyncedDishes: INonSyncedDishes[]
  orderPlanMenuIntegrationStatus: null
}

export interface IPlanMenuControlOrderBase {
  name: string
  sourceRepresent: string
  orderDateStart: string
  orderDateEnd: string
  planMenuStatus: PlanMenuStatusType
  planMenu: number
  dishTotalCount: number
  dishPlannedCount: number
  comment: string
}

export interface IPlanMenuControlOrderDto extends IPlanMenuControlOrderBase {
  id: number
  lastStatusHistory: IStatusHistory
}

export interface IPlanMenuControlOrderLineDto {
  id: number
  lineGuid: string
  dish: IBuffetDishDto
  quantity: number
  deliveredStatus: boolean
  planned: boolean
  lastDimensionTime: number | null
  implementationTime: number | null
  implementationTimeExceeded: number | null
  countExpiredDim: number
  countNotExpiredDim: number
  countDimWithComment: number
}

export interface IPlanMenuControlDimensionDto {
  id: number
  photoUrl: string
  thermoPhotoUrl: string
  dishStandardImage: string
  dishStandardWeight: number
  temperature: number | null
  dimensionDatetime: string
  weight: number | null
  milletWeight: number
  dishTempMin: number
  dishTempMax: number
  tempChecker: number
  quantity: number
  dimensionType: DimensionType
  comment: string
  dishImplementationTime: number | null
  implementationTime: number
  implementationTimeExceeded: number | null
  dimensionTags: ITag[]
}

export interface IOrderLinesOpenDto {
  id: number
  lineGuid: string
  dish: IBuffetDishDto
  quantity: number
  deliveredStatus: boolean
  planned: boolean
  lastDimensionTime: number
  implementationTime: number
  implementationTimeExceeded: number
  photoUrl: string
}

export interface IPlanMenuControlOrdersDto extends IBaseResponseDto {
  results: IPlanMenuControlOrderDto[]
}
export interface IPlanMenuControlOrdersLinesOpenDto extends IBaseResponseDto {
  results: IOrderLinesOpenDto[]
}

export interface IPlanMenuControlOrdersRequest extends IBaseRequestDto {
  search?: string
  tag_ids?: string
  dimension_datetime?: string
  dish_ids?: string
  category_ids?: string
  implemented_status?: string
  delivery_status?: string
  dimension_type?: string
  comment?: string

  order_date_execution_gte?: string
  order_date_execution_lte?: string
  start_time?: string
  end_time?: string
  order_ids?: string
}
