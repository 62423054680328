export const colorsArr = [
  'rgba(148, 0, 211, 1)',
  'rgba(60, 179, 113, 1)',
  'rgb(18,197,197)',
  'rgba(50, 205, 50, 1)',
  'rgba(255, 140, 0, 1)',
  'rgba(255, 215, 0, 1)',
  'rgba(34, 139, 34, 1)',
  'rgba(12, 40, 94, 1)',
  'rgba(23, 78, 134, 1)',
  'rgba(53, 212, 117, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(0, 123, 186, 1)',
  'rgba(0, 169, 226, 1)',
  'rgba(124, 205, 244, 1)',
  'rgba(188, 227, 250, 1)',
  'rgba(155, 156, 155, 1)',
  'rgba(178, 176, 176, 1)',
  'rgba(197, 198, 198, 1)',
  'rgba(235, 235, 235, 1)',
  'rgba(62, 24, 22, 0.53)',
  'rgba(99, 53, 51, 1)',
  'rgba(106, 4, 15, 1)',
  'rgba(157, 2, 8, 1)',
  'rgba(208, 0, 0, 1)',
  'rgba(220, 47, 2, 1)',
  'rgba(232, 93, 4, 1)',
  'rgba(244, 140, 6, 1)',
  'rgba(250, 163, 7, 1)',
  'rgba(255, 186, 8, 1)',
  'rgba(44, 18, 0, 1)',
  'rgba(83, 49, 19, 1)',
  'rgba(140, 92, 43, 1)',
  'rgba(156, 119, 68, 1)',
  'rgba(234, 229, 214, 1)',
  'rgba(224, 227, 202, 1)',
  'rgba(180, 193, 135, 1)',
  'rgba(128, 145, 75, 1)',
  'rgba(90, 107, 58, 1)',
  'rgba(73, 98, 49, 1)',
  'rgba(57, 208, 92, 1)',
  'rgba(53, 233, 95, 1)',
  'rgba(53, 172, 122, 1)',
  'rgba(52, 127, 131, 1)',
  'rgba(46, 81, 138, 1)',
  'rgba(64, 40, 143, 1)',
  'rgba(87, 2, 161, 1)',
  'rgba(101, 0, 163, 1)',
  'rgba(132, 51, 181, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgb(51,105,105)',
  'rgba(138, 43, 226, 1)',
  'rgba(139, 0, 139, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255, 99, 71, 1)',
  'rgba(220, 20, 60, 1)',
  'rgba(255, 20, 147, 1)',
  'rgba(255, 69, 0, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 255, 127, 1)',
  'rgba(199, 21, 133, 1)',
  'rgba(75, 0, 130, 1)',
  'rgba(218, 165, 32, 1)',
  'rgba(189, 183, 107, 1)',
  'rgba(128, 0, 0, 1)',
  'rgba(255, 165, 0, 1)',
  'rgba(127, 255, 212, 1)',
  'rgba(70, 130, 180, 1)',
  'rgba(72, 61, 139, 1)',
  'rgba(240, 230, 140, 1)',
  'rgba(244, 164, 96, 1)',
  'rgba(46, 139, 87, 1)',
  'rgba(173, 255, 47, 1)',
  'rgba(0, 250, 154, 1)',
  'rgba(205, 92, 92, 1)',
  'rgba(112, 128, 144, 1)',
  'rgba(127, 255, 0, 1)',
  'rgba(106, 90, 205, 1)',
  'rgba(0, 128, 128, 1)',
  'rgba(47, 79, 79, 1)',
  'rgba(188, 143, 143, 1)',
  'rgba(255, 228, 181, 1)',
  'rgba(148, 0, 211, 1)',
  'rgba(60, 179, 113, 1)',
  'rgb(18,197,197)',
  'rgba(50, 205, 50, 1)',
  'rgba(255, 140, 0, 1)',
  'rgba(255, 215, 0, 1)',
  'rgba(34, 139, 34, 1)',
  'rgba(12, 40, 94, 1)',
  'rgba(23, 78, 134, 1)',
  'rgba(53, 212, 117, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(0, 123, 186, 1)',
  'rgba(0, 169, 226, 1)',
  'rgba(124, 205, 244, 1)',
  'rgba(188, 227, 250, 1)',
  'rgba(155, 156, 155, 1)',
  'rgba(178, 176, 176, 1)',
  'rgba(197, 198, 198, 1)',
  'rgba(235, 235, 235, 1)',
  'rgba(62, 24, 22, 0.53)',
  'rgba(99, 53, 51, 1)',
  'rgba(106, 4, 15, 1)',
  'rgba(157, 2, 8, 1)',
  'rgba(208, 0, 0, 1)',
  'rgba(220, 47, 2, 1)',
  'rgba(232, 93, 4, 1)',
  'rgba(244, 140, 6, 1)',
  'rgba(250, 163, 7, 1)',
  'rgba(255, 186, 8, 1)',
  'rgba(44, 18, 0, 1)',
  'rgba(83, 49, 19, 1)',
  'rgba(140, 92, 43, 1)',
  'rgba(156, 119, 68, 1)',
  'rgba(234, 229, 214, 1)',
  'rgba(224, 227, 202, 1)',
  'rgba(180, 193, 135, 1)',
  'rgba(128, 145, 75, 1)',
  'rgba(90, 107, 58, 1)',
  'rgba(73, 98, 49, 1)',
  'rgba(57, 208, 92, 1)',
  'rgba(53, 233, 95, 1)',
  'rgba(53, 172, 122, 1)',
  'rgba(52, 127, 131, 1)',
  'rgba(46, 81, 138, 1)',
  'rgba(64, 40, 143, 1)',
  'rgba(87, 2, 161, 1)',
  'rgba(101, 0, 163, 1)',
  'rgba(132, 51, 181, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgb(51,105,105)',
  'rgba(138, 43, 226, 1)',
  'rgba(139, 0, 139, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255, 99, 71, 1)',
  'rgba(220, 20, 60, 1)',
  'rgba(255, 20, 147, 1)',
  'rgba(255, 69, 0, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 255, 127, 1)',
  'rgba(199, 21, 133, 1)',
  'rgba(75, 0, 130, 1)',
  'rgba(218, 165, 32, 1)',
  'rgba(189, 183, 107, 1)',
  'rgba(128, 0, 0, 1)',
  'rgba(255, 165, 0, 1)',
  'rgba(127, 255, 212, 1)',
  'rgba(70, 130, 180, 1)',
  'rgba(72, 61, 139, 1)',
  'rgba(240, 230, 140, 1)',
  'rgba(244, 164, 96, 1)',
  'rgba(46, 139, 87, 1)',
  'rgba(173, 255, 47, 1)',
  'rgba(0, 250, 154, 1)',
  'rgba(205, 92, 92, 1)',
  'rgba(112, 128, 144, 1)',
  'rgba(127, 255, 0, 1)',
  'rgba(106, 90, 205, 1)',
  'rgba(0, 128, 128, 1)',
  'rgba(47, 79, 79, 1)',
  'rgba(188, 143, 143, 1)',
  'rgba(255, 228, 181, 1)',
  'rgba(148, 0, 211, 1)',
  'rgba(60, 179, 113, 1)',
  'rgb(18,197,197)',
  'rgba(50, 205, 50, 1)',
  'rgba(255, 140, 0, 1)',
  'rgba(255, 215, 0, 1)',
  'rgba(34, 139, 34, 1)',
  'rgba(12, 40, 94, 1)',
  'rgba(23, 78, 134, 1)',
  'rgba(53, 212, 117, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(0, 123, 186, 1)',
  'rgba(0, 169, 226, 1)',
  'rgba(124, 205, 244, 1)',
  'rgba(188, 227, 250, 1)',
  'rgba(155, 156, 155, 1)',
  'rgba(178, 176, 176, 1)',
  'rgba(197, 198, 198, 1)',
  'rgba(235, 235, 235, 1)',
  'rgba(62, 24, 22, 0.53)',
  'rgba(99, 53, 51, 1)',
  'rgba(106, 4, 15, 1)',
  'rgba(157, 2, 8, 1)',
  'rgba(208, 0, 0, 1)',
  'rgba(220, 47, 2, 1)',
  'rgba(232, 93, 4, 1)',
  'rgba(244, 140, 6, 1)',
  'rgba(250, 163, 7, 1)',
  'rgba(255, 186, 8, 1)',
  'rgba(44, 18, 0, 1)',
  'rgba(83, 49, 19, 1)',
  'rgba(140, 92, 43, 1)',
  'rgba(156, 119, 68, 1)',
  'rgba(234, 229, 214, 1)',
  'rgba(224, 227, 202, 1)',
  'rgba(180, 193, 135, 1)',
  'rgba(128, 145, 75, 1)',
  'rgba(90, 107, 58, 1)',
  'rgba(73, 98, 49, 1)',
  'rgba(57, 208, 92, 1)',
  'rgba(53, 233, 95, 1)',
  'rgba(53, 172, 122, 1)',
  'rgba(52, 127, 131, 1)',
  'rgba(46, 81, 138, 1)',
  'rgba(64, 40, 143, 1)',
  'rgba(87, 2, 161, 1)',
  'rgba(101, 0, 163, 1)',
  'rgba(132, 51, 181, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgb(51,105,105)',
  'rgba(138, 43, 226, 1)',
  'rgba(139, 0, 139, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255, 99, 71, 1)',
  'rgba(220, 20, 60, 1)',
  'rgba(255, 20, 147, 1)',
  'rgba(255, 69, 0, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 255, 127, 1)',
  'rgba(199, 21, 133, 1)',
  'rgba(75, 0, 130, 1)',
  'rgba(218, 165, 32, 1)',
  'rgba(189, 183, 107, 1)',
  'rgba(128, 0, 0, 1)',
  'rgba(255, 165, 0, 1)',
  'rgba(127, 255, 212, 1)',
  'rgba(70, 130, 180, 1)',
  'rgba(72, 61, 139, 1)',
  'rgba(240, 230, 140, 1)',
  'rgba(244, 164, 96, 1)',
  'rgba(46, 139, 87, 1)',
  'rgba(173, 255, 47, 1)',
  'rgba(0, 250, 154, 1)',
  'rgba(205, 92, 92, 1)',
  'rgba(112, 128, 144, 1)',
  'rgba(127, 255, 0, 1)',
  'rgba(106, 90, 205, 1)',
  'rgba(0, 128, 128, 1)',
  'rgba(47, 79, 79, 1)',
  'rgba(188, 143, 143, 1)',
  'rgba(255, 228, 181, 1)',
  'rgba(148, 0, 211, 1)',
  'rgba(60, 179, 113, 1)',
  'rgb(18,197,197)',
  'rgba(50, 205, 50, 1)',
  'rgba(255, 140, 0, 1)',
  'rgba(255, 215, 0, 1)',
  'rgba(34, 139, 34, 1)',
  'rgba(12, 40, 94, 1)',
  'rgba(23, 78, 134, 1)',
  'rgba(53, 212, 117, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(0, 123, 186, 1)',
  'rgba(0, 169, 226, 1)',
  'rgba(124, 205, 244, 1)',
  'rgba(188, 227, 250, 1)',
  'rgba(155, 156, 155, 1)',
  'rgba(178, 176, 176, 1)',
  'rgba(197, 198, 198, 1)',
  'rgba(235, 235, 235, 1)',
  'rgba(62, 24, 22, 0.53)',
  'rgba(99, 53, 51, 1)',
  'rgba(106, 4, 15, 1)',
  'rgba(157, 2, 8, 1)',
  'rgba(208, 0, 0, 1)',
  'rgba(220, 47, 2, 1)',
  'rgba(232, 93, 4, 1)',
  'rgba(244, 140, 6, 1)',
  'rgba(250, 163, 7, 1)',
  'rgba(255, 186, 8, 1)',
  'rgba(44, 18, 0, 1)',
  'rgba(83, 49, 19, 1)',
  'rgba(140, 92, 43, 1)',
  'rgba(156, 119, 68, 1)',
  'rgba(234, 229, 214, 1)',
  'rgba(224, 227, 202, 1)',
  'rgba(180, 193, 135, 1)',
  'rgba(128, 145, 75, 1)',
  'rgba(90, 107, 58, 1)',
  'rgba(73, 98, 49, 1)',
  'rgba(57, 208, 92, 1)',
  'rgba(53, 233, 95, 1)',
  'rgba(53, 172, 122, 1)',
  'rgba(52, 127, 131, 1)',
  'rgba(46, 81, 138, 1)',
  'rgba(64, 40, 143, 1)',
  'rgba(87, 2, 161, 1)',
  'rgba(101, 0, 163, 1)',
  'rgba(132, 51, 181, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgb(51,105,105)',
  'rgba(138, 43, 226, 1)',
  'rgba(139, 0, 139, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255, 99, 71, 1)',
  'rgba(220, 20, 60, 1)',
  'rgba(255, 20, 147, 1)',
  'rgba(255, 69, 0, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 255, 127, 1)',
  'rgba(199, 21, 133, 1)',
  'rgba(75, 0, 130, 1)',
  'rgba(218, 165, 32, 1)',
  'rgba(189, 183, 107, 1)',
  'rgba(128, 0, 0, 1)',
  'rgba(255, 165, 0, 1)',
  'rgba(127, 255, 212, 1)',
  'rgba(70, 130, 180, 1)',
  'rgba(72, 61, 139, 1)',
  'rgba(240, 230, 140, 1)',
  'rgba(244, 164, 96, 1)',
  'rgba(46, 139, 87, 1)',
  'rgba(173, 255, 47, 1)',
  'rgba(0, 250, 154, 1)',
  'rgba(205, 92, 92, 1)',
  'rgba(112, 128, 144, 1)',
  'rgba(127, 255, 0, 1)',
  'rgba(106, 90, 205, 1)',
  'rgba(0, 128, 128, 1)',
  'rgba(47, 79, 79, 1)',
  'rgba(188, 143, 143, 1)',
  'rgba(255, 228, 181, 1)',
  'rgba(148, 0, 211, 1)',
  'rgba(60, 179, 113, 1)',
  'rgb(18,197,197)',
  'rgba(50, 205, 50, 1)',
  'rgba(255, 140, 0, 1)',
  'rgba(255, 215, 0, 1)',
  'rgba(34, 139, 34, 1)',
  'rgba(12, 40, 94, 1)',
  'rgba(23, 78, 134, 1)',
  'rgba(53, 212, 117, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(0, 123, 186, 1)',
  'rgba(0, 169, 226, 1)',
  'rgba(124, 205, 244, 1)',
  'rgba(188, 227, 250, 1)',
  'rgba(155, 156, 155, 1)',
  'rgba(178, 176, 176, 1)',
  'rgba(197, 198, 198, 1)',
  'rgba(235, 235, 235, 1)',
  'rgba(62, 24, 22, 0.53)',
  'rgba(99, 53, 51, 1)',
  'rgba(106, 4, 15, 1)',
  'rgba(157, 2, 8, 1)',
  'rgba(208, 0, 0, 1)',
  'rgba(220, 47, 2, 1)',
  'rgba(232, 93, 4, 1)',
  'rgba(244, 140, 6, 1)',
  'rgba(250, 163, 7, 1)',
  'rgba(255, 186, 8, 1)',
  'rgba(44, 18, 0, 1)',
  'rgba(83, 49, 19, 1)',
  'rgba(140, 92, 43, 1)',
  'rgba(156, 119, 68, 1)',
  'rgba(234, 229, 214, 1)',
  'rgba(224, 227, 202, 1)',
  'rgba(180, 193, 135, 1)',
  'rgba(128, 145, 75, 1)',
  'rgba(90, 107, 58, 1)',
  'rgba(73, 98, 49, 1)',
  'rgba(57, 208, 92, 1)',
  'rgba(53, 233, 95, 1)',
  'rgba(53, 172, 122, 1)',
  'rgba(52, 127, 131, 1)',
  'rgba(46, 81, 138, 1)',
  'rgba(64, 40, 143, 1)',
  'rgba(87, 2, 161, 1)',
  'rgba(101, 0, 163, 1)',
  'rgba(132, 51, 181, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgb(51,105,105)',
  'rgba(138, 43, 226, 1)',
  'rgba(139, 0, 139, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255, 99, 71, 1)',
  'rgba(220, 20, 60, 1)',
  'rgba(255, 20, 147, 1)',
  'rgba(255, 69, 0, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 255, 127, 1)',
  'rgba(199, 21, 133, 1)',
  'rgba(75, 0, 130, 1)',
  'rgba(218, 165, 32, 1)',
  'rgba(189, 183, 107, 1)',
  'rgba(128, 0, 0, 1)',
  'rgba(255, 165, 0, 1)',
  'rgba(127, 255, 212, 1)',
  'rgba(70, 130, 180, 1)',
  'rgba(72, 61, 139, 1)',
  'rgba(240, 230, 140, 1)',
  'rgba(244, 164, 96, 1)',
  'rgba(46, 139, 87, 1)',
  'rgba(173, 255, 47, 1)',
  'rgba(0, 250, 154, 1)',
  'rgba(205, 92, 92, 1)',
  'rgba(112, 128, 144, 1)',
  'rgba(127, 255, 0, 1)',
  'rgba(106, 90, 205, 1)',
  'rgba(0, 128, 128, 1)',
  'rgba(47, 79, 79, 1)',
  'rgba(188, 143, 143, 1)',
  'rgba(255, 228, 181, 1)',
  'rgba(148, 0, 211, 1)',
  'rgba(60, 179, 113, 1)',
  'rgb(18,197,197)',
  'rgba(50, 205, 50, 1)',
  'rgba(255, 140, 0, 1)',
  'rgba(255, 215, 0, 1)',
  'rgba(34, 139, 34, 1)',
  'rgba(12, 40, 94, 1)',
  'rgba(23, 78, 134, 1)',
  'rgba(53, 212, 117, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(0, 123, 186, 1)',
  'rgba(0, 169, 226, 1)',
  'rgba(124, 205, 244, 1)',
  'rgba(188, 227, 250, 1)',
  'rgba(155, 156, 155, 1)',
  'rgba(178, 176, 176, 1)',
  'rgba(197, 198, 198, 1)',
  'rgba(235, 235, 235, 1)',
  'rgba(62, 24, 22, 0.53)',
  'rgba(99, 53, 51, 1)',
  'rgba(106, 4, 15, 1)',
  'rgba(157, 2, 8, 1)',
  'rgba(208, 0, 0, 1)',
  'rgba(220, 47, 2, 1)',
  'rgba(232, 93, 4, 1)',
  'rgba(244, 140, 6, 1)',
  'rgba(250, 163, 7, 1)',
  'rgba(255, 186, 8, 1)',
  'rgba(44, 18, 0, 1)',
  'rgba(83, 49, 19, 1)',
  'rgba(140, 92, 43, 1)',
  'rgba(156, 119, 68, 1)',
  'rgba(234, 229, 214, 1)',
  'rgba(224, 227, 202, 1)',
  'rgba(180, 193, 135, 1)',
  'rgba(128, 145, 75, 1)',
  'rgba(90, 107, 58, 1)',
  'rgba(73, 98, 49, 1)',
  'rgba(57, 208, 92, 1)',
  'rgba(53, 233, 95, 1)',
  'rgba(53, 172, 122, 1)',
  'rgba(52, 127, 131, 1)',
  'rgba(46, 81, 138, 1)',
  'rgba(64, 40, 143, 1)',
  'rgba(87, 2, 161, 1)',
  'rgba(101, 0, 163, 1)',
  'rgba(132, 51, 181, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgb(51,105,105)',
  'rgba(138, 43, 226, 1)',
  'rgba(139, 0, 139, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255, 99, 71, 1)',
  'rgba(220, 20, 60, 1)',
  'rgba(255, 20, 147, 1)',
  'rgba(255, 69, 0, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 255, 127, 1)',
  'rgba(199, 21, 133, 1)',
  'rgba(75, 0, 130, 1)',
  'rgba(218, 165, 32, 1)',
  'rgba(189, 183, 107, 1)',
  'rgba(128, 0, 0, 1)',
  'rgba(255, 165, 0, 1)',
  'rgba(127, 255, 212, 1)',
  'rgba(70, 130, 180, 1)',
  'rgba(72, 61, 139, 1)',
  'rgba(240, 230, 140, 1)',
  'rgba(244, 164, 96, 1)',
  'rgba(46, 139, 87, 1)',
  'rgba(173, 255, 47, 1)',
  'rgba(0, 250, 154, 1)',
  'rgba(205, 92, 92, 1)',
  'rgba(112, 128, 144, 1)',
  'rgba(127, 255, 0, 1)',
  'rgba(106, 90, 205, 1)',
  'rgba(0, 128, 128, 1)',
  'rgba(47, 79, 79, 1)',
  'rgba(188, 143, 143, 1)',
  'rgba(255, 228, 181, 1)',
]
