import React, { useEffect, useState } from 'react'
import { IPlanMenuControlOrdersRequest } from '../../../../core/api/dto/PlanMenuControlDto'
import { useGetPlanMenuCloseOrdersQuery } from '../../../../core/api/PlanMenuCloseApi'
import { IPlanMenuCloseOrderDto } from '../../../../core/api/dto/PlanMenuCloseDto'
import { PlanMenuListBlock } from '../../PlanMenuControl/components/PlanMenuListBlock'

export interface IPlanMenuListProps {
  pageParams: IPlanMenuControlOrdersRequest | null
  setEditMode?: (value: boolean) => void
}

export const PlanMenuCloseList = ({
  pageParams,
  setEditMode,
}: IPlanMenuListProps) => {
  const [offset, setOffset] = useState<number>(0)
  const [loadMore, setLoadMore] = useState<boolean>(false)

  const { data: ordersListData } = useGetPlanMenuCloseOrdersQuery(
    {
      ...pageParams,
      offset,
    },
    { skip: !pageParams },
  )
  const [ordersList, setOrdersList] = useState<IPlanMenuCloseOrderDto[]>([])

  useEffect(() => {
    if (offset === 0 && !!ordersListData) {
      setOrdersList(ordersListData.results)
    }
    if (offset !== 0 && !!ordersListData && loadMore) {
      setOrdersList((prevState) => [...prevState, ...ordersListData.results])
      setLoadMore(false)
    }
  }, [ordersListData])
  const nextPageHandler = () => {
    setLoadMore(true)
    setOffset((prevState) => prevState + 20)
  }

  return (
    <PlanMenuListBlock
      ordersList={ordersList}
      next={ordersListData?.next}
      nextPageHandler={nextPageHandler}
      setEditMode={setEditMode}
    />
  )
}
