import React, { useCallback, useEffect, useRef, useState } from 'react'
import ListOptions from './ListOptions'

export enum SelectSize {
  base = '',
  sm = 'sm',
  md = 'md',
}

export enum SelectColor {
  base = '',
}

interface ISelect<T, TKey extends keyof T> {
  size?: SelectSize
  options: T[]
  value: T
  name?: TKey
  id?: TKey
  icon?: TKey
  onChange: (val: T) => void
  disabled?: boolean
  isSearch?: boolean
  color?: SelectColor
}

const Select = <T, TKey extends keyof T>({
  options,
  value,
  name,
  id,
  icon,
  size = SelectSize.base,
  disabled = false,
  onChange,
  isSearch = false,
  color = SelectColor.base,
}: ISelect<T, TKey>) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isShow, setIsShow] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [suggestions, setSuggestions] = useState<T[]>(options)
  const handlerChangeValue = useCallback(
    (val: T) => {
      if (isSearch) {
        setSearchValue('')
      }
      setIsShow(!isShow)
      onChange(val)
    },
    [isSearch, isShow, onChange],
  )

  const searchHandler = useCallback(() => {
    if (searchValue) {
      setSuggestions(
        options.filter(
          (el) =>
            String(name ? el[name] : el)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) !== -1,
        ),
      )
    } else {
      setSuggestions(options)
    }
  }, [searchValue, name, options])

  useEffect(() => {
    searchHandler()
  }, [searchValue, searchHandler])

  const outSideClickHandler = (e: any) => {
    e.stopPropagation()
    if (ref.current && !ref.current.contains(e.target)) setIsShow(false)
  }

  useEffect(() => {
    if (isShow) {
      document.addEventListener('click', outSideClickHandler, false)
      document.addEventListener('touchend', outSideClickHandler, false)
    }
    return () => {
      if (isShow) {
        document.removeEventListener('click', outSideClickHandler, false)
        document.removeEventListener('touchend', outSideClickHandler, false)
      }
    }
  }, [isShow])

  const handlerShowHideOptions = () => !disabled && setIsShow(!isShow)

  return (
    <div
      className={`select ${isShow ? 'open' : ''} ${size} ${
        value && !disabled ? 'check' : ''
      } ${color}`}
      ref={ref}
    >
      <div className="select-trigger" onClick={handlerShowHideOptions}>
        {!(isSearch && isShow) ? (
          <>
            {icon && value[icon] && (
              <img src={`${value[icon]}`} alt="" className="image" />
            )}
            <div className="option">
              <span>{name ? value[name] : value}</span>
            </div>
            <div className="wrapper-icon">
              <i className="an-ico an-ico-arrow-down" />
            </div>
          </>
        ) : (
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className="search-field"
            autoFocus={true}
          />
        )}
      </div>

      {isShow && (
        <ListOptions
          options={suggestions}
          name={name}
          id={id}
          icon={icon}
          onChange={handlerChangeValue}
          isSearch={isSearch}
        />
      )}
    </div>
  )
}

export default Select
