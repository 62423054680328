import React, { MouseEvent, useState } from 'react'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import { ICategory } from '../../core/api/dto/CategoriesObjDto'
import ChangeCategoryPopup from '../popup/ChangeCategoryPopup'

interface IPropsNavItemWithCategory {
  el: ICategory
  path?: never
  name?: never
}

interface IPropsNavItemWithoutCategory {
  path: string
  name: string
  el?: never
}

type TPropsNavItem = IPropsNavItemWithCategory | IPropsNavItemWithoutCategory

const NavSidebarItem = ({ el, name, path }: TPropsNavItem) => {
  const [isShowPopup, setIsShowPopup] = useState(false)
  const { technologicalCatalogRestaurantId } = useParams()

  const changeCategory = (e: MouseEvent) => {
    e.preventDefault()
    setIsShowPopup(true)
  }
  return (
    <>
      <NavLink
        to={`${technologicalCatalogRestaurantId}/${el ? el.id : path}`}
        className={`item-category`}
      >
        <img
          src={
            el?.iconPreview
              ? el.iconPreview
              : '/assets/images/category-icon.png'
          }
          alt=""
          className="icon-category"
        />
        <span>{el ? el.name : name}</span>
        {el && (
          <i className="an-ico an-ico-go" onClick={(e) => changeCategory(e)} />
        )}
      </NavLink>
      {isShowPopup && el && (
        <ChangeCategoryPopup
          setIsShowPopup={setIsShowPopup}
          currentCategory={el}
        />
      )}
    </>
  )
}

export default NavSidebarItem
