import { store } from '../core/store/store'
import {
  AppNotification,
  NotificationType,
} from '../components/notification/Notification'

export interface IObj {
  [key: string]: string
}
interface IBodyObj {
  [key: string]: string | null
}

export const useHttp = <T extends Object>() => {
  const request = async (
    url: string,
    method = 'GET',
    body: IBodyObj | T | null | string = null,
    headers: IObj = {},
    fileDownload = false,
  ) => {
    try {
      if (body) {
        body = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
      }
      const resp = await fetch(url, { method, body, headers })
      if (fileDownload) {
        if (!resp.ok) {
          throw new Error('что то не так')
        }
        resp.blob().then((blob) => {
          window.open(URL.createObjectURL(blob), '_blank')
        })
        return true
      } else {
        const data = await resp.json()
        if (resp.status === 410) {
          return 'reSend'
        } else if (resp.status === 404) {
          return false
        } else if (resp.status === 401) {
          throw new Error(data.response.errorMessage || 'ошибка авторизации')
        } else if (!resp.ok) {
          throw new Error(data.response.errorMessage || 'что то не так')
        }
        return data.response
      }
    } catch (e) {
      AppNotification({
        msg: `Что то пошло не так при загрузке ${fileDownload ? 'файла' : ''}`,
        type: NotificationType.error,
      })
      throw e
    }
  }

  return { request }
}

export const GetFile = async (url: string) => {
  const { request } = useHttp()

  const { foodBoxAuth } = store.getState()
  try {
    if (!!foodBoxAuth?.token) {
      await request(
        `${url}`,
        'GET',
        null,
        {
          Authorization: foodBoxAuth.token ? `Bearer ${foodBoxAuth.token}` : '',
        },
        true,
      )
    }
    return true
  } catch (e) {
    return false
  }
}
