import { api, LIMIT } from './BaseApi'
import {
  IDishesResponseDto,
  IDish,
  IDishWithVersions,
  IIngredients,
  IWorkshop,
} from './dto/DishObjDto'

export interface IPropsGetDishes {
  id?: string
  queryString: string
}

interface IVersions {
  description: string
  ingredients: IIngredients[]
}

export const dishApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDishes: builder.query<IDishesResponseDto, IPropsGetDishes>({
      query: ({ id, queryString }) => ({
        url: `/dish/?${queryString}`,
        params: id
          ? {
              category: id,
              limit: LIMIT.chef,
            }
          : { limit: LIMIT.chef },
      }),
      providesTags: ['Dish'],
    }),
    getDishesWithoutCategory: builder.query<IDishesResponseDto, string>({
      query: (queryString) => ({
        url: `/dish/get_dishes_without_category/?${queryString}`,
        params: { limit: LIMIT.chef },
      }),
      providesTags: ['Dish'],
    }),
    getDishById: builder.query<IDishWithVersions, string>({
      query: (id) => ({
        url: `/dish/${id}/`,
      }),
      providesTags: ['Dish'],
    }),
    getDishVersionById: builder.query<IVersions, string>({
      query: (id) => ({
        url: `/dish/${id}/get_diff_version/`,
      }),
      providesTags: ['Dish'],
    }),
    updateDish: builder.mutation<IDish, Partial<IDish>>({
      query: (body) => ({
        url: `/dish/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Dish'],
    }),
    getWorkshops: builder.query<IWorkshop[], null>({
      query: () => ({
        url: `/dish/get_workshops/`,
      }),
      providesTags: ['Dish'],
    }),
  }),
})

export const {
  useGetDishesQuery,
  useGetDishByIdQuery,
  useUpdateDishMutation,
  useGetDishVersionByIdQuery,
  useGetDishesWithoutCategoryQuery,
  useGetWorkshopsQuery,
} = dishApi
