import { api } from './BaseApi'
import {
  IStationDto,
  IStationsRequestDto,
  IStationsResponseDto,
} from './dto/StationsDto'

export const stationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStations: builder.query<IStationsResponseDto, IStationsRequestDto>({
      query: (params) => ({
        url: '/station/',
        params: !!params ? params : {},
      }),
      providesTags: ['Stations'],
    }),
    getStationDetail: builder.query<IStationDto, { id: number }>({
      query: ({ id }) => ({
        url: `/station/${id}`,
      }),
      providesTags: ['Stations'],
    }),
    updateStation: builder.mutation<IStationDto, { id: number; name: string }>({
      query: ({ id, name }) => ({
        url: `/station/${id}/`,
        method: 'PATCH',
        body: { name },
      }),
      invalidatesTags: ['Stations'],
    }),
  }),
})

export const {
  useGetStationsQuery,
  useGetStationDetailQuery,
  useUpdateStationMutation,
} = stationsApi
