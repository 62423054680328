import { AppTableTaskStatusEnum } from 'components/AppTable/AppTableStatus/AppTableStatus'
import { IBaseRequestDto, IBaseResponseDto } from './BaseDto'
import { IEmployeeDto } from './EmployeeDto'
import { IRole } from './UserDto'

export interface ITaskEmployee {
  id: number
  name: string
  email: string
}

interface ITaskBase {
  title: string
  description: string
  isImportant: boolean
  status: AppTableTaskStatusEnum
}

export interface ICreateTaskDto extends ITaskBase {
  followers: number[]
  responsible: number | null
  expectedCompletionDate?: string | null
}

export interface ITaskDto extends ITaskBase {
  id: number
  title: string
  responsible: ITaskEmployee | null
  followers: IEmployeeDto[]
  creator: ITaskEmployee
  expectedCompletionDate?: string
  createdAt: string
  status: AppTableTaskStatusEnum
  hasNotifications: boolean
}

export interface ITasksResponseDto extends IBaseResponseDto {
  results: ITaskDto[]
}

export interface ITasksRequestDto extends IBaseRequestDto {
  search?: string
  ids?: string
  ordering?: string
  statuses?: AppTableTaskStatusEnum
}

interface ISender {
  id: number
  name: string
  role: IRole
}

interface ITasksCommentBase {
  text: string
}

export interface ICreateTasksComment extends ITasksCommentBase {
  mentionedUsers?: number[]
  taskObjects?: number[]
  task: number
}

export interface ITasksCommentDto extends ITasksCommentBase {
  id: number
  createdAt: string
  sender: ISender
  mentionedUsers: ISender[]
  taskObjects: ITaskObjectDto[]
}

export interface ITasksCommentsResponseDto extends IBaseResponseDto {
  results: ITasksCommentDto[]
}

export interface ITasksCommentsRequestDto extends IBaseRequestDto {
  task: number
  ordering?: string
}

export enum ObjectTypeEnum {
  LINE = 'LINE',
  ORDER = 'ORDER',
  DIMENSION = 'DIMENSION',
}

export enum ObjectTypeTranslateEnum {
  ORDER = 'Блюдо',
  LINE = 'План-меню',
  DIMENSION = 'Замер',
}

export interface IOrderLineDimensionTask {
  id: number
  orderLine: {
    id: number
    dish: {
      id: number
      name: string
      createdAt: string
    }
    order: { id: number }
  }
  dimensionDatetime: string
}

export interface IOrderLineTask {
  id: number
  dish: {
    id: number
    name: string
    createdAt: string
  }
  order: { id: number }
}
export interface IOrderTask {
  id: number
  name: string
  orderDate: string
  orderDateStart: string
}

interface ITaskObjectBase {
  title: string
  objectType: ObjectTypeEnum
}

export interface ITaskObjectDto extends ITaskObjectBase {
  id: number
  orderLineDimension?: IOrderLineDimensionTask
  orderLine?: IOrderLineTask
  order?: IOrderTask
}

export interface ITaskObjectResponseDto extends IBaseResponseDto {
  results: ITaskObjectDto[]
}

export interface ITaskObjectRequestDto extends IBaseRequestDto {
  task: number
  search?: string
}

export interface ITaskObjectCreateDto {
  orderLine?: number | null
  order?: number | null
  orderLineDimension?: number | null
}

export interface ITaskObjectCreateResponseDto extends ITaskObjectDto {
  task: {
    id: number
  }
}
