import { useGetCurrentUserQuery } from '../core/api/UserApi'
import { useMemo } from 'react'
import { Role } from '../core/api/dto/UserDto'

export const useIsAdmin = (): boolean | null => {
  const { data: currentUser } = useGetCurrentUserQuery()

  return useMemo(
    () =>
      currentUser
        ? !!currentUser.userRoles.roleType.find(
            (item) => item === Role.BUFF_SUPERVISOR,
          )
        : null,
    [currentUser],
  )
}
