import React, { useCallback, useState } from 'react'
import { AppImagePopup, IAppImagePopup } from '../AppImagePopup/AppImagePopup'
import {
  ImagePreviewWrapper,
  ImagePreviewWrapperSizeEnum,
} from 'UiKitComponents/ImagePreviewWrapper/ImagePreviewWrapper'

export interface IAppTableImageViewerProps {
  archived?: boolean
  imageName: string
  image: string | null
  imgPreview?: string | null
  size?: ImagePreviewWrapperSizeEnum
}

export const AppTableImageViewer = ({
  archived,
  image,
  imgPreview,
  imageName,
  size,
}: IAppTableImageViewerProps) => {
  const [imagePopup, setImagePopup] = useState<IAppImagePopup>({
    active: false,
    photo: null,
    title: null,
  })

  const closeImagePopupHandler = () => {
    setImagePopup({ active: false, photo: null, title: null })
  }

  const openImagePopupHandler = (photo: string, title: string) => {
    setImagePopup({ active: true, photo, title })
  }

  const onClick = useCallback(
    () => openImagePopupHandler(image!, imageName),
    [image, imageName],
  )

  return (
    <React.Fragment>
      {imagePopup.active && imagePopup.photo && (
        <AppImagePopup
          title={imagePopup.title || ''}
          img={imagePopup.photo}
          setActive={closeImagePopupHandler}
        />
      )}
      <ImagePreviewWrapper
        image={image}
        onClick={onClick}
        archived={archived}
        imgPreview={imgPreview}
        size={size}
      />
    </React.Fragment>
  )
}
