import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit'
import { errorHandling } from '../../../utils'
import { AllRoutes } from '../../routes/AllRoutes'
import {
  setDimensionCount,
  setErrorWeight,
  setFetchError,
  setTempCount,
} from '../connection/connectionSlice'
import * as Sentry from '@sentry/react'

const currentUrl = window.location.href

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const isCurrentWight =
      action?.meta?.arg?.endpointName?.includes('getCurrentWeight')
    const isGetPredictionData =
      action?.meta?.arg?.endpointName?.includes('getPredictionData')
    const isAddDimension =
      action?.meta?.arg?.endpointName?.includes('addDimension')
    const isGetCurrentTemperature = action?.meta?.arg?.endpointName?.includes(
      'getCurrentTemperature',
    )
    const isCheckLatestModel =
      action?.meta?.arg?.endpointName?.includes('checkLatestModel')
    const isCheckTrainingProcess = action?.meta?.arg?.endpointName?.includes(
      'checkTrainingProcess',
    )
    if (
      action.type.endsWith('/pending') &&
      (isCurrentWight || isAddDimension || isGetCurrentTemperature)
    ) {
      const requestStartTime = Date.now()
      const timerId = setTimeout(() => {
        Sentry.captureMessage(
          `Дольше 3сек ${action?.meta?.arg?.endpointName}`,
          {
            extra: {
              query: action?.meta?.requestId,
            },
          },
        )
      }, 3000)

      // Добавляем информацию о времени начала запроса и таймере в метаданные экшена
      action.meta.requestStartTime = requestStartTime
      action.meta.timerId = timerId
    }
    if (isRejectedWithValue(action)) {
      if (action?.payload?.status === 'PARSING_ERROR' && !isGetPredictionData) {
        if (!api.getState().connection.isFetchError) {
          api.dispatch(setFetchError('Ошибка сервера'))
          Sentry.captureException(new Error(`Ошибка сервера`))
        }
      } else if (
        (action?.payload?.status === 'FETCH_ERROR' ||
          action.payload.status.toString()[0] === '5') &&
        !currentUrl.includes(AllRoutes.login.path)
      ) {
        if (isCurrentWight) {
          api.dispatch(setErrorWeight(true))
        }
        if (isAddDimension) {
          const { addDimensionCount } = api.getState().connection
          const errorText =
            action?.payload?.status.toString() === '503'
              ? 'Отсутствует соединение c весами'
              : 'Отсутствует соединение'
          if (addDimensionCount >= 3) {
            api.dispatch(setFetchError(errorText))
          } else {
            api.dispatch(setDimensionCount(addDimensionCount + 1))
          }
          Sentry.captureException(
            new Error(
              `add dimension - ${errorText}, статус: ${action?.payload?.status}`,
            ),
          )
        }
        if (isGetCurrentTemperature) {
          const { addTempCount } = api.getState().connection
          if (addTempCount >= 3) {
            api.dispatch(
              setFetchError('Отсутствует соединение c датчиком температуры'),
            )
          } else {
            api.dispatch(setTempCount(addTempCount + 1))
          }
          Sentry.captureException(
            new Error(
              `Отсутствует соединение c датчиком температуры, статус: ${action?.payload?.status}`,
            ),
          )
        }
      } else {
        if (!isGetPredictionData && !isCheckLatestModel && !isCheckTrainingProcess) {
          errorHandling(action.payload)
        }
      }
    }

    if (
      (action.type.endsWith('/rejected') ||
        action.type.endsWith('/fulfilled')) &&
      (isCurrentWight || isAddDimension || isGetCurrentTemperature)
    ) {
      clearTimeout(action.meta?.timerId)
      delete action.meta?.requestStartTime
      delete action.meta?.timerId
      if (action.type.endsWith('/fulfilled')) {
        if (isAddDimension || isGetCurrentTemperature) {
          if (isAddDimension) {
            api.dispatch(setFetchError(''))
            api.dispatch(setDimensionCount(0))
          }
          if (isGetCurrentTemperature) {
            api.dispatch(setTempCount(0))
          }
        }
        if (isCurrentWight) {
          api.dispatch(setErrorWeight(false))
        }
      }
    }
    return next(action)
  }
