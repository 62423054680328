import { api } from './BaseApi'
import {
  IComputeDishQuantity,
  ICreatePlanMenuDto,
  IOrderLine,
  IPlanMenuDto,
  IPlanMenuRequest,
  IPlanMenuResponse,
} from './dto/PlanMenuDto'

export const planMenuApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlanMenuList: builder.query<IPlanMenuResponse, IPlanMenuRequest>({
      query: (params) => ({
        url: '/plan_menu/',
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenu', 'ВuffetDish', 'Order'],
    }),
    getPlanMenuSimpleList: builder.query<IPlanMenuResponse, IPlanMenuRequest>({
      query: (params) => ({
        url: '/plan_menu/get_plans_menu_simple_info/',
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenu', 'ВuffetDish', 'Order'],
    }),
    getPlanMenuDetail: builder.query<IPlanMenuDto, { id: number }>({
      query: ({ id }) => ({
        url: `/plan_menu/${id}/`,
      }),
      providesTags: ['PlanMenu', 'ВuffetDish'],
    }),
    computeDishQuantity: builder.query<
      IComputeDishQuantity[],
      { id: number; count: number }
    >({
      query: ({ id, count }) => ({
        url: `/plan_menu/${id}/compute_dish_lines_quantity/`,
        params: { number_of_persons: count },
      }),
      providesTags: ['PlanMenu', 'ВuffetDish'],
    }),
    createPlanMenu: builder.mutation<IPlanMenuDto, ICreatePlanMenuDto>({
      query: (body) => ({
        method: 'POST',
        url: '/plan_menu/',
        body,
      }),
      invalidatesTags: ['PlanMenu', 'Order'],
    }),
    addedPlanMenuDishList: builder.mutation<
      IPlanMenuDto,
      { id: number; body: IOrderLine[] }
    >({
      query: ({ id, body }) => ({
        method: 'POST',
        url: `/plan_menu_dish/create_or_update_multiple_lines_dishes/${id}/`,
        body,
      }),
      invalidatesTags: ['PlanMenu', 'Order'],
    }),
    deletePlanMenuDishList: builder.mutation<
      IPlanMenuDto,
      { id: number; lineGuids: string[] }
    >({
      query: ({ id, lineGuids }) => ({
        method: 'POST',
        url: `/plan_menu_dish/archive_multiple_lines_dishes/${id}/`,
        body: { lineGuids },
      }),
      invalidatesTags: ['PlanMenu'],
    }),
    updatePlanMenu: builder.mutation<
      IPlanMenuDto,
      { id: number; data: ICreatePlanMenuDto }
    >({
      query: ({ id, data }) => ({
        method: 'PATCH',
        url: `/plan_menu/${id}/`,
        body: data,
      }),
      invalidatesTags: ['PlanMenu', 'Order'],
    }),
    archiveMultiplePlanMenu: builder.mutation<
      IPlanMenuDto,
      { plansMenu: number[] }
    >({
      query: ({ plansMenu }) => ({
        method: 'PATCH',
        url: `plan_menu/archive_multiple_plans_menu/`,
        body: { plansMenu },
      }),
      invalidatesTags: ['PlanMenu'],
    }),
  }),
})

export const {
  useGetPlanMenuListQuery,
  useGetPlanMenuSimpleListQuery,
  useGetPlanMenuDetailQuery,
  useComputeDishQuantityQuery,
  useCreatePlanMenuMutation,
  useUpdatePlanMenuMutation,
  useAddedPlanMenuDishListMutation,
  useDeletePlanMenuDishListMutation,
  useArchiveMultiplePlanMenuMutation,
} = planMenuApi
