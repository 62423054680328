import { api } from './BaseApi'
import { IPlanMenuDto } from './dto/PlanMenuDto'
import {
  IPlanMenuTypeBase,
  IPlanMenuTypeDto,
  IPlanMenuTypeRequest,
  IPlanMenuTypeResponse,
} from './dto/PlanMenuTypeDto'

export const planMenuTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlanMenuTypeList: builder.query<
      IPlanMenuTypeResponse,
      IPlanMenuTypeRequest
    >({
      query: (params) => ({
        url: '/plan_menu_type/',
        params: !!params ? params : {},
      }),
      providesTags: ['PlanMenuType'],
    }),
    getPlanMenuTypeDetail: builder.query<IPlanMenuTypeDto, { id: number }>({
      query: ({ id }) => ({
        url: `/plan_menu_type/${id}/`,
      }),
      providesTags: ['PlanMenuType'],
    }),
    createPlanMenuType: builder.mutation<IPlanMenuTypeDto, IPlanMenuTypeBase>({
      query: (body) => ({
        method: 'POST',
        url: '/plan_menu_type/',
        body,
      }),
      invalidatesTags: ['PlanMenuType'],
    }),
    updatePlanMenuType: builder.mutation<
      IPlanMenuDto,
      { id: number; data: IPlanMenuTypeBase }
    >({
      query: ({ id, data }) => ({
        method: 'PATCH',
        url: `/plan_menu_type/${id}/`,
        body: data,
      }),
      invalidatesTags: ['PlanMenuType'],
    }),
    archiveMultiplePlanMenuType: builder.mutation<
      IPlanMenuDto,
      { data: number[] }
    >({
      query: ({ data }) => ({
        method: 'PATCH',
        url: `/plan_menu_type/archive_multiple_plan_menu_types/`,
        body: { planMenuTypes: data },
      }),
      invalidatesTags: ['PlanMenuType'],
    }),
  }),
})

export const {
  useGetPlanMenuTypeListQuery,
  useGetPlanMenuTypeDetailQuery,
  useCreatePlanMenuTypeMutation,
  useUpdatePlanMenuTypeMutation,
  useArchiveMultiplePlanMenuTypeMutation,
} = planMenuTypeApi
