import React from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { FrozeList } from './FrozeList/FrozeList'
import { FrozeDetail } from './FrozeDetail'
import { useGetParamQuery } from '../../../utils/useGetParamQuery'
import { useGetBuffetDishDetailQuery } from '../../../core/api/BuffetDishApi'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import './PlanMenuControlManagerDetail.scss'
import { useGetWidth } from 'utils/useGetWidth'

export const PlanMenuControlManagerDetail = () => {
  const dishId = useGetParamQuery('dishId')
  const { width } = useGetWidth()
  const { data: currentDish } = useGetBuffetDishDetailQuery(
    { id: Number(dishId!) },
    { skip: !dishId },
  )
  return (
    <div className="width-for-page-wrapper plan-menu-control-manager-detail">
      <AppTitlePage
        title={currentDish?.name || ''}
        goBack
        goBackPath={`/${AllRoutes.planMenuControlManager.path}/`}
      />
      <div className="plan-menu-control-page close manager-detail mt-15">
        <div className={`width-${width <= 1024 ? width<=768 ? 100 : 33 : 25}`}>
          <FrozeList />
        </div>
        <div className={`width-${width <= 1024 ? width<=768 ? 100 : 33 : 37}`}>
          <FrozeDetail withoutPhotoBlock />
        </div>
        <div className={`width-${width <= 1024 ? width<=768 ? 100 : 33 : 37}`}>
          <FrozeDetail withoutInfoBlock />
        </div>
      </div>
    </div>
  )
}
