import { TSelector } from '../AppTable'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ToggleTableBlock } from './ToggleTableBlock'
import { useTouchMove } from './useTouchMove'
import { IChooseItems } from '../../mainContent/TableAllDishes'
import Checkbox from '../../checkbox/Checkbox'
import { useGetWidth } from 'utils/useGetWidth'

interface ITableMobileRowProps<T, TKey> {
  item: T
  itemIndex: number
  idName?: string
  mobileMode: boolean
  currentCol: number
  setCurrentCol: React.Dispatch<React.SetStateAction<number>>
  tableDataSelectors: TSelector<T, TKey>[]
  subTableRepresent?: (
    item: T,
    currentCol: number,
    itemIndex?: number,
    onTouchStart?: ((e: React.TouchEvent) => void) | undefined,
    onTouchMove?: ((e: React.TouchEvent) => void) | undefined,
    width?: number,
    toggleAllState?: number[]
  ) => void
  isDefaultOpen?: boolean
  rowClassName?: string
  tableCellClassName?: string
  toggleAllState?: number[]
  setToggleAllState?: Dispatch<SetStateAction<number[]>>
  chooseMode?: boolean
  chooseItems?: IChooseItems | null
  chooseItemsHandler: (value: boolean, id: number) => void
}

export const TableMobileRow = <
  T extends {
    id: number
    archived?: boolean
  },
  TKey extends keyof T,
>({
  item,
  mobileMode,
  currentCol,
  setCurrentCol,
  idName,
  itemIndex,
  subTableRepresent,
  tableDataSelectors,
  isDefaultOpen,
  rowClassName,
  tableCellClassName,
  toggleAllState,
  setToggleAllState,
  chooseItems,
  chooseItemsHandler,
  chooseMode,
}: ITableMobileRowProps<T, TKey>) => {
  const [subTableActive, setSubTableActive] = useState(!!isDefaultOpen)
  const { width } = useGetWidth()
  const defaultRenderItem = (item: T, name: TKey) => {
    return <div>{item[name] ? `${item[name]}` : null}</div>
  }
  const { onTouchStart, onTouchMove } = useTouchMove({
    totalCount: tableDataSelectors.length,
    currentCol,
    setCurrentCol,
  })

  useEffect(() => {
    if (!toggleAllState) return
    const isExternalActive = toggleAllState?.find(
      (index) => index === itemIndex,
    )
    setSubTableActive(isExternalActive !== undefined)
  }, [itemIndex, toggleAllState])

  if (mobileMode) {
    return (
      <React.Fragment>
        <tr className={rowClassName}>
          {chooseMode && (
            <td>
              {!!item?.archived ? (
                <div className={'delete-checkbox delete-item'}>
                  <i className="an-ico an-ico-close" />
                </div>
              ) : (
                <Checkbox
                  name={`${item.id}`}
                  value={
                    !!chooseItems && chooseItems[item.id!]
                      ? chooseItems[item.id!]
                      : false
                  }
                  onChange={(value) => chooseItemsHandler(value, item.id!)}
                />
              )}
            </td>
          )}
          {(width < 600
            ? [tableDataSelectors[0], tableDataSelectors[currentCol]]
            : [
                tableDataSelectors[0],
                tableDataSelectors.slice(
                  currentCol,
                  currentCol + (width < 600 ? 1 : 3),
                ),
              ].flat()
          ).map(
            (
              {
                propsNameToDisable,
                name,
                renderItem = (item) =>
                  name ? defaultRenderItem(item, name!) : '?',
              },
              tdIndex,
            ) => {
              return (
                <td
                  key={`tr-${itemIndex}-td-${tdIndex}`}
                  id={`${
                    (idName || '') + '-' || ''
                  }row-${itemIndex}-col-${tdIndex}`}
                  className={subTableActive ? 'active' : undefined}
                  onTouchStart={!!tdIndex ? onTouchStart : undefined}
                  onTouchMove={!!tdIndex ? onTouchMove : undefined}
                >
                  {subTableRepresent && !tdIndex ? (
                    <ToggleTableBlock
                      subTableActive={subTableActive}
                      setSubTableActive={(value) => {
                        setSubTableActive(value)
                      }}
                    >
                      {renderItem(item, itemIndex)}
                    </ToggleTableBlock>
                  ) : (
                    renderItem(item, itemIndex)
                  )}
                </td>
              )
            },
          )}
        </tr>
        {subTableRepresent &&
          subTableActive &&
          subTableRepresent(
            item,
            mobileMode ? currentCol : 0,
            itemIndex,
            onTouchStart,
            onTouchMove,
            width,
            toggleAllState
          )}
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <tr className={rowClassName}>
        {chooseMode && (
          <td>
            {!!item?.archived ? (
              <div className={'delete-checkbox delete-item'}>
                <i className="an-ico an-ico-close" />
              </div>
            ) : (
              <Checkbox
                name={`${item.id}`}
                value={
                  !!chooseItems && chooseItems[item.id!]
                    ? chooseItems[item.id!]
                    : false
                }
                onChange={(value) => chooseItemsHandler(value, item.id!)}
              />
            )}
          </td>
        )}
        {tableDataSelectors.map(
          (
            {
              propsNameToDisable,
              name,
              renderItem = (item) =>
                name ? defaultRenderItem(item, name!) : '?',
            },
            tdIndex,
          ) => {
            return (
              <td
                key={`tr-${itemIndex}-td-${tdIndex}`}
                id={`${
                  (idName || '') + '-' || ''
                }row-${itemIndex}-col-${tdIndex}`}
                className={`${tableCellClassName || ''}${
                  subTableActive ? ' active' : ''
                }`}
              >
                {subTableRepresent && !tdIndex ? (
                  <ToggleTableBlock
                    setToggleAllState={setToggleAllState}
                    toggleAllState={toggleAllState}
                    subTableActive={subTableActive}
                    setSubTableActive={setSubTableActive}
                    itemIndex={itemIndex}
                  >
                    {renderItem(item, itemIndex)}
                  </ToggleTableBlock>
                ) : (
                  renderItem(item, itemIndex)
                )}
              </td>
            )
          },
        )}
      </tr>
      {subTableRepresent &&
        subTableActive &&
        subTableRepresent(
          item,
          mobileMode ? currentCol : 0,
          itemIndex,
          onTouchStart,
          onTouchMove,
          undefined,
          toggleAllState
        )}
    </React.Fragment>
  )
}
