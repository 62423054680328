import React, { ChangeEvent } from 'react'

type TInputFile = {
  label: string
  onChange: (val: string) => void
  className: string
  disabled?: boolean
}

export const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

const InputFile = ({ label, className, onChange, disabled }: TInputFile) => {
  const uploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const base64 = await convertBase64(file)
      onChange(base64 as string)
    }
  }

  return (
    <div className={`wrapper-field ${className ? className : ''}`}>
      <input
        type="file"
        name="file"
        id="file"
        onChange={uploadImage}
        disabled={disabled}
      />
      <label htmlFor="file">{label}</label>
    </div>
  )
}

export default InputFile
