import React, { useEffect, useState } from 'react'
import { ITaskDto } from 'core/api/dto/TaskDto'
import { AppRadioButtonsBlock } from 'components/AppRadioButtonsBlock/AppRadioButtonsBlock'
import { getAvailableStatuses } from '../../getAvailableStatuses'
import moment from 'moment'
import './EditTaskDisabledForm.css'
import { ITaskFormData } from '../EditTaskForm/EditTaskForm'
import { AppTableTaskStatusEnum } from 'components/AppTable/AppTableStatus/AppTableStatus'
import { AppFormHook } from 'components/AppFormController'
import { AppCheckboxButton } from '../../../../../UiKitComponents/AppCheckboxButton'

interface IEditTaskDisabledFormProps {
  currentTask: ITaskDto
  isCreatorCurrentUser: boolean
  setFormData: React.Dispatch<React.SetStateAction<ITaskFormData | null>>
  isResponsibleCurrentUser: boolean
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
}

const formData = [
  {
    fieldName: 'title',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'description',
  },
  {
    fieldName: 'responsible',
  },
  {
    fieldName: 'expectedCompletionDate',
  },
  {
    fieldName: 'status',
  },
]

export const EditTaskDisabledForm: React.FC<IEditTaskDisabledFormProps> = (
  props,
) => {
  const {
    currentTask,
    isCreatorCurrentUser,
    setFormData,
    isResponsibleCurrentUser,
    setIsDirty,
  } = props
  const [status, setStatus] = useState(currentTask.status)
  const radioButtons = getAvailableStatuses(
    isCreatorCurrentUser,
    currentTask.status,
    isResponsibleCurrentUser,
  )
  const { formStateChangeHandler } = AppFormHook({
    formConfig: formData,
  })
  const expectedCompletionDate = currentTask.expectedCompletionDate
    ? new Date(currentTask.expectedCompletionDate)
    : null
  const setStatusHandler = (status: AppTableTaskStatusEnum) => {
    setStatus(status)
    setFormData((prev) => ({ ...prev, status }))
    setIsDirty(true)
  }
  useEffect(() => {
    if (currentTask) {
      const {
        title,
        description,
        responsible,
        status,
        expectedCompletionDate,
      } = currentTask
      const data = {
        title,
        description,
        responsible: `${responsible?.id}`,
        status,
        expectedCompletionDate: expectedCompletionDate || '',
      }
      setStatus(status)

      formStateChangeHandler(data)
      setFormData({ ...data, followers: currentTask.followers })
    }
  }, [currentTask])
  return (
    <>
      <div className={'edit-task-disabled-form-block'}>
        <span className={'title'}>Название задачи</span>
        <span className={'content'}>{currentTask.title}</span>
      </div>
      {currentTask.description && (
        <div className={'edit-task-disabled-form-block'}>
          <span className={'title'}>Описание</span>
          <span className={'content'}>{currentTask.description}</span>
        </div>
      )}
      {currentTask.responsible && (
        <div className={'edit-task-disabled-form-block'}>
          <span className={'title'}>Исполнитель</span>
          <span className={'content'}>{currentTask.responsible.name}</span>
        </div>
      )}
      {expectedCompletionDate && (
        <div className={'edit-task-disabled-form-block'}>
          <span className={'title'}>Срок выполнения</span>
          <span className={'content'}>
            {moment(expectedCompletionDate).format('DD.MM.YYYY')}
          </span>
        </div>
      )}

      <div className={'edit-task-disabled-form-block'}>
        <div className={'edit-task-form-status-buttons-wrapper'}>
          <AppRadioButtonsBlock
            value={status}
            setValue={setStatusHandler}
            buttons={radioButtons}
            wrapperClassName={'edit-task-form-radio-wrapper'}
            buttonClassName={'edit-task-form-radio-button'}
          />
        </div>

        {currentTask.isImportant && (
          <AppCheckboxButton
            className="edit-task-form-important-button"
            active
            disabled
            text="Пометить как важное"
          />
        )}
      </div>
      {currentTask.followers.length > 0 && (
        <div className={'edit-task-disabled-form-block'}>
          <span className={'title'}>Список отслеживающих</span>
          <span className={'content'}>
            {`${currentTask.followers.map((follower) => follower.name)} `}
          </span>
        </div>
      )}
    </>
  )
}
