import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { InputValidation } from '../components/input/InputField'
import { controller } from './validationController'

export const useValidate = (
  value: string,
  onChange: (val: string) => void,
  required: boolean,
) => {
  const [error, setError] = useState(InputValidation.not)
  const [isTouched, setIsTouched] = useState(false)
  const [val, setVal] = useState(value)

  const validate = useCallback(() => {
    if (required) {
      val.trim()
        ? setError(InputValidation.not)
        : setError(InputValidation.error)
    } else {
      setError(InputValidation.not)
    }
  }, [val, required])

  const onChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target
    setVal(value)
    onChange(value)
    !isTouched && setIsTouched(true)
  }

  useEffect(() => {
    isTouched && validate()
  }, [validate, isTouched])

  useEffect(() => {
    if (!val) {
      const unSubscribe = controller.subscribe(validate)
      return unSubscribe
    }
  }, [validate, val])

  return { error, onChangeHandler, val }
}

export default useValidate
