import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CalculatorMemoized } from '../components/calculator/Calculator'
import { IStateNavPanelMobile } from '../widgets/header/HeaderWaiterMobile'
import HeaderWaiterPage from '../widgets/header/HeaderWaiterPage'
import { isGivenOutDish } from '../components/orderCard/MealItem/MealItemWaiter'
import OrderCardWaiter from '../components/orderCard/OrderCardWaiter'
import Popup from '../components/popup/Popup'
import { CookingStatus, IOrder, IOrderLine } from '../core/api/dto/OrderObjDto'
import {
  useGetAllOrdersQuery,
  useGetCurrentWeightQuery,
} from '../core/api/OrderApi'
import { useGetCurrentUserQuery } from '../core/api/UserApi'
import useFilterQuery from '../utils/useFilterQuery'

export enum Condition {
  Dimensions = 'Dimensions',
  CookStatus = 'CookStatus',
}

export const getOrders = (
  arr: IOrder[],
  isShowReadyDishes: boolean,
  condition: keyof typeof Condition = Condition.Dimensions,
) => {
  return arr.reduce((acc, el) => {
    if (isShowReadyDishes) {
      const res =
        condition === Condition.Dimensions
          ? el.orderLines.filter((item) =>
              isGivenOutDish(item.quantity, item.orderLineDimensions.length),
            )
          : el.orderLines.filter(
              (item) =>
                item.orderLineCookingStatuses?.cookingStatus ===
                CookingStatus.Cook_done,
            )

      res.length && acc.push({ ...el, orderLines: res })
    } else {
      const res =
        condition === Condition.Dimensions
          ? el.orderLines.filter(
              (item) =>
                !isGivenOutDish(item.quantity, item.orderLineDimensions.length),
            )
          : el.orderLines.filter(
              (item) =>
                item.orderLineCookingStatuses?.cookingStatus !==
                CookingStatus.Cook_done,
            )

      res.length && acc.push({ ...el, orderLines: res })
    }

    return acc
  }, [] as IOrder[])
}

export interface IStateNavPanel extends IStateNavPanelMobile {
  next: string | null
  previous: string | null
}

export interface IActiveDish {
  info: IOrderLine
  id: number
  orderRepresent: string
}

const Waiter = () => {
  const { data: currentUser } = useGetCurrentUserQuery()
  const refRoot = useRef<HTMLDivElement | null>(null)
  const { searchString } = useFilterQuery()
  const { data: allOrders } = useGetAllOrdersQuery(
    {
      queryString: searchString,
      shiftNum: currentUser?.userRoles.restaurant.shiftNum
        ? currentUser?.userRoles.restaurant.shiftNum
        : '',
    },
    {
      skip: !currentUser,
      pollingInterval: 5000,
    },
  )
  const [activeDish, setActiveDish] = useState<null | IActiveDish>(null)
  const { data, isError, isSuccess } = useGetCurrentWeightQuery(null, {
    pollingInterval: 1000,
    skip: !activeDish,
  })
  const handlerSetActiveDish = (el: IActiveDish) => {
    if (!isError && !data?.errorMsg) {
      setActiveDish(el)
    }
  }
  const [stateNavPanel, setStateNavPanel] = useState<IStateNavPanel>({
    next: null,
    previous: null,
    isShowReadyDishes: false,
    isUnwrapOrders: false,
  })

  useEffect(() => {
    if (allOrders) {
      setStateNavPanel({
        ...stateNavPanel,
        previous: allOrders.previous,
        next: allOrders.next,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrders])

  const orders = useMemo(() => {
    if (allOrders?.results) {
      return getOrders(allOrders.results, stateNavPanel.isShowReadyDishes)
    }

    return []
  }, [allOrders?.results, stateNavPanel.isShowReadyDishes])

  return (
    <div className="waiter-page">
      <HeaderWaiterPage
        contentRef={refRoot}
        stateNavPanel={stateNavPanel}
        setStateNavPanel={setStateNavPanel}
      />
      {allOrders?.results?.length ? (
        <div className="column" ref={refRoot}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 ">
                {orders.slice(0, 6).map((el) => (
                  <OrderCardWaiter
                    info={el}
                    key={el.id}
                    isUnwrapOrders={stateNavPanel.isUnwrapOrders}
                    isShowReadyDishes={stateNavPanel.isShowReadyDishes}
                    handlerSetActiveDish={handlerSetActiveDish}
                  />
                ))}
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 ">
                {orders.slice(6, 12).map((el) => (
                  <OrderCardWaiter
                    info={el}
                    key={el.id}
                    isUnwrapOrders={stateNavPanel.isUnwrapOrders}
                    isShowReadyDishes={stateNavPanel.isShowReadyDishes}
                    handlerSetActiveDish={handlerSetActiveDish}
                  />
                ))}
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 ">
                {orders.slice(12, 18).map((el) => (
                  <OrderCardWaiter
                    info={el}
                    key={el.id}
                    isUnwrapOrders={stateNavPanel.isUnwrapOrders}
                    isShowReadyDishes={stateNavPanel.isShowReadyDishes}
                    handlerSetActiveDish={handlerSetActiveDish}
                  />
                ))}
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 ">
                {orders.slice(18).map((el) => (
                  <OrderCardWaiter
                    info={el}
                    key={el.id}
                    isUnwrapOrders={stateNavPanel.isUnwrapOrders}
                    isShowReadyDishes={stateNavPanel.isShowReadyDishes}
                    handlerSetActiveDish={handlerSetActiveDish}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {activeDish && (
        <Popup>
          <CalculatorMemoized
            dishName={activeDish.info.dish.name}
            sourceId={activeDish.id}
            resetActiveDish={() => setActiveDish(null)}
            isSuccessWeight={isSuccess}
            currentWeight={data?.weight ? data.weight : 0}
            isBuffetPage={false}
            sourceName={activeDish.orderRepresent}
            orderLineId={activeDish.info.id}
          />
        </Popup>
      )}
    </div>
  )
}

export default Waiter
