import moment from 'moment'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import {
  CookingStatus,
  IOrder,
  StatusOrder,
} from '../../core/api/dto/OrderObjDto'
import { useUpdateOrderMutation } from '../../core/api/OrderApi'
import { deleteOrder } from '../../core/store/cook/cookSlice'
import { useAppDispatch } from '../../core/store/store'
import { compareProps, filterMealItems } from '../../utils'
import useScroll from '../../utils/useScroll'
import AppBtn, { AppBtnColor, AppBtnSize } from '../btn/AppBtn'
import MealItemCookMemoized from './MealItem/MealItemCook'

import StatusInfo from './StatusInfo'

export interface IOrderCardProps {
  info: IOrder
  isAllOrders?: boolean
}

const OrderCardCook = ({ info, isAllOrders }: IOrderCardProps) => {
  const [isUnwrapCard, setIsUnwrapCard] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const [updateOrder] = useUpdateOrderMutation()
  const scrollList = useScroll(ref)
  const dispatch = useAppDispatch()
  const changeCurrentStatus = useCallback(() => {
    if (!info.orderDateStart) {
      updateOrder({
        id: info.id,
        status: StatusOrder.Working,
        orderDateStart: moment().format(),
      })
    } else {
      const isDishAtWork = info.orderLines.find(
        (el) =>
          el.orderLineCookingStatuses &&
          el.orderLineCookingStatuses.cookingStatus !== CookingStatus.Waiting,
      )
      if (!isDishAtWork) {
        updateOrder({
          id: info.id,
          status: StatusOrder.Created,
          orderDateStart: null,
        })
        dispatch(deleteOrder(info.id))
      }
    }
  }, [dispatch, info.id, info.orderDateStart, info.orderLines, updateOrder])

  const scrollDown = useCallback(() => scrollList('down'), [scrollList])
  const scrollUp = useCallback(() => scrollList('up'), [scrollList])

  const getAllComments = useMemo(
    () =>
      info.orderLines.reduce((acc, el) => {
        if (el.comment) {
          acc += ` ${el.comment}`
        }
        return acc
      }, ''),
    [info.orderLines],
  )

  return (
    <div
      className={`card main-view cook ${
        isUnwrapCard && info.orderDateStart ? 'active' : ''
      } ${isUnwrapCard && !info.orderDateStart ? 'waiting' : ''}`}
    >
      <div
        className="wrapper-card"
        onClick={() => setIsUnwrapCard(!isUnwrapCard)}
      >
        <div className={`number ${info.orderRepresent.length > 4 ? 'sm' : ''}`}>
          {info.orderRepresent.slice(0, 20)}
        </div>
        <div
          className={`wrapper-info m-l-auto ${
            !info.orderDateStart ? 'await' : ''
          }`}
        >
          <StatusInfo title="Создан" time={info.orderDate} />
          {info.orderDateStart && (
            <StatusInfo title="В работе c" time={info.orderDateStart} />
          )}
        </div>
      </div>
      {isUnwrapCard && (
        <>
          <div className="details">
            <div className="clip">{info.waiter?.name}</div>
            <div className="btn-block">
              <AppBtn
                color={
                  !info.orderDateStart
                    ? AppBtnColor.outlineViolet
                    : AppBtnColor.whiteViolet
                }
                iconClassName="arrow-down"
                onClick={scrollDown}
              />
              <AppBtn
                color={
                  !info.orderDateStart
                    ? AppBtnColor.outlineViolet
                    : AppBtnColor.whiteViolet
                }
                iconClassName="arrow-up"
                onClick={scrollUp}
              />
            </div>
          </div>
          {info.orderLines.length ? (
            <>
              <div className="title-count">
                {isAllOrders ? 'Выдано' : 'Кол-во'}
              </div>
              <div className="meal-list" ref={ref}>
                {(isAllOrders
                  ? info.orderLines
                  : filterMealItems(info, false)
                ).map((el, idx) => (
                  <MealItemCookMemoized
                    info={el}
                    parentId={info.id}
                    key={`meal-item-${el.id}-${idx}`}
                    orderDateStart={info.orderDateStart}
                  />
                ))}
              </div>
              {getAllComments ? (
                <div className="order-info">{getAllComments}</div>
              ) : null}
              {isAllOrders ? null : (
                <AppBtn
                  color={
                    !info.orderDateStart
                      ? AppBtnColor.outlineViolet
                      : AppBtnColor.whiteViolet
                  }
                  sized={AppBtnSize.sm}
                  title={
                    !info.orderDateStart
                      ? 'Передать в работу'
                      : 'Вернуть в ожидание'
                  }
                  onClick={changeCurrentStatus}
                />
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  )
}

const OrderCardCookMemoized = memo(OrderCardCook, compareProps)

export default OrderCardCookMemoized
