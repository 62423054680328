import React, { useEffect } from 'react'

export const useOutsideClick = (
  ref: React.MutableRefObject<HTMLDivElement | null> | null,
  cb: () => void,
  active: boolean = true,
  triggerRef?: React.MutableRefObject<HTMLDivElement | null> | null,
) => {
  const outSideClickHandler = (e: any) => {
    e.stopPropagation()
    if (!ref?.current) return null

    const ignoreElements = [ref?.current]
    if (triggerRef?.current) {
      ignoreElements.push(triggerRef?.current)
    }
    if (!ignoreElements.some((ref) => ref.contains(e.target))) {
      cb()
    }
  }

  useEffect(() => {
    if (active) {
      document.addEventListener('click', outSideClickHandler, false)
      document.addEventListener('touchend', outSideClickHandler, false)
    }
    return () => {
      if (active) {
        document.removeEventListener('click', outSideClickHandler, false)
        document.removeEventListener('touchend', outSideClickHandler, false)
      }
    }
  }, [active])
}
