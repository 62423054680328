import React, { useCallback } from 'react'
import Popup, { PopupSize } from '../popup/Popup'

export interface IAppImagePopup {
  active: boolean
  photo: string | null
  title: string | null
}

interface IAppImagePopupProps {
  title: string
  img: string
  setActive: () => void
}

export const AppImagePopup = ({
  img,
  title,
  setActive,
}: IAppImagePopupProps) => {
  const outSideClickHandler = useCallback(() => {
    setActive()
  }, [])

  return (
    <Popup
      size={PopupSize.base}
      closePopup={outSideClickHandler}
      borderRadiusMin
      className="mar-auto"
    >
      <div className="img-popup-wrap">
        <div className="img-popup-title">
          <span>{title}</span>
          <div className="close-popup-block" onClick={outSideClickHandler}>
            <i className="an-ico an-ico-close" />
          </div>
        </div>
        <img src={img} alt={title} />
      </div>
    </Popup>
  )
}
