import React from 'react'
import AppBtn, { AppBtnColor } from '../btn/AppBtn'

interface IPropsWarning {
  getLatestVersion: () => void
}

const Warning = ({ getLatestVersion }: IPropsWarning) => {
  return (
    <>
      <div className="col-8">
        <div className="warning">
          Внимание! Это старая версия технологической карты
        </div>
      </div>
      <div className="col-4">
        <div className="btn-block">
          <AppBtn
            color={AppBtnColor.violetWhite}
            title="Перейти к актуальной карте"
            onClick={getLatestVersion}
          />
        </div>
      </div>
    </>
  )
}

export default Warning
