import { AppTitlePage } from 'components/AppTitlePage'
import { AppBtnColor } from 'components/btn/AppBtn'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useGetTasksQuery,
  useGetUnreadTaskCountQuery,
} from '../../../core/api/TaskApi'
import { useParamsControl } from '../../../utils/useParamsControl'
import { ITasksRequestDto } from '../../../core/api/dto/TaskDto'
import { useNavigate } from 'react-router'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { TaskListTable } from './TaskListTable'
import { TaskListFilter } from './TaskListFilter'
import { useAppDispatch } from '../../../core/store/store'
import { setNotificationCount } from '../../../core/store/notifications/notificationsSlice'
import { useRefetchUnread } from '../../../utils/useRefetchUnread'

export const useUnreadTask = (): number => {
  const dispatch = useAppDispatch()
  const { data: unreadTask } = useGetUnreadTaskCountQuery(null, {
    refetchOnMountOrArgChange: true,
  })
  useEffect(() => {
    dispatch(setNotificationCount(unreadTask?.count))
  }, [unreadTask?.count, dispatch])
  return useMemo(() => unreadTask?.count || 0, [unreadTask])
}

export const TaskListPage: React.FC = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const pageParams = useParamsControl<ITasksRequestDto, keyof ITasksRequestDto>(
    {
      withPagination: true,
      whiteList: ['statuses', 'ordering', 'ids'],
    },
  )

  const { data: taskList, refetch } = useGetTasksQuery(
    { ...pageParams },
    { refetchOnMountOrArgChange: true },
  )
  useRefetchUnread(refetch)

  const onAddTaskClick = useCallback(
    () => navigate(`/${AllRoutes.taskList.path}/${AllRoutes.addTask.path}`),
    [navigate],
  )

  const clearFilterHandler = useCallback(() => {
    setSearchParams('limit=40')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isFiltersOpen, setIsFiltersOpen] = useState(true)

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Добавить задачу',
        onClick: onAddTaskClick,
        btnIco: 'add',
        color: AppBtnColor.outlineViolet,
      },
      {
        btnTitle: 'Сбросить фильтр',
        onClick: clearFilterHandler,
        color: AppBtnColor.extraVioletViolet,
      },
    ],
    [onAddTaskClick, clearFilterHandler],
  )

  if (!taskList) return null

  return (
    <div className="width-for-page-wrapper">
      <AppTitlePage
        title="Задачи"
        actions={actions}
        isFiltersOpen={isFiltersOpen}
        setIsFiltersOpen={setIsFiltersOpen}
      />
      <TaskListFilter isFiltersOpen={isFiltersOpen} />
      <TaskListTable taskList={taskList} />
    </div>
  )
}
