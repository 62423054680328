import React, { ReactNode, useRef, useState } from 'react'
import { useOutsideClick } from '../../utils/useOutsideClick'

interface AppPopoverProps {
  trigger: ReactNode | string
  children: ReactNode
}

export const AppPopover = ({ trigger, children }: AppPopoverProps) => {
  const popoverRef = useRef<HTMLDivElement | null>(null)
  const [active, setActive] = useState(false)

  const onPopoverClick = () => setActive((prevState) => !prevState)
  useOutsideClick(popoverRef, () => setActive(false))
  return (
    <div className="popover-wrap" onClick={onPopoverClick} ref={popoverRef}>
      {trigger}
      {active && (
        <div
          className="popover-content"
          style={{ top: (popoverRef.current?.clientHeight || 0) + 10 }}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      )}
    </div>
  )
}
