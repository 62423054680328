import React, { useCallback, useRef, useState, memo, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { changeField } from '../../core/store/buffet/buffetSlice'
import { useAppDispatch, useAppSelector } from '../../core/store/store'
import { scrollDown, scrollUp } from '../../utils'
import useFilterQuery from '../../utils/useFilterQuery'
import AppBtn, {
  AppBtnColor,
  AppBtnRadius,
  AppBtnSize,
  IconPosition,
} from '../btn/AppBtn'
import Checkbox from '../checkbox/Checkbox'
import InputField, { InputSize } from '../input/InputField'
import KeyboardWrapper, { Lang } from '../keyboardWrapper/KeyboardWrapper'
import { comparePropsFixedPanel, IPropsFixedPanel } from './FixedPanel'

export interface IPropsFixedPanelAddition extends IPropsFixedPanel {
  title?: string
}

const FixedSearchPanel = ({
  refChild,
  refRoot,
  refetch,
  title,
}: IPropsFixedPanelAddition) => {
  const [keyboardVisibility, setKeyboardVisibility] = useState(false)
  const navigate = useNavigate()
  const keyboard = useRef<any>(null)
  const { setNewSearchParams, getCurrentSearchParamValue } = useFilterQuery()
  const { isFullModeDish, isFullModeAdditionDish, isKeyboard } = useAppSelector(
    (state) => state.buffet,
  )
  const dispatch = useAppDispatch()

  const onChangeHandler = (val: string) => {
    if (val.length <= 50) {
      setNewSearchParams({
        searchParamName: 'search',
        value: val,
      })

      if (keyboardVisibility) {
        keyboard.current.setInput(val)
      }
    }
  }

  const onChangeKeyboard = (val: string) =>
    setNewSearchParams({
      searchParamName: 'search',
      value: val,
    })

  const handlerScrollUp = useCallback(() => {
    scrollUp(refRoot, refChild.current!.children[0].clientHeight)
  }, [refRoot, refChild])

  const handlerScrollDown = useCallback(() => {
    scrollDown(refRoot, refChild.current!.children[0].clientHeight)
  }, [refRoot, refChild])

  useEffect(() => {
    if (
      getCurrentSearchParamValue('search') &&
      !keyboard.current?.getInput() &&
      keyboardVisibility
    ) {
      keyboard.current.setInput(getCurrentSearchParamValue('search'))
    }
  }, [getCurrentSearchParamValue, keyboardVisibility])

  const handlerOnChangeMode = () => {
    dispatch(
      changeField(
        title
          ? { field: 'isFullModeDish', value: !isFullModeDish }
          : {
              field: 'isFullModeAdditionDish',
              value: !isFullModeAdditionDish,
            },
      ),
    )
  }

  const showKeyboard = () => {
    if (isKeyboard) {
      setKeyboardVisibility(true)
    }
  }

  return (
    <>
      <div className={`fixed-panel ${title ? 'dish-by-workshop' : ''}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="search-box">
                <AppBtn
                  sized={AppBtnSize.sm}
                  color={AppBtnColor.redWhite}
                  radius={AppBtnRadius.round}
                  iconClassName="arrow"
                  onClick={() => navigate(`../`)}
                />
                <InputField
                  placeholder="Поиск по названию блюда"
                  sized={InputSize.sm}
                  iconPosition={IconPosition.right}
                  iconName="close"
                  onClick={() => onChangeHandler('')}
                  value={
                    getCurrentSearchParamValue('search') !== null
                      ? getCurrentSearchParamValue('search')!
                      : ''
                  }
                  onFocus={showKeyboard}
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="wrapper-action">
                <Checkbox
                  isRadio={true}
                  name="radio"
                  text="полный размер"
                  value={title ? isFullModeDish : isFullModeAdditionDish}
                  onChange={handlerOnChangeMode}
                />
                <AppBtn
                  iconClassName="refresh"
                  sized={AppBtnSize.sm}
                  color={AppBtnColor.violetWhite}
                  onClick={refetch}
                />
                <AppBtn
                  iconClassName="arrow-up"
                  sized={AppBtnSize.sm}
                  color={AppBtnColor.violetWhite}
                  onClick={handlerScrollUp}
                />
                <AppBtn
                  iconClassName="arrow-down"
                  sized={AppBtnSize.sm}
                  color={AppBtnColor.violetWhite}
                  onClick={handlerScrollDown}
                />
              </div>
            </div>
          </div>
          {title && <h3 className="text-center">{title}</h3>}
        </div>
      </div>
      {keyboardVisibility && (
        <div className="keyboard-elements">
          <AppBtn
            iconClassName="close"
            radius={AppBtnRadius.round}
            color={AppBtnColor.violetWhite}
            onClick={() => setKeyboardVisibility(!keyboardVisibility)}
          />
          <KeyboardWrapper
            onChange={onChangeKeyboard}
            keyboard={keyboard}
            lang={Lang.RU}
          />
        </div>
      )}
    </>
  )
}

export const FixedSearchPanelMemoized = memo(
  FixedSearchPanel,
  comparePropsFixedPanel,
)
