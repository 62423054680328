import React from 'react'
import { ICreateDish } from '../../core/api/dto/OrderObjDto'
import { useAppSelector } from '../../core/store/store'
import AppBtn, { AppBtnColor, AppBtnSize, IconPosition } from '../btn/AppBtn'

interface IPropsAdditionBuffetCard {
  info: ICreateDish
  setActiveDish: () => void
}

const AdditionBuffetCard = ({
  info,
  setActiveDish,
}: IPropsAdditionBuffetCard) => {
  const { isFullModeAdditionDish } = useAppSelector((state) => state.buffet)
  return (
    <div
      className={`meal-card buffet ${isFullModeAdditionDish ? '' : 'sm'}`}
      onClick={setActiveDish}
    >
      <div className="wrapper-info">
        <div className="info">
          <div className="content">{info.name}</div>
        </div>
        {isFullModeAdditionDish && (
          <AppBtn
            title="Добавить"
            color={AppBtnColor.outlineGrayLight}
            iconClassName="arrow-long"
            iconPosition={IconPosition.right}
            sized={AppBtnSize.sm}
          />
        )}
      </div>
    </div>
  )
}

export default AdditionBuffetCard
