import React, { useEffect, useState } from 'react'
import { AppInput } from '../../../../components/AppInput/AppInput'
import Popup from '../../../../components/popup/Popup'
import { AppScrollWrapperNew } from '../../../../widgets/AppScrollWrapper/AppScrollWrapper'
import AppBtn, {
  AppBtnColor,
  AppBtnSize,
} from '../../../../components/btn/AppBtn'
import {
  useGetPlanMenuTemplateDetailQuery,
  useGetPlanMenuTemplateListQuery,
} from '../../../../core/api/PlanMenuTemplateApi'
import { IPlanMenuTemplateDto } from '../../../../core/api/dto/PlanMenuTemplateDto'
import Checkbox from '../../../../components/checkbox/Checkbox'
import { IOrderLine } from '../../../../core/api/dto/PlanMenuDto'
import { useTranslation } from 'react-i18next'

function mergeAndSumArrays(arr1: IOrderLine[], arr2: IOrderLine[]) {
  const merged: IOrderLine[] = arr1.concat(arr2)
  return merged.reduce((acc: IOrderLine[], obj) => {
    const existingObj = acc.find((item) => item.dish.id === obj.dish.id)
    if (existingObj) {
      const updatedObj = {
        ...existingObj,
        quantity: (Number(existingObj.quantity) + Number(obj.quantity)).toFixed(
          3,
        ),
      }
      return acc.map((item) =>
        item.dish.id === obj.dish.id ? updatedObj : item,
      )
    } else {
      acc.push(obj)
      return acc
    }
  }, [])
}

interface IPlanMenuTemplatePopup {
  onClose: () => void
  setPlanMenuTableData: React.Dispatch<React.SetStateAction<IOrderLine[]>>
  setNumberOfPersons: ((propName: string, value: string) => void) | null
  setIsDirty?: (value: boolean) => void
}

export const PlanMenuTemplatePopup = ({
  onClose,
  setPlanMenuTableData,
  setNumberOfPersons,
  setIsDirty,
}: IPlanMenuTemplatePopup) => {
  const { t } = useTranslation()
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState('')
  const [loadMore, setLoadMore] = useState(false)
  const { data: planMenuTemplateList } = useGetPlanMenuTemplateListQuery({
    limit: 20,
    offset,
    search,
  })
  const [planMenuTemplateListData, setPlanMenuTemplateListData] = useState<
    IPlanMenuTemplateDto[]
  >([])
  const [currentTemplate, setCurrentTemplate] =
    useState<IPlanMenuTemplateDto | null>(null)
  const { data: templateData } = useGetPlanMenuTemplateDetailQuery(
    {
      id: currentTemplate?.id || 0,
    },
    { skip: !currentTemplate },
  )

  useEffect(() => {
    if (!!planMenuTemplateList && offset === 0) {
      setPlanMenuTemplateListData(planMenuTemplateList.results)
    }
    if (!!planMenuTemplateList && offset !== 0 && loadMore) {
      setPlanMenuTemplateListData((prevState) => [
        ...prevState,
        ...planMenuTemplateList.results,
      ])
      setLoadMore(false)
    }
  }, [planMenuTemplateList])

  const showElseHandler = () => {
    setOffset((prevState) => prevState + 20)
    setLoadMore(true)
  }
  const addTemplateHandler = () => {
    if (!!templateData) {
      setPlanMenuTableData((prev) => {
        return mergeAndSumArrays([...prev], templateData.planMenuPatternLines)
      })
      setNumberOfPersons &&
        setNumberOfPersons('numberOfPersons', `${templateData.numberOfPersons}`)
      setIsDirty && setIsDirty(true)
      onClose()
    }
  }
  return (
    <Popup closePopup={onClose}>
      <div className="stations-list-popup">
        <span className="title-stations-popup">
          {t('Выбор шаблона план-меню')}
        </span>
        <AppInput
          label="Поиск"
          placeholder="Ввести название шаблона"
          onChange={setSearch}
          value={search}
          fullWidth
          ico="search"
          border
        />
        <div className="stations-list">
          <AppScrollWrapperNew>
            {planMenuTemplateListData.map((planMenuTemplate) => (
              <div
                className="stations-item"
                key={`st-item-${planMenuTemplate.id}`}
                onClick={() => setCurrentTemplate(planMenuTemplate)}
              >
                <Checkbox
                  name={planMenuTemplate.name}
                  value={currentTemplate?.id === planMenuTemplate.id}
                  text={planMenuTemplate.name}
                  onChange={() => {}}
                  isRadioView
                />
              </div>
            ))}
            {!!planMenuTemplateList?.next && (
              <AppBtn
                sized={AppBtnSize.fullWidth}
                color={AppBtnColor.violetWhite}
                title={'Показать ещё'}
                onClick={showElseHandler}
              />
            )}
          </AppScrollWrapperNew>
        </div>
        <div className="buttons-block mt-20">
          <AppBtn
            title="Отменить"
            color={AppBtnColor.outlineViolet}
            sized={AppBtnSize.md}
            iconClassName={'close'}
            onClick={onClose}
          />
          <AppBtn
            title="Выбрать"
            iconClassName={'done'}
            color={AppBtnColor.violetWhite}
            sized={AppBtnSize.md}
            onClick={addTemplateHandler}
            disabled={!templateData}
          />
        </div>
      </div>
    </Popup>
  )
}
