import { IBuffetDishWithImplInfoDto } from '../../../../core/api/dto/BuffetDishDto'
import { getTimeFromMins } from '../../DishesPage/CreateDishPage'
import React, { useState } from 'react'
import { AddFrozePopup } from './AddFrozePopup'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { AllRoutes } from '../../../../core/routes/AllRoutes'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../core/store/store'
import {
  setDimensionCount,
  setFetchError,
  setTempCount,
} from '../../../../core/store/connection/connectionSlice'

interface IDishItemProps {
  dish: IBuffetDishWithImplInfoDto
  onOpenPopup?: (id: IBuffetDishWithImplInfoDto) => void
}

export const DishItem = ({ dish, onOpenPopup }: IDishItemProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const orderIds = searchParams.get('ids')?.split(',')
  const [showPopup, setShowPopup] = useState(false)

  const onDishClick = () => {
    dispatch(setFetchError(''))
    dispatch(setDimensionCount(0))
    dispatch(setTempCount(0))
    if (!!onOpenPopup) {
      onOpenPopup?.(dish)
    } else if (orderIds && orderIds.length > 1 && !onOpenPopup) {
      navigate(
        `/${AllRoutes.planMenuIssuing.path}/${AllRoutes.choosePlanMenuIssuingDetail.path}/?ids=${orderIds}&dish=${dish.id}`,
      )
      return
    }
    if (!!orderIds && orderIds.length === 1 && !onOpenPopup) {
      navigate(`/${AllRoutes.planMenuIssuing.path}/${orderIds}/${dish.id}/`)
    }
  }
  const closePopupHandler = () => setShowPopup(false)

  return (
    <React.Fragment>
      {showPopup && (
        <AddFrozePopup dishId={dish.id} onClose={closePopupHandler} />
      )}
      <div className="dish-item" onClick={onDishClick}>
        <div className="dish-item-header">
          <div className="given-away">
            <span>{t('ВЫДАНО')}: </span>
            <span>
              <b>
                {dish.lastDimensionTime
                  ? moment(dish.lastDimensionTime).format('HH:mm')
                  : '---'}
              </b>
            </span>
          </div>
          {!dish.planned && (
            <div className="status error">{t('незапланированное')}</div>
          )}
        </div>
        <div className="title-wrap">
          <div className="title-block">
            <div className="category">
              {dish?.category?.name || dish?.categoryRepresent || '---'}
            </div>
            <div className="title clip-base">{dish.name}</div>
          </div>
          <div className="photo-block">
            <img
              src={
                dish?.image ||
                dish?.imageOnS3 ||
                '../../assets/images/dish-fake.png'
              }
              alt=""
            />
          </div>
        </div>
        <div className="realization-time">
          <span>{t('Время на реализацию')}</span>
          <span className="time">
            {dish.dimImplementationTime
              ? getTimeFromMins(dish.dimImplementationTime)
              : '---'}
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}
