import React from 'react'
import useFilterQuery from '../../utils/useFilterQuery'

export interface ISortingItem {
  iconName?: string
  value: string
  reverseValue?: string
  name: string
}

export interface ISortingItemProps {
  info: ISortingItem
  sortingParamName: string
  isDefaultSort: boolean
}

const SortingItem = ({
  info: { value, name, reverseValue, iconName },
  sortingParamName,
  isDefaultSort,
}: ISortingItemProps) => {
  const { setNewSearchParams, getCurrentSearchParamValue } = useFilterQuery()
  const activeSort = getCurrentSearchParamValue(sortingParamName)

  const onChangeSorting = (name: string) => {
    setNewSearchParams({
      searchParamName: sortingParamName,
      value: name,
    })
  }
  const changeSorting = () => {
    if (!reverseValue) {
      onChangeSorting(name)
    } else {
      changeSortingWithDirection()
    }
  }
  const changeSortingWithDirection = () => {
    if (!activeSort && !isDefaultSort) {
      onChangeSorting(name)
    } else if (!activeSort && isDefaultSort) {
      onChangeSorting(`-${name}`)
    } else if (activeSort === name) {
      onChangeSorting(`-${name}`)
    } else if (activeSort === `-${name}`) {
      onChangeSorting(name)
    } else if (activeSort && !activeSort.includes(name)) {
      onChangeSorting(name)
    } else {
      onChangeSorting(`-${name}`)
    }
  }

  return (
    <div
      onClick={changeSorting}
      className={`item-sort ${
        activeSort && activeSort.includes(name) ? 'active' : ''
      }`}
    >
      {iconName && <i className={`an-ico an-ico-${iconName}`} />}
      <span className="text">
        {activeSort === `-${name}` ? reverseValue : value}
      </span>
    </div>
  )
}

export default SortingItem
