import React, { useCallback, useMemo, useState } from 'react'
import { AppTitlePage } from 'components/AppTitlePage'
import { AppBtnColor } from 'components/btn/AppBtn'
import { EditTaskForm, ITaskFormData } from './EditTaskForm/EditTaskForm'
import { EditTaskCommentsBlock } from './EditTaskCommentsBlock/EditTaskCommentsBlock'
import { useNavigate, useParams } from 'react-router'
import {
  useCreateTaskMutation,
  useGetTaskByIdQuery,
  useGetTaskObjectsQuery,
  useUpdateTaskMutation,
} from '../../../../core/api/TaskApi'
import { TasksObjectTable } from './TasksObjectTable/TasksObjectTable'
import { useGetCurrentUserQuery } from 'core/api/UserApi'
import { AppTableTaskStatusEnum } from 'components/AppTable/AppTableStatus/AppTableStatus'
import { ICreateTaskDto } from '../../../../core/api/dto/TaskDto'
import {
  AppNotification,
  NotificationType,
} from '../../../../components/notification/Notification'
import { EditTaskDisabledForm } from './EditTaskDisabledForm/EditTaskDisabledForm'
import { AllRoutes } from '../../../../core/routes/AllRoutes'
import { AppLoader, LoaderType } from '../../../../components/AppLoader'
import { BlockCbPopup } from '../../components/BlockCbPopup'
import { AppTabsBlock } from '../../../../UiKitComponents/AppTabsBlock'

export enum TaskDetailTab {
  'main' = 'main',
  'list' = 'list',
}

export const EditTaskPage: React.FC = () => {
  const { taskId } = useParams()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<TaskDetailTab>(TaskDetailTab.main)
  const [formData, setFormData] = useState<ITaskFormData | null>(null)
  const [isError, setIsError] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [isBlockCb, setIsBlockCb] = useState(false)

  const {
    data: currentTask,
    isLoading: isLoadingTask,
    refetch,
  } = useGetTaskByIdQuery(
    { id: Number(taskId || 0) },
    { skip: !taskId, refetchOnMountOrArgChange: true },
  )
  const { data: taskObjects } = useGetTaskObjectsQuery(
    { task: Number(taskId || 0) },
    { skip: !taskId },
  )

  const [createTask, { isLoading: isLoadingCreateTask }] =
    useCreateTaskMutation()
  const [updateTask, { isLoading: isLoadingUpdateTask }] =
    useUpdateTaskMutation()

  const onSaveHandler = useCallback(() => {
    if (!formData?.title) {
      setIsError(true)
    }
    if (formData && formData?.title) {
      const {
        title,
        description,
        isImportant,
        responsible,
        status,
        expectedCompletionDate,
        followers,
      } = formData
      const followersIds = followers?.map(({ id }) => id) || []

      const newTask: ICreateTaskDto = {
        title: title || '',
        description: description || '',
        isImportant: !!isImportant,
        responsible: Number(responsible) || null,
        status: status || AppTableTaskStatusEnum.DRAFT,
        expectedCompletionDate: expectedCompletionDate || null,
        followers: followersIds,
      }
      if (taskId) {
        updateTask({ id: Number(taskId), newTask })
          .unwrap()
          .then(() => {
            AppNotification({
              msg: 'Задача успешно обновлена!',
              type: NotificationType.success,
            })
            setIsDirty(false)
            navigate(`/${AllRoutes.taskList.path}/`)
          })
      } else {
        createTask(newTask)
          .unwrap()
          .then(() => {
            AppNotification({
              msg: 'Задача успешно создана!',
              type: NotificationType.success,
            })
            setIsDirty(false)
            navigate(`/${AllRoutes.taskList.path}/`)
          })
      }
    }
  }, [formData, taskId, updateTask, createTask, navigate])

  const actions = useMemo(
    () => [
      {
        btnTitle: taskId ? 'Сохранить' : 'Создать',
        btnIco: 'save',
        onClick: onSaveHandler,
        color: AppBtnColor.violetWhite,
        disabled: isLoadingUpdateTask || isLoadingCreateTask,
      },
    ],
    [isLoadingUpdateTask, isLoadingCreateTask, onSaveHandler, taskId],
  )

  const { data: currentUser } = useGetCurrentUserQuery()

  const isCreatorCurrentUser: boolean =
    !!currentUser && !!currentTask && currentUser.id === currentTask.creator.id

  const isResponsibleCurrentUser: boolean =
    !!currentUser &&
    !!currentTask &&
    !!currentTask.responsible &&
    !isCreatorCurrentUser &&
    currentUser.id === currentTask.responsible.id

  const isInputDisabled: boolean =
    (isCreatorCurrentUser &&
      currentTask!.status === AppTableTaskStatusEnum.DONE) ||
    !isCreatorCurrentUser

  const closeBlockPopup = () => setIsBlockCb(false)
  const onActiveBlockPopup = () => {
    setIsDirty(false)
    setIsBlockCb(false)
    setActiveTab(TaskDetailTab.list)
  }
  const setActiveTabHandler = (value: TaskDetailTab) => {
    if (value === TaskDetailTab.main && currentTask) {
      refetch()
    }
    setActiveTab(value)
    if (isDirty) setIsBlockCb(true)
  }

  return (
    <>
      {isLoadingTask && <AppLoader loaderType={LoaderType.main} />}
      {isDirty && isBlockCb && (
        <BlockCbPopup
          closePopup={closeBlockPopup}
          onCbClick={onActiveBlockPopup}
        />
      )}
      <div className={'width-for-page-wrapper'}>
        <AppTitlePage
          title="Задачи"
          actions={
            activeTab === TaskDetailTab.main &&
            currentTask?.status !== AppTableTaskStatusEnum.DONE
              ? actions
              : undefined
          }
          goBack
          goBackPath={'/task-list'}
          isDirty={isDirty}
        />
        <AppTabsBlock<TaskDetailTab>
          tabs={[
            { name: 'Заголовок', value: TaskDetailTab.main },
            { name: 'Список', value: TaskDetailTab.list },
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTabHandler}
          className="mt-15"
        >
          {activeTab === TaskDetailTab.main && (
            <div className="grid-2-column">
              <div className={'edit-task-tabs-content-form'}>
                {isInputDisabled && currentTask ? (
                  <EditTaskDisabledForm
                    isResponsibleCurrentUser={isResponsibleCurrentUser}
                    currentTask={currentTask}
                    isCreatorCurrentUser={isCreatorCurrentUser}
                    setFormData={setFormData}
                    setIsDirty={setIsDirty}
                  />
                ) : (
                  <EditTaskForm
                    isCreatorCurrentUser={isCreatorCurrentUser}
                    currentTask={currentTask}
                    setFormData={setFormData}
                    isError={isError}
                    setIsDirty={setIsDirty}
                  />
                )}
              </div>
              <div className={'edit-task-tabs-content-comments'}>
                <EditTaskCommentsBlock
                  id={taskId ? Number(taskId) : undefined}
                  isCommentInputVisible={
                    !!currentTask &&
                    !(
                      currentTask.status === AppTableTaskStatusEnum.DONE ||
                      currentTask.status === AppTableTaskStatusEnum.DRAFT
                    )
                  }
                  isDraft={currentTask?.status === AppTableTaskStatusEnum.DRAFT}
                />
              </div>
            </div>
          )}
          {activeTab === TaskDetailTab.list && taskObjects && taskId && (
            <TasksObjectTable taskObjects={taskObjects} taskId={+taskId} />
          )}
        </AppTabsBlock>
      </div>
    </>
  )
}
