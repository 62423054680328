import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IRestaurant } from '../../core/api/dto/RestaurantsObjDto'
import { useGetRestaurantByIdQuery } from '../../core/api/RestaurantApi'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import { AllRoutes } from '../../core/routes/AllRoutes'
import useFilterQuery from '../../utils/useFilterQuery'
import InputField, {
  IconPosition,
  InputSize,
} from '../../components/input/InputField'
import Select from '../../components/select/Select'

const HeaderChefPage = () => {
  const [restaurant, setRestaurant] = useState({} as IRestaurant)
  const { data: currentUser, isSuccess } = useGetCurrentUserQuery()
  const navigate = useNavigate()
  const { technologicalCatalogRestaurantId } = useParams()
  const { data: currentRestaurant } = useGetRestaurantByIdQuery(
    isSuccess ? String(currentUser.userRoles.restaurant.id) : '',
    { skip: !currentUser },
  )
  const { setNewSearchParams, getCurrentSearchParamValue } = useFilterQuery()
  const onChangeHandler = useCallback(
    (val: string) => {
      setNewSearchParams({
        searchParamName: 'offset',
        value: '0',
      })
      setNewSearchParams({
        searchParamName: 'search',
        value: val,
      })
    },
    [setNewSearchParams],
  )

  useEffect(() => {
    if (
      technologicalCatalogRestaurantId &&
      !restaurant.name &&
      currentRestaurant?.name
    ) {
      setRestaurant(currentRestaurant)
    } else if (
      restaurant.name &&
      Number(technologicalCatalogRestaurantId) !== restaurant.id
    ) {
      navigate(`${restaurant.id}`)
    }
  }, [
    restaurant.name,
    technologicalCatalogRestaurantId,
    currentRestaurant,
    restaurant.id,
    navigate,
  ])

  useEffect(() => {
    if (!technologicalCatalogRestaurantId && currentRestaurant?.name) {
      setRestaurant(currentRestaurant)
    }
  }, [currentRestaurant, technologicalCatalogRestaurantId])

  return (
    <header className="header-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-6 col-xl-12">
            <InputField
              placeholder="Поиск по тех. картам"
              sized={InputSize.md}
              iconName="search"
              iconPosition={IconPosition.right}
              value={
                getCurrentSearchParamValue('search') !== null
                  ? getCurrentSearchParamValue('search')!
                  : ''
              }
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-xxl-4 col-xl-8 col-md-8">
            <Select
              options={currentRestaurant ? [currentRestaurant] : []}
              value={restaurant}
              onChange={setRestaurant}
              name="name"
              id="id"
              disabled={true}
            />
          </div>
          <div className="col-xxl-2 col-xl-4 col-md-4">
            <Link to={`${AllRoutes.user.path}`} className="exit">
              {currentUser && <span>{currentUser.name}</span>}
              <i className="an-ico an-ico-go-out" />
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderChefPage
