import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import moment from 'moment'
import { filterOrders } from '../../utils'
import { api, LIMIT } from './BaseApi'
import {
  ICreateCookDimension,
  TCreateDimension,
  ICurrentWeight,
  IOrder,
  IOrdersResponseDto,
  OrderType,
  StatusOrder,
  IOrdersDishTemperatureResponseDto,
  ICorrespondentTypeResponseDto,
  ICorrespondentTypeRequestDto,
  IResponseModel,
  IRequestTrainModel,
} from './dto/OrderObjDto'

interface IPropsUpdateOrder {
  status: keyof typeof StatusOrder
  id: number
  orderDateStart?: string | null
  orderDateEnd?: string | null
}

interface IPropsGetOrdersByDate {
  date: string
  searchString: string
}

export enum OrderingByDate {
  ORDER_DATE_START = 'order_date_start',
  ORDER_DATE_START_TIME = 'order_date_start__time',
  ORDER_CREATED_DATE = 'order_date',
}

export const orderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.query<
      IOrdersResponseDto,
      { queryString: string; shiftNum: string }
    >({
      query: ({ queryString, shiftNum }) => ({
        url: `/order/?${queryString}`,
        params: {
          status: `${StatusOrder.Created},${StatusOrder.Working}`,
          order_type: OrderType.Regular,
          limit: LIMIT.waiter,
          shift_num: shiftNum,
        },
      }),
      transformResponse: (response: IOrdersResponseDto) => {
        return { ...response, results: filterOrders(response.results) }
      },
      providesTags: ['Order', 'PlanMenu'],
    }),
    getAllOrdersByWaiter: builder.query<
      IOrdersResponseDto,
      { email: string; shiftNum: string }
    >({
      query: ({ email, shiftNum }) => ({
        url: `/order/`,
        params: {
          status: `${StatusOrder.Created},${StatusOrder.Working}`,
          order_type: OrderType.Regular,
          limit: LIMIT.base,
          waiter_email: email,
          shift_num: shiftNum,
        },
      }),
      transformResponse: (response: IOrdersResponseDto) => {
        return { ...response, results: filterOrders(response.results) }
      },
      providesTags: ['Order', 'PlanMenu'],
    }),
    getAllOrdersBuffet: builder.query<IOrdersResponseDto, null>({
      query: () => ({
        url: `/order/get_simple_orders/`,
        params: {
          status: `${StatusOrder.Created},${StatusOrder.Working}`,
          order_type: OrderType.Buffet,
          order_current_datetime: moment().format(),
          ordering: OrderingByDate.ORDER_DATE_START_TIME,
          archived: '0',
        },
      }),
      transformResponse: (
        response: IOrdersResponseDto,
        meta: FetchBaseQueryMeta,
        _: any,
      ) => {
        return { ...response, results: response.results.slice(0, 2) }
      },
      providesTags: ['Order', 'PlanMenu'],
    }),
    getAllOrdersByDate: builder.query<IOrder[], IPropsGetOrdersByDate>({
      query: ({ date, searchString }) => ({
        url: `/order/`,
        params: {
          limit: LIMIT.base,
          order_created_at: date,
          dish: searchString,
        },
      }),
      transformResponse: (response: IOrdersResponseDto) => {
        return filterOrders(response.results)
      },
      providesTags: ['Order', 'PlanMenu'],
    }),
    getWorkingOrders: builder.query<IOrder[], string>({
      query: (shiftNum) => ({
        url: `/order/get_orders_for_current_user/`,
        params: {
          limit: LIMIT.base,
          status: StatusOrder.Working,
          order_type: OrderType.Regular,
          shift_num: shiftNum,
          ordering: OrderingByDate.ORDER_DATE_START,
        },
      }),
      transformResponse: (response: IOrder[]) => {
        return filterOrders(response)
      },
      providesTags: ['Order', 'PlanMenu'],
    }),
    getCreatedOrders: builder.query<IOrder[], string>({
      query: (shiftNum) => ({
        url: `/order/get_orders_for_current_user/`,
        params: {
          limit: LIMIT.base,
          status: StatusOrder.Created,
          order_type: OrderType.Regular,
          ordering: OrderingByDate.ORDER_CREATED_DATE,
          shift_num: shiftNum,
        },
      }),
      transformResponse: (response: IOrder[]) => {
        return filterOrders(response)
      },
      providesTags: ['Order', 'PlanMenu'],
    }),
    getOrderById: builder.query<IOrder, string>({
      query: (id) => ({
        url: `/order/${id}/`,
      }),
      providesTags: ['Order'],
    }),
    getCurrentWeight: builder.query<ICurrentWeight, null | void>({
      query: () => ({
        url: `/order/get_weight/now/`,
      }),
      providesTags: ['Order'],
      keepUnusedDataFor: 0,
    }),
    setZeroWeight: builder.query<{ status: string }, void>({
      query: () => ({
        url: `/order/set_zero_weight/`,
      }),
      providesTags: ['Order'],
    }),
    getCorrespondentTypeList: builder.query<
      ICorrespondentTypeResponseDto,
      ICorrespondentTypeRequestDto
    >({
      query: (params) => ({
        url: `/correspondent_type/`,
        params: params ? params : {},
      }),
    }),
    getCurrentTemperature: builder.query<
      IOrdersDishTemperatureResponseDto,
      number
    >({
      query: (id) => ({
        url: `/order/get_dish_temperature/now/?dish=${id}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Order'],
    }),

    addDimension: builder.mutation<TCreateDimension, TCreateDimension>({
      query: (item) => ({
        url: `/order/add_order_line_dimension/${
          item.orderLine ? 'order_line_id/' : ''
        }now/`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: ['Order', 'PlanMenu'],
    }),
    changeCookStatus: builder.mutation<
      ICreateCookDimension,
      ICreateCookDimension
    >({
      query: (item) => ({
        url: `/order/add_order_line_cooking_status/order_line_id/now/`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: ['Order', 'OrderLine'],
    }),
    updateOrder: builder.mutation<IOrder, IPropsUpdateOrder>({
      query: (item) => ({
        url: `/order/${item.id}/`,
        method: 'PATCH',
        body: item,
      }),
      invalidatesTags: ['Order'],
    }),
    trainModel: builder.mutation<IResponseModel, IRequestTrainModel>({
      query: (item) => ({
        url: `/ai/train_model/`,
        method: 'POST',
        body: item,
      }),
    }),
    checkLatestModel: builder.query<IResponseModel, void>({
      query: (item) => ({
        url: `/ai/check_latest_model/`,
        method: 'GET',
      }),
    }),
    enableLatestModel: builder.mutation<IResponseModel, void>({
      query: () => ({
        url: `/ai/set_latest_model/`,
        method: 'POST',
      }),
    }),
    checkTrainingProcess: builder.query<IResponseModel, void>({
      query: (item) => ({
        url: `/ai/check_training_process/`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useTrainModelMutation,
  useCheckTrainingProcessQuery,
  useCheckLatestModelQuery,
  useEnableLatestModelMutation,
  useGetAllOrdersQuery,
  useGetOrderByIdQuery,
  useGetCurrentWeightQuery,
  useLazySetZeroWeightQuery,
  useAddDimensionMutation,
  useGetCreatedOrdersQuery,
  useGetWorkingOrdersQuery,
  useUpdateOrderMutation,
  useChangeCookStatusMutation,
  useGetAllOrdersBuffetQuery,
  useGetAllOrdersByDateQuery,
  useGetAllOrdersByWaiterQuery,
  useGetCurrentTemperatureQuery,
  useGetCorrespondentTypeListQuery,
} = orderApi
