import React, { useCallback, useMemo, useState } from 'react'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { PlanMenuList } from './PlanMenuList'
import { DishesList } from './DishesList/DishesList'
import { FrozeList } from './FrozeList/FrozeList'
import { FrozeDetail } from './FrozeDetail'
import { AppFilterDateInput } from '../../../components/AppInput/AppFilterDateInput'
import { IBaseSimpleList } from '../../../core/api/dto/BaseDto'
import { useGetBuffetDishSimpleListQuery } from '../../../core/api/BuffetDishApi'
import { AppFilterMultiDropdownWithPagination } from '../../../components/AppDropdown/AppFilterMultiDropdownWithPagination'
import { AppFilterDropdown } from '../../../components/AppDropdown/AppFilterDropdown'
import { AppFilterTimeInput } from '../../../components/AppInput/AppFilterTimeInput'
import { ICategory } from '../../../core/api/dto/CategoriesObjDto'
import { useGetCategoryQuery } from '../../../core/api/CategoryApi'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { useSearchParams } from 'react-router-dom'
import { AppFilterCheckbox } from '../../../components/AppInput/AppFilterCheckbox'
import { useGetTagsListQuery } from '../../../core/api/TagsApi'
import { ITag } from '../../../core/api/dto/TagsDto'
import {
  DimensionTranslateType,
  DimensionType,
} from '../../../core/api/dto/BuffetReportDto'
import { createQueryString } from '../../../utils/createQueryString'
import { GetFile } from '../../../utils/getFile'
import { BASE_URL } from '../../../core/api/BaseApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { useParamsControl } from '../../../utils/useParamsControl'
import { IPlanMenuControlOrdersRequest } from '../../../core/api/dto/PlanMenuControlDto'
import moment from 'moment'
import { useGetFullDateTime } from '../../../utils/useGetFullDateTime'
import { useIsAdmin } from '../../../utils/useIsAdmin'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import './PlanMenuControlAdmin.scss'
import { useTranslation } from 'react-i18next'
import { useGetWidth } from 'utils/useGetWidth'

export const deliveryStatusData = [
  { name: 'Выданное', value: 'Delivered' },
  { name: 'Не выданное', value: 'Not_delivered' },
]

export const implementedStatusData = [
  { name: 'Превышенное время', value: 'Time_exceeded' },
  { name: 'Непревышенное время', value: 'Time_not_exceeded' },
]

export const dimensionTypeData = [
  {
    name: DimensionTranslateType[DimensionType.Serving],
    value: DimensionType.Serving,
  },
  {
    name: DimensionTranslateType[DimensionType.Refund],
    value: DimensionType.Refund,
  },
  {
    name: DimensionTranslateType[DimensionType.Write_off],
    value: DimensionType.Write_off,
  },
]

export const useFullTimeHok = (time?: string) => {
  const fullDate = useGetFullDateTime(
    moment().format('YYYY.MM.DD'),
    time || null,
  )
  return time ? moment(fullDate).format() : null
}

export const PlanMenuControlAdmin = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { width } = useGetWidth()
  const isAdmin = useIsAdmin()
  const pageParams = useParamsControl<
    IPlanMenuControlOrdersRequest,
    keyof IPlanMenuControlOrdersRequest
  >({
    withPagination: true,
    whiteList: [
      'search',
      'tag_ids',
      'implemented_status',
      'delivery_status',
      'dimension_type',
      'dish_ids',
      'category_ids',
      'comment',
      'order_date_execution_gte',
      'order_date_execution_lte',
      'start_time',
      'end_time',
    ],
  })

  const timeStart = useFullTimeHok(pageParams?.start_time)
  const timeEnd = useFullTimeHok(pageParams?.end_time)
  const isRedirected = !!searchParams.get('isRedirected')
  const goBackToTask = searchParams.get('go-back-taskId')
  const clearFilterHandler = useCallback(() => {
    setSearchParams('limit=40')
  }, [])

  const unloadReportHandler = () => {
    const params = createQueryString({
      ...pageParams,
      dimension_datetime:
        timeStart || timeEnd
          ? `${timeStart || ''},${timeEnd || ''}`
          : undefined,
    })
    GetFile(
      `${BASE_URL}/plan_menu_control_order/export_to_file/${params}`,
    ).then((res) => {
      if (res) {
        AppNotification({
          msg: t('Выбранные план-меню успешно выгружены!'),
          type: NotificationType.success,
        })
      }
    })
  }

  const [isFiltersOpen, setIsFiltersOpen] = useState(true)

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Выгрузить',
        btnIco: 'export',
        onClick: () => {},
        items: [
          {
            title: 'Таблица .XLSX',
            onClick: unloadReportHandler,
            ico: 'file-xlsx',
          },
          // { title: 'Журнал .PDF', onClick: () => {}, ico: 'file-pdf' },
          // { title: 'Экспортировать .CSV', onClick: () => {}, ico: 'file-csv' },
        ],
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Сбросить фильтр',
        onClick: clearFilterHandler,
        color: AppBtnColor.extraVioletViolet,
      },
    ],
    [pageParams, clearFilterHandler, unloadReportHandler],
  )

  return (
    <div className="width-for-page-wrapper plan-menu-control-admin">
      {isRedirected || goBackToTask ? (
        <AppTitlePage
          title={'Контроль план-меню'}
          goBack
          goBackPath={
            goBackToTask
              ? `/${AllRoutes.taskList.path}/${goBackToTask}/`
              : undefined
          }
        />
      ) : (
        <AppTitlePage
          title={'Контроль план-меню'}
          actions={isAdmin ? actions : undefined}
          isFiltersOpen={isAdmin ? isFiltersOpen : undefined}
          setIsFiltersOpen={isAdmin ? setIsFiltersOpen : undefined}
        />
      )}

      {!isRedirected && isFiltersOpen && isAdmin && (
        <div className="filter-block mt-20">
          <div className='date-inputs-wrap'>
            <label>{t('Период исполнения')}</label>
            <div className="input-row mt-10">
              <AppFilterDateInput searchParam={'order_date_execution_gte'} />
              <AppFilterDateInput searchParam={'order_date_execution_lte'} />
            </div>
          </div>
          <AppFilterMultiDropdownWithPagination<
            IBaseSimpleList,
            keyof IBaseSimpleList
          >
            valuePropName={'id'}
            label={'Названия блюд'}
            searchParam={'dish_ids'}
            getterData={useGetBuffetDishSimpleListQuery}
            propToShowInList={'name'}
            placeholder={'Выбрать'}
          />
          <AppFilterDropdown
            withTranslate
            searchParam={'implemented_status'}
            data={implementedStatusData}
            valuePropName={'value'}
            propToShowInList={'name'}
            label={'Статус реализации'}
            placeholder={'Выбрать'}
            minHeight
          />
          <AppFilterDropdown
            withTranslate
            searchParam={'delivery_status'}
            data={deliveryStatusData}
            valuePropName={'value'}
            propToShowInList={'name'}
            label={'Статус выдачи блюд'}
            placeholder={'Выбрать'}
            minHeight
          />
          <div className='time-inputs-wrap'>
            <label>{t('Время замера')}</label>
            <div className="input-row mt-10">
              <AppFilterTimeInput searchParam={'start_time'} />
              <AppFilterTimeInput searchParam={'end_time'} />
            </div>
          </div>
          <AppFilterMultiDropdownWithPagination<ICategory, keyof ICategory>
            searchParam={'category_ids'}
            getterData={useGetCategoryQuery}
            propToShowInList={'name'}
            valuePropName={'id'}
            label={'Категория'}
            placeholder={'Выбрать'}
          />
          <AppFilterDropdown
            withTranslate
            searchParam={'dimension_type'}
            data={dimensionTypeData}
            valuePropName={'value'}
            propToShowInList={'name'}
            label={'Тип замера'}
            placeholder={'Выбрать'}
            minHeight
            minWidth
          />
          <AppFilterMultiDropdownWithPagination<ITag, keyof ITag>
            valuePropName={'id'}
            label={'Хэштеги'}
            searchParam={'tag_ids'}
            getterData={useGetTagsListQuery}
            propToShowInList={'name'}
            placeholder={'Выбрать'}
            minWidth
          />
          <AppFilterCheckbox searchParam={'comment'} text="Комментарии" />
        </div>
      )}
      <div
        className={`plan-menu-control-page mt-15${
          !isAdmin ? ' not-admin' : ''
        }`}
      >
        <div className={`width-${width <= 1024 ? 100 : 20} ${(!isFiltersOpen && width<=1024) ? 'list-opened' : ''}`}>
          <PlanMenuList
            pageParams={{
              ...pageParams,
              dimension_datetime:
                timeStart || timeEnd
                  ? `${timeStart || ''},${timeEnd || ''}`
                  : undefined,
            }}
            withTaskAddButton={false}
          />
        </div>
        <div className={`width-${width <= 1024 ? width<=768 ? 100 : 33 : 25}`}>
          <DishesList
            pageParams={{
              ...pageParams,
              dimension_datetime:
                timeStart || timeEnd
                  ? `${timeStart || ''},${timeEnd || ''}`
                  : undefined,
            }}
            isAdmin={!!isAdmin}
            withTaskAddButton={false}
          />
        </div>
        <div className={`width-${width <= 1024 ? width<=768 ? 100 : 33 : 20}`}>
          <FrozeList
            pageParams={{
              ...pageParams,
              dimension_datetime:
                timeStart || timeEnd
                  ? `${timeStart || ''},${timeEnd || ''}`
                  : undefined,
            }}
            withTaskAddButton={false}
          />
        </div>
        <div className={`width-${width <= 1024 ? width<=768 ? 100 : 33 : 37}`}>
          <FrozeDetail isAdmin={!!isAdmin} />
        </div>
      </div>
    </div>
  )
}
