export interface IInfoDish {
  parentId: number
  orderLineId: number
}

interface ICookState {
  dishes: IInfoDish[]
}

export const cookInitialState: ICookState = {
  dishes: [],
}
