import React, { useCallback, useEffect } from 'react'
import AppBtn, {
  AppBtnColor,
  AppBtnShadow,
  AppBtnSize,
  IconPosition,
} from '../../components/btn/AppBtn'
import { scrollDown, scrollUp } from '../../utils'
import { IPropsNavBar } from './HeaderWaiterPage'
import useFilterQuery from '../../utils/useFilterQuery'
import { LIMIT } from '../../core/api/BaseApi'

export type TProps = 'next' | 'previous'

const NavHeaderWaiterPage = ({
  contentRef,
  setStateNavPanel,
  stateNavPanel,
}: IPropsNavBar) => {
  const { setNewSearchParams, getCurrentSearchParamValue } = useFilterQuery()

  const getNewPage = useCallback(
    (arg: TProps) => {
      const count = Number(getCurrentSearchParamValue('offset'))
      if (arg === 'next') {
        setNewSearchParams({
          searchParamName: 'offset',
          value: `${count + LIMIT.waiter}`,
        })
      } else if (arg === 'previous') {
        setNewSearchParams({
          searchParamName: 'offset',
          value: `${count - LIMIT.waiter}`,
        })
      }
    },
    [getCurrentSearchParamValue, setNewSearchParams],
  )
  useEffect(() => {
    if (!getCurrentSearchParamValue('offset')) {
      setNewSearchParams({ searchParamName: 'offset', value: '0' })
    }
  }, [getCurrentSearchParamValue, setNewSearchParams])
  return (
    <nav className="nav">
      <div className="row">
        <div className="col-3">
          <AppBtn
            title="Предыдущая страница"
            sized={AppBtnSize.md}
            color={
              Boolean(stateNavPanel.previous)
                ? AppBtnColor.whiteGray
                : AppBtnColor.whiteLightGray
            }
            shadow={AppBtnShadow.gray}
            iconClassName="arrow-l"
            onClick={() => getNewPage('previous')}
            disabled={!Boolean(stateNavPanel.previous)}
          />
        </div>
        <div className="col-3">
          <div className="btn-block">
            <AppBtn
              sized={AppBtnSize.md}
              color={
                stateNavPanel.isShowReadyDishes
                  ? AppBtnColor.whiteViolet
                  : AppBtnColor.whiteGray
              }
              shadow={AppBtnShadow.gray}
              title="Выданные блюда"
              onClick={() =>
                setStateNavPanel({
                  ...stateNavPanel,
                  isShowReadyDishes: !stateNavPanel.isShowReadyDishes,
                })
              }
            />
            <AppBtn
              sized={AppBtnSize.md}
              color={
                stateNavPanel.isUnwrapOrders
                  ? AppBtnColor.whiteViolet
                  : AppBtnColor.whiteGray
              }
              shadow={AppBtnShadow.gray}
              title="Развернуть заказы"
              onClick={() =>
                setStateNavPanel({
                  ...stateNavPanel,
                  isUnwrapOrders: !stateNavPanel.isUnwrapOrders,
                })
              }
            />
          </div>
        </div>
        <div className="col-3">
          <div className="btn-block">
            <AppBtn
              sized={AppBtnSize.md}
              color={AppBtnColor.whiteGray}
              shadow={AppBtnShadow.gray}
              iconClassName="arrow-down"
              onClick={() => scrollDown(contentRef, 80)}
            />
            <AppBtn
              sized={AppBtnSize.md}
              color={AppBtnColor.whiteGray}
              shadow={AppBtnShadow.gray}
              iconClassName="arrow-up"
              onClick={() => scrollUp(contentRef, 80)}
            />
          </div>
        </div>
        <div className="col-3">
          <AppBtn
            title="Следующая страница"
            sized={AppBtnSize.md}
            color={
              Boolean(stateNavPanel.next)
                ? AppBtnColor.whiteGray
                : AppBtnColor.whiteLightGray
            }
            shadow={AppBtnShadow.gray}
            iconClassName="arrow-r"
            iconPosition={IconPosition.right}
            onClick={() => getNewPage('next')}
            disabled={!Boolean(stateNavPanel.next)}
          />
        </div>
      </div>
    </nav>
  )
}

export default NavHeaderWaiterPage
