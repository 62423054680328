import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { resetPaginationByParamsChanged } from '../../utils/resetPaginationByParamsChanged'
import { AppMultiDropdown } from './AppMultiDropdown'

export interface IAppFilterDropdown<T, TKey> {
  data: T[]
  searchParam: string
  propToShowInList: TKey
  valuePropName: TKey
  placeholder?: string
  label?: string
  id?: string
  shouldResetPagination?: boolean
  disabled?: boolean
  setCurrentValue?: (value: T[]) => void
}

export const AppFilterMultiDropdown = <T, TKey extends keyof T>({
  searchParam,
  data,
  propToShowInList,
  valuePropName,
  label,
  placeholder,
  shouldResetPagination = true,
  setCurrentValue,
  disabled,
}: PropsWithChildren<IAppFilterDropdown<T, TKey>>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const [currentFilterValue, setCurrentFilterValue] = useState<T[]>([])
  const currentSearchParamValue = searchParams.get(
    searchParam,
  ) as unknown as TKey[]

  useEffect(() => {
    if (!!currentSearchParamValue) {
      const currentData =
        data &&
        data.filter((item: T) =>
          `${currentSearchParamValue}`
            .split(',')
            .some((i) => i === `${item[valuePropName]}`),
        )
      setCurrentFilterValue(!!currentData ? currentData : [])
      setCurrentValue && setCurrentValue(!!currentData ? currentData : [])
    } else {
      setCurrentFilterValue([])
      setCurrentValue && setCurrentValue([])
    }
  }, [data, currentSearchParamValue])

  const changeHandler = useCallback(
    (item: T[]) => {
      if (!!item.length) {
        searchParams.set(
          searchParam,
          item.map((item) => item[valuePropName]).join(','),
        )
      } else {
        searchParams.delete(searchParam)
      }
      if (shouldResetPagination) {
        resetPaginationByParamsChanged({ searchParams, setSearchParams, pathname })
      }
      setCurrentFilterValue(item)
      setSearchParams(searchParams.toString())
    },
    [searchParams, setSearchParams, searchParam, valuePropName],
  )

  const resetFilterHandler = useCallback(() => {
    searchParams.delete(searchParam)
    setSearchParams(searchParams.toString())
    if (shouldResetPagination) {
      resetPaginationByParamsChanged({ searchParams, setSearchParams, pathname })
    }
  }, [searchParams, searchParam])
  return (
    <AppMultiDropdown
      data={data}
      propToShowInList={propToShowInList}
      propToShowInInput={valuePropName}
      value={currentFilterValue}
      placeholder={placeholder}
      onChange={changeHandler}
      label={label}
      resetValueHandler={resetFilterHandler}
      disabled={disabled}
    />
  )
}
