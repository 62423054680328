import React, { memo } from 'react'
import { IMillet } from '../../core/api/dto/MilletObjDto'
import { compareProps } from '../../utils'

interface IPropsBuffetPlate {
  tray: IMillet | null
  info: IMillet
  onClick: (val: IMillet) => void
  isSuccess: boolean
}

const OrderLinePlate = ({
  info,
  onClick,
  isSuccess,
  tray,
}: IPropsBuffetPlate) => {
  const handlerOnclick = () => {
    if (isSuccess) {
      onClick(info)
    }
  }

  return (
    <div
      className={`buffet-plate ${tray && tray.id === info.id ? 'active' : ''}`}
      onClick={handlerOnclick}
    >
      <img src={info.milletPhoto} alt="" className="photo-plate" />
      <div className="info">
        <div className="name">{info.milletName}</div>
        <div className="weight">{info.milletWeight}кг</div>
      </div>
    </div>
  )
}

const OrderLinePlateMemoized = memo(OrderLinePlate, compareProps)

export default OrderLinePlateMemoized
