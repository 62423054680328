import React from 'react'
import AppDropdownWithPagination, {
  IExtraOptions,
} from './AppDropdownWithPagination'
import { IWithPaginationRequestSearch } from '../../core/api/dto/BaseDto'
import { useTranslation } from 'react-i18next'

interface IAppMultiDropdownWithPagination<T, TKey> {
  value: T[] | []
  propToShowInList?: TKey // если data массив строк, то не передаем propToShowInList и propToShowInInput
  propToShowInInput: TKey
  sparePropToShowInList?: TKey
  extraPropToShowInList?: TKey
  getterData: (
    params: IWithPaginationRequestSearch | any | null,
    extraParams?: { skip?: boolean },
  ) => any
  onChange: (
    value: T[],
  ) => void | React.Dispatch<React.SetStateAction<[] | T[]>>
  resetValueHandler?: () => void
  label?: string
  placeholder?: string
  extraOptions?: IExtraOptions
  disabled?: boolean
  error?: string | null
  id?: string
  emptyListMessage?: string
  require?: boolean
  border?: boolean
  fullWidth?: boolean
  minWidth?: boolean
}

export const AppMultiDropdownWithPagination = <T, TKey extends keyof T>({
  value,
  onChange,
  propToShowInList,
  propToShowInInput,
  extraPropToShowInList,
  sparePropToShowInList,
  getterData,
  label,
  placeholder,
  extraOptions,
  disabled,
  error,
  emptyListMessage,
  resetValueHandler,
  require,
  border,
  fullWidth,
  minWidth,
  id,
}: IAppMultiDropdownWithPagination<T, TKey>) => {
  const { t } = useTranslation()
  const onChangeHandler = (newItem: T) => {
    const duplicate = value.find(
      (item) => item[propToShowInInput] === newItem[propToShowInInput],
    )
    if (!!duplicate) {
      const removeDuplicate = value.filter(
        (item) => item[propToShowInInput] !== duplicate[propToShowInInput],
      )
      onChange(removeDuplicate)
      return
    }
    onChange([...value, newItem])
  }

  return (
    <AppDropdownWithPagination
      label={label}
      value={null}
      onChange={onChangeHandler}
      getterData={getterData}
      multiValue={value}
      propToShowInList={propToShowInList}
      propToShowInInput={propToShowInInput}
      placeholder={
        value.length === 0 ? placeholder : `${t('Выбрано')} : ${value.length}`
      }
      resetValueHandler={resetValueHandler}
      extraOptions={extraOptions}
      disabled={disabled}
      error={error}
      require={require}
      emptyListMessage={emptyListMessage}
      border={border}
      fullWidth={fullWidth}
      minWidth={minWidth}
      id={`-m${id || ''}`}
      extraPropToShowInList={extraPropToShowInList}
      sparePropToShowInList={sparePropToShowInList}
    />
  )
}
