import React, { useCallback } from 'react'
import { useGetWorkshopsQuery } from '../../core/api/DishApi'
import {
  IDish,
  IDishWithVersions,
  IWorkshop,
} from '../../core/api/dto/DishObjDto'
import Checkbox, { CheckboxSize } from '../checkbox/Checkbox'

interface IPropsWorkShops {
  setCurrentCard: (arg: IDish) => void
  currentCard: IDishWithVersions | IDish
  changeDataForUpdate: (
    propsName: keyof IDish,
    value: IDish[typeof propsName],
  ) => void
  isDisabled: boolean
}

const Workshops = ({
  setCurrentCard,
  currentCard,
  changeDataForUpdate,
  isDisabled,
}: IPropsWorkShops) => {
  const { data: workshops } = useGetWorkshopsQuery(null, {
    refetchOnMountOrArgChange: true,
  })
  const changeWorkShopField = useCallback(
    (newWorkshops: IWorkshop[]) => {
      setCurrentCard({
        ...currentCard,
        workshops: !newWorkshops ? [] : newWorkshops,
      })
      changeDataForUpdate('workshops', !newWorkshops ? [] : newWorkshops)
    },
    [changeDataForUpdate, setCurrentCard, currentCard],
  )

  const handlerCheckbox = useCallback(
    (item: IWorkshop) => {
      if (!currentCard.workshops.length) {
        changeWorkShopField([item])
      } else {
        const res = currentCard.workshops.find((el) => el.id === item.id)

        if (res) {
          const newWorkshops = currentCard.workshops.filter(
            (el) => el.id !== item.id,
          )
          changeWorkShopField(newWorkshops.length ? newWorkshops : [])
        } else {
          changeWorkShopField([...currentCard.workshops, item])
        }
      }
    },
    [changeWorkShopField, currentCard.workshops],
  )

  return (
    <div className="list-workShop">
      {workshops &&
        workshops.map((el, idx) => {
          return (
            <Checkbox
              size={CheckboxSize.md}
              value={currentCard?.workshops &&
                currentCard.workshops.find((item) => item.id === el.id)
                  ? true
                  : false
              }
              onChange={() => handlerCheckbox(el)}
              name={`checkbox-${idx}`}
              text={el.name}
              key={`checkbox-${idx}-workshop-${el.id}`}
              disabled={isDisabled}
            />
          )
        })}
    </div>
  )
}

export default Workshops
