import React from 'react'
import { Link } from 'react-router-dom'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import { AllRoutes } from '../../core/routes/AllRoutes'
import AppBtn, { AppBtnColor, AppBtnSize } from '../../components/btn/AppBtn'
import CurrentDate from '../../components/currentDate/CurrentDate'

interface IPropsHeader {
  isAllOrders: boolean
  setIsAllOrders: (arg: boolean) => void
}

const HeaderCook = ({ setIsAllOrders, isAllOrders }: IPropsHeader) => {
  const { data: currentUser } = useGetCurrentUserQuery()

  return (
    <header className="header cook">
      <div className="container-fluid container-xl">
        <div className="row">
          <div className="col-3">
            <Link to={`${AllRoutes.user.path}`} className="link">
              <i className="an-ico an-ico-user" />
              {currentUser?.name && <span>{currentUser.name}</span>}
            </Link>
          </div>
          <div className="col-3 text-center">
            <AppBtn
              sized={AppBtnSize.md}
              color={
                isAllOrders ? AppBtnColor.whiteViolet : AppBtnColor.outlineWhite
              }
              title="Выданные блюда"
              onClick={() => setIsAllOrders(!isAllOrders)}
            />
          </div>
          <div className="col-6 text-end">
            <h4 className="color-White">
              <CurrentDate />
            </h4>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderCook
