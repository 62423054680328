import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AddDishInPlanMenuPopup } from '../PlanMenuPage/AddDishInPlanMenuPopup'
import { IOrderLine } from '../../../core/api/dto/PlanMenuDto'
import { AppTitlePage } from '../../../components/AppTitlePage'
import { AppBtnColor } from '../../../components/btn/AppBtn'
import { useNavigate, useParams } from 'react-router'
import { AppFormHook } from '../../../components/AppFormController'
import { AppInput, InputMaskType } from '../../../components/AppInput/AppInput'
import { PlanMenuTableDish } from '../PlanMenuPage/components/PlanMenuTableDish'
import { IChooseItems } from '../../../components/mainContent/TableAllDishes'
import {
  useAddedPlanMenuTemplateDishListMutation,
  useCreatePlanMenuTemplateMutation,
  useDeletePlanMenuTemplateDishListMutation,
  useGetPlanMenuTemplateDetailQuery,
  useUpdatePlanMenuTemplateMutation,
} from '../../../core/api/PlanMenuTemplateApi'
import {
  AppNotification,
  NotificationType,
} from '../../../components/notification/Notification'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { IPlanMenuTemplateBase } from '../../../core/api/dto/PlanMenuTemplateDto'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useSearchParams } from 'react-router-dom'
import { useGetPlanMenuDetailQuery } from '../../../core/api/PlanMenuApi'
import { useTranslation } from 'react-i18next'

const formData = [
  {
    fieldName: 'name',
    required: 'Обязательное поле',
  },
  {
    fieldName: 'numberOfPersons',
    required: 'Обязательное поле',
  },
]

export const CreateTemplatePlanMenuPage = () => {
  const { t } = useTranslation()
  const { templatePlanMenuId } = useParams()
  const [searchParams] = useSearchParams()
  const planMenuId = searchParams.get('plan_menu')
  const navigate = useNavigate()
  const [planMenuTableData, setPlanMenuTableData] = useState<IOrderLine[]>([])
  const [addDishPopup, setAddDishPopup] = useState(false)
  const [tableValid, setTableValid] = useState(true)
  const [chooseItems, setChooseItems] = useState<IChooseItems | null>(null)
  const [isDirty, setIsDirty] = useState(false)

  const {
    formState,
    formStateChangeHandler,
    formErrorState,
    formValidationHandler,
  } = AppFormHook({
    formConfig: formData,
  })

  const { data: currentTemplatePlanMenu } = useGetPlanMenuTemplateDetailQuery(
    { id: Number(templatePlanMenuId) },
    { skip: !templatePlanMenuId },
  )
  const { data: currentPlanMenu } = useGetPlanMenuDetailQuery(
    { id: Number(planMenuId!) },
    { skip: !planMenuId },
  )
  const [createPlanMenuTemplate, { isLoading: isLoadingCreate }] =
    useCreatePlanMenuTemplateMutation()
  const [updatePlanMenuTemplate, { isLoading: isLoadingUpdate }] =
    useUpdatePlanMenuTemplateMutation()
  const [addedPlanMenuTemplateDish, { isLoading: isLoadingAdded }] =
    useAddedPlanMenuTemplateDishListMutation()
  const [deletePlanTemplateMenu, { isLoading: isLoadingDelete }] =
    useDeletePlanMenuTemplateDishListMutation()

  useEffect(() => {
    if (!!currentPlanMenu) {
      formStateChangeHandler({
        name: currentPlanMenu?.name || '',
        numberOfPersons: `${currentPlanMenu?.numberOfPersons || ''}`,
      })
      setPlanMenuTableData(currentPlanMenu.planMenuLines)
    }
  }, [currentPlanMenu])
  useEffect(() => {
    if (!!currentTemplatePlanMenu) {
      formStateChangeHandler({
        name: currentTemplatePlanMenu?.name || '',
        numberOfPersons: `${currentTemplatePlanMenu?.numberOfPersons || ''}`,
      })
      setPlanMenuTableData(currentTemplatePlanMenu.planMenuPatternLines)
    }
  }, [currentTemplatePlanMenu])
  const changeHandler = (propName: string, value: string) => {
    formStateChangeHandler({ [propName]: value })
    setIsDirty(true)
  }

  const closePopupHandler = useCallback(() => setAddDishPopup(false), [])

  const onSaveHandler = () => {
    const { isValid } = formValidationHandler()
    const tableValueError = planMenuTableData.find(
      (item) =>
        item.quantity === 0 || Number(item.quantity) === 0 || !item.quantity,
    )
    if (!!tableValueError) {
      setTableValid(false)
    }
    if (!planMenuTableData.length) {
      AppNotification({
        msg: t(
          'Для создания шаблона план-меню должно быть добавлено минимум одно блюдо',
        ),
        type: NotificationType.error,
      })
      return
    }
    if (
      isValid &&
      !!formState.numberOfPersons &&
      Number(formState.numberOfPersons) !== 0 &&
      !tableValueError
    ) {
      const { name, numberOfPersons } = formState

      const newPlanMenuData: IPlanMenuTemplateBase = {
        name,
        numberOfPersons: Number(numberOfPersons),
      }
      const newPlanMenuDishesData = planMenuTableData.map(
        ({ lineGuid, dish, quantity }) => ({
          lineGuid,
          dish: dish.id,
          quantity: quantity || 0,
        }),
      )
      if (templatePlanMenuId) {
        let deletedItems: string[] = []
        if (!!currentTemplatePlanMenu) {
          deletedItems = currentTemplatePlanMenu.planMenuPatternLines
            .filter(
              (obj1) => !planMenuTableData.some((obj2) => obj1.id === obj2.id),
            )
            .map((item) => item.lineGuid)
        }
        updatePlanMenuTemplate({
          id: Number(templatePlanMenuId),
          data: newPlanMenuData,
        })
          .then(() => {
            !!deletedItems.length &&
              deletePlanTemplateMenu({
                id: Number(templatePlanMenuId),
                lineGuids: deletedItems,
              })
          })
          .then(() =>
            addedPlanMenuTemplateDish({
              id: Number(templatePlanMenuId),
              body: newPlanMenuDishesData as unknown as IOrderLine[],
            })
              .unwrap()
              .then(() => {
                AppNotification({
                  msg: t('Шаблон план-меню успешно редактирован'),
                  type: NotificationType.success,
                })
                setIsDirty(false)
                navigate(`/${AllRoutes.templatePlanMenu.path}/`)
              }),
          )
      } else {
        createPlanMenuTemplate(newPlanMenuData)
          .unwrap()
          .then((res) =>
            addedPlanMenuTemplateDish({
              id: Number(res.id),
              body: newPlanMenuDishesData as unknown as IOrderLine[],
            })
              .unwrap()
              .then(() => {
                AppNotification({
                  msg: t('Шаблон план-меню успешно создан'),
                  type: NotificationType.success,
                })
                setIsDirty(false)
                navigate(`/${AllRoutes.templatePlanMenu.path}/`)
              }),
          )
      }
    }
  }

  const actions = useMemo(
    () => [
      {
        btnTitle: 'Добавить блюдо',
        btnIco: 'add',
        onClick: () => setAddDishPopup(true),
        color: AppBtnColor.whiteOutlineViolet,
      },
      {
        btnTitle: 'Сохранить',
        btnIco: 'save',
        onClick: onSaveHandler,
        color: AppBtnColor.violetWhite,
      },
    ],
    [formState, planMenuTableData, templatePlanMenuId, currentTemplatePlanMenu],
  )

  return (
    <React.Fragment>
      {(isLoadingCreate ||
        isLoadingUpdate ||
        isLoadingAdded ||
        isLoadingDelete) && <AppLoader loaderType={LoaderType.main} />}
      {addDishPopup && (
        <AddDishInPlanMenuPopup
          closePopup={closePopupHandler}
          addedDish={planMenuTableData}
          setNewDish={setPlanMenuTableData}
          setIsDirty={setIsDirty}
        />
      )}
      <div className={'width-for-page-wrapper'}>
        <AppTitlePage
          title={`${
            templatePlanMenuId ? 'Редактирование' : 'Создание'
          } шаблона план-меню`}
          actions={actions}
          goBack
          isDirty={isDirty}
        />
        <div className="filter-block mt-20">
          <div className="col-4">
            <AppInput
              value={formState.name}
              error={formErrorState.name ? 'Обязательное поле' : undefined}
              onChange={(value) => changeHandler('name', value)}
              label="Название шаблона"
              placeholder="Название"
              fullWidth
              required
            />
          </div>
          <div className="col-4">
            <AppInput
              value={formState.numberOfPersons}
              error={
                formErrorState.numberOfPersons ? 'Обязательное поле' : undefined
              }
              onChange={(value) => changeHandler('numberOfPersons', value)}
              label="Кол-во гостей"
              placeholder="0"
              fullWidth
              required
              inputMask={InputMaskType.integer}
            />
          </div>
        </div>
        <PlanMenuTableDish
          planMenuTableData={planMenuTableData}
          setPlanMenuTableData={setPlanMenuTableData}
          tableActionsProps={[]}
          tableValid={tableValid}
          chooseItems={chooseItems}
          setChooseItems={setChooseItems}
          setIsDirty={setIsDirty}
        />
      </div>
    </React.Fragment>
  )
}
