import React, { useState } from 'react'
import {
  IBuffetGeneralReportResult,
} from 'core/api/dto/BuffetGeneralReportDto'
import { AppTableMobile } from 'components/AppTable/AppTableMobile'
import { SubTableRepresentVolume } from './SubTableRepresentVolume'

const headerDataVolume = [
  { title: 'Станция', colWidth: '10%' },
  { title: 'Название блюда', colWidth: '15%', sort: 'name' },
  { title: 'Дата', colWidth: '3%' },
  { title: 'Время', colWidth: '3%' },
  { title: 'Тип замера', colWidth: '5%' },
  { title: 'Порядковый номер замера', colWidth: '6%' },
  {
    title: 'Кол-во заявленных гостей',
    colWidth: '6%',
    sort: 'plannedPersonsAmount',
  },
  {
    title: 'Кол-во указанных гостей',
    colWidth: '6%',
    sort: 'actualPersonsAmount',
  },
  {
    title: 'Разница кол-во гостей',
    colWidth: '6%',
    sort: 'personsAmountDiff',
  },
  { title: 'Вес заявки, кг', colWidth: '6%', sort: 'plannedWeight' },
  { title: 'Вес факт, кг', colWidth: '6%', sort: 'actualWeight' },
  { title: 'Разница вес, кг', colWidth: '6%', sort: 'weightDiff' },
  { title: 'Вес на персону', colWidth: '6%', sort: 'weightPerPerson' },
]

interface IReportFullProps {
  reportData: IBuffetGeneralReportResult[]
  setCurrentSortValue: React.Dispatch<React.SetStateAction<string | null>>
}

export const ReportVolume = ({reportData, setCurrentSortValue} : IReportFullProps) => {
  const [toggleAllState, setToggleAllState] = useState<number[]>([])

  const subTableRepresentFn = (
    item: IBuffetGeneralReportResult,
    currentCol?: number,
    itemIndex?: number,
    onTouchStart?: ((e: React.TouchEvent) => void) | undefined,
    onTouchMove?: ((e: React.TouchEvent) => void) | undefined,
    width?: number,
    toggleAllState?: number[],
  ) => (
    <SubTableRepresentVolume
      item={item}
      currentCol={currentCol}
      itemIndex={itemIndex}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      width={width}
      toggleAllState={toggleAllState}
      toggleAllStateFullLenght={reportData.length}
    />
  )

  return (
    <React.Fragment>
      {reportData && (
        <AppTableMobile<
          IBuffetGeneralReportResult,
          keyof IBuffetGeneralReportResult
        >
          toggleAllState={toggleAllState}
          setToggleAllState={setToggleAllState}
          data={{
            count: 0,
            results: reportData,
            previous: '',
            next: '',
          }}
          tableWrapperClassName={'buffet-report-table'}
          tableCellClassName={'buffet-report-page-table-cell'}
          rowClassName={'buffet-report-page-table-category'}
          setCurrentSortFn={setCurrentSortValue}
          tableDataSelectors={[
            {
              renderItem: (item, index) => (
                <div
                  className={
                    index
                      ? 'violet-sticky'
                      : 'buffet-report-page-table-category-title'
                  }
                ></div>
              ),
            },
            {
              renderItem: (item) => <div>{item?.name}</div>,
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: () => <div />,
            },
            {
              renderItem: () => <div className={`text-end`}></div>,
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {+item?.plannedPersonsAmount.toFixed(1)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {+item?.actualPersonsAmount.toFixed(1)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.personsAmountDiff}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.plannedWeight.toFixed(3)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.actualWeight.toFixed(3)}
                </div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>{item?.weightDiff?.toFixed(3)}</div>
              ),
            },
            {
              renderItem: (item) => (
                <div className={`text-end`}>
                  {item?.weightPerPerson?.toFixed(3)}
                </div>
              ),
            },
          ]}
          subTableRepresent={subTableRepresentFn}
          headerData={headerDataVolume.map((item) => item)}
        />
      )}
      </React.Fragment>
  )
}
