import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AppScrollWrapperNew } from '../../widgets/AppScrollWrapper/AppScrollWrapper'
import { IBaseResponseDto } from '../../core/api/dto/BaseDto'
import { useSearchParams } from 'react-router-dom'
import { IChooseItems } from '../mainContent/TableAllDishes'
import './AppTable.css'
import { TableActionsBlock } from './tableComponents/TableActionsBlock'
import { TableColgroupBlock } from './tableComponents/TableColgroupBlock'
import { TableHeaderBlock } from './tableComponents/TableHeaderBlock'
import { TableRow } from './tableComponents/TableRow'
import { TablePaginationBlock } from './tableComponents/TablePaginationBlock'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { useTranslation } from 'react-i18next'

export interface IHeaderData {
  title: string
  sort?: string
  colWidth?: string // px or %
  order?: number
  startPosition?: number
}

export enum ITableActionType {
  'choose' = 'choose',
  'delete' = 'delete',
}

export interface IActionsData {
  title: string
  type?: ITableActionType
  ico?: string
  onClick: () => void
  chooseValue?: boolean
  disabled?: boolean
}

interface ITableData<T> extends IBaseResponseDto {
  results: T[] | []
}

interface ISelectorBase<TKey> {
  propsNameToDisable?: TKey
}

export interface ISelectorName<TKey> extends ISelectorBase<TKey> {
  name: TKey | null
  renderItem?: never
}

interface ISelectorVoid<T> extends ISelectorBase<keyof T> {
  name?: never
  renderItem: (item: T, index: number) => JSX.Element | string
}

export type TSelector<T, TKey> = ISelectorName<TKey> | ISelectorVoid<T>

export interface IAppTableBase<T, TKey> {
  idName?: string
  data: ITableData<T>
  headerData: IHeaderData[]
  subTableRepresent?: (
    item: T,
    currentCol?: number,
    itemIndex?: number,
    onTouchStart?: ((e: React.TouchEvent) => void) | undefined,
    onTouchMove?: ((e: React.TouchEvent) => void) | undefined,
  ) => void
  isLoading?: boolean
  tableDataSelectors: TSelector<T, TKey>[]
  setCurrentSortFn?: (value: string) => void
}

export interface IAppTable<T, TKey> extends IAppTableBase<T, TKey> {
  chooseActions?: IActionsData[]
  chooseMode?: boolean
  importantItem?: TKey
  chooseItems?: IChooseItems | null
  setChooseItems?: React.Dispatch<React.SetStateAction<IChooseItems | null>>
  setTableDataHandler?: (value: T[]) => void
  withoutPagination?: boolean
  bigPaddingBottom?: boolean
  wrapperClassName?: string
}

export const AppTable = <
  T extends { id: number; archived?: boolean },
  TKey extends keyof T,
>({
  wrapperClassName,
  idName,
  headerData,
  data,
  tableDataSelectors,
  chooseActions,
  setTableDataHandler,
  isLoading,
  chooseMode,
  importantItem,
  chooseItems,
  setChooseItems,
  withoutPagination,
  setCurrentSortFn,
  bigPaddingBottom,
  subTableRepresent,
}: IAppTable<T, TKey>) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const ordering = searchParams.get('ordering')
  const [tableData, setTableData] = useState<T[]>([])
  const tableRef = useRef<HTMLDivElement | null>(null)

  const chooseItemsHandler = useCallback((value: boolean, id: number) => {
    setChooseItems &&
      setChooseItems((prevState) => ({ ...prevState, [id]: value }))
  }, [])

  useEffect(() => {
    if (!withoutPagination) {
      searchParams.set('limit', '40')
      setSearchParams(searchParams.toString())
    }
  }, [])

  useEffect(() => {
    setTableData(data.results)
    !!setTableDataHandler && setTableDataHandler(data.results)
  }, [data, setTableDataHandler])
  return (
    <div className={`app-table-wrap ${wrapperClassName || ''}`} ref={tableRef}>
      {isLoading && <AppLoader loaderType={LoaderType.table} />}
      <TableActionsBlock
        chooseActions={chooseActions}
        tableData={tableData}
        chooseItems={chooseItems}
        setChooseItems={setChooseItems}
        chooseMode={chooseMode}
      />
      <div className={'app-table'}>
        <AppScrollWrapperNew theme="table">
          <table>
            <TableColgroupBlock
              chooseMode={chooseMode}
              importantMode={!!importantItem}
              subTableRepresent={!!subTableRepresent}
              headerData={headerData}
            />
            <TableHeaderBlock
              headerData={headerData}
              subTableRepresent={!!subTableRepresent}
              chooseMode={chooseMode}
              setCurrentSortFn={setCurrentSortFn}
              ordering={ordering}
              importantMode={!!importantItem}
            />
            <tbody>
              {tableData.map((item, itemIndex) => {
                return (
                  <TableRow
                    tableDataSelectors={tableDataSelectors}
                    chooseItems={chooseItems}
                    chooseItemsHandler={chooseItemsHandler}
                    item={item}
                    itemIndex={itemIndex}
                    chooseMode={chooseMode}
                    bigPaddingBottom={bigPaddingBottom}
                    key={`table-row-c-${itemIndex}`}
                    subTableRepresent={subTableRepresent}
                    idName={idName}
                    importantItem={importantItem}
                  />
                )
              })}
              {!tableData.length && (
                <tr>
                  <td
                    colSpan={
                      tableDataSelectors.length +
                      (chooseMode ? 1 : 0) +
                      (!!importantItem ? 1 : 0)
                    }
                    className="result-not-found"
                  >
                    <div>{t('Выберете фильтр')}</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </AppScrollWrapperNew>
        <TablePaginationBlock
          isLoading={!!isLoading}
          dataCount={data.count}
          dataNext={!!data.next}
          tableRef={tableRef}
        />
      </div>
    </div>
  )
}
