import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  memo,
  useEffect,
} from 'react'
import { IMillet } from '../../core/api/dto/MilletObjDto'
import { TCreateDimension } from '../../core/api/dto/OrderObjDto'
import { useGetMilletsQuery } from '../../core/api/MilletApi'
import { useAddDimensionMutation } from '../../core/api/OrderApi'
import { compareProps, scrollDown, scrollUp } from '../../utils'
import AppBtn, {
  AppBtnColor,
  AppBtnRadius,
  AppBtnShadow,
  AppBtnSize,
} from '../btn/AppBtn'
import OrderLinePlateMemoized from '../orderLinePlate/OrderLinePlate'
import { AppNotification, NotificationType } from '../notification/Notification'
import Spinner, {
  SpinnerColor,
  SpinnerPosition,
  SpinnerSize,
} from '../spinner/Spinner'
import AmountItem from './AmountItem'
import Checkbox, { CheckboxSize } from '../checkbox/Checkbox'
import InputField, { IconPosition, InputSize } from '../input/InputField'
import KeyboardWrapper, { Lang } from '../keyboardWrapper/KeyboardWrapper'
import { useAppSelector } from '../../core/store/store'
import { LIMIT } from '../../core/api/BaseApi'

const mockPlatesWeight = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 1.2]

interface IBaseCalculatorProps {
  dishName: string
  dishId?: number
  sourceId: number
  orderLineId?: number
  sourceName?: string
  resetActiveDish: () => void
  hideCalculator?: () => void
  isSuccessWeight: boolean
  currentWeight: number
  isBuffetPage?: boolean
}

interface IBuffetCalculatorProps extends IBaseCalculatorProps {
  dishId: number
  orderLineId?: never
}

interface IWaiterCalculatorProps extends IBaseCalculatorProps {
  dishId?: never
  orderLineId: number
}

type TCalculatorProps = IBuffetCalculatorProps | IWaiterCalculatorProps

const Calculator = ({
  dishName,
  dishId,
  sourceId,
  sourceName,
  resetActiveDish,
  isSuccessWeight,
  currentWeight,
  isBuffetPage = true,
  orderLineId,
}: TCalculatorProps) => {
  const [searchValue, setSearchValue] = useState('')
  const { data: allTrays } = useGetMilletsQuery(
    { limit: LIMIT.base, search: searchValue },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const { isKeyboard } = useAppSelector((state) => state.buffet)
  const [isRefund, setIsRefund] = useState(false)
  const [tray, setTray] = useState<IMillet | null>(null)
  const [plateWeight, setPlateWeight] = useState<number>(0)
  const [addDimension] = useAddDimensionMutation()
  const [isDisabled, setIsDisabled] = useState(false)
  const [keyboardVisibility, setKeyboardVisibility] = useState(false)
  const keyboard = useRef<any>(null)
  const refRoot = useRef<HTMLDivElement | null>(null)
  const refChild = useRef<HTMLDivElement | null>(null)
  const closeCalculator = () => !isDisabled && resetActiveDish()

  const weightWithoutPlate = useMemo(() => {
    if ((tray?.milletWeight || plateWeight) && isSuccessWeight) {
      return +(
        tray?.milletWeight
          ? currentWeight - tray.milletWeight - plateWeight
          : currentWeight - plateWeight
      ).toFixed(3)
    } else {
      return 0
    }
  }, [currentWeight, tray?.milletWeight, isSuccessWeight, plateWeight])

  const dimensionsByRole = useMemo(
    () =>
      orderLineId
        ? { weightTare: plateWeight, orderLine: orderLineId }
        : {
            dish: dishId,
            refund: isRefund,
          },
    [isRefund, orderLineId, plateWeight, dishId],
  )

  const sendDimensionsToDb = () => {
    if (weightWithoutPlate > 0 && !isDisabled) {
      setIsDisabled(true)
      addDimension({
        order: sourceId,
        millet: tray?.id,
        ...dimensionsByRole,
      } as TCreateDimension)
        .unwrap()
        .then(() => {
          AppNotification({
            msg: 'Успешно добавлено',
            type: NotificationType.success,
          })
        })
        .catch(() => {})
        .finally(() => {
          setIsDisabled(false)
          resetActiveDish()
        })
    }
  }

  const handlerScrollUp = useCallback(() => {
    scrollUp(refRoot, refChild.current!.children[0].clientHeight)
  }, [refRoot, refChild])

  const handlerScrollDown = useCallback(() => {
    scrollDown(refRoot, refChild.current!.children[0].clientHeight)
  }, [refRoot, refChild])

  const chosePlate = (weight: number) => {
    if (isSuccessWeight) {
      weight === plateWeight ? setPlateWeight(0) : setPlateWeight(weight)
    }
  }

  const chooseTare = (arg: IMillet) =>
    tray && tray.id === arg.id ? setTray(null) : setTray(arg)

  const onChangeKeyboard = (val: string) => setSearchValue(val)

  const onChangeTrayName = (val: string) => {
    setSearchValue(val)
    keyboard.current.setInput(val)
  }

  useEffect(() => {
    if (searchValue && !keyboard.current?.getInput() && keyboardVisibility) {
      keyboard.current.setInput(searchValue)
    }
  }, [searchValue, keyboardVisibility])

  const showKeyboard = () => {
    if (isKeyboard) {
      setKeyboardVisibility(true)
    }
  }

  return (
    <div className="calculator-root">
      <div
        className={`calculator-wrapper ${isBuffetPage ? '' : 'page-waiter'}`}
      >
        {!isBuffetPage ? (
          <div className="weight-sidebar">
            {mockPlatesWeight.map((el) => (
              <AppBtn
                title={`${el * 1000}г`}
                color={
                  el === plateWeight
                    ? AppBtnColor.violetWhite
                    : AppBtnColor.whiteGray
                }
                shadow={AppBtnShadow.gray}
                sized={AppBtnSize.lg}
                onClick={() => chosePlate(el)}
                key={`weight-pate-${el}`}
              />
            ))}
          </div>
        ) : null}
        <div className="calculator">
          {keyboardVisibility && (
            <div className="keyboard-elements">
              <AppBtn
                iconClassName="close"
                radius={AppBtnRadius.round}
                color={AppBtnColor.violetWhite}
                onClick={() => setKeyboardVisibility(!keyboardVisibility)}
              />
              <KeyboardWrapper
                onChange={onChangeKeyboard}
                keyboard={keyboard}
                lang={Lang.RU}
              />
            </div>
          )}
          {isBuffetPage ? (
            <>
              <h4>
                Категория:
                <span>{sourceName}</span>
              </h4>
              <h4>
                Блюдо: <span>{dishName}</span>
              </h4>
            </>
          ) : (
            <>
              <h6>
                Стол №<span>{sourceName}</span>
              </h6>
              <h6>
                Блюдо: <span>{dishName}</span>
              </h6>
            </>
          )}
          <div className="counting-display">
            <AmountItem title="Общий вес" value={currentWeight} />
            {tray ? (
              <>
                <div className="math-symbol">-</div>
                <AmountItem title="Вес тары" value={tray.milletWeight} />
              </>
            ) : null}
            {plateWeight ? (
              <>
                <div className="math-symbol">-</div>
                <AmountItem title="Вес тары" value={plateWeight} />
              </>
            ) : null}
            {tray || plateWeight ? (
              <>
                <div className="math-symbol">=</div>
                <AmountItem title="Вес без тары" value={weightWithoutPlate!} />
              </>
            ) : null}
          </div>
          <div className="wrapper-heading-btn">
            <div className="row">
              {isBuffetPage && (
                <div className="col-2">
                  <Checkbox
                    name="refund"
                    text="возврат"
                    size={CheckboxSize.lg}
                    value={isRefund}
                    onChange={setIsRefund}
                  />
                </div>
              )}
              <div className="col-8">
                <InputField
                  placeholder="Введите название тары"
                  sized={InputSize.sm}
                  iconPosition={IconPosition.right}
                  value={searchValue}
                  onFocus={showKeyboard}
                  onChange={onChangeTrayName}
                  iconName="close"
                  onClick={() => onChangeTrayName('')}
                />
              </div>
              <div className={isBuffetPage ? 'col-2' : 'col-4'}>
                <div className="btn-block">
                  <AppBtn
                    iconClassName="arrow-up"
                    sized={AppBtnSize.sm}
                    color={AppBtnColor.outlineViolet}
                    onClick={handlerScrollUp}
                  />
                  <AppBtn
                    iconClassName="arrow-down"
                    sized={AppBtnSize.sm}
                    color={AppBtnColor.outlineViolet}
                    onClick={handlerScrollDown}
                  />
                </div>
              </div>
            </div>
          </div>
          {allTrays?.results ? (
            <div className="column" ref={refRoot}>
              <div className="row" ref={refChild}>
                {allTrays.results.map((el) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6"
                    key={el.id}
                  >
                    <OrderLinePlateMemoized
                      tray={tray}
                      info={el}
                      onClick={chooseTare}
                      isSuccess={isSuccessWeight}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Spinner
              size={SpinnerSize.lg}
              color={SpinnerColor.violet}
              spinnerPosition={SpinnerPosition.center}
            />
          )}
          <div className="btn-block">
            <AppBtn
              sized={isBuffetPage ? AppBtnSize.xxl : AppBtnSize.xl}
              color={
                isDisabled ? AppBtnColor.lightRedWhite : AppBtnColor.redWhite
              }
              iconClassName="arrow"
              onClick={closeCalculator}
            />
            <AppBtn
              sized={isBuffetPage ? AppBtnSize.xxl : AppBtnSize.xl}
              color={
                weightWithoutPlate <= 0 || isDisabled
                  ? AppBtnColor.lightGreenWhite
                  : AppBtnColor.greenWhite
              }
              onClick={sendDimensionsToDb}
            >
              {isDisabled ? <Spinner /> : <i className="an-ico an-ico-popup" />}
            </AppBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CalculatorMemoized = memo(Calculator, compareProps)
