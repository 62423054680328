import React from 'react'

export interface IChooseItems {
  [key: number]: boolean
}

const TableAllDishes = () => {
  return (
    <>
      <div className={'col-10 m-l-auto m-r-auto'}>
        {/*<div className="filter-panel">*/}
        {/*  <div className="row">*/}
        {/*    <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-12">*/}
        {/*      <InputField*/}
        {/*        type="text"*/}
        {/*        value={value}*/}
        {/*        onChange={setValue}*/}
        {/*        iconName="search"*/}
        {/*        placeholder="Например стейк"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12">*/}
        {/*      <div className="btn-block actions">*/}
        {/*        <AppBtn*/}
        {/*          sized={AppBtnSize.md}*/}
        {/*          color={AppBtnColor.outlineViolet}*/}
        {/*          title="Добавить блюдо"*/}
        {/*          iconClassName="add"*/}
        {/*          path={`../${AllRoutes.addNewDish.path}`}*/}
        {/*        />*/}
        {/*        <AppBtn*/}
        {/*          sized={AppBtnSize.md}*/}
        {/*          color={AppBtnColor.outlineViolet}*/}
        {/*          title="Импортировать"*/}
        {/*          iconClassName="import"*/}
        {/*        />*/}
        {/*        <AppBtn*/}
        {/*          sized={AppBtnSize.md}*/}
        {/*          color={AppBtnColor.outlineViolet}*/}
        {/*          title="Экспортировать"*/}
        {/*          iconClassName="export"*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="wrapper-table">*/}
        {/*  <table className="table">*/}
        {/*    <colgroup>*/}
        {/*      <col style={{ width: '100px' }} />*/}
        {/*      <col style={{ width: '280px' }} />*/}
        {/*      <col span={4} style={{ width: '180px' }} />*/}
        {/*    </colgroup>*/}
        {/*    <thead>*/}
        {/*      <tr>*/}
        {/*        {headerData.map((el) => (*/}
        {/*          <th key={el.title}>{el.title}</th>*/}
        {/*        ))}*/}
        {/*      </tr>*/}
        {/*    </thead>*/}
        {/*    <tbody>*/}
        {/*      {mockDataDishes.map((el) => (*/}
        {/*        <tr key={`report-item-${el.id}`}>*/}
        {/*          <td>{el.id}</td>*/}
        {/*          <td>*/}
        {/*            <Link to={`${el.id}`}>{el.name}</Link>*/}
        {/*          </td>*/}
        {/*          <td>{el.weight}</td>*/}
        {/*          <td>{el.min}</td>*/}
        {/*          <td>{el.category}</td>*/}
        {/*          <td className="delete">Удалить</td>*/}
        {/*        </tr>*/}
        {/*      ))}*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*</div>*/}
      </div>
    </>
  )
}

export default TableAllDishes
