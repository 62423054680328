import React from 'react'
import { Link } from 'react-router-dom'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import { AllRoutes } from '../../core/routes/AllRoutes'
import BurgerMenu from '../burgerMenu/BurgerMenu'
import CurrentDate from '../../components/currentDate/CurrentDate'

const HeaderAdminPage = () => {
  const { data: currentUser } = useGetCurrentUserQuery()

  return (
    <header className="header admin">
      <BurgerMenu />
      <div className="container-fluid">
        <div className="wrapper-heading">
          <Link
            to={currentUser ? `${AllRoutes.user.path}` : ''}
            className="link"
          >
            <i className="an-ico an-ico-user" />
            {currentUser && <span>{currentUser.name}</span>}
          </Link>
          <h4 className="color-White text-center">
            {currentUser?.userRoles.restaurant.name
              ? currentUser?.userRoles.restaurant.name
              : ''}
          </h4>
          <h4 className="color-White text-end">
            <CurrentDate />
          </h4>
        </div>
      </div>
    </header>
  )
}

export default HeaderAdminPage
