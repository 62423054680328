import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  useGetCategoryByIdQuery,
  useGetCategoryByRestaurantQuery,
} from '../../core/api/CategoryApi'
import { useUpdateDishMutation } from '../../core/api/DishApi'
import { ICategory } from '../../core/api/dto/CategoriesObjDto'
import {
  IIngredients,
  IDishWithVersions,
  IDish,
} from '../../core/api/dto/DishObjDto'
import { controller } from '../../utils/validationController'
import InputField, { InputSize } from '../input/InputField'
import { AppNotification, NotificationType } from '../notification/Notification'
import PhotoUploader from '../photoUploader/PhotoUploader'
import Select, { SelectSize } from '../select/Select'
import Footer from './Footer'
import IngredientTable from './IngredientTable'
import SourceInfo from './SourceInfo'
import VersionsCard from './VersionsCard'
import Warning from './Warning'
import Workshops from './Workshops'
import { AppInput, TextareaInputRowsType } from '../AppInput/AppInput'
interface IPropsRecipeCard {
  info: IDishWithVersions
  isEditable: boolean
}

const RecipeCard = ({ info, isEditable }: IPropsRecipeCard) => {
  const [disabled, setDisabled] = useState(false)
  const { technologicalCatalogRestaurantId } = useParams()
  const { data: allCategories } = useGetCategoryByRestaurantQuery(
    technologicalCatalogRestaurantId!,
  )
  const [currentCard, setCurrentCard] = useState<IDish | IDishWithVersions>(
    info,
  )
  const { data: currentCategory, isFetching } = useGetCategoryByIdQuery(
    currentCard.category,
    {
      skip: !currentCard.category,
    },
  )
  const [dataForUpdate, setDataForUpdate] = useState<Partial<IDish>>({
    id: info.id,
  })

  const [version, setVersion] = useState<number | null>(null)

  const [updateDish] = useUpdateDishMutation()
  const navigate = useNavigate()

  useEffect(() => {
    if (version) {
      const currentVersion = info.versions.find((el) => el.version === version)
      setCurrentCard(currentVersion!.dish)
      setDataForUpdate({ id: info.id })
    } else {
      setCurrentCard(info)
    }
  }, [version, info])

  const updateCard = () => {
    controller.validate()
    const isNotCardChange = JSON.stringify(info) === JSON.stringify(currentCard)

    isNotCardChange &&
      AppNotification({
        msg: 'Версия технологичнской карты не отличается от предыдущей, если хотите вернуться, нажмите кнопку назад.',
        type: NotificationType.info,
      })

    if (!controller.subscribers.length && !isNotCardChange && !disabled) {
      setDisabled(true)
      updateDish(dataForUpdate)
        .unwrap()
        .then(() => {
          AppNotification({
            msg: 'Успешно обновлено!',
            type: NotificationType.success,
          })
          navigate(-1)
        })
        .catch(() => {})
        .finally(() => {
          setDisabled(false)
        })
    }
  }
  const changeDataForUpdate = useCallback(
    (propsName: keyof IDish, value: IDish[typeof propsName]) => {
      if (JSON.stringify(value) !== JSON.stringify(info[propsName])) {
        setDataForUpdate({ ...dataForUpdate, [propsName]: value })
      } else if (propsName in dataForUpdate) {
        const newState = Object.entries(dataForUpdate)
          .filter(([key, _]) => key !== propsName)
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {} as Partial<ICategory>,
          )
        setDataForUpdate({ ...newState })
      }
    },
    [dataForUpdate, info],
  )
  const changeField = (
    propsName: keyof IDish,
    value: IDish[typeof propsName],
  ) => {
    setCurrentCard({ ...currentCard, [propsName]: value })
    changeDataForUpdate(propsName, value)
  }

  const changeName = (val: string) => changeField('name', val)
  const changeDescription = (val: string) => changeField('description', val)
  const changeImage = (val: string | null) => changeField('image', val)
  const changeIngredients = (val: IIngredients[]) =>
    changeField('ingredients', val)
  const changeCategory = (val: ICategory) => {
    changeField('category', val.id)
  }

  return (
    <div className="row">
      <div className="col-xxl-1 col-xl-1 col-lg-1">
        {info.versions.length ? (
          <VersionsCard
            versions={info.versions}
            setVersion={setVersion}
            version={version}
          />
        ) : null}
      </div>
      <div className="col-xxl-10 col-xl-10 col-lg-10">
        <div className="cooking-card">
          <div className="row">
            <div className="col-12">
              <h3>Первичное описание и фотография</h3>
            </div>
            {version && <Warning getLatestVersion={() => setVersion(null)} />}
            <div className="col-8">
              <InputField
                placeholder="Название блюда"
                sized={InputSize.sm}
                value={currentCard.name}
                onChange={changeName}
                required={true}
                disabled={!isEditable || Boolean(version)}
              />
            </div>
            <div className="col-4">
              <Select
                options={
                  allCategories?.results.length ? allCategories?.results : []
                }
                onChange={changeCategory}
                value={
                  currentCategory && !isFetching
                    ? currentCategory
                    : ({} as ICategory)
                }
                name="name"
                id="id"
                icon="iconPreview"
                size={SelectSize.sm}
                disabled={!isEditable || !!version}
                isSearch={true}
              />
            </div>
            <div className="col-8">
              <AppInput
                type={'textarea'}
                value={currentCard.description ? currentCard.description : ''}
                placeholder="Описание, правила подачи блюда"
                onChange={changeDescription}
                disabled={!isEditable || !!version}
                rows={TextareaInputRowsType['rows-1']}
              />
            </div>
            <div className="col-4">
              <PhotoUploader
                photo={currentCard.image}
                setPhoto={changeImage}
                isEditable={isEditable && !version}
                iconClassName="search"
              />
            </div>
            {currentCard.ingredients.length || (isEditable && !version) ? (
              <div className="col-12">
                <IngredientTable
                  isEditable={isEditable && !version}
                  ingredients={currentCard.ingredients}
                  changeIngredients={changeIngredients}
                />
              </div>
            ) : null}
            {!version && (
              <div className="col-12">
                <Workshops
                  setCurrentCard={setCurrentCard}
                  currentCard={currentCard}
                  changeDataForUpdate={changeDataForUpdate}
                  isDisabled={!isEditable || !!version}
                />
              </div>
            )}
            <div className="col-12">
              {info.source && (
                <SourceInfo
                  source={info.source.sourceType}
                  sourceName={
                    info.sourceRepresent ? info.sourceRepresent : info.name
                  }
                />
              )}
            </div>
            <Footer
              onClick={updateCard}
              isEditable={isEditable && !version}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecipeCard
