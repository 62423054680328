import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import 'react-simple-keyboard/build/css/index.css'
import { ICreateDish } from '../../core/api/dto/OrderObjDto'
import { useGetExternalDishByNameQuery } from '../../core/api/ExternalDishApi'
import {
  useGetCurrentWeightQuery,
  useGetOrderByIdQuery,
} from '../../core/api/OrderApi'
import { useAppSelector } from '../../core/store/store'
import useFilterQuery from '../../utils/useFilterQuery'
import { AdditionBuffetCardsMemoized } from '../additionBuffetCard/AdditionBuffetCards'
import { CalculatorMemoized } from '../calculator/Calculator'
import { FixedSearchPanelMemoized } from '../fixedPanel/FixedSearchPanel'
import Popup from '../popup/Popup'
import Spinner, {
  SpinnerColor,
  SpinnerPosition,
  SpinnerSize,
} from '../spinner/Spinner'

const BuffetCardMapCatalogAddition = () => {
  const { isFetchError } = useAppSelector((state) => state.connection)
  const { isFullModeAdditionDish } = useAppSelector((state) => state.buffet)
  const { searchString, getCurrentSearchParamValue } = useFilterQuery()
  const { buffetOrderId } = useParams()
  const { data: allDishById } = useGetOrderByIdQuery(String(buffetOrderId), {
    skip: !buffetOrderId,
  })
  const [activeDish, setActiveDish] = useState<ICreateDish | null>(null)
  const {
    data: externalDish,
    refetch,
    isFetching,
  } = useGetExternalDishByNameQuery(
    searchString && getCurrentSearchParamValue('search')!.length > 2
      ? searchString
      : '',
  )
  const [cards, setCards] = useState(
    externalDish?.results.length ? externalDish.results : ([] as ICreateDish[]),
  )
  const { data, isError, isSuccess } = useGetCurrentWeightQuery(null, {
    pollingInterval: 1000,
    skip: Boolean(isFetchError),
  })

  const refRoot = useRef<HTMLDivElement | null>(null)
  const refChild = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    externalDish?.results.length
      ? setCards(externalDish.results)
      : setCards([] as ICreateDish[])
  }, [externalDish?.results])

  const handlerSetActiveDish = (el: ICreateDish) => {
    if (!isError && !data?.errorMsg) {
      setActiveDish(el)
    }
  }

  return (
    <>
      {activeDish && (
        <Popup>
          <CalculatorMemoized
            dishName={activeDish.name}
            dishId={activeDish.id}
            sourceId={allDishById!.id}
            sourceName={allDishById!.sourceRepresent}
            resetActiveDish={() => setActiveDish(null)}
            currentWeight={data?.weight ? data.weight : 0}
            isSuccessWeight={isSuccess}
          />
        </Popup>
      )}
      <FixedSearchPanelMemoized
        refChild={refChild}
        refRoot={refRoot}
        refetch={refetch}
      />
      {!isFetching ? (
        <div className="column cards" ref={refRoot}>
          <div className="container-fluid">
            <div
              className={`row row-cols-xl-6 ${
                isFullModeAdditionDish ? 'row-cols-lg-4' : 'row-cols-lg-6'
              } row-cols-md-3`}
              ref={refChild}
            >
              <AdditionBuffetCardsMemoized
                cards={cards}
                handlerSetActiveDish={handlerSetActiveDish}
              />
            </div>
          </div>
        </div>
      ) : (
        <Spinner
          color={SpinnerColor.violet}
          size={SpinnerSize.lg}
          spinnerPosition={SpinnerPosition.center}
        />
      )}
    </>
  )
}

export default BuffetCardMapCatalogAddition
