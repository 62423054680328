import { api } from './BaseApi'
import {
  IUserResponseDto,
  IUsersRequestDto,
  IUsersResponseDto,
} from './dto/UserDto'

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsersList: builder.query<IUsersResponseDto, IUsersRequestDto>({
      query: (params) => ({
        url: '/user/',
        params: { ...params },
      }),
      providesTags: ['User'],
    }),
    getCurrentUser: builder.query<IUserResponseDto, void | {}>({
      query: () => ({
        url: '/user/current/',
      }),
      providesTags: ['User'],
    }),
  }),
})

export const { useGetUsersListQuery, useGetCurrentUserQuery } = userApi
