import React from 'react'

interface ICpuBlockProps {
  value?: number
  isDelete: boolean
}

export const CpuBlock = ({ isDelete, value }: ICpuBlockProps) => {
  return (
    <div className={`cpu-block ${isDelete ? 'delete-item' : ''}`}>
      <i className="an-ico an-ico-cpu" />
      {value && <span>{value}%</span>}
    </div>
  )
}
