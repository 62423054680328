import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AppScrollWrapperNew } from '../../widgets/AppScrollWrapper/AppScrollWrapper'
import { useSearchParams } from 'react-router-dom'
import './AppTable.css'
import { TableActionsBlock } from './tableComponents/TableActionsBlock'
import { TableColgroupBlock } from './tableComponents/TableColgroupBlock'
import { TableRow } from './tableComponents/TableRow'
import { TablePaginationBlock } from './tableComponents/TablePaginationBlock'
import { AppLoader, LoaderType } from 'components/AppLoader'
import { IAppTable, IHeaderData, TSelector } from './AppTable'
import { TableHeaderDndBlock } from './tableComponents/TableHeaderDndBlock'

interface IAppTableDndProps<T, TKey> extends IAppTable<T, TKey> {
  rerender?: any
  setHeaderData: (value: IHeaderData[]) => void
}

export const AppTableDnd = <
  T extends { id: number; archived?: boolean },
  TKey extends keyof T,
>({
  rerender,
  wrapperClassName,
  idName,
  headerData,
  data,
  tableDataSelectors,
  chooseActions,
  setTableDataHandler,
  isLoading,
  chooseMode,
  importantItem,
  chooseItems,
  setChooseItems,
  withoutPagination,
  setCurrentSortFn,
  bigPaddingBottom,
  subTableRepresent,
  setHeaderData,
}: IAppTableDndProps<T, TKey>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const ordering = searchParams.get('ordering')
  const [tableData, setTableData] = useState<T[]>([])
  const [tableSelectors, setTableSelectors] = useState<TSelector<T, TKey>[]>([])
  const tableRef = useRef<HTMLDivElement | null>(null)

  const chooseItemsHandler = useCallback((value: boolean, id: number) => {
    setChooseItems &&
      setChooseItems((prevState) => ({ ...prevState, [id]: value }))
  }, [])

  useEffect(() => {
    if (!withoutPagination) {
      searchParams.set('limit', '40')
      setSearchParams(searchParams.toString())
    }
  }, [])

  useEffect(() => {
    setTableData(data.results)
    !!setTableDataHandler && setTableDataHandler(data.results)
  }, [data, setTableDataHandler])

  function handleScroll(e: any) {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    if (scrollTop + clientHeight >= scrollHeight) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  useEffect(() => {
    setTableSelectors(
      tableDataSelectors.map((item, index) => {
        return tableDataSelectors[`${headerData[index]!.startPosition!}`]
      }),
    )
  }, [headerData, rerender, chooseMode])

  return (
    <div className={`app-table-wrap ${wrapperClassName}`}>
      {isLoading && <AppLoader loaderType={LoaderType.table} />}
      <TableActionsBlock
        chooseActions={chooseActions}
        tableData={tableData}
        chooseItems={chooseItems}
        setChooseItems={setChooseItems}
        chooseMode={chooseMode}
      />
      <div className={'app-table'} onScroll={handleScroll} ref={tableRef}>
        <AppScrollWrapperNew theme="table">
          <table>
            <TableColgroupBlock
              chooseMode={chooseMode}
              importantMode={!!importantItem}
              subTableRepresent={!!subTableRepresent}
              headerData={headerData}
            />
            <TableHeaderDndBlock
              headerData={headerData}
              setHeaderData={setHeaderData}
              subTableRepresent={!!subTableRepresent}
              chooseMode={chooseMode}
              setCurrentSortFn={setCurrentSortFn}
              ordering={ordering}
              importantMode={!!importantItem}
            />
            <tbody>
              {tableData.map((item, itemIndex) => {
                return (
                  <TableRow
                    tableDataSelectors={tableSelectors}
                    chooseItems={chooseItems}
                    chooseItemsHandler={chooseItemsHandler}
                    item={item}
                    itemIndex={itemIndex}
                    chooseMode={chooseMode}
                    bigPaddingBottom={bigPaddingBottom}
                    key={`table-row-c-${itemIndex}`}
                    subTableRepresent={subTableRepresent}
                    idName={idName}
                    importantItem={importantItem}
                  />
                )
              })}
              {!tableData.length && (
                <tr>
                  <td
                    colSpan={
                      tableDataSelectors.length +
                      (chooseMode ? 1 : 0) +
                      (!!importantItem ? 1 : 0)
                    }
                    className="result-not-found"
                  >
                    <div>Выберете фильтр</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </AppScrollWrapperNew>
        <TablePaginationBlock
          isLoading={!!isLoading}
          dataCount={data.count}
          dataNext={!!data.next}
          tableRef={tableRef}
        />
      </div>
    </div>
  )
}
