import { toast } from 'react-toastify'

export enum NotificationType {
  error = 'error',
  success = 'success',
  info = 'info',
  warning = 'warning',
  warn = 'warn',
}

export interface INotification {
  msg: string
  type: NotificationType
  title?: string
  onClick?: () => void
  autoClose?: number
}

const Msg = ({ title, msg }: { title: string; msg: string }) => (
  <>
    <h6>{title}</h6>
    <div>{msg}</div>
  </>
)

export const AppNotification = ({
  msg,
  title,
  onClick,
  autoClose = 5000,
  type = NotificationType.error,
}: INotification) => {
  toast[type](title ? <Msg title={title} msg={msg} /> : msg, {
    position: 'top-right',
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClick,
  })
}
