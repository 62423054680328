import React, { ChangeEvent } from 'react'

interface IAppToggle {
  value: boolean
  disabled?: boolean
  onChange?: (value: boolean) => void
  isDelete?: boolean
}

export const AppToggle = ({
  value,
  onChange,
  disabled,
  isDelete,
}: IAppToggle) => {
  const onChangeHandler = !!onChange
    ? (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked)
      }
    : undefined

  const isDeleteStyle = isDelete ? 'checkbox-is-delete' : ''
  return (
    <label className={`checkbox-slider ${isDeleteStyle}`}>
      <input
        type="checkbox"
        className="checkbox"
        id="input-p-slider"
        checked={value}
        onChange={onChangeHandler}
        disabled={disabled}
      />
      <span className={`slider${!onChange ? ' active-not' : ''}`} />
    </label>
  )
}
