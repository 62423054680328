import React from 'react'
import { Link } from 'react-router-dom'
import { useGetCurrentUserQuery } from '../../core/api/UserApi'
import { AllRoutes } from '../../core/routes/AllRoutes'
import CurrentDate from '../../components/currentDate/CurrentDate'

const BaseHeader = () => {
  const { data: currentUser } = useGetCurrentUserQuery()

  return (
    <header className="header">
      <div className="container-fluid">
        <div className="wrapper-heading">
          <Link to={`${AllRoutes.user.path}`} className="link">
            <i className="an-ico an-ico-user" />
            {currentUser?.name && <span>{currentUser.name}</span>}
          </Link>
          {currentUser?.userRoles.restaurant.name && (
            <h4 className="color-White">
              {currentUser?.userRoles.restaurant.name}
            </h4>
          )}
          <h4 className="color-White">
            <CurrentDate />
          </h4>
        </div>
      </div>
    </header>
  )
}

export default BaseHeader
