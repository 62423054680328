import { AppLoader, LoaderType } from 'components/AppLoader'
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export enum AppBtnColor {
  base = '',
  whiteViolet = 'white-violet',
  violetWhite = 'violet-white',
  whiteGray = 'white-gray',
  whiteLightGray = 'white-light-gray',
  outlineWhite = 'outline-white',
  outlineViolet = 'outline-violet',
  outlineGreen = 'outline-green',
  outlineGray = 'outline-gray',
  outlineGrayLight = 'outline-gray-light',
  redWhite = 'red-white',
  greenWhite = 'green-white',
  lightGreenWhite = 'light-green-white',
  lightRedWhite = 'light-red-white',
  violetLightWhite = 'violet-light-white',
  violetGray = 'violet-gray',
  lightViolet = 'light-violet',
  extraVioletViolet = 'extra-violet-violet',
  whiteOutlineViolet = 'white-outline-violet',
}

export enum AppBtnSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
  fullWidth = 'full-width',
  onlyIco = 'only-ico',
}

export enum IconPosition {
  left = 'left',
  right = 'right',
}

export enum AppBtnShadow {
  base = '',
  gray = 'shadow-gray',
}

export enum AppBtnRadius {
  base = '',
  r30 = 'rounded',
  round = 'round',
}

interface IBtnBaseProps {
  minWidth?: string
  path?: string
  sized?: AppBtnSize
  color?: AppBtnColor
  shadow?: AppBtnShadow
  radius?: AppBtnRadius
  onClick?: () => void
  disabled?: boolean
  setRef?: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<HTMLDivElement | null> | null>
  >
  isLoading?: boolean
  className?: string
}

interface IButtonChildren extends IBtnBaseProps {
  title?: never
  value?: never
  iconClassName?: never
  iconPosition?: never
  children: JSX.Element
}
interface IButtonInput extends IBtnBaseProps {
  title?: never
  value: string
  iconClassName?: never
  iconPosition?: never
  children?: never
}
interface IButtonBase extends IBtnBaseProps {
  title?: string
  value?: never
  iconClassName?: string
  iconPosition?: string
  children?: never
}

type TPropsBtn = IButtonChildren | IButtonInput | IButtonBase

const AppBtn = ({
  minWidth,
  iconClassName,
  title,
  value,
  children,
  path,
  sized,
  color = AppBtnColor.base,
  shadow = AppBtnShadow.base,
  radius = AppBtnRadius.base,
  iconPosition = IconPosition.left,
  onClick,
  disabled,
  setRef,
  isLoading,
  className,
}: TPropsBtn) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref.current && setRef) {
      setRef(() => ref)
    }

    return () => {
      if (ref.current && setRef) {
        setRef(null)
      }
    }
  }, [ref.current, setRef])

  const btnChildren = () => {
    if (title && iconClassName && iconPosition === 'right') {
      return (
        <>
          <span>{t(title)}</span>
          <i className={`an-ico an-ico-${iconClassName}`} />
        </>
      )
    } else if (title && iconClassName) {
      return (
        <>
          <i className={`an-ico an-ico-${iconClassName}`} />
          <span>{t(title)}</span>
        </>
      )
    } else if (!title && iconClassName) {
      return <i className={`an-ico an-ico-${iconClassName}`} />
    } else if (title) {
      return <span>{t(title)}</span>
    } else {
      return children
    }
  }

  if (isLoading) {
    return (
      <div className={`btn ${sized} ${color} ${shadow} ${radius} disabled`}>
        <AppLoader loaderType={LoaderType.dimension} relative white />
      </div>
    )
  } else if (value) {
    return (
      <input
        value={value}
        type="submit"
        className={`${sized} ${color} ${shadow} ${radius}`}
        onClick={onClick}
      />
    )
  } else if (path) {
    return (
      <Link to={path} className={`btn ${sized} ${color} ${shadow} ${radius}`}>
        {btnChildren()}
      </Link>
    )
  } else {
    return (
      <div
        className={`btn ${sized ? sized : ''}${
          className ? ' ' + className : ''
        } ${color} ${shadow} ${radius} ${disabled ? 'btn-disabled' : ''}`}
        onClick={!disabled ? onClick : undefined}
        ref={ref}
        style={{ minWidth }}
      >
        {btnChildren()}
      </div>
    )
  }
}

export default AppBtn
