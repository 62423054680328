import React from 'react'
import { Outlet } from 'react-router'
import HeaderChefPage from '../widgets/header/HeaderChefPage'
import NavSidebar from '../components/sidebar/NavSidebar'

const TechnologicalMapCatalog = () => {
  return (
    <div className="chef-page">
      <NavSidebar />
      <main className="main">
        <HeaderChefPage />
        <Outlet />
      </main>
    </div>
  )
}

export default TechnologicalMapCatalog
