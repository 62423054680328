import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

import { RootState } from '../store/rootReducer'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  FetchArgs,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { parseJwt } from '../../helpers/jwtDecode'
import { deleteCredentials } from '../store/auth/authSlice'
import {
  ILoginGetCodeRequestDto,
  ILoginSendCodeRequestDto,
} from './dto/AuthDto'
import { IUserResponseDto } from './dto/UserDto'

export interface ICustomErrorDto {
  data: { status_code: number; status_text: string }
  status: number
}

export const LIMIT = {
  base: 1000,
  search: 28,
  buffet: 60,
  waiter: 24,
  chef: 20,
}

export const BASE_URL = process.env.REACT_APP_API_PROTOCOL
  ? `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/api/v1`
  : '/api/v1'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).foodBoxAuth.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    const requestToken = result.meta
      ? result.meta.request.headers.get('authorization')
      : null
    const { roles: currentRole } = parseJwt(requestToken) // TODO mayBe use from path, not from token, course in some case may have not same roles from path and token

    if (currentRole) {
      api.dispatch(deleteCredentials())
    } else {
      api.dispatch(deleteCredentials())
    }
  }
  return result
}

export const api = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    ICustomErrorDto,
    {},
    FetchBaseQueryMeta
  >,
  tagTypes: [
    'User',
    'Restaurant',
    'Category',
    'Order',
    'Dish',
    'OrderLine',
    'TokenMsg',
    'ВuffetDish',
    'BuffetReport',
    'BuffetMillet',
    'PlanMenu',
    'PlanMenuType',
    'PlanMenuTemplate',
    'Tags',
    'Stations',
    'PlanMenuControl',
    'GuestType',
    'CompareReport',
    'Task',
  ],
  endpoints: (builder) => ({
    loginGetCode: builder.mutation<null, ILoginGetCodeRequestDto>({
      query: ({ ...credentials }) => {
        console.log('credentials => ', credentials)
        return {
          url: `/auth/buffet/send/`,
          method: 'POST',
          body: credentials,
        }
      },
      invalidatesTags: ['User'],
    }),
    loginSendCode: builder.mutation<
      { token: string },
      ILoginSendCodeRequestDto
    >({
      query: ({ ...credentials }) => ({
        url: `/auth/buffet/sign_in/`,
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['User'],
    }),
    loginByUsername: builder.mutation<
      { token: string },
      { login: string; password: string }
    >({
      query: (body) => ({
        url: `/auth/buffet/sign_in_password/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    logout: builder.mutation<IUserResponseDto, void>({
      query: () => ({
        url: `/auth/logout/`,
        method: 'POST',
      }),
      invalidatesTags: [
        'User',
        'Restaurant',
        'Category',
        'Order',
        'Dish',
        'OrderLine',
      ],
    }),
  }),
})

export const {
  useLoginGetCodeMutation,
  useLoginSendCodeMutation,
  useLoginByUsernameMutation,
  useLogoutMutation,
} = api
