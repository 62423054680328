export function base64ToBlob(base64String: string, contentType: string) {
  const sliceSize = 1024
  const byteCharacters = atob(base64String)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export function handleBase64Image(
  base64String: string,
  fileName: string,
): File {
  const contentType = base64String.split(';')[0].split(':')[1]
  const blob = base64ToBlob(base64String, contentType)

  return new File([blob], fileName, { type: contentType })
}
