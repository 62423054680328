import { IBaseRequestDto, IBaseResponseDto } from './BaseDto'
import { IWorkshop } from './DishObjDto'
import { CorrespondentType, DimensionType } from './BuffetReportDto'

export enum StatusOrder {
  Created = 'Created',
  Working = 'Working',
  FullFilled = 'FullFilled',
}

export enum StatusPlanMenu {
  Opened = 'Opened',
  Closed = 'Closed',
}

export enum OrderType {
  Regular = 'Regular',
  Buffet = 'Buffet',
}

export enum CookingStatus {
  Waiting = 'Waiting',
  Cooking = 'Cooking',
  Cook_done = 'Cook_done',
}

export interface ICurrentWeight {
  weight: number
  tare: number
  errorMsg: string
}

interface IBaseDimension {
  order: number
  dish?: number
  millet?: number
  refund?: boolean
  weightTare?: number
  orderLine?: number
}

export interface IBuffetDimension {
  order: number
  dish: number
  millet: number
  dimensionType: DimensionType
  orderLine?: number
  correspondentType?: number
}

interface IWaiterDimension extends IBaseDimension {
  dish?: never
  refund?: never
  weightTare: number
  orderLine: number
}

export type TCreateDimension = IWaiterDimension | IBuffetDimension

export interface ICreateCookDimension {
  order: number
  orderLine: number
  cookingStatus: keyof typeof CookingStatus
}

export interface ICreateDish {
  sourceIdent: string
  name: string
  id: number
  workshops: IWorkshop[]
  categoryRepresent: string | null
}

export interface IOrderLineDimension {
  id: number
  photoUrl: string
  thermoPhotoUrl: string
  temperature: number
  weight: number
  dimensionDatetime: string
  quantity: number
  orderLine: number
  milletType: number
}

interface IOrderLineCookingStatus {
  id: number
  cookingStatusDatetime: string
  cookingStatus: keyof typeof CookingStatus
  orderLine: number
}

export interface IOrderLine {
  id: number
  dish: ICreateDish
  quantity: number
  orderLineDimensions: IOrderLineDimension[]
  orderLineCookingStatus: IOrderLineCookingStatus[]
  comment: string
  orderLineCookingStatuses: IOrderLineCookingStatus | null
}

export interface ITable {
  id: number
  source: number
  sourceIdent: string
  name: string
}

export interface IWaiter {
  name?: string
  email: string
  code: string
  source_ident: string
}

export interface IOrder {
  id: number
  sourceIdent: string
  sourceRepresent: string
  orderRepresent: string
  table: ITable
  orderDate: string
  orderDateStart: string | null
  orderDateEnd: string | null
  status: keyof typeof StatusOrder
  orderType: keyof typeof OrderType
  orderLines: IOrderLine[]
  waiter: IWaiter | null
  workshops: IWorkshop[]
}

export interface IOredeLines extends IBaseResponseDto {
  results: IOrderLine[]
}

export interface IOrdersResponseDto extends IBaseResponseDto {
  results: IOrder[]
}
export interface ICorrespondentTypeRequestDto extends IBaseRequestDto {
  active?: boolean
}
export interface ICorrespondentTypeResponseDto extends IBaseResponseDto {
  results: CorrespondentType[]
}

export interface IOrdersDishTemperatureResponseDto {
  standardTempMin: number
  standardTempMax: number
  tempCurrent: number
}

export interface IResponseModel {
  detail: string
}

export interface IRequestTrainModel {
  batch_size?: number
  num_epochs?: number
  validate_ratio?: number
  balance_diff?: number
}
