import { useEffect, useRef, useState } from 'react'
import { IBasePickerProps } from '../datePicker/DatePicker'
import InputField, { IconPosition, InputSize } from '../input/InputField'
import { useTimePickerValidValue } from '../../utils/useTimePickerValidValue'

const hourOptions = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 0,
].map((num) => num.toString().padStart(2, '0'))
const minuteOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 59].map(
  (num) => num.toString().padStart(2, '0'),
)

const TimePicker = ({
  value,
  onChange,
  text,
  minValue,
  maxValue,
}: IBasePickerProps) => {
  const [isShow, setIsShow] = useState(false)
  const ref = useRef<null | HTMLDivElement>(null)
  const [hourValueValidFn, minValueValidFn, onBlurHandler] =
    useTimePickerValidValue({ minValue, maxValue, value, onChange })

  // const hourDisabled: number | false = useMemo(() => {
  //   if (!!maxValue) {
  //     return maxValue.split(':')[0] ? Number(maxValue.split(':')[0]) : false
  //   }
  //   if (!!minValue) {
  //     return minValue.split(':')[0] ? Number(minValue.split(':')[0]) : false
  //   }
  //   return false
  // }, [minValue, maxValue])
  // const minDisabled: number | false = useMemo(() => {
  //   if (maxValue) {
  //     const currentHourMax = hourDisabled <= Number(value.split(':')[0])
  //     return currentHourMax && maxValue.split(':')[1]
  //       ? Number(maxValue.split(':')[1])
  //       : false
  //   }
  //   if (minValue) {
  //     const currentHourMax = hourDisabled >= Number(value.split(':')[0])
  //     return currentHourMax && minValue.split(':')[1]
  //       ? Number(minValue.split(':')[1])
  //       : false
  //   }
  //   return false
  // }, [value, hourDisabled, minValue, maxValue])

  const outSideClickHandler = (e: any) => {
    e.stopPropagation()
    if (ref.current && !ref.current.contains(e.target)) setIsShow(false)
  }

  useEffect(() => {
    if (isShow) {
      document.addEventListener('click', outSideClickHandler, false)
      document.addEventListener('touchend', outSideClickHandler, false)
    }
    return () => {
      if (isShow) {
        document.removeEventListener('click', outSideClickHandler, false)
        document.removeEventListener('touchend', outSideClickHandler, false)
      }
    }
  }, [isShow])

  const setTimeHandler = (newTime: string, set: 'hour' | 'min') => {
    switch (set) {
      case 'hour': {
        return onChange(
          value ? `${newTime}:${value.split(':')[1]}` : `${newTime}:00`,
        )
      }
      case 'min': {
        return onChange(
          value ? `${value.split(':')[0]}:${newTime}` : `00:${newTime}`,
        )
      }
    }
  }
  // const hourValueValidFn = useCallback(
  //   (value: string) => {
  //     if (maxValue) {
  //       return hourDisabled < Number(value)
  //     }
  //     if (minValue) {
  //       return hourDisabled > Number(value)
  //     }
  //   },
  //   [maxValue, minValue, hourDisabled],
  // )
  // const minValueValidFn = useCallback(
  //   (value: string) => {
  //     if (!!maxValue) {
  //       return minDisabled < Number(value)
  //     }
  //     if (!!minValue) {
  //       return minDisabled > Number(value)
  //     }
  //   },
  //   [minDisabled, maxValue, minValue],
  // )
  // const onBlurHandler = () => {
  //   if (!!maxValue) {
  //     const hour =
  //       hourDisabled < Number(value.split(':')[0])
  //         ? hourDisabled.toString().padStart(2, '0')
  //         : value.split(':')[0]
  //     const min =
  //       minDisabled < Number(value.split(':')[1])
  //         ? minDisabled.toString().padStart(2, '0')
  //         : value.split(':')[1]
  //     onChange(`${hour}:${min}`)
  //   }
  //   if (!!minValue) {
  //     const hour =
  //       hourDisabled > Number(value.split(':')[0])
  //         ? hourDisabled.toString().padStart(2, '0')
  //         : value.split(':')[0]
  //     const min =
  //       minDisabled > Number(value.split(':')[1])
  //         ? minDisabled.toString().padStart(2, '0')
  //         : value.split(':')[1]
  //     onChange(`${hour}:${min}`)
  //   }
  // }

  return (
    <div className="wrapper-time-picker" ref={ref}>
      <span>{text}</span>
      <InputField
        type="time"
        sized={InputSize.xs}
        value={value}
        onChange={onChange}
        iconName="watch"
        iconPosition={IconPosition.right}
        onClick={() => setIsShow(!isShow)}
        onBlur={onBlurHandler}
      />
      {isShow && (
        <div className="columns-with-time main-view">
          <div className="hour">
            <div className="name">Час</div>
            <div className="column">
              {hourOptions.map((item, index) => {
                return (
                  <div
                    className={`item ${
                      value?.split(':')[0] === item && 'active'
                    } ${hourValueValidFn(item) ? 'disable-time' : ''}`}
                    key={`${index} ${item} hour-key`}
                    onClick={() => {
                      setTimeHandler(item, 'hour')
                    }}
                  >
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="min">
            <div className="name">Мин</div>
            <div className="column">
              {minuteOptions.map((item, index) => {
                return (
                  <div
                    className={`item ${
                      value?.split(':')[1] === item && 'active'
                    } ${minValueValidFn(item) ? 'disable-time' : ''}`}
                    key={`${index} ${item} min-key`}
                    onClick={() => setTimeHandler(item, 'min')}
                  >
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TimePicker
